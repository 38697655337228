<template>
    <v-menu v-if="isMultiSession"
            offset-y
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                color="primary"
                dark
                v-bind="attrs"
                class="px-1 mr-7"
                v-on="on"
            >
                <v-icon class="mr-1">
                    mdi-open-in-new
                </v-icon>
                Live director
            </v-btn>
        </template>
        <v-list>
            <a
                v-for="(event, index) in project.events"
                :key="`${index}-${event.name}`"
                :href="liveDirectorCockpitUrl(event)"
                target="_blank"
                class="live-director-link"
            >
               
                <v-list-item>
                    <v-list-item-icon>
                        <v-icon>
                            {{ event.breakout_group ? 'mdi-alpha-b-box-outline': 'mdi-alpha-s-box-outline' }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-title>
                        {{ event.name }}
                    </v-list-item-title>
                </v-list-item>
            </a>
        </v-list>
    </v-menu>
    <v-btn v-else
           color="primary"
           dark
           class="px-1 mr-7"
           :href="liveDirectorCockpitUrl(project.events[0])"
           target="_blank"
    >
        <v-icon class="mr-1">
            mdi-open-in-new
        </v-icon>
        Live director
    </v-btn>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'LiveDirectorButton',
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['isMultiSession']),
        },
        methods: {
            liveDirectorCockpitUrl(event){
                return process.env.VUE_APP_LIVEDIRECTOR_COCKPIT.replace('{broadcast}', event.liveDirectorBroadcastSlug);
            }
        },
    };
</script>

<style scoped>
.live-director-link{
    color: rgba(0,0,0,.87)!important;
    text-decoration: none !important;
}
</style>