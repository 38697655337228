import { get } from 'lodash';
import {
  hasProductionCompany,
  hasProject
} from '@/store/auth/helpers';

const auth = {
  namespaced: true,
  state: {
    authenticated: false,
    user: {
      id: null,
      email: '',
      name: '',
    },
    PermissionBag: {},
  },
  getters: { 
    user(state) {
      return state.user;
    },
    isAuthenticated(state){
        return state.authenticated;
    },
    isQuadiaLiveAdmin(state) {
      return get(state, 'PermissionBag.is_quadia_live_admin') === true;
    },
    isCompanyAdminAtAnyProductionCompany(state) {
      return get(state, 'PermissionBag.company_admin_production_companies').length > 0;
    },
    isProjectEditorAtAnyProductionCompany(state) {
      return get(state, 'PermissionBag.project_editor_production_companies').length > 0;
    },
    isFreelancerAtAnyProject(state) {
        return get(state, 'PermissionBag.assigned_freelancer_projects').length > 0;
    },
    isClientAtAnyProject(state) {
        return get(state, 'PermissionBag.assigned_client_projects').length > 0;
    },
    isProductionCompanyAdminForProject: (state) => (project) => {
      return hasProductionCompany(get(
        state,
        'PermissionBag.company_admin_production_companies'
      ), project.company?.productionCompany || null);
    },
    isProjectEditorForProject: (state) => (project) => {
      return hasProductionCompany(get(
        state,
        'PermissionBag.project_editor_production_companies'
      ), project.company?.productionCompany || null);
    },
    isClientForProject: (state) => (project) => {
      return hasProject(get(
        state,
        'PermissionBag.assigned_client_projects'
      ), project._id);
    },
    isFreelancerForProject: (state) => (project) => {
      return hasProject(get(
        state,
        'PermissionBag.assigned_freelancer_projects'
      ), project._id);
    },
    isCompanyAdminOfUser: (state) => (user) => {
      const productionCompanies = get(state, 'PermissionBag.company_admin_production_companies');
      return productionCompanies.indexOf(user.productionCompany) > -1;
    },
    isFreelancerOnlyForProject: (state, getters) => (project) => {
      return !getters.isQuadiaLiveAdmin &&
          !getters.isProductionCompanyAdminForProject(project) &&
          !getters.isProjectEditorForProject(project) &&
          !getters.isClientForProject(project) &&
          getters.isFreelancerForProject(project);
    },
    isClientAtMostForProject: (state, getters) => (project) => {
      // before the permissions system is finished, you can test client only access
      // by returning true for this getter
      return !getters.isQuadiaLiveAdmin &&
        !getters.isProductionCompanyAdminForProject(project) &&
        !getters.isProjectEditorForProject(project) &&
        !getters.isFreelancerForProject(project) &&
        getters.isClientForProject(project);
    },
    canEditProject: (state, getters) => (project) => {
      // before the permissions system is finished, you can test client only access
      // by returning false for this getter
      return getters.isQuadiaLiveAdmin ||
        getters.isProductionCompanyAdminForProject(project) ||
        getters.isProjectEditorForProject(project) ||
        getters.isFreelancerForProject(project);
    },
    isClientOnly: (state, getters) => {
        return !getters.isQuadiaLiveAdmin
        && !getters.isProjectEditorAtAnyProductionCompany
        && !getters.isCompanyAdminAtAnyProductionCompany
        && !getters.isFreelancerAtAnyProject
        && getters.isClientAtAnyProject;
    },
    isProjectEditorAtQuadia: (state) => {
        const productionCompanies = get(state, 'PermissionBag.project_editor_production_companies');
        return productionCompanies.indexOf('quadia') > -1;
    },
    isCompanyAdminAtQuadia: (state) => {
        const productionCompanies = get(state, 'PermissionBag.company_admin_production_companies');
        return productionCompanies.indexOf('quadia') > -1;
    },
    canAccessIntakes: (state, getters) => {
        return getters.isQuadiaLiveAdmin
        || getters.isCompanyAdminAtQuadia
        || getters.isProjectEditorAtQuadia;
    },
    canSeeRegistrationData: (state, getters) => (project) => {
        return getters.isQuadiaLiveAdmin
        || getters.isClientForProject(project) || getters.isProductionCompanyAdminForProject(project);
    },
    canDownloadStats: (state, getters) => (project) => {
        return getters.isQuadiaLiveAdmin
        || getters.isClientForProject(project) || getters.isProductionCompanyAdminForProject(project);
    },
    canCreateOnlyExternalEmbedProject: (state) => {
        return get(state, 'PermissionBag.can_create_only_external_embed_project') === true;
    }
  },
  mutations: {
    login(state, payload) {
      state.authenticated = true;
      state.user.email = payload && payload.email ? payload.email : '';
      state.user.name = payload && payload.name ? payload.name : '';
      state.PermissionBag = payload.PermissionBag;
    },
    logout(state) {
      state.authenticated = false;
      state.user = {
        id: null,
        email: '',
        name: '',
      };
    },
  },
};

export default auth;
