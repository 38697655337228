<template>
    <custom-time-picker :value="project.onSiteStartTime"
                        label="On-site start time"
                        class="text-input"
                        :disabled="project.isArchived"
                        @input="saveProjectStartTime"
    />
</template>
<script>
    import CustomTimePicker from '@/components/controls/CustomTimePicker';
    import { mapActions, mapMutations, mapState } from 'vuex';
    import TimeService from '@/services/TimeService';
    export default {
        name: 'OnSiteStartTime',
        components: {CustomTimePicker},
        computed: {
            ...mapState('projects', ['project']),
        },
        methods: {
            ...mapMutations('projects', ['setProjectOnSiteStartTime']),
            ...mapActions('projects', ['saveProject']),
            saveProjectStartTime(value) {
                const onSiteStartTime = TimeService.applyDateComponent(this.project.startTime, value);
                this.save(onSiteStartTime);
            },
            save(value) {
                this.setProjectOnSiteStartTime(value);
                this.saveProject({
                    id: this.project._id,
                    key: 'onSiteStartTime',
                    value,
                });
            }
        },
        watch: {
            'project.startTime'(value) {
                const onSiteStartTime = TimeService.applyDateComponent(value, this.project.onSiteStartTime);
                this.save(onSiteStartTime);
            }
        }
    };
</script>

<style scoped>
.text-input {
    width: 12rem;
}
</style>