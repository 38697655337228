<template>
    <div class="header-item">
        <h4 class="header-item-title">
            {{ name }}
        </h4>
        <div class="header-filters">
            <ProjectListHeadFilterPopup v-if="filterItems"
                                        :is-filtered="isFilteredBy"
                                        :name="name"
                                        class="ml-1"
                                        :part="part"
            >
                <ProjectListHeadFilter
                    :items="filterItems"
                    :column-id="id"
                    :part="part"
                />
            </ProjectListHeadFilterPopup>
            <list-header-sort :is-sorted-by="isSortedBy"
                              :sort-direction="sortDirection"
                              :tooltip-down="tooltipContentDown"
                              :tooltip-up="tooltipContentUp"
                              @sort="sort"
            />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations } from 'vuex';
    import ProjectListHeadFilterPopup from '@/components/pages/Projects/ProjectListHeadFilterPopup';
    import ProjectListHeadFilter from '@/components/pages/Projects/ProjectListHeadFilter';
    import ListHeaderSort from '@/components/controls/list-sort/ListHeaderSort';

    export default {
        name: 'ProjectListHeaderItem',
        components: {ListHeaderSort, ProjectListHeadFilter, ProjectListHeadFilterPopup},
        props: ['id', 'name', 'filterItems', 'part'],
        computed: {
            ...mapGetters('projectLists', ['listingHeader']),
            isSortedBy() {
                return this.listingHeader(this.part).sorting.targetId === this.id;
            },
            sortDirection(){
                return this.listingHeader(this.part).sorting.direction;
            },
            isFilteredBy(){
                return this.listingHeader(this.part).filtering.filters && this.listingHeader(this.part).filtering.filters[this.id] && this.listingHeader(this.part).filtering.filters[this.id].length;
            },
            isStartTime(){
                return this.name === 'Start Time';
            },
            tooltipContentUp(){
                if(this.part === 'passed'){
                    return this.isStartTime ? 'Sort by start time ascending' : 'A-Z';
                }
                return this.isStartTime ? 'Sort by upcoming webinar' : 'A-Z';
            },
            tooltipContentDown(){
                if(this.part === 'passed'){
                    return this.isStartTime ? 'Sort by start time descending' : 'A-Z';
                }
                return this.isStartTime ? 'Sort by last webinar' : 'Z-A';
            }

        },
        methods: {
            ...mapMutations('projectLists', ['setSortingDirection', 'setSortingTargetId']),
            sort(direction) {
                this.setSortingDirection({
                    listing: this.part,
                    value: direction
                });
                this.setSortingTargetId({
                    listing: this.part,
                    value: this.id
                });
            }
        }
    };
</script>

<style lang="scss" scoped>

button.v-icon {
  font-size: 18px !important;
}
</style>
