import { $http } from '@/services/http';
import { makeUrl } from '@/services/ApiService';

const ProductionCompaniesStore = {
    namespaced: true,
    state: {
        productionCompanies: []
    },
    mutations: {
        setProductionCompanies: (state, payload) => state.productionCompanies = payload,
    },
    actions: {
        getProductionCompanies: async({ commit, rootGetters }) => {
            if (!rootGetters['auth/isQuadiaLiveAdmin']) {
                return Promise.resolve();
            }
            
            var response = await $http.get(makeUrl('/production-companies'));
            commit('setProductionCompanies', response.data);
        }
    }
};

export default ProductionCompaniesStore;