<template>
    <div class="px-8 item-list">
        <user-list-header />
        <v-card v-for="user in users"
                :key="`user-list-${user.id}`"
                class="mt-1 list-item"
        >
            <v-row class="px-4">
                <v-col :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'">
                    <a @click="editUser(user)">
                        {{ user.email }}
                    </a>
                </v-col>
                <v-col v-if="isQuadiaLiveAdmin"
                       :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'"
                >
                    {{ user.production_company }}
                </v-col>
                <v-col :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'">
                    <span>
                        {{ user.role }}
                        <span v-if="user.hasFreelancerProjects && user.role"
                              style="display: inline;"
                        >, </span>
                        <span v-if="user.hasFreelancerProjects"
                              style="display: inline;"
                        >Freelancer</span>
                    </span>
                </v-col>
                <v-col :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'"
                       class="d-flex flex-row justify-end"
                >
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-btn icon
                                   small
                                   color="black"
                                   @click="showAssignDialog(user)"
                                   v-on="on"
                            >
                                <v-icon small>
                                    assignment_ind
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Assign freelancer projects</span>
                    </v-tooltip>
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-btn icon
                                   small
                                   color="black"
                                   @click="editUser(user)"
                                   v-on="on"
                            >
                                <v-icon small>
                                    edit
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Edit</span>
                    </v-tooltip>
                    <v-tooltip v-if="canDeleteUser(user)"
                               bottom
                    >
                        <template v-slot:activator="{on}">
                            <v-btn icon
                                   small
                                   color="error"
                                   @click="showConfirmDeleteUser(user)"
                                   v-on="on"
                            >
                                <v-icon small>
                                    delete
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Delete</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </v-card>

        <delete-user-dialog v-model="deleteModal"
                            :user="userToDelete"
                            @confirm="confirmDeleteUser"
        />

        <assign-project-dialog v-if="assignUser"
                               :value="assignUser"
                               @close="hideAssignDialog"
        />
    </div>
</template>

<script>
    import UserListHeader from '@/components/pages/Users/UserListHeader';
    import { mapActions, mapGetters } from 'vuex';
    import User from '@/models/User';
    import AssignProjectDialog from '@/components/pages/Users/AssignProjectDialog';
    import DeleteUserDialog from '@/components/pages/Users/DeleteUserDialog';

    export default {
        name: 'UserList',
        components: {DeleteUserDialog, AssignProjectDialog, UserListHeader},
        data() {
            return {
                userToDelete: {
                    id: '',
                    email: '',
                    role: '',
                },
                deleteModal: false,
                assignUser: null,
            };
        },
        props: {
            users: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            ...mapGetters('auth', ['isQuadiaLiveAdmin'])
        },
        methods: {
            ...mapActions('users', ['getUsers', 'deleteUser']),
            editUser(user) {
                this.$router.push({name: 'User', params: {user_id: user.id}});
            },
            showConfirmDeleteUser(user) {
                this.userToDelete = user;
                this.deleteModal = true;
            },
            async confirmDeleteUser() {
                await this.deleteUser(this.userToDelete.id);
                await this.getUsers();
                this.deleteModal = false;
            },
            showAssignDialog(user) {
                this.assignUser = new User(user);
            },
            hideAssignDialog() {
                this.assignUser = null;
            },
            canDeleteUser(user) {
                return !user.isMyself;
            },
        }
    };
</script>
