<template>
    <div class="header-item">
        <h4 class="header-title font-weight-medium">
            {{ name }}
        </h4>
        <div class="header-filters mt-n1">
            <list-header-sort :is-sorted-by="isSortedBy"
                              :sort-direction="sortDirection"
                              @sort="sort"
            />
        </div>
    </div>
</template>

<script>
    import { mapMutations, mapActions, mapState } from 'vuex';
    import ListHeaderSort from '@/components/controls/list-sort/ListHeaderSort';

    export default {
        name: 'UserListHeaderItem',
        components: {ListHeaderSort},
        props: ['id', 'name'],
        computed: {
            ...mapState('users', ['listingHeader']),
            isSortedBy() {
                return this.listingHeader.sorting.targetId === this.id;
            },
            sortDirection() {
                return this.listingHeader.sorting.direction;
            }
        },
        methods: {
            ...mapMutations('users', ['setSortingDirection', 'setSortingTargetId']),
            ...mapActions('users', ['getUsers']),
            sort(direction) {
                this.setSortingDirection(direction);
                this.setSortingTargetId(this.id);
                this.getUsers();
            }
        }
    };
</script>

<style lang="scss"
       scoped
>
button.v-icon {
    font-size: 18px !important;
}
</style>