<template>
    <v-card class="px-4 font-weight-medium list-header"
            elevation="0"
    >
        <v-row>
            <v-col md="3"
                   class="pt-4"
            >
                <client-list-header-item id="email"
                                         name="Client"
                />
            </v-col>
            <v-col md="6"
                   class="pt-4"
            >
                <client-list-header-item id="projects"
                                         name="Projects"
                                         :non-sortable="true"
                />
            </v-col>
            <v-col md="3">
                <div class="header-item text-right">
                    <div class="py-1">
                        Actions
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import ClientListHeaderItem from './ClientListHeaderItem';

    export default {
        name: 'ClientListHeader',
        components: {ClientListHeaderItem}
    };
</script>
