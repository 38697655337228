<template>
    <div>
        <h3 class="mb-2 font-weight-bold">
            Default registration page body text
        </h3>
        <v-divider class="mb-10" />
        <div class="px-4">
            <rich-text-editor
                v-model="introductionText"
                class="rich-text-editor"
                :disabled="project.isArchived"
                :with-event-links="false"
            />
        </div>
        <h3 class="mb-2 mt-15 font-weight-bold">
            Linkedin
        </h3>
        <v-divider class="mb-1" />
        <div class="d-flex align-center">
            <div class="mr-4 d-inline-block">
                Show register with LinkedIn above the registration form.
            </div>
            <v-switch
                v-model="showLinkedin"
                inset
                :disabled="project.isArchived"
            />
        </div>
        <div class="d-flex align-center">
            <div class="mr-4 d-inline-block">
                Send registration data with the Ask a Question message body to Live Director.
            </div>
            <v-switch
                v-model="attachRegistrationDataToAskAQuestion"
                inset
                :disabled="project.isArchived"
            />
        </div>
        <h3 class="mb-2 mt-15 font-weight-bold">
            Registration form editor
        </h3>
        <v-divider class="mb-1" />
        <registration-fields-editor
            v-model="registrationFields"
            label="Fields"
            :fixed-fields="fixedFields"
            :disabled="project.isArchived"
            class="mt-10 px-4"
            @validate="onFieldValidation"
        />
    </div>
</template>

<script>
    import RegistrationFieldsEditor from '@/components/controls/field-editor/RegistrationFieldsEditor.vue';
    import RichTextEditor from '@/components/controls/RichTextEditor.vue';
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'RegistrationFormEditor',
        components: {RichTextEditor, RegistrationFieldsEditor},
        data() {
            return {
                fieldValidationResult: {
                    valid: true,
                },
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            introductionText: {
                get() {
                    return this.project.registration.introductionText;
                },
                async set(value) {
                    await this.saveProject({
                        key: 'registration.introductionText',
                        value,
                    });
                },
            },
            showLinkedin: {
                get() {
                    return this.project.registration.showLinkedin;
                },
                async set(value) {
                    this.setShowLinkedin(value);
                    this.setRequireRegistrationForOnDemand(false);
                    await this.saveProjectImmediate({
                        id: this.project._id,
                        key: 'registration.showLinkedin',
                        value,
                    });
                    await this.saveProjectImmediate({
                        id: this.project._id,
                        key: 'requireRegistrationForOnDemand',
                        value: false
                    });
                },
            },
            attachRegistrationDataToAskAQuestion: {
                get() {
                    return this.project.registration.attachRegistrationDataToAskAQuestion;
                },
                async set(value) {
                    this.setAttachRegistrationDataToAskAQuestion(value);
                    await this.saveProject({
                        id: this.project._id,
                        key: 'registration.attachRegistrationDataToAskAQuestion',
                        value
                    });
                }
            },
            registrationFields: {
                get() {
                    return this.project.registration.fields;
                },
                async set(value) {
                    this.setRegistrationFields(value);
                    await this.saveProject({
                        key: 'registration.fields',
                        value,
                    });
                },
            },
            fixedFields() {
                const fixedFields = [
                    'last_name',
                    'email'
                ];

                if (this.project.type === 'hybrid') {
                    fixedFields.push('on_site_viewer');
                }


                return fixedFields;
            },
        },
        methods: {
            ...mapMutations('projects', [
                'setShowLinkedin',
                'setRegistrationFields',
                'setRequireRegistrationForOnDemand',
                'setAttachRegistrationDataToAskAQuestion',
            ]),
            ...mapActions('projects', ['saveRegistration', 'saveProject', 'saveProjectImmediate']),
            onFieldValidation(result) {
                this.fieldValidationResult = result;
            },
        }
    };

</script>
<style scoped>

</style>