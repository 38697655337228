import { EVALUATION_URL, populateEvaluationUrl } from '@/services/ApiService';
import { $http } from '@/services/http';
import { debounce } from 'lodash';
import Evaluation from '@/models/Evaluation';

const evaluationFieldsStore = {
    namespaced: true,
    state: {
        evaluation: new Evaluation({fields: [], _id: ''})
    },
    getters: {
        fields: (state) => state.evaluation.fields,
    },
    mutations: {
        setFields: (state, payload) => state.evaluation.fields = payload,
        setEvaluation: (state, payload) => state.evaluation = new Evaluation(payload),
    },
    actions: {
        async getFields({commit}, {projectId, evaluationId}) {
            const url = populateEvaluationUrl(`${EVALUATION_URL}`, projectId, evaluationId );
            const {data} = await $http.get(url);
            commit('setEvaluation', data);
        },

        // TODO: remove eventId when evaluation is moved to project level
        saveFields: debounce( async function ({commit}, {projectId, value, evaluationId}) {
            commit('setFields', value.fields);
            const url = populateEvaluationUrl(`${EVALUATION_URL}`, projectId, evaluationId);
            await $http.put(url, value);
        }, 400),
    }
};

export default evaluationFieldsStore;