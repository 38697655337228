<template>
    <v-card class="link-tracking-table mt-4">
        <v-card-title class="font-weight-regular">
            <slot name="title">
                Link tracking
            </slot>
        </v-card-title>
        <v-divider />
        <v-card-text>
            <v-simple-table dense>
                <thead>
                    <tr>
                        <th v-if="isHybridProject"
                            class="text-left"
                        >
                            App
                        </th>
                        <th class="text-left">
                            Action
                        </th>
                        <th class="text-right">
                            Clicks
                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="x in items"
                        :key="`${x.app}${x.page}.${x.action}.${x.clicks}`"
                    >
                        <td v-if="isHybridProject"
                            class="text-left"
                        >
                            {{ x.app }}
                        </td>
                        <td class="text-left">
                            <a v-if="/^https:\/\//.test(x.action)"
                               target="_blank"
                               :href="x.action"
                            >
                                {{ x.action }}
                            </a>
                            <span v-else>{{ x.action }}</span>
                        </td>
                        <td class="text-right">
                            {{ x.count }}
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
        </v-card-text>
    </v-card>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'LinkTrackingPageList',
        props: {
            items: {
                type: Array,
                required: true
            }
        },
        computed: {
            ...mapGetters('projects', ['isHybridProject']),
        }
    };
</script>

<style scoped>

</style>