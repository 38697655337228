<template>
    <div>
        <list-filter-selector :selected="selected"
                              :items="items"
                              :column-id="columnId"
                              :max-list-length="0"
                              @input="toggleSelected"
        />
    </div>
</template>

<script>
    import {mapMutations, mapState} from 'vuex';
    import ListFilterSelector from '@/components/controls/list-filters/ListFilterSelector';

    export default {
        name: 'IntakeListHeadFilter',
        components: {ListFilterSelector},
        props: ['items', 'columnId'],
        data: () => ({
            listLengthLimit: 5,
            selection: []
        }),
        computed: {
            ...mapState('intake', ['listingHeader']),
            selected: {
                get() {
                    return this.listingHeader.filtering.filters[this.columnId] || [];
                },
                set(value){
                    this.setHeaderFilter({id: this.columnId, items: value});
                }
            },
        },
        methods: {
            ...mapMutations('intake', ['setHeaderFilter']),
            toggleSelected(item) {
                if (this.selected.filter(x => x.id === item.id).length > 0) {
                    this.selected = this.selected.filter(s => s.id !== item.id);
                } else {
                    this.selected = [...this.selected, item];
                }
            },
        }
    };
</script>
