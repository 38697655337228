<template>
    <stats-card
        class=" metric geo-chart-card"
        :loading="loading"
    >
        <template v-slot:title>
            Views per country
        </template>
        <template v-slot:content>
            <GChart v-if="!loading"
                    :type="type"
                    :data="chartData"
                    :settings="settings"
                    :options="options"
            />
        </template>
    </stats-card>
</template>

<script>
    import { GChart } from 'vue-google-charts/legacy';
    import StatsCard from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/StatsCard.vue';
    import { fetchStatisticsApi } from '@/services/StatisticsService';

    export default {
        name: 'CardCountriesGeoChart',
        components: {
            StatsCard,
            GChart,
        },
        props: {
            dateRange: {
                type: Array,
                required: true
            },
            videoId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                type: 'GeoChart',
                chartData: [['Country', 'Popularity']],
                settings: {
                    packages: ['geochart'],
                },
                options: {
                    colorAxis: {colors: ['#7aa4cb', '#0174dd']}
                }
            };
        },
        methods: {
            async getCountries() {
                this.loading = true;
                fetchStatisticsApi('/api/videos/views-by-country', this.dateRange, this.videoId).then(response => {
                    const buckets = response.data;
                    this.countries = [];
                    if (buckets.length > 0) {
                        buckets.forEach(country => {
                            this.chartData.push([country.key, country.doc_count]);
                        });
                    }
                }).finally(() => this.loading = false);
            },
        },
        mounted() {
            this.getCountries();
        },
    };
</script>

<style lang="scss">
.geo-chart-card{
    .v-card__text {
        min-height: 100px;
        height: 100%;
        max-height: 100000px !important;
        overflow: hidden !important;
    }
    min-height: 100%;
}
</style>