<template>
    <div>
        <draggable v-model="model"
                   ghost-class="ghost"
                   handle=".drag-handle"
        >
            <v-row
                v-for="item in model"
                :key="`option-editor-${item.id || item.text}`"
                outlined
                class="no-gutters item-row"
            >
                <v-col cols="1"
                       md="1"
                >
                    <div class="d-flex">
                        <div class="flex-grow-0 drag-handle">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        color="grey"
                                        class="mr-4 mt-2"
                                        v-on="on"
                                    >
                                        drag_handle
                                    </v-icon>
                                </template>
                                <span>Drag to reorder</span>
                            </v-tooltip>
                        </div>
                    </div>
                </v-col>
                <v-col cols="10"
                       md="10"
                >
                    <v-text-field
                        v-model="item.text"
                        hide-details
                        @input="validate"
                    />
                </v-col>
                <v-col cols="1">
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn
                                icon
                                class="mt-3"
                                v-on="on"
                                @click="deleteOption(item)"
                            >
                                <v-icon>delete</v-icon>
                            </v-btn>
                        </template>
                        <span>Remove</span>
                    </v-tooltip>
                </v-col>
            </v-row>
        </draggable>
        <v-row>
            <v-col cols="12">
                <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                        <v-divider class="mb-4" />
                        <v-row class="no-gutters">
                            <v-col cols="11">
                                <v-text-field
                                    v-model="newFieldValue"
                                    outlined
                                    dense
                                    class=""
                                    @keyup.enter="addOption"
                                />
                            </v-col>
                            <v-col cols="1">
                                <v-btn
                                    color="primary"
                                    icon
                                    text
                                    :disabled="newFieldValue.trim() === ''"
                                    class=""
                                    v-on="on"
                                    @click="addOption"
                                >
                                    <v-icon>add</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                    </template>
                    <span>Add option</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import { v4 as uuidv4 } from 'uuid';
    import FieldOption from '@/models/FieldOption';
    import { getNonDuplicateText, validateOptions } from '@/services/FieldService';

    export default {
        name: 'OptionEditor',
        props: {
            value: {
                type: Array,
                default: () => [],
            },
        },
        components: {
            draggable,
        },
        data() {
            return {
                model: [...this.value],
                newFieldValue: '',
            };
        },
        watch: {
            model: {
                deep: true,
                handler(value) {
                    this.$emit('input', value);
                },
            },
        },
        methods: {
            addOption() {
                if (this.newFieldValue.trim() === '') {
                    return;
                }

                this.model.push(
                    new FieldOption({
                        id: uuidv4(),
                        text: getNonDuplicateText(this.model, this.newFieldValue),
                    })
                );
                this.newFieldValue = '';
                this.validateImmediate();
                this.$emit('confirm');
            },
            deleteOption(option) {
                this.model = this.model.filter((o) => o.id !== option.id);
                this.validateImmediate();
            },
            validateImmediate() {
                this.$emit('validate', validateOptions(this.model));
            },
            validate() {
                clearTimeout(this.$options.validationTimeout);

                this.$options.validationTimeout = setTimeout(
                    this.validateImmediate,
                    400
                );
                this.$emit('confirm');
            },
        },
        mounted() {
            this.validateImmediate();
        },
    };
</script>

<style scoped
       lang="scss"
>
@import "src/_scss/components/colors";

.text-ellipsis {
    text-overflow: ellipsis;
}

.item-row {
    border-bottom: 1px solid #f2f2f2;
    position: relative;

    .drag-handle {
        cursor: grab;
    }
}

.ghost {
    border: 1px solid $primary;
}
</style>