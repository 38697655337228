const BaseAggregator = class {
    withPercentages(result, total) {
        const keys = Object.keys(result);
        for (let key in keys) {
            const value = (100 * result[keys[key]]) / total;
            result[keys[key]] = Math.round((value + Number.EPSILON) * 100) / 100;
        }

        return result;
    }
};

export default BaseAggregator;