import { $http } from '@/services/http';
import { buildSimpleQueryString, makeUrl } from '@/services/ApiService';

const CLIENT_ROWS_PER_PAGE_KEY = 'clientRowsPerPage';
const PAGE_SIZE = 10;

const ClientsStore = {
    namespaced: true,
    state: {
        loading: true,
        clients: [],
        pagination: {
            searchText: '',
            current: 1,
            total: 1,
            pageSize: window.localStorage.getItem(CLIENT_ROWS_PER_PAGE_KEY) || PAGE_SIZE,
        },
        listingHeader: {
            sorting: {
                targetId: 'email',
                direction: 'asc'
            }
        }
    },
    mutations: {
        setClients: (state, payload) => {
            state.loading = false;
            state.clients = payload;
        },

        setSearchText: (state, payload) => state.pagination.searchText = payload || '',
        setCurrentPage: (state, payload) => state.pagination.current = parseInt(payload),
        setPageTotal: (state, payload) => state.pagination.total = parseInt(payload),
        setPageSize: (state, payload) => {
            window.localStorage.setItem(CLIENT_ROWS_PER_PAGE_KEY, parseInt(payload));            
            state.pagination.pageSize = parseInt(payload);
        },

        setSortingTargetId: (state, payload) => state.listingHeader.sorting.targetId = payload,
        setSortingDirection: (state, payload) => state.listingHeader.sorting.direction = payload,
    },
    actions: {
        getClients: async({ state, commit }) => {
            var url = makeUrl('/clients');
            var response = await $http.get(buildSimpleQueryString(url, {
                search: encodeURIComponent(state.pagination.searchText),
                page: state.pagination.current,
                perPage: state.pagination.pageSize,
                sort: state.listingHeader.sorting.targetId,
                order: state.listingHeader.sorting.direction
            }));

            commit('setClients', response.data.data);
            commit('setCurrentPage', response.data.pagination.page);
            commit('setPageTotal', response.data.pagination.total);
            commit('setPageSize', response.data.pagination.perPage);
        },
        assignClientToProject: (context, { userId, projectId }) => {
            return $http.put(makeUrl('/clients/{user_id}/assign/{project_id}', { userId, projectId }));
        },
        unassignClientFromProject: (context, { userId, projectId }) => {
            return $http.put(makeUrl('/clients/{user_id}/unassign/{project_id}', { userId, projectId }));
        },
    }
};

export default ClientsStore;