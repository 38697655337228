export const REGISTRATION_TYPE_OPEN = 'open';
export const REGISTRATION_TYPE_REGISTRATION = 'registration';
export const REGISTRATION_TYPE_GENERATED = 'generated';
export const REGISTRATION_TYPE_SPRINGER = 'springer';

export const REGISTRATION_TYPES = [
  {
    label: 'Open',
    value: REGISTRATION_TYPE_OPEN
  },
  {
    label: 'Registration',
    value: REGISTRATION_TYPE_REGISTRATION
  },
  {
    label: 'Generate links',
    value: REGISTRATION_TYPE_GENERATED
  },
  {
    label: 'Springer Oauth',
    value: REGISTRATION_TYPE_SPRINGER
  }
];
