<template>
    <v-card class="filter-popup filter-popup-card"
            :style="popupStyle"
    >
        <v-toolbar light
                   dense
                   flat
                   class="pr-2"
        >
            <v-toolbar-title>Filter</v-toolbar-title>
            <v-spacer />
            <v-btn icon
                   small
                   @click="closeMenu"
            >
                <v-icon>mdi-close</v-icon>
            </v-btn>
        </v-toolbar>
        <slot />
    </v-card>
</template>
<script>
    export default {
        name: 'ListFilterCard',
        props: {
            minWidth: {
                type: Number,
                default: 235
            }
        },
        computed: {
            popupStyle() {
                return {
                    minWidth: this.minWidth + 'px',
                };
            },
        },
        methods: {
            closeMenu() {
                this.$emit('close');
            }
        }
    };
</script>
