<template>
    <v-dialog
        v-model="value"
        persistent
        max-width="600"
        scrollable
    >
        <v-form ref="form">
            <v-card>
                <v-card-title class="pb-0">
                    Add breakout sessions
                    <v-spacer />
                    <v-btn icon
                           @click="closeModal"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="breakoutGroupName"
                                  filled
                                  class="session-input mt-4"
                                  label="Breakout group name"
                                  style="max-width: none"
                                  :rules="requiredField"
                                  hide-details="auto"
                    />
                    <div class="d-flex align-center">
                        <span class="mr-10 pt-3">
                            Amount of breakouts
                        </span>
                        <v-text-field
                            v-model="amountOfBreakouts"
                            class="number-input"
                            type="number"
                            min="0"
                            :rules="requiredField"
                        />
                    </div>
                    <v-divider class=" mt-3 mb-6" />

                    <div v-if="amountOfBreakouts">
                        <div v-for="n in parseInt(amountOfBreakouts)"
                             :key="'key'+ n"
                        >
                            <v-text-field
                                v-model="breakoutTitles[n - 1]"
                                filled
                                class="text-input"
                                :label="'Breakout ' + n"
                                :rules="requiredField"
                                hide-details="auto"
                            />
                            <div class="d-flex align-center  mt-3">
                                <span class="mr-10 pt-3">Max number of registrations</span>
                                <v-text-field
                                    v-model="breakoutRegistrationsLimit[n - 1]"
                                    class="number-input"
                                    type="number"
                                    min="0"
                                    max="300"
                                    :rules="[v => v <= 300 || 'Max 300 registrants']"
                                />
                            </div>
                            <v-divider class=" mt-3 mb-6" />
                        </div>
                    </div>
                    <v-row>
                        <v-col cols="12"
                               lg="6"
                               md="6"
                               sm="6"
                               class="py-0 d-inline-flex"
                        >
                            <h3 class="font-weight-regular mr-3 pt-6">
                                Start time
                            </h3>
                            <custom-time-picker
                                v-model="breakoutGroupStartTime"
                                :rules="requiredField"
                                send-time-formatted
                                class="session-input"
                                @formattedStartTime="saveFormattedTime"
                            />
                        </v-col>
                        <v-col cols="12"
                               lg="6"
                               md="6"
                               sm="6"
                               class="py-0 d-inline-flex"
                        >
                            <h3 class="font-weight-regular mr-3 pt-6">
                                Duration
                            </h3>
                            <v-text-field
                                v-model="durationInMinutes"
                                class="session-input"
                                type="number"
                                min="1"
                                :rules="requiredFieldDuriation"
                            />
                            <h3 class="font-weight-regular ml-3 pt-6">
                                minutes
                            </h3>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-divider />
                <v-card-actions class="px-6 pb-4">
                    <v-spacer />
                    <v-btn color="primary"
                           :disabled="submitDisabled"
                           :loading="submitDisabled"
                           @click="addBreakoutCreateProject()"
                    >
                        Add
                    </v-btn>
                    <v-btn
                        @click="closeModal"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>

<script>
    import { mapState } from 'vuex';
    import CustomTimePicker from '@/components/controls/CustomTimePicker';
    import {trim} from 'lodash';
    import { v4 as uuidv4 } from 'uuid';


    export default {
        name:'CreateBreakoutGroup',
        components: {CustomTimePicker},
        props:{
            value: {
                type: Boolean,
                default: () => false
            },
        },
        data(){
            return {
                breakoutGroupStartTime: 0,
                durationInMinutes: 1,
                amountOfBreakouts: null,
                breakoutTitles: [],
                breakoutGroupName: null,
                submitDisabled: false,
                formattedStartTime: '',
                breakoutRegistrationsLimit: []
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            requiredField(){
                return [(v) => !!trim(v) || 'Field is required'];
            },
            requiredFieldDuriation(){
                return [(v) => !!v && v > 0 || 'Value must be greater than 1'];
            }
        },
        methods:{
            addBreakoutCreateProject(){
                if(this.$refs.form.validate()){
                    const breakoutGroupId = uuidv4();
                    this.$emit('confirm', this.breakoutTitles.map((breakout, index) => {
                        return {
                            breakoutGroupName: this.breakoutGroupName,
                            breakout_group: breakoutGroupId,
                            name: breakout,
                            registrationsCountLimit:this.breakoutRegistrationsLimit[index],
                            startTime: this.breakoutGroupStartTime,
                            durationInMinutes: Math.ceil(this.durationInMinutes),
                            startTimeFormattedTime: this.formattedStartTime
                        };
                    }));
                    this.closeModal();
                }
            },
            saveFormattedTime(formattedStartTime){
                this.formattedStartTime = formattedStartTime;
            },
            closeModal(){
                this.$emit('input', false);
            },
        },
    };
</script>

<style scoped>
.session-input{
    max-width: 10rem;
}
.number-input{
    max-width: 100px;
}
</style>
