<template>
    <v-menu top
            nudge-top="50"
            open-on-hover
            close-delay="800"
    >
        <template v-slot:activator="{ on }">
            <v-btn icon
                   v-on="on"
            >
                <v-icon>
                    info
                </v-icon>
            </v-btn>
        </template>
        <v-card dark
                max-width="560"
        >
            <v-card-text>
                <span>Recommended aspect ratio is 16 x 4. Maximum allowed file size 100kb. Save the jpeg
                    with 80% quality, to have the best quality possible. To convert and resize the image
                    you can use the free Photoshop tool: 
                    <a href="https://spark.adobe.com/tools/image-resize"
                       target="_blank"
                    >
                        https://spark.adobe.com/tools/image-resize
                    </a>.
                </span>
            </v-card-text>
        </v-card>
    </v-menu>
</template>

<script>
    export default {
        name: 'EmailHeaderHint',
    };
</script>

<style scoped>

</style>