<template>
    <span>{{ normalizedValue }}</span>
</template>
<script>
    export default {
        name: 'IntakeTypeDisplay',
        props: {
            value: {
                type: String,
                default: () => ''
            },
            separator: {
                type: String,
                default: () => '-'
            },
            glue: {
                type: String,
                default: () => ' '
            }
        },
        computed: {
            normalizedValue() {
                const chunks = this.value.split(this.separator);
                const results = [];
                chunks.forEach((c) => results.push(this.capitalize(c)));
                return results.join(this.glue);
            }
        },
        methods: {
            capitalize(s) {
                if (typeof s !== 'string') {
                    return '';
                }

                return s.charAt(0).toUpperCase() + s.slice(1);
            }
        }
    };
</script>

<style scoped>

</style>