<template>
    <v-dialog :value="true"
              scrollable
              persistent
    >    
        <v-card>
            <v-progress-linear v-if="loading"
                               indeterminate
            />
            <v-card-title>
                <h1 class="px-4 font-weight-regular page-header">
                    <span v-if="!user.id">Create</span>
                    <span v-else>Edit</span> user: {{ user.email }}
                </h1>
            </v-card-title>
            <v-card-text>
                <user-details v-if="!loading"
                              :key="userDetailsKey"
                              v-model="user"
                              class="mt-4"
                              :hide-freelancer="hideFreelancer"
                              @validate="userDetailsValidated"
                              @input="++freelancerProjectKey"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn :to="{name: 'Users'}">
                    Cancel
                </v-btn>
                <v-btn color="primary"
                       :disabled="!valid"
                       @click="saveUser"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import UserDetails from '@/components/pages/Users/UserDetails';
    import { mapActions, mapMutations } from 'vuex';

    export default {
        name: 'EditUser',
        components: {UserDetails},
        data() {
            return {
                loading: false,
                valid: false,
                freelancerProjectKey: 0,
                userDetailsKey: 0,
            };
        },
        computed: {
            user: {
                get() {
                    return this.$store.state.users.user;
                },
                set(value) {
                    this.setUser(value);
                }
            },
            hideFreelancer() {
                return !!this.$store.state.users.user.role;
            }
        },
        methods: {
            ...mapMutations('users', ['setUser']),
            ...mapActions('users', [
                'storeUser',
                'updateUser',
                'getUser',
            ]),
            ...mapActions('productionCompanies', ['getProductionCompanies']),
            async saveUser() {
                await this.updateUser();
                this.$emit('user-edited');
            },
            async loadUser() {
                this.loading = true;
                var promises = [
                    this.getProductionCompanies(),
                    this.getUser(this.$route.params.user_id)
                ];
                await Promise.all(promises);
                this.loading = false;
            },
            userDetailsValidated(valid) {
                this.valid = valid;
            },
            assign(project) {
                this.user.freelancerProjects.push(project);
            },
            unAssign(project) {
                this.user.freelancerProjects = this.user.freelancerProjects
                    .filter(p => p.projectId !== project.projectId);
            }
        },
        mounted() {
            this.loadUser();
        }
    };
</script>

<style scoped>

</style>