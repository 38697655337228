<template>
    <v-row>
        <v-col cols="12">
            Assigned projects:
            <v-chip-group v-if="assignedProjects.length > 0"
                          column
            >
                <project-chip
                    v-for="project in assignedProjects"
                    :key="`assigned-project-${project.projectId}`"
                    :project="project"
                    @close="unassign(project)"
                />
            </v-chip-group>
            <span v-else>None</span>
        </v-col>
        <v-col cols="12">
            <v-text-field
                v-model="searchText"
                solo-inverted
                flat
                dense
                clearable
                hide-details
                placeholder="Search for projects to assign"
            />
        </v-col>
        <v-col cols="12">
            <v-simple-table dense>
                <thead>
                    <tr>
                        <th v-if="isQuadiaLiveAdmin"
                            class="company-name-column"
                        >
                            Production company
                        </th>
                        <th class="company-name-column">
                            Company
                        </th>
                        <th class="name-column">
                            Internal project name
                        </th>
                        <th class="action-column">
                            Action
                        </th>
                    </tr>
                </thead>

                <tbody v-if="!loading">
                    <tr
                        v-for="project in projects"
                        :key="`project-selector-${project.projectId}`"
                    >
                        <td v-if="isQuadiaLiveAdmin"
                            class="company-name-column"
                        >
                            {{ project.productionCompany }}
                        </td>
                        <td class="company-name-column">
                            {{ project.clientCompany }}
                        </td>
                        <td class="name-column">
                            {{ project.projectName }}
                        </td>
                        <td class="action-column">
                            <v-btn
                                v-if="!isAssigned(project)"
                                x-small
                                color="primary"
                                @click="assign(project)"
                            >
                                Assign
                            </v-btn>
                            <v-btn v-else
                                   x-small
                                   color="error"
                                   @click="unassign(project)"
                            >
                                Unassign
                            </v-btn>
                        </td>
                    </tr>
                </tbody>
            </v-simple-table>
            <v-progress-linear v-if="loading"
                               indeterminate
            />
        </v-col>
    </v-row>
</template>

<script>
    import { debounce } from 'lodash';
    import {
        buildSimpleQueryString,
        PROJECT_EVENT_LISTING_FOR_ASSIGNMENT_URL
    } from '@/services/ApiService';
    import { $http } from '@/services/http';
    import ProjectChip from './ProjectChip';
    import {mapGetters} from 'vuex';

    export default {
        name: 'ProjectSelector',
        components: { ProjectChip },
        props: ['assignedProjects'],
        data() {
            return {
                searchText: '',
                loading: true,
                projects: [],
            };
        },
        watch: {
            searchText: {
                immediate: true,
                handler: debounce(async function(value) {
                    this.loadProjects(value);
                }),
            },
        },
        computed: {
            ...mapGetters('auth', ['isQuadiaLiveAdmin']),
        },
        methods: {
            async loadProjects(searchText) {
                this.loading = true;
                var url = buildSimpleQueryString(PROJECT_EVENT_LISTING_FOR_ASSIGNMENT_URL, {
                    search: searchText || '',
                    sort: 'startTime',
                    order: 'desc',
                    perPage: 20,
                });
                var response = await $http.get(url);
                this.projects = response.data.projects.map((p) => {
                    return {
                        projectId: p._id,
                        projectName: p.name,
                        clientCompany: p.company?.name || '',
                        productionCompany: p.company?.productionCompany || '',
                    };
                });
                this.loading = false;
            },
            async assign(project) {
                this.$emit('assign', project);
            },
            async unassign(project) {
                this.$emit('unassign', project);
            },
            isAssigned(project) {
                for (var i in this.assignedProjects) {
                    if (this.assignedProjects[i].projectId === project.projectId) {
                        return true;
                    }
                }
                return false;
            },
        },
    };
</script>
