<template>
    <v-dialog
        v-model="showEmailModal"
        persistent
        max-width="500"
    >
        <v-card>
            <v-card-title class="pb-0">
                Add a mail
                <v-spacer />
                <v-btn icon
                       @click="closeModal"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-radio-group v-model="selectedEmailType"
                               row
                >
                    <v-radio
                        v-for="n in emailLabels"
                        :key="n"
                        :disabled="disableType"
                        :label="n"
                        :value="emailLabels.indexOf(n)"
                    />
                </v-radio-group>
                <v-row class="px-3">
                    <v-col cols="12"
                           lg="4"
                           md="4"
                           style="display : flex"
                           class="pa-2 pl-0"
                    >
                        <v-text-field v-model="sendTimeDays"
                                      outlined
                                      type="number"
                                      class="timeInput"
                                      hide-details
                                      dense
                                      min="0"
                        />
                        <h3 class="font-weight-regular pt-2 pl-1">
                            days,
                        </h3>
                    </v-col>
                    <v-col cols="12"
                           lg="4"
                           md="4"
                           style="display : flex"
                           class="pa-2"
                    >
                        <v-text-field v-model="sendTimeHours"
                                      outlined
                                      type="number"
                                      class="timeInput"
                                      hide-details
                                      dense
                                      min="0"
                        />
                        <h3 class="font-weight-regular pt-2 pl-1">
                            hours,
                        </h3>
                    </v-col>
                    <v-col cols="12"
                           lg="4"
                           md="4"
                           style="display : flex"
                           class="pa-2 pr-0"
                    >
                        <v-text-field v-model="sendTimeMinutes"
                                      outlined
                                      type="number"
                                      class="timeInput"
                                      hide-details
                                      dense
                                      min="0"
                        />
                        <h3 class="font-weight-regular pt-2 pl-1">
                            minutes
                        </h3>
                    </v-col>
                    <h3 v-if="selectedEmailType !== null"
                        class="font-weight-regular mt-2"
                    >
                        {{ selectedEmailType === 1 ? 'after':'before' }} the event starts
                    </h3>
                </v-row>
                <h3 v-if="!isScheduleDateCorrect"
                    class="font-weight-regular mt-4"
                    style="color: red"
                >
                    {{ warningMessage }}
                </h3>
                <h4 class="font-weight-regular mt-4">
                    Note: sending out emails can take about 5 minutes to process
                </h4>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-6 pb-4">
                <h3 class="font-weight-regular"
                    style="color: rgba(0,0,0,.6);"
                >
                    {{ emailSendDateFormatted }}
                </h3>
                <v-spacer />
                <v-btn color="primary"
                       :disabled="selectedEmailType === null || selectedTime === 0"
                       @click="email ? updateScheduledMail() : addScheduledMail()"
                >
                    {{ email ? 'Update mail' : 'Add mail' }}
                </v-btn>
                <v-btn 
                    @click="closeModal"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import TimeService from '@/services/TimeService';
    import { $http } from '@/services/http';
    import {EMAIL_ADD_SCHEDULED_EMAIL,
            EMAIL_UPDATE_SCHEDULED_EMAIL, 
            populateScheduledEmailsUrl
    } from '@/services/ApiService';
    import moment from 'moment-timezone';
    export default {
        name:'ScheduleMailDialog',
        props:{
            showEmailModal: {
                type: Boolean,
                default: () => false
            },
            email: {
                type: Object,
                default: () => null
            } 
        },
        data(){
            return {
                emailLabels:['Reminder mail', 'Thank You mail'],
                emailTypes: ['reminder', 'thank_you'],
                selectedEmailType: null,
                sendTimeDays: 0,
                sendTimeHours: 0,
                sendTimeMinutes: 0,
                disableType: false,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            isReminderType(){
                return this.selectedEmailType === 0 || this.selectedEmailType === 2; 
            },
            selectedTime(){
                return (this.sendTimeDays*24*60*60 + this.sendTimeHours*60*60 + this.sendTimeMinutes*60) *1000;
            },
            selectedTimeInMinutes(){
                return parseInt(this.sendTimeDays ? this.sendTimeDays : 0 )*24*60 + parseInt(this.sendTimeHours ? this.sendTimeHours : 0)*60 + parseInt(this.sendTimeMinutes ? this.sendTimeMinutes : 0);
            },
            emailSendDateTimeStamp(){
                return this.isReminderType ? 
                    this.project.startTime * 1000 - this.selectedTime : 
                    this.project.startTime * 1000 + this.selectedTime;
            },
            emailSendDateFormatted(){
                if(this.selectedEmailType === null){
                    return '';
                }
                return moment(this.emailSendDateTimeStamp)
                    .tz(TimeService.defaultTimezone)
                    .format('MMMM D YYYY [at] HH:mm z');
            },
            isScheduleDateCorrect(){
                const currentDate = new Date().getTime();
                if(this.selectedEmailType === null){
                    return false;
                }
                if (currentDate > this.emailSendDateTimeStamp ){
                    return false;
                }
                const minutes15 = 15*60*1000;
                const scheduleEventDateDifference = this.project.startTime *1000 - this.emailSendDateTimeStamp;
                if( this.isReminderType && scheduleEventDateDifference < minutes15 ){
                    return false;
                }
                return true;
            },
            warningMessage(){
                const minutes15 = 15*60*1000;
                const scheduleEventDateDifference = this.project.startTime *1000 - this.emailSendDateTimeStamp;
               
                if( this.isReminderType && scheduleEventDateDifference < minutes15 && this.selectedTimeInMinutes > 0){
                    return 'The scheduled date should not be less than 15 minutes before event date';
                   
                }
                const currentDate = new Date().getTime();
                if (this.selectedEmailType !== null && currentDate > this.emailSendDateTimeStamp ){
                    return 'The scheduled date should not be before current date';
                    
                }
                return '';
            }
        },
        methods:{
            ...mapActions('projects', ['getProject']),
            updateScheduledMail(){
                if(this.isScheduleDateCorrect){
                    $http.put(populateScheduledEmailsUrl(
                                  EMAIL_UPDATE_SCHEDULED_EMAIL,
                                  this.$route.params.project_id, this.email._id),
                              {
                                  timeDifferenceInMinutes: this.selectedTimeInMinutes,
                              })
                        .then(() => {
                            this.getProject(this.$route.params.project_id);

                            this.selectedEmailType = null;
                            this.sendTimeDays= 0;
                            this.sendTimeHours= 0;
                            this.sendTimeMinutes= 0;
                            this.disableType = null;
                            this.closeModal();
                        });
                }
            },
            addScheduledMail(){
                if(this.isScheduleDateCorrect){
                    const emailType =  this.emailTypes[this.selectedEmailType];
                    $http.post(populateScheduledEmailsUrl(EMAIL_ADD_SCHEDULED_EMAIL,this.$route.params.project_id), {
                        emailType: emailType,
                        timeDifferenceInMinutes: this.selectedTimeInMinutes
                    }).then(() =>{
                        this.getProject(this.$route.params.project_id);
                        this.selectedEmailType = null;
                        this.sendTimeDays= 0;
                        this.sendTimeHours= 0;
                        this.sendTimeMinutes= 0;
                        this.closeModal();
                    });
                }
            },
            closeModal(){
                this.sendTimeDays= 0;
                this.sendTimeHours= 0;
                this.sendTimeMinutes= 0;
                this.selectedEmailType = null;
                this.disableType = false;
                this.$emit('cancelled');
            },
            getTimeStemp(timeDifferenceInMinutes, emailType){
                if (emailType === 'reminder'){
                    return this.project.startTime *1000 - timeDifferenceInMinutes*60000; 
                } else {
                    return this.project.startTime *1000 + timeDifferenceInMinutes*60000; 
                }
            },
            setTimeDifference(timeDifferenceInMinutes, emailType){
                const emailTimeStamp = this.getTimeStemp(timeDifferenceInMinutes, emailType);
                const eventTimeStamp = this.project.startTime *1000;
                let delta = Math.abs(eventTimeStamp - emailTimeStamp) / 1000;
                this.sendTimeDays = Math.floor(delta / 86400);
                delta -= this.sendTimeDays * 86400;
                this.sendTimeHours = Math.floor(delta / 3600) % 24;
                delta -= this.sendTimeHours * 3600;
                this.sendTimeMinutes = Math.floor(delta / 60) % 60;
            }
        },
        watch:{
            email(value){
                if (value){
                    this.setTimeDifference(this.email.timeDifferenceInMinutes, this.email.emailType);
                    this.selectedEmailType = this.emailTypes.indexOf(this.email.emailType);
                    this.disableType = true;
                } else {
                    this.sendTimeDays= 0;
                    this.sendTimeHours= 0;
                    this.sendTimeMinutes= 0;
                    this.disableType= null;
                    this.selectedEmailType = null;
                }
            }
        },
        mounted(){
            if(this.project.type === 'hybrid'){
                this.emailLabels.push('On-site viewer reminder');
                this.emailTypes.push('on_site_reminder');
            }
        }
    };
</script>
