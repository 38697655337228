<template>
    <v-col md="2"
           :class="{'col-md-3': isClientOnly}"
    >
        <project-event-name v-if="showNameText"
                            :project-event="projectEvent"
        />
        <router-link v-if="showNameLinkToEventPage"
                     :to="{name: 'CreateEvent',
                           params:{ 'project_id': projectEvent._id, 'event_id': projectEvent.event._id}}"
        >
            <project-event-name :project-event="projectEvent" />
        </router-link>
        <router-link v-if="showNameLinkToStatistics"
                     :to="{name: 'CreateStatistics',
                           params:{ 'project_id': projectEvent._id, 'event_id': projectEvent.event._id}}"
        >
            <project-event-name :project-event="projectEvent" />
        </router-link>
    </v-col>
</template>

<script>
    import ProjectEventName from '@/components/controls/ProjectEventName';
    export default {
        name: 'ListEventLink',
        components: {ProjectEventName},
        props: {
            projectEvent: {
                type: Object,
                required: true
            },
            isClientOnly: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            showNameText() {
                return this.projectEvent.type === 'externalEmbed';
            },
            showNameLinkToStatistics() {
                return !this.showNameText && this.isClientOnly;
            },
            showNameLinkToEventPage() {
                return !this.showNameText && !this.isClientOnly;
            },
        }        
    };
</script>