import { $http } from '@/services/http';
import {
    buildSimpleQueryString,
    STATS_EVALUATIONS_LIST_URL,
} from '@/services/ApiService';

const PAGE_SIZE = 10;

const evaluationStore = {
    namespaced: true,
    state: {
        evaluations: {},
        pagination: {
            current: 1,
            total: 1,
            pageSize: PAGE_SIZE,
            itemCount: 0,
        }
    },
    getters: {

    },
    mutations: {
        setEvaluations: (state, payload) => state.evaluations = payload,
        setCurrentPage: (state, payload) => state.pagination.current = parseInt(payload),
        setPageTotal: (state, payload) => state.pagination.total = parseInt(payload),
        setPageSize: (state, payload) => state.pagination.pageSize = parseInt(payload),
        setItemCount: (state, payload) => state.pagination.itemCount = parseInt(payload),
    },
    actions: {
        async getEvaluations({ commit }, { projectId, evaluationId, params }) {
            const url = buildSimpleQueryString(STATS_EVALUATIONS_LIST_URL, params);
            return await $http.get(url.replace('{project_id}', projectId).replace('{evaluation_id}', evaluationId))
                .then(response => response.data)
                .then((data) => {
                    commit('setEvaluations', data.evaluations);
                    commit('setCurrentPage', data.pagination.current);
                    commit('setPageTotal', data.pagination.total);
                    commit('setPageSize', data.pagination?.pageSize || PAGE_SIZE);
                    commit('setItemCount', data.pagination?.itemCount || 0);
                });
        }
    }
};

export default evaluationStore;
