<template>
    <div class="d-flex">
        <div class="d-inline-block mr-9 mt-1">
            Enable mailings
        </div>
        <v-switch v-model="enableMailings"
                  hide-details
                  class="mt-0"
                  inset
                  :disabled="project.isArchived || isRegistrationTypeWithoutMailings"
        />
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

    export default {
        name: 'ToggleEnableMailings',
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['isRegistrationTypeWithoutMailings', 'mailingsEnabled']),
            enableMailings: {
                get() {
                    return this.mailingsEnabled;
                },
                set(value) {
                    this.setEnableMailings(value);
                    this.saveProject({
                        key: 'emailSettings.enableMailings',
                        value
                    });
                }
            }
        },
        methods: {
            ...mapMutations('projects', ['setEnableMailings']),
            ...mapActions('projects', ['saveProject']),
        }

    };
</script>

<style scoped>

</style>