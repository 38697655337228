import { $http } from '@/services/http';
import {
  buildSimpleQueryString, ARCHIVED_PROJECT_EVENT_LISTING
} from '@/services/ApiService';


const PAGE_SIZE = 20;

const ArchiveStore = {
    namespaced: true,
    state: {
        archivedProjects: {
            list: [],
            firstPassedId: null,
            firstUpcomingId: null
        },
        pagination: {
            current: 1,
            total: 1,
            pageSize: PAGE_SIZE,
            itemCount: 0,
        },
        listingHeader: {
            sorting: {
                targetId: 'startTime',
                direction: 'asc'
            },
            filtering: {
                openPopup: '',
                filters: {},
                subFilters: {}
            },
            filtersLoading: false,
        },
        previewKey: 0,
        previewLoading: false
    },
    getters: {
        filtersLoading: state => state.listingHeader.filtersLoading,
    },
    mutations: {
        setArchivedProjects: (state, payload) => state.archivedProjects.list = payload,
        setListFirstPassedId: (state, payload) => state.archivedProjects.firstPassedId = payload,
        setListFirstUpcomingId: (state, payload) => state.archivedProjects.firstUpcomingId = payload,
        
        setCurrentPage: (state, payload) => state.pagination.current = parseInt(payload),
        setPageTotal: (state, payload) => state.pagination.total = parseInt(payload),
        setPageSize: (state, payload) => state.pagination.pageSize = parseInt(payload),
        setItemCount: (state, payload) => state.pagination.itemCount = parseInt(payload),

        setSortingTargetId: (state, payload) => state.listingHeader.sorting.targetId = payload,
        setSortingDirection: (state, payload) => state.listingHeader.sorting.direction = payload,

        setHeaderOpenPopup: (state, payload) => state.listingHeader.filtering.openPopup = payload,
        setHeaderFilter: (state, payload) => state.listingHeader.filtering.filters = { ...state.listingHeader.filtering.filters, [payload.id]: payload.items },
        setHeaderSubFilter: (state, payload) => state.listingHeader.filtering.subFilters = { ...state.listingHeader.filtering.subFilters, [payload.id]: payload.content },
        setFiltersLoading: (state, payload) => state.listingHeader.filtersLoading = payload,
    },
    actions: {
        getArchivedProjects: ({ commit }, payload) => {
            const url = buildSimpleQueryString(ARCHIVED_PROJECT_EVENT_LISTING, payload);
            return $http.get(url)
                .then(response => response.data)
                .then((data) => {
                    commit('setArchivedProjects', data.projects);
                    commit('setListFirstPassedId', data.firstPassedId);
                    commit('setListFirstUpcomingId', data.firstUpcomingId);
                    commit('setCurrentPage', data.pagination.current);
                    commit('setPageTotal', data.pagination.total);
                    commit('setPageSize', data.pagination?.pageSize || PAGE_SIZE);
                    commit('setItemCount', data.pagination?.itemCount || 0);
                });
        },
       
    }
};

export default ArchiveStore;
