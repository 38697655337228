<template>
    <v-col md="1"
           class="logo-container"
    >
        <img v-if="projectEvent.company"
             class="logo"
             :src="projectEvent.company.logoUrl"
             :alt="projectEvent.company.name"
        >
    </v-col>
</template>

<script>
    export default {
        name: 'ListLogo',
        props: {
            projectEvent: {
                type: Object,
                required: true
            }
        }
    };
</script>

<style scoped>
.logo-container {
    padding-bottom: 0;
    padding-top: 0;
}

.logo {
    max-height: 50px;
    width: 100%;
    object-fit: scale-down;
}
</style>