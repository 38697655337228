<template>
    <div class="evaluation-star-rating"
         :title="value"
    >
        <span v-for="(star, index) in stars"
              :key="index"
              :class="{'star': true, 'star-filled': star === 1}"
        >
            <v-icon :color="getColor(star)">
                star
            </v-icon>
        </span>
    </div>
</template>

<script>
    export default {
        name: 'EvaluationStarRatingCell',
        props: {
            value: {
                type: Number,
                value: () => 0
            }
        },
        computed: {
            stars() {
                const ret = [];
                for (let i = 1; i <= this.value; i++) {
                    ret.push(1);
                }

                for (let i = this.value + 1; i <= 5; i++) {
                    ret.push(0);
                }

                return ret;
            },
        },
        methods: {
            getColor(star) {
                return star === 1 ? 'primary' : '';
            }
        }
    };
</script>

<style scoped>
    .evaluation-star-rating {
        min-width: 10rem;
    }
</style>