<template>
    <div class="filter-popup-container">
        <v-tooltip
            bottom
        >
            <template v-slot:activator="{on}">
                <v-btn
                    ref="button"
                    icon
                    small
                    class="filter-button pb-5"
                    :class="{'is-open': isOpen, 'is-filtered' : isFiltered}"
                    @click="openPopup"
                    v-on="on"
                >
                    <v-icon dark
                            small
                    >
                        mdi-filter-variant
                    </v-icon>
                </v-btn>
            </template>
            <span>{{ tooltipContentFilter }}</span>
        </v-tooltip>

        <v-card v-if="isOpen"
                class="filter-popup"
                :style="popupStyle"
        >
            <v-toolbar light
                       dense
                       flat
                       class="pr-2"
            >
                <v-toolbar-title>Filter</v-toolbar-title>
                <v-spacer />
                <v-btn icon
                       small
                       @click="closeMenu"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <slot />
        </v-card>
    </div>
</template>

<script>
    import {v4 as uuidv4} from 'uuid';
    import {mapState, mapMutations} from 'vuex';

    export default {
        name: 'IntakeListHeadFilterPopup',
        props: ['isFiltered', 'name'],
        data: () => ({
            top: 0,
            left: '',
            minWidth: 235,
        }),
        computed: {
            ...mapState('intake', ['listingHeader']),
            buttonId() {
                return `button-filter-${uuidv4()}`;
            },
            isOpen() {
                return this.listingHeader.filtering.openPopup === this.buttonId;
            },
            popupStyle() {
                return {
                    top: this.top + 'px',
                    left: this.left + 'px',
                    minWidth: this.minWidth + 'px'
                };
            },
            isCompany(){
                return this.name === 'Company';
            },
            tooltipContentFilter(){
                return this.isCompany ? 'Filter by company' : 'Filter by name';
            },
        },
        methods: {
            ...mapMutations('intake', ['setHeaderOpenPopup']),
            closeMenu() {
                this.setHeaderOpenPopup('');
            },
            openPopup() {
                const popup = this.$refs.button.$el;
                const popupPos = popup.getBoundingClientRect();
                this.top = popupPos.height;
                this.left = '';
                const r = document.querySelector('.list-header').getBoundingClientRect();
                if(popupPos.left + 220 > r.right) {
                    this.left = r.right - popupPos.left - 180;
                }
                this.isOpen ? this.setHeaderOpenPopup('') : this.setHeaderOpenPopup(this.buttonId);
            }
        }
    };
</script>

<style lang="scss" scoped>
.filter-popup-container {
  display: inline-block;
  width: auto;
}

.filter-popup {
  font-weight: 400;
  position: absolute;
  z-index: 5;
}
</style>
