<template>
    <v-row v-if="currentProjectId">
        <v-col class="pt-0">
            <v-row class=" tabs sticky-submenu">
                <v-col cols="12"
                       lg="6"
                       md="6"
                       sm="6"
                       class="py-0 col-height"
                >
                    <v-tabs :key="`stats-tab-${chatTabKey}`"
                            v-model="tab"
                            class="mb-6 "
                    >
                        <v-tab v-if="isRegistrationAllowed"
                               class="subtitle-1 text-capitalize tab-title mb-3"
                               :to="{ name: 'RegistrationStatistics' }"
                               replace
                               href="#registrations"
                        >
                            Registrations
                        </v-tab>
                        <v-tab class="subtitle-1 text-capitalize tab-title mb-3"
                               :to="{ name: 'InsightsStatistics' }"
                               replace
                               href="#insights"
                        >
                            Insights
                        </v-tab>
                        <v-tab v-if="!isExternalEmbed"
                               class="subtitle-1 text-capitalize tab-title mb-3"
                               :to="{ name: 'PollsStatistics' }"
                               replace
                               :disabled="!hasPolls"
                               href="#polls"
                        >
                            Polls
                        </v-tab>
                        <v-tab v-if="!isExternalEmbed"
                               class="subtitle-1 text-capitalize tab-title mb-3"
                               :to="{ name: 'EvaluationStatistics' }"
                               replace
                               :disabled="!hasEvaluations"
                               href="#evaluations"
                        >
                            Evaluations
                        </v-tab>
                        <v-tab v-if="!isExternalEmbed && isHybridProject"
                               class="subtitle-1 text-capitalize tab-title mb-3"
                               :to="{ name: 'OnSiteEvaluationStatistics' }"
                               replace
                               :disabled="!hasOnSiteEvaluations"
                               href="#onSiteEvaluations"
                        >
                            On-site Evaluations
                        </v-tab>
                        <v-tab
                            class="subtitle-1 text-capitalize tab-title mb-3"
                            :to="{ name: 'QuestionsStatistics' }"
                            replace
                            :disabled="!hasQuestions"
                            href="#questions"
                        >
                            Questions
                        </v-tab>
                        <v-tab v-if="!isExternalEmbed"
                               class="subtitle-1 text-capitalize tab-title mb-3"
                               :to="{ name: 'ChatStatistics' }"
                               replace
                               :disabled="!hasChat"
                               href="#chat"
                        >
                            Chat
                        </v-tab>
                        <v-tab v-if="showOndemandTab"
                               class="subtitle-1 text-capitalize tab-title mb-3"
                               :to="{ name: 'OndemandStatistics' }"
                               replace
                               href="#onDemand"
                        >
                            On Demand
                        </v-tab>
                        <v-tab class="subtitle-1 text-capitalize tab-title mb-3"
                               :to="{name: 'LinkTracking'}"
                               replace
                               href="#linkTracking"
                        >
                            Link tracking
                        </v-tab>
                        <v-tab class="subtitle-1 text-capitalize tab-title mb-3"
                               :to="{name: 'DeviceStats'}"
                               replace
                               href="#device"
                        >
                            Device
                        </v-tab>
                    </v-tabs>
                </v-col>
                <v-col v-if="!project.isArchived && !showEventSelector"
                       cols="12"
                       lg="6"
                       md="6"
                       sm="6"
                       class="py-0 col-height"
                >
                    <h3 class="font-weight-regular font-italic text-end pt-2 anonymize-text">
                        All information will be automatically anonymized after 180 days
                    </h3>
                </v-col>
                <v-col v-if="showEventSelector"
                       cols="12"
                       lg="6"
                       md="6"
                       sm="6"
                       class=" d-flex justify-end py-0 col-height"
                >
                    <v-select v-model="selectedEvent"
                              :items="project.events"
                              item-text="name"
                              :return-object="true"
                              class="session-selector pb-3 mt-n1"
                    />
                </v-col>
            </v-row>
            <v-tabs-items :value="recentTab">
                <v-tab-item v-if="isRegistrationAllowed"
                            :transition="false"
                            :reverse-transition="false"
                            value="registrations"
                >
                    <registration-statistics v-if="recentTab === 'registrations'"
                                             :key="selectedEvent._id"
                                             :selected-event="selectedEvent"
                    />
                </v-tab-item>
                <v-tab-item :transition="false"
                            :reverse-transition="false"
                            value="insights"
                >
                    <insight-statistics v-if="recentTab === 'insights'"
                                        :key="selectedEvent._id"
                                        :selected-event="selectedEvent"
                    />
                </v-tab-item>
                <v-tab-item v-if="!isExternalEmbed"
                            :transition="false"
                            :reverse-transition="false"
                            value="polls"
                >
                    <polls-statistics v-if="recentTab === 'polls'"
                                      :key="selectedEvent._id"
                                      :selected-event="selectedEvent"
                    />
                </v-tab-item>
                <v-tab-item v-if="!isExternalEmbed"
                            :transition="false"
                            :reverse-transition="false"
                            value="evaluations"
                >
                    <evaluation-statistics v-if="recentTab === 'evaluations'"
                                           :evaluation-id="evaluationId"
                    />
                </v-tab-item>
                <v-tab-item v-if="!isExternalEmbed && isHybridProject"
                            :transition="false"
                            :reverse-transition="false"
                            value="onSiteEvaluations"
                >
                    <evaluation-statistics v-if="recentTab === 'onSiteEvaluations'"
                                           :evaluation-id="onSiteEvaluationId"
                    />
                </v-tab-item>
                <v-tab-item
                    :transition="false"
                    :reverse-transition="false"
                    value="questions"
                >
                    <questions-statistics v-if="recentTab === 'questions'"
                                          :key="selectedEvent._id"
                                          :selected-event="selectedEvent"
                    />
                </v-tab-item>
                <v-tab-item v-if="!isExternalEmbed"
                            :transition="false"
                            :reverse-transition="false"
                            value="chat"
                >
                    <chat-statistics v-if="recentTab === 'chat'"
                                     :key="selectedEvent._id"
                                     :selected-event="selectedEvent"
                    />
                </v-tab-item>
                <v-tab-item :transition="false"
                            :reverse-transition="false"
                            value="ondemand"
                >
                    <ondemand-statistics v-if="recentTab === 'ondemand'"
                                         :key="selectedEvent._id"
                                         :selected-event="selectedEvent"
                    />
                </v-tab-item>
                <v-tab-item :transition="false"
                            :reverse-transition="false"
                            value="linkTracking"
                >
                    <link-tracking v-if="recentTab === 'linkTracking'"
                                   :key="selectedEvent._id"
                                   :selected-event="selectedEvent"
                    />
                </v-tab-item>
                <v-tab-item :transition="false"
                            :reverse-transition="false"
                            value="deviceStats"
                >
                    <device-stats v-if="recentTab === 'deviceStats'"
                                  :key="selectedEvent._id"
                                  :selected-event="selectedEvent"
                    />
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </v-row>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import RegistrationStatistics from '@/components/pages/Project/StatisticsTabs/RegistrationStatistics';
    import InsightStatistics from '@/components/pages/Project/StatisticsTabs/InsightStatistics';
    import PollsStatistics from '@/components/pages/Project/StatisticsTabs/PollsStatistics';
    import EvaluationStatistics from '@/components/pages/Project/StatisticsTabs/EvaluationStatistics';
    import OndemandStatistics from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/OndemandStatistics';
    import QuestionsStatistics from '@/components/pages/Project/StatisticsTabs/QuestionsStatistics';
    import ChatStatistics from '@/components/pages/Project/StatisticsTabs/ChatStatistics';
    import {
        REGISTRATION_TYPE_GENERATED,
        REGISTRATION_TYPE_REGISTRATION,
        REGISTRATION_TYPE_SPRINGER
    } from '@/services/RegistrationService';
    import DeviceStats from '@/components/pages/Project/StatisticsTabs/DeviceStats';
    import LinkTracking from '@/components/pages/Project/StatisticsTabs/LinkTracking.vue';


    export default {
        name: 'ProjectStatistics',
        components: {
            LinkTracking,
            DeviceStats,
            RegistrationStatistics,
            InsightStatistics,
            PollsStatistics,
            EvaluationStatistics,
            OndemandStatistics,
            QuestionsStatistics,
            ChatStatistics,
        },
        data() {
            return {
                recentTab: '',
                tab: window.location.href,
                selectedEvent: null,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', [
                'currentProjectId', 
                'registrationType',
                'isMultiSession',
                'isExternalEmbed',
                'isHybridProject',
                'evaluationId',
                'onSiteEvaluationId'
            ]),
            ...mapGetters('auth', ['isQuadiaLiveAdmin']),
            ...mapState('statistics', ['filledInEvaluations', 'pollsCount', 'contentRelatedQuestions']),
            ...mapGetters('statistics', ['hasPolls', 'hasEvaluations', 'hasQuestions', 'hasOnSiteEvaluations']),
            isRegistrationAllowed(){
                if (this.registrationType === REGISTRATION_TYPE_GENERATED) {
                    return this.project.enableGeneratedRegistrationStatistics;
                }
                return [REGISTRATION_TYPE_REGISTRATION, REGISTRATION_TYPE_SPRINGER].indexOf(this.registrationType) !== -1;
            },
            showEventSelector(){
                if (this.isMultiSession) {
                    if (this.$route.name === 'ChatStatistics') {
                        return true;
                    }

                    return this.$route.name !== EvaluationStatistics;
                }

                return false;
            },
            showOndemandTab() {
                return this.selectedEvent.onDemandActive;
            },
            hasChat(){
                if (this.tab.indexOf('statistics/chat') > -1) {
                    return true;
                }
                return this.selectedEvent.slidoEvent || this.selectedEvent.crispChatEvent;
            },
            chatTabKey() {
                return `chat-tab-${this.selectedEvent.slidoEvent || this.selectedEvent.crispChatEvent}`;
            }
        },
        methods:{
            ...mapActions('statistics', ['getOptionalMetrics']),
        },
        watch: {
            $route: {
                handler(to) {
                    if(to.path.indexOf('registrations') > -1) {
                        this.recentTab =  'registrations';
                    }
                    if(to.path.indexOf('insights') > -1) {
                        this.recentTab = 'insights';
                    }
                    if(to.path.indexOf('polls') > -1) {
                        this.recentTab = 'polls';
                    }
                    if(to.path.indexOf('evaluations') > -1) {
                        this.recentTab = 'evaluations';
                    }
                    if(to.path.indexOf('on-site-evaluations') > -1) {
                        this.recentTab = 'onSiteEvaluations';
                    }
                    if(to.path.indexOf('questions') > -1) {
                        this.recentTab = 'questions';
                    }
                    if(to.path.indexOf('chat') > -1) {
                        this.recentTab = 'chat';
                    }
                    if(to.path.indexOf('ondemand') > -1) {
                        this.recentTab = 'ondemand';
                    }
                    if(to.path.indexOf('link-tracking') > -1) {
                        this.recentTab = 'linkTracking';
                    }
                    if(to.path.indexOf('device-stats') > -1) {
                        this.recentTab = 'deviceStats';
                    }
                },
                immediate: true,
            },
            selectedEvent(value){
                this.getOptionalMetrics({
                    eventId: value._id, 
                    projectId: this.currentProjectId
                });
            }
        },
        created() {
            this.selectedEvent = this.project.events[0];
        },
        mounted() {
            if (this.$route.name === 'CreateStatistics') {
                this.$router.push({
                    name: this.isRegistrationAllowed ? 'RegistrationStatistics' : 'InsightsStatistics',
                    params: this.$route.params,
                    hash: this.$route.hash,
                    query: this.$route.query
                });
            }
        }
    };
</script>

<style scoped lang="scss">
.tabs {
    border-bottom: 4px solid;
    border-color: #f5f5f5;
    border-top: 20px solid white;
    background-color: white;
}
.tab-title {
    border-right: 2px solid;
    border-color: #f5f5f5;
}
.text-input {
    width: 22rem;
}

.anonymize-text{
    color: rgba(0,0,0,.6) !important;
}

.col-height{
    height: 48px !important

}

.session-selector{
    max-width: 20rem;
}

</style>
