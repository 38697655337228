<template>
    <div>
        <h2 class="font-weight-regular black--text">
            Reminder settings
        </h2>
        <v-row>
            <v-col cols="12">
                Email subject:
                <div class="black--text">
                    {{ value.reminderMail.subject }}
                </div>
            </v-col>
        </v-row>
        <v-divider />
        <v-row>
            <v-col cols="12">
                Email body text:
                <div class="black--text"
                     v-html="value.reminderMail.bodyText"
                />
            </v-col>
        </v-row>
        <v-divider />
        <v-row>
            <v-col cols="12">
                SMS from:
                <div class="black--text">
                    {{ value.reminderMail.smsFrom }}
                </div>
            </v-col>
        </v-row>
        <v-divider />
        <v-row>
            <v-col cols="12">
                SMS content:
                <div class="black--text">
                    {{ value.reminderMail.smsBody }}
                </div>
            </v-col>
        </v-row>
        <v-divider />
        <v-row>
            <v-col cols="12">
                SMS date:
                <div v-if="value.reminderMail.smsSendDateTime"
                     class="black--text"
                >
                    {{ smsSendDate }}
                </div>
                <div v-if="value.reminderMail.smsSendDateTime"
                     class="black--text"
                >
                    {{ smsSendTime }}
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import TimeService from '@/services/TimeService';

    export default {
        name: 'ReminderEmailDetails',
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        reminderMail: {
                            subject: '',
                            bodyText: '',
                            smsBody: '',
                            smsFrom: '',
                            sendDateTime: null,
                        },
                    };
                },
            },
        },
        computed: {
            smsSendDateTime() {
                return TimeService.dateFromUnixTimestamp(this.value.reminderMail?.smsSendDateTime || 0);
            },
            smsSendDate() {
                return TimeService.toDateString(this.smsSendDateTime);
            },
            smsSendTime() {
                return TimeService.toTimeString24(this.smsSendDateTime);
            }
        }
    };
</script>

<style scoped>
</style>
