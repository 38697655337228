import { get } from 'lodash';

class PwaStyle {
    constructor(data) {
        this.primaryColor = data?.primaryColor || 'rgba(255,255,255,1)';
        this.secondaryColor= data?.secondaryColor || 'rgba(255,255,255,1)';
        this.background = data?.background || '';
        this.backgroundUrl = data?.backgroundUrl || '';
        this.backgroundGradient = get(data, 'backgroundGradient', true);
    }
}

export default PwaStyle;
