<template>
    <div class="header-item">
        <h4 class="header-item-title">
            {{ name }}
        </h4>
        <div class="header-filters">
            <ArchiveListHeadFilterPopup v-if="filterItems"
                                        :is-filtered="isFilteredBy"
                                        :name="name"
                                        class="ml-1"
            >
                <ArchiveListHeadFilter
                    :items="filterItems"
                    :column-id="id"
                />
            </ArchiveListHeadFilterPopup>
            <list-header-sort :is-sorted-by="isSortedBy"
                              :sort-direction="sortDirection"
                              :tooltip-down="tooltipContentDown"
                              :tooltip-up="tooltipContentUp"
                              @sort="sort"
            />
        </div>
    </div>
</template>

<script>
    import {mapMutations, mapState} from 'vuex';
    import ArchiveListHeadFilterPopup from '@/components/pages/Archives/ArchiveListHeadFilterPopup';
    import ArchiveListHeadFilter from '@/components/pages/Archives/ArchiveListHeadFilter';
    import ListHeaderSort from '@/components/controls/list-sort/ListHeaderSort';

    export default {
        name: 'ArchiveProjectListHeaderItem',
        components: {ListHeaderSort, ArchiveListHeadFilter, ArchiveListHeadFilterPopup},
        props: ['id', 'name', 'filterItems'],
        computed: {
            ...mapState('archives', ['listingHeader']),
            isSortedBy() {
                return this.listingHeader.sorting.targetId === this.id;
            },
            sortDirection(){
                return this.listingHeader.sorting.direction;
            },
            isFilteredBy(){
                return this.listingHeader.filtering.filters && this.listingHeader.filtering.filters[this.id] && this.listingHeader.filtering.filters[this.id].length;
            },
            isStartTime(){
                return this.name === 'Start Time';
            },
            tooltipContentUp(){
                return this.isStartTime ? 'Sort by start time ascending' : 'A-Z';
            },
            tooltipContentDown(){
                return this.isStartTime ? 'Sort by start time descending' : 'Z-A';
            }

        },
        methods: {
            ...mapMutations('archives', ['setSortingDirection', 'setSortingTargetId']),
            sort(direction) {
                this.setSortingDirection(direction);
                this.setSortingTargetId(this.id);
            }
        }
    };
</script>

<style lang="scss" scoped>

button.v-icon {
  font-size: 18px !important;
}
</style>
