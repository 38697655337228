<template>
    <v-row justify="end"
           class="mt-5 mb-5"
           no-gutters
    >
        <v-col cols="12"
               lg="3"
               md="3"
               class="d-flex justify-end"
        >
            <h3 v-if="!isDownloading"
                class="font-weight-regular mr-3 mt-2 clickable-text"
                @click="downloadStatsChat"
            >
                Download chat report
            </h3>
            <v-btn v-if="!isDownloading"
                   icon
                   class="mr-5"
                   @click="downloadStatsChat"
            >
                <v-icon large>
                    mdi-file-download-outline
                </v-icon>
            </v-btn>
            <v-progress-circular v-if="isDownloading"
                                 indeterminate
            />
        </v-col>
    </v-row>
</template>

<script>
    import ProjectStatisticsService from '@/services/ProjectStatisticsService';

    export default {
        name: 'DownloadCrispChatStatsButton',
        props: {
            selectedEvent: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                isDownloading: false,
            };
        },
        methods: {
            async downloadStatsChat() {
                this.isDownloading = true;
                const link = document.createElement('a');
                link.href = await ProjectStatisticsService.exportCrispChats(this.selectedEvent);
                link.setAttribute('download', this.selectedEvent.name + '.xlsx');
                this.isDownloading = false;
                link.click();
            }
        }
    };
</script>

<style scoped>
.clickable-text {
    cursor: pointer;
}
</style>