<template>
    <v-dialog v-model="value"
              persistent
              max-width="600"
    >
        <v-card>
            <v-form ref="updateBreakoutFrom"
                    @submit.prevent="updateBreakoutGroup"
            >
                <v-card-title class="pb-0">
                    Edit breakout
                    <v-spacer />
                    <v-btn icon
                           @click="closeModal"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="internalBreakoutGroupName"
                                  filled
                                  class="session-input mt-4"
                                  style="max-width: none"
                                  label="Breakout group name"
                                  :rules="requiredField"
                                  hide-details="auto"
                    />
                    <v-divider class=" mt-8 mb-7" />
                    <div v-for="(name, index) in internalEventNames"
                         :key="`breakout-${index}`"
                    >
                        <v-text-field 
                            v-model="internalEventNames[index]"
                            filled
                            class="session-input"
                            style="max-width: none"
                            label="Breakout title"
                            :rules="requiredField"
                            hide-details="auto"
                        />
                        <div class="d-flex align-center  mt-3">
                            <span class="mr-10 pt-3">Max number of registrations</span>
                            <v-text-field
                                v-model="intervalEventRegistrationsCountLimit[index]"
                                class="number-input"
                                type="number"
                                min="0"
                                max="300"
                                :rules="[v => v <= 300 || 'Max 300 registrants']"
                            />
                        </div>
                        <v-divider class=" mt-3 mb-6" />
                    </div>
                    <v-row>
                        <v-col cols="12"
                               lg="6"
                               md="6"
                               sm="6"
                               class="py-0 d-inline-flex"
                        >
                            <h3 class="font-weight-regular mr-3 pt-6">
                                Start time
                            </h3>
                            <custom-time-picker v-model="breakoutGroupStartTime"
                                                :rules="requiredField"
                                                class="session-input"
                                                send-time-formatted
                                                @formattedStartTime="saveFormattedTime"
                            />
                        </v-col>
                        
                        <v-col cols="12"
                               lg="6"
                               md="6"
                               sm="6"
                               class="py-0 d-inline-flex"
                        >
                            <h3 class="font-weight-regular mr-3 pt-6">
                                Duration
                            </h3>
                            <v-text-field
                                v-model="internalDurationInMinutes"
                                type="number"
                                min="1"
                                class="session-input"
                                :rules="requiredFieldDuriation"
                            />
                            <h3 class="font-weight-regular ml-3 pt-6">
                                minutes
                            </h3>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="px-6 pb-4">
                    <v-spacer />
                    <v-btn color="primary"
                           :disabled="submitDisabled"
                           :loading="submitDisabled"
                           type="submit"
                    >
                        Update
                    </v-btn>
                    <v-btn @click="closeModal">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
    import { mapState } from 'vuex';
    import CustomTimePicker from '@/components/controls/CustomTimePicker';
    import { trim } from 'lodash';


    export default {
        name: 'UpdateBreakoutGroup',
        components: {CustomTimePicker},
        props: {
            value: {
                type: Boolean,
                default: () => false,
            },
            events: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                breakoutGroupStartTime: this.events[0].startTime,
                internalDurationInMinutes: this.events[0].durationInMinutes,
                internalBreakoutGroupName: this.events[0].breakoutGroupName,
                internalBreakoutGroup: this.events[0].breakout_group,
                submitDisabled: false,
                internalEventNames: this.events.map(e => e.name),
                intervalEventRegistrationsCountLimit: this.events.map(e => e.registrationsCountLimit),
                formattedStartTime: this.events[0].startTimeFormattedTime,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            requiredField(){
                return [(v) => !!trim(v) || 'Field is required'];
            },
            requiredFieldDuriation(){
                return [(v) => !!v && v > 0 || 'Value must be greater than 1'];
            }
        },
        methods: {
            saveFormattedTime(formattedStartTime){
                this.formattedStartTime = formattedStartTime;
            },
            updateBreakoutGroup() {
                this.submitDisabled = true;
                if (this.$refs.updateBreakoutFrom.validate()){
                    this.$emit('confirm', this.internalEventNames.map((e,index) => {
                        return {
                            breakoutGroupName: this.internalBreakoutGroupName,
                            breakout_group: this.internalBreakoutGroup,
                            durationInMinutes: Math.round(this.internalDurationInMinutes),
                            name: e,
                            registrationsCountLimit: this.intervalEventRegistrationsCountLimit[index],
                            startTime: this.breakoutGroupStartTime,
                            startTimeFormattedTime: this.formattedStartTime
                        };
                    }));
                    this.closeModal();
                }
                this.submitDisabled = false;
            },
            closeModal(){
                this.$emit('input', false);
            },
        },
    };
</script>

<style scoped>
.session-input{
    max-width: 10rem;
}
</style>
