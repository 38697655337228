import Vue from 'vue';
import Vuex from 'vuex';
import projectStore from '@/store/projects';
import dynamiBlockStore from '@/store/projects/dynamic-block.js';
import auth from '@/store/auth';
import SnackbarStore from '@/store/snackbar';
import IntakeStore from '@/store/intake';
import CompanyStore from '@/store/companies';
import EvaluationStore from '@/store/evaluations';
import UserStore from '@/store/users';
import ClientsStore from '@/store/clients';
import ProductionCompaniesStore from '@/store/production-companies';
import ArchiveStore from '@/store/archive';
import EvaluationFieldsStore from '@/store/evaluations/evaluationFields';
import ProjectListingStore from '@/store/projects/listing';
import StatisticsStore from '@/store/projects/statistics.js';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    projects: projectStore,
    'dynamic-block': dynamiBlockStore,
    auth: auth,
    snackbar: SnackbarStore,
    intake: IntakeStore,
    companies: CompanyStore,
    evaluations: EvaluationStore,
    users: UserStore,
    clients: ClientsStore,
    productionCompanies: ProductionCompaniesStore,
    archives: ArchiveStore,
    evaluationFields: EvaluationFieldsStore,
    projectLists: ProjectListingStore,
    statistics: StatisticsStore
  }
});
