import Speaker from '@/models/Speaker';
import { v4 as uuidv4 } from 'uuid';

export const getValidSpeakers = (speakers) => {
    const valid = [];
    for (let i in speakers) {
        if (speakers[i].name || '' !== '') {
            valid.push(speakers[i]);
        }
    }

    return valid;
};

export const createSpeaker = () => {
    return new Speaker({
        id: uuidv4(),
        name: '',
        link: '',
        description: '',
        title: '',
        avatar: undefined
    });
};