<template>
    <div>
        <generic-block-options :block="block"
                               :save-url="saveUrl"
                               :has-tab-titles="hasTabTitles"
        />
        <upcoming-events-editor v-model="upcomingEvents"
                                :save-url="saveUrl"
                                :block="block"
        />
        <label class="d-block mb-1">Content text:</label>
        <rich-text-editor
            v-model="content"
            class="rich-text-editor"
            :disabled="project.isArchived"
            :with-event-links="false"
        />
    </div>
</template>

<script>
    import GenericBlockOptions from './GenericBlockOptions';
    import RichTextEditor from '@/components/controls/RichTextEditor.vue';
    import { debounce } from 'lodash';
    import { mapActions, mapState } from 'vuex';
    import UpcomingEventsEditor from '@/components/controls/upcoming-events/UpcomingEventsEditor.vue';

    export default {
        name: 'UpcomingEvents',
        components: {
            UpcomingEventsEditor,
            RichTextEditor,
            GenericBlockOptions,
        },
        props: {
            block: {
                type: Object,
                required: true,
            },
            saveUrl: {
                type: String,
                required: true,
            },
            hasTabTitles: {
                type: Boolean,
                default: () => true,
            }
        },
        computed: {
            ...mapState('projects', ['project']),
            content: {
                get(){
                    return this.block.componentOptions?.content || '';
                },
                set: debounce(async function (value) {
                    this.block.componentOptions.content = value;
                    await this.saveBlocks(this.saveUrl);
                }, 400)
            },
            upcomingEvents: {
                get() {
                    return this.block.componentOptions?.upcomingEvents ?? [];
                },
                async set(value) {
                    this.block.componentOptions.upcomingEvents = value;
                    await this.saveBlocks(this.saveUrl);
                }
            }
        },
        methods: {
            ...mapActions('dynamic-block', ['saveBlocks']),
        }
    };
</script>
