<template>
    <v-card class="px-4 font-weight-medium list-header"
            elevation="0"
    >
        <v-row>
            <v-col md="2"
                   class="pt-4"
            >
                Id
            </v-col>
            <v-col md="1"
                   class="pt-4"
            >
                <intake-list-header-item id="type"
                                         name="Intake Type"
                                         :filter-items="intakeTypeFilterItems"
                />
            </v-col>
            <v-col md="2"
                   class="pt-4"
            >
                Client name
            </v-col>
            <v-col md="3"
                   class="pt-4"
            >
                Event title
            </v-col>
            <v-col md="2"
                   class="pt-4"
            >
                Last modified
            </v-col>
            <v-col md="2"
                   class="pt-4"
            >
                <div class="text-right">
                    Actions
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import IntakeListHeaderItem from '@/components/pages/Intake/IntakeListHeaderItem';
    export default {
        name: 'IntakeListHeader',
        components: {IntakeListHeaderItem},
        data() {
            return {
                intakeTypeFilterItems: [
                    {
                        id: 'quadia-live',
                        name: 'Quadia Live'
                    },
                    {
                        id: 'event-manager',
                        name: 'Event Manager'
                    }
                ]
            };
        }
    };
</script>

<style scoped>

</style>