<template>
    <div>
        <image-picker v-model="backgroundImage"
                      :max-file-size="maxFileSize"
                      :rules="backgroundRules"
                      :upload-url="uploadUrl"
                      form-data-key="background"
                      label="Background image"
        >
            <template v-slot:hint>
                <background-hint />
            </template>
        </image-picker>
    </div>
</template>

<script>
    import ImagePicker from '@/components/controls/ImagePicker.vue';
    import BackgroundHint from '@/components/controls/hints/BackgroundHint.vue';
    import { BACKGROUND_MAX_FILE_SIZE, backgroundValidationRules } from '@/services/fileValidation';
    import { BACKGROUND_UPLOAD_URL } from '@/services/ApiService';
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'BackgroundPicker',
        components: {BackgroundHint, ImagePicker},
        computed: {
            ...mapState('projects', ['project']),
            backgroundImage: {
                get() {
                    return {'url': this.backgroundUrl, 'key': this.background};
                },
                set(value) {
                    this.backgroundUrl = value.url;
                    this.background = value.key;
                },
            },
            backgroundUrl: {
                get() {
                    return this.project.style.backgroundUrl;
                },
                set(value) {
                    this.setBackgroundUrl(value);
                },
            },
            background: {
                get() {
                    return this.project.style.background;
                },
                set(value) {
                    this.setBackground(value);
                    this.saveProject({
                        key: 'style.background',
                        value,
                    });
                },
            },
            backgroundRules() {
                return backgroundValidationRules;
            },
            maxFileSize() {
                return BACKGROUND_MAX_FILE_SIZE;
            },
            uploadUrl() {
                return BACKGROUND_UPLOAD_URL;
            }
        },
        methods: {
            ...mapMutations('projects', [
                'setBackground',
                'setBackgroundUrl',
            ]),
            ...mapActions('projects', ['saveProject']),
        }
    };
</script>

<style scoped>

</style>