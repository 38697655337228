<template>
    <div>
        <v-row class="tabs sticky-submenu">
            <v-col cols="12"
                   class="pt-0 pr-0 col-height"
            >
                <v-tabs v-model="tab"
                        class="mb-6 "
                >
                    <v-tab class="subtitle-1 text-capitalize tab-title mb-3 pb-2"
                           href="#eventPage"
                    >
                        Event Page
                    </v-tab>
                    <v-tab class="subtitle-1 text-capitalize tab-title mb-3 pb-2"
                           href="#registrationForm"
                    >
                        Registration form
                    </v-tab>
                    <v-tab class="subtitle-1 text-capitalize tab-title mb-3 pb-2"
                           href="#confirmation"
                    >
                        Confirmation
                    </v-tab>
                    <v-tab v-if="isHybridProject"
                           class="subtitle-1 text-capitalize mb-3 pb-2"
                           href="#onSiteConfirmation"
                    >
                        On-Site Confirmation
                    </v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-tabs-items :value="tab">
                    <v-tab-item :transition="false"
                                :reverse-transition="false"
                                value="eventPage"
                                class="pt-5 tab-height"
                    >
                        <h3 class="mb-2 mt-5 font-weight-bold">
                            Event page editor
                        </h3>
                        <v-divider class="mb-10" />
                        <editor v-if="isRegistrationPageEditor"
                                class="px-4"
                                dynamic-blocks-kind="registration"
                        />
                    </v-tab-item>
                    <v-tab-item :transition="false"
                                :reverse-transition="false"
                                value="registrationForm"
                                class="tab-content tab-height"
                    >
                        <registration-form-editor />
                    </v-tab-item>
                    <v-tab-item :transition="false"
                                :reverse-transition="false"
                                value="confirmation"
                                class="tab-content tab-height"
                    >
                        <h3 class="mb-2 font-weight-bold">
                            Confirmation body text
                        </h3>
                        <v-divider class="mb-10" />
                        <div class="px-4">
                            <rich-text-editor
                                v-model="confirmationText"
                                class="rich-text-editor"
                                :disabled="project.isArchived"
                                :with-event-links="false"
                            />
                        </div>
                    </v-tab-item>
                    <v-tab-item v-if="isHybridProject"
                                :transition="false"
                                :reverse-transition="false"
                                value="onSiteConfirmation"
                                class="tab-content tab-height"
                    >
                        <h3 class="mb-2 font-weight-bold">
                            On-site confirmation body text
                        </h3>
                        <v-divider class="mb-10" />
                        <div class="px-4">
                            <rich-text-editor
                                v-model="onSiteConfirmationText"
                                class="rich-text-editor"
                                :disabled="project.isArchived"
                                :with-event-links="false"
                            />
                        </div>
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import
    { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import Editor from '@/components/pages/Project/EventPage/Editor';
    import RegistrationFormEditor from '@/components/pages/Project/RegistrationTab/RegistrationFormEditor.vue';

    export default {
        name: 'RegistrationPageEditor',
        components: {RegistrationFormEditor, RichTextEditor, Editor },
        data() {
            return {
                fieldValidationResult: {
                    valid: true,
                },
                tab: 'eventPage',
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['currentProjectId', 'isHybridProject']),
            isRegistrationPageEditor() {
                return this.$route.path.includes('registration');
            },
            confirmationText: {
                get() {
                    return this.project.registration.confirmationText;
                },
                async set(value) {
                    this.setConfirmationText(value);
                    await this.saveProject({
                        key: 'registration.confirmationText',
                        value,
                    });
                },
            },
            onSiteConfirmationText: {
                get() {
                    return this.project.registration.onSiteConfirmationText;
                },
                async set(value) {
                    this.setOnSiteConfirmationText(value);
                    await this.saveProject({
                        key: 'registration.onSiteConfirmationText',
                        value,
                    });
                },
            },
        },
        methods: {
            ...mapMutations('projects', [
                'setConfirmationText',
                'setOnSiteConfirmationText',
            ]),
            ...mapMutations('snackbar', ['showMessage', 'hideMessage']),
            ...mapActions('projects', ['saveRegistration', 'saveProject', 'saveProjectImmediate']),
        },
        watch: {
            tab() {
                window.scrollTo({top: 0, left: 0});
            }
        }
    };
</script>

<style scoped>
.tabs {
    border-bottom: 4px solid;
    border-color: #f5f5f5;
    border-top: 20px solid white;
    background-color: white;

}
.tab-title {
    border-right: 2px solid;
    border-color: #f5f5f5;
}

.rich-text-editor{
    height: 20rem;
}

.col-height{
    height: 48px !important
}

.tab-content{
    padding: 40px 12vw;
}

.tab-height{
    min-height: 70vh ;
}

</style>
