<template>
    <div v-if="items.length"
         class="px-2 d-flex flex-column filter-chips"
    >
        <div v-for="x in items"
             :key="`selection-${columnId}-${x.id}`"
             class="pr-4"
        >
            <v-tooltip bottom
                       :open-delay="750"
            >
                <template
                    v-slot:activator="{on}"
                >
                    <v-chip class="mx-2 my-1"
                            close
                            small
                            style="width:100%"
                            v-on="on"
                            @click:close="onItemCloseClick(x)"
                    >
                        <div class="chip-content-item">
                            {{ x.name }}
                        </div>
                    </v-chip>
                </template>
                <span>{{ x.name }}</span>
            </v-tooltip>
        </div>
        <v-divider class="mb-1" />
    </div>
</template>
<script>
    export default {
        name: 'ListFilterSelected',
        props: {
            items: {
                required: true,
                type: Array
            },
            columnId: {
                type: String,
                required: true
            }
        },
        methods: {
            onItemCloseClick(item) {
                this.$emit('input', item);
            }
        }
    };
</script>

<style lang="scss">
.filter-chips {
    .v-chip__content {
        width: 100%;

        .v-chip__close {
            position: absolute;
            right: 6px;
        }
    }
}

.chip-content-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
</style>