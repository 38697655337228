<template>
    <div class="d-flex">
        <h3
            v-if="!loadingExport"
            class="font-weight-regular mr-2 mt-2 clickable-text"
            @click="exportExcel"
        >
            Download ondemand registrations
        </h3>
        <h3 v-else
            class="font-weight-regular mr-2 mt-2"
        >
            Generating report
        </h3>
        <v-btn v-if="!loadingExport"
               icon
               class="mr-5"
               @click="exportExcel"
        >
            <v-icon large>
                mdi-file-download-outline
            </v-icon>
        </v-btn>
        <v-progress-circular v-else
                             indeterminate
                             class="mr-5"
        />
    </div>
</template>
<script>
    import { makeUrl } from '@/services/ApiService';
    import { $http } from '@/services/http';
    import { mapGetters } from 'vuex';

    export default {
        name: 'OnDemandRegistrationsExport',
        props: {
            selectedEvent: {
                type: Object,
                required: true,
            },
            dateRange: {
                type: Array,
                required: true
            },
        },
        data() {
            return {
                loadingExport: false,
            };
        },
        computed: {
            ...mapGetters('projects', ['currentProjectId']),
        },
        methods: {
            exportExcel() {
                const queries = [];
                if (this.dateRange[0] != null) {
                    queries.push('from=' + this.dateRange[0].toISOString());
                }

                if (this.dateRange[1] != null) {
                    queries.push('to=' + this.dateRange[1].toISOString());
                }

                const url = makeUrl(
                    '/projects/{project_id}/stats/aggregated-on-demand-registrations-export',
                    { projectId: this.currentProjectId }
                ) + '?' + queries.join('&');
                this.loadingExport = true;

                $http({
                    url,
                    method: 'GET',
                    responseType: 'blob',
                }).then((response) => {
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const hiddenElement = document.createElement('a');
                    hiddenElement.download =
                        'registrations-on-demand-' + this.selectedEvent._id + '.xlsx';
                    hiddenElement.href = url;
                    hiddenElement.click();
                });
                this.loadingExport = false;
            },
        },
    };
</script>

<style scoped>
.clickable-text:hover {
  cursor: pointer;
}
</style>