<template>
    <stats-card class="no-content-padding h13"
                :loading="loading"
    >
        <template v-slot:title>
            Starts per referrer
        </template>
        <template v-slot:content>
            <v-list>
                <div v-for="referrer in referrers"
                     :key="referrer.key"
                >
                    <v-list-item class="list-item"
                                 @click="copyToClipboard(referrer)"
                    >
                        <v-list-item-content>
                            <v-list-item-title class="item-title-starts">
                                <div class="title-flex">
                                    <div class="title-flex-item flex-key"
                                         :title="referrer.key"
                                    >
                                        {{ referrer.key }}
                                    </div>
                                    <div class="title-flex-item flex-number ml-2">
                                        <span class="number-color">{{ referrer.doc_count }}</span>
                                    </div>
                                </div>
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </div>
            </v-list>
        </template>
    </stats-card>
</template>
<script>
    import StatsCard from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/StatsCard.vue';
    import { fetchStatisticsApi } from '@/services/StatisticsService';
    import copy from 'copy-to-clipboard';
    import { toastSuccess } from '@/services/responseErrors';

    export default {
        name: 'OnDemandStartsPerReferrer',
        components: {StatsCard},
        props: {
            dateRange: {
                type: Array,
                required: true
            },
            videoId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                referrers: [],
            };
        },
        methods: {
            getReferrerStarts() {
                this.loading = true;
                fetchStatisticsApi('/api/videos/starts-per-referrer', this.dateRange, this.videoId).then(response => {
                    const buckets = response.data;
                    this.referrers = [];
                    const currentBucket = buckets.find(bucket => bucket.key === this.videoId);
                    if (!currentBucket) {
                        return;
                    }

                    this.referrers = currentBucket.referrer_aggr?.buckets || [];
                }).finally(() => {
                    this.loading = false;
                });
            },
            copyToClipboard(item) {
                copy(item.key, {format: 'text/plain'});
                toastSuccess('Referrer URL copied to clipboard!');
            },
        },
        mounted() {
            this.getReferrerStarts();
        }
    };
</script>

<style scoped lang="scss">
.item-title-starts {
    font-size: 0.7rem;
    @media (max-width: 1500px) {
        font-size: 0.65rem
    }
    @media (max-width: 1400px) {
        font-size: 0.55rem
    }

    @media (max-width: 1300px) {
        font-size: 0.45rem
    }

    @media (max-width: 959px) {
        font-size: 0.6rem
    }
}

.list-item {
    min-height: 36px;
}
</style>