<template>
    <editor :key="editorKey"
            v-model="content"
            api-key="no-api-key"
            :init="editorInit"
            :disabled="disabled"
            :class="{'disabledStyle': disabled}"
    />
</template>

<script>
// eslint-disable-next-line no-unused-vars
    import tinymce from 'tinymce/tinymce';
    import { addCustomBold } from '@/plugins/CustomBold';
    import 'tinymce/themes/silver/theme';
    import 'tinymce/icons/default';
    import 'tinymce/plugins/autolink';
    import 'tinymce/plugins/lists';
    import 'tinymce/plugins/link';
    import 'tinymce/plugins/anchor';
    import 'tinymce/plugins/visualblocks';
    import 'tinymce/plugins/searchreplace';
    import 'tinymce/plugins/wordcount';

    // Plugins
    import 'tinymce/plugins/paste';
    import 'tinymce/plugins/noneditable';

    import Editor from '@tinymce/tinymce-vue';
    import {mapGetters, mapState} from 'vuex';

    import {getFont} from '@/services/FontService.js';
    import { addContentVariables, toNonEditable, toPlaceholder } from '@/services/EditorContentVariables';
    import { colorDarken, splitColorsRGBA } from '@/services/ColorService';
    import { getEditorLinkList } from '@/services/EditorLinkService';
    import {
        EDITOR_DEFAULT_FONT_FAMILY,
        EDITOR_INIT_TOOLBAR_WITH_FONT_SELECTOR,
        getEditorConfig, getEditorInitFontStyle
    } from '@/services/EditorConfigService';

    export default {
        name: 'RichTextEditor',
        props: {
            value: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: () => false,
            },
            withAgenda: {
                type: Boolean,
                default: false
            },
            withEventLinks: {
                type: Boolean,
                default: true
            },
            withOnSiteStartTime: {
                type: Boolean,
                default: false
            },
            withLocationAddress: {
                type: Boolean,
                default: false
            },
            withFontSelector:{
                type: Boolean,
                default: true
            }
        },
        components: { Editor },
        data() {
            return {
                editorKey: 0,
                content: this.value,
                editorInit: getEditorConfig((ed) => {
                    addCustomBold(ed);
                    addContentVariables(ed, this.withOnSiteStartTime, this.withLocationAddress, this.withAgenda);
                })
            };
        },
        watch: {
            content(val) {
                if(this.editorKey !== 0){
                    this.$emit('input', toPlaceholder(val, this.linkColor));
                }
            }
        },
        computed:{
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['isMultiSession']),
            linkColor(){
                const {r,b,g} = splitColorsRGBA(this.project.style.primaryColor);
                return colorDarken(r,g,b);
            }
        },
        mounted(){
            this.editorInit.link_list = getEditorLinkList(
                this.project.language,
                this.withEventLinks,
                this.project.events,
                this.isMultiSession,
                this.project.eventTitle
            );

            if(this.value){
                this.content = toNonEditable(this.value);
            }

            this.editorInit.content_style = getEditorInitFontStyle(EDITOR_DEFAULT_FONT_FAMILY);

            if(this.withFontSelector){
                this.editorInit.toolbar = EDITOR_INIT_TOOLBAR_WITH_FONT_SELECTOR;
                this.editorInit.content_style = getEditorInitFontStyle(getFont(this.project.style.fontFamily));
            }
            this.editorKey++;
        }
    };
</script>

<style lang="scss">
@import "/assets/tinymce/skins/ui/oxide/skin.min.css";
@import "/assets/tinymce/skins/ui/oxide/content.min.css";
@import "/assets/tinymce/skins/content/default/content.min.css";
.disabledStyle{
    opacity: 0.5;
    pointer-events: none;
    polygon{
        display: none;
    }
}

.tox .tox-editor-header {
    z-index: 0 !important;
}

.tox-tinymce--disabled {
    opacity: 0.5
}

.tox-toolbar__group {
    border: 0 none transparent !important;
    padding: 0 0.5rem !important;
}
</style>
