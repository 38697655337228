<script>
    import { Pie } from 'vue-chartjs';

    export default {
        name: 'CustomPieChart',
        extends: Pie,
        props: {
            data: {
                type: Object,
                required: true,
            },
            options: {
                type: Object,
                default: () => {},
            },
            plugins: {
                type: Array,
                default: () => []
            }
        },
        mounted() {
            if (this.options.length > 0) {
                this.options.forEach(o => this.addPlugin(o));
            }
            this.renderChart(this.data, this.options);
        },
    };
</script>

<style>
</style>