<template>
    <div>
        <generic-block-options :block="block"
                               :save-url="saveUrl"
                               :has-tab-titles="hasTabTitles"
        />
        <label class="d-block mb-1">Content text:</label>
        <rich-text-editor
            v-model="content"
            class="rich-text-editor"
            :disabled="project.isArchived"
            :with-event-links="false"
        />
        <v-divider class="mt-2" />
        <image-picker v-model="blockImage"
                      :max-file-size="maxFileSize"
                      :rules="imageRules"
                      :upload-url="uploadUrl"
                      label="Content image"
        >
            <template v-slot:hint> 
                <background-hint />
            </template>
        </image-picker>
        <v-divider class="my-2" />
        <v-select
            v-model="alignment"
            class="mb-6"
            :items="alignmentOptions"
            item-text="label"
            item-value="value"
            label="Image alignment"
            :disabled="project.isArchived"
            hide-details=""
        />
        <v-divider class="my-2" />
        <div class="d-flex mb-4">
            <label class="mt-5 mr-4">CTA button:</label>
            <v-switch v-model="enableCtaButton"
                      inset
                      hide-details
            />
        </div>
        <v-text-field v-if="enableCtaButton"
                      v-model="ctaButtonText"
                      label="CTA button text"
        />
        <v-text-field v-if="enableCtaButton"
                      v-model="ctaButtonUrl"
                      label="CTA button URL"
                      :rules="urlRules"
        />
        <v-select
            v-model="ctaButtonAlignment"
            class="mb-6"
            :items="ctaButtonAlignmentOptions"
            item-text="label"
            item-value="value"
            label="Cta button alignment"
            :disabled="project.isArchived"
            hide-details=""
        />
    </div>
</template>

<script>
    import GenericBlockOptions from './GenericBlockOptions';
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import ImagePicker from '@/components/controls/ImagePicker';
    import BackgroundHint from '@/components/controls/hints/BackgroundHint';
    import { mapActions, mapState } from 'vuex';
    import { DYNAMIC_BLOCK_IMAGE_UPLOAD_URL } from '@/services/ApiService';
    import {
        DYNAMIC_BLOCK_IMAGE_MAX_FILE_SIZE,
        dynamicBlockImageValidationRules,
    } from '@/services/fileValidation';
    import { debounce } from 'lodash';
    import {isHttpUri, isHttpsUri} from 'valid-url';

    export default {
        name: 'ImageContentBlock',
        components: {
            GenericBlockOptions,
            RichTextEditor,
            ImagePicker,
            BackgroundHint
        },
        props: {
            block: {
                type: Object,
                required: true
            },
            saveUrl: {
                type: String,
                required: true,
            },
            hasTabTitles: {
                type: Boolean,
                default: () => true,
            }
        },
        data(){
            return {
                alignmentOptions: [
                    {
                        value: 'image-left',
                        label: 'Image left'
                    },
                    {
                        value: 'image-top',
                        label: 'Image top'
                    },
                    {
                        value: 'image-right',
                        label: 'Image right'
                    },
                    {
                        value: 'image-bottom',
                        label: 'Image bottom'
                    },
                    {
                        value: 'image-top-full-width',
                        label: 'Image top full width'
                    },                    
                    {
                        value: 'image-bottom-full-width',
                        label: 'Image bottom full width'
                    }
                ],
                ctaButtonAlignmentOptions:[
                    {
                        value: 'flex-start',
                        label: 'Left'
                    },
                    {
                        value: 'center',
                        label: 'Center'
                    },                    
                    {
                        value: 'flex-end',
                        label: 'Right'
                    }                    
                ]
            };
        },
        computed:{
            ...mapState('projects', ['project']),
            urlRules(){
                return [v => (isHttpUri(v) !== undefined || isHttpsUri(v) !== undefined  || v.length === 0) || 'URL is invalid'];
            },
            content: {
                get(){
                    return this.block.componentOptions?.content || '';
                },
                set: debounce(async function (value) {
                    this.block.componentOptions.content = value;
                    await this.saveBlocks(this.saveUrl);
                }, 400),
            },
            blockImage:{
                get() {
                    return {'url': this.imageUrl, 'key': this.imagePath};
                },
                set(value) {
                    this.imageUrl = value.url;
                    this.imagePath = value.key;
                    if(value.url){
                        this.getMeta(value.url);
                    }
                },
            },
            imageUrl: {
                get() {
                    return this.block.componentOptions.image?.url || '';
                },
                set(value) {
                    this.block.componentOptions.image.url = value;
                },
            },
            imagePath: {
                get() {
                    return this.block.componentOptions.image?.path || '';
                },
                set(value) {
                    if(!value){
                        this.block.componentOptions.image.width = 0;
                        this.block.componentOptions.image.height = 0;
                    }
                    this.block.componentOptions.image.path = value;
                    this.saveBlocks(this.saveUrl);
                },
            },
            alignment:{
                get(){
                    return this.block.componentOptions?.alignment || 'image-left';
                },
                set(value){
                    this.block.componentOptions.alignment = value;
                    this.saveBlocks(this.saveUrl);
                }
            },
            enableCtaButton:{
                get(){
                    return this.block.componentOptions?.ctaButton?.enableCtaButton || false ;
                },
                set(value){
                    this.block.componentOptions.ctaButton.enableCtaButton = value;
                    this.saveBlocks(this.saveUrl);
                }
            },
            ctaButtonText:{
                get(){
                    return this.block.componentOptions?.ctaButton?.ctaButtonText || '';
                },
                set(value){
                    this.block.componentOptions.ctaButton.ctaButtonText = value;
                    this.saveBlocks(this.saveUrl);
                }
            },
            ctaButtonUrl:{
                get(){
                    return this.block.componentOptions?.ctaButton?.ctaButtonUrl || '';
                },
                set(value){
                    if(this.isUrlValid(value)){
                        this.block.componentOptions.ctaButton.ctaButtonUrl = value;
                        this.saveBlocks(this.saveUrl);
                    }
                }
            },
            ctaButtonAlignment:{
                get(){
                    return this.block.componentOptions?.ctaButton?.alignment || 'flex-start';
                },
                set(value){
                    this.block.componentOptions.ctaButton.alignment = value;
                    this.saveBlocks(this.saveUrl);
                }
            },
            imageRules(){
                return dynamicBlockImageValidationRules;
            },
            maxFileSize(){
                return DYNAMIC_BLOCK_IMAGE_MAX_FILE_SIZE;
            },
            uploadUrl(){
                return DYNAMIC_BLOCK_IMAGE_UPLOAD_URL;
            }
        },
        methods: {
            ...mapActions('dynamic-block', ['saveBlocks']),
            getMeta(url){   
                const img = new Image();
                img.addEventListener('load', () => {
                    this.block.componentOptions.image.width = img.width;
                    this.block.componentOptions.image.height= img.height;
                    this.saveBlocks(this.saveUrl);
                });
                img.src = url;
            },
            isUrlValid(v){
                return (isHttpUri(v) !== undefined || isHttpsUri(v) !== undefined  || v.length === 0) || false;
            }
        }
    };
</script>