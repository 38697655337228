<template>
    <div>
        <v-row>
            <v-col cols="12"
                   md="6"
            >
                <h2 class="font-weight-regular black--text mb-2">
                    Live page details
                </h2>
                <v-divider class="mb-2" />
                <div>
                    Title:
                    <div class="black--text">
                        {{ value.live.title }}
                    </div>
                </div>
                <v-divider class="my-2" />
                <div>
                    Body text:
                    <div class="black--text"
                         v-html="value.live.bodyText"
                    />
                </div>
            </v-col>
            <v-col cols="12"
                   md="6"
            >
                <h3 class="font-weight-regular black--text mb-2">
                    Options:
                </h3>
                <v-divider class="mb-2" />
                <div>
                    Polls:
                    <span class="black--text">{{
                        value.live.options.poll ? "Yes" : "No"
                    }}</span>
                </div>
                <div>
                    Chat:
                    <span class="black--text">{{
                        value.live.options.chat ? "Yes" : "No"
                    }}</span>
                </div>

                <h3 class="font-weight-regular black--text mt-3 mb-2">
                    External page link:
                </h3>
                <v-divider class="mb-2" />
                <div>
                    Text:
                    <div class="black--text">
                        {{ value.live.externalPageLink.text }}
                    </div>
                </div>
                <div>
                    Url:
                    <a class="black--text">
                        {{ value.live.externalPageLink.url }}
                    </a>
                </div>
                <h3 class="font-weight-regular black--text mt-3 mb-2">
                    Downloadable Content:
                </h3>
                <v-divider class="mb-2" />
                <div>
                    <a
                        v-if="value.live.downloadableContent"
                        :href="value.live.downloadableContent"
                        download
                        target="_blank"
                    >download</a>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'LivePageDetails',
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        live: {
                            title: '',
                            bodyText: '',
                            options: {
                                poll: false,
                                chat: false,
                            },
                            downloadableContent: '',
                            externalPageLink: {
                                text: '',
                                url: '',
                            },
                        },
                    };
                },
            },
        },
    };
</script>

<style scoped>
</style>