<template>
    <div class="flex-fill">
        <h4 class="font-weight-medium mt-3 ml-3">
            Registrations over time
        </h4>
        <div class="d-flex justify-center ">
            <custom-chart :key="chartKey"
                          :width="700"
                          :data="chartData"
                          :options="chartOptions"
                          :plugins="chartPlugins"
            />
        </div>
    </div>
</template>
<script>
    import CustomChart from '@/components/pages/Project/Elements/CustomChart';
    import {
        CHART_JS_OPTIONS_REGISTRATION,
        CHART_JS_PLUGIN_GRACE_CUSTOM_SCALE,
        getRegistrationChartDataTemplate
    } from '@/services/ChartService';
    import { mapGetters } from 'vuex';
    import ProjectStatisticsService from '@/services/ProjectStatisticsService';
    export default {
        name: 'RegistrationsOverTime',
        components: {CustomChart},
        data() {
            return {
                chartData: this.getChartDataTemplate(),
            };
        },
        computed: {
            ...mapGetters('projects', ['currentProjectId','isHybridProject']),
            chartOptions() {
                return CHART_JS_OPTIONS_REGISTRATION;
            },
            chartKey() {
                let value = this.chartData.labels.join() + this.chartData.datasets[0].data.join();
                if (this.isHybridProject && this.chartData.datasets.length > 1) {
                    value +=  + this.chartData.datasets[1].data.join();
                }

                return value;
            },
            chartPlugins() {
                return [CHART_JS_PLUGIN_GRACE_CUSTOM_SCALE];
            },
        },
        methods: {
            loadData() {
                if (!this.currentProjectId) {
                    return;
                }
                ProjectStatisticsService.getRegistrationsOverTime(this.currentProjectId).then((data) => {
                    if (data) {
                        const chartData = this.getChartDataTemplate();
                        chartData.labels = data.labels;
                        chartData.datasets[0].data = Object.values(data.values.total);
                        if (this.isHybridProject) {
                            chartData.datasets[1].data = Object.values(data.values.onlineViewers);
                            chartData.datasets[2].data = Object.values(data.values.onSiteViewers);
                        }

                        chartData.datasets = chartData.datasets.reverse();

                        this.chartData = chartData;
                    }
                });
            },
            getChartDataTemplate()
            {
                return getRegistrationChartDataTemplate(this.isHybridProject);
            }
        },
        mounted() {
            this.loadData();
        }
    };
</script>

<style scoped>

</style>