<template>
    <span class="color-display d-inline-flex flex-row">
        <span class="color-square mr-4"
              :style="{'background-color': value}"
        />
        {{ value }}
    </span>
</template>

<script>
    export default {
        name: 'ColorDisplay',
        props: ['value']
    };
</script>

<style scoped>
.color-display {
    justify-content: flex-start;
}

.color-square {
    width: 1.2rem;
    height: 1.2rem;
    box-shadow: 0 0 2px 1px rgba(0,0,0, 0.3);
}
</style>