import { $http } from '@/services/http';

export const fetchStatisticsApi = function (path, dateRange, videoId) {
    const queries = [
        '?videos[]=' + videoId
    ];

    if (dateRange[0] != null) {
        queries.push('from=' + dateRange[0].toISOString());
    }

    if (dateRange[1] != null) {
        queries.push('to=' + dateRange[1].toISOString());
    }

    let url = process.env.VUE_APP_STATS_API_HOST + path +  queries.join('&');
    return $http.get(url);
};

export const getTimeAsHoursMinutes = function (timeInMilliseconds) {
    const hourDivider = 3600 * 1000;
    const minutesDivider = 60 * 1000;

    const hours = Math.floor(timeInMilliseconds / hourDivider);
    // show last fragment of a minute as a full minute
    const minutes = Math.ceil((timeInMilliseconds % hourDivider) / minutesDivider);

    return `${leadingZero(hours)}:${leadingZero(minutes)}`;
};
export const leadingZero = function (value) {
    return `${value}`.length === 1 ? `0${value}` : value;
};