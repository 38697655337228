<template>
    <v-container fluid
                 class="pt-0"
    >
        <v-row justify="space-around">
            <v-col cols="12"
                   md="12"
                   sm="12"
                   lg="12"
                   class="pt-0"
            >
                <v-card v-if="!loading"
                        elevation="0"
                        class="mx-4 content-card pt-0"
                >
                    <v-tabs v-model="tab"
                            elevation="0"
                            class="sticky-tabs text-right"
                            background-color="#F5F5F5"
                            active-class="active-tab"
                            show-arrows
                    >
                        <template v-slot:default>
                            <v-tab replace
                                   class="text-capitalize"
                            >
                                <v-icon class="mr-1">
                                    mdi-cog
                                </v-icon>
                                Project settings
                            </v-tab>
                            <v-tab
                                class="text-capitalize"
                                replace
                                :disabled="true"
                            >
                                <v-icon class="mr-1">
                                    mdi-format-list-bulleted
                                </v-icon>
                                Registration page
                            </v-tab>
                            <v-tab replace
                                   class="text-capitalize"
                                   :disabled="true"
                            >
                                <v-icon class="mr-1">
                                    mdi-email-edit
                                </v-icon>
                                Mailings
                            </v-tab>
                            <v-tab replace
                                   class="text-capitalize"
                                   :disabled="true"
                            >
                                <v-icon class="mr-1">
                                    mdi-television-play
                                </v-icon>
                                Event Pages
                            </v-tab>
                            <v-tab replace
                                   class="text-capitalize"
                                   :disabled="true"
                            >
                                <v-icon class="mr-1">
                                    mdi-chart-bar
                                </v-icon>
                                Statistics
                            </v-tab>
                            <h3 class="font-weight-regular grey--text project-title ">
                                {{ eventTitle }}
                            </h3>
                        </template>
                    </v-tabs>

                    <v-divider />
                    <v-card-text elevation="2"
                                 class="pt-0"
                    >
                        <v-tabs-items :value="0"
                                      class="pt-0"
                        >
                            <v-tab-item :transition="false"
                                        :reverse-transition="false"
                            />
                            <create-project-details @eventTitleChanged="eventTitleChanged" />
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
                <v-progress-linear v-else
                                   indeterminate
                                   class="mt-5"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapActions, mapMutations} from 'vuex';
    import CreateProjectDetails from '@/components/pages/Project/CreateProjectDetails';

    export default {
        name: 'CreateProject',
        components: {CreateProjectDetails},
        data() {
            return {
                loading: true,
                tab: window.location.href,
                recentTab: 0,
                eventTitle: '',
            };
        },
        watch: {},
        methods: {
            ...mapActions('productionCompanies', ['getProductionCompanies']),
            ...mapMutations('companies', ['setCompany']),
            ...mapMutations('projects', ['setProject']),
            eventTitleChanged(value){
                this.eventTitle=value;
            }
        },
        async created() {
            await this.getProductionCompanies();
            this.loading = false;
        },
        mounted() {
            this.setCompany([]);
            this.setProject({});
        }
    };
</script>

<style scoped lang="scss">

.active-tab {
  background-color: white;
}

.nav-back {
  position: absolute;
  z-index: 2;
  display: inline-block;
  left: -40px;
  @media (max-width: 1260px) {
    left: -20px;
  }
}

.project-title {
  margin-left: auto;
  margin-top: 10px;
}

</style>
