<template>
    <div class="mt-10 d-flex flex-column">
        <v-tooltip bottom>
            <template v-slot:activator="{ on }">
                <v-btn 
                    color="primary"
                    :disabled="disabled"
                    class=" mb-5 align-self-center"
                    v-on="on"
                    @click="addField"
                >
                    Add new field
                    <v-icon class="ml-3">
                        add
                    </v-icon>
                </v-btn>
            </template>
            <span>Add</span>
        </v-tooltip>
        <v-row class="px-4">
            <v-col cols="6"
                   lg="6"
                   class="pl-15"
            >
                <h4 class="caption">
                    Question
                </h4>
            </v-col>
            <v-col cols="6"
                   lg="4"
                   class="pl-0 ml-n8"
            >
                <h4 class="caption">
                    Type
                </h4>
            </v-col>
            <v-col cols="6"
                   lg="2"
                   class="pl-12"
            >
                <h4 class="caption">
                    Required?
                </h4>
            </v-col>
        </v-row>
        <evaluation-field-row
            :key="`evaluation-fields-${updateKey}`"
            :value="fields"
            :disabled="disabled"
            class="px-4"
            @input="emitFields"
        />
    </div>
</template>

<script>
    import { FIELD_TYPE_TEXT } from '@/services/FieldService';
    import EvaluationField from '@/models/EvaluationField';
    import EvaluationFieldRow from '@/components/controls/field-editor/EvaluationFieldRow';
    import { mapToEvaluationFields } from '@/services/EvaluationFieldService';

    export default {
        name: 'EvaluationFieldsEditor',
        components: { EvaluationFieldRow },
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            disabled: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                updateKey: 0,
                fields: this.value.map((field) => new EvaluationField(field)), 
            };
        },
        methods: {
            getFieldName(name) {
                let newName = name;
                let nameCounter = 0;
                let found = true;
                while (found) {
                    const field = this.fields.find((f) => f.name === newName);
                    if (!field) {
                        return newName;
                    }

                    ++nameCounter;
                    newName = name + '_' + nameCounter;
                }
            },
            addField() {
                this.fields.push(
                    new EvaluationField({
                        id: undefined,
                        type: FIELD_TYPE_TEXT,
                        required: false,
                        question: this.getFieldName('new_question'),
                    })
                );
                ++this.updateKey;
                this.emitFields(this.fields);
            },
            emitFields: function (fields) {
                this.fields = [...fields];
                this.$emit('input', mapToEvaluationFields(fields));
            },
        },
    };
</script>
