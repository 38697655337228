<template>
    <favicon-picker :upload-url="uploadUrl"
                    :favicon-url="faviconUrl"
                    :favicon="favicon"
                    @input="faviconChanged"
    />
</template>
<script>
    import FaviconPicker from '@/components/controls/FaviconPicker.vue';
    import { mapActions, mapMutations, mapState } from 'vuex';
    import { COMPANY_FAVICON_UPLOAD_URL } from '@/services/ApiService';

    export default {
        name: 'CompanyFavicon',
        components: {FaviconPicker},
        computed: {
            ...mapState('companies', ['company']),
            uploadUrl() {
                return COMPANY_FAVICON_UPLOAD_URL;
            },
            faviconUrl: {
                get() {
                    return this.company.faviconUrl;
                },
                set(value) {
                    this.setFaviconUrl(value);
                }
            },
            favicon: {
                get() {
                    return this.company.favicon;
                },
                set(value) {
                    this.setFavicon(value);
                }
            }
        },
        methods: {
            ...mapActions('companies', ['saveCompany']),
            ...mapMutations('companies', ['setFavicon', 'setFaviconUrl']),
            faviconChanged(data) {
                this.favicon = data.favicon;
                this.faviconUrl = data.faviconUrl;
                this.saveCompany({
                    key: 'favicon',
                    value: data.favicon,
                });
            }
        }
    };
</script>

<style scoped>

</style>