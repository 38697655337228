export const API_URL = `${process.env.VUE_APP_API_URL}/api`;
export const AUTOSAVE_PROJECT_URL = `${API_URL}/projects/{project_id}/autosave`;
export const APPLY_COMPANY_BRANDING_URL = `${API_URL}/projects/{project_id}/apply-company-branding`;
export const PROJECT_EVENT_LISTING_URL = `${API_URL}/projectevents`;
export const PROJECT_EVENT_LISTING_FOR_ASSIGNMENT_URL = `${API_URL}/projectevents/for-assignment`;
export const PROJECT_EVENT_SHOW_URL = `${API_URL}/projectevents/{event_id}`;
export const PROJECT_EVENT_LIST_CREATED_BY_URL = `${API_URL}/projectevents/created-by`;
export const PROJECTS_URL = `${API_URL}/projects`;
export const SINGLE_PROJECT_URL = `${API_URL}/projects/{project_id}`;
export const REGISTRATION_URL = `${API_URL}/projects/{project_id}/registration-settings`;
export const GENERATE_REGISTRATION_LINKS_URL = `${API_URL}/projects/{project_id}/generate-registrations-list`;
export const ARCHIVED_PROJECT_EVENT_LISTING = `${API_URL}/projectevents/archived`;
export const ARCHIVED_PROJECT_LISTING_CREATED_BY = `${API_URL}/projectevents/archived/created-by`;
export const ARCHIVE_PROJECT = `${API_URL}/projects/{project_id}/archive`;

// Emails
export const EMAIL_TEST_REGISTRATION_CONFIRMATION = `${API_URL}/projects/{project_id}/email-settings/registration-confirmation/test`;
export const EMAIL_PREVIEW_REGISTRATION_CONFIRMATION = `${API_URL}/projects/{project_id}/email-settings/registration-confirmation/render`;
export const EMAIL_TEST_ON_SITE_REGISTRATION_CONFIRMATION = `${API_URL}/projects/{project_id}/email-settings/on-site-registration-confirmation/test`;
export const EMAIL_PREVIEW_ON_SITE_REGISTRATION_CONFIRMATION = `${API_URL}/projects/{project_id}/email-settings/on-site-registration-confirmation/render`;
export const EMAIL_TEST_ON_SITE_VIEWER_REMINDER = `${API_URL}/projects/{project_id}/email-settings/on-site-viewers-reminder/test`;
export const EMAIL_PREVIEW_ON_SITE_VIEWER_REMINDER = `${API_URL}/projects/{project_id}/email-settings/on-site-viewers-reminder/render`;
export const EMAIL_TEST_REMINDER = `${API_URL}/projects/{project_id}/email-settings/reminder/test`;
export const EMAIL_PREVIEW_REMINDER = `${API_URL}/projects/{project_id}/email-settings/reminder/render`;
export const EMAIL_STATS_REMINDER = `${API_URL}/projects/{project_id}/email-settings/reminder/stats`;
export const EMAIL_PREVIEW_THANK_YOU = `${API_URL}/projects/{project_id}/email-settings/thank-you/render`;
export const EMAIL_TEST_THANK_YOU = `${API_URL}/projects/{project_id}/email-settings/thank-you/test`;
export const EMAIL_SEND_THANK_YOU = `${API_URL}/projects/{project_id}/send-thank-you-emails`;
export const EMAIL_PREVIEW_POST_EVENT = `${API_URL}/projects/{project_id}/email-settings/post-event/render`;
export const EMAIL_TEST_POST_EVENT = `${API_URL}/projects/{project_id}/email-settings/post-event/test`;
export const EMAIL_PREVIEW_EXTRA = `${API_URL}/projects/{project_id}/email-settings/extra-mail/render`;
export const EMAIL_SEND_EXTRA = `${API_URL}/projects/{project_id}/{event_id}/send-extra-email`;
export const EMAIL_TEST_EXTRA = `${API_URL}/projects/{project_id}/email-settings/extra-mail/test`;
export const EMAIL_ADD_SCHEDULED_EMAIL = `${API_URL}/projects/{project_id}/scheduled-emails`;
export const EMAIL_UPDATE_SCHEDULED_EMAIL = `${API_URL}/projects/{project_id}/scheduled-emails/{scheduledEmail}`;
export const EMAIL_DELETE_SCHEDULED_EMAIL = `${API_URL}/projects/{project_id}/scheduled-emails/{scheduledEmail}`;
export const EMAIL_GET_SCHEDULED_EMAIL_PREVIEW = `${API_URL}/projects/{project_id}/scheduled-emails/{scheduledEmail}/view-sent-content`;
export const EMAIL_HEADER_IMAGE_UPLOAD_URL = `${API_URL}/email-header`;

export const EVENTS_URL = `${API_URL}/projects/{project_id}/events`;
export const SINGLE_EVENT_URL = `${API_URL}/projects/{project_id}/events/{event_id}`;
export const SIMPLE_SESSION_EVENT_URL = `${API_URL}/projects/{project_id}/events/session`;
export const BREAKOUT_SESSION_EVENT_URL = `${API_URL}/projects/{project_id}/events/breakouts`;
export const DELETE_EVENT_URL = `${API_URL}/projects/{project_id}/events/{event_id}`;
export const EVALUATION_URL = `${API_URL}/projects/{project_id}/evaluation-settings/{evaluation_id}`;

export const LOGO_UPLOAD_URL = `${API_URL}/logo`;
export const FAVICON_UPLOAD_URL = `${API_URL}/favicon`;
export const BACKGROUND_UPLOAD_URL = `${API_URL}/background`;
export const DYNAMIC_BLOCK_IMAGE_UPLOAD_URL = `${API_URL}/image-uploads`;
export const LOCATION_IMAGE_UPLOAD_URL = `${API_URL}/location-image`;
export const BLOCK_IMAGE_UPLOAD_URL = `${API_URL}/block-image`;
export const COMPANIES_URL = `${API_URL}/companies`;
export const COMPANIES_FOR_FILTERING_URL = `${API_URL}/companies-list-for-filter`;
export const SINGLE_COMPANY_URL = `${API_URL}/companies/{company_id}`;
export const COMPANY_LOGO_UPLOAD_URL = `${API_URL}/companies/logo`;
export const COMPANY_FAVICON_UPLOAD_URL = `${API_URL}/companies/favicon`;
export const AUTOSAVE_COMPANY_URL = `${API_URL}/companies/{company_id}/autosave`;
export const SPEAKER_AVATAR_UPLOAD_URL = `${API_URL}/avatar`;
export const HANDOUT_UPLOAD_URL = `${API_URL}/handout`;

// Intake API
export const INTAKE_LIST_URL = `${process.env.VUE_APP_API_URL}/intake-api/intakes`;
export const SINGLE_INTAKE_URL = `${INTAKE_LIST_URL}/{$intake}`;
export const INTAKE_TOGGLE_SECTION_URL = `${INTAKE_LIST_URL}/{$intake}/toggle-section`;
export const INTAKE_COPY_URL = `${process.env.VUE_APP_API_URL}/intake-api/intakes/{$intake}/copy`;
export const INTAKE_PREPARATION_SECTION = 1;
export const INTAKE_LIVE_SECTION = 2;

export const INTAKE_URL = `${process.env.VUE_APP_INTAKE_HOST}/{$intake}`;

// Stats
export const STATS_QUESTIONS_EXPORT_URL = `${API_URL}/projects/{event_id}/stats/questions-export`;
export const STATS_EVALUATIONS_LIST_URL = `${API_URL}/projects/{project_id}/stats/evaluations/{evaluation_id}`;
export const STATS_EVALUATIONS_EXPORT_URL = `${API_URL}/projects/{project_id}/stats/evaluations-export/{evaluation_id}`;
export const STATS_POLLS_EXPORT_URL = `${API_URL}/projects/{event_id}/stats/polls-export`;
export const COMPANY_TEMPLATE_INDEX_URL = `${API_URL}/companies/{company}/layouts/dynamic/index`;
export const COMPANY_TEMPLATE_ADD_URL = `${API_URL}/companies/{company}/layouts/dynamic`;
export const COMPANY_TEMPLATE_UPDATE_URL = `${API_URL}/companies/{company}/layouts/dynamic/{layout}`;
//Layout
export const GET_SELECTED_LAYOUT = `${API_URL}/projects/{project_id}/layouts/{layout}`;
export const WAITING_PAGE_COMPANY_TEMPLATE_INDEX_URL = `${API_URL}/companies/{company}/waiting-page-layouts/dynamic/index`;
export const WAITING_PAGE_COMPANY_TEMPLATE_ADD_URL = `${API_URL}/companies/{company}/waiting-page-layouts/dynamic`;
export const WAITING_PAGE_COMPANY_TEMPLATE_UPDATE_URL = `${API_URL}/companies/{company}/waiting-page-layouts/dynamic/{layout}`;
//Layout
export const WAITING_PAGE_GET_SELECTED_LAYOUT = `${API_URL}/projects/{project_id}/waiting-page-layouts/{layout}`;
export const DEVICE_STATS_URL = '/projects/{event_id}/stats/device';



export const populateUrl = function (url, projectId, eventId) {
  let result = url.replace('{project_id}', projectId);

  if (eventId) {
    result = result.replace('{event_id}', eventId);
  }
  return result;
};

export const populateLayoutUrl = function (url, projectId, layout) {
    let result = url.replace('{project_id}', projectId).replace('{layout}', layout);
    return result;
  };

export const populateEvaluationUrl = function (url, projectId, evaluationId) {
    let result = url.replace('{project_id}', projectId).replace('{evaluation_id}', evaluationId);
    return result;
};

export const populateCompanyUrl = function (url, companyId) {
  let result = url.replace('{company_id}', companyId);
  return result;
};

export const populateIntakeUrl = function (url, intakeId) {
  return url.replace('{$intake}', intakeId);
};

export const populateScheduledEmailsUrl = function (url, projectId, scheduledEmail) {
  let result = url.replace('{project_id}', projectId);
  if (scheduledEmail) {
    result = result.replace('{scheduledEmail}', scheduledEmail);
  }

  return result;
};

export const buildSimpleQueryString = function (url, parameters) {
  let paramStr = '';
  let i = 0;

  for (let key in parameters) {
    let sep = i === 0 ? '?' : '&';
    paramStr = `${paramStr}${sep}${key}=${parameters[key]}`;
    ++i;
  }

  return `${url}${paramStr}`;
};

export const makeUrl = function (path, {
  projectId = '',
  eventId = '',
  companyId = '',
  userId = '',
  clientId = '',
  intakeId = '',
  breakoutGroup = ''
} = {}) {
  var url = `${process.env.VUE_APP_API_URL}/api${path}`;
  return url
    .replace('{project_id}', projectId)
    .replace('{event_id}', eventId)
    .replace('{company_id}', companyId)
    .replace('{user_id}', userId)
    .replace('{client_id}', clientId)
    .replace('{$intake}', intakeId)
    .replace('{breakoutGroup}', breakoutGroup);

};
