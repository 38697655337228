<template>
    <div class="d-flex">
        <label class="mr-9">
            Primary color
        </label>
        <custom-color-picker
            :key="primaryColor"
            v-model="primaryColor"
            :disabled="project.isArchived"
        />
    </div>
</template>

<script>
    import CustomColorPicker from '@/components/controls/CustomColorPicker.vue';
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'PrimaryColorPicker',
        components: {CustomColorPicker},
        computed: {
            ...mapState('projects', ['project']),
            primaryColor: {
                get() {
                    return this.project.style.primaryColor;
                },
                set(value) {
                    this.setStylePrimaryColor(value);
                    this.saveProject({
                        key: 'style.primaryColor',
                        value,
                    });
                },
            },
        },
        methods: {
            ...mapMutations('projects', [
                'setStylePrimaryColor',
            ]),
            ...mapActions('projects', ['saveProject']),
        },
    };
</script>

<style scoped>

</style>