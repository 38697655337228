<template>
    <div class="filter-popup-container">
        <list-filter-button :is-open="isOpen"
                            :is-filtered="isFiltered"
                            :tooltip="tooltipContentFilter"
                            @input="openPopup"
        />
        <list-filter-card v-if="isOpen"
                          @close="closeMenu"
        >
            <slot />
        </list-filter-card>
    </div>
</template>

<script>
    import {v4 as uuidv4} from 'uuid';
    import {mapState, mapMutations} from 'vuex';
    import ListFilterButton from '@/components/controls/list-filters/popup/ListFilterButton';
    import ListFilterCard from '@/components/controls/list-filters/popup/ListFilterCard';

    export default {
        name: 'ListHeadFilterPopup',
        components: {ListFilterCard, ListFilterButton},
        props: ['isFiltered', 'name'],
        computed: {
            ...mapState('archives', ['listingHeader']),
            buttonId() {
                return `button-filter-${uuidv4()}`;
            },
            isOpen() {
                return this.listingHeader.filtering.openPopup === this.buttonId;
            },
            isCompany(){
                return this.name === 'Company';
            },
            tooltipContentFilter(){
                return this.isCompany ? 'Filter by company' : 'Filter by name';
            },
        },
        methods: {
            ...mapMutations('archives', ['setHeaderOpenPopup']),
            closeMenu() {
                this.setHeaderOpenPopup('');
            },
            openPopup() {
                this.isOpen ? this.setHeaderOpenPopup('') : this.setHeaderOpenPopup(this.buttonId);
            }
        }
    };
</script>

