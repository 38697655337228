<template>
    <div>
        <h3 class="mb-2 font-weight-bold">
            Handout tab settings
        </h3>
        <v-divider class="mb-10" />
        <div class="px-4">
            <div class="mb-10 d-inline-flex">
                <div class="pt-1 mr-5">
                    Enable handout tab
                </div>
                <v-switch
                    v-model="showHandoutTab"
                    :input-value="showHandoutTab"
                    :disabled="project.isArchived"
                    inset
                    class="mt-0"
                    hide-details
                />
            </div>
            <h4 
                v-if="showHandoutTab"
                class="mb-2 font-weight-bold"
            >
                Handout tab body text
            </h4>
            <v-divider v-if="showHandoutTab"
                       class="mb-10"
            />
            <div v-if="showHandoutTab"
                 class="px-4 mb-10"
            >
                <rich-text-editor
                    v-model="livePageHandoutText"
                    :disabled="project.isArchived"
                    class="rich-text-editor"
                />
            </div>
            <handout-editor v-if="showHandoutTab"
                            v-model="handouts"
            />
        </div>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';
    import HandoutEditor from '@/components/controls/handouts/HandoutEditor';
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import {get} from 'lodash';

    export default {
        name: 'HandoutTabSettings',
        components:{
            HandoutEditor,
            RichTextEditor
        },
        props: {
            event: {
                type: Object,
                required: true
            },
        },
        computed:{
            ...mapState('projects', ['project']),
            showHandoutTab: {
                get() {
                    return get(this.event, 'showHandoutTab', false);
                },
                async set(value) {
                    this.setEventShowHandoutTab({eventID: this.event._id, value});
                    await this.saveEvent({
                        id: this.event._id,
                        key: 'showHandoutTab',
                        value: value,
                    });
                },
            },
            livePageHandoutText: {
                get() {
                    return this.event.livePageHandoutText;
                },
                async set(value) {
                    this.setEventLivePageHandoutText({eventID: this.event._id, value});
                    await this.saveEvent({
                        id: this.event._id,
                        key: 'livePageHandoutText',
                        value: value,
                    });
                },
            },
            handouts: {
                get() {
                    return this.event.handouts;
                }  ,
                async set(value) {
                    this.setEventHandouts({eventID: this.event._id, value});
                    await this.saveEvent({
                        id: this.event._id,
                        key: 'handouts',
                        value: value,
                    });
                }
            },
        },
        methods: {
            ...mapActions('projects', ['saveEvent']),
            ...mapMutations('projects',['setEventLivePageHandoutText',
                                        'setEventHandouts',
                                        'setEventShowHandoutTab' 
            ]),
        },
    };
</script>