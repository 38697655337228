<template>
    <span>{{ displayTimestamp(value) }}</span>
</template>

<script>
    import TimeService from '@/services/TimeService';

    export default {
        name: 'TimestampToDate',
        props: {
            value: {
                type: Number,
                default: () => 0,
            },
        },
        methods: {
            displayTimestamp(timestamp) {
                const date = TimeService.dateFromUnixTimestamp(timestamp);
                return `${TimeService.toDateString(
                    date
                )} ${TimeService.toTimeString(date)}`;
            },
        },
    };
</script>

<style scoped>
</style>