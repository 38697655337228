<template>
    <div>
        <h3 class="mb-2 font-weight-bold">
            Custom tab settings
        </h3>
        <v-divider class="mb-10" />
        <div class="px-4">
            <div class="d-inline-flex mb-4">
                <div class="mt-1 mr-4 d-inline-block">
                    Enable custom tab
                </div>
                <v-switch
                    v-model="showCustomTab"
                    :input-value="showCustomTab"
                    :disabled="project.isArchived"
                    inset
                    class="mt-0"
                    hide-details
                />
            </div>
            <v-text-field
                v-if="showCustomTab"
                v-model="customTabTitle"
                filled
                class="text-input mt-4"
                label="Custom tab title"
                :disabled="project.isArchived"
            />
            <v-select v-if="showCustomTab"
                      v-model="customTabIcon"
                      :items="icons"
                      label="Custom tab icon"
                      item-value="icon"
                      class="custom-icon-selector mb-5"
                      filled
            >
                <template v-slot:selection="{item}">
                    <v-list-item-icon class="custom-tab-icon my-3">
                        <v-icon>
                            {{ item.icon }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content class="py-0">
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                </template>
                <template v-slot:item="{item}">
                    <v-list-item-icon class="custom-tab-icon">
                        <v-icon>
                            {{ item.icon }}
                        </v-icon>
                    </v-list-item-icon>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                </template>
            </v-select>
            <h4 v-if="showCustomTab"
                class="mb-2 font-weight-bold"
            >
                Custom tab body text
            </h4>
            <v-divider v-if="showCustomTab"
                       class="mb-10"
            />
            <div class="px-4">
                <rich-text-editor
                    v-if="showCustomTab"
                    v-model="customTabBodyText"
                    :disabled="project.isArchived"
                    class="rich-text-editor"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import { mapActions, mapMutations, mapState } from 'vuex';
    export default {
        name:'CustomTabSettings',
        components: {RichTextEditor},
        data(){
            return {
                icons: [
                    {icon:'mdi-note-text-outline',
                     name:'Text'
                    },
                    {icon:'mdi-bookmark-outline',
                     name:'Bookmark'
                    },
                    {icon:'mdi-apps',
                     name:'Apps'
                    },
                    {icon:'mdi-plus-circle-outline',
                     name:'Add'
                    }, 
                ]
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            showCustomTab: {
                get() {
                    return this.project.customTab.show;
                },
                async set(value) {
                    this.setProjectShowCustomTab(value);
                    await this.saveProject({
                        id: this.project._id,
                        key: 'customTab.show',
                        value: value,
                    });
                },
            },
            customTabTitle: {
                get() {
                    return this.project.customTab.title;
                },
                async set(value) {
                    this.setProjectCustomTabTitle(value);
                    await this.saveProject({
                        id: this.project._id,
                        key: 'customTab.title',
                        value: value,
                    });
                },
            },
            customTabIcon: {
                get() {
                    return this.project.customTab.icon;
                },
                async set(value) {
                    this.setProjectCustomTabIcon(value);
                    await this.saveProject({
                        id: this.project._id,
                        key: 'customTab.icon',
                        value: value,
                    });
                },
            },
            customTabBodyText: {
                get() {
                    return this.project.customTab.bodyText;
                },
                async set(value) {
                    this.setProjectCustomTabBodyText(value);
                    await this.saveProject({
                        id: this.project._id,
                        key: 'customTab.bodyText',
                        value: value,
                    });
                },
            },
        },
        methods: {
            ...mapActions('projects', ['saveProject']),
            ...mapMutations('projects',
                            ['setProjectCustomTabTitle',
                             'setProjectCustomTabIcon',
                             'setProjectCustomTabBodyText',
                             'setProjectShowCustomTab',
                            ]),
        },
    };
</script>

<style lang="scss">
.custom-tab-icon{
    max-width: 32px;
    padding-left: 10px;
}
.custom-icon-selector{
    .v-input__append-inner{
        margin-top: 20px !important;
    }
}
</style>