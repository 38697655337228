<template>
    <v-card class=" font-weight-medium list-header"
            elevation="0"
    >
        <v-row class="mx-0">
            <v-col cols="12"
                   lg="2"
                   md="2"
                   sm="2"
                   class="vertical-center-text"
            >
                Date & time
            </v-col>
            <v-col cols="12"
                   lg="5"
                   md="5"
                   sm="5"
                   class="vertical-center-text"
            >
                Question
            </v-col>
            <v-col v-if="canShowComponent"
                   cols="12"
                   lg="2"
                   md="2"
                   sm="2"
                   class="vertical-center-text"
            >
                Name
            </v-col>
            <v-col v-if="canShowComponent"
                   cols="12"
                   lg="3"
                   md="3"
                   sm="3"
                   class="vertical-center-text"
            >
                E-mail address
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: 'QuestionsListHeader',
        computed: {
            ...mapGetters('auth', ['canSeeRegistrationData']),
            ...mapState('projects', ['project']),
            canShowComponent() {
                return this.canSeeRegistrationData(this.project);

            }
        },
    };
</script>

<style scoped>
.vertical-center-text{
    display: flex;
    align-items: center;
}
.list-header {
    z-index: 0;
}
</style>
