<template>
    <div class="d-flex flex-column justify-center">
        <h3 class="mb-2 font-weight-bold">
            Speakers editor
        </h3>
        <v-divider class="mb-1" />
        <v-btn
            color="primary"
            class="mt-8 mb-12 align-self-center"
            style="max-width: 250px"
            :disabled="disabled || speakers.length >= 12"
            @click="addSpeaker"
        >
            Add new speaker
            <v-icon class="ml-3">
                mdi-plus
            </v-icon>
        </v-btn>
        <draggable
            v-model="speakers"
            class="field-dragger"
            ghost-class="ghost"
            handle=".drag-handle"
            :options="{disabled : disabled}"
        >
            <v-card
                v-for="(speaker, index) in speakers"
                :key="`speaker-${speaker.id}`"
                class="item-row mb-4 px-2"
            >
                <v-row>
                    <v-col cols="12"
                           lg="1"
                           md="1"
                           sm="1"
                           class="justify-space-around d-flex drag-handle px-0"
                    >
                        <v-icon
                            color="grey"
                        >
                            drag_handle
                        </v-icon>
                    </v-col>
                    <v-col cols="12"
                           lg="3"
                           md="3"
                           sm="3"
                           class="justify-space-around d-flex align-center pr-0"
                    >
                        <avatar-editor v-model="speaker.avatar"
                                       :disabled="disabled"
                        />
                    </v-col>
                    <v-col cols="12"
                           lg="7"
                           md="7"
                           sm="7"
                    >
                        <v-text-field v-model="speaker.name"
                                      label="Name"
                                      outlined
                                      dense
                                      hide-details
                                      class="mb-2"
                                      :disabled="disabled"
                        />
                        <v-text-field v-model="speaker.title"
                                      label="Job title"
                                      outlined
                                      dense
                                      hide-details
                                      class="mb-2"
                                      :disabled="disabled"
                        />
                        <v-text-field :value="speaker.link"
                                      label="Link"
                                      outlined
                                      dense
                                      :rules="urlRules"
                                      class="mb-2"
                                      @input="checkUri(index, $event)"
                        />
                        <v-textarea :value="speaker.description"
                                    label="Description"
                                    :rules="descriptionRules"
                                    :counter="maxDescriptionLength"
                                    outlined
                                    dense
                                    height="100"
                                    :disabled="disabled"
                                    no-resize
                                    @input="checkLength(index, $event)"
                        />
                    </v-col>
                    <v-col cols="12"
                           lg="1"
                           md="1"
                           sm="1"
                           class="justify-space-around d-flex align-center pl-0"
                    >
                        <v-btn
                            icon
                            color="error"
                            class="mt-8 mb-12"
                            :disabled="disabled"
                            @click="deleteSpeaker(speaker)"
                        >
                            <v-icon>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card>
        </draggable>
        <speaker-delete-modal v-if="speakerToDelete"
                              :speaker="speakerToDelete"
                              @input="handleDeleteSpeaker"
        />
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import {isHttpUri, isHttpsUri} from 'valid-url';
    import { debounce } from 'lodash';
    import SpeakerDeleteModal from '@/components/controls/speakers/SpeakerDeleteModal';
    import { createSpeaker } from '@/services/SpeakerService';
    import AvatarEditor from '@/components/controls/speakers/AvatarEditor';


    export default {
        name: 'SpeakersEditor',
        components: {
            AvatarEditor,
            SpeakerDeleteModal,
            draggable
        },
        props:{
            value: {
                type: Array,
                default: () => [],
            },
            disabled: {
                type: Boolean,
                default: () => false
            }
        },
        data(){
            return {
                speakers: [...this.value],
                speakerToDelete: null,
                maxDescriptionLength: 200,
            };
        },
        computed:{
            descriptionRules(){
                return [v => v.length <= this.maxDescriptionLength || `Max ${this.maxDescriptionLength} characters`];
            },
            urlRules(){
                return [v => (isHttpUri(v) !== undefined || isHttpsUri(v) !== undefined  || v.length === 0) || 'URL is invalid'];
            }
        },
        methods:{
            emitSpeakers(value){
                this.$emit('input', value);
            },
            addSpeaker(){
                this.speakers = [...this.speakers, createSpeaker()];
            },
            deleteSpeaker(speaker) {
                this.speakerToDelete = speaker;
            },
            handleDeleteSpeaker(value) {
                this.speakerToDelete = null;
                if (!value) {
                    return;
                }

                this.speakers = this.speakers.filter(
                    (s) => s.id !== value.id
                );
            },
            checkLength(index, event){
                if(event.length <= this.maxDescriptionLength){
                    this.speakers[index].description = event;
                }
            },
            checkUri(index, event){
                if(isHttpUri(event) || isHttpsUri(event) || event.length === 0){
                    this.speakers[index].link = event;
                }
            }
        },
        watch: {
            speakers: {
                deep: true,
                handler: debounce(function (value) {
                    this.emitSpeakers(value);
                }, 400),
            }
        },


    };
</script>

<style scoped lang="scss">
@import "../../../_scss/components/colors";

.item-row {
    border: 1px solid transparent;
    border-top: 1px solid #f2f2f2;
    border-left-width: 3px;
    position: relative;

    .drag-handle {
        cursor: grab;
    }

    &.field-active {
        border: 1px solid $primary;
        border-left-width: 3px;
    }

    &.field-error {
        border: 1px solid $error;
        border-left-width: 3px;
    }
}

</style>

<style lang="scss">
</style>
