<template>
    <v-row>
        <v-col class="py-0">
            <v-text-field
                v-model="calendarTitle"
                class="text-input pl-4"
                label="Calendar title"
                :disabled="project.isArchived"
            />
        </v-col>
    </v-row>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'CalendarTitle',
        computed: {
            ...mapState('projects', ['project']),
            calendarTitle: {
                get() {
                    return this.project.calendarTitle;
                },
                set(value) {
                    this.setCalendarTitle(value);
                    this.saveProject({
                        id: this.project._id,
                        key: 'calendarTitle',
                        value,
                    });
                },
            },
        },
        methods: {
            ...mapMutations('projects', ['setCalendarTitle']),
            ...mapActions('projects', ['saveProject']),
        }
    };
</script>

<style scoped>
</style>
