<template>
    <div class="custom-code d-flex flex-row">
        <v-col cols="2">
            <v-tabs v-model="tab"
                    vertical
            >
                <v-tab href="#stylesheet">
                    Style
                </v-tab>
                <v-tab href="#script">
                    Script
                </v-tab>
            </v-tabs>
        </v-col>
        <v-col cols="10">
            <v-tabs-items :value="tab">
                <v-tab-item :transition="false"
                            :reverse-transition="false"
                            value="stylesheet"
                >
                    <p>Add your custom styles in the editor below. We assume you know what you are doing.</p>
                    <v-alert type="warning"
                             outlined
                             dense
                             text
                    >
                        Custom stylesheets are loaded after the event is loaded in the client's browser. Drastic style changes may cause flicker or unexpected re-rendering on the client's screen.
                    </v-alert>
                    <codemirror v-model="customStyle"
                                :options="cssCodeMirrorOptions"
                    />
                </v-tab-item>
                <v-tab-item :transition="false"
                            :reverse-transition="false"
                            value="script"
                >
                    <p>Add your custom Javascript code into the editor below. We assume you know what you are doing.</p>
                    <p>This code is executed inside a global function, which receives two parameters:</p>
                    <ul class="mb-4">
                        <li>component: <code>Object</code>, contains the actual Vue component. All its data and methods are accessible. Changes are reactive.</li>
                        <li>componentName: <code>String</code>, use this as a filter. When the component with the given name is rendered, the code will be executed.</li>
                    </ul>

                    <v-alert type="warning"
                             outlined
                             dense
                             text
                    >
                        Custom scripts are executed every time a component (forms, buttons, screen elements, etc.) is rendered. <br>
                        Filter the code using <code>componentName</code>. <br>
                        Try to keep the number of hacks low. Excessive amount of scripting may slow down the end-uer site.
                    </v-alert>
                    <v-alert type="error"
                             outlined
                             dense
                             text
                    >
                        Syntax errors in code may break site functionality. Make sure your code is clean. This editor doesn't check the syntax.<br>
                        Avoid editing the scripts during registration and/or during the live event.
                    </v-alert>
                    <codemirror v-model="customScript"
                                :options="jsCodeMirrorOptions"
                    />
                </v-tab-item>
            </v-tabs-items>
        </v-col>
    </div>
</template>
<script>
    import 'codemirror/mode/javascript/javascript.js';
    import 'codemirror/mode/css/css.js';
    import { codemirror } from 'vue-codemirror';
    import { mapActions, mapMutations, mapState } from 'vuex';

    // require styles
    import 'codemirror/lib/codemirror.css';

    const baseCodeMirrorOptions = {
        lineNumbers: true,
        viewportMargin: Infinity,
    };

    export default {
        name: 'CustomCode',
        components: {
            codemirror,
        },
        data() {
            return {
                tab: 'stylesheet',
                cssCodeMirrorOptions: {
                    mode: 'text/css',
                    ...baseCodeMirrorOptions,
                },
                jsCodeMirrorOptions: {
                    mode: 'text/javascript',
                    ...baseCodeMirrorOptions,
                }
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            customStyle: {
                get() {
                    return this.project.customStyle ?? ' ';
                },
                async set(value) {
                    this.setCustomStyle(value);
                    await this.saveProject({
                        key: 'customStyle',
                        value
                    });
                }
            },
            customScript: {
                get() {
                    return this.project.customScript ?? ' ';
                },
                async set(value) {
                    this.setCustomScript(value);
                    await this.saveProject({
                        key: 'customScript',
                        value
                    });
                }
            }
        },
        methods: {
            ...mapMutations('projects', ['setCustomStyle', 'setCustomScript']),
            ...mapActions('projects', ['saveProject']),
        }
    };
</script>

<style>
    .CodeMirror {
        border: 1px solid #ccc;
        border-radius: 5px;
        height: auto;
    }
</style>