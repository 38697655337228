<template>
    <v-row v-if="company">
        <v-col cols="12"
               md="6"
        >
            <h3 class="mb-5 font-weight-regular">
                Company information
            </h3>
            <v-text-field
                v-model="companyName"
                class="text-input"
                prepend-icon="business"
                label="Company name"
            />
            <v-text-field
                v-model="fromName"
                class="text-input"
                prepend-icon="person"
                label="From email name"
            />
            <v-select v-if="isQuadiaLiveAdmin"
                      v-model="productionCompany"
                      class="text-input"
                      prepend-icon="business"
                      :items="productionCompanies"
                      item-text="name"
                      item-value="slug"
                      label="Production company"
            />

            <v-divider class="mb-2 mt-2" />
            <h3 class="mb-5 font-weight-regular">
                Branding settings
            </h3>
            <v-row>
                <v-col lg="6">
                    <company-logo />
                </v-col>
                <v-col lg="6">
                    <company-favicon />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <label>Primary color</label>
                    <custom-color-picker
                        v-model="primaryColor"
                    />
                </v-col>
            </v-row>
            <v-divider class="mb-2 mt-2" />
            <h3 class="mb-5 font-weight-regular">
                Privacy settings
            </h3>
            <v-row>
                <v-text-field
                    v-model="privacyPolicyUrl"
                    class="text-input"
                    prepend-icon="link"
                    label="Privacy Policy"
                />
                <span v-if="!isValid"
                      class="error--text"
                >It looks like you’ve filled in an invalid link. Please check if the link starts with http:// or https://</span>
            </v-row>
        </v-col>
        <v-col cols="12"
               md="6"
        >
            <h3 class="mb-5 font-weight-regular">
                Sample webinar page
            </h3>
            This is what the webinar pages might look like with the current
            styling.
            <frontend-preview
                :key="previewKey"
                page="registration-company"
                :company-i-d="company._id"
                class="wrap"
            />
        </v-col>
    </v-row>
</template>

<script>
    import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
    import CustomColorPicker from '@/components/controls/CustomColorPicker';
    import FrontendPreview from '@/components/pages/Project/Elements/FrontendPreview';
    import { debounce } from 'lodash';
    import CompanyFavicon from '@/components/controls/company/CompanyFavicon.vue';
    import CompanyLogo from '@/components/controls/company/CompanyLogo.vue';

    export default {
        name: 'CompanyDetails',
        components: {CompanyLogo, CompanyFavicon, CustomColorPicker, FrontendPreview },
        data() {
            return {
                saving: false,
                logoUploading: false,
                previewKey: 0,
                isValid: true,
            };
        },
        computed: {
            ...mapState('companies', ['company']),
            ...mapState('productionCompanies', ['productionCompanies']),
            ...mapGetters('auth', [
                'isQuadiaLiveAdmin',
            ]),
            companyName: {
                get() {
                    return this.company.name;
                },
                set: debounce(async function (value) {
                    this.setCompanyName(value);
                    await this.saveCompany({
                        key: 'name',
                        value,
                    });
                    this.previewKey++;
                }, 400),
            },
            fromName: {
                get() {
                    return this.company.mailFromName;
                },
                set: debounce(async function (value) {
                    this.setFromName(value);
                    this.saveCompany({
                        key: 'mailFromName',
                        value,
                    });
                    ++this.previewKey;
                }, 400),
            },
            productionCompany: {
                get() {
                    return this.company.productionCompany;
                },
                set: debounce(async function (value) {
                    this.setProductionCompany(value);
                    this.saveCompany({
                        key: 'productionCompany',
                        value,
                    });
                    ++this.previewKey;
                }, 400),
            },
            primaryColor: {
                get() {
                    return this.company.primaryColor;
                },
                async set(value) {
                    this.setPrimaryColor(value);
                    await this.saveCompany({
                        key: 'primaryColor',
                        value,
                    });
                    ++this.previewKey;
                },
            },
            privacyPolicyUrl: {
                get() {
                    return this.company.privacyPolicyUrl;
                },
                async set(value) {
                    this.validateUrl(value);
                    if(this.isValid || !value) {
                        this.setCompanyPrivacyPolicyUrl(value);
                        await this.saveCompany({
                            key: 'privacyPolicyUrl',
                            value,
                        });
                        ++this.previewKey;
                    }
                },
            },
        },
        methods: {
            ...mapMutations('companies', [
                'setCompanyName',
                'setFromName',
                'setPrimaryColor',
                'setProductionCompany',
                'setCompanyPrivacyPolicyUrl',
            ]),
            ...mapActions('companies', ['saveCompany']),
            validateUrl(value) {
                let validUrlPattern = new RegExp('^(https?:\\/\\/)'+ // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
                this.isValid = !value ? true : !!validUrlPattern.test(value);
            }
        },
    };
</script>

<style scoped>
.text-input {
    width: 22rem;
}

.wrap{
    height: 31rem;
}
</style>
