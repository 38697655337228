class FieldOption {
    constructor(data) {
        if (data.id) {
            this.id = data.id;
        }

        this.text = data.text;
    }
}

export default FieldOption;
