export const filterInvalidNodes = function (node) {
    const supportedNodesAfterPaste = [
        'p',
        'ul',
        'li',
        'ol',
        'span',
        'h1',
        'h2',
        'h3',
        'h4',
        'h5',
        'h6',
        'pre',
        'b',
        'strong',
        'i',
        'em',
        'a',
        'div',
        'br'
    ];

    node.childNodes.forEach(n => {
        // touch HTML element nodes only
        if (n.nodeType === 1) {
            // process child items first before changing the wrapper node
            filterInvalidNodes(n);

            // strip all images
            if (n.nodeName.toLowerCase() === 'img') {
                n.parentNode.removeChild(n);
                return;
            }

            // replace unsupported wrapper nodes with supported ones, see the list above
            // also fix li > p anomaly on paste
            if (supportedNodesAfterPaste.indexOf(n.nodeName.toLowerCase()) === -1 ||
                (n.nodeName.toLowerCase() === 'p' && n.parentNode.nodeName.toLowerCase() === 'li')) {
                const span = document.createElement('span');
                span.innerHTML = n.innerHTML;
                n.parentNode.replaceChild(span, n);
                return;
            }

            // strip all styling
            const hasNonEditable = n.classList.contains('mceNonEditable');
            n.removeAttribute('style');
            n.removeAttribute('class');

            if (hasNonEditable) {
                n.classList.add('mceNonEditable');
            }
        }
    });
};