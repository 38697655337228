import { $http } from '@/services/http';
import { mapToRegistrationFields } from '@/services/FieldService';
import {
  AUTOSAVE_PROJECT_URL,
  PROJECTS_URL,
  REGISTRATION_URL,
  SINGLE_PROJECT_URL,
  SINGLE_EVENT_URL,
  populateUrl,
  populateLayoutUrl,
  APPLY_COMPANY_BRANDING_URL,
} from '@/services/ApiService';
import Project from '@/models/Project';
import { debounce } from 'lodash';
import {toastMessage} from '@/services/responseErrors';
import { DEFAULT_TITLE, setTitle } from '@/services/TitleService';

const projectStore = {
    namespaced: true,
    state: {
        project: new Project(),
        previewKey: 0,
        previewLoading: false,
        loadingProject: false,
        projectRenderKey: 0,
    },
    getters: {
        currentProjectId: state => state.project?._id || undefined,
        registrationType: state => state.project?.registration?.type || undefined,
        isMultiSession: state => state.project?.type === 'multiSession',
        isSingleSession: state => state.project?.type === 'singleSession',
        isSpringerProject: (state, getters) => getters.registrationType === 'springer',
        selectedLayout: state => state.project.dynamicBlocks.layouts[state.project.dynamicBlocks.layout],
        isHybridProject: state => state.project?.type === 'hybrid',
        isExternalEmbed: state => state.project?.type === 'externalEmbed',
        location: state => state.project?.location || {},
        dynamicBlocksLayout: (state) => state.project.dynamicBlocks?.layout ?? 'default',
        waitingPageDynamicBlocksLayout: (state) => state.project.waitingPageDynamicBlocks?.layout ?? 'default',
        evaluationId: state => state.project?.evaluation?._id || '',
        onSiteEvaluationId: state => state.project?.onSiteEvaluation?._id || '',
        isDutchProject: state => ['nlFormal', 'nlInformal'].indexOf(state.project?.language ?? 'en') > -1,
        startTimeInconsistent: state => {
            const events = [...state.project.events].sort((a, b) => {
                if (a.startTime === b.startTime) {
                    return 0;
                }

                return a.startTime > b.startTime ? 1 : -1;
            });
            return events[0].startTime !== state.project.startTime;
        },
        isRegistrationTypeWithoutMailings: state => state.project?.registration?.type === 'open'
            || state.project?.registration?.type === 'generated',
        mailingsEnabled: state => state.project?.emailSettings?.enableMailings === true,
    },
    mutations: {
        setProject: (state, payload) =>{
            state.project = new Project(payload);
            state.loadingProject = false;
            setTitle(payload.eventTitle + ' - ' + DEFAULT_TITLE);
        },
        setStylePrimaryColor: (state, payload) => state.project.style.primaryColor = payload,
        setPwaPrimaryColor: (state, payload) => state.project.pwaStyle.primaryColor = payload,
        setPwaSecondaryColor: (state, payload) => state.project.pwaStyle.secondaryColor = payload,
        setTemplate: (state, payload) => state.project.template = payload,
        setRegistrationType: (state, payload) => state.project.registration.type = payload,
        setSingleSession: (state, payload) => state.project.registration.singleSession = payload,
        setRegistrationExpectedViewers: (state, payload) => state.project.registration.expectedViewers = payload,
        setexpectedOnSiteViewers: (state, payload) => state.project.expectedOnSiteViewers = payload,
        setIntroductionText: (state, payload) => state.project.registration.introductionText = payload,
        setConfirmationText: (state, payload) => state.project.registration.confirmationText = payload,
        setOnSiteConfirmationText: (state, payload) => state.project.registration.onSiteConfirmationText = payload,
        setRegistrationFields: (state, payload) => state.project.registration.fields = payload,
        setShowLinkedin: (state, payload) => state.project.registration.showLinkedin = payload,
        setProjectName: (state, payload) => state.project.name = payload,
        setLogo: (state, payload) => state.project.style.logo = payload,
        setFavicon: (state, payload) => state.project.style.favicon = payload,
        setBackgroundGradient: (state, payload) => state.project.style.backgroundGradient = payload,
        setLogoUrl: (state, payload) => state.project.style.logoUrl = payload,
        setFaviconUrl: (state, payload) => state.project.style.faviconUrl = payload,
        setBackground: (state, payload) => state.project.style.background = payload,
        setBackgroundUrl: (state, payload) => state.project.style.backgroundUrl = payload,
        setCustomLogoHeightRegistrationPage: (state, payload) => state.project.style.customLogoHeightRegistrationPage = payload,
        setCustomLogoWidthInPercentRegistrationPage: (state, payload) => state.project.style.customLogoWidthInPercentRegistrationPage = payload,
        setPwaBackground: (state, payload) => state.project.pwaStyle.background = payload,
        setPwaBackgroundUrl: (state, payload) => state.project.pwaStyle.backgroundUrl = payload,
        setPwaBackgroundGradient: (state, payload) => state.project.pwaStyle.backgroundGradient = payload,
        setRegistrationConfirmation: (state, payload) => state.project.emailSettings.registrationConfirmation = payload,
        setReminder: (state, payload) => state.project.emailSettings.reminder = payload,
        setProjectEventTitle: (state, payload) => {
            state.project.eventTitle = payload;
            if (state.project.type === 'singleSession') {
                state.project.events[0].name = payload;
            }
        },
        setEventDuration: (state, payload) => state.project.events[0].durationInMinutes = payload,
        setProjectDateTime: (state, payload) => state.project.startTime = payload,
        setProjectOnSiteStartTime: (state, payload) => state.project.onSiteStartTime = payload,
        setHideTimezone: (state, payload) => state.project.hideTimezone = payload,
        setHideCalendarInvite: (state, payload) => state.project.hideCalendarInvite = payload,
        setProjectSplashPageContent: (state, payload) => state.project.splashPageContent = payload,
        setEventLivePageContent: (state, {eventID, value}) => { updateEvent(eventID, 'livePageContent', value); },
        setProjectEndPageContent: (state, payload) => state.project.endPage.content = payload,
        setProjectEndPageHandouts: (state, payload) => state.project.endPage.handouts = payload,
        setEventOnDemandPageContent: (state, {eventID, value}) => { updateEvent(eventID, 'onDemandPageContent', value); },
        setEventOnDemandID: (state, {eventID, value}) => { updateEvent(eventID, 'onDemandID', value); },
        setActivateEventOnDemand: (state, {eventID, value}) => { updateEvent(eventID, 'onDemandActive', value); },
        setEventSlidoEvent: (state, {eventID, value}) => { updateEvent(eventID, 'slidoEvent', value); },
        setEventTabOrder: (state, {eventID, value}) => { updateEvent(eventID, 'tabOrder', value); },
        setEventCrispChatEvent: (state, {eventID, value}) => { updateEvent(eventID, 'crispChatEvent', value); },
        setEventChatType: (state, {eventID, value}) => { updateEvent(eventID, 'chatType', value); },
        setSessionType: (state, {eventID, value}) => { updateEvent(eventID, 'sessionType', value); },
        setModerator: (state, {eventID, value}) => { updateEvent(eventID, 'moderator', value); },
        setEventLivePageHandoutText: (state, {eventID, value}) => { updateEvent(eventID, 'livePageHandoutText', value); },
        setEventShowHandoutTab: (state, {eventID, value}) => { updateEvent(eventID, 'showHandoutTab', value); },
        setEventHandouts: (state, {eventID, value}) => { updateEvent(eventID, 'handouts', value); },
        setProjectShowPollsOverVideo:(state, payload) => state.project.showPollsOverVideo = payload,
        setProjectShowQuizOverVideo:(state, payload) => state.project.showQuizOverVideo = payload,
        setThankYouBody: (state, payload) => state.project.emailSettings.thankYou.bodyText = payload,
        setThankYouSubject: (state, payload) => state.project.emailSettings.thankYou.subject = payload,
        setThankYouEmailsSentAt: (state, payload) => state.project.emailSettings.thankYou.emailsSentAt = payload,
        setPostEventBody: (state, payload) => state.project.emailSettings.postEvent.bodyText = payload,
        setPostEventSubject: (state, payload) => state.project.emailSettings.postEvent.subject = payload,
        setReminderBody: (state, payload) => state.project.emailSettings.reminder.bodyText = payload,
        setReminderSubject: (state, payload) => state.project.emailSettings.reminder.subject = payload,
        setExtraBody: (state, payload) => state.project.emailSettings.extraMail.bodyText = payload,
        setExtraSubject: (state, payload) => state.project.emailSettings.extraMail.subject = payload,
        setRegistrationConfirmationBody: (state, payload) => state.project.emailSettings.registrationConfirmation.bodyText = payload,
        setRegistrationConfirmationSubject: (state, payload) => state.project.emailSettings.registrationConfirmation.subject = payload,
        setOnSiteRegistrationConfirmationBody: (state, payload) => state.project.emailSettings.onSiteRegistrationConfirmation.bodyText = payload,
        setOnSiteRegistrationConfirmationSubject: (state, payload) => state.project.emailSettings.onSiteRegistrationConfirmation.subject = payload,
        setOnSiteViewerReminderSubject: (state, payload) => state.project.emailSettings.onSiteViewerReminder.subject = payload,
        setOnSiteViewerReminderBody: (state, payload) => state.project.emailSettings.onSiteViewerReminder.body = payload,
        setLanguage: (state, payload) => state.project.language = payload,
        setProjectPrivacyPolicyUrl: (state, payload) => state.project.privacyPolicyUrl = payload,
        setProjectCustomTabTitle: (state, payload) => state.project.customTab.title = payload,
        setProjectCustomTabIcon: (state, payload) => state.project.customTab.icon = payload,
        setProjectCustomTabBodyText: (state, payload) => state.project.customTab.bodyText = payload,
        setProjectShowCustomTab: (state, payload) => state.project.customTab.show = payload,

        setEvaluationButtonHide: (state, payload) => (state.project.evaluationButton.hide = payload),
        setEvaluationButtonHref : (state, payload) => state.project.evaluationButton.href = payload,
        setEvaluationButtonTitle : (state, payload) => state.project.evaluationButton.title = payload,
        setEvaluationButtonOverwrite : (state, payload) => state.project.evaluationButton.overwrite = payload,
        setElearningButtonHref : (state, payload) => state.project.elearningButton.href = payload,
        setElearningButtonAlternateHref : (state, payload) => state.project.elearningButton.alternateHref = payload,
        setElearningButtonTitle : (state, payload) => state.project.elearningButton.title = payload,
        setShowCrispChat: (state, payload) => state.project.endPage.showCrispChat = payload,

        setCompanyId: (state, payload) => state.project.companyId = payload,
        setMailFromName: (state, payload) => state.project.emailSettings.mailFromName = payload,
        setMailSalutation: (state, payload) => state.project.emailSettings.salutation = payload,
        setMailFooterText: (state, payload) => state.project.emailSettings.footerText = payload,
        setMailNameSetup: (state, payload) => state.project.emailSettings.nameSetup = payload,
        setHeaderImg: (state, payload) => state.project.emailSettings.headerImg = payload,
        setHeaderImgUrl: (state, payload) => state.project.emailSettings.headerImgUrl = payload,
        setProjectPasswordLivePage: (state, payload) => state.project.security.passwordLivePage = payload,
        setProjectPasswordLivePageActive: (state, payload) => state.project.security.passwordLivePageActive = payload,
        setProjectSwitchingRooms: (state, payload) => state.project.preventSwitchingRooms = payload,

        incrementPreviewKey: (state) => ++state.previewKey,
        setPreviewLoading: (state, payload) => state.previewLoading = payload,
        startLoadingProject:(state) => state.loadingProject = true,

        reRender: (state) => state.projectRenderKey++,

        setSpeakers: (state, payload) => state.project.speakers = payload,
        setEnableGeneratedRegistrationStatistics: (state, payload) => state.project.enableGeneratedRegistrationStatistics = payload,

        setDynamicBlocksLayout: (state, payload) => state.project.dynamicBlocks.layout = payload,
        setWaitingPageDynamicBlocksLayout: (state, payload) => state.project.waitingPageDynamicBlocks.layout = payload,
        setDynamicBlocks: (state, payload) => state.project.dynamicBlocks.layouts[state.project.dynamicBlocks.layout] = payload,
        setWaitingPageDynamicBlocks: (state, payload) => state.project.waitingPageDynamicBlocks.layouts[state.project.waitingPageDynamicBlocks.layout] = payload,
        setTopics: (state, payload) => state.project.topics = payload,
        setLocation: (state, payload) => state.project.location = payload,
        setProjectPwaHomescreenContent: (state, payload) => state.project.pwaHomescreenContent = payload,
        setExtraMailEnabled: (state, payload) => state.project.extraMailEnabled = payload,
        setCalendarTitle: (state, payload) => state.project.calendarTitle = payload,
        setShowProgramTab: (state, payload) => state.project.showProgramTab = payload,
        setButtonBorderRadius: (state, payload) => state.project.buttonBorderRadius = payload,
        setLabelBorderRadius: (state, payload) => state.project.labelBorderRadius = payload,
        setCardBorderRadius: (state, payload) => state.project.cardBorderRadius = payload,
        setInputBorderRadius: (state, payload) => state.project.inputBorderRadius = payload,
        setFontFamily:(state, payload) => state.project.fontFamily = payload,
        setRequireRegistrationForOnDemand: (state, payload) => state.project.requireRegistrationForOnDemand = payload,
        setAttachRegistrationDataToAskAQuestion: (state, payload) => state.project.registration.attachRegistrationDataToAskAQuestion = payload,
        setCustomStyle: (state, payload) => state.project.customStyle = payload,
        setCustomScript: (state, payload) => state.project.customScript = payload,
        setProjectCustomCtaEnabled: (state, payload) => state.project.customCtaButton.enabled = payload,
        setProjectCustomCtaTitle: (state, payload) => state.project.customCtaButton.title = payload,
        setProjectCustomCtaHref: (state, payload) => state.project.customCtaButton.href = payload,
        setEnableMailings: (state, payload) => state.project.emailSettings.enableMailings = payload,
    },
    actions: {
        getProject: ({ commit }, projectId) => {
            commit('startLoadingProject');
            return $http.get(populateUrl(SINGLE_PROJECT_URL, projectId)).then(response => {
                commit('setProject', response.data);
            });
        },
        saveProject: debounce(async function ({ dispatch }, { key, value }) {
            dispatch('saveProjectImmediate',{key,value});
        }, 400),
        saveProjectImmediate: async function ({ state }, { key, value }) {
            var input = {};
            input[key] = value;
            var url = populateUrl(AUTOSAVE_PROJECT_URL, state.project._id);
            await $http.put(url, input).then((response) => {
                if(!response.data.productionCompanyExist){
                    toastMessage('No matching ProductionCompany found in LiveDirector.  Please contact support');
                }
            });
        },
        saveEvent: debounce(async function ({ state }, { id, key, value }) {
            var input = {};
            input[key] = value;
            var url = populateUrl(`${SINGLE_EVENT_URL}/autosave`, state.project._id, id);
            await $http.put(url, input);
        }, 400),
        storeProject: (context, payload) => {
            return $http.post(PROJECTS_URL, payload);
        },
        saveRegistration: ({ commit, state }, payload) => {
            const url = populateUrl(REGISTRATION_URL, payload._id);

            const registrationDto = {
                ...payload,
                fields: mapToRegistrationFields(payload.fields)
            };

            return $http.put(url, registrationDto).then(() => commit(
                'setProject', {
                ...state.project,
                registration: payload
            }));
        },
        sendTestEmail: (context, payload) => {
            return $http.post(payload.url, payload.settings);
        },
        renderEmailPreview: (context, payload) => {
            return $http.get(payload.url, {});
        },
        async reloadProject({dispatch, getters}) {
            if(getters.currentProjectId){
                return dispatch('getProject', getters.currentProjectId);
            }
        },
        inheritCompanyBranding: async function({ state, dispatch }, companyId) {
            var url = populateUrl(APPLY_COMPANY_BRANDING_URL, state.project._id);
            await $http.put(url, {
                'companyId': companyId
            });
            dispatch('getProject', state.project._id);
        },
        getSelectedLayout: async ({state, commit}, {baseUrl, setter}) => {
            var url = populateLayoutUrl(baseUrl, state.project._id, state.project.dynamicBlocks.layout);
            var response = await $http.get(url);
            commit(setter, response.data);
        },
    }
};

function updateEvent(eventID, key, value) {
    const event = projectStore.state.project.events.find((obj => obj._id == eventID));
    event[key] = value;
}

export default projectStore;
