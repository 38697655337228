
<template>
    <div>
        <div class="information">
            <span v-if="location.formattedAddress"
                  class="address"
            >{{ location.formattedAddress }}</span>
            <v-btn color="primary"
                   :disabled="project.isArchived"
                   @click="openDialog"
            >
                <v-icon class="mr-2">
                    mdi-map-outline
                </v-icon>
                {{ formattedAddress ? 'Change location' : 'Set location' }}
            </v-btn>
        </div>
        <v-dialog 
            v-if="edit"
            v-model="edit"
            max-width="80vw"
            persistent
        >
            <v-card id="card"
                    class="wrapper"
            >
                <v-btn icon
                       class="close"
                       @click="closeDialog"
                >
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
                <v-card-title>Set location for event</v-card-title>
                <v-divider class="mb-4" />
                <div class="input-wrapper mb-4 px-4">
                    <GmapAutocomplete 
                        ref="gmapAutocomplete"
                        :position.sync="marker.position"
                        class="google-autocomplete"
                        @place_changed="setPlace"
                    />
                    <div>
                        <v-btn color="primary"
                               class="mr-2"
                               :loading="loading"
                               min-width="145"
                               @click="saveLocation"
                        >
                            <span v-if="!saved">                       
                                Save location
                            </span>
                            <v-icon v-else>
                                mdi-checkbox-marked-circle-outline
                            </v-icon>
                        </v-btn>
                        <v-tooltip top>
                            <template v-slot:activator="{ on }">
                                <v-btn icon
                                       v-on="on"
                                >
                                    <v-icon>info</v-icon>
                                </v-btn>
                            </template>
                            <span>Position your map as you would like it to be shown <br> in the e-mails before saving the location.</span>
                        </v-tooltip>
                    </div>
                    <span v-if="fileTooLarge"
                          class="error--text"
                    >
                        Screenshot of the map cannot be larger than 2MB. Please make your browser's window smaller and try again.
                    </span>
                </div>
                <GmapMap
                    id="capture"
                    ref="mapRef"
                    :center="center"
                    :zoom="zoom"
                    map-type-id="terrain"
                    style="width: 100%; height: 60vh"
                    @click="handleMarkerReplace"
                    @zoom_changed="getCurrentZoom"
                >
                    <GmapMarker
                        :position="marker.position"
                        :clickable="true"
                        :draggable="true"
                        @drag="handleMarkerReplace"
                        @click="panToMarker"
                    />
                </GmapMap>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {gmapApi} from 'vue2-google-maps';
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import html2canvas from 'html2canvas';
    import { $http } from '@/services/http';
    import { populateUrl, AUTOSAVE_PROJECT_URL, LOCATION_IMAGE_UPLOAD_URL} from '@/services/ApiService';
    import  {LOCATION_MAX_FILE_SIZE} from '@/services/fileValidation';

    export default {
        data(){
            return{
                center: {lat: 52.23542191101441, lng: 5.1719029},
                marker: {position:{lat: 52.23542191101441, lng: 5.1719029}},
                zoom: 0,
                formattedAddress: '',
                edit: false,
                loading: false,
                fileTooLarge: false,
                saved: false,
            };
        },
        computed:{
            ...mapGetters('projects', ['location', 'currentProjectId']),
            ...mapState('projects', ['project']),
            apiKey(){
                return process.env.VUE_APP_GOOGLE_MAPS_API_KEY;
            }
        },
        methods:{
            ...mapActions('projects', ['saveProject']),
            ...mapMutations('projects', ['setLocation']),
            async panToMarker() {
                this.$refs.mapRef.$mapPromise.then((map) => {
                    map.panTo(this.marker.position);
                    map.setZoom(16);
                });
            },
            async handleMarkerReplace(e){
                this.marker.position = { lat: e.latLng.lat(), lng: e.latLng.lng() };
                const maps = await new gmapApi().maps;
                const geocoder = new maps.Geocoder();
                geocoder.geocode({ 'latLng': e.latLng }, (result) => {
                    this.$refs.gmapAutocomplete.$refs.input.value = result[0].formatted_address;
                    this.formattedAddress = result[0].formatted_address;
                });
            },
            setPlace(place) {
                this.marker.position = {
                    lat: place.geometry.location.lat(),
                    lng: place.geometry.location.lng(),
                };
                this.center = this.marker.position; 
                this.formattedAddress = this.$refs.gmapAutocomplete.$refs.input.value;
            },
            getCurrentZoom(zoom){
                this.zoom = zoom;
            },
            saveLocation(){
                this.loading = true;
                this.saved = false;
                html2canvas(document.querySelector('#capture'), {
                    allowTaint: true,
                    useCORS: true,
                }).then(canvas => {
                    const base64 = canvas.toDataURL();
                    fetch(base64)
                        .then(res => res.blob())
                        .then(blob => {
                            const file = new File([blob], 'location.png');
                            if (file === undefined || file.size > LOCATION_MAX_FILE_SIZE) {
                                if(file.size > LOCATION_MAX_FILE_SIZE){
                                    this.fileTooLarge = true;
                                }
                                return Promise.reject();
                            }
                            this.fileTooLarge = false;
                            const formData = new FormData();
                            formData.append('image', file);
                            return $http.post(LOCATION_IMAGE_UPLOAD_URL, formData, {
                                headers: {
                                    'Content-Type': 'multipart/form-data',
                                },
                            })
                                .then((res)=>{
                                    var url = populateUrl(AUTOSAVE_PROJECT_URL, this.currentProjectId);
                                    this.setLocation({
                                        lat: this.marker.position.lat,
                                        lng: this.marker.position.lng,
                                        zoom: `${this.zoom}`,
                                        formattedAddress: this.trimFormattedAddress(this.formattedAddress),
                                        image: res.data.key,
                                    });
                                    $http.put(url, {
                                        'location.lat' : this.marker.position.lat,
                                        'location.lng': this.marker.position.lng,
                                        'location.zoom': `${this.zoom}`,
                                        'location.formattedAddress':  this.trimFormattedAddress(this.formattedAddress),
                                        'location.image': res.data.key,
                                    })
                                        .then(() => {
                                            this.saved = true;
                                            setTimeout(() => {
                                                this.saved = false;
                                            }, 10000);
                                        })
                                        .finally(() => {
                                            this.loading = false;
                                        });
                                });
                        });
                });
            },
            openDialog(){
                this.edit = true;
                this.$nextTick(() => {
                    this.$refs.mapRef.$mapPromise.then((map) => {
                        map.panTo(this.center);
                    });
                    this.$refs.gmapAutocomplete.$refs.input.value = this.location.formattedAddress;
                    this.zoom = parseInt(this.location.zoom);
                    this.marker.position = {lat: this.location.lat, lng: this.location.lng};
                    this.center = this.marker.position;
                });
            },
            closeDialog(){
                this.edit = false;
            },
            trimFormattedAddress(value){
                if(this.project.language === 'en'){
                    return value;

                } else {
                    var lastIndex = value.lastIndexOf(' ');
                    return value.substring(0, lastIndex-1);
                }
            }
        },
        mounted(){
            this.formattedAddress = this.location.formattedAddress;
        }
    };

</script>

<style scoped lang="scss">
.wrapper{
    position: relative;
    .close{
        position: absolute;
        top: 10px;
        right: 10px
    }
}

.input-wrapper{
    display: flex;
    align-items: center;
    gap: 32px;
}

.information{
    display: flex;
    align-items: center;
    gap:36px;

    .address{
        font-size: 16px;
        text-decoration: underline;
    }
}
</style>

<style lang="scss">
.google-autocomplete{

    min-height: 50px !important;
    min-width: 50% !important;
    border: 1px solid #e3e3e3e3;
    border-radius: 3px;
    background: #f0f0f0;
    padding: 0px 10px;

    &:focus-visible{
        outline: none !important;
    }
}
</style>