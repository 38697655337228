class Company {
    constructor(data) {
        this._id = data?._id || '';
        this.name = data?.name || '';
        this.productionCompany = data?.productionCompany || null;
        this.logo = data?.logo || '';
        this.logoUrl = data?.logoUrl || '';
        this.favicon = data?.favicon || '';
        this.faviconUrl = data?.faviconUrl || '';
        this.primaryColor = data?.primaryColor || 'rgba(1, 2, 3, 1)';
        this.mailFromName = data?.mailFromName || '';
        this.privacyPolicyUrl = data?.privacyPolicyUrl || '';
    }
}

export default Company;
