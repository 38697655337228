<template>
    <v-card class=" font-weight-medium list-header"
            elevation="0"
    >
        <v-row class="mx-0">
            <v-col v-if="canShowComponent"
                   cols="12"
                   lg="1"
                   md="1"
                   sm="1"
                   class="vertical-center-text"
            >
                Name
            </v-col>
            <v-col v-if="canShowComponent"
                   cols="12"
                   lg="2"
                   md="2"
                   sm="2"
                   class="vertical-center-text"
            >
                E-mail address
            </v-col>
            <v-col cols="12"
                   lg="2"
                   md="2"
                   sm="2"
                   class="vertical-center-text"
            >
                Date of registration
            </v-col>
            <v-col cols="12"
                   lg="2"
                   md="2"
                   sm="2"
                   class="vertical-center-text"
            >
                Event name
            </v-col>
            <v-col cols="12"
                   :lg="canShowComponent ? '1':'2'"
                   :md="canShowComponent ? '1':'2'"
                   :sm="canShowComponent ? '1':'2'"
                   class="vertical-center-text"
                   style="justify-content: center"
            >
                Attended y/n
            </v-col>
            <v-col cols="12"
                   :lg="canShowComponent ? '1':'2'"
                   :md="canShowComponent ? '1':'2'"
                   :sm="canShowComponent ? '1':'2'"

                   class="vertical-center-text "
            >
                Time of entry
            </v-col>
            <v-col cols="12"
                   :lg="canShowComponent ? '1':'2'"
                   :md="canShowComponent ? '1':'2'"
                   :sm="canShowComponent ? '1':'2'"
                   class="vertical-center-text"
            >
                Time of exit
            </v-col>
            <v-col cols="12"
                   lg="2"
                   md="2"
                   sm="2"
                   class="vertical-left-text"
                   style="justify-content: center"
            >
                Attendance rate
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';

    export default {
        name: 'RegistrationListHeader',
        computed: {
            ...mapGetters('auth', ['canSeeRegistrationData']),
            ...mapGetters('projects', ['isHybridProject']),
            ...mapState('projects', ['project']),
            canShowComponent() {
                return this.canSeeRegistrationData(this.project);

            }
        },
    };
</script>

<style scoped>
.vertical-center-text{
    display: flex;
    align-items: center;
}
.list-header {
    z-index: 0;
}
</style>
