<template>
    <v-tooltip right>
        <template #activator="tooltip">
            <v-menu bottom
                    nudge-bottom="33px"
                    class="move-into-column-button"
            >
                <template #activator="menu">
                    <v-btn
                        fab
                        elevation="1"
                        color="white"
                        x-small
                        :disabled="project.isArchived"
                        v-on="{ ...menu.on, ...tooltip.on }"
                    >
                        <v-icon>
                            mdi-arrow-down
                        </v-icon>
                    </v-btn>
                </template>
                <v-list dense
                        class="pt-0"
                >
                    <v-subheader class="slim-subheader no-border px-2">
                        Select block to move here
                    </v-subheader>
                    <v-list-item
                        v-for="blockToAdd in blocks"
                        v-show="blockToAdd.componentType !== 'column'"
                        :key="`block-list-item-${blockToAdd._id}`"
                        class="list-element slim"
                    >
                        <v-list-item-content class="matte">
                            <v-list-item-title @click="moveIntoColumn({ block: blockToAdd, columnBlock: block, saveUrl })">
                                {{ mapComponentName(blockToAdd) }}
                            </v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
        <span>Move block into this column</span>
    </v-tooltip>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { componentTypeNames } from '@/components/pages/Project/EventPage/blocks-helper.js';

    export default {
        name: 'MoveIntoColumnButton',
        props: {
            block: {
                type: Object,
                required: true,
            },
            saveUrl: {
                type: String,
                required: true,
            },
        },
        computed: {
            ...mapState('dynamic-block', ['blocks']),
            ...mapState('projects', ['project'])
        },
        methods: {
            ...mapActions('dynamic-block', ['moveIntoColumn']),
            mapComponentName(block) {
                return componentTypeNames[block.componentType];
            },
        },
    };
</script>

<style lang="scss" scoped>
.list-element {
  cursor: pointer;
  &:hover {
    background: #e3e3e3;
  }
}
.move-into-column-button {
  position: absolute;
  top: 0;
  left: 0;
}

.no-border {
    border: 0 none transparent;
}
</style>
