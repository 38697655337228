<template>
    <v-card v-if="field"
            :value="value"
            flat
    >
        <v-card-text class="pa-0">
            <option-editor
                v-model="field.options"
                @validate="optionsValidated"
                @confirm="updateField"
            />
            <div v-if="!optionsValid">
                <div
                    v-if="optionsValidationReason === fieldOptionNone"
                    class="error--text"
                >
                    At least one option must be specified.
                </div>
                <div
                    v-if="optionsValidationReason === fieldOptionDuplicate"
                    class="error--text"
                >
                    There are duplicate options.
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    import {
        FIELD_VALIDATION_OPTION_DUPLICATE,
        FIELD_VALIDATION_OPTION_NONE,
    } from '@/services/FieldService';
    import OptionEditor from '@/components/controls/OptionEditor';

    export default {
        name: 'FieldOptionEditorCard',
        components: { OptionEditor },
        props: {
            value: {
                type: Boolean,
                default: false,
            },
            field: {
                type: Object,
            },
        },
        data() {
            return {
                optionEditor: false,
                optionsValid: true,
                optionsValidationReason: '',
            };
        },
        computed: {
            editedFieldName() {
                return this.field ? this.field.name : '';
            },
            fieldOptionNone() {
                return FIELD_VALIDATION_OPTION_NONE;
            },
            fieldOptionDuplicate() {
                return FIELD_VALIDATION_OPTION_DUPLICATE;
            },
        },
        methods: {
            updateField() {
                this.$emit('confirm');
            },
            optionsValidated({ valid, reason }) {
                this.optionsValid = valid;
                this.optionsValidationReason = reason;
            },
        },
    };
</script>

<style scoped lang="scss">
</style>
