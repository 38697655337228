<template>
    <div class="favicon-picker">
        <div v-if="!faviconUrl"
             class="d-flex align-baseline"
        >
            <v-file-input
                :key="key"
                ref="faviconInput"
                label="Favicon"
                accept="image/*, .ico"
                truncate-length="50"
                show-size
                :disabled="disabled"
                :rules="faviconRules"
                append-icon="mdi-image-plus"
                prepend-icon=""
                color="primary"
                class="file-input pr-2"
                @click:append="$refs.faviconInput.$el.querySelector('input').click()"
                @change="onFileSelect"
            />
            <favicon-hint />
        </div>
        <div v-else
             class="d-flex mb-2 pt-4"
        >
            <h3 class="font-weight-regular pt-2">
                Favicon
            </h3>
            <favicon-hint />
        </div>
        <div v-if="!uploading"
             class="pl-5"
        >
            <img :src="faviconUrl"
                 class="logo mr-10"
            >
            <v-btn
                v-if="faviconUrl"
                icon
                color="primary"
                class="mt-n2"
                :disabled="disabled"
                @click="removeFavicon"
            >
                <v-icon dark>
                    mdi-image-remove
                </v-icon>
            </v-btn>
        </div>
        <v-progress-circular v-else
                             indeterminate
                             color="primary"
        />
    </div>
</template>


<script>
    import {
        faviconValidationRules,
        LOGO_MAX_FILE_SIZE,
    } from '@/services/fileValidation';
    import { $http } from '@/services/http';
    import FaviconHint from '@/components/controls/hints/FaviconHint.vue';


    export default {
        name: 'FaviconPicker',
        components:{FaviconHint},
        data(){
            return{
                uploading: false,
                key: 0,
                selectedFile: ''
            };
        },
        props: {
            uploadUrl: {
                type: String,
                required: true,
            },
            faviconUrl: {
                type: String,
                default: () => null,
            },
            favicon: {
                type: String,
                default: () => null,
            },
            disabled: {
                type: Boolean,
                default: () => false,
            }
        },
        computed:{
            faviconRules() {
                return faviconValidationRules;
            },
        },
        methods:{
            async uploadFavicon(file) {
                if (file === undefined || file.size > LOGO_MAX_FILE_SIZE) {
                    return Promise.reject();
                }
                const formData = new FormData();
                formData.append('favicon', file);
                this.uploading = true;
                return $http.post(this.uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then((res)=>{
                    this.$emit('input', {
                        faviconUrl: res.data.url,
                        favicon: res.data.key,
                    });
                }).finally(()=>{
                    this.uploading = false;
                });
            },
            async removeFavicon(){
                this.$emit('input', {
                    faviconUrl: '',
                    favicon: '',
                });
                this.key++;
            },
            onFileSelect(e){
                this.uploadFavicon(e);
            }
        }
    };
</script>


<style lang="scss">
.file-input{
  .v-icon.v-icon.v-icon--link{
    padding-top: 10px;
    color: #2977BE
  }
}
.logo {
  max-width: 40%;
}

.favicon-picker {
  width: 100%;
}
</style>