import { $http } from '@/services/http';
import {
    populateUrl,
    makeUrl,
} from '@/services/ApiService';

const ProjectStatisticsService = class {

    async getTotalRegistrationsCount(projectId) {
        const url = makeUrl('/projects/{project_id}/stats/registrations-count', { projectId });
        const response = await $http.get(url);
        return response.data.count;
    }

    async getTotalRegistrationsCountPerEvent(eventId) {
        const url = makeUrl('/projects/{event_id}/stats/registrations-count-per-event', { eventId });
        const response = await $http.get(url);
        return response.data.count;
    }

    async getRegistrationsOverTime(projectId) {
        const url = makeUrl('/projects/{project_id}/stats/registrations-chart', { projectId });
        const response = await $http.get(url);
        let processedData = {
            labels: [],
            values: {
                onSiteViewers: {},
                onlineViewers: {},
                total: {},
            }
        };
        let oldLabel = '';
        response.data.sort((a, b) => {
            const dateA = new Date(a._id.dayOfMonth).getTime();
            const dateB = new Date(b._id.dayOfMonth).getTime();
            if (dateA === dateB) {
                return 0;
            }

            return dateA > dateB ? 1 : -1;
        }).forEach(obj => {
            const timeString = obj._id.dayOfMonth;
            if (timeString !== oldLabel) {
                processedData.labels.push(timeString);
                oldLabel = timeString;
            }

            if (obj._id.onSiteViewer === 1) {
                processedData.values.onSiteViewers[oldLabel] = obj.count;
                if (!processedData.values.onlineViewers[oldLabel]) {
                    processedData.values.onlineViewers[oldLabel] = 0;
                }
            } else {
                processedData.values.onlineViewers[oldLabel] = obj.count;
                if (!processedData.values.onSiteViewers[oldLabel]) {
                    processedData.values.onSiteViewers[oldLabel] = 0;
                }
            }

            processedData.values.total[oldLabel] = processedData.values.onlineViewers[oldLabel] + processedData.values.onSiteViewers[oldLabel];

        });
        return processedData;
    }

    async getUniqueViewersCount(eventId) {
        const url = makeUrl('/projects/{event_id}/stats/unique-viewers', { eventId });
        const response = await $http.get(url);
        return response.data.count;
    }

    async getUniqueSessionsCount(eventId) {
        const url = makeUrl('/projects/{event_id}/stats/unique-sessions', { eventId });
        const response = await $http.get(url);
        return response.data.count;
    }

    async getAverageViewingTime(eventId) {
        const url = makeUrl('/projects/{event_id}/stats/average-viewing-time', { eventId });
        const response = await $http.get(url);
        return response.data.count;
    }

    async getAverageViewingTimeByUID(eventId) {
        const url = makeUrl('/projects/{event_id}/stats/average-viewing-time-by-uid', { eventId });
        const response = await $http.get(url);
        return response.data.count;
    }

    async getStatisticsQuestionsCount(eventId) {
        const url = makeUrl('/projects/{event_id}/stats/questions-count', { eventId });
        const response = await $http.get(url);
        return response.data;
    }

    async getStatisticsQuestions(eventId, params) {
        const url = makeUrl('/projects/{event_id}/stats/questions', { eventId });
        const response = await $http.get(url, { params: { search: params.search, page: params.page, perPage: params.perPage } });
        return response.data;
    }

    async getPolls(eventId) {
        const url = makeUrl('/projects/{event_id}/stats/polls', { eventId });
        const response = await $http.get(url);
        return response.data.count;
    }

    async getViewersInfo(eventId, limit, offset) {
        const url = makeUrl('/projects/{event_id}/stats/registrations?', { eventId });
        const response = await $http.get(url, { params: { limit: limit, offset: offset } });
        return response.data;
    }

    async getViewersInfoPerEvent(eventId, limit, offset) {
        const url = makeUrl('/projects/{event_id}/stats/registrations-per-event?', { eventId });
        const response = await $http.get(url, { params: { limit: limit, offset: offset } });
        return response.data;
    }

    async getNoShows(projectId) {
        const url = makeUrl('/projects/{project_id}/stats/no-shows-count', { projectId });
        const response = await $http.get(url);
        return response.data.noShowsCount;
    }

    async getViewingChartData(eventId) {
        const url = makeUrl('/projects/{event_id}/stats/viewers-chart', { eventId });
        const response = await $http.get(url);
        return response.data;
    }

    async getFilledInEvaluationCount(projectId) {
        const url = makeUrl('/projects/{project_id}/stats/filled-in-evaluations', { projectId });
        const response = await $http.get(url);
        return response.data;
    }

    async getChatCount(projectId) {
        const url = makeUrl('/projects/{project_id}/stats/external-chat/count', { projectId });
        const response = await $http.get(url);
        return response.data;
    }

    async getChatCategoryCounts(projectId) {
        const url = makeUrl('/projects/{project_id}/stats/external-chat/categories/count', { projectId });
        const response = await $http.get(url);
        return response.data;
    }

    async getRegistrationPageViews(projectId) {
        const url = populateUrl(process.env.VUE_APP_QLIVE_ANALYTICS_PAGE_VIEWS_ENDPOINT, projectId);
        const response = await $http.get(url, {
            headers: {
                'x-api-key': process.env.VUE_APP_QLIVE_ANALYTICS_X_API_KEY
            },
            params: {
                action: 'registration-page-view'
            }
        });
        return response.data.pageViews;
    }

    async exportChats(project) {
        const response = await $http.get(
            makeUrl('/projects/{project_id}/stats/external-chat', { projectId: project._id }),
            {
                responseType: 'blob'
            }
        );
        return window.URL.createObjectURL(new Blob([response.data]));
    }

    async exportCrispChats(event){
        const response = await $http.get(
            makeUrl('/projects/{event_id}/stats/crisp', {eventId: event._id}),
            {
                responseType: 'blob'
            }
        );
        return window.URL.createObjectURL(new Blob([response.data]));
    }
    async getHybridUniqueViewers(eventId) {
        const url = makeUrl('/projects/{event_id}/stats/hybrid-unique-viewers', { eventId });
        const response = await $http.get(url);
        return response.data;
    }

    async getHybridUniqueSessions(eventId) {
        const url = makeUrl('/projects/{event_id}/stats/hybrid-unique-sessions', { eventId });
        const response = await $http.get(url);
        return response.data;
    }

    async getHybridRegistrations(projectId) {
        const url = makeUrl('/projects/{project_id}/stats/hybrid-registrations-count', { projectId });
        const response = await $http.get(url);
        return response.data;
    }

    async unregister(projectId, registrationId) {
        const url = makeUrl('/projects/{project_id}/stats/registration/' + registrationId, {projectId});
        await $http.delete(url);
    }

    async update(projectId, registrationId, email) {
        const url = makeUrl('/projects/{project_id}/stats/registration/' + registrationId, {projectId});
        await $http.put(url, {
            email
        });
    }
};

export default new ProjectStatisticsService();
