<template>
    <v-card elevation="0"
            rounded="0"
            class="px-4 breakout-group"
    >
        <v-row class="breakout-item">
            <v-col cols="8"
                   lg="8"
                   md="8"
                   class="text-left pr-0"
            >
                <h5 class="font-weight-bold">
                    {{ breakoutGroupName || 'Breakout group' }}
                </h5>
            </v-col>
            <v-col cols="4"
                   lg="4"
                   md="4"
                   class="text-end pr-0 pl-0"
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-btn icon
                               x-small
                               v-on="on"
                               @click="editBreakoutGroup()"
                        >
                            <v-icon size="20">
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Edit breakout group (without title)</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-btn icon
                               :x-small="true"
                               v-on="on"
                               @click="addBreakoutToGroup()"
                        >
                            <v-icon size="20">
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Add new breakout to group</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <draggable
            v-model="internalValue"
            class="field-dragger"
            ghost-class="ghost"
            handle=".drag-handle"
            @change="emitInput"
        >
            <session-list-item v-for="(item) in internalValue"
                               :key="item.name"
                               class="breakout-item"
            >
                <template v-slot:type>
                    <h5 class="font-weight-regular"
                        style="padding-left: 2px"
                    >
                        <v-icon class="drag-handle">
                            drag_handle
                        </v-icon>
                        Breakout
                    </h5>
                </template>
                <template v-slot:startTime>
                    {{ item.startTimeFormattedTime }}
                </template>
                <template v-slot:duration>
                    {{ item.durationInMinutes + ' min' }}
                </template>
                <template v-slot:maxRegistrations>
                    {{ item.registrationsCountLimit }}
                </template>
                <template v-slot:eventName>
                    {{ item.name }}
                </template>
                <template v-slot:actions>
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-btn icon
                                   :x-small="true"
                                   v-on="on"
                                   @click="initiateDelete(item)"
                            >
                                <v-icon size="20">
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Delete</span>
                    </v-tooltip>
                </template>
            </session-list-item>
        </draggable>
        <delete-dialog v-model="deleteBreakoutModal"
                       @confirm="deleteBreakout"
        >
            <template v-slot:title>
                This will delete the selected breakout.
            </template>
            <template v-slot:content>
                <p class="mt-4">
                    This can't be undone. Are you sure?
                </p>
            </template>
        </delete-dialog>
        <add-to-breakout-group v-if="addToBreakoutModal"
                               v-model="addToBreakoutModal"
                               @confirm="insertBreakout"
        />
        <update-breakout-group v-if="updateBreakoutGroupModal"
                               v-model="updateBreakoutGroupModal"
                               :events="internalValue"
                               @confirm="updateInternalValue"
        />
    </v-card>
</template>
<script>
    import draggable from 'vuedraggable';
    import SessionListItem from '@/components/pages/Project/SessionList/SessionListItem';
    import DeleteDialog from '@/components/controls/dialogs/DeleteDialog';
    import AddToBreakoutGroup from '@/components/pages/Project/SessionList/AddToBreakoutGroup';
    import { toastError } from '@/services/responseErrors';
    import UpdateBreakoutGroup from '@/components/pages/Project/CreateProjectElements/UpdateBreakoutGroup';
    export default {
        name: 'BreakoutGroupEditor',
        components: {UpdateBreakoutGroup, AddToBreakoutGroup, DeleteDialog, SessionListItem, draggable},
        props: {
            value: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                internalValue: [...this.value],
                deleteBreakoutModal: false,
                breakoutToDelete: null,
                addToBreakoutModal: false,
                updateBreakoutGroupModal: false,
            };
        },
        computed: {
            firstBreakout() {
                return this.internalValue[0] || null;
            },
            breakoutGroupName() {
                return this.firstBreakout?.breakoutGroupName || '';
            }
        },
        methods: {
            editBreakoutGroup() {
                this.updateBreakoutGroupModal = true;
            },
            updateInternalValue(newValue) {
                this.internalValue = [...newValue];
                this.emitInput();
            },
            addBreakoutToGroup() {
                this.addToBreakoutModal = true;
            },
            insertBreakout(breakout) {
                const exists = this.internalValue.filter(i => i.name === breakout.name).length > 0;
                if (exists) {
                    return toastError('A breakout with this name already exists: ' + name + '.');
                }
                const newBreakout = {...this.internalValue[this.internalValue.length - 1]};
                newBreakout.name = breakout.name;
                newBreakout.registrationsCountLimit = breakout.registrationsCountLimit;
                this.internalValue = [...this.internalValue, newBreakout];
                this.emitInput();
            },
            initiateDelete(item) {
                this.breakoutToDelete = item;
                this.deleteBreakoutModal = true;
            },
            deleteBreakout() {
                this.internalValue = this.internalValue.filter(i => {
                    return JSON.stringify(i) !== JSON.stringify(this.breakoutToDelete);
                });
                this.emitInput();
            },
            emitInput() {
                this.$emit('input', this.internalValue);
            }
        }
    };
</script>

<style scoped>
.breakout-item{
    border-bottom: 1px solid rgba(0,0,0,.12);
}

.breakout-group {
    border-bottom: 1.5px solid rgba(0,0,0,.35) !important;
}

.ghost {
    border: 1px solid #2977BE;
}

.drag-handle {
    cursor: grabbing;
}
</style>