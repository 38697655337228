import {v4 as uuidv4} from 'uuid';
import FieldOption from '@/models/FieldOption';

class EvaluationField {
    constructor(data) {
        this.id = data.id || uuidv4();
        this.type = data.type;
        this.question = data.question;
        this.required = data.required;

        if (data.options) {
            this.options = data.options.map(o => new FieldOption(o));
        }
    }
}

export default EvaluationField;