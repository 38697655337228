<template>
    <div class="d-inline-flex header-sort">
        <v-tooltip
            bottom
        >
            <template v-slot:activator="{on}">
                <v-btn
                    icon
                    small
                    class="sort-button pb-5 mx-1"
                    :color="isSortedBy && sortDirection === 'asc' ? 'black' : ''"
                    :class="{'is-sorted': isSortedBy}"
                    @click="sort('asc')"
                    v-on="on"
                >
                    <v-icon dark
                            small
                    >
                        mdi-arrow-up
                    </v-icon>
                </v-btn>
            </template>
            <span>{{ tooltipUp }}</span>
        </v-tooltip>
        <v-tooltip
            bottom
        >
            <template v-slot:activator="{on}">
                <v-btn
                    icon
                    small
                    class="sort-button pb-5"
                    :color="isSortedBy && sortDirection === 'desc' ? 'black' : ''"
                    :class="{'is-sorted': isSortedBy}"
                    @click="sort('desc')"
                    v-on="on"
                >
                    <v-icon dark
                            small
                    >
                        mdi-arrow-down
                    </v-icon>
                </v-btn>
            </template>
            <span>{{ tooltipDown }}</span>
        </v-tooltip>
    </div>
</template>
<script>
    export default {
        name: 'ListHeaderSort',
        props: {
            isSortedBy: {
                type: Boolean,
                default: false,
            },
            sortDirection: {
                type: String,
                default: 'desc',
            },
            tooltipUp: {
                type: String,
                default: 'A-Z',
            },
            tooltipDown: {
                type: String,
                default: 'Z-A',
            },
        },
        methods: {
            sort(direction) {
                this.$emit('sort', direction);
            }
        }
    };
</script>

<style scoped>

</style>