<template>
    <v-card class="px-4"
            rounded="0"
            elevation="1"
            style="color: #00000099"
    >
        <v-row>
            <v-col cols="12"
                   md="2"
                   lg="2"
                   class="pr-0"
            >
                <h5 class="text-left">
                    Type
                </h5>
            </v-col>
            <v-col cols="12"
                   lg="3"
                   md="3"
                   class="pr-0 pl-0"
            >
                <h5 class="left">
                    {{ "Mail date & time" }}
                </h5>
            </v-col>
            <v-col cols="12"
                   lg="3"
                   md="3"
                   class="pr-0 pl-0"
            >
                <h5 class=" text-left">
                    Time before/after
                </h5>
            </v-col>
            <v-col cols="12"
                   lg="2"
                   md="2"
                   class="pr-0"
            >
                <h5 class="text-left">
                    Status
                </h5>
            </v-col>
            <v-col cols="12"
                   lg="2"
                   md="2"
            />
        </v-row>
    </v-card>
</template>

<script>
    export default {
        name: 'EmailListHeader',
    };
</script>

<style scoped>

</style>
