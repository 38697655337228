<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="confirmationMailSubject"
                        filled
                        class="text-input"
                        label="Mail subject"
                        :disabled="!edit"
                    />
                </v-col>
            </v-row>
            <rich-text-editor 
                v-model="confirmationMailBody"
                :disabled="!edit" 
                :with-agenda="true"
                :with-event-links="false"
                :with-on-site-start-time="isHybridProject"
                :with-location-address="isHybridProject"
                :with-font-selector="false"
            />
            <div v-if="!project.isArchived">
                <v-btn
                    v-if="!edit"
                    class="mt-5 mr-4 pa-2"
                    color="primary"
                    outlined
                    @click="editEmail"
                >
                    <v-icon class="mr-1">
                        mdi-pencil
                    </v-icon>
                    EDIT CONTENT
                </v-btn>
                <v-btn
                    v-else
                    class="mt-5 mr-4 pa-2"
                    color="primary"
                    @click="saveAndRefresh"
                >
                    <v-icon class="mr-1">
                        mdi-content-save
                    </v-icon>
                    SAVE CONTENT
                </v-btn>
            </div>
        </v-col>
        <v-divider 
            vertical
        />
    </v-row>
</template>

<script>
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import { $http } from '@/services/http';
    import {
        AUTOSAVE_PROJECT_URL,
        populateUrl,
    } from '@/services/ApiService';

    export default {
        name: 'OnSiteConfirmationMailing',
        components: { RichTextEditor},
        data(){
            return {
                edit: false,
                confirmationMailSubject: '',
                confirmationMailBody: '',

            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['currentProjectId', 'isHybridProject']),
        },
        watch:{
            'project.emailSettings.onSiteRegistrationConfirmation.subject': {
                immediate:true,
                handler(value){
                    this.confirmationMailSubject = value;
                }
            },
            'project.emailSettings.onSiteRegistrationConfirmation.bodyText': {
                immediate:true,
                handler(value){
                    this.confirmationMailBody = value;
                }
            }
        },
        methods: {
            ...mapActions('projects', ['saveProject']),
            ...mapMutations('projects', [
                'setOnSiteRegistrationConfirmationBody',
                'setOnSiteRegistrationConfirmationSubject',
            ]),
            editEmail(){
                this.edit = true;
            },
            async saveAndRefresh(){
                this.edit = false;
                this.setOnSiteRegistrationConfirmationSubject(this.confirmationMailSubject);
                this.setOnSiteRegistrationConfirmationBody(this.confirmationMailBody);
                const url = populateUrl(AUTOSAVE_PROJECT_URL, this.currentProjectId);
                await $http.put(url, {
                    'emailSettings.onSiteRegistrationConfirmation.subject' : this.confirmationMailSubject,
                    'emailSettings.onSiteRegistrationConfirmation.bodyText': this.confirmationMailBody,
                });
                this.$emit('refreshPreview');
            }
        },
    };
</script>
<style scoped>
.text-input {
  width: 22rem;
}
</style>
