class Location {
    constructor(data) {
        this.lat = data?.lat || 52.2354219110144,
        this.lng = data?.lng || 5.1719029,
        this.formattedAddress = data?.formattedAddress || '',
        this.zoom = data?.zoom || 17,
        this.image = data?.image || '';
        this.imageUrl = data?.imageUrl || '';    
    }
}

export default Location;
