class User {
    constructor(data) {
        this.id = data.id || null;
        this.email = data.email || '';
        this.name = data.name || '';
        this.productionCompany = data.productionCompany || null;
        this.role = data.role || null;
        this.phoneNumber = data.phoneNumber || '';
        this.clientProjects = data.clientProjects ? [...data.clientProjects] : [],
        this.freelancerProjects = data.freelancerProjects ? [...data.freelancerProjects] : [];
        this.isMyself = data.isMyself || false;
    }
}

export default User;
