<template>
    <div>
        <div class="list-wrapper">
            <email-list-header />
            <v-card v-for="item in project.scheduledEmails"
                    :key="`${project.scheduledEmails.indexOf(item)}`"
                    elevation="0"
                    rounded="0"
                    class="px-4 email-item"
            >
                <v-row>
                    <v-col cols="12"
                           lg="2"
                           md="2"
                           class="left pr-0"
                    >
                        <h5 class="font-weight-regular">
                            {{ emailTypes[item.emailType] }}
                        </h5>
                    </v-col>
                    <v-col cols="12"
                           lg="3"
                           md="3"
                           class="text-left pr-0 pl-0"
                    >
                        <h5 class="font-weight-regular">
                            {{ emailSendDate(item.timeDifferenceInMinutes, item.emailType) }}
                        </h5>
                    </v-col>
                    <v-col cols="12"
                           lg="3"
                           md="3"
                           class="text-left pl-0 pr-0"
                    >
                        <h5 class="font-weight-regular">
                            {{ timeDifference(item.timeDifferenceInMinutes, item.emailType) }}
                        </h5>
                    </v-col>
                    <v-col cols="12"
                           lg="2"
                           md="2"
                           class="text-left pr-0"
                    >
                        <h5 class="font-weight-regular">
                            {{ item.wasSentAt ? `Sent to ${item.nrOfEmailsSent} ${item.nrOfEmailsSent === 1 ? 'user': 'users'}` : 'Scheduled' }}
                        </h5>
                    </v-col>
                    <v-col v-if="!item.wasSentAt"
                           cols="12"
                           lg="2"
                           md="2"
                           class="text-end pr-0 pl-0"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{on}">
                                <v-btn icon
                                       :small="true"
                                       :disabled="project.isArchived"
                                       v-on="on"
                                       @click="editScheduledEmail(item)"
                                >
                                    <v-icon size="20">
                                        mdi-pencil
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{on}">
                                <v-btn icon
                                       :small="true"
                                       :disabled="project.isArchived"
                                       v-on="on"
                                       @click="initiateDelete(item._id)"
                                >
                                    <v-icon size="20">
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Delete</span>
                        </v-tooltip>
                    </v-col>
               
                    <v-col v-else
                           cols="12"
                           lg="2"
                           md="2"
                           class="text-end pr-0 pl-0"
                    >
                        <v-tooltip bottom>
                            <template v-slot:activator="{on}">
                                <v-btn icon
                                       v-on="on"
                                       @click="showPreview(item._id)"
                                >
                                    <v-icon size="20">
                                        mdi-eye
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>View</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{on}">
                                <v-icon size="20"
                                        color="green"
                                        v-on="on"
                                >
                                    mdi-check
                                </v-icon>
                            </template>
                            <span>Sent</span>
                        </v-tooltip>
                    </v-col>
                </v-row>
            </v-card>
            <schedule-mail-dialog :show-email-modal="showEmailModal"
                                  :email="selectedEmail"
                                  @cancelled="cancelled"
            />
        </div>
        <v-row>
            <v-col>
                <v-btn color="primary"
                       outlined
                       :disabled="project.isArchived"
                       @click="showEmailModal = true"
                >
                    <v-icon>mdi-plus</v-icon>
                    ADD A MAIL
                </v-btn>
            </v-col>
        </v-row>
        <v-dialog
            v-model="deleteModal"
            persistent
            max-width="500"
        >
            <v-card>
                <v-card-title class="pb-0">
                    Delete scheduled email
                    <v-spacer />
                    <v-btn icon
                           @click="cancelDelete()"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <p class="font-weight-regular mt-4">
                        Are you sure you want to delete this scheduled email?
                    </p>
                </v-card-text>
                <v-divider />
                <v-card-actions class=" px-4 ">
                    <v-spacer />
                    <v-btn color="error"
                           class="px-2"
                           dark
                           @click="deleteScheduledEmail()"
                    >
                        <v-icon>mdi-delete</v-icon>
                        Delete
                    </v-btn>

                
                    <v-btn color="primary"
                           @click="cancelDelete()"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <email-preview-dialog :email="showEmailPreviewId"
                              :show="showEmailPreviewModal"
                              @cancelModal="cancelPreview"
        />
    </div>
</template>

<script>
    import EmailListHeader from '@/components/pages/Project/Elements/EmailListHeader';
    import ScheduleMailDialog from '@/components/pages/Project/Elements/ScheduleMailDialog';
    import EmailPreviewDialog from '@/components/pages/Project/Elements/EmailPreviewDialog';
    import {
        EMAIL_DELETE_SCHEDULED_EMAIL,
        populateScheduledEmailsUrl
    } from '@/services/ApiService';
    import { $http } from '@/services/http';

    import TimeService from '@/services/TimeService';
    import { mapActions, mapState } from 'vuex';
    import moment from 'moment-timezone';

    export default {
        name: 'EmailList',
        props:{},
        components:{EmailListHeader, ScheduleMailDialog, EmailPreviewDialog},
        data(){
            return {
                showEmailModal: false,
                selectedEmail: null,
                loading : false,
                deleteEmailId: null,
                deleteModal: false,
                showEmailPreviewId: null,
                showEmailPreviewModal: false,
                emailTypes: {
                    reminder: 'Reminder',
                    thank_you: 'Thank You',
                    on_site_reminder: 'On site viewer reminder'
                }
            };
        },
        computed:{
            ...mapState('projects', ['project']),           
        },
        methods:{
            ...mapActions('projects', ['getProject']),
            initiateDelete(id){
                this.deleteEmailId = id;
                this.deleteModal = true;
            },
            cancelDelete(){
                this.deleteModal = false;
                this.deleteEmailId = null;
            },

            deleteScheduledEmail(){
                $http.delete(populateScheduledEmailsUrl(
                                 EMAIL_DELETE_SCHEDULED_EMAIL,
                                 this.$route.params.project_id, this.deleteEmailId),
                             {
                                 timeDifferenceInMinutes: this.selectedTimeInMinutes,
                             })
                    .then(() => {
                        this.getProject(this.$route.params.project_id);
                    });
                this.deleteEmailId = null;
                this.deleteModal = false;
            },
            showPreview(id){
                this.showEmailPreviewId = id;
                this.showEmailPreviewModal = true;
            },
            cancelPreview(){
                this.showEmailPreviewId = null;
                this.showEmailPreviewModal = false;
            },
            cancelled(){
                this.selectedEmail = null;
                this.showEmailModal = false;
            },
            editScheduledEmail(email){
                this.selectedEmail = email;
                this.showEmailModal = true;
            },
            getTimeStemp(timeDifferenceInMinutes, emailType){
                if (emailType === 'thank_you'){
                    return this.project.startTime *1000 + timeDifferenceInMinutes*60000; 
                } else {
                    return this.project.startTime *1000 - timeDifferenceInMinutes*60000; 
                }
            },
            emailSendDate(timeDifferenceInMinutes, emailType){
                const timeStamp = this.getTimeStemp(timeDifferenceInMinutes, emailType);
                return moment(timeStamp).tz(TimeService.defaultTimezone).format('DD/MM/YYYY HH:mm (z)');
            },
            timeDifference(timeDifferenceInMinutes, emailType){
                const emailTimeStamp = this.getTimeStemp(timeDifferenceInMinutes, emailType);
                const eventTimeStamp = this.project.startTime *1000;
                let delta = Math.abs(eventTimeStamp - emailTimeStamp) / 1000;
                const days = Math.floor(delta / 86400);
                const daysString = days ? `${days}d.`: '';
                delta -= days * 86400;
                const hours = Math.floor(delta / 3600) % 24;
                const hoursString = hours ? `${hours}h.`: '';
                delta -= hours * 3600;
                const minutes = Math.floor(delta / 60) % 60;
                const minutesString = minutes ? `${minutes}m.`: '';
                return `${daysString} ${hoursString} ${minutesString} ${emailType === 'thank_you' ? 'after':'before'} start of the event`.trim();
            },       
        }
    };
</script>

<style lang="scss" scoped>

h5{
    display: inline-block;
    vertical-align: sub;
}
.list-wrapper{
    border: 1px solid #ccc  !important;
    border-radius: 2px!important;
}

.email-item:nth-child(even) {
    background-color: rgba(0,0,0,.1)
}

</style>
