<template>
    <v-dialog :value="true"
              max-width="1000"
              persistent
              scrollable
    >
        <v-card>
            <v-card-title>
                Create client
                <v-spacer />
                <v-btn icon
                       @click="close"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-subtitle>
                <client-details
                    v-model="client"
                    class="mt-4"
                    @validate="clientDetailsValidated"
                />
            </v-card-subtitle>
            <v-card-text>
                <project-selector
                    :assigned-projects="client.clientProjects"
                    @assign="assign"
                    @unassign="unassign"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary"
                       :disabled="!valid"
                       @click="confirmCreateClient"
                >
                    Create Client
                </v-btn>
                <v-btn @click="close">
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions } from 'vuex';
    import User from '@/models/User';
    import ClientDetails from './ClientDetails';
    import ProjectSelector from '@/components/controls/user-management/ProjectSelector';
    import { $http } from '@/services/http';
    import { makeUrl } from '@/services/ApiService';

    export default {
        name: 'CreateClientDialog',
        components: { ClientDetails, ProjectSelector },
        data() {
            return {
                client: new User([]),
                valid: false,
            };
        },
        methods: {
            ...mapActions('clients', ['getClients']),
            createClient() {
                this.client = new User([]);
            },
            close() {
                this.$emit('close');
            },
            async confirmCreateClient() {
                await $http.post(makeUrl('/clients'), {
                    name: this.client.name,
                    email: this.client.email,
                    phoneNumber: this.client.phoneNumber,
                    clientProjects: this.client.clientProjects.map(project => project.projectId)
                });
                await this.getClients();
                this.$emit('close');
            },
            clientDetailsValidated(valid) {
                this.valid = valid;
            },
            assign(project) {
                this.client.clientProjects.push(project);
            },
            unassign(project) {
                this.client.clientProjects = this.client.clientProjects.filter((p) => {
                    return p.projectId !== project.projectId;
                });
            },
        },
    };
</script>
