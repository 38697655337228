<template>
    <v-row class="center">
        <v-col cols="12"
               sm="12"
               md="8"
               offset-md="2"
               lg="6"
               offset-lg="0"
        >
            <v-card :loading="loading"
                    style="height: 100%"
            >
                <v-card-title class="text-center justify-center font-weight-regular">
                    Devices
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <custom-pie-chart v-if="!loading"
                                              :data="deviceChartData"
                                              :options="chartOptions"
                            />
                        </v-col>
                        <v-col cols="6"
                               class="d-flex justify-center"
                        >
                            <div>
                                <div v-for="(device, index) in Object.keys(devices) "
                                     :key="device"
                                     class="label-wrapper"
                                >
                                    <div class="colored-badge"
                                         :style="{'background-color' : backgroundColor[index]}"
                                    >
                                        <small class="white--text d-block">{{ devices[device] }} %</small>
                                    </div>
                                    <div>
                                        {{ device }}
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
        <v-col cols="12"
               sm="12"
               md="8"
               offset-md="2"
               lg="6"
               offset-lg="0"
        >
            <v-card :loading="loading"
                    style="height: 100%"
            >
                <v-card-title class="text-center justify-center font-weight-regular">
                    Browsers
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <v-row>
                        <v-col cols="6">
                            <custom-pie-chart v-if="!loading"
                                              :data="browserChartData"
                                              :options="chartOptions"
                            />
                        </v-col>
                        <v-col cols="6"
                               class="d-flex justify-center"
                        >
                            <div>
                                <div v-for="(browser, index) in Object.keys(browsers) "
                                     :key="browser"
                                     class="label-wrapper"
                                >
                                    <div class="colored-badge"
                                         :style="{'background-color' : backgroundColor[index]}"
                                    >
                                        <small class="white--text d-block">{{ browsers[browser] }} %</small>
                                    </div>
                                    <div>
                                        {{ browser }}
                                    </div>
                                </div>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import { DEVICE_STATS_URL, makeUrl } from '@/services/ApiService';
    import UserAgentService from '@/services/UserAgentService';
    import UserAgentDeviceAggregator from '@/services/UserAgentDeviceAggregator';
    import { $http } from '@/services/http';
    import CustomPieChart from '@/components/pages/Project/Elements/CustomPieChart';
    import { pieChartDatasetTemplate } from '@/services/ChartService';
    import UserAgentBrowserAggregator from '@/services/UserAgentBrowserAggregator';

    export default {
        name: 'DeviceStats',
        components: {CustomPieChart},
        data() {
            return {
                devices: [],
                browsers: [],
                loading: true,
                backgroundColor: [
                    '#2977BE',
                    '#2B4390',
                    '#31B6BB',
                    '#961E87',
                    '#4F3480',
                    '#C20C7F',
                    '#EE3A23',
                    '#23A6DF',
                    '#40B649',
                    '#F7A41D',
                    '#CCCCCC',
                    '#B0B0B0',
                    '#AAAAAA',
                    '#909090',
                    '#888888',
                    '#707070',
                ],
            };
        },
        props:{
            selectedEvent: {
                type: Object,
                required: true
            },
        },
        computed: {
            chartOptions() {
                return {
                    legend: {
                        display: false
                    },
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItem, data) {
                                return data.labels[tooltipItem.index] + ': ' + data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index] + '%';
                            }
                        }
                    },
                };
            },
            deviceChartData() {
                return {
                    labels: Object.keys(this.devices),
                    datasets: [
                        pieChartDatasetTemplate('Device', Object.values(this.devices)),
                    ],
                };
            },
            browserChartData() {
                return {
                    labels: Object.keys(this.browsers),
                    datasets: [
                        pieChartDatasetTemplate('Browser', Object.values(this.browsers))
                    ],
                };
            }
        },
        methods: {
            async getData() {
                this.loading = true;
                const {data} = await $http.get(makeUrl(DEVICE_STATS_URL, {eventId: this.selectedEvent._id}));
                const userAgentService = new UserAgentService(data);
                this.devices = userAgentService.aggregate(new UserAgentDeviceAggregator());
                this.browsers = userAgentService.aggregate(new UserAgentBrowserAggregator());
                this.loading = false;
            },
        },
        mounted() {
            this.getData();
        }
    };
</script>

<style scoped lang="scss">
.center {
    align-content: center;
}


.label-wrapper{
    display: flex;
    align-items: center;
    gap: 8px;
    .colored-badge{
        width: 50px;
        height: 15px;
        border-radius: 2px;
        line-height: 15px;
        text-align: right;
        padding-right: 3px;
        small {
            text-shadow: 0 0 2px #000;
        }
    }
}
</style>