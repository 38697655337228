<template>
    <div class=" chat-statistics-count">
        <v-divider v-if="categoriesAsList.length > 0"
                   class="mb-4"
        />
        <v-row v-if="categoriesAsList.length > 0 && !loading"
               class="count-row"
        >
            <v-col v-for="(category, index) in categoriesAsList"
                   :key="category.name"
                   :class="getColorClass(index)"
                   class="count-item"
            >
                <h2 class="font-weight-medium text-center">
                    {{ category.count }} chats in
                </h2>
                <h2 class="text-center">
                    {{ category.name }}
                </h2>
            </v-col>
        </v-row>
        <div v-if="loading"
             class="text-center"
        >
            <div>Loading category counts</div>

            <v-progress-circular v-if="loading"
                                 indeterminate
            />
        </div>
        <v-row>
            <v-col class="justify-center d-flex">
                <v-alert type="info"
                         color="grey"
                         dense
                         outlined
                         max-width="900"
                >
                    Chat messages are not available on the report screen. Please <strong>download the chat report</strong> to get them.
                </v-alert>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import ProjectStatisticsService from '@/services/ProjectStatisticsService';

    export default {
        name: 'ChatCategoryCounts',
        data() {
            return {
                categories: [],
                loading: false,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            categoriesAsList() {
                return Object.keys(this.categories)
                    .sort((a, b) => {
                        if (a === b) return 0;
                        return a > b ? 1 : -1;
                    })
                    .map((key) => {
                        return {
                            name: key,
                            count: this.categories[key]
                        };
                    });
            }
        },
        methods: {
            async loadData() {
                this.loading = true;
                this.categories = await ProjectStatisticsService.getChatCategoryCounts(this.project._id);
                this.loading = false;
            },
            getColorClass(index) {
                return {
                    'purple--text': index === 0,
                    'blue--text': index === 1,
                    'red--text': index === 2,
                    'green--text': index === 3,
                    'indigo--text': index === 4,
                    'pink--text': index === 5,
                };
            },
        },
        mounted() {
            this.loadData();
        }
    };
</script>

<style scoped>
.count-row {
    justify-content: center;
}

.count-item {
    flex-shrink: 0;
    flex-basis: 250px;
    flex-grow: 0;
    border-left: 1px solid #ccc;
    margin-bottom: 1rem;
}

.count-item:first-child,
.count-item:nth-child(5n+1){
    border-left: 0 none transparent;
}

@media(max-width: 1400px) {
    .count-item:nth-child(5n+1){
        border-left: 1px solid #ccc;
    }
    .count-item:nth-child(4n+1){
        border-left: 0 none transparent;
    }
}

@media(max-width: 1150px) {
    .count-item:nth-child(5n+1){
        border-left: 1px solid #ccc;
    }
    .count-item:nth-child(4n+1){
        border-left: 1px solid #ccc;
    }
    .count-item:nth-child(3n+1){
        border-left: 0 none transparent;
    }
}

@media(max-width: 900px) {
    .count-item:nth-child(5n+1){
        border-left: 1px solid #ccc;
    }
    .count-item:nth-child(4n+1){
        border-left: 1px solid #ccc;
    }
    .count-item:nth-child(3n+1){
        border-left: 1px solid #ccc;
    }
    .count-item:nth-child(2n+1){
        border-left: 0 none transparent;
    }
}

@media (max-width: 650px) {
    .count-item {
        border-left: 0 none transparent !important;
    }
}
</style>