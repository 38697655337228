<template>
    <div>
        <validation-observer ref="obs">
            <v-row>
                <v-col cols="12"
                       class="py-0"
                >
                    <validation-provider v-slot="{errors}"
                                         name="clientEmail"
                                         rules="required|email"
                    >
                        <v-text-field v-model="client.email"
                                      :disabled="disabled || hasClientId"
                                      label="E-mail address"
                                      :error-messages="errors"
                                      @keyup="validate"
                        />
                    </validation-provider>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="6"
                       class="py-0"
                >
                    <validation-provider v-slot="{errors}"
                                         name="clientName"
                                         rules="required"
                    >
                        <v-text-field v-model="client.name"
                                      :disabled="disabled"
                                      label="User name"
                                      :error-messages="errors"
                                      @keyup="validate"
                        />
                    </validation-provider>
                </v-col>
                <v-col cols="12"
                       md="6"
                       class="py-0"
                >
                    <v-text-field v-model="client.phoneNumber"
                                  :disabled="disabled"
                                  label="Phone number"
                                  @keyup="validate"
                    />
                </v-col>
            </v-row>
        </validation-observer>
    </div>
</template>

<script>
    import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
    import {required, email} from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });

    extend('email', {
        ...email,
        message: 'This field must contain a valid e-mail address'
    });

    import User from '@/models/User';

    export default {
        name: 'ClientDetails',
        components: {
            ValidationObserver,
            ValidationProvider
        },
        props: {
            value: {
                type: Object,
                default: () => new User([]),
            },
            disabled: {
                type: Boolean,
                default: () => false
            }
        },
        computed: {
            valid() {
                return this.$refs.obs.valid;
            },
            hasClientId() {
                return !!this.client.id;
            }
        },
        watch: {
            'client.name'() {
                this.emitInput();
            },
            'client.email'() {
                this.emitInput();
            },
            'client.phoneNumber'() {
                this.emitInput();
            }
        },
        data() {
            return {
                client: this.value,
            };
        },
        methods: {
            emitInput() {
                this.$emit('input', this.client);
            },
            async validate() {
                const c = await this.$refs.obs.validate();
                this.$emit('validate', c);
            }
        },
    };
</script>

<style scoped>

</style>