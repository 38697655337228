<template>
    <stats-card class="h13 metric smaller"
                :loading="loading"
    >
        <template v-slot:title>
            Avg viewing time
        </template>
        <template v-slot:content>
            {{ avgWatchTimeFormatted }}
            <div class="metric-explain">
                (hours minutes and seconds)
            </div>
        </template>
    </stats-card>
</template>
<script>
    import { fetchStatisticsApi } from '@/services/StatisticsService';
    import TimeService from '@/services/TimeService';
    import StatsCard from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/StatsCard.vue';

    export default {
        name: 'OnDemandAvgWatchTime',
        components: {StatsCard},
        props: {
            dateRange: {
                type: Array,
                required: true
            },
            videoId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                avgWatchTime: 0,
            };
        },
        computed: {
            avgWatchTimeFormatted() {
                if (!this.avgWatchTime) {
                    return '00:00:00';
                }
                return TimeService.fromMsToHis(this.avgWatchTime);
            }
        },
        methods: {
            getAverageWatchTime() {
                this.loading = true;
                fetchStatisticsApi('/api/videos/average-view-time', this.dateRange, this.videoId).then(response => {
                    const item = response.data.filter(item => item.key === this.videoId);
                    this.avgWatchTime = 0;
                    if (item.length) {
                        this.avgWatchTime = item[0].time;
                    }
                }).finally(() => this.loading = false);
            }
        },
        mounted() {
            this.getAverageWatchTime();
        }
    };
</script>

<style scoped>

</style>