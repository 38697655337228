<template>
    <div v-if="hasEventId">
        <div v-if="isBreakoutGroup">
            Breakout Group: {{ breakoutGroupTitle }}
        </div>
        <div v-else>
            <div v-if="event">
                <a v-if="showLink"
                   :href="eventLink"
                   target="_blank"
                >{{ event.name }}</a>
                <span v-else>{{ event.name }}</span>
            </div>
            <div v-else>
                Event {{ eventId }} not found
            </div>
        </div>
    </div>
    <div v-else>
        N/A
    </div>
</template>
<script>
    import { mapState } from 'vuex';
    import { trim } from 'lodash';

    export default {
        name: 'ChangedEvent',
        props: {
            eventId: {
                type: String,
                default: () => null
            },
            showLink: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            ...mapState('projects', ['project']),
            hasEventId() {
                return !!this.eventId;
            },
            isBreakoutGroup() {
                return this.eventId.includes('Breakout Group');
            },
            breakoutGroupTitle() {
                const breakoutGroupId = trim(this.eventId.replace('Breakout Group ID:', ''));
                const event = this.project.events.filter(e => e.breakout_group === breakoutGroupId).shift();
                return event.breakoutGroupName;
            },
            event() {
                const event = this.project.events.filter(e => e._id === this.eventId).shift();
                if (!event) {
                    return null;
                }
                return event;
            },
            eventLink() {
                return this.$router.resolve({
                    name: 'CreateEvent',
                    params: {
                        'project_id': this.project._id,
                        'event_id': this.event._id,
                    }
                }).href;
            }
        }
    };
</script>

<style scoped>

</style>