
export const getEditorLinkList = function (language, withEventLinks, events, isMultiSession, eventTitle) {
    let registrationLink = 'registratiepagina';
    let testLink = 'testpagina';
    let supportLink = 'ondersteuning';
    let privacyLink = 'privacy beleid';
    let evaluationLink = 'evaluatie';
    let eventPageLink = 'evenementpagina';
    let unregisterLink= 'uitschrijfpagina';
    let changeAttendanceLink = 'aanwezigheidswijzigingspagina';

    if(language === 'en'){
        registrationLink = 'registration';
        testLink = 'test';
        supportLink = 'support';
        privacyLink = 'privacy policy';
        evaluationLink = 'evaluation';
        eventPageLink = 'event page';
        unregisterLink = 'unregister page';
        changeAttendanceLink = 'change attendance page';
    } else if(language === 'de'){
        registrationLink = 'Registrierungslink';
        testLink = 'Test';
        supportLink = 'Unterstützung';
        privacyLink = 'Datenschutz-Bestimmungen';
        evaluationLink = 'Bewertungsseite';
        eventPageLink = 'Ereignisseite';
        unregisterLink = 'Abmeldeseite';
    }

    const linkList = [
        {title: registrationLink, value: '{{registration}}'},
        {title: testLink, value: '{{test-page}}'},
        {title: supportLink, value: '{{support}}'},
        {title: privacyLink, value: '{{privacy-policy}}'},
        {title: evaluationLink, value: '{{evaluation}}'},
        {title: unregisterLink, value: '{{unregister-link}}'},
        {title: changeAttendanceLink, value: '{{change-attendance}}'}
    ];

    if(withEventLinks){
        events.map(event => {
            linkList.push(
                {
                    title: isMultiSession ? event.name : eventTitle,
                    value: `{{event-page|${event._id}}}`
                }
            );
        });
    } else {
        linkList.push({title: eventPageLink, value: '{{event-page}}'});
    }

    return linkList;
};