import { REGISTRATION_TYPE_REGISTRATION, REGISTRATION_TYPE_SPRINGER } from '@/services/RegistrationService';
import { $http } from '@/services/http';
import { makeUrl } from '@/services/ApiService';

export const buildSessionList = (events) => {
    const eventList = [];
    let oldBreakoutGroup = null;
    let currentGroup = [];
    for (let x in events) {
        const event = events[x];
        if (event.breakout_group !== oldBreakoutGroup) {
            if (currentGroup.length > 0) {
                eventList.push([...currentGroup]);
            }
            oldBreakoutGroup = event.breakout_group;
            currentGroup = [];
        }
        if (!event.breakout_group) {
            eventList.push(event);
        } else {
            currentGroup.push(event);
        }
        oldBreakoutGroup = event.breakout_group;
    }
    if (currentGroup.length > 0) {
        eventList.push(currentGroup);
    }
    return eventList;
};

export const getEventDashboardUrl = (eventId, registrationType) =>{
    const registrationUrl = process.env.VUE_APP_FRONTEND_REGISTRATION_PAGE_URL.replace('{eventID}', eventId);
    const openUrl = process.env.VUE_APP_FRONTEND_EVENT_PAGE_URL
        .replace('{eventID}', eventId)
        .replace('{registrationID}', 'gm84cf8a74');
    return [REGISTRATION_TYPE_REGISTRATION, REGISTRATION_TYPE_SPRINGER].indexOf(registrationType) !== -1 ? registrationUrl : openUrl;
};

export const deleteEvent = async (projectId, eventId) => {
    const url = makeUrl('/projects/{project_id}/events/{event_id}', {
        projectId,
        eventId
    });
    return await $http.delete(url);
};