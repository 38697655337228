<template>
    <div class="px-4 pb-4">
        <v-text-field
            v-model="subFilter"
            height="29"
            flat
            dense
            solo
            background-color="grey lighten-1"
            hide-details
            :append-icon="subFilter ? undefined : 'search'"
            label="Quick search"
            clearable
        />
    </div>
</template>
<script>
    export default {
        name: 'ListFilterQuickSearch',
        props: {
            value: {
                type: String,
                default: ''
            }
        },
        data() {
            return {
                subFilter: this.value,
            };
        },
        watch: {
            subFilter(value) {
                this.$emit('input', value);
            }
        }
    };
</script>

<style scoped>

</style>