<template>
    <iframe :src="ignoreTriggerHtml"
            class="hidden-iframe"
    />
</template>
<script>
    export default {
        name: 'IgnorePageViewsTrigger',
        computed: {
            ignoreTriggerHtml() {
                return process.env.VUE_APP_IGNORE_STAT_TRIGGER_URL;
            }
        }
    };
</script>

<style scoped>
    .hidden-iframe {
        position: absolute;
        border: 0 none transparent;
        left: 0;
        top: 0;
        width: 1px;
        height: 1px;
        opacity: 50%;
    }
</style>