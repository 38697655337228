<template>
    <div>
        <v-file-input
            :key="currentUrl"
            label="Select file"
            truncate-length="50"
            show-size
            :rules="handoutUploadRules"
            :disabled="project.isArchived"
            @change="uploadFile"
        >
            <template v-slot:append-outer>
                <div class="logo-buttons d-flex">
                    <v-btn
                        v-if="hasUrl"
                        icon
                        dark
                        color="primary"
                        title="Remove"
                        @click="clearUrl"
                    >
                        <v-icon dark>
                            mdi-file-remove
                        </v-icon>
                    </v-btn>
                </div>
            </template>
        </v-file-input>
        <a v-if="!uploading && hasUrl"
           :href="currentUrl"
           target="_blank"
        >{{ currentUrl }}</a>
        <v-progress-circular v-if="uploading"
                             indeterminate
                             color="primary"
        />
    </div>
</template>

<script>
    import { $http } from '@/services/http';
    import { HANDOUT_MAX_FILE_SIZE, handoutValidationRules } from '@/services/fileValidation';
    import {HANDOUT_UPLOAD_URL} from '@/services/ApiService';
    import {mapState} from 'vuex';

    export default {
        name: 'HandoutUpload',
        props: {
            currentUrl: {
                type: String,
                default: '',
            }
        },
        data() {
            return {
                uploading: false,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            hasUrl() {
                return !!this.currentUrl;
            },
            handoutUploadRules() {
                return handoutValidationRules;
            },
        },
        methods: {
            async uploadFile(file) {
                if (file === undefined || file.size > HANDOUT_MAX_FILE_SIZE) {
                    return Promise.reject();
                }
                const formData = new FormData();
                formData.append('handout', file);
                this.uploading = true;
                const res = await $http.post(HANDOUT_UPLOAD_URL, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                this.$emit('input', res.data);
                this.uploading = false;
            },
            clearUrl() {
                this.$emit('input', '');
            }
        }
    };
</script>

<style scoped>

</style>
