<template>
    <div>
        <h2 class="font-weight-regular black--text">
            Project details
        </h2>
        <v-row>
            <v-col cols="12"
                   md="6"
                   lg="4"
            >
                Company name:
                <div class="black--text my-2">
                    {{ value.client.companyName }}
                </div>
            </v-col>
            <v-col cols="12"
                   md="6"
                   lg="4"
            >
                Contact person:
                <div class="black--text my-2">
                    {{ value.client.contactPerson }}
                </div>
            </v-col>
            <v-col cols="12"
                   md="6"
                   lg="4"
            >
                Contact email:
                <div class="black--text my-2">
                    {{ value.client.contactEmail }}
                </div>
            </v-col>
            <v-col cols="12"
                   md="12"
                   lg="4"
            >
                Logo:
                <div>
                    <img
                        :src="value.style.logo"
                        style="max-width: 100%; max-height: 80px"
                    >
                </div>
            </v-col>
        </v-row>
        <v-divider class="mb-4" />
        <h3 class="font-weight-regular black--text">
            Style
        </h3>
        <v-row>
            <!--            <v-col cols="12"-->
            <!--                   md="6"-->
            <!--                   lg="4"-->
            <!--            >-->
            <!--                Background color:-->
            <!--                <div class="black&#45;&#45;text my-2">-->
            <!--                    <color-display :value="value.style.backgroundColor" />-->
            <!--                </div>-->
            <!--            </v-col>-->
            <v-col cols="12"
                   md="6"
                   lg="4"
            >
                Header color:
                <div class="black--text my-2">
                    <color-display :value="value.style.headerColor" />
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import ColorDisplay from '@/components/controls/ColorDisplay';

    export default {
        name: 'ProjectDetails',
        components: { ColorDisplay },
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        client: {
                            companyName: '',
                            contactPerson: '',
                            contactEmail: '',
                        },
                        style: {
                            backgroundColor: 'rgba(240,240,240, 1)',
                            headerColor: 'rgba(0,0,0, 1)',
                            subHeaderColor: 'rgba(127,127,127, 1)',
                            buttonColor: 'rgba(0,0,0,1)',
                            logo: null,
                        },
                    };
                },
            },
        },
    };
</script>

<style scoped>
</style>