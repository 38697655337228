const cssToRgba = function (value) {
    const list = value.replace('rgba(', '')
        .replace(')', '')
        .replace(' ', '')
        .split(',');
    const rgba = {
        r: parseInt(list[0] || 0),
        g: parseInt(list[1] || 0),
        b: parseInt(list[2] || 0),
        a: parseFloat(list[3] || 1)
    };

    return rgba;
};

const rgbaToCss = function (value) {
    return `rgba(${value.r}, ${value.g}, ${value.b}, ${parseFloat(value.a.toFixed(2))})`;
};

const intToHex = function (value) {
    let result = parseInt(value).toString(16);
    if (result.length === 1) {
        result = `0${result}`;
    }
    return result;
};

const opacityToHex = function (value) {
    let v = Math.round((parseFloat(value)) * 255).toString(16);
    if (v.length < 2) {
        v = `0${v}`;
    }
    return v;
};

const splitColorsRGBA = function (rgbaColor) {
    const list = rgbaColor.replace('rgba(', '')
        .replace(')')
        .replace(' ', '')
        .split(',');
    const r = list[0];
    const g = list[1];
    const b = list[2];
    return {r, g, b};
};

const colorDarken = function (r, g, b) {
    const average = (parseInt(r) + parseInt(g) + parseInt(b)) / 3;
    if (average > 192) {
        r = r / 3 * 2;
        g = g / 3 * 2;
        b = b / 3 * 2;
    }
    if (average > 127 && average <= 192) {
        r = r / 3 * 2.5;
        g = g / 3 * 2.5;
        b = b / 3 * 2.5;
    }

    return 'rgb(' + r + ', ' + g + ', ' + b + ')';
};

export {
    cssToRgba,
    intToHex,
    opacityToHex,
    rgbaToCss,
    splitColorsRGBA,
    colorDarken,
};
