<template>
    <stats-card class="h13 metric">
        <template v-slot:title>
            Start Ratio
        </template>
        <template v-slot:content>
            {{ startRatio }} %
        </template>
    </stats-card>
</template>
<script>
    import StatsCard from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/StatsCard.vue';

    export default {
        name: 'OnDemandStartRatio',
        components: {StatsCard},
        props: {
            views: {
                type: Number,
                required: true
            },
            impressions: {
                type: Number,
                required: true
            }
        },
        computed: {
            startRatio() {
                return this.impressions > 0 ? Math.round((this.views / this.impressions) * 100) : 0;
            },
        }
    };
</script>

<style scoped>

</style>