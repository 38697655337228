<template>
    <div>
        <delete-block-dialog
            v-model="showConfirmDeleteBlockModal"
            :block="blockToRemove"
            :save-url="saveUrl"
        />

        <block-sidebar-panel
            v-for="block in blocks"
            :key="block._id"
            :block="block"
            :save-url="saveUrl"
            :dynamic-block-kind="dynamicBlockKind"
        >
            <template v-slot:header-buttons>
                <v-tooltip right>
                    <template v-slot:activator="{on}">
                        <v-btn icon
                               small
                               color="error"
                               class="mt-1 mr-5"
                               :disabled="project.isArchived"
                               v-on="on"
                               @click.stop="askConfirmationForBlockRemoval(block)"
                        >
                            <v-icon small>
                                mdi-delete
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Delete block</span>
                </v-tooltip>
            </template>

            <template v-if="block.componentType === 'column'"
                      v-slot:after-content
            >
                <block-sidebar-panel
                    v-for="subBlock in block.componentOptions.blocks"
                    :key="subBlock._id"
                    :block="subBlock"
                    :save-url="saveUrl"
                    :dynamic-block-kind="dynamicBlockKind"
                >
                    <template v-slot:header-buttons>
                        <v-tooltip right>
                            <template v-slot:activator="{on}">
                                <v-btn icon
                                       small
                                       color="error"
                                       class="mt-1 mr-5"
                                       :disabled="project.isArchived"
                                       v-on="on"
                                       @click.stop="askConfirmationForBlockRemoval(subBlock)"
                                >
                                    <v-icon small>
                                        mdi-delete
                                    </v-icon>
                                </v-btn>
                            </template>
                            <span>Delete block</span>
                        </v-tooltip>
                    </template>
                </block-sidebar-panel>
            </template>
        </block-sidebar-panel>
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    import DeleteBlockDialog from '@/components/pages/Project/EventPage/DeleteBlockDialog.vue';
    import BlockSidebarPanel from '@/components/pages/Project/EventPage/BlockSidebarPanel.vue';

    export default {
        name: 'BlockSidebar',
        props: {
            saveUrl: {
                type: String,
                required: true,
            },
            dynamicBlockKind: {
                type: String,
                required: true,
            }
        },
        computed: {
            ...mapState('dynamic-block', ['blocks']),
            ...mapState('projects', ['project'])
        },
        components: {
            DeleteBlockDialog,
            BlockSidebarPanel,
        },
        data() {
            return {
                showConfirmDeleteBlockModal: false,
                blockToRemove: null,
            };
        },
        methods: {
            askConfirmationForBlockRemoval(block) {
                this.showConfirmDeleteBlockModal = true;
                this.blockToRemove = block;
            },
        },
    };
</script>