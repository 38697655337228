export const LOGO_MAX_FILE_SIZE = 100000;
export const BACKGROUND_MAX_FILE_SIZE = 500000;
export const SPEAKER_AVATAR_MAX_FILE_SIZE = 300000;
export const HANDOUT_MAX_FILE_SIZE = 5000000;
export const DYNAMIC_BLOCK_IMAGE_MAX_FILE_SIZE = 5000000;
export const LOCATION_MAX_FILE_SIZE = 2000000;

export const logoValidationRules = [
    v => !v || v.size < LOGO_MAX_FILE_SIZE || 'Logo size should be less than 100 KB!',
];

export const faviconValidationRules = [
    v => !v || v.size < LOGO_MAX_FILE_SIZE || 'Favicon size should be less than 100 KB!',
];

export const backgroundValidationRules = [
    v => !v || v.size < BACKGROUND_MAX_FILE_SIZE || 'Background image size should be less than 500 KB!',
];

export const speakerAvatarValidationRules = [
    v => !v || v.size < SPEAKER_AVATAR_MAX_FILE_SIZE || 'Avatar size should be less than 300 KB!',
];

export const handoutValidationRules = [
    v => !v || v.size < HANDOUT_MAX_FILE_SIZE || 'Handout size should be less than 5 MB!',
];

export const dynamicBlockImageValidationRules = [
    v => !v || v.size < DYNAMIC_BLOCK_IMAGE_MAX_FILE_SIZE || 'Block image size should be less than 500 KB!',
];
