import { get } from 'lodash';

class Style {
    constructor(data) {
        this.primaryColor = data?.primaryColor || 'rgba(255,255,255,1)';
        this.logo = data?.logo || '';
        this.logoUrl = data?.logoUrl || '';
        this.background = data?.background || '';
        this.backgroundUrl = data?.backgroundUrl || '';        
        this.backgroundGradient = get(data, 'backgroundGradient', true);
        this.customLogoHeightRegistrationPage = data?.customLogoHeightRegistrationPage || null;
        this.customLogoWidthInPercentRegistrationPage = data?.customLogoWidthInPercentRegistrationPage || null;
        if(data.buttonBorderRadius === null){
            this.buttonBorderRadius = 4;
        } else {
            this.buttonBorderRadius = data.buttonBorderRadius;
        }
        if(data.labelBorderRadius === null){
            this.labelBorderRadius = 20;
        } else {
            this.labelBorderRadius = data.labelBorderRadius;
        }
        if(data.inputBorderRadius === null){
            this.inputBorderRadius = 0;
        } else {
            this.inputBorderRadius = data.inputBorderRadius;
        }
        if(data.cardBorderRadius === null){
            this.cardBorderRadius = 4;
        } else {
            this.cardBorderRadius = data.cardBorderRadius;
        }
        this.fontFamily = data?.fontFamily || 'archivo';
        this.favicon = data?.favicon || '';
        this.faviconUrl = data?.faviconUrl || '';
    }
}

export default Style;
