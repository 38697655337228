<template>
    <div class="px-8">
        <registration-list-header />
        <div v-if="!loading">
            <v-card v-for="item in items"
                    :key="`${items.indexOf(item)}`"
                    class="mt-1"
            >
                <v-card-text class="px-0">
                    <v-row class="mx-0">
                        <v-col v-if="canShowComponent"
                               cols="12"
                               lg="1"
                               md="1"
                               sm="1"
                               class="vertical-center-text"
                        >
                            {{ item.name }}
                        </v-col>
                        <v-col v-if="canShowComponent"
                               cols="12"
                               lg="2"
                               md="2"
                               sm="2"
                               class="vertical-center-text"
                        >
                            {{ item.email }}
                        </v-col>
                        <v-col cols="12"
                               lg="2"
                               md="2"
                               sm="2"
                               class="vertical-center-text"
                               style="display:block"
                        >
                            <span v-if="item.registrationTimestamp">
                                {{ item.registrationTimestamp.split(" ")[0] }}
                                <br>
                                {{ `${item.registrationTimestamp.split(" ")[1]} ${item.registrationTimestamp.split(" ")[2]}` }}</span>
                            <v-tooltip right>
                                <template v-slot:activator="{on}">
                                    <v-icon v-if="item.onSiteViewer"
                                            class="ml-2 on-site-marker"
                                            v-on="on"
                                    >
                                        mdi-map-marker-check
                                    </v-icon>
                                </template>
                                <span>On-site registration</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="12"
                               lg="2"
                               md="2"
                               sm="2"
                               class="vertical-center-text"
                        >
                            {{ item.eventName }}
                        </v-col>
                        <v-col cols="12"
                               :lg="canShowComponent ? '1':'2'"
                               :md="canShowComponent ? '1':'2'"
                               :sm="canShowComponent ? '1':'2'"
                               class="vertical-center-text"
                               style="justify-content: center"
                        >
                            <v-icon v-if="item.attended"
                                    color="green"
                            >
                                mdi-check
                            </v-icon>
                            <v-icon v-else
                                    color="red"
                            >
                                mdi-close
                            </v-icon>
                        </v-col>
                        <v-col cols="12"
                               :lg="canShowComponent ? '1':'2'"
                               :md="canShowComponent ? '1':'2'"
                               :sm="canShowComponent ? '1':'2'"
                               style="display:block"

                               class="vertical-center-text"
                        >
                            <span v-if="item.joinedTime">
                                {{ item.joinedTime.split(" ")[0] }}
                                <br>
                                {{ `${item.joinedTime.split(" ")[1]} ${item.joinedTime.split(" ")[2]}` }}</span>
                        </v-col>
                        <v-col cols="12"
                               :lg="canShowComponent ? '1':'2'"
                               :md="canShowComponent ? '1':'2'"
                               :sm="canShowComponent ? '1':'2'"
                               class="vertical-center-text"
                               style="display:block"
                        >
                            <span v-if="item.exitTime">
                                {{ item.exitTime.split(" ")[0] }}
                                <br>
                                {{ `${item.exitTime.split(" ")[1]} ${item.exitTime.split(" ")[2]}` }}</span>
                        </v-col>
                        <v-col cols="12"
                               lg="2"
                               md="2"
                               sm="2"
                               class="vertical-center-text"
                               style="justify-content: center"
                        >
                            {{ item.attendanceInPercent + "%" }}
                            <div v-if="isQuadiaLiveAdmin"
                                 class="action-container"
                            >
                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <v-btn icon
                                               small
                                               class="ml-2"
                                               :disabled="saving || deleting || started"
                                               :loading="isSaving(item)"
                                               v-on="on"
                                               @click="editRegistrant(item)"
                                        >
                                            <v-icon small>
                                                edit
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Edit e-mail address</span>
                                </v-tooltip>
                                <v-tooltip top>
                                    <template v-slot:activator="{on}">
                                        <v-btn icon
                                               small
                                               class="ml-2"
                                               color="error"
                                               :disabled="saving || deleting || started"
                                               :loading="isDeleting(item)"
                                               v-on="on"
                                               @click="deleteRegistrantQuery(item)"
                                        >
                                            <v-icon small>
                                                delete
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Delete registrant</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
        <v-progress-linear v-else
                           indeterminate
        />
        <confirm-modal ref="deleteDialog"
                       value="false"
        >
            <template v-slot:title>
                This will remove the selected registration
            </template>
            <template v-slot:content>
                <p>The following registrant will be deleted: </p>
                <p><strong>{{ registrantEmail }}</strong></p>
                <p>This action cannot be undone. The registrant will not be able to view the event.</p>
                <p>Are you sure?</p>
            </template>
        </confirm-modal>
        <confirm-modal ref="editModal"
                       value="false"
        >
            <template v-slot:title>
                Edit registrant
            </template>
            <template v-slot:content="{toggleConfirm}">
                <v-text-field ref="emailEditor"
                              v-model="editedEmail"
                              type="email"
                              :rules="editedEmailRules"
                              @input="() => validate(toggleConfirm)"
                />
            </template>
        </confirm-modal>
    </div>
</template>

<script>
    import RegistrationListHeader from './RegistrationListHeader';
    import { mapActions, mapGetters, mapState } from 'vuex';
    import ConfirmModal from '@/components/controls/dialogs/ConfirmModal.vue';

    export default {
        name: 'RegistrationList',
        components: {ConfirmModal, RegistrationListHeader },
        props: {
            loading: {
                type: Boolean,
                default: () => false,
            },
            items: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                selectedRegistrant: null,
                editedEmail: '',
                saving: false,
                deleting: false,
                editedEmailRules: [
                    value => !!value || 'Required.',
                    value => value !== this.registrantEmail || 'Email unchanged.',
                    value => {
                        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        return pattern.test(value) || 'Invalid e-mail.';
                    },
                ],
            };
        },
        computed: {
            ...mapGetters('auth', ['canSeeRegistrationData', 'isQuadiaLiveAdmin']),
            ...mapGetters('projects', ['isHybridProject']),
            ...mapState('projects', ['project']),
            canShowComponent() {
                return this.canSeeRegistrationData(this.project);
            },
            registrantEmail() {
                return this.selectedRegistrant?.email ?? '';
            },
            isSaving() {
                return item => item === this.selectedRegistrant && this.saving;
            },
            isDeleting() {
                return item => item === this.selectedRegistrant && this.deleting;
            },
            started() {
                const currentTime = (new Date()).getTime() / 1000;
                return this.project.startTime < currentTime;
            }
        },
        methods: {
            ...mapActions('statistics', ['deleteRegistrant', 'updateRegistrant']),
            validate(toggleConfirm) {
                toggleConfirm(this.$refs.emailEditor.validate());
            },

            async editRegistrant(registrant) {
                this.selectedRegistrant = registrant;
                this.editedEmail = registrant.email;
                this.$refs.editModal.toggleConfirm(false);
                const result = await this.$refs.editModal.open();
                if (result) {
                    this.saving = true;
                    await this.updateRegistrant({
                        projectId: this.project._id,
                        registrationId: registrant.id,
                        email: this.editedEmail,
                    });
                    this.saving = false;
                    this.selectedRegistrant = null;
                    this.$emit('update');
                }
            },

            async deleteRegistrantQuery(registrant) {
                this.selectedRegistrant = registrant;
                const result = await this.$refs.deleteDialog.open();
                if (result) {
                    this.deleting = true;
                    await this.deleteRegistrant({
                        projectId: this.project._id,
                        registrationId: registrant.id,
                    });
                    this.deleting = false;
                    this.selectedRegistrant = null;
                    this.$emit('update');
                }
            }
        },
        mounted() {},
    };
</script>

<style scoped>
.vertical-center-text {
    display: flex;
    align-items: center;
    word-break: break-word;
}
.on-site-marker {
    margin-top: -20px;
    font-size: 42px !important;
}

.action-container {
    flex: 1 1 0;
    text-align: right;
}
</style>
