<template>
    <div>
        <draggable
            v-model="fields"
            class="field-dragger"
            ghost-class="ghost"
            handle=".drag-handle"
            :options="{disabled : disabled}"
        >
            <v-card
                v-for="field in fields"
                :key="`field-${field.id}`"
                class="item-row pt-md-2 mt-md-2 mb-2"
                elevation="1"
                :class="{
                    'field-error':
                        fieldValidationResult.field &&
                        fieldValidationResult.field === field,
                    'field-active': isActiveField(field),
                }"
                @click="activateField(field)"
            >
                <v-card-text class="py-0">
                    <v-row>
                        <div class="flex-grow-0 drag-handle">
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-icon
                                        color="grey"
                                        class="mt-2 ml-2 mr-4"
                                        v-on="on"
                                    >
                                        drag_handle
                                    </v-icon>
                                </template>
                                <span>Drag to reorder</span>
                            </v-tooltip>
                            <div class="flex-grow-1">
                                <slot
                                    name="identifier-field"
                                    :field="field"
                                />
                            </div>
                        </div>
                        <v-col cols="12"
                               lg="5"
                               sm="8"
                               class="py-0"
                        >
                            <v-text-field
                                v-model="field.question"
                                class="mt-0"
                                hide-details
                                :disabled="disabled"
                                @input="validateFieldsDebounced"
                            />
                        </v-col>
                        <v-col cols="12"
                               lg="3"
                               sm="5"
                               class="py-0"
                        >
                            <v-select
                                v-model="field.type"
                                :items="fieldTypeOptions"
                                item-text="label"
                                item-value="value"
                                class="mt-0"
                                hide-details
                                :disabled="disabled"
                                @input="fieldTypeChanged(field)"
                            />
                        </v-col>
                        <v-col
                            class="pt-2 pb-2 pl-3 pr-0 text-center"
                        >
                            <v-btn
                                v-if="fieldHasOptions(field) && field.type !== 'scale' && field.type !== 'star-rating'"
                                icon
                                :disabled="disabled"
                                @click="editFieldOptions(field)"
                            >
                                <v-icon v-if="optionsOpen === field.id">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else>
                                    mdi-chevron-down
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="10"
                            lg="1"
                            sm="1"
                            class="pt-3 pb-2 pr-0 mr-0 d-flex justify-center"
                        >
                            <v-switch
                                v-model="field.required"
                                class="mt-0 mr-0 pr-0 required"
                                hide-details
                                :disabled="disabled"
                            />
                        </v-col>
                        <v-col cols="12"
                               lg="1"
                               sm="1"
                               class="pt-2 pb-2 text-end"
                        >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        icon
                                        color="error"
                                        class="mt-0"
                                        :disabled="disabled"
                                        v-on="on"

                                        @click="deleteField(field)"
                                    >
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="
                            fieldHasOptions(field) && optionsOpen === field.id
                        "
                    >
                        <v-col cols="8"
                               offset="2"
                               class="py-0"
                        >
                            <EvaluationFieldOptionEditorCard
                                :key="'editor-for-' + editedField.id + editedField.type"
                                v-model="optionEditor"
                                :field="editedField"
                                @confirm="updateField"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </draggable>
        <v-row>
            <div v-if="!fieldValidationResult.valid"
                 class="mx-4"
            >
                <div
                    v-if="fieldValidationResult.reason === fieldOptionDuplicate"
                    class="error--text"
                >
                    There are fields with duplicate options
                </div>
                <div
                    v-if="fieldValidationResult.reason === fieldOptionNone"
                    class="error--text"
                >
                    There are fields with no options
                </div>
                <div
                    v-if="fieldValidationResult.reason === fieldNoQuestion"
                    class="error--text"
                >
                    There are fields with no question
                </div>
            </div>
        </v-row>

        <FieldOptionDeleteModal
            v-model="deleteModal"
            :field="fieldToDelete"
            @confirm="confirmDeleteField"
            @cancel="closeDeleteModal"
        />
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import {
        EVALUATION_FIELD_TYPES,
        EVALUATION_FIELD_VALIDATION_NO_QUESTION,
        EVALUATION_FIELD_VALIDATION_OPTION_DUPLICATE,
        EVALUATION_FIELD_VALIDATION_OPTION_NONE,
        fieldHasOptions,
        validateEvaluationFields,
    } from '@/services/EvaluationFieldService';
    import EvaluationField from '@/models/EvaluationField';
    import { debounce } from 'lodash';
    import EvaluationFieldOptionEditorCard from '@/components/controls/field-editor/EvaluationFieldOptionEditorCard';
    import FieldOptionDeleteModal from '@/components/controls/field-editor/FieldOptionDeleteModal';
    import { v4 as uuidv4 } from 'uuid';
    import FieldOption from '@/models/FieldOption';

    export default {
        name: 'EvalutaionFieldRow',
        components: { FieldOptionDeleteModal, EvaluationFieldOptionEditorCard, draggable },
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            fixedFields: {
                type: Array,
                default: () => [],
            },
            disabled: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                fields: [...this.value],
                editedField: null,
                optionEditor: false,
                fieldToDelete: null,
                deleteModal: false,
                fieldValidationResult: {
                    valid: true,
                    reason: '',
                },
                optionsOpen: null,
                activeField: null,
            };
        },
        computed: {
            fieldTypeOptions() {
                return EVALUATION_FIELD_TYPES;
            },
            fieldOptionNone() {
                return EVALUATION_FIELD_VALIDATION_OPTION_NONE;
            },
            fieldOptionDuplicate() {
                return EVALUATION_FIELD_VALIDATION_OPTION_DUPLICATE;
            },
            fieldNoQuestion() {
                return EVALUATION_FIELD_VALIDATION_NO_QUESTION;
            },
        },
        watch: {
            fields: {
                deep: true,
                handler(value) {
                    this.emitFields(value);
                },
            }
        },
        methods: {
            fieldHasOptions(field) {
                return fieldHasOptions(field);
            },
            emitFields: debounce(function (value) {
                this.$emit('input', value);
            }),
            getOptionsFieldBadgeColor(field) {
                return (field.options || []).length > 0 ? 'info' : 'error';
            },
            editFieldOptions(field) {
                if (this.optionsOpen == field.id) {
                    this.closeOptionEditor();
                } else {
                    this.openOptionEditor(field);
                }
            },
            openOptionEditor(field){
                this.optionsOpen = field.id;
                this.editedField = new EvaluationField({ ...field });
                this.optionEditor = true;
            },
            closeOptionEditor(){
                this.optionsOpen = null;
                this.optionEditor = false;
            },
            updateField() {
                this.fields = this.fields.map((f) => {
                    if (f.id === this.editedField.id) {
                        return this.editedField;
                    }

                    return f;
                });

                this.validateEvaluationFields();
                this.emitFields(this.fields);
            },
            deleteField(field) {
                this.fieldToDelete = field;
                this.deleteModal = true;
            },
            closeDeleteModal() {
                this.deleteModal = false;
                this.$nextTick(() => {
                    this.fieldToDelete = null;
                });
            },
            confirmDeleteField() {
                this.fields = this.fields.filter(
                    (f) => f.id !== this.fieldToDelete.id
                );
                this.emitFields(this.fields);

                this.closeDeleteModal();
                this.validateEvaluationFields();
            },
            validateEvaluationFields() {
                this.fieldValidationResult = validateEvaluationFields(this.fields);
                this.$emit('validate', this.fieldValidationResult);
            },
            validateFieldsDebounced() {
                clearInterval(this.$options.validationInterval);
                this.$options.validationInterval = setTimeout(
                    this.validateEvaluationFields,
                    400
                );
            },
            isActiveField(field) {
                return this.activeField === field;
            },
            activateField(field) {
                this.activeField = field;
            },
            fieldTypeChanged(field){
                this.validateFieldsDebounced();
                this.fields = this.fields.map(f => {
                    if(f.id === field.id && f.type === 'star-rating'){
                        f.options = [];
                        let count = 1;
                        while(count <= 5){
                            f.options.push(
                                new FieldOption({
                                    id: uuidv4(),
                                    text: count.toString(),
                                }));
                            count = count + 1;
                        }
                    }
                    if(f.id === field.id && f.type === 'scale'){
                        f.options = [];
                        let count = 1;
                        while(count <= 10){
                            f.options.push(
                                new FieldOption({
                                    id: uuidv4(),
                                    text: count.toString(),
                                }));
                            count = count + 1;
                        }
                    }
                    return f;
                });
                if(fieldHasOptions(field) && field.type !== 'star-rating' && field.type !== 'scale'){   
                    field.options = [];
                    if(this.optionsOpen === field.id){
                        this.closeOptionEditor();
                    }
                    this.openOptionEditor(field);
                }
            }
        },
        mounted() {
            this.validateEvaluationFields();
        },
    };
</script>

<style scoped
       lang="scss"
>
@import "src/_scss/components/colors";

.item-row {
    border: 1px solid transparent;
    border-top: 1px solid #f2f2f2;
    border-left-width: 3px;
    position: relative;

    .drag-handle {
        cursor: grab;
    }

    &.field-active {
        border: 1px solid $primary;
        border-left-width: 3px;
    }

    &.field-error {
        border: 1px solid $error;
        border-left-width: 3px;
    }
}

.ghost {
    border: 1px solid $primary;
}

.required {
    margin-top: 0rem;
    padding: 0rem;
    font-size: 0.74rem !important;
    line-height: 1.18rem;
}

</style>
