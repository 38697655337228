<template>
    <div>
        <div class="list-wrapper">
            <sessions-list-header class="session" />
            <div v-for="(item, index) in internalEvents"
                 :key="getInternalKey(item)"
                 class="newlist"
            >
                <v-card v-if="isEvent(item)"
                        elevation="0"
                        rounded="0"
                        class="px-4 session"
                >
                    <session-list-item>
                        <template v-slot:type>
                            <h5 class="font-weight-bold">
                                Session
                            </h5>
                        </template>
                        <template v-slot:startTime>
                            {{ item.startTimeFormattedTime }}
                        </template>
                        <template v-slot:duration>
                            {{ item.durationInMinutes + ' min' }}
                        </template>
                        <template v-slot:eventName>
                            {{ item.name }}
                        </template>
                        <template v-slot:actions>
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        icon
                                        :x-small="true"
                                        v-on="on"
                                        @click="editSession(item)"
                                    >
                                        <v-icon size="20">
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-btn icon
                                           :x-small="true"
                                           v-on="on"
                                           @click="initiateDelete(item)"
                                    >
                                        <v-icon size="20">
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                        </template>
                    </session-list-item>
                </v-card>
                <breakout-group-editor v-else
                                       v-model="internalEvents[index]"
                                       @input="emitChange"
                />
            </div>
        </div>
        <v-row>
            <v-col>
                <v-btn color="primary"
                       outlined
                       class="mr-3"
                       @click="addSession"
                >
                    <v-icon>mdi-plus</v-icon>
                    Session
                </v-btn>
                <v-btn color="primary"
                       outlined
                       @click="showBreakoutModal = true"
                >
                    <v-icon>mdi-plus</v-icon>
                    Breakout
                </v-btn>
            </v-col>
        </v-row>
        <delete-dialog v-model="showDeleteDialog"
                       @confirm="deleteEvent"
        >
            <template v-slot:title>
                This will delete this session
            </template>
            <template v-slot:content>
                <p class="mt-4">
                    This can't be undone. Are you sure?
                </p>
            </template>
        </delete-dialog>
        <session-dialog v-if="showSessionModal"
                        v-model="showSessionModal"
                        :session="selectedSession"
                        @confirm="handleSession"
        />
        <create-breakout-group v-if="showBreakoutModal"
                               v-model="showBreakoutModal"
                               @confirm="insertBreakoutGroup"
        />
    </div>
</template>

<script>
    import SessionsListHeader from '@/components/pages/Project/SessionList/SessionsListHeader';
    import BreakoutGroupEditor from '@/components/pages/Project/CreateProjectElements/BreakoutGroupEditor';
    import SessionListItem from '@/components/pages/Project/SessionList/SessionListItem';
    import DeleteDialog from '@/components/controls/dialogs/DeleteDialog';
    import CreateBreakoutGroup from '@/components/pages/Project/SessionList/CreateBreakoutGroup';
    import SessionDialog from '@/components/pages/Project/CreateProjectElements/SessionDialog';
    import { buildSessionList } from '@/services/SessionService';

    export default {
        name: 'SessionsList',
        props:{
            events: {
                type: Array,
                default: () => []
            },
        },
        components:{
            SessionDialog,
            CreateBreakoutGroup,
            DeleteDialog,
            SessionListItem,
            BreakoutGroupEditor,
            SessionsListHeader,
        },
        data(){
            return {
                internalEvents: buildSessionList(this.events),
                showDeleteDialog: false,
                showBreakoutModal: false,
                showSessionModal: false,
                eventToDelete: null,
                selectedSession: null,
            };
        },
        methods:{
            sortAndFlatten(value) {
                const sorted = value.sort((a, b) => {
                    const aValue = this.isEvent(a) ? a.startTime : a.find(x => x !== undefined).startTime;
                    const bValue = this.isEvent(b) ? b.startTime : b.find(x => x !== undefined).startTime;
                    return aValue - bValue;
                });
                let result = [];
                sorted.forEach(x => {
                    if (this.isEvent(x)) {
                        result = [...result, x];
                    } else {
                        result = [...result, ...x];
                    }
                });
                return result;
            },
            getInternalKey(item) {
                const keyVariant = Array.isArray(item) ? item.find(x => x !== undefined)?.startTime || Math.random() : item.startTime;
                return `create-event-list-${keyVariant}`;
            },
            isEvent(item) {
                return !Array.isArray(item) && typeof item === 'object';
            },
            addSession() {
                this.selectedSession = null;
                this.showSessionModal = true;
            },
            handleSession(session) {
                if (!this.selectedSession) {
                    this.internalEvents = [...this.internalEvents, session];
                } else {
                    this.internalEvents = this.internalEvents.map(e => e === this.selectedSession ? session : e);
                }
                this.selectedSession = null;
                this.emitChange();
            },
            initiateDelete(event){
                this.eventToDelete = event;
                this.showDeleteDialog = true;
            },
            insertBreakoutGroup(breakoutGroup) {
                this.internalEvents = [...this.internalEvents, breakoutGroup];
                this.emitChange();
            },
            deleteEvent() {
                this.internalEvents = this.internalEvents.filter(item => {
                    return !(this.isEvent(item)
                        && item.name === this.eventToDelete.name
                        && item.startTime === this.eventToDelete.startTime
                        && item.duration === this.eventToDelete.duration);
                });
                this.emitChange();
            },
            editSession(session){
                this.selectedSession = session;
                this.showSessionModal = true;
            },
            emitChange() {
                this.internalEvents = this.internalEvents.filter(x => !(!this.isEvent(x) && x.length === 0));
                const r = this.sortAndFlatten(this.internalEvents);
                this.$emit('updateEvents', r);
            }
        },
    };
</script>

<style lang="scss" scoped>
h5{
    display: inline-block;
    vertical-align: sub;
}

.list-wrapper{
    border: 1px solid #ccc  !important;
    border-radius: 2px!important;
}

.session {
    border-bottom: 1.5px solid rgba(0,0,0,.35) !important;
}

</style>
