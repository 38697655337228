<template>
    <v-row>
        <v-col cols="12"
               lg="2"
               md="2"
               class="left pr-0"
        >
            <slot name="type" />
        </v-col>
        <v-col cols="12"
               lg="2"
               md="2"
               class="text-left pr-0"
        >
            <h5 class="font-weight-regular">
                <slot name="startTime" />
            </h5>
        </v-col>
        <v-col cols="12"
               lg="2"
               md="2"
               class="text-left pl-0 pr-0"
        >
            <h5 class="font-weight-regular text-no-wrap">
                <slot name="duration" />
            </h5>
        </v-col>
        <v-col cols="12"
               lg="1"
               md="1"
               class="text-left pl-0 pr-0"
        >
            <h5 class="font-weight-regular text-no-wrap">
                <slot name="maxRegistrations" />
            </h5>
        </v-col>
        <v-col cols="12"
               lg="4"
               md="4"
               class="text-left pr-0 session-title-wrapper pt-3"
        >
            <h5 class="font-weight-regular text-no-wrap session-title">
                <slot name="eventName" />
            </h5>
        </v-col>
        <v-col cols="12"
               lg="1"
               md="1"
               class="text-end pr-0 pl-0"
        >
            <slot name="actions" />
        </v-col>
    </v-row>
</template>
<script>
    export default {
        name: 'SessionListItem'
    };
</script>

<style scoped lang="scss">
.session-title-wrapper{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: #1976d2 !important;

    .session-title{
        display: contents;
    }
}
</style>