const BLOCK_LEVEL = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'blockquote', 'dd', 'dl', 'dt', 'ul', 'li', 'td', 'th', 'div', 'address'];

export const COMMANDS = {
    BOLD: 'cmd_customBold'
};

export const FORMATS = {
    UNBOLD: 'fmt_customUnBold',
};

function currentSelectionIsBold(editor) {
    let currentNode = getSelectionNode(editor);
    while (currentNode.nodeName.toLowerCase() !== 'body') {
        const computedStyle = window.getComputedStyle(currentNode);
        const fontWeight = computedStyle['font-weight'];
        if (fontWeight === 'normal' || fontWeight < 500) {
            return false;
        }
        // Check if the node is the requested tag
        if (isBold (currentNode))
            return true;
        // Move up in the tree
        currentNode = currentNode.parentNode;
    }
    return false;
}

const getSelectionNode = function(editor) {
    const currentNode = editor.selection.getNode();
    const start = editor.selection.getStart();
    return  start === currentNode ? currentNode : start;
};

const isBold = function (node) {
    const style = window.getComputedStyle(node);
    return style['font-weight'] === 'bold' || style['font-weight'] >= 500;
};

const parentIsBlockAndBold = (n) => {
    if (!n) {
        return false;
    }
    if (n.nodeType === Node.ELEMENT_NODE) {
        if (n.nodeName.toLowerCase() === 'body') {
            return false;
        }
        if (BLOCK_LEVEL.indexOf(n.nodeName.toLowerCase()) > -1) {
            const cs = window.getComputedStyle(n);
            if (cs.fontWeight === 'bold' || cs.fontWeight >= 500) {
                return true;
            }
        }
    }
    return parentIsBlockAndBold(n.parentNode);
};

export function addCustomBold(editor) {
    editor.ui.registry.addToggleButton('customBoldBtn', {
        tooltip: 'Bold',
        icon: 'bold',
        // cmd: COMMANDS.BOLD,
        onAction: function () {
            editor.execCommand(COMMANDS.BOLD);
        },
        onSetup: function (buttonApi) {
            editor.formatter.register(FORMATS.UNBOLD, [{
                inline: 'span',
                selector: 'span.custom-unbold',
                classes: ['custom-unbold'],
                styles: {fontWeight: 'normal'},
                merge_siblings: true,
                deep: true,
                remove: 'all',
            }]);

            editor.addCommand(COMMANDS.BOLD, function () {
                const n = getSelectionNode(editor);
                if (parentIsBlockAndBold(n)) {
                    editor.formatter.remove('bold');
                    if (!isBold(n)) {
                        editor.formatter.remove(FORMATS.UNBOLD);
                    } else {
                        editor.formatter.apply(FORMATS.UNBOLD);
                    }
                } else {
                    editor.execCommand('bold');
                }

                editor.nodeChanged();
            });
            const editorEventCallback = function () {
                buttonApi.setActive(currentSelectionIsBold(editor));
            };

            editor.on('NodeChange', editorEventCallback);
            editor.shortcuts.add('meta+b', 'Custom BOLD', COMMANDS.BOLD);// "meta" maps to Command on Mac and Ctrl on PC
            return function () {
                editor.off('NodeChange');
            };
        },
    });
}