<template>
    <div>
        <h2 class="font-weight-regular black--text">
            End page details
        </h2>
        <v-row>
            <v-col>
                <v-row>
                    <v-col cols="12">
                        Title:
                        <div class="black--text">
                            {{ value.end.title }}
                        </div>
                    </v-col>
                </v-row>
                <v-divider />
                <v-row>
                    <v-col cols="12">
                        Body text:
                        <div
                            class="black--text"
                            v-html="value.end.bodyText"
                        />
                    </v-col>
                </v-row>
            </v-col>
            <v-col>
                <h3 class="font-weight-regular black--text mt-3 mb-2">
                    External page link:
                </h3>
                <v-divider class="mb-2" />
                <div>
                    Text:
                    <div class="black--text">
                        {{ value.end.externalPageLink.text }}
                    </div>
                </div>
                <div>
                    Url:
                    <a class="black--text">
                        {{ value.end.externalPageLink.url }}
                    </a>
                </div>
                <h3 class="font-weight-regular black--text mt-3 mb-2">
                    Downloadable Content:
                </h3>
                <v-divider class="mb-2" />
                <div>
                    <a
                        v-if="value.end.downloadableContent"
                        :href="value.end.downloadableContent"
                        download
                        target="_blank"
                    >download</a>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'EndPageDetails',
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        end: {
                            title: '',
                            bodyText: '',
                            downloadableContent: '',
                            externalPageLink: {
                                text: '',
                                url: '',
                            },
                        },
                    };
                },
            },
        },
    };
</script>

<style scoped>
</style>