<template>
    <v-card class="px-4 font-weight-medium list-header"
            elevation="0"
    >
        <v-row>
            <v-col :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'">
                <CompanyListHeaderItem id="name"
                                       name="Company name"
                />
            </v-col>
            <v-col v-if="isQuadiaLiveAdmin"
                   :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'"
            >
                <CompanyListHeaderItem id="productionCompany"
                                       name="Production Company"
                />
            </v-col>
            <v-col :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'">
                <CompanyListHeaderItem id="createdBy"
                                       name="Created by"
                />
            </v-col>
            <v-col :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'">
                <CompanyListHeaderItem id="lastEditedBy"
                                       name="Last edited by"
                />
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import CompanyListHeaderItem from '@/components/pages/Companies/CompanyListHeaderItem';
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'CompanyListHeader',
        components: {CompanyListHeaderItem},
        data: () => ({

        }),
        computed: {
            ...mapState('companies', ['listingHeader']),
            ...mapGetters('auth', ['isQuadiaLiveAdmin']),
        },
        watch: {},
        methods: {},
        mounted() {

        }
    };
</script>

