<template>
    <div>
        <h2 class="font-weight-regular black--text">
            Post event E-mail settings
        </h2>
        <v-row>
            <v-col cols="12">
                Email subject:
                <div class="black--text">
                    {{ value.postEventMail.subject }}
                </div>
            </v-col>
        </v-row>
        <v-divider />
        <v-row>
            <v-col cols="12">
                Email body text:
                <div class="black--text"
                     v-html="value.postEventMail.bodyText"
                />
            </v-col>
        </v-row>
        <v-divider />
        <v-row>
            <v-col cols="12">
                Email date:
                <div v-if="value.postEventMail.emailSendDateTime"
                     class="black--text"
                >
                    {{ emailSendDate }}
                </div>
                <div v-if="value.postEventMail.emailSendDateTime"
                     class="black--text"
                >
                    {{ emailSendTime }}
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import TimeService from '@/services/TimeService';
    export default {
        name: 'PostEventMailDetails',
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        postEventMail: {
                            subject: '',
                            bodyText: '',
                            emailSendDateTime: null
                        },
                    };
                },
            },
        },
        computed: {
            emailSendDateTime() {
                return TimeService.dateFromUnixTimestamp(this.value.postEventMail?.emailSendDateTime || 0);
            },
            emailSendDate() {
                return TimeService.toDateString(this.emailSendDateTime);
            },
            emailSendTime() {
                return TimeService.toTimeString24(this.emailSendDateTime);
            }
        }
    };
</script>

<style scoped>
</style>