<template>
    <div class="px-8 item-list">
        <project-list-header :part="part" />
        <template v-for="(projectEvent) in projectEvents">
            <v-card :key="`project-list-${projectEvent.event._id}`"
                    class="mt-1 list-item"
            >
                <v-row class="px-4">
                    <list-logo :project-event="projectEvent" />
                    <list-company :project-event="projectEvent"
                                  :class="{'col-md-3': isClientOnly}"
                    />
                    <list-project-link v-if="!isClientOnly"
                                       :project-event="projectEvent"
                    />
                    <list-event-link :project-event="projectEvent"
                                     :is-client-only="isClientOnly"
                    />
                    <list-start-time :class="{'col-md-3': isClientOnly}"
                                     :project-event="projectEvent"
                    />
                    <list-created-by v-if="!isClientOnly"
                                     :project-event="projectEvent"
                    />
                    <list-registrations-count :project-event="projectEvent" />
                    <list-actions :project-event="projectEvent"
                                  @archive="initArchive"
                    />
                </v-row>
            </v-card>
        </template>
        <v-dialog v-model="archiveModal"
                  max-width="600"
                  persistent
        >
            <v-card>
                <v-card-title class="font-weight-regular">
                    Archive project: "{{ projectToArchive.name }}"
                    <v-spacer />
                    <v-btn icon
                           @click="cancelArchive()"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-divider />
                <v-card-text class="my-8">
                    <h2 class="font-weight-regular mb-5">
                        WARNING!
                    </h2>
                    <h3 class="font-weight-regular">
                        Are you sure you want to archive this project? This will anonymize all data and make the project
                        read-only. This action is irreversible!
                    </h3>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary"
                           @click="archive()"
                    >
                        <v-icon class="mr-2">
                            mdi-archive
                        </v-icon>
                        Archive
                    </v-btn>
                    <v-btn
                        @click="cancelArchive()"
                    >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import ProjectListHeader from '@/components/pages/Projects/ProjectListHeader';
    import {mapGetters} from 'vuex';
    import {$http} from '@/services/http';
    import {ARCHIVE_PROJECT, populateUrl} from '@/services/ApiService';
    import ListLogo from '@/components/pages/Projects/Columns/ListLogo';
    import ListCompany from '@/components/pages/Projects/Columns/ListCompany';
    import ListProjectLink from '@/components/pages/Projects/Columns/ListProjectLink';
    import ListEventLink from '@/components/pages/Projects/Columns/ListEventLink';
    import ListStartTime from '@/components/pages/Projects/Columns/ListStartTime';
    import ListCreatedBy from '@/components/pages/Projects/Columns/ListCreatedBy';
    import ListRegistrationsCount from '@/components/pages/Projects/Columns/ListRegistrationsCount';
    import ListActions from '@/components/pages/Projects/Columns/ListActions';

    export default {
        name: 'ProjectList',
        components: {
            ListActions,
            ListRegistrationsCount,
            ListCreatedBy,
            ListStartTime,
            ListEventLink,
            ListProjectLink,
            ListCompany,
            ListLogo,
            ProjectListHeader
        },
        props: {
            part: {
                type: String,
                default: 'upcoming'
            }
        },
        data() {
            return {
                projectToArchive: {},
                archiveModal: false,
            };
        },
        computed: {
            ...mapGetters('projectLists', ['items']),
            ...mapGetters('auth', ['canEditProject', 'isClientOnly']),
            projectEvents() {
                return this.items(this.part);
            }
        },
        methods: {
            initArchive(project) {
                this.projectToArchive = project;
                this.archiveModal = true;
            },
            cancelArchive() {
                this.projectToArchive = {};
                this.archiveModal = false;
            },
            archive() {
                const url = populateUrl(ARCHIVE_PROJECT, this.projectToArchive._id);
                $http.put(url).then(() => {
                    this.$emit('reload', true);
                    this.cancelArchive();
                });
            }
        }
    };
</script>

<style scoped>

</style>
