<template>
    <div>
        <evaluation-fields-editor v-if="!loading"
                                  v-model="evaluationFields"
                                  label="Fields"
                                  :disabled="project.isArchived"
                                  @validate="onFieldValidation"
        />
        <v-progress-linear v-else
                           indeterminate
        />
    </div>
</template>

<script>
    import EvaluationFieldsEditor from '@/components/controls/field-editor/EvaluationFieldsEditor';
    import { mapActions, mapGetters, mapState } from 'vuex';
    export default {
        name: 'EvaluationEventTab',
        components:{
            EvaluationFieldsEditor,
        },
        props:{
            evaluationId:{
                type: String,
                default:() => '',
            },
        },
        data(){
            return {
                fieldValidationResult: {
                    valid: true,
                },
                loading: true
            };
        },
        computed:{
            ...mapState('projects', ['project']),
            ...mapGetters('evaluationFields', ['fields']),
            evaluationFields: {
                get() {
                    return this.fields;
                },
                async set(value) {
                    await this.saveFields({
                        projectId: this.project?._id,
                        value: { fields: value },
                        evaluationId: this.evaluationId
                    });
                },
            },
        },
        methods:{
            ...mapActions('evaluationFields', ['saveFields', 'getFields']),
            onFieldValidation(result) {
                this.fieldValidationResult = result;
            }
        },
        async mounted() {
            this.loading = true;
            await this.getFields({
                projectId: this.project._id, 
                evaluationId: this.evaluationId
            });
            this.loading = false;
        }
    };
</script>