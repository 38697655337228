<template>
    <v-dialog
        v-model="value"
        persistent
        max-width="600"
    >
        <v-card>
            <v-card-title class="pb-0">
                {{ session ? 'Edit' : 'Add' }}&nbsp;a session
                <v-spacer />
                <v-btn icon
                       @click="closeModal"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row no-gutters>
                    <v-col>
                        <h3 class="font-weight-regular mb-5">
                            Session:
                        </h3>
                        <v-form ref="form"
                                @submit.prevent="session ? updateSession() : addSession()"
                        >
                            <v-text-field
                                v-model="sessionTitle"
                                filled
                                class="text-input"
                                label="Session title"
                                :rules="requiredField"
                            />
                            <v-row>
                                <v-col cols="12"
                                       lg="6"
                                       md="6"
                                       sm="6"
                                       class="py-0 d-inline-flex"
                                >
                                    <h3 class="font-weight-regular mr-3 pt-6">
                                        Start time
                                    </h3>
                                    <custom-time-picker v-model="sessionStartTime"
                                                        :rules="requiredField"
                                                        class="session-input"
                                                        send-time-formatted
                                                        @formattedStartTime="saveFormattedTime"
                                    />
                                </v-col>
                                <v-col cols="12"
                                       lg="6"
                                       md="6"
                                       sm="6"
                                       class="py-0 d-inline-flex"
                                >
                                    <h3 class="font-weight-regular mr-3 pt-6">
                                        Duration
                                    </h3>
                                    <v-text-field
                                        v-model="durationInMinutes"
                                        class="session-input"
                                        type="number"
                                        min="1"
                                        :rules="requiredFieldDuriation"
                                    />
                                    <h3 class="font-weight-regular ml-3 pt-6">
                                        minutes
                                    </h3>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-6 pb-4">
                <v-spacer />
                <v-btn color="primary"
                       :disabled="submitDisabled"
                       :loading="submitDisabled"
                       @click="confirm"
                >
                    {{ session ? 'Update' : 'Add' }}
                </v-btn>
                <v-btn @click="closeModal">
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import CustomTimePicker from '@/components/controls/CustomTimePicker';
    import {trim} from 'lodash';

    export default {
        name:'SessionDialog',
        components: {CustomTimePicker},
        props:{
            value: {
                type: Boolean,
                default: () => false
            },
            session: {
                type: Object,
                default: () => null
            },
        },
        data(){
            return {
                sessionTitle: this.session?.name || null,
                sessionStartTime: this.session?.startTime || 0,
                durationInMinutes: this.session?.durationInMinutes || 1,
                submitDisabled: false,
                formattedStartTime: this.session?.startTimeFormattedTime || '',
            };
        },
        computed: {
            requiredField(){
                return [(v) => !!trim(v) || 'Field is required'];
            },
            requiredFieldDuriation(){
                return [(v) => !!v && v > 0 || 'Value must be greater than 1'];
            }
        },
        methods:{
            confirm() {
                if(this.$refs.form.validate()) {
                    this.$emit('confirm', {
                        breakoutGroupName: null,
                        breakout_group: null,
                        startTime: this.sessionStartTime,
                        durationInMinutes: Math.ceil(this.durationInMinutes) || 1,
                        name: this.sessionTitle,
                        startTimeFormattedTime: this.formattedStartTime
                    });
                    this.submitDisabled = false;
                    this.closeModal();
                }
            },
            saveFormattedTime(formattedStartTime){
                this.formattedStartTime = formattedStartTime;
            },
            closeModal(){
                this.$emit('input', false);
            },
        },
    };
</script>

<style scoped>
.session-input{
    max-width: 10rem;
}
</style>
