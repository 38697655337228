<template>
    <v-dialog
        v-model="showModal"
        persistent
        max-width="600"
    >
        <v-card>
            <v-card-title class="pb-0">
                {{ session ? `Edit a ${session.breakout_group ? ' breakout' : 'session'}` : `Add a session` }}
                <v-spacer />
                <v-btn icon
                       @click="closeModal"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row no-gutters>
                    <v-col>
                        <h3 class="font-weight-regular mb-5">
                            Session:
                        </h3>
                        <v-form ref="form"
                                @submit.prevent="session ? updateSession() : addSession()"
                        >
                            <v-text-field
                                v-model="sessionTitle"
                                filled
                                class="text-input"
                                label="Session title"
                                :rules="requiredField"
                            />
                            <v-row>
                                <v-col cols="12"
                                       lg="6"
                                       md="6"
                                       sm="6"
                                       class="py-0 d-inline-flex"
                                >
                                    <h3 class="font-weight-regular mr-3 pt-6">
                                        Start time
                                    </h3>
                                    <custom-time-picker v-model="eventStartTime"
                                                        :rules="requiredField"
                                                        class="session-input"
                                    />
                                </v-col>
                                <v-col cols="12"
                                       lg="6"
                                       md="6"
                                       sm="6"
                                       class="py-0 d-inline-flex"
                                >
                                    <h3 class="font-weight-regular mr-3 pt-6">
                                        Duration
                                    </h3>
                                    <v-text-field
                                        v-model="durationInMinutes"
                                        class="session-input"
                                        type="number"
                                        min="1"
                                        :rules="requiredFieldDuriation"
                                    />
                                    <h3 class="font-weight-regular ml-3 pt-6">
                                        minutes
                                    </h3>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-6 pb-4">
                <v-spacer />
                <v-btn color="primary"
                       :disabled="submitDisabled"
                       :loading="submitDisabled"
                       @click="session ? updateSession() : addSession()"
                >
                    {{ session ? 'Update' : 'Add' }}
                </v-btn>
                <v-btn @click="closeModal">
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import {
        SIMPLE_SESSION_EVENT_URL,
        SINGLE_EVENT_URL,
        populateUrl
    } from '@/services/ApiService';
    import { $http } from '@/services/http';
    import CustomTimePicker from '@/components/controls/CustomTimePicker';
    import {trim} from 'lodash';


    export default {
        name:'SessionDialog',
        components: {CustomTimePicker},
        props:{
            value: {
                type: Boolean,
                default: () => false
            },
            session: {
                type: Object,
                default: () => null
            }
        },
        data(){
            return {
                showModal: this.value,
                sessionTitle: null,
                eventStartTime: 0,
                durationInMinutes: 1,
                submitDisabled: false,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            requiredField(){
                return [(v) => !!trim(v) || 'Field is required'];
            },
            requiredFieldDuriation(){
                return [(v) => !!v && v > 0 || 'Value must be greater than 1'];
            }
        },
        methods:{
            ...mapActions('projects', ['getProject']),
            async updateSession(){
                this.submitDisabled = true;
                if(this.$refs.form.validate()){
                    const url = populateUrl(`${SINGLE_EVENT_URL}/autosave`, this.project._id, this.session._id);
                    await $http.put(url, {
                        startTime: this.eventStartTime,
                        durationInMinutes: Math.ceil(this.durationInMinutes),
                        name: this.sessionTitle
                    });
                    this.$emit('updateList');
                    this.closeModal();
                }
                this.submitDisabled = false;
            },
            async addSession(){
                this.submitDisabled = true;
                if(this.$refs.form.validate()){
                    const url = populateUrl(SIMPLE_SESSION_EVENT_URL, this.project._id);
                    await $http.post(url, {
                        startTime: this.eventStartTime,
                        durationInMinutes: Math.ceil(this.durationInMinutes),
                        name: this.sessionTitle
                    });
                    this.$emit('updateList');
                    this.closeModal();
                }
                this.submitDisabled = false;
            },
            closeModal(){
                this.$emit('input', false);
            },
        },
        created(){
            if(this.session){
                this.sessionTitle = this.session.name;
                this.durationInMinutes = this.session.durationInMinutes;
                this.eventStartTime = this.session.startTime;
            }
        }
    };
</script>

<style scoped>
.session-input{
    max-width: 10rem;
}
</style>
