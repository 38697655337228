<template>
    <v-card class="px-4 font-weight-medium list-header"
            elevation="0"
    >
        <v-row>
            <v-col md="2"
                   class="pt-4"
                   :class="{'col-md-3': isClientOnly}"
            >
                <ArchiveProjectListHeaderItem id="company.name"
                                              name="Company"
                                              :filter-items="companiesColumnFilters"
                />
            </v-col>
            <v-col v-if="!isClientOnly"
                   md="2"
                   class="pt-4"
            >
                <ArchiveProjectListHeaderItem
                    id="name"
                    name="Project"
                />
            </v-col>
            <v-col md="2"
                   class="pt-4"
                   :class="{'col-md-3': isClientOnly}"
            >
                <ArchiveProjectListHeaderItem id="events.name"
                                              name="Event"
                />
            </v-col>
            <v-col md="2"
                   class="pt-4"
                   :class="{'col-md-3': isClientOnly}"
            >
                <ArchiveProjectListHeaderItem id="startTime"
                                              name="Start Time"
                />
            </v-col>
            <v-col v-if="!isClientOnly"
                   md="2"
                   class="pt-4"
            >
                <ArchiveProjectListHeaderItem
                    id="createdBy.name"
                    name="Created By"
                    :filter-items="createdByColumnFilters"
                />
            </v-col>
            <v-col md="1"
                   class="pt-4 pl-1"
            >
                <div class="text-right"
                     style="white-space: nowrap;"
                >
                    Registrations
                </div>
            </v-col>
            <v-col md="1"
                   class="pt-4"
            >
                <div class="text-right"
                     style="white-space: nowrap;"
                >
                    Actions
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import ArchiveProjectListHeaderItem from '@/components/pages/Archives/ArchiveProjectListHeaderItem';
    import {$http} from '@/services/http';
    import {COMPANIES_FOR_FILTERING_URL, ARCHIVED_PROJECT_LISTING_CREATED_BY} from '@/services/ApiService';
    import { mapGetters, mapMutations, mapState } from 'vuex';

    export default {
        name: 'ProjectListHeader',
        components: {ArchiveProjectListHeaderItem},
        data: () => ({
            createdByColumnFilters: [],
            companiesColumnFilters: [],
        }),
        computed: {
            ...mapState('archives', ['listingHeader']),
            ...mapGetters('auth', ['isClientOnly']),
            createdBySubSearch: function () {
                return this.listingHeader.filtering.subFilters['createdBy.name'];
            },
            companiesSubSearch: function () {
                return this.listingHeader.filtering.subFilters['company.name'];
            }
        },
        watch: {
            createdBySubSearch: function (value) {
                this.getCreatedByFilterItems(value ? value : '');
            },
            companiesSubSearch: function (value) {
                this.getCompaniesFilterItems(value ? value : '');
            },
        },
        methods: {
            ...mapMutations('archives', ['setFiltersLoading']),
            getCreatedByFilterItems(search) {
                this.setFiltersLoading(true);
                $http.get(ARCHIVED_PROJECT_LISTING_CREATED_BY, {
                    params: {
                        'search': encodeURIComponent(search)
                    }
                }).then((data) => {
                    this.createdByColumnFilters = data.data;
                }).finally(() => this.setFiltersLoading(false));
            },
            getCompaniesFilterItems(search) {
                this.setFiltersLoading(true);
                $http.get(COMPANIES_FOR_FILTERING_URL, {
                    params: {
                        'search': encodeURIComponent(search)
                    }
                }).then((data) => {
                    this.companiesColumnFilters = data.data;
                }).finally(() => this.setFiltersLoading(false));
            }
        },
        mounted() {
            this.getCreatedByFilterItems('');
            this.getCompaniesFilterItems('');
        }
    };
</script>

<style scoped>

</style>
