<template>
    <div>
        <generic-block-options :block="block"
                               :save-url="saveUrl"
                               :has-tab-titles="hasTabTitles"
        />
        <label v-if="dynamicBlockKind === 'waitingPage'"
               class="d-block mb-1"
        >
            Content text:
        </label>
        <rich-text-editor v-if="dynamicBlockKind === 'waitingPage'"
                          v-model="content"
                          class="rich-text-editor"
                          :disabled="project.isArchived"
                          :with-event-links="false"
        />
    </div>
</template>

<script>
    import GenericBlockOptions from './GenericBlockOptions';
    import RichTextEditor from '@/components/controls/RichTextEditor.vue';
    import { debounce } from 'lodash';
    import { mapActions, mapState } from 'vuex';

    export default {
        name: 'HeaderBlock',
        components: {
            RichTextEditor,
            GenericBlockOptions,
        },
        props: {
            block: {
                type: Object,
                required: true,
            },
            saveUrl: {
                type: String,
                required: true,
            },
            dynamicBlockKind: {
                type: String,
                required: true,
            },
            hasTabTitles: {
                type: Boolean,
                default: () => true,
            }
        },
        computed: {
            ...mapState('projects', ['project']),
            content:{
                get(){
                    return this.block.componentOptions.content;
                },
                set: debounce(async function (value) {
                    this.block.componentOptions = {
                        ...this.block.componentOptions,
                        content: value,
                    };
                    await this.saveBlocks(this.saveUrl);
                },400)
            },
        },
        methods: {
            ...mapActions('dynamic-block', ['saveBlocks'])
        }
    };
</script>
