import { $http } from '@/services/http';
import { makeUrl } from '@/services/ApiService';
import { get } from 'lodash';

const dynamicBlockStore = {
  namespaced: true,
  state: {
    selectedBlock: null,
    overlayScrollKey: 0,
    blocks: [],
    blocksLoadedMessage: {
      initialSizesLoaded: false,
      scrollHeight: 0,
      components: []
    }
  },
  getters: {
    isSelectedBlock: (state) => (block) => {
      return state.selectedBlock === block._id;
    },
    isParentOfSelectedBlock: (state) => (block) => {
      const subBlocks = get(block, 'componentOptions.blocks', []);
      for (var i = 0; i < subBlocks.length; i++) {
        if (state.selectedBlock === subBlocks[i]._id) {
          return true;
        }
      }
      return false;
    },
  },
  mutations: {
    setBlocks(state, payload) {
      state.blocks = payload;
    },
    selectBlock(state, block) {
      if (state.selectedBlock === block._id) {
        state.selectedBlock = null;
      } else {
        state.selectedBlock = block._id;
      }
    },
    addNewBlock(state, block) {
      state.blocks.push(block);
      state.selectedBlock = block._id;
      state.overlayScrollKey++;
    },
    addBlockToColumn(state, {columnBlock, block}) {
      state.blocks.find(x => x._id === columnBlock._id).componentOptions.blocks.push(block);
      state.selectedBlock = block._id;
      state.overlayScrollKey++;
    },
    removeBlock(state, block) {
      for (var i = 0; i < state.blocks.length; i++) {
        if (state.blocks[i] === block) {
          state.blocks.splice(i, 1);
          return;
        }
        if (state.blocks[i].componentOptions.blocks) {
          for (var j = 0; j < state.blocks[i].componentOptions.blocks.length; j++) {
            if (state.blocks[i].componentOptions.blocks[j] === block) {
              state.blocks[i].componentOptions.blocks.splice(j, 1);
              return;
            }
          }
        }
      }
    },
    unselectBlock(state) {
      state.selectedBlock = null;
      state.overlayScrollKey++;
    },
    scrollOverlayToSelectedBlock(state) {
      state.overlayScrollKey++;
    },
    updateBlocksLoadedMessage(state, payload) {
      state.blocksLoadedMessage = {
        initialSizesLoaded: true,
        scrollHeight: Math.max(payload.scrollHeight, 400),
        components: payload.components
      };
    }
  },
  actions: {
    loadBlocks: async ({ commit, rootGetters }, url) => {
      const projectId = rootGetters['projects/currentProjectId'];
      const response = await $http.get(makeUrl(url, {
        projectId
      }));

      commit('setBlocks', response.data);
    },
    saveBlocks: async ({ state, rootGetters }, url) => {
      const message = JSON.stringify({
        event: 'qlive-app-blocks-updated',
        blocks: state.blocks
      });

      setTimeout(() => {
        document.getElementById('dynamic-blocks-preview-iframe')
            .contentWindow
            .postMessage(message, process.env.VUE_APP_FRONTEND_ORIGIN);
      }, 100);


      const projectId = rootGetters['projects/currentProjectId'];
      return $http.put(makeUrl(url, {
        projectId
      }), { blocks: state.blocks });
    },
    moveOutOfColumn: async ({ commit, dispatch }, {block, saveUrl}) => {
      commit('removeBlock', block);
      commit('addNewBlock', block);
      return dispatch('saveBlocks', saveUrl);
    },
    moveIntoColumn: async ({ commit, dispatch }, { block, columnBlock, saveUrl }) => {
      commit('removeBlock', block);
      commit('addBlockToColumn', { block, columnBlock });
      return dispatch('saveBlocks', saveUrl);
    }
  }
};

export default dynamicBlockStore;
