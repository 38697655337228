<template>
    <v-chip close
            @click:close="close"
    >
        <i v-if="showProductionCompany">{{ project.productionCompany }} / </i>
        {{ project.clientCompany }} /
        {{ project.projectName }}
    </v-chip>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'ProjectChip',
        props: ['project'],
        computed: {
            ...mapGetters('auth', ['isQuadiaLiveAdmin']),
            showProductionCompany() {
                return this.isQuadiaLiveAdmin;
            },
        },
        methods: {
            close() {
                this.$emit('close');
            },
        },
    };
</script>
