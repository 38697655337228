<template>
    <div>
        <v-list dense
                color="transparent"
        >
            <v-list-item v-if="items.length === 0"
                         disabled
                         class="bd-top"
            >
                <v-list-item-content>
                    <v-list-item-title>
                        <slot name="no-items">
                            No upcoming events selected
                        </slot>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item-group v-else
                               v-model="selectedItems"
                               :multiple="!deleteMode"
                               @change="listChange"
            >
                <v-list-item v-for="(item, index) in items"
                             :key="item._id"
                             :value="item._id"
                             color="primary"
                             :selectable="!deleteMode"
                             class="bd-top"
                             :class="{'bd-bottom': index === items.length - 1}"
                >
                    <template v-slot:default="{ active }">
                        <v-list-item-content>
                            <v-list-item-title>
                                {{ item.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ item.startTimeFormatted }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                            <v-checkbox v-if="!deleteMode"
                                        :input-value="active"
                            />
                            <v-tooltip v-else
                                       top
                            >
                                <template v-slot:activator="{on}">
                                    <v-btn icon
                                           color="error"
                                           v-on="on"
                                           @click="deleteItem(item)"
                                    >
                                        <v-icon>
                                            delete
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>
                                    Remove item
                                </span>
                            </v-tooltip>
                        </v-list-item-action>
                    </template>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        <confirm-modal v-if="deleteMode"
                       ref="deleteUpcoming"
        >
            <template v-slot:title>
                Deleting upcoming event from list
            </template>
            <template v-slot:content>
                <p>This will remove the following upcoming event from the list:</p>
                <p v-if="itemToDelete">
                    {{ itemToDelete.name }} / {{ itemToDelete.startTimeFormatted }}
                </p>
            </template>
        </confirm-modal>
    </div>
</template>

<script>
    import ConfirmModal from '@/components/controls/dialogs/ConfirmModal.vue';

    export default {
        name: 'UpcomingEventsList',
        components: {ConfirmModal},
        data() {
            return {
                selectedItems: this.value.map(v => v._id),
                itemToDelete: null,
            };
        },
        props: {
            value: {
                type: Array,
                default: () => []
            },
            items: {
                type: Array,
                default: () => []
            },
            deleteMode: {
                type: Boolean,
                default: () => false,
            }
        },
        methods: {
            listChange(value) {
                if (!this.deleteMode) {
                    const modelValue = this.items.filter(i => value.includes(i._id));
                    this.$emit('input', modelValue);
                }
            },
            async deleteItem(item) {
                this.itemToDelete = item;
                const result = await this.$refs.deleteUpcoming.open();
                if (result) {
                    const modelValue = this.items.filter(i => i._id !== this.itemToDelete._id);
                    this.$emit('input', modelValue);
                }
            }
        },
        mounted() {
            this.$emit('toggleconfirm', this.items.length > 0);
        }
    };
</script>

<style>
.bd-top {
  border-top: 1px solid #E0E0E0
}

.bd-bottom {
  border-bottom: 1px solid #E0E0E0
}

.v-dialog {
  .bd-top {
    border-top: 0 none transparent;
  }

  .bd-bottom {
    border-bottom: 0 none transparent;
  }
}
</style>