<template>
    <v-container fluid>
        <v-row align="center"
               align-content="center"
               justify="center"
        >
            <v-col
                class="d-flex"
                cols="1"
                sm="1"
            >
                <small class="d-flex justify-right"
                       style="width: 100%; white-space: nowrap"
                >
                    <slot name="page-size-selector-label" />
                </small>
            </v-col>
            <v-col
                class="d-flex"
                cols="1"
                sm="1"
            >
                <slot name="page-size-selector" />
            </v-col>
            <v-col
                class="d-flex"
                cols="6"
                sm="3"
            >
                <slot name="pagination" />
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
    export default {
        name: 'ProjectListFooter'
    };
</script>

<style scoped>

</style>