<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{on}">
                <v-btn icon
                       tile
                       color="rgba(0,0,0, .87)"
                       :disabled="project.isArchived"
                       @click="openDialog"
                       v-on="on"
                >
                    <v-icon>
                        mdi-content-save
                    </v-icon>
                </v-btn>
            </template>
            <span>Save as template...</span>
        </v-tooltip>
        <v-divider class="my-0" />
        <template-dialog v-model="dialogOpen"
                         :selected-template="selectedTemplate"
                         :index-url="indexUrl"
                         show-name-editor
                         @confirm="selectTemplate"
        >
            <template v-slot:title>
                Save as template
            </template>
        </template-dialog>
        <confirm-modal ref="confirmModal">
            <template v-slot:title>
                Overwrite template?
            </template>
            <template v-slot:content>
                <p class="mt-4">
                    This will overwrite the selected template. Would you like to continue?
                </p>
            </template>
        </confirm-modal>
    </div>
</template>

<script>
    import TemplateDialog from '@/components/pages/Project/EventPage/Templates/TemplateDialog';
    import ConfirmModal from '@/components/controls/dialogs/ConfirmModal';
    import { mapState } from 'vuex';
    export default {
        name: 'SaveAsTemplate',
        components: {ConfirmModal, TemplateDialog},
        props: {
            indexUrl: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                dialogOpen: false,
                selectedTemplate: null,
            };
        },
        computed:{
            ...mapState('projects', ['project'])
        },
        methods: {
            openDialog() {
                this.selectedTemplate = null;
                this.dialogOpen = true;
            },
            async selectTemplate(value) {
                this.selectedTemplate = value.selectedTemplate;

                if (value.existing) {
                    if (!await this.$refs.confirmModal.open()) {
                        return;
                    }
                }

                this.$emit('input', {
                    selectedTemplate: this.selectedTemplate,
                    action: value.existing ? 'update' : 'store',
                });
            },
        }
    };
</script>

<style scoped>

</style>