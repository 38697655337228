<template>
    <div>
        <h2 class="font-weight-regular black--text">
            Registration E-mail settings
        </h2>
        <v-row>
            <v-col cols="12">
                Subject:
                <div class="black--text">
                    {{ value.registerMail.subject }}
                </div>
            </v-col>
        </v-row>
        <v-divider />
        <v-row>
            <v-col cols="12">
                Body text:
                <div class="black--text"
                     v-html="value.registerMail.bodyText"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    export default {
        name: 'RegistrationEmailDetails',
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        registerMail: {
                            subject: '',
                            bodyText: '',
                        },
                    };
                }
            }
        }
    };
</script>

<style scoped>

</style>