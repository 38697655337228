<template>
    <v-card>
        <v-card-title>
            Create new company
        </v-card-title>
        <v-card-text>
            <v-row v-if="company">
                <v-col>
                    <h3 class="mb-5 font-weight-regular">
                        Company information
                    </h3>
                    <v-text-field
                        v-model="company.name"
                        class="text-input"
                        prepend-icon="business"
                        label="Company name"
                    />
                    <v-text-field
                        v-model="company.mailFromName"
                        class="text-input"
                        prepend-icon="person"
                        label="From email name"
                    />
                    <v-select v-if="isQuadiaLiveAdmin"
                              v-model="company.productionCompany"
                              class="text-input"
                              prepend-icon="business"
                              :items="productionCompanies"
                              item-text="name"
                              item-value="slug"
                              label="Production company"
                    />

                    <v-divider class="mb-2 mt-2" />
                    <h3 class="mb-5 font-weight-regular">
                        Branding settings
                    </h3>
                    <v-row>
                        <v-col lg="6">
                            <logo-picker :upload-url="logoUploadUrl"
                                         :logo="company.logo"
                                         :logo-url="company.logoUrl"
                                         @input="logoChanged"
                            />
                        </v-col>
                        <v-col lg="6">
                            <favicon-picker :upload-url="faviconUploadUrl"
                                            :favicon="company.favicon"
                                            :favicon-url="company.faviconUrl"
                                            @input="faviconChanged"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <label>Primary color</label>
                            <custom-color-picker
                                v-model="company.primaryColor"
                            />
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col lg="11">
                            <h3 class="mb-5 font-weight-regular">
                                Privacy settings
                            </h3>
                            <v-text-field
                                v-model="company.privacyPolicyUrl"
                                prepend-icon="link"
                                label="Privacy Policy"
                            />
                            <span v-if="!isValid"
                                  class="error--text"
                            >It looks like you’ve filled in an invalid link. Please check if the link starts with http:// or https://</span>
                        </v-col>
                    </v-row>
                </v-col>

                <v-divider inset
                           vertical
                />
                <v-col>
                    <h3 class="mb-5 font-weight-regular">
                        Sample webinar pages
                    </h3>
                    This is what the webinar pages might look like with the current
                    styling.
                </v-col>
            </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions>
            <v-spacer />
            <v-btn @click="close">
                Close
            </v-btn>
            <v-btn color="primary"
                   @click="saveAndClose"
            >
                Save and close
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    import { mapActions, mapGetters, mapState } from 'vuex';
    import { COMPANY_FAVICON_UPLOAD_URL, COMPANY_LOGO_UPLOAD_URL } from '@/services/ApiService';
    import CustomColorPicker from '@/components/controls/CustomColorPicker';
    import Company from '@/models/Company';
    import FaviconPicker from '@/components/controls/FaviconPicker.vue';
    import LogoPicker from '@/components/controls/LogoPicker.vue';

    export default {
        name: 'CreateCompanyModal',
        components: {LogoPicker, FaviconPicker, CustomColorPicker},
        data() {
            return {
                company: new Company(),
                logoUploading: false,
                isValid: true,
            };
        },
        computed: {
            ...mapState('productionCompanies', ['productionCompanies']),
            ...mapGetters('auth', ['isQuadiaLiveAdmin']),
            faviconUploadUrl() {
                return COMPANY_FAVICON_UPLOAD_URL;
            },
            logoUploadUrl() {
                return COMPANY_LOGO_UPLOAD_URL;
            },
        },
        methods: {
            ...mapActions('companies', ['storeCompany']),
            ...mapActions('productionCompanies', ['getProductionCompanies']),
            faviconChanged(data) {
                this.company.favicon = data.favicon;
                this.company.faviconUrl = data.faviconUrl;
            },
            logoChanged(data) {
                this.company.logo = data.logo;
                this.company.logoUrl = data.logoUrl;
            },
            close() {
                this.$emit('close');
            },
            async saveAndClose() {
                if (!this.isQuadiaLiveAdmin) {
                    delete this.company.productionCompany;
                }
                this.validateUrl(this.company.privacyPolicyUrl);
                if (this.isValid) {
                    var response = await this.storeCompany(this.company);
                    this.$emit('saved-and-close', new Company(response.data));
                }
            },
            validateUrl(value) {
                let validUrlPattern = new RegExp('^(https?:\\/\\/)' + // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
                    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
                this.isValid = !value ? true : !!validUrlPattern.test(value);
            },
        },
        async mounted() {
            await this.getProductionCompanies();
        }
    };
</script>

<style scoped>
.text-input {
    width: 22rem;
}
</style>
