import UAParser from 'ua-parser-js';

class UserAgentService {
    constructor(data) {
        this.data = this.parse(data);
    }

    parse(data) {
        return data.map((item) => {
            return {
                agent: UAParser(item.ua),
            };
        });
    }

    aggregate(aggregator) {
        return aggregator.getResults(this.data);
    }
}

export default UserAgentService;