<template>
    <div>
        <div v-if="!logoUrl"
             class="d-flex align-baseline"
        >
            <v-file-input
                :key="logoKey"
                ref="logoInput"
                label="Logo"
                accept="image/*, .eps, .psd"
                truncate-length="50"
                show-size
                :disabled="disabled"
                :rules="logoRules"
                append-icon="mdi-image-plus"
                prepend-icon=""
                color="primary"
                class="file-input pr-2"
                @click:append="$refs.logoInput.$el.querySelector('input').click()"
                @change="onFileSelect"
            />
            <logo-hint />
        </div>
        <div v-else
             class="d-flex mb-2 pt-4"
        >
            <h3 class="font-weight-regular pt-2">
                Logo
            </h3>
            <logo-hint />
        </div>
        <image-cropper :file="selectedFile"
                       @save="uploadLogo"
                       @cancel="logoKey++"
        />
        <div v-if="!logoUploading"
             class="pl-5"
        >
            <img :src="logoUrl"
                 class="logo mr-10"
            >
            <v-btn
                v-if="logoUrl"
                icon
                color="primary"
                :disabled="disabled"
                class="mt-n2"
                @click="removeLogo"
            >
                <v-icon dark>
                    mdi-image-remove
                </v-icon>
            </v-btn>
        </div>
        <v-progress-circular v-else
                             indeterminate
                             color="primary"
        />
    </div>
</template>


<script>
    import LogoHint from '@/components/controls/hints/LogoHint';
    import ImageCropper from '@/components/controls/ImageCropper';

    import {
        LOGO_MAX_FILE_SIZE,
        logoValidationRules
    } from '@/services/fileValidation';
    import { $http } from '@/services/http';
    import { mapActions, mapMutations, mapState } from 'vuex';


    export default {
        name: 'LogoPicker',
        components:{LogoHint, ImageCropper},
        data(){
            return{
                logoUploading: false,
                logoKey: 0,
                selectedFile: ''
            };
        },
        props: {
            uploadUrl: {
                type: String,
                required: true,
            },
            logoUrl: {
                type: String,
                default: () => null,
            },
            logo: {
                type: String,
                default: () => null,
            },
            disabled: {
                type: Boolean,
                default: () => false,
            }
        },
        computed:{
            ...mapState('projects', ['project']),
            logoRules() {
                return logoValidationRules;
            },
        },
        methods:{
            ...mapMutations('projects', [
                'setLogo',
                'setLogoUrl',
            ]),
            ...mapActions('projects', ['saveProject', 'saveProjectImmediate']),

            async uploadLogo(file) {
                if (file === undefined || file.size > LOGO_MAX_FILE_SIZE) {
                    return Promise.reject();
                }
                const formData = new FormData();
                formData.append('logo', file);
                this.logoUploading = true;
                return $http.post(this.uploadUrl, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then((res)=>{
                    this.$emit('input', {
                        logoUrl: res.data.url,
                        logo: res.data.key,
                    });
                }).finally(()=>{
                    this.logoUploading = false;
                });
            },
            async removeLogo(){
                this.$emit('input', {
                    logo: '',
                    logoUrl: '',
                });
                this.logoKey++;
            },
            onFileSelect(e){
                this.selectedFile = e;
            }
        }
    };
</script>


<style lang="scss">
.file-input{
    .v-icon.v-icon.v-icon--link{
        padding-top: 10px;
        color: #2977BE
    }
}
.logo {
    max-width: 40%;
}
</style>