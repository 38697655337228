var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"700"},on:{"click:outside":_vm.cancel},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_vm._v("Crop image")]),_c('v-card-text',[_c('vue-cropper',{ref:"cropper",attrs:{"src":_vm.selectedFile,"auto-crop-area":1,"view-mode":2,"img-style":{ 'max-width': '500px', 'max-height': '500px' },"alt":"Source Image"}})],1),_c('v-card-actions',{staticClass:"px-5 pb-5"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.zoom(0.2)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-magnify-plus-outline ")])],1)]}}])},[_c('span',[_vm._v("Zoom in")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.zoom(-0.2)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-magnify-minus-outline ")])],1)]}}])},[_c('span',[_vm._v("Zoom out")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.rotate(-90)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-rotate-left ")])],1)]}}])},[_c('span',[_vm._v("Rotate left")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.rotate(90)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-rotate-right ")])],1)]}}])},[_c('span',[_vm._v("Rotate right")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({ref:"flipY",staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.flipY($event)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-flip-vertical ")])],1)]}}])},[_c('span',[_vm._v("Flip vertical")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({ref:"flipX",staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.flipX($event)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-flip-horizontal ")])],1)]}}])},[_c('span',[_vm._v("Flip horizontal")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"outlined":"","color":"primary","small":""},on:{"click":function($event){$event.preventDefault();return _vm.setAspectRatio(1/1)}}},on),[_vm._v(" 1:1 ")])]}}])},[_c('span',[_vm._v("1:1 aspect ratio")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"outlined":"","color":"primary","small":""},on:{"click":function($event){$event.preventDefault();return _vm.setAspectRatio(4/3)}}},on),[_vm._v(" 4:3 ")])]}}])},[_c('span',[_vm._v("4:3 aspect ratio")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"outlined":"","color":"primary","small":""},on:{"click":function($event){$event.preventDefault();return _vm.setAspectRatio(16/9)}}},on),[_vm._v(" 16:9 ")])]}}])},[_c('span',[_vm._v("16:9 aspect ratio")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":""},on:{"click":function($event){$event.preventDefault();return _vm.reset($event)}}},on),[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v(" mdi-restore ")])],1)]}}])},[_c('span',[_vm._v("Reset image")])]),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2 primary",attrs:{"text":""},on:{"click":function($event){_vm.saveImage(), (_vm.dialog = false)}}},on),[_vm._v(" Save ")])]}}])},[_c('span',[_vm._v("Crop and save")])]),_c('v-btn',{on:{"click":_vm.cancel}},[_vm._v(" Cancel ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }