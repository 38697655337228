<template>
    <v-col col="12"
           xl="8"
           lg="8"
           class="text-right pr-11"
    >
        <v-btn color="primary"
               :to="{ name: 'CreateProject' }"
        >
            <v-icon class="mr-2">
                add
            </v-icon>
            Create
        </v-btn>
    </v-col>
</template>
<script>
    export default {
        name: 'CreateProjectButton'
    };
</script>

<style scoped>

</style>