<template>
    <div>
        <v-col class="mt-0 pt-0">
            <v-row justify="space-around">
                <v-col cols="12"
                       md="7"
                       lg="7"
                >
                    <v-row class="mt-n3">
                        <v-col cols="12"
                               md="4"
                               xl="3"
                               lg="3"
                        >
                            <on-demand-views :key="JSON.stringify(daterange)"
                                             :date-range="daterange"
                                             :video-id="videoID"
                                             @ready="onDemandViewsReady"
                            />
                        </v-col>
                        <v-col cols="12"
                               md="4"
                               xl="3"
                               lg="3"
                        >
                            <on-demand-impressions :key="JSON.stringify(daterange)"
                                                   :video-id="videoID"
                                                   :date-range="daterange"
                                                   @ready="onDemandImpressionsReady"
                            />
                        </v-col>
                        <v-col cols="12"
                               md="4"
                               xl="3"
                               lg="3"
                        >
                            <on-demand-start-ratio :impressions="impressions"
                                                   :views="views"
                            />
                        </v-col>
                        <v-col cols="12"
                               md="4"
                               xl="3"
                               lg="3"
                        >
                            <on-demand-avg-watch-time :key="JSON.stringify(daterange)"
                                                      :video-id="videoID"
                                                      :date-range="daterange"
                            />
                        </v-col>
                        <v-col cols="12"
                               md="4"
                               xl="3"
                               lg="3"
                        >
                            <on-demand-total-watch-time :key="JSON.stringify(daterange)"
                                                        :video-id="videoID"
                                                        :date-range="daterange"
                            />
                        </v-col>
                        <v-col cols="12"
                               md="4"
                               xl="6"
                               lg="6"
                        >
                            <on-demand-starts-per-referrer :key="JSON.stringify(daterange)"
                                                           :video-id="videoID"
                                                           :date-range="daterange"
                            />
                        </v-col>
                        <v-col cols="12"
                               md="4"
                               xl="3"
                               lg="3"
                        >
                            <on-demand-view-ratio :key="JSON.stringify(daterange)"
                                                  :video-id="videoID"
                                                  :date-range="daterange"
                            />
                        </v-col>
                    </v-row>
                </v-col>
                <v-col cols="12"
                       md="3"
                       lg="3"
                >
                    <on-demand-registrations-export
                        v-if="project.requireRegistrationForOnDemand"
                        id="on-demand-regs"
                        :selected-event="selectedEvent"
                        :date-range="daterange"
                    />
                    <stats-date-picker
                        v-model="daterange"
                        :selection="1"
                        :minimum-date="videoPublished"
                        @input="daterangeUpdated"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                       xl="6"
                       lg="6"
                >
                    <dropoff-rate :key="JSON.stringify(daterange)"
                                  :video-id="videoID"
                                  :date-range="daterange"
                    />
                </v-col>
                <v-col cols="12"
                       xl="6"
                       lg="6"
                >
                    <card-countries-geo-chart :key="JSON.stringify(daterange)"
                                              :video-id="videoID"
                                              :date-range="daterange"
                    />
                </v-col>
            </v-row>
        </v-col>
    </div>
</template>

<script>
    import StatsDatePicker from './StatsDatePicker';
    import DropoffRate from './DropoffRate';
    import { mapState } from 'vuex';
    import OnDemandViews from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/OnDemandViews.vue';
    import OnDemandImpressions
        from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/OnDemandImpressions.vue';
    import OnDemandStartRatio
        from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/OnDemandStartRatio.vue';
    import OnDemandAvgWatchTime
        from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/OnDemandAvgWatchTime.vue';
    import OnDemandTotalWatchTime
        from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/OnDemandTotalWatchTime.vue';
    import OnDemandStartsPerReferrer
        from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/OnDemandStartsPerReferrer.vue';
    import OnDemandViewRatio from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/OnDemandViewRatio.vue';
    import CardCountriesGeoChart
        from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/CardCountriesGeoChart.vue';
    import OnDemandRegistrationsExport
        from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/OnDemandRegistrationsExport';

    export default {
        name: 'OndemandStatistics',
        components: {
            CardCountriesGeoChart,
            OnDemandViewRatio,
            OnDemandStartsPerReferrer,
            OnDemandTotalWatchTime,
            OnDemandAvgWatchTime,
            OnDemandStartRatio,
            OnDemandImpressions,
            OnDemandViews,
            DropoffRate,
            StatsDatePicker,
            OnDemandRegistrationsExport
        },
        props: {
            selectedEvent: {
                type: Object,
                required: true
            },
        },
        data() {
            const start = new Date();
            const end = new Date();
            start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);
            return {
                views: 0,
                impressions: 0,
                daterange: [start, end],
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            videoID() {
                return this.selectedEvent.onDemandID;
            },
            videoPublished() {
                return new Date(0);
            },
        },
        methods: {
            daterangeUpdated(daterange) {
                // set the second date to one millisecond before midnight
                // (to include the entire length of the last day of the selection)
                if (daterange[1]) {
                    daterange[1].setHours(23, 59, 59, 999);
                }

                this.daterange = [...daterange];
            },
            onDemandViewsReady(value) {
                this.views = value;
            },
            onDemandImpressionsReady(value) {
                this.impressions = value;
            },
        },
    };
</script>
<style lang="scss">
.metric-explain {
    line-height: initial;
    font-size: 0.7rem;
    margin-top: -0.6rem;
}

.no-content-padding .v-list {
    margin: -15px !important;
    padding: 0 !important;
}

.no-last:last-child .v-divider {
    display: none
}

.number-color {
    color: rgba(0, 0, 0, 0.54);
    font-weight: bold;
}

.title-flex {
    display: flex;
    flex-direction: row;
}

.title-flex-item.flex-key {
    flex: 1 1 0;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
}

.title-flex-item.flex-number {
    flex: 0 0 auto;
}
#on-demand-regs {
    margin-bottom: 32px;
}
</style>