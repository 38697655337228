<template>
    <speakers-editor v-model="speakers"
                     :disabled="project.isArchived"
    />
</template>

<script>
    import SpeakersEditor from '@/components/controls/speakers/SpeakersEditor';
    import { getValidSpeakers } from '@/services/SpeakerService';
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'GeneralSettings',
        components: {
            SpeakersEditor,
        },
        computed:{
            ...mapState('projects', ['project']),
            speakers: {
                get() {
                    return this.project.speakers;
                },
                async set(value) {
                    this.setSpeakers(value);
                    await this.saveProject({
                        key: 'speakers',
                        value: getValidSpeakers(value),
                    });
                },
            },
            currentEvent(){
                return this.project?.events[0]?._id;
            },
        },
        methods:{
            ...mapMutations('projects', ['setSpeakers']),
            ...mapActions('projects', [ 'saveProject']),
        }
    };
</script>
