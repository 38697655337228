<template>
    <div>
        <v-row>
            <v-col>
                <v-text-field
                    v-model="elearningButtonTitle"
                    filled
                    class="text-input mt-4"
                    label="Elearning button title"
                    :disabled="project.isArchived"
                />
                <v-text-field
                    v-model="elearningButtonHref"
                    filled
                    class="text-input mt-4"
                    label="Elearning button URL"
                    :disabled="project.isArchived"
                    @keyup="validate"
                    @mouseup="validate"
                />
                <validation-observer ref="obs">
                    <validation-provider
                        v-if="isSpringerProject && elearningButtonHref"
                        v-slot="{errors}"
                        name="url"
                        rules="required"
                    >
                        <v-text-field
                            v-if="isSpringerProject && elearningButtonHref"
                            v-model="elearningButtonAlternateHref"
                            filled
                            class="text-input mt-4"
                            :error-messages="errors"
                            label="Elearning button fallback URL (https://(*.)elearning.quadia.net/3rd-party-connection?company=companyslug&courseSlug=slug)"
                            :disabled="project.isArchived"
                        />
                    </validation-provider>
                </validation-observer>
                <v-divider v-if="isSpringerProject" />
                <div class="d-inline-flex mt-5 mb-7">
                    <div class="mt-1 mr-4 d-inline-block">
                        Replace evaluation button
                    </div>
                    <v-switch
                        v-model="evaluationButtonOverwrite"
                        :input-value="evaluationButtonOverwrite"
                        inset
                        class="mt-0"
                        hide-details
                        :disabled="project.isArchived"
                    />
                </div>
                <v-text-field
                    v-model="evaluationButtonTitle"
                    filled
                    class="text-input mt-4"
                    label="Evaluation button title"
                    :disabled="project.isArchived"
                />
                <v-text-field
                    v-model="evaluationButtonHref"
                    filled
                    class="text-input mt-4"
                    label="Evaluation button URL"
                    :disabled="project.isArchived"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
    import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
    import {required} from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });

    export default {
        name: 'EvaluationCustomButtons',
        components: {ValidationProvider, ValidationObserver},
        computed: {
            ...mapGetters('projects', ['isSpringerProject']),
            ...mapState('projects', ['project']),
            ...mapState('evaluationFields', ['evaluation']),
            evaluationButtonHref: {
                get() {
                    return this.project.evaluationButton.href;
                },
                set(value){
                    this.setEvaluationButtonHref(value);
                    this.saveProject({
                        key: 'evaluationButton.href',
                        value: value,
                    });
                }
            },
            evaluationButtonTitle: {
                get() {
                    return this.project.evaluationButton.title;
                },
                set(value){
                    this.setEvaluationButtonTitle(value);
                    this.saveProject({
                        key: 'evaluationButton.title',
                        value: value,
                    });
                }
            },
            evaluationButtonOverwrite: {
                get() {
                    return this.project.evaluationButton.overwrite;
                },
                set(value){
                    this.setEvaluationButtonOverwrite(value);
                    this.saveProject({
                        key: 'evaluationButton.overwrite',
                        value: value,
                    });
                }
            },
            elearningButtonHref: {
                get() {
                    return this.project.elearningButton.href;
                },
                set(value){
                    this.setElearningButtonHref(value);
                    this.saveProject({
                        key: 'elearningButton.href',
                        value: value,
                    });
                }
            },
            elearningButtonAlternateHref: {
                get() {
                    return this.project.elearningButton.alternateHref;
                },
                set(value){
                    this.setElearningButtonAlternateHref(value);
                    this.saveProject({
                        key: 'elearningButton.alternateHref',
                        value: value,
                    });
                }
            },
            elearningButtonTitle: {
                get() {
                    return this.project.elearningButton.title;
                },
                set(value){
                    this.setElearningButtonTitle(value);
                    this.saveProject({
                        key: 'elearningButton.title',
                        value: value,
                    });
                }
            }
        },
        methods: {
            ...mapActions('projects', ['saveProject']),
            ...mapMutations('projects', [
                'setEvaluationButtonHref',
                'setEvaluationButtonTitle',
                'setEvaluationButtonOverwrite',
                'setElearningButtonHref',
                'setElearningButtonAlternateHref',
                'setElearningButtonTitle',
            ]),
            async validate() {
                setTimeout(async () => {
                    const valid = await this.$refs.obs.validate();
                    this.$emit('validate', valid);
                }, 400);
            }
        },
    };
</script>