<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{on}">
                <v-btn icon
                       tile
                       color="rgba(0,0,0, .87)"
                       :disabled="project.isArchived"
                       @click="openDialog"
                       v-on="on"
                >
                    <v-icon>
                        mdi-folder-open
                    </v-icon>
                </v-btn>
            </template>
            <span>Load from template...</span>
        </v-tooltip>
        <template-dialog v-model="dialogOpen"
                         :selected-template="selectedTemplate"
                         :index-url="indexUrl"
                         @confirm="selectTemplate"
        >
            <template v-slot:title>
                Load from template
            </template>
        </template-dialog>
        <v-divider class="my-0" />
    </div>
</template>

<script>
    import TemplateDialog from '@/components/pages/Project/EventPage/Templates/TemplateDialog';
    import { mapState } from 'vuex';
    export default {
        name: 'LoadFromTemplate',
        components: {TemplateDialog},
        props: {
            indexUrl: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                dialogOpen: false,
                selectedTemplate: null,
            };
        },
        computed:{
            ...mapState('projects', ['project'])
        },
        methods: {
            openDialog() {
                this.selectedTemplate = null;
                this.dialogOpen = true;
            },
            selectTemplate(value) {
                this.selectedTemplate = value.selectedTemplate;
                this.$emit('input', this.selectedTemplate);
            }
        }
    };
</script>

<style scoped>

</style>