<template>
    <stats-card class="no-content-padding h13 metric smaller"
                :loading="loading"
    >
        <template v-slot:title>
            Total watch time
        </template>
        <template v-slot:content>
            {{ watchTimeAsHours }}
            <div class="metric-explain">
                (hours, minutes and seconds)
            </div>
        </template>
    </stats-card>
</template>
<script>
    import { fetchStatisticsApi } from '@/services/StatisticsService';
    import StatsCard from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/StatsCard.vue';
    import TimeService from '@/services/TimeService';

    export default {
        name: 'OnDemandTotalWatchTime',
        components: {StatsCard},
        props: {
            dateRange: {
                type: Array,
                required: true
            },
            videoId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                totalWatchTimeData: [],
            };
        },
        computed: {
            watchTimeAsHours() {
                const currentVideoData = this.totalWatchTimeData.find(bucket => bucket.key === this.videoId);

                if (!currentVideoData) {
                    return '00:00:00';
                }

                return TimeService.fromMsToHis(currentVideoData.total_time);
            },
        },
        methods: {
            getTotalWatchTime() {
                this.loading = true;
                fetchStatisticsApi('/api/videos/total-watch-time', this.dateRange, this.videoId).then(response => {
                    this.totalWatchTimeData = response.data;
                }).finally(() => this.loading = false);
            },
        },
        mounted() {
            this.getTotalWatchTime();
        }
    };
</script>

<style scoped>

</style>