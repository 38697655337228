<template>
    <v-dialog :value="true"
              max-width="1000"
              persistent
              scrollable
    >
        <v-card>
            <v-card-title>
                Edit client {{ client.email }}
                <v-spacer />
                <v-btn icon
                       @click="cancel"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <client-details v-model="client"
                                @validate="clientDetailsValidated"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary"
                       :disabled="!valid || saving"
                       @click="save"
                >
                    Save Client
                </v-btn>
                <v-btn :disabled="saving"
                       @click="cancel"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions } from 'vuex';
    import ClientDetails from './ClientDetails';
    import { $http } from '@/services/http';
    import { makeUrl } from '@/services/ApiService';

    export default {
        name: 'EditClientDialog',
        components: { ClientDetails },
        props: ['client'],
        data() {
            return {
                saving: false,
                valid: false,
            };
        },
        methods: {
            ...mapActions('clients', ['getClients']),

            cancel() {
                this.$emit('close');
            },
            async save() {
                this.saving = true;
                var url = makeUrl('/clients/{user_id}', { userId: this.client.id });
                await $http.put(url, this.client);
                await this.getClients();
                this.$emit('close');
            },
            clientDetailsValidated(valid) {
                this.valid = valid;
            },
        },
    };
</script>
