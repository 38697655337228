<template>
    <div class="download-container mx-3 text-right">
        <v-btn color="#666666"
               class="font-weight-regular font-16"
               text
               :loading="loadingExport"
               :disabled="loadingExport"
               @click="exportExcel"
        >
            Download link tracking&nbsp;
            <v-icon>
                mdi-file-download-outline
            </v-icon>
        </v-btn>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { makeUrl } from '@/services/ApiService';
    import { $http } from '@/services/http';

    export default {
        name: 'LinkTrackingExport',
        data() {
            return {
                loadingExport: false,
            };
        },
        computed: {
            ...mapGetters('projects', ['currentProjectId'] ),
        },
        methods: {
            async exportExcel() {
                const url = makeUrl('/projects/{project_id}/stats/link-tracking/export',
                                    { projectId: this.currentProjectId });
                this.loadingExport = true;
                const response = await $http({
                    url,
                    method: 'GET',
                    responseType: 'blob',
                });

                const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
                const hiddenElement = document.createElement('a');
                hiddenElement.download = 'link-tracking-' + this.currentProjectId + '.xlsx';
                hiddenElement.href = downloadUrl;
                hiddenElement.click();
                this.loadingExport = false;
            }
        }
    };
</script>

<style scoped>

</style>