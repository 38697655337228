<template>
    <div class="preview-canvas">
        <v-row no-gutters
               class="tabs"
        >
            <v-col cols="12"
                   lg="10"
                   md="10"
                   sm="10"
                   class="pa-0 ma-0"
            >
                <v-tabs v-model="tab"
                        class="pt-2"
                >
                    <v-tab class="subtitle-1 text-capitalize tab-title"
                           :to="{ name: 'RegistrationPagePreview' }"
                           replace
                           href="#registrationPage"
                    >
                        Registration
                    </v-tab>
                    <v-tab class="subtitle-1 text-capitalize tab-title"
                           :to="{ name: 'RegistrationConfirmationPreview' }"
                           replace
                           href="#registrationConfirmationPage"
                    >
                        Registration confirmation
                    </v-tab>
                    <v-tab v-if="isHybridProject"
                           class="subtitle-1 text-capitalize last-tab-title"
                           :to="{ name: 'OnSiteRegistrationConfirmationPreview' }"
                           replace
                           href="#onSiteRegistrationConfirmationPage"
                    >
                        On-site Registration confirmation
                    </v-tab>
                </v-tabs>
            </v-col>
            <v-col cols="12"
                   lg="2"
                   md="2"
                   sm="2"
            >
                <v-row
                    justify="end"
                    align="center"
                >
                    <v-col class="pt-2">
                        <v-badge
                            :value="hoverMobile"
                            content="414x736"
                            left
                            offset-x="55"
                            transition="slide-x-transition"
                        >
                            <v-hover v-model="hoverMobile">
                                <v-btn
                                    large
                                    icon

                                    @click="mobileScreen"
                                >
                                    <v-icon>mdi-cellphone</v-icon>
                                </v-btn>
                            </v-hover>
                        </v-badge>
                    </v-col>
                    <v-col class="pt-2">
                        <v-badge
                            :value="hoverTablet"
                            content="768x1024"
                            left
                            offset-x="60"
                            transition="slide-x-transition"
                        >
                            <v-hover v-model="hoverTablet">
                                <v-btn
                                    large
                                    icon

                                    @click="tabletScreen"
                                >
                                    <v-icon large>
                                        mdi-tablet
                                    </v-icon>
                                </v-btn>
                            </v-hover>
                        </v-badge>
                    </v-col>
                    <v-col class="pt-2">
                        <v-badge
                            :value="hoverDesktop"
                            content="1920x1080"
                            left
                            offset-x="65"
                            transition="slide-x-transition"
                        >
                            <v-hover v-model="hoverDesktop">
                                <v-btn
                                    large
                                    icon

                                    @click="desktopScreen"
                                >
                                    <v-icon large>
                                        mdi-monitor
                                    </v-icon>
                                </v-btn>
                            </v-hover>
                        </v-badge>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <frontend-preview
            :full-screen="true"
            :actions="false"
            :page="page"
            :event-i-d="currentEventId"
            :mobile="mobile"
            :tablet="tablet"
            :desktop="desktop"
        />
    </div>
</template>

<script>
    import FrontendPreview from '@/components/pages/Project/Elements/FrontendPreview';
    import {mapGetters} from 'vuex';
    export default {
        name: 'PreviewRegistrationPages',
        components: {FrontendPreview},
        data(){
            return {
                tab: 'registrationPage',
                recentTab: 'registrationPage',
                obj: {
                    'registrationPage': 'registration',
                    'registrationConfirmationPage': 'registration-confirmation',
                    'onSiteRegistrationConfirmationPage': 'on-site-registration-confirmation',
                },
                hoverMobile: false,
                hoverTablet: false,
                hoverDesktop: false,
                mobile: false,
                tablet: false,
                desktop: true,
            };
        },
        computed: {
            ...mapGetters('projects',['isHybridProject']),
            page(){
                return this.obj[this.recentTab];
            },
            currentEventId(){
                return this.$route.params.event_id;
            },
        },
        watch:{
            $route: {
                handler(to) {
                    if(to.path.indexOf('registration') > -1) {
                        this.recentTab =  'registrationPage';
                    }
                    if(to.path.indexOf('registration-confirmation') > -1) {
                        this.recentTab =  'registrationConfirmationPage';
                    }
                    if(to.path.indexOf('on-site-registration-confirmation') > -1) {
                        this.recentTab =  'onSiteRegistrationConfirmationPage';
                    }
                },
                immediate: true,
            },
        },
        methods:{
            mobileScreen(){
                this.desktop = false;
                this.tablet = false;
                this.mobile = true;
            },
            tabletScreen(){
                this.desktop = false;
                this.mobile = false;
                this.tablet = true;
            },
            desktopScreen(){
                this.mobile = false;
                this.tablet = false;
                this.desktop = true;
            },
        }
    };
</script>

<style scoped>
.tabs {
    border-bottom: 12px solid;
    border-color: #f5f5f5;
    background-color: white;
    border-top: 4px white;
    height: 68px !important;

}
.tab-title {
    border-right: 2px solid;
    border-color: #f5f5f5;
    margin-bottom: 10px;

}

.last-tab-title{
     margin-bottom: 10px;

}
</style>
