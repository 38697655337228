<template>
    <stats-card class="h13 metric"
                :loading="loading"
    >
        <template v-slot:title>
            Impressions
        </template>
        <template v-slot:content>
            {{ impressions }}
        </template>
    </stats-card>
</template>
<script>
    import StatsCard from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/StatsCard.vue';
    import { fetchStatisticsApi } from '@/services/StatisticsService';

    export default {
        name: 'OnDemandImpressions',
        components: {StatsCard},
        props: {
            dateRange: {
                type: Array,
                required: true
            },
            videoId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                impressions: 0
            };
        },
        methods: {
            getImpressions() {
                this.loading = true;
                fetchStatisticsApi('/api/videos/impressions', this.dateRange, this.videoId).then(response => {
                    const buckets = response.data.filter(item => item.key === this.videoId);
                    this.impressions = 0;
                    if (buckets.length > 0) {
                        this.impressions = buckets[0].doc_count;
                        this.$emit('ready', buckets[0].doc_count);
                    }
                }).finally(() => this.loading = false);
            },
        },
        mounted() {
            this.getImpressions();
        }
    };
</script>

<style scoped>

</style>