<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="onSiteViewerReminderMailSubject"
                        filled
                        class="text-input"
                        label="Mail subject"
                        :disabled="!edit"
                    />
                </v-col>
            </v-row>
            <rich-text-editor 
                v-model="onSiteViewerReminderMailBody"
                :disabled="!edit"
                :with-agenda="true"
                :with-event-links="false"
                :with-on-site-start-time="isHybridProject"
                :with-location-address="isHybridProject"
                :with-font-selector="false"
            />
            <div v-if="!project.isArchived">
                <v-btn
                    v-if="!edit"
                    class="mt-5 mr-4 pa-2"
                    color="primary"
                    outlined
                    @click="editEmail"
                >
                    <v-icon class="mr-1">
                        mdi-pencil
                    </v-icon>
                    EDIT CONTENT
                </v-btn>
                <v-btn
                    v-else
                    class="mt-5 mr-4 pa-2"
                    color="primary"
                    @click="saveAndRefresh"
                >
                    <v-icon class="mr-1">
                        mdi-content-save
                    </v-icon>
                    SAVE CONTENT
                </v-btn>
            </div>
        </v-col>

        <v-divider
            vertical
        />
    </v-row>
</template>

<script>
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import { mapGetters, mapMutations, mapState } from 'vuex';
    import { $http } from '@/services/http';
    import {
        AUTOSAVE_PROJECT_URL,
        populateUrl,
    } from '@/services/ApiService';

    export default {
        name: 'OnSisteReminderMailing',
        components: { RichTextEditor },
        data(){
            return{
                edit: false,
                onSiteViewerReminderMailSubject:'',
                onSiteViewerReminderMailBody: ''
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['currentProjectId', 'isHybridProject']),
        },
        watch:{
            'project.emailSettings.onSiteViewerReminder.subject': {
                immediate:true,
                handler(value){
                    this.onSiteViewerReminderMailSubject = value;
                }
            },
            'project.emailSettings.onSiteViewerReminder.bodyText': {
                immediate:true,
                handler(value){
                    this.onSiteViewerReminderMailBody = value;
                }
            }
        },
        methods: {
            ...mapMutations('projects', ['setOnSiteViewerReminderBody', 'setOnSiteViewerReminderSubject']),
            editEmail(){
                this.edit = true;
            },
            async saveAndRefresh(){
                this.edit = false;
                this.setOnSiteViewerReminderSubject(this.onSiteViewerReminderMailSubject);
                this.setOnSiteViewerReminderBody(this.onSiteViewerReminderMailBody);
                var url = populateUrl(AUTOSAVE_PROJECT_URL, this.currentProjectId);
                await $http.put(url, {
                    'emailSettings.onSiteViewerReminder.subject' : this.onSiteViewerReminderMailSubject,
                    'emailSettings.onSiteViewerReminder.bodyText': this.onSiteViewerReminderMailBody,
                });
                this.$emit('refreshPreview');
            }
        },
    };
</script>
<style scoped>
.text-input {
  width: 22rem;
}
</style>
