<template>
    <v-row>
        <v-col class="py-0">
            <v-row>
                <v-col cols="12"
                       lg="3"
                       class="py-0"
                >
                    <custom-date-picker :key="`date-picker-${startDate}`"
                                        v-model="startDate"
                                        :top="true"
                                        :disabled="project.isArchived"
                    />
                </v-col>
                <v-col cols="12"
                       lg="3"
                >
                    <custom-time-picker :key="`time-picker-${startDate}`"
                                        v-model="startDate"
                                        :disabled="project.isArchived"
                    />
                </v-col>
                <v-col cols="12"
                       lg="2"
                       class="py-0"
                >
                    <v-text-field
                        v-model="durationInMinutes"
                        class="mt-4"
                        label="Duration"
                        type="number"
                        min="0"
                        :disabled="project.isArchived"
                    />
                </v-col>
                <v-col cols="12"
                       lg="4"
                       class="py-0"
                >
                    <div class="mt-8">
                        <h3 class="font-weight-regular todo-tbd">
                            Minutes
                        </h3>
                    </div>
                </v-col>
            </v-row>            
        </v-col>
    </v-row>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';
    import CustomDatePicker from '@/components/controls/CustomDatePicker';
    import CustomTimePicker from '@/components/controls/CustomTimePicker';

    export default {
        name: 'ProjectTime',
        components: { 
            CustomDatePicker,
            CustomTimePicker,
        },
        computed: {
            ...mapState('projects', ['project']),
            durationInMinutes: {
                get() {
                    return this.project.events[0]?.durationInMinutes;
                },
                set(value) {
                    this.setEventDuration(value);
                    this.saveEvent({
                        id: this.project.events[0]._id,
                        key: 'durationInMinutes',
                        value,
                    });
                },
            },
            startDate: {
                get() {
                    return this.project.startTime || 0;
                },
                async set(value) {
                    this.setProjectDateTime(value);
                    await this.saveProjectImmediate({
                        id: this.project._id,
                        key: 'startTime',
                        value: value,
                    });
                    await this.reloadProject();
                    this.reRender();
                },
            },
        },
        methods: {
            ...mapMutations('projects', [
                'setEventDuration',
                'setProjectDateTime',
                'reRender',
            ]),
            ...mapActions('projects', ['saveEvent', 'saveProjectImmediate', 'reloadProject']),
        },
    };
</script>
