<template>
    <v-card v-if="field"
            :value="value"
            flat
    >
        <v-card-text class="pa-0">
            <!-- <star-rating-option-editor
                v-if="field.type === 'star-rating'"
                v-model="field.options"
                @validate="optionsValidated"
                @confirm="updateField"
            />
            <scale-option-editor 
                v-else-if="field.type === 'scale'"
                v-model="field.options"
                @validate="optionsValidated"
                @confirm="updateField"
            />  -->
            <open-answer-dropdown-option-editor
                v-if="field.type === 'dropdown-open-answer'"
                v-model="field.options"
                @validate="optionsValidated"
                @confirm="updateField"
            />  
            <option-editor 
                v-else
                v-model="field.options"
                @validate="optionsValidated"
                @confirm="updateField"
            />
            <div v-if="!optionsValid">
                <div
                    v-if="optionsValidationReason === fieldOptionNone"
                    class="error--text"
                >
                    At least one option must be specified.
                </div>
                <div
                    v-if="optionsValidationReason === fieldOptionDuplicate"
                    class="error--text"
                >
                    There are duplicate options.
                </div>
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    import {
        EVALUATION_FIELD_VALIDATION_OPTION_DUPLICATE,
        EVALUATION_FIELD_VALIDATION_OPTION_NONE,
    } from '@/services/EvaluationFieldService';
    import OptionEditor from '@/components/controls/field-editor/EvaluationOptionEditor';
    // import StarRatingOptionEditor from "@/components/controls/field-editor/StarRatingOptionEditor";
    // import ScaleOptionEditor from "@/components/controls/field-editor/ScaleOptionEditor";
    import OpenAnswerDropdownOptionEditor from '@/components/controls/field-editor/OpenAnswerDropdownOptionEditor';

    export default {
        name: 'EvaluationFieldOptionEditorCard',
        components: { OptionEditor,OpenAnswerDropdownOptionEditor},
        props: {
            value: {
                type: Boolean,
                default: false,
            },
            field: {
                type: Object,
            },
        },
        data() {
            return {
                optionEditor: false,
                optionsValid: true,
                optionsValidationReason: '',
            };
        },
        computed: {
            fieldOptionNone() {
                return EVALUATION_FIELD_VALIDATION_OPTION_NONE;
            },
            fieldOptionDuplicate() {
                return EVALUATION_FIELD_VALIDATION_OPTION_DUPLICATE;
            },
        },
        methods: {
            updateField() {
                this.$emit('confirm');
            },
            optionsValidated({ valid, reason }) {
                this.optionsValid = valid;
                this.optionsValidationReason = reason;
            },
        },
    };
</script>

<style scoped lang="scss">
</style>
