import User from '@/models/User';
import { $http } from '@/services/http';
import {
    buildSimpleQueryString,
    makeUrl,
} from '@/services/ApiService';

const USER_ROWS_PER_PAGE_KEY = 'userRowsPerPage';
const PAGE_SIZE = 10;

const addRoleAndCompany = (data, user) => {
    const result = {
        ...data,
        role: user.role
    };

    if (user.role === 'freelancer') {
        return data;
    }
    if (user.role !== 'quadia_live_admin') {
        result.productionCompany = user.productionCompany;
    }

    return result;
};

const UserStore = {
    namespaced: true,
    state: {
        users: [],
        user: new User([]),
        userEditorState: 0,
        pagination: {
            searchText: '',
            current: 1,
            total: 1,
            pageSize: window.localStorage.getItem(USER_ROWS_PER_PAGE_KEY) || PAGE_SIZE,
        },
        listingHeader: {
            sorting: {
                targetId: 'email',
                direction: 'asc'
            }
        }
    },
    getters: {
        roles: () => [
            {
                'id': 'quadia_live_admin',
                'name': 'Quadia Live admin'
            },
            {
                'id': 'company_admin',
                'name': 'Company Admin'
            },
            {
                'id': 'project_editor',
                'name': 'Project Editor'
            },
            {
                'id': 'freelancer',
                'name': 'Freelancer'
            }
        ]
    },
    mutations: {
        setUsers: (state, payload) => state.users = payload,
        setUser: (state, payload) => state.user = payload,
        setProductionCompany: (state, payload) => state.user.productionCompany = payload,
        setRole: (state, payload) => state.user.roleId = payload,

        setRoles: (state, payload) => state.roles = payload,

        setSearchText: (state, payload) => state.pagination.searchText = payload || '',
        setCurrentPage: (state, payload) => state.pagination.current = parseInt(payload),
        setPageTotal: (state, payload) => state.pagination.total = parseInt(payload),
        setPageSize: (state, payload) => {
            window.localStorage.setItem(USER_ROWS_PER_PAGE_KEY, parseInt(payload));
            state.pagination.pageSize = parseInt(payload);
        },

        setSortingTargetId: (state, payload) => state.listingHeader.sorting.targetId = payload,
        setSortingDirection: (state, payload) => state.listingHeader.sorting.direction = payload,

        updateEditorState: (state) => ++state.userEditorState,
    },
    actions: {
        getUsers: async ({ state, commit }) => {
            var url = makeUrl('/users');
            var response = await $http.get(buildSimpleQueryString(url, {
                search: encodeURIComponent(state.pagination.searchText),
                page: state.pagination.current,
                perPage: state.pagination.pageSize,
                sort: state.listingHeader.sorting.targetId,
                order: state.listingHeader.sorting.direction
            }));

            commit('setUsers', response.data.data);
            commit('setCurrentPage', response.data.pagination.page);
            commit('setPageTotal', response.data.pagination.total);
            commit('setPageSize', response.data.pagination.perPage);
        },

        getUser: ({ commit }, payload) => {
            return $http.get(makeUrl(
                '/users/{user_id}',
                { userId: payload }
            ))
                .then(response => response.data)
                .then(data => {
                    commit('setUser', data);
                });
        },

        deleteUser: (context, payload) => {
            return $http.delete(makeUrl(
                '/users/{user_id}',
                { userId: payload }
            ));
        },

        storeUser: ({ state, rootGetters }) => {
            let data = {
                name: state.user.name,
                email: state.user.email,
                phoneNumber: state.user.phoneNumber,
                freelancerProjects: state.user.freelancerProjects.map(p => p.projectId)
            };

            if (rootGetters['auth/isQuadiaLiveAdmin'] ||
                rootGetters['auth/isCompanyAdminAtAnyProductionCompany']
            ) {
                data = addRoleAndCompany(data, state.user);
            }

            return $http.post(makeUrl('/users'), data);
        },

        updateUser: ({ state, rootGetters }) => {
            let data = {
                name: state.user.name,
                email: state.user.email,
                phoneNumber: state.user.phoneNumber
            };

            if (rootGetters['auth/isQuadiaLiveAdmin'] ||
                rootGetters['auth/isCompanyAdminAtAnyProductionCompany']
            ) {
                data = addRoleAndCompany(data, state.user);
            }
            return $http.put(
                makeUrl(
                    '/users/{user_id}',
                    { userId: state.user.id }
                ),
                data
            );
        },

        assignUserToFreelancerProject: (context, { userId, projectId }) => {
            return $http.put(makeUrl(
                '/users/{user_id}/assign-freelancer/{project_id}',
                { userId, projectId }));
        },

        unAssignUserFromFreelancerProject: (context, { userId, projectId }) => {
            return $http.put(makeUrl(
                '/users/{user_id}/unassign-freelancer/{project_id}',
                { userId, projectId }));
        }
    },

};

export default UserStore;
