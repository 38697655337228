<template>
    <div>
        <v-row v-for="(handout, index) in internalValue"
               :key="index"
        >
            <v-col cols="12"
                   class="pb-0"
            >
                <h4>
                    Handout {{ (index + 1) }}
                </h4>
            </v-col>
            <v-col class="mt-4 pt-0 pb-0 col-md-6">
                <v-text-field
                    v-model="handout.buttonText"
                    filled
                    class="text-input"
                    :rules="rules"
                    :label="'Handout button ' + (index + 1)"
                    :disabled="project.isArchived"
                />
            </v-col>
            <v-col class="mt-4 pt-0 pb-0 col-md-6">
                <handout-upload :current-url="handout.url"
                                @input="(data) => setHandout(handout, data)"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapActions, mapMutations, mapState} from 'vuex';
    import HandoutUpload from './HandoutUpload';

    export default {
        name: 'HandoutEditor',
        components: {HandoutUpload},

        props: {
            value: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                internalValue: this.value,
                rules: [v => v.length <= 40 || 'Max 40 characters'],
            };
        },
        computed: {
            ...mapState('projects', ['project']),
        },
        watch: {
            internalValue: {
                deep: true,
                handler(value) {
                    this.$emit('input', value);
                },
            },
        },
        methods: {
            ...mapActions('projects', ['saveProject']),
            ...mapMutations('projects', ['setProjectEndPageContent', 'setEvaluationButtonHide']),
            setHandout(handout, data) {
                handout.url = data.url;
                handout.path = data.key;
            }
        },
    };
</script>

<style scoped>

</style>
