<template>
    <div>
        <v-btn :disabled="project.isArchived"
               @click="openDialog"
        >
            <slot name="buttonText">
                Send now
            </slot>
        </v-btn>
        <v-dialog v-model="dialog"
                  :max-width="500"
        >
            <v-card>
                <v-card-title>
                    <slot name="title">
                        Send "Thank you" email
                    </slot>
                </v-card-title>
                <v-card-text class="d-flex align-center py-5 subtitle-1">
                    Are you sure you want to send the “Thank you” email?
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary"
                           type="submit"
                           class="mr-2"
                           :loading="sending"
                           :disabled="sending"
                           @click="send"
                    >
                        Send
                    </v-btn>
                    <v-btn @click="closeDialog">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { $http } from '@/services/http';
    import {EMAIL_SEND_THANK_YOU, populateUrl} from '@/services/ApiService';
    import {mapMutations, mapState} from 'vuex';
    import {toastMessage, toastSuccess} from '@/services/responseErrors';

    export default {
        name: 'SendThankYouEmail',
        data() {
            return {
                dialog: false,
                sending: false,
            };
        },
        computed:{
            ...mapState('projects', ['project']),
        },
        methods: {
            ...mapMutations('projects', ['setThankYouEmailsSentAt']),
            openDialog() {
                this.dialog = true;
            },
            closeDialog() {
                this.dialog = false;
            },
            send() {
                this.sending = true;
                toastMessage('Sending...');
                this.sendingInProgress = true;
                const url = populateUrl(EMAIL_SEND_THANK_YOU, this.project._id);
                return $http.post(url, {}).then((data) => {
                    toastSuccess('Thank you emails sent.');
                    this.setThankYouEmailsSentAt(data.data.time);
                    this.closeDialog();
                }).finally(() => {
                    this.sending= false;
                });
            }
        }
    };
</script>

<style scoped>

</style>
