<template>
    <div>
        <v-row>
            <v-col>
                <h2 class="font-weight-medium mt-2">
                    {{ totalRegistrations }} total registrations
                </h2>
            </v-col>
            <v-col v-if="canShowComponent"
                   class="d-flex"
                   style="justify-content : flex-end"
            >
                <registrations-export :selected-event="selectedEvent" />
            </v-col>
        </v-row>
        <v-row v-if="project.registration.type !== 'generated'">
            <registrations-over-time />
        </v-row>
        <v-row>
            <v-col>
                <h4 class="font-weight-medium mt-1">
                    Viewer info
                </h4>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-pagination
                    v-if="pagination.total > 1"
                    v-model="currentPage"
                    :length="pagination.total"
                    circle
                    total-visible="6"
                    class="mb-4"
                    color="secondary"
                />
                <registration-list :loading="loading"
                                   :items="items"
                                   @update="getViewersInfo"
                />
                <v-row align="center"
                       align-content="center"
                       justify="center"
                >
                    <v-col class="d-flex"
                           cols="1"
                           sm="1"
                    >
                        <small class="d-flex justify-right"
                               style="width: 100%; white-space: nowrap"
                        >
                            Rows per page
                        </small>
                    </v-col>
                    <v-col class="d-flex"
                           cols="1 sm=1"
                    >
                        <v-select v-model="rowsPerPageSelected"
                                  :items="rowsPerPageItems"
                                  :label="undefined"
                                  outlined
                                  dense
                                  hide-details
                                  @input="rowsPerPageChanged"
                        >
                            <template v-slot:selection="{item}">
                                <span class="d-flex justify-end"
                                      style="width: 100%"
                                >{{ item }}</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col
                        class="d-flex"
                        cols="6"
                        sm="3"
                    >
                        <v-pagination
                            v-if="pagination.total > 1"
                            v-model="currentPage"
                            :length="pagination.total"
                            circle
                            total-visible="6"
                            color="secondary"
                        />
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import RegistrationList from '../Elements/RegistrationList';
    import {mapGetters, mapState} from 'vuex';
    import ProjectStatisticsService from '@/services/ProjectStatisticsService';
    import RegistrationsOverTime
        from '@/components/pages/Project/StatisticsTabs/RegistrationStatistics/RegistrationsOverTime';
    import RegistrationsExport
        from '@/components/pages/Project/StatisticsTabs/RegistrationStatistics/RegistrationsExport';

    export default {
        name: 'RegistrationStatistics',
        components: {RegistrationsExport, RegistrationsOverTime, RegistrationList},
        props: {
            selectedEvent: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                rowsPerPageSelected: 5,
                offset: 0,
                pagination: {
                    current: 1,
                    total: 1,
                    pageSize: 2,
                    itemCount: 2,
                },
                loading: false,
                items: [],
                totalRegistrations: 0,
                totalRegistrationsPerEvent: 0,
                rowsPerPageItems: [5, 10, 15, 20, 25, 50, 100],
            };
        },
        computed: {
            ...mapGetters('projects', ['currentProjectId', 'isMultiSession']),
            ...mapGetters('auth', ['canSeeRegistrationData']),
            ...mapState('projects', ['project']),
            currentPage: {
                get() {
                    return this.pagination.current;
                },
                set(value) {
                    if (this.pagination.current < value) {
                        this.offset = this.offset + (value - this.pagination.current) * this.rowsPerPageSelected;
                    } else {
                        this.offset = this.offset - (this.pagination.current - value) * this.rowsPerPageSelected;
                    }
                    this.setTotalPages();
                    this.loading = true;
                    this.getViewersInfo();
                    this.pagination.current = value;
                },
            },
            canShowComponent() {
                return this.canSeeRegistrationData(this.project);
            },
        },
        methods: {
            rowsPerPageChanged() {
                this.pagination.current = 1;
                this.offset = 0;
                this.setTotalPages();
                this.getViewersInfo();
            },
            loadData() {
                if (!this.currentProjectId) {
                    return;
                }
                ProjectStatisticsService.getTotalRegistrationsCount(this.currentProjectId)
                    .then((count) => {
                        if (count) {
                            this.totalRegistrations = count;
                            this.setTotalPages();
                        }
                    });
                this.getViewersInfo();
            },
            setTotalPages() {
                const totalPages = this.selectedEvent.breakout_group === null ?
                    this.totalRegistrations / this.rowsPerPageSelected :
                    this.totalRegistrationsPerEvent / this.rowsPerPageSelected;
                if (Number.isInteger(totalPages)) {
                    this.pagination.total = totalPages;
                } else {
                    this.pagination.total = Math.floor(totalPages) + 1;
                }
            },
            getViewersInfo() {
                this.loading = true;
                if (!this.isMultiSession || this.selectedEvent.breakout_group === null) {
                    return ProjectStatisticsService.getViewersInfo(this.selectedEvent._id, this.rowsPerPageSelected, this.offset)
                        .then((data) => {
                            this.items = data;
                            this.loading = false;
                        });
                } else {
                    ProjectStatisticsService.getViewersInfoPerEvent(this.selectedEvent._id, this.rowsPerPageSelected, this.offset)
                        .then((data) => {
                            this.items = data;
                            this.loading = false;
                        });
                }
            },
        },
        watch:{
            selectedEvent:{
                handler(value){
                    if(value.breakout_group !== null){
                        ProjectStatisticsService.getTotalRegistrationsCountPerEvent(value._id)
                            .then((count) => {
                                if (count) {
                                    this.totalRegistrationsPerEvent = count;
                                    this.setTotalPages();
                                }
                            });
                    }
                },
                immediate:true
            }
        },
        mounted() {
            this.loadData();
        }
    };
</script>
