const SnackbarStore = {
    namespaced: true,
    state: () => ({
        content: '',
        type: '',
        timeout: 2000,
    }),
    mutations: {
        showMessage: (state, payload) => {
            state.content = payload.content;
            state.type = payload.type;
            state.timeout = payload.timeout;
            state.hide = payload.hide || false; // may not be set
        },

        hideMessage: () => {}
    }
};

export default SnackbarStore;