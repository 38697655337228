<template>
    <v-tooltip bottom>
        <template v-slot:activator="{on}">
            <v-icon v-if="isRegular"
                    small
                    class="mr-1"
                    :class="{'drag-handle': dragHandle}"
                    v-on="on"
            >
                slideshow
            </v-icon>
            <v-icon v-if="isJitsiMeet"
                    small
                    class="mr-1"
                    :class="{'drag-handle': dragHandle}"
                    v-on="on"
            >
                question_answer
            </v-icon>
        </template>
        <span v-if="isRegular">
            Regular session
        </span>
        <span v-if="isJitsiMeet">
            Interactive session (Jitsi)
        </span>
        <span v-if="dragHandle"
              class="d-block"
        >( Drag to reorder )</span>
    </v-tooltip>
</template>
<script>
    export default {
        name: 'EventType',
        props: {
            event: {
                type: Object,
                required: true
            },
            dragHandle: {
                type: Boolean,
                default: () => false
            }
        },
        computed: {
            isJitsiMeet() {
                return this.event.sessionType === 'jitsi-meet';
            },
            isRegular() {
                return this.event.sessionType === 'regular';
            }
        }
    };
</script>

<style scoped>
.drag-handle {
    cursor: grabbing;
}
</style>