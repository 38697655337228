import {$http} from '@/services/http';
import {
    SINGLE_COMPANY_URL,
    populateCompanyUrl,
    buildSimpleQueryString,
    COMPANIES_URL,
    AUTOSAVE_COMPANY_URL
} from '@/services/ApiService';
import Company from '@/models/Company';

const PAGE_SIZE = 10;

const CompanyStore = {
    namespaced: true,
    state: {
        companies: [],
        company: new Company(),
        pagination: {
            current: 1,
            total: 1,
            pageSize: PAGE_SIZE,
            itemCount: 0,
        },
        listingHeader: {
            sorting: {
                targetId: 'name',
                direction: 'asc'
            }
        }
    },
    getters: {
        currentCompanyId: state => state.company?._id || undefined,
    },
    mutations: {
        setCompanies: (state, payload) => state.companies = payload,
        setCompany: (state, payload) => state.company = new Company(payload),
        setCompanyName: (state, payload) => state.company.name = payload,
        setFromName: (state, payload) => state.company.mailFromName = payload,
        setProductionCompany: (state, payload) => state.company.productionCompany = payload,
        setLogo: (state, payload) => state.company.logo = payload,
        setLogoUrl: (state, payload) => state.company.logoUrl = payload,
        setFavicon: (state, payload) => state.company.favicon = payload,
        setFaviconUrl: (state, payload) => state.company.faviconUrl = payload,
        setPrimaryColor: (state, payload) => state.company.primaryColor = payload,
        setCompanyPrivacyPolicyUrl: (state, payload) => state.company.privacyPolicyUrl = payload,

        setCurrentPage: (state, payload) => state.pagination.current = parseInt(payload),
        setPageTotal: (state, payload) => state.pagination.total = parseInt(payload),
        setPageSize: (state, payload) => state.pagination.pageSize = parseInt(payload),
        setItemCount: (state, payload) => state.pagination.itemCount = parseInt(payload),

        setSortingTargetId: (state, payload) => state.listingHeader.sorting.targetId = payload,
        setSortingDirection: (state, payload) => state.listingHeader.sorting.direction = payload,

    },
    actions: {
        getCompanies: ({commit}, payload) => {
            const url = buildSimpleQueryString(COMPANIES_URL, payload);
            return $http.get(url)
                .then(response => response.data)
                .then((data) => {
                    commit('setCompanies', data.companies);
                    commit('setCurrentPage', data.pagination.current);
                    commit('setPageTotal', data.pagination.total);
                    commit('setPageSize', data.pagination?.pageSize || PAGE_SIZE);
                    commit('setItemCount', data.pagination?.itemCount || 0);
                });
        },
        getCompany: async({ commit }, companyId) => {
            var response = await $http.get(populateCompanyUrl(SINGLE_COMPANY_URL, companyId));
            var company = new Company(response.data);
            commit('setCompany', company);
            return company;
        },
        storeCompany: async(context, payload) => {
            return $http.post(COMPANIES_URL, payload);
        },
        saveCompany:  async function ({ state }, { key, value }) {
            var input = {};
            input[key] = value;
            var url = populateCompanyUrl(AUTOSAVE_COMPANY_URL, state.company._id);
            await $http.put(url, input);
        },
    }
};

export default CompanyStore;
