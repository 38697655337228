<template>
    <v-container fluid>
        <v-row class="page-header-container grey lighten-4">
            <v-col cols="12"
                   md="2"
                   lg="2"
            >
                <h1 class="text-center font-weight-regular page-header">
                    Archived events
                </h1>
            </v-col>
            <v-col cols="12"
                   md="2"
                   lg="2"
            >
                <v-text-field v-model="searchText"
                              solo
                              flat
                              hide-details
                              dense
                              background-color="rgba(0,0,0,0.1)"
                              placeholder="Search"
                              clearable
                />
            </v-col>
        </v-row>
        <div v-if="!loading">
            <archive-project-list :project-events="archivedProjects" />

            <v-container fluid>
                <v-row align="center"
                       align-content="center"
                       justify="center"
                >
                    <v-col
                        class="d-flex"
                        cols="1"
                        sm="1"
                    >
                        <small class="d-flex justify-right"
                               style="width: 100%; white-space: nowrap"
                        >
                            Rows per page
                        </small>
                    </v-col>
                    <v-col
                        class="d-flex"
                        cols="1"
                        sm="1"
                    >
                        <v-select
                            v-model="rowsPerPageSelected"
                            :items="rowsPerPageItems"
                            :label="undefined"
                            :outlined="true"
                            :dense="true"
                            :hide-details="true"
                            @input="rowsPerPageChanged"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="d-flex justify-end"
                                      style="width: 100%;"
                                >
                                    {{ item }}
                                </span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col
                        class="d-flex"
                        cols="6"
                        sm="3"
                    >
                        <v-pagination v-if="pagination.total > 1"
                                      v-model="currentPage"
                                      :length="pagination.total"
                                      circle
                                      total-visible="6"
                                      color="secondary"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-progress-linear v-else
                           indeterminate
        />
    </v-container>
</template>

<script>

    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import ArchiveProjectList from '@/components/pages/Archives/ArchiveProjectList';
    import {debounce} from 'lodash';

    export default {
        name: 'Archive',
        components: {ArchiveProjectList},
        data() {
            return {
                loading: true,
                inProgress: false,
                searchText: '',
                paginatorCurrentPageKey: 1,
                rowsPerPageItems: [10, 25, 50, 100],
                rowsPerPageSelected: 25
            };
        },
        watch: {
            searchText: debounce(function () {
                this.setCurrentPage(1);
                this.loadProjectEvents(true);
            },400),
            paginatorCurrentPageKey: debounce(function () {
                this.loadProjectEvents(false);
            }, 400),
            sortBy: debounce(function () {
                this.loadProjectEvents(false);
            }, 400),
            sortOrder: debounce(function () {
                this.loadProjectEvents(false);
            }, 400),
            headerFilters: debounce(function () {
                this.setCurrentPage(1);
                this.loadProjectEvents(false);
            }, 400),
        },
        computed: {
            ...mapState('archives', ['archivedProjects', 'pagination', 'listingHeader']),
            ...mapGetters('auth', [
                'isQuadiaLiveAdmin',
                'isCompanyAdminAtAnyProductionCompany',
                'isProjectEditorAtAnyProductionCompany'
            ]),
            currentPage: {
                get() {
                    return this.pagination.current;
                },
                set(value) {
                    this.paginatorCurrentPageKey += 1;
                    this.setCurrentPage(value);
                }
            },
            sortBy(){
                return this.listingHeader.sorting.targetId;
            },
            sortOrder(){
                return this.listingHeader.sorting.direction;
            },
            headerFilters(){
                return this.listingHeader.filtering.filters;
            },
            canCreateProject() {
                return this.isQuadiaLiveAdmin ||
                    this.isCompanyAdminAtAnyProductionCompany ||
                    this.isProjectEditorAtAnyProductionCompany;
            }
        },
        methods: {
            ...mapActions('archives', ['getArchivedProjects']),
            ...mapMutations('archives', ['setCurrentPage', 'setPageTotal']),
            async loadProjectEvents(reload) {
                this.loading = reload;
                await this.getArchivedProjects(this.getParams());
                this.loading = false;
            },
            rowsPerPageChanged() {
                window.localStorage.setItem('projectRowsPerPage', this.rowsPerPageSelected);
                this.setCurrentPage(1);
                this.loadProjectEvents(true);
            },
            getParams() {
                const params = {};
                if (this.searchText) {
                    params['search'] = encodeURIComponent(this.searchText);
                }

                if (this.currentPage && this.currentPage > 1) {
                    params['page'] = this.currentPage;
                }

                params['perPage'] = this.rowsPerPageSelected;

                if(this.sortBy){
                    params['sort'] = this.sortBy;
                }

                if(this.sortOrder){
                    params['order'] = this.sortOrder;
                }

                let filters = {...this.headerFilters};
                if(filters){
                    for(let k in filters){
                        params['filter['+k+']'] = filters[k].map(x => x.id);
                    }
                }

                return params;
            }
        },
        mounted() {
            let rowsPerPage = window.localStorage.getItem('projectRowsPerPage');
            if (rowsPerPage) {
                this.rowsPerPageSelected = parseInt(rowsPerPage);
            }
            this.setCurrentPage(1);
            this.loadProjectEvents(true);
        }
    };
</script>

<style scoped>

</style>
