<template>
    <v-dialog :value="value"
              max-width="700"
              persistent
    >
        <v-card>
            <v-card-title>
                Would you like to delete the selected block?
                <v-spacer />
                <v-btn icon
                       small
                       :disabled="loading"
                       @click="close"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    color="error"
                    :loading="loading"
                    :disabled="loading"
                    @click="confirm"
                >
                    Yes, delete
                </v-btn>
                <v-btn :disabled="loading"
                       @click="close"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions, mapMutations } from 'vuex';
    export default {
        name: 'DeleteBlockDialog',
        props: {
            value: {
                type: Boolean,
                default: () => false,
            },
            block: {
                type: Object,
                required: false,
            },
            saveUrl: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                loading: false,
            };
        },
        methods: {
            ...mapMutations('dynamic-block', ['removeBlock', 'unselectBlock']),
            ...mapActions('dynamic-block', ['saveBlocks']),
            close() {
                this.$emit('input', false);
            },
            async confirm() {
                this.loading = true;
                this.unselectBlock();
                this.removeBlock(this.block);
                await this.saveBlocks(this.saveUrl);
                this.loading = false;
                this.close();
            },
        },
    };
</script>