<template>
    <div class="custom-color-picker"
         :class="{'disabled': disabled}"
         @click="openPicker"
    >
        <div class="custom-color-picker-code">
            {{ hex }}
        </div>
        <div class="custom-color-picker-sample"
             :style="style"
        />
        <v-dialog v-model="pickerModal"
                  :max-width="300"
        >
            <v-card>
                <v-card-title>
                    Select background color
                </v-card-title>
                <v-divider />
                <v-card-text class="pt-4">
                    <v-color-picker v-model="colorPickerValue"
                                    mode="hexa"
                                    :disabled="disabled"
                    />
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="cancelPicker">
                        Cancel
                    </v-btn>
                    <v-btn color="primary"
                           @click="colorPickerConfirm"
                    >
                        OK
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import {cssToRgba, intToHex, opacityToHex, rgbaToCss} from '@/services/ColorService';

    export default {
        name: 'CustomColorPicker',
        props: {
            value: {
                type: String,
                default: () => 'rgba(32,32,32,1)'
            },
            disabled: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            const v = cssToRgba(this.value);
            return {
                internalValue: v,
                colorPickerValue: {...v},
                pickerModal: false
            };
        },
        computed: {
            style() {
                return {
                    'background-color': rgbaToCss(this.internalValue),
                };
            },
            hex() {
                const rgba = this.internalValue;
                return `#${intToHex(rgba.r)}${intToHex(rgba.g)}${intToHex(rgba.b)}${opacityToHex(rgba.a)}`
                    .toUpperCase();
            },

        },
        watch: {
            colorPickerValue(value) {
                const v = {...value};
                if (v.a < 0.001) {
                    v.a = 0.001;
                    this.colorPickerValue = v;
                }
            }
        },
        methods: {
            openPicker() {
                if(!this.disabled){
                    this.colorPickerValue = {...this.internalValue};
                    this.pickerModal = true;
                }
            },
            cancelPicker(){
                this.closePicker();
                this.colorPickerValue = null;
            },
            closePicker() {
                this.pickerModal = false;
            },
            colorPickerConfirm() {
                this.closePicker();
                const v = rgbaToCss(this.colorPickerValue);
                this.internalValue = {...this.colorPickerValue};
                this.$emit('input', v);
            }
        }
    };
</script>

<style scoped lang="scss">
    @import "src/_scss/components/colors";

    .custom-color-picker:hover{
        .custom-color-picker-code{
            color: #484545;
        }
    }

    .disabled{
        cursor: default !important;
            &:hover{
                .custom-color-picker-code{
                    color: #888888 !important;
                }
            }
    }
    .custom-color-picker {
        display: flex;
        cursor: pointer;
    }

    .custom-color-picker-code {
        color: $med-gray-2;
        line-height: 1rem;
        font-size: 0.86rem;
        padding-top: 2px;
    }

    .custom-color-picker-sample {
        height: 20px;
        width: 20px;
        border-radius: 3px;
        margin-left: 10px
    }
    
</style>
