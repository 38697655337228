<template>
    <div
        class="panel"
        :class="{
            selected: isSelectedBlock(block),
            'parent-of-selected': isParentOfSelectedBlock(block),
        }"
    >
        <div class="header d-flex"
             @click="toggle"
        >
            <div class="header-text flex-grow-1 mx-6 py-2">
                {{ mapComponentName(block) }}
            </div>
            <div class="flex-grow-0">
                <slot name="header-buttons" />
            </div>
        </div>
        <div class="content px-6 py-2">
            <component :is="mapComponent(block)"
                       :block="block"
                       :save-url="saveUrl"
                       :dynamic-block-kind="dynamicBlockKind"
                       :has-tab-titles="dynamicBlockKind === 'registration'"
            />

            <slot name="after-content" />
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapMutations, mapState } from 'vuex';
    import { componentTypeNames } from '@/components/pages/Project/EventPage/blocks-helper.js';
    import Column from '@/components/pages/Project/EventPage/Components/Column.vue';
    import Spacer from '@/components/pages/Project/EventPage/Components/Spacer.vue';
    import ImageContentBlock from '@/components/pages/Project/EventPage/Components/ImageContentBlock.vue';
    import SlideShow from '@/components/pages/Project/EventPage/Components/SlideShow.vue';
    import CountDown from '@/components/pages/Project/EventPage/Components/CountDown.vue';
    import VideoContentBlock from '@/components/pages/Project/EventPage/Components/VideoContentBlock.vue';
    import Speakers from '@/components/pages/Project/EventPage/Components/Speakers.vue';
    import TextContent from '@/components/pages/Project/EventPage/Components/TextContent.vue';
    import ProgramElement from '@/components/pages/Project/EventPage/Components/ProgramElement.vue';
    import HeaderBlock from '@/components/pages/Project/EventPage/Components/HeaderBlock.vue';
    import UpcomingEvents from '@/components/pages/Project/EventPage/Components/UpcomingEvents.vue';


    export default {
        name: 'BlockSidebarPanel',
        props: {
            block: {
                type: Object,
                required: true,
            },
            saveUrl: {
                type: String,
                required: true,
            },
            dynamicBlockKind: {
                type: String,
                required: true,
            }
        },
        computed: {
            ...mapState('dynamic-block', ['blocks']),
            ...mapGetters('dynamic-block', [
                'isSelectedBlock',
                'isParentOfSelectedBlock',
            ]),
        },
        methods: {
            ...mapMutations('dynamic-block', [
                'selectBlock',
                'scrollOverlayToSelectedBlock',
            ]),
            mapComponent(block) {
                return {
                    column: Column,
                    'image-content-block': ImageContentBlock,
                    slideshow: SlideShow,
                    'count-down-block': CountDown,
                    'video-content-block': VideoContentBlock,
                    'text-content-block': TextContent,
                    speakers: Speakers,
                    spacer: Spacer,
                    'program-element': ProgramElement,
                    'header-block': HeaderBlock,
                    'upcoming-events': UpcomingEvents,
                }[block.componentType];
            },
            mapComponentName(block) {
                return componentTypeNames[block.componentType];
            },
            toggle() {
                this.selectBlock(this.block);
                this.scrollOverlayToSelectedBlock();
            },
        },
    };
</script>

<style lang="scss"
       scoped
>
.panel {
  border-top: 1px solid #e3e3e3;
  .header {
    cursor: pointer;
    font-weight: 500;
    color: #3e3e3e;

    &:hover {
      background-color: #f0f0f0;
    }
  }

  .content {
    display: none;
  }

  &.parent-of-selected,
  &.selected {
    > .content {
      display: block;
      background: #f5f5f5f5;
    }

    >.header {
      border-bottom: 1px solid #e3e3e3;
      background-color: #e3e3e3;
      font-weight: 700;
      color: #3e3e3e;
    }
  }
}
</style>

<style lang="scss">
    .panel {
        .content {
            label {
                font-size: .875rem;
            }
        }
    }
</style>