<template>
    <v-text-field
        v-model="model"
        :label="customLabel"
        :rules="timeFormat.concat(rules)"
        prepend-icon="mdi-clock-time-four-outline"
        :disabled="disabled"
        @input="input"
    />
</template>

<script>
    import TimeService from '@/services/TimeService';
    import moment from 'moment-timezone';
    import { mapState } from 'vuex';
    import { debounce } from 'lodash';


    export default {
        name: 'CustomTimePicker',
        props: {
            value: {
                type: Number,
                required: true,
            },
            disabled: {
                type: Boolean,
                default: () => false
            },
            rules: {
                type: Array,
                default:() => []
            },
            sendTimeFormatted: {
                type: Boolean,
                default: () => false
            },
            label: {
                type: String,
                default: () => '',
            }
        },
        data(){
            return {
                model: null,
            };
        }, 
        computed:{
            ...mapState('projects', ['project']),
            timeFormat() {
                const pattern = /^([01]?\d|2[0-3])[:.]?([0-5]\d)$/;
                return [(v) => pattern.test(v) || 'Correct date format HH:MM, HH.MM or HHMM'];
            },
            startTimeZone() {
                const s = this.dateTimeStr || moment(new Date()).tz(TimeService.defaultTimezone).format('YYYY-MM-DD HH:mm');
                return moment.tz(s, TimeService.defaultTimezone).format('z');
            },
            customLabel() {
                return `${(this.label || 'Start time')} ${this.startTimeZone}`;
            }
        },
        methods:{
            input(value){
                const patternHHMM = /^([01]\d|2[0-3])[:.]?([0-5]\d)$/;
                const patternHMM = /^(\d)[:.]?([0-5]\d)$/;
                if(patternHHMM.test(value)){
                    if(value.indexOf(':') > -1){
                        this.emitData(value);
                    } else if(value.indexOf('.') > -1){
                        const time = `${value.slice(0,2)}:${value.slice(3,6)}`;
                        this.emitData(time);
                    } else {
                        const time = `${value.slice(0,2)}:${value.slice(2,5)}`;
                        this.emitData(time);
                    }
                }
                if(patternHMM.test(value)){
                    if(value.indexOf(':') > -1){
                        const time = `0${value}`;
                        this.emitData(time);
                    } else if(value.indexOf('.') > -1){
                        const time = `0${value.slice(0,1)}:${value.slice(2,4)}`;
                        this.emitData(time);
                    } else {
                        const time = `0${value.slice(0,1)}:${value.slice(1,3)}`;
                        this.emitData(time);
                    }
                }
            },
            emitData: debounce(function(startHour){
                const projectStartTime = this.project.startTime ? this.project.startTime : this.value;
                const eventStartTime = TimeService.eventStartTimeFromProjectStartTime(projectStartTime, startHour);
                if(this.sendTimeFormatted){
                    this.$emit('formattedStartTime', startHour);
                }
                this.$emit('input', eventStartTime);
            },500)
        },
        watch:{
            value(value){
                if(this.model){
                    let date = moment(value, 'X').tz(TimeService.defaultTimezone);
                    this.model = date.format('HH:mm');
                }
            }
        },
        created(){
            if(this.value){
                let date = moment(this.value, 'X').tz(TimeService.defaultTimezone);
                this.model = date.format('HH:mm');            
            }
        }
    };
</script>

