<template>
    <div>
        <v-menu close-on-click
                close-on-content-click
                bottom
                offset-y
                offset-x
        >
            <template v-slot:activator="{on, attrs}">
                <v-btn v-bind="attrs"
                       color="primary"
                       class="mr-8"
                       v-on="on"
                >
                    Create...
                </v-btn>
            </template>
            <v-card width="250">
                <v-list>
                    <v-list-item-group>
                        <v-list-item three-line
                                     @click="create(eventManagerIntake)"
                        >
                            <v-list-item-content>
                                <v-list-item-title>
                                    Event Manager Intake Form
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Intake form specific to the legacy Event Manager
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                        <v-list-item three-line
                                     @click="create(quadiaLiveIntake)"
                        >
                            <v-list-item-content>
                                <v-list-item-title>
                                    Quadia Live Intake Form
                                </v-list-item-title>
                                <v-list-item-subtitle>
                                    Intake form for the new Quadia Live
                                </v-list-item-subtitle>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
            </v-card>
        </v-menu>
        <v-dialog v-model="showModal"
                  max-width="700"
        >
            <v-card>
                <v-card-title>
                    <h3 class="font-weight-regular">
                        Your intake form is ready
                    </h3>
                </v-card-title>
                <v-divider />
                <v-card-text>
                    <p class="my-4">
                        Your intake form has been created and is available under <a :href="intakeUrl">{{ intakeUrl }}</a>
                    </p>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn :href="intakeUrl"
                           target="_blank"
                           color="primary"
                    >
                        Go to intake form
                    </v-btn>
                    <v-btn @click="closeModal">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import { INTAKE_LIST_URL, INTAKE_URL, populateIntakeUrl } from '@/services/ApiService';
    import { $http } from '@/services/http';

    export default {
        name: 'CreateIntake',
        data() {
            return {
                showModal: false,
                createdId: '',
            };
        },
        computed: {
            eventManagerIntake() {
                return 'event-manager';
            },
            quadiaLiveIntake() {
                return 'quadia-live';
            },
            intakeUrl() {
                return populateIntakeUrl(INTAKE_URL, this.createdId);
            }
        },
        methods: {
            create(type) {
                $http.post(INTAKE_LIST_URL, {type: type})
                    .then((response) => response.data)
                    .then((data) => {
                        this.createdId = data._id;
                        this.showModal = true;
                        this.$emit('created', data._id);
                    });
            },
            closeModal() {
                this.showModal = false;
                this.createdId = '';
            },
        }
    };
</script>

<style scoped>

</style>