<template>
    <div>
        <v-tooltip bottom>
            <template v-slot:activator="{on}">
                <v-btn icon
                       v-on="on"
                       @click="showModal"
                >
                    <v-icon>mdi-eye</v-icon>
                </v-btn>
            </template>
            <span>View</span>
        </v-tooltip>
        <v-dialog
            v-if="modal"
            v-model="modal"
            scrollable
        >
            <v-card>
                <v-card-title>
                    Activity details
                    <v-spacer />
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-btn icon
                                   @click="closeModal"
                                   v-on="on"
                            >
                                <v-icon>close</v-icon>
                            </v-btn>
                        </template>
                        <span>Close</span>
                    </v-tooltip>
                </v-card-title>
                <v-card-subtitle class="mb-0 pb-0 pt-4">
                    <div class="d-flex header-below mx-n6 px-6 py-3 mb-0">
                        <div v-if="isMultiSession"
                             class="d-flex mr-16"
                        >
                            Event: <changed-event class="ml-2"
                                                  :event-id="item.eventId"
                                                  show-link
                            />
                        </div>
                        <div class="d-flex">
                            User: <changing-user class="ml-2"
                                                 :user="item.user"
                                                 show-link
                            />
                        </div>
                    </div>
                </v-card-subtitle>
                <v-card-text class="pt-4">
                    <pre>{{ value }}</pre>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
    import trim from 'lodash';
    import ChangedEvent from '@/components/pages/ActivityLog/ChangedEvent';
    import ChangingUser from '@/components/pages/ActivityLog/ChangingUser';
    import { mapGetters } from 'vuex';

    export default {
        name: 'LogDetails',
        components: {ChangingUser, ChangedEvent},
        data() {
            return {
                modal: false
            };
        },
        props: {
            item: {
                type: Object,
                required: true
            }
        },
        computed: {
            ...mapGetters('projects', ['isMultiSession']),
            value() {
                return JSON.stringify(JSON.parse(trim(this.item.newValue)), undefined, 4);
            }
        },
        methods: {
            showModal() {
                this.modal = true;
            },
            closeModal() {
                this.modal = false;
            }
        }
    };
</script>

<style scoped>
    pre {
        background-color: #3e3e3e;
        color: #e9e9e9;
        width: 100%;
        white-space: pre-wrap;       /* Since CSS 2.1 */
        white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
        white-space: -o-pre-wrap;    /* Opera 7 */
        word-wrap: break-word;       /* Internet Explorer 5.5+ */
    }

    .v-card__text {
        background-color: #3e3e3e;
        color: #e9e9e9;
    }

    .header-below {
        border-top: 1px solid #e9e9e9;
        border-bottom: 1px solid #e9e9e9;
    }
</style>