<template>
    <div>
        <validation-observer ref="obs">
            <v-row>
                <v-col cols="12"
                       class="py-0"
                >
                    <validation-provider v-slot="{errors}"
                                         name="userEmail"
                                         rules="required|email"
                    >
                        <v-text-field v-model="user.email"
                                      :disabled="hasUserId"
                                      label="E-mail address"
                                      :error-messages="errors"
                                      @keyup="validate"
                        />
                    </validation-provider>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12"
                       sm="12"
                       md="6"
                       class="py-0"
                >
                    <validation-provider v-slot="{errors}"
                                         name="userName"
                                         rules="required"
                    >
                        <v-text-field v-model="user.name"
                                      label="User name"
                                      :error-messages="errors"
                                      @keyup="validate"
                        />
                    </validation-provider>
                </v-col>
                <v-col cols="12"
                       md="6"
                       class="py-0"
                >
                    <v-text-field v-model="user.phoneNumber"
                                  label="Phone number"
                                  @input="validate"
                    />
                </v-col>
            </v-row>
        </validation-observer>
        <v-divider v-if="canSetUserRole || isProjectEditorAtAnyProductionCompany" />
        <v-row v-if="canSetUserRole || isProjectEditorAtAnyProductionCompany">
            <v-col cols="12"
                   md="6"
                   class="py-0"
            >
                <v-select v-model="userRole"
                          label="User role"
                          :items="availableRoles"
                          item-value="id"
                          item-text="name"
                          :disabled="isProjectEditorAtAnyProductionCompany"
                          @input="validate"
                />
            </v-col>
            <v-col v-if="showProductionCompany"
                   cols="12"
                   md="6"
                   class="py-0"
            >
                <v-select v-model="user.productionCompany"
                          label="Production company"
                          :items="productionCompanies"
                          item-value="slug"
                          item-text="name"
                          @input="validate"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import User from '@/models/User';
    import { mapGetters, mapState } from 'vuex';
    import { ValidationProvider, ValidationObserver, extend } from 'vee-validate';
    import { required, email } from 'vee-validate/dist/rules';

    extend('required', {
        ...required,
        message: 'This field is required'
    });

    extend('email', {
        ...email,
        message: 'This field must contain a valid e-mail address'
    });

    export default {
        name: 'UserDetails',
        components: {
            ValidationObserver,
            ValidationProvider
        },
        props: {
            value: {
                type: Object,
                default: () => new User([])
            },
            hideFreelancer: {
                type: Boolean,
                default: false
            }
        },
        watch: {
            user: {
                handler() {
                    this.$emit('input', new User(this.user));
                },
                deep: true
            }
        },
        computed: {
            ...mapState('productionCompanies', ['productionCompanies']),
            ...mapGetters('users', ['roles']),
            ...mapGetters('auth', ['isQuadiaLiveAdmin', 'isCompanyAdminOfUser', 'isCompanyAdminAtAnyProductionCompany', 'isProjectEditorAtAnyProductionCompany']),
            hasUserId() {
                return !!this.user.id;
            },
            canSetUserRole() {
                if(this.user.isMyself) {
                    return false;
                }
                if (this.isCompanyAdminAtAnyProductionCompany && !this.user.productionCompany) {
                    return true;
                }
                return this.isQuadiaLiveAdmin || this.isCompanyAdminOfUser(this.user);
            },
            availableRoles() {
                const roles = this.isQuadiaLiveAdmin
                    ? this.roles
                    : this.roles.filter(r => r.id !== 'quadia_live_admin');
                return roles.filter( r => !(this.hideFreelancer && r.id === 'freelancer'));
            },
            showProductionCompany() {
                return this.isQuadiaLiveAdmin && ['company_admin','project_editor'].indexOf(this.user.role) !== -1;
            },
            userRole: {
                get() {
                    return !this.user.role && this.user.freelancerProjects.length > 0 ? 'freelancer' : this.user.role;
                },
                set(value) {
                    this.user.role = value === 'freelancer' ? 'freelancer' : value;
                }
            }
        },
        data() {
            return {
                user: this.value,
            };
        },
        methods: {
            async validate() {
                const valid = await this.$refs.obs.validate();
                this.$emit('validate', valid);
            }
        },
    };
</script>

<style scoped>

</style>
