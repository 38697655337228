<template>
    <div>
        <h3 class="font-weight-bold pt-3">
            Web app settings
        </h3>
        <v-row no-gutters>
            <v-col cols="6">
                <pwa-style-settings />
            </v-col>
            <v-col cols="6"
                   class="pt-0 text-center"
            >
                <qrcode-vue :value="pwaLink"
                            :size="128"
                            class="px-8 pb-8"
                />
                <a :href="pwaLink"
                   target="_blank"
                   class="ml-2"
                >{{ pwaLink }}</a>
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import PwaStyleSettings from '@/components/pages/Project/ProjectOverviewElements/PwaStyleSettings';
    import QrcodeVue from 'qrcode.vue';
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'PwaSettings',
        components: {
            PwaStyleSettings,
            QrcodeVue
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['registrationType']),
            pwaLink() {
                if(this.registrationType === 'open'){
                    return process.env.VUE_APP_FRONTEND_ORIGIN + '/' + this.project.events[0]._id + '/gm84cf8a74?pwa=true';
                }
                return process.env.VUE_APP_FRONTEND_ORIGIN + '/' + this.project.events[0]._id + '?pwa=true';
            }
        }
    };
</script>
