<template>
    <v-col md="1">
        <div class="text-right">
            <v-tooltip v-if="isClientAtMostForProject(projectEvent)"
                       bottom
            >
                <template v-slot:activator="{on}">
                    <v-btn icon
                           color="black"
                           small
                           :to="{name: 'CreateStatistics', params: {'project_id': projectEvent._id}}"
                           v-on="on"
                    >
                        <v-icon>query_stats</v-icon>
                    </v-btn>
                </template>
                <span>Statistics</span>
            </v-tooltip>
            <v-tooltip v-if="canEditProject(projectEvent)"
                       bottom
            >
                <template v-slot:activator="{on}">
                    <v-btn icon
                           color="black"
                           small
                           :to="{name: 'CreateEvent',
                                 params:{ 'project_id': projectEvent._id, 'event_id': projectEvent.event._id}}"
                           class="mr-2"
                           v-on="on"
                    >
                        <v-icon>edit</v-icon>
                    </v-btn>
                </template>
                <span>Edit</span>
            </v-tooltip>
            <v-tooltip v-if="canEditProject(projectEvent)"
                       bottom
            >
                <template v-slot:activator="{on}">
                    <v-btn icon
                           color="black"
                           small
                           v-on="on"
                           @click="initArchive(projectEvent)"
                    >
                        <v-icon>mdi-archive</v-icon>
                    </v-btn>
                </template>
                <span>Archive</span>
            </v-tooltip>
        </div>
    </v-col>
</template>
<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'ListActions',
        props: {
            projectEvent: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                projectToArchive: {},
                archiveModal: false,
            };
        },
        computed: {
            ...mapGetters('auth', ['isClientAtMostForProject', 'canEditProject', 'isClientOnly']),
        },
        methods: {
            initArchive(project) {
                this.$emit('archive', project);
            },
        }

    };
</script>

<style scoped>

</style>