<template>
    <div class="preview">
        <v-overlay absolute
                   :value="!blocksLoadedMessage.initialSizesLoaded"
                   class="overlay-loader"
                   color="#bbbbbb"
        >
            <v-progress-circular indeterminate
                                 size="64"
            />
        </v-overlay>
        <iframe
            id="dynamic-blocks-preview-iframe"
            :src="frontendAppUrl"
            frameborder="0"
            class="dynamic-blocks-iframe"
            scrolling="no"
            :height="blocksLoadedMessage.scrollHeight"
        />
    </div>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: 'DynamicBlocksPagePreview',
        props: {
            dynamicBlockKind: {
                type: Object,
                required: true,
            }
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapState('dynamic-block', ['blocksLoadedMessage']),
            frontendAppUrl() {
                let url = new URL(
                    this.dynamicBlockKind.dynamicPreviewUrl.replace(
                        '{eventID}',
                        this.eventId
                    )
                );
                return decodeURIComponent(url.toString());
            },
            eventId() {
                return this.project.events[0]._id;
            },
        },
    };
</script>

<style scoped lang="scss">
.preview {
  position: relative;

  .dynamic-blocks-iframe {
    min-width: 100%;
  }
}

.overlay-loader {
    min-height: 200px;
    border-radius: 3px;
}
</style>