<template>
    <div>
        <image-picker v-model="slideshowImage"
                      :max-file-size="maxFileSize"
                      :rules="imageRules"
                      :upload-url="uploadUrl"
                      :show-image="false"
                      label="New slideshow image"
        >
            <template v-slot:hint> 
                <slideshow-hint />
            </template>
        </image-picker>
        <draggable
            v-model="slideshow"
            class="field-dragger"
            ghost-class="ghost"
            handle=".drag-handle"
            :options="{disabled : project.isArchived}"
        >
            <v-card v-for="image in slideshow"
                    :key="image.path"
                    class="slideshow-image-wrapper mb-2 drag-handle"
            >
                <img 
                    :src="image.url"
                    class="slideshow-image"
                >
                <v-btn class="delete-button"
                       fab
                       elevation="0"
                       color="#e3e3e3e3"
                       max-width="30"
                       max-height="30"
                       @click="removeSlideshowImage(image)"
                >
                    <v-icon small
                            color="error"
                    >
                        mdi-delete
                    </v-icon>
                </v-btn>
            </v-card>
        </draggable>
    </div>
</template>

<script>
    import { DYNAMIC_BLOCK_IMAGE_UPLOAD_URL } from '@/services/ApiService';
    import {
        DYNAMIC_BLOCK_IMAGE_MAX_FILE_SIZE,
        dynamicBlockImageValidationRules,
    } from '@/services/fileValidation';
    import SlideshowHint from '@/components/controls/hints/SlideshowHint';
    import ImagePicker from '@/components/controls/ImagePicker';
    import draggable from 'vuedraggable';
    import { mapState } from 'vuex';
    export default {
        name: 'SlideshowImagePicker',
        props:{
            value:{
                type: Array,
                required: true,
            },
        },
        components:{draggable, ImagePicker, SlideshowHint},
        data(){
            return {
                slideshowUploading: false,
                slideshow: [...this.value],
                slideshowImage: {},          
            };
        },
        computed:{
            ...mapState('projects',['project']),
            imageRules(){
                return dynamicBlockImageValidationRules;
            },
            maxFileSize(){
                return DYNAMIC_BLOCK_IMAGE_MAX_FILE_SIZE;
            },
            uploadUrl(){
                return DYNAMIC_BLOCK_IMAGE_UPLOAD_URL;
            }
        },
        methods:{
            emitSlideshow(value){
                this.$emit('input', value);
            },
            addSlideshowImage(image){
                const img = new Image();
                img.addEventListener('load', () => {
                    this.slideshow = [...this.slideshow, {'url': image.url, 'path': image.key, 'width':img.width, 'height':img.height}];
                });
                img.src = image.url;
               
            },
            removeSlideshowImage(image){
                this.slideshow = this.slideshow.filter( (e) => e.path !== image.path);
            },
        },
        watch: {
            slideshowImage(value){
                if(value){
                    this.addSlideshowImage(value);
                }
            },
            slideshow: {
                deep: true,
                handler: function(value) {
                    this.emitSlideshow(value);
                },
            }
        }
    };
</script>

<style lang="scss" scoped>
.slideshow-image-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    
    .slideshow-image{
        max-width: 100%;
    }
    .delete-button {
        position: absolute;
        top: 10px;
        right: 10px
    }
}
.drag-handle {
    cursor: grab;
}

</style>