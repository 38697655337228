<template>
    <logo-picker :upload-url="uploadUrl"
                 :logo-url="logoUrl"
                 :logo="logo"
                 @input="logoChanged"
    />
</template>
<script>
    import { mapActions, mapMutations, mapState } from 'vuex';
    import { COMPANY_LOGO_UPLOAD_URL } from '@/services/ApiService';
    import LogoPicker from '@/components/controls/LogoPicker.vue';

    export default {
        name: 'CompanyLogo',
        components: {LogoPicker},
        computed: {
            ...mapState('companies', ['company']),
            uploadUrl() {
                return COMPANY_LOGO_UPLOAD_URL;
            },
            logoUrl: {
                get() {
                    return this.company.logoUrl;
                },
                set(value) {
                    this.setLogoUrl(value);
                }
            },
            logo: {
                get() {
                    return this.company.logo;
                },
                set(value) {
                    this.setLogo(value);
                }
            }
        },
        methods: {
            ...mapActions('companies', ['saveCompany']),
            ...mapMutations('companies', ['setLogo', 'setLogoUrl']),
            logoChanged(data) {
                this.logo = data.logo;
                this.logoUrl = data.logoUrl;
                this.saveCompany({
                    key: 'logo',
                    value: data.logo,
                });
            }
        }
    };
</script>

<style scoped>

</style>