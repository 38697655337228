<template>
    <v-dialog v-if="value"
              :value="true"
              scrollable
              permanent
              max-width="700"
              @input="onDialogInput"
    >
        <v-card :loading="loading">
            <v-card-title>
                <slot name="title" />
                <v-spacer />
                <v-btn icon
                       @click="closeModal"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-subtitle v-if="showNameEditor"
                             class="pa-4"
            >
                <v-text-field :value="internalSelectedLayout"
                              label="Template name"
                              placeholder="Enter template name"
                              hide-details
                              outlined
                              dense
                              @input="selectTemplateFromText"
                />
                <p class="mt-2 mb-0 mx-4">
                    or select template below:
                </p>
            </v-card-subtitle>
            <v-card-text>
                <v-list v-if="!loading && hasTemplates"
                        dense
                >
                    <v-list-item-group :value="internalSelectedTemplate"
                                       color="primary"
                    >
                        <v-list-item v-for="template in templates"
                                     :key="template.layout"
                                     ripple
                                     :value="template"
                                     @click="selectTemplate(template)"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{ template.layout }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-item-group>
                </v-list>
                <p v-if="!hasTemplates && !loading"
                   class="mx-2"
                >
                    No templates available
                </p>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary"
                       :disabled="!internalSelectedTemplate"
                       @click="confirmModal"
                >
                    Select
                </v-btn>
                <v-btn color="default"
                       @click="closeModal"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { $http } from '@/services/http';
    import { mapState } from 'vuex';

    export default {
        name: 'TemplateDialog',
        props: {
            value: {
                type: Boolean,
                default: () => false,
            },
            selectedTemplate: {
                type: Object,
                default: () => null,
            },
            showNameEditor: {
                type: Boolean,
                default: () => false,
            },
            indexUrl: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                internalSelectedTemplate: null,
                templates: [],
                loading: false,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            hasTemplates() {
                return this.templates.length > 0;
            },
            internalSelectedLayout() {
                if (!this.internalSelectedTemplate) {
                    return '';
                }

                return this.internalSelectedTemplate.layout;
            }
        },
        methods: {
            closeModal() {
                this.$emit('input', false);
            },
            confirmModal() {
                this.$emit('confirm', {
                    selectedTemplate: this.internalSelectedTemplate,
                    existing: this.templates.filter(t => t.layout === this.internalSelectedTemplate?.layout).length > 0,
                });
                this.closeModal();
            },
            onDialogInput(value) {
                this.$emit('input', value);
            },
            async loadTemplates() {
                this.loading = true;
                this.templates = (await $http.get(this.indexUrl.replace('{company}', this.project.company._id))).data;
                this.loading = false;
            },
            selectTemplate(template) {
                this.internalSelectedTemplate = template;
            },
            selectTemplateFromText(value) {
                const selectedTemplate = this.templates.find(template => template.layout === value);
                this.internalSelectedTemplate = selectedTemplate ?? {layout: value, blocks : []};
            }
        },
        watch: {
            value(val) {
                if (val) {
                    this.internalSelectedTemplate = this.selectedTemplate;
                    this.loadTemplates();
                }
            }
        },
        mounted() {
            this.loadTemplates();
        }
    };
</script>

<style scoped>

</style>