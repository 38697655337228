<template>
    <div>
        <v-row class="tabs sticky-submenu">
            <v-col class="pt-0 col-height">
                <v-tabs v-model="tab"
                        class="mb-6 "
                >
                    <v-tab class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#settings"
                    >
                        Settings
                    </v-tab>
                    <v-tab class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#registrationConfirmation"
                    >
                        Confirmation
                    </v-tab>
                    <v-tab class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#reminder"
                    >
                        Reminder
                    </v-tab>
                    <v-tab class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#thankYou"
                    >
                        Thank You
                    </v-tab>
                    <v-tab v-if="showExtraMail"
                           class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#extra"
                    >
                        Extra Mail
                    </v-tab>
                    <v-tab v-if="showOnSiteViewerConfirmation"
                           class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#onSiteViewerRegistrationConfirmation"
                    >
                        On-site Viewer Confirmation
                    </v-tab>
                    <v-tab v-if="showOnSiteReminderMail"
                           class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#onSiteViewerReminder"
                    >
                        On-site Viewer Reminder
                    </v-tab>
                </v-tabs>
            </v-col>
            <v-col
                cols="12"
                lg="4"
                md="4"
                sm="4"
                class="pl-0 pt-0 col-height"
            >
                <v-row justify="end"
                       class="ml-0 pl-0 "
                >
                    <h2 class="font-weight-regular text-end pt-2 mr-3">
                        Preview
                    </h2>
                    <send-test-email class="mr-3 "
                                     :url="testUrl"
                    />
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                cols="12"
                lg="6"
                md="6"
                sm="6"
            >
                <v-tabs-items :value="tab">
                    <v-tab-item
                        :transition="false"
                        :reverse-transition="false"
                        value="settings"
                    >
                        <mailing-settings @refreshPreview="refreshPreview" />
                    </v-tab-item>
                    <v-tab-item :transition="false"
                                :reverse-transition="false"
                                value="registrationConfirmation"
                    >
                        <confirmation-mailing @refreshPreview="refreshPreview" />
                    </v-tab-item>
                    <v-tab-item :transition="false"
                                :reverse-transition="false"
                                value="reminder"
                    >
                        <reminder-mailing @refreshPreview="refreshPreview" />
                    </v-tab-item>
                    <v-tab-item :transition="false"
                                :reverse-transition="false"
                                value="thankYou"
                    >
                        <thank-you-mailing @refreshPreview="refreshPreview" />
                    </v-tab-item>
                    <v-tab-item v-if="showExtraMail"
                                :transition="false"
                                :reverse-transition="false"
                                value="extra"
                    >
                        <extra-mailing @refreshPreview="refreshPreview" />
                    </v-tab-item>
                    <v-tab-item v-if="isHybridProject"
                                :transition="false"
                                :reverse-transition="false"
                                value="onSiteViewerRegistrationConfirmation"
                    >
                        <on-site-confirmation-mailing @refreshPreview="refreshPreview" />
                    </v-tab-item>
                    <v-tab-item v-if="showOnSiteReminderMail"
                                :transition="false"
                                :reverse-transition="false"
                                value="onSiteViewerReminder"
                    >
                        <on-site-viewer-reminder-mailing @refreshPreview="refreshPreview" />
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
            <v-col
                cols="12"
                lg="6"
                md="6"
                sm="6"
            >
                <email-preview
                    :key="previewKey"
                    :template="template"
                />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import ConfirmationMailing from './MailingTabs/ConfirmationMailing';
    import ReminderMailing from './MailingTabs/ReminderMailing';
    import MailingSettings from '@/components/pages/Project/MailingTabs/MailingSettings';
    import ThankYouMailing from '@/components/pages/Project/MailingTabs/ThankYouMailing';
    import ExtraMailing from '@/components/pages/Project/MailingTabs/ExtraMailing';
    import OnSiteViewerReminderMailing from './MailingTabs/OnSiteViewerReminderMailing';
    import SendTestEmail from '@/components/controls/SendTestEmail';
    import EmailPreview from '@/components/controls/EmailPreview';

    import {
        populateUrl,
        EMAIL_TEST_REGISTRATION_CONFIRMATION,
        EMAIL_TEST_REMINDER, EMAIL_TEST_THANK_YOU,
        EMAIL_TEST_ON_SITE_VIEWER_REMINDER, EMAIL_TEST_EXTRA,
        EMAIL_TEST_ON_SITE_REGISTRATION_CONFIRMATION
    } from '@/services/ApiService';
    import { mapGetters, mapState } from 'vuex';
    import OnSiteConfirmationMailing from '@/components/pages/Project/MailingTabs/OnSiteConfirmationMailing';
    export default {
        name: 'ProjectMailings',
        components: {
            OnSiteConfirmationMailing,
            ThankYouMailing,
            MailingSettings,
            ConfirmationMailing,
            ReminderMailing,
            SendTestEmail,
            EmailPreview,
            ExtraMailing,
            OnSiteViewerReminderMailing
        },
        data() {
            return {
                tab: 0,
                obj: {
                    'settings': 'registration-confirmation',
                    'registrationConfirmation': 'registration-confirmation',
                    'reminder': 'reminder',
                    'thankYou': 'thank-you',
                    'extra': 'extra',
                    'onSiteViewerReminder': 'on-site-viewer-reminder',
                    'onSiteViewerRegistrationConfirmation': 'on-site-viewer-registration-confirmation',
                },
                previewKey: 0
            };
        },
        computed:{
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['isHybridProject', 'isSpringerProject']),
            template(){
                return this.obj[this.tab];
            },
            testUrl: function (){
                if(this.template === 'registration-confirmation'){
                    return populateUrl(EMAIL_TEST_REGISTRATION_CONFIRMATION, this.project._id);
                }else if(this.template === 'reminder'){
                    return populateUrl(EMAIL_TEST_REMINDER, this.project._id);
                }else if(this.template === 'thank-you'){
                    return populateUrl(EMAIL_TEST_THANK_YOU, this.project._id);
                }else if(this.template === 'on-site-viewer-reminder'){
                    return populateUrl(EMAIL_TEST_ON_SITE_VIEWER_REMINDER, this.project._id);
                }else if(this.template === 'extra'){
                    return populateUrl(EMAIL_TEST_EXTRA, this.project._id);
                }else if (this.template === 'on-site-viewer-registration-confirmation'){
                    return populateUrl(EMAIL_TEST_ON_SITE_REGISTRATION_CONFIRMATION, this.project._id);
                }
                return null;
            },
            showExtraMail: function (){
                return this.project.extraMailEnabled;
            },
            showOnSiteViewerConfirmation(){
                return this.isHybridProject;
            },
            showOnSiteReminderMail(){
                return this.project.type === 'hybrid';
            },
        },
        methods:{
            refreshPreview(){
                this.previewKey++;
            }
        },
        watch:{
            tab(){
                this.previewKey++;
            }
        }
    };
</script>

<style scoped>
.tabs {
    border-bottom: 4px solid;
    border-color: #f5f5f5;
    border-top: 20px solid white;
    background-color: white;

}
.tab-title {
    border-right: 2px solid;
    border-color: #f5f5f5;
}
.text-input {
    width: 22rem;
}

.col-height{
    height: 48px !important

}
</style>
