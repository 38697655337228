<template>
    <v-container fluid>
        <v-row class="page-header-container grey lighten-4">
            <v-col cols="12"
                   md="2"
                   lg="2"
            >
                <h1 class="px-8 font-weight-regular page-header">
                    Clients
                </h1>
            </v-col>
            <v-col cols="12"
                   md="2"
                   lg="2"
            >
                <v-text-field
                    v-model="searchText"
                    solo
                    flat
                    hide-details
                    dense
                    background-color="rgba(0,0,0,0.1)"
                    placeholder="Search clients"
                    clearable
                />
            </v-col>
            <v-col cols="12"
                   xl="8"
                   lg="8"
                   md="8"
                   class="d-flex"
            >
                <v-spacer />
                <v-btn v-if="canCreateClient"
                       color="primary"
                       class="mr-8"
                       @click="showCreateClientDialog"
                >
                    Add new Client
                </v-btn>
                <create-client-dialog
                    v-if="createClientDialogVisible"
                    @close="hideCreateClientDialog"
                />
            </v-col>
        </v-row>
        <v-progress-linear v-if="loading"
                           indeterminate
        />
        <div v-else>
            <client-list @update="getClients" />
            <v-container fluid>
                <v-row align="center"
                       align-content="center"
                       justify="center"
                >
                    <v-col class="d-flex"
                           cols="1"
                           sm="1"
                    >
                        <small
                            class="d-flex justify-right"
                            style="width: 100%; white-space: nowrap"
                        >
                            Rows per page
                        </small>
                    </v-col>
                    <v-col class="d-flex"
                           cols="1 sm=1"
                    >
                        <v-select
                            v-model="rowsPerPageSelected"
                            :items="rowsPerPageItems"
                            outlined
                            dense
                            hide-details
                        >
                            <template v-slot:selection="{ item }">
                                <span class="d-flex justify-end"
                                      style="width: 100%"
                                >{{
                                    item
                                }}</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col class="d-flex"
                           cols="6"
                           sm="3"
                    >
                        <v-pagination
                            v-if="pagination.total > 1"
                            v-model="currentPage"
                            :length="pagination.total"
                            circle
                            total-visible="6"
                            color="secondary"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </div>
    </v-container>
</template>

<script>
    import { debounce } from 'lodash';
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import ClientList from '@/components/pages/Clients/ClientList';
    import CreateClientDialog from '@/components/pages/Clients/CreateClientDialog';

    export default {
        name: 'Clients',
        components: { CreateClientDialog, ClientList },
        data() {
            return {
                rowsPerPageItems: [10, 25, 50],
                createClientDialogVisible: false,
            };
        },
        computed: {
            ...mapState('clients', ['loading', 'pagination']),
            ...mapGetters('auth', [
                'isQuadiaLiveAdmin',
                'isCompanyAdminAtAnyProductionCompany',
                'isProjectEditorAtAnyProductionCompany'
            ]),
            searchText: {
                get() {
                    return this.pagination.searchText;
                },
                set: debounce(function(value) {
                    this.setSearchText(value);
                    this.setCurrentPage(1);
                    this.getClients();
                }, 400)
            },
            currentPage: {
                get() {
                    return this.pagination.current;
                },
                set(value) {
                    this.setCurrentPage(value);
                    this.getClients();
                },
            },
            rowsPerPageSelected: {
                get() {
                    return this.pagination.pageSize;
                },
                set(value) {
                    this.setCurrentPage(1);
                    this.setPageSize(value);
                    this.getClients();
                },
            },
            canCreateClient() {
                return this.isQuadiaLiveAdmin ||
                    this.isCompanyAdminAtAnyProductionCompany ||
                    this.isProjectEditorAtAnyProductionCompany;
            }
        },
        methods: {
            ...mapMutations('clients', ['setCurrentPage', 'setPageSize', 'setSearchText']),
            ...mapActions('clients', ['getClients']),
            showCreateClientDialog() {
                this.createClientDialogVisible = true;
            },
            hideCreateClientDialog() {
                this.createClientDialogVisible = false;
            },
        },
        mounted() {
            if (!this.isQuadiaLiveAdmin &&
                !this.isCompanyAdminAtAnyProductionCompany &&
                !this.isProjectEditorAtAnyProductionCompany) {
                this.$router.push({name: 'Home'});
            }
            this.setCurrentPage(1);
            this.getClients();
        },
    };
</script>
