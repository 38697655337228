import { filterText } from '@/services/strUtils';

export const FIELD_TYPE_EMAIL = 'email';
export const FIELD_TYPE_TEXT = 'text';
export const FIELD_TYPE_TEXTAREA = 'textarea';
export const FIELD_TYPE_CHECKBOX = 'checkbox';
export const FIELD_TYPE_RADIO = 'radio';
export const FIELD_TYPE_DROPDOWN = 'dropdown';
export const FIELD_TYPE_MOBILE = 'mobile';
export const FIELD_TYPE_ON_SITE_VIEWER = 'on-site-viewer';
export const FIELD_TYPE_DATE_PICKER = 'date-picker';

export const FIELD_VALIDATION_OPTION_NONE = 'no-options';
export const FIELD_VALIDATION_OPTION_DUPLICATE = 'duplicate-options';
export const FIELD_VALIDATION_NO_LABEL = 'no-field-label';
export const FIELD_VALIDATION_NO_QUESTION = 'no-field-question';

export const FIELD_TYPES = [
    {
        label: 'E-mail field',
        value: FIELD_TYPE_EMAIL
    },
    {
        label: 'Text field',
        value: FIELD_TYPE_TEXT
    },
    {
        label: 'Multi-line text',
        value: FIELD_TYPE_TEXTAREA
    },
    {
        label: 'Check boxes',
        value: FIELD_TYPE_CHECKBOX
    },
    {
        label: 'Radio buttons',
        value: FIELD_TYPE_RADIO
    },
    {
        label: 'Dropdown',
        value: FIELD_TYPE_DROPDOWN
    },
    {
        label: 'Mobile number',
        value: FIELD_TYPE_MOBILE
    },
    {
        label: 'Location selector',
        value: FIELD_TYPE_ON_SITE_VIEWER
    },
    {
        label: 'Date picker',
        value: FIELD_TYPE_DATE_PICKER
    },
];

export const getFieldsForCompanyAndTitle = () => {
    return FIELD_TYPES.filter((item) => {
        if(item.value === FIELD_TYPE_DROPDOWN || item.value === FIELD_TYPE_RADIO || item.value === FIELD_TYPE_TEXT){
            return item;
        }
    });
};

export const getFieldsForFirstName = () => {
    return FIELD_TYPES.filter((item) => {
        if(item.value === FIELD_TYPE_TEXT){
            return item;
        }
    });
};


export const fieldHasOptions = function (field) {
    return ([
        FIELD_TYPE_RADIO,
        FIELD_TYPE_CHECKBOX,
        FIELD_TYPE_DROPDOWN
    ].indexOf(field.type) > -1);
};

export const validateOptions = function (options) {
    if (!options || options.length < 1) {
        return {
            valid: false,
            reason: FIELD_VALIDATION_OPTION_NONE,
        };
    }

    let duplicateFound = false;
    options.forEach(m => {
        const ct = options.filter(e => e.text.toLowerCase() === m.text.toLowerCase()).length;
        if (ct !== 1) {
            duplicateFound = true;
        }
    });

    if (duplicateFound) {
        return {
            valid: false,
            reason: FIELD_VALIDATION_OPTION_DUPLICATE
        };
    }

    return {
        valid: true,
        reason: ''
    };
};

export const getNonDuplicateText = function (options, text) {
    const filteredText = filterText(text);
    let newText = filteredText;

    let found = true;
    let i = 0;

    while (found) {
        const item = options.find(e => filterText(e.text.toLowerCase()) === newText.toLowerCase());
        if (typeof item === 'undefined') {
            break;
        }
        i++;
        if (i > 0) {
            newText = `${filteredText} (${i})`;
        }
    }

    return newText;
};

export const validateFields = function (fields) {
    const validResult = {
        valid: true,
        reason: '',
    };

    for (let i in fields) {
        if (!fields[i].label) {
            validResult.valid = false;
            validResult.reason = FIELD_VALIDATION_NO_LABEL;
            validResult.field = fields[i];
            break;
        }

        if (fieldHasOptions(fields[i])) {
            const vr = validateOptions(fields[i].options);
            if (!vr.valid) {
                validResult.valid = false;
                validResult.reason = vr.reason;
                validResult.field = fields[i];
                break;
            }
        }
    }

    return validResult;
};

const mapFieldOptions = function (fieldOptions) {
    return fieldOptions.map(o => {
        return {
            id: o.id,
            text: filterText(o.text)
        };
    });
};

export const mapToRegistrationFields = function (fields) {
    return fields.map(f => {
        const fieldDto = {
            type: f.type,
            label:filterText(f.label),
            name: f.name,
            required: f.required
        };

        if (fieldHasOptions(f)) {
            fieldDto.options = mapFieldOptions(f.options);
        }

        return fieldDto;
    });
};
