<template>
    <v-row>
        <v-col>
            <h3 class="mb-5 mt-n4 font-weight-bold">
                Project settings
            </h3>
            <v-row>
                <v-col cols="12"
                       xl="6"
                       lg="6"
                       md="6"
                       sm="6"
                       class="input-col-left"
                >
                    <company-autocomplete class="mb-9" />

                    <registration-type-field v-if="!isExternalEmbed" />

                    <v-alert v-if="!mailingsEnabled"
                             color="warning"
                             outlined
                             dense
                    >
                        <v-icon color="warning"
                                x-small
                        >
                            warning
                        </v-icon>
                        No mails will be sent
                    </v-alert>

                    <v-checkbox v-if="showRegistrationStatisticsCheckbox"
                                v-model="enableRegistrationStatistics"
                                label="Enable registration reports "
                    />
                </v-col>
                <v-col cols="12"
                       xl="6"
                       lg="6"
                       md="6"
                       sm="6"
                       class="input-col-right"
                >
                    <v-text-field
                        v-model="projectName"
                        filled
                        label="Internal project name"
                        :disabled="project.isArchived"
                        :rules="requiredField"
                    />
                    <v-text-field
                        v-if="isHybridProject"
                        v-model="expectedOnSiteViewers"
                        filled
                        class="text-input mt-4"
                        label="Maximum on-site viewers"
                        type="number"
                        :disabled="project.isArchived"
                    />
                    <div class="d-flex">
                        <project-type-selector :value="project.type"
                                               disabled
                                               class="pr-5 disabled-field"
                        />
                        <v-select v-if="isExternalEmbed"
                                  v-model="liveDirectorTemplate"
                                  :items="liveDirectorTemplates"
                                  class="disabled-field"
                                  item-text="label"
                                  item-value="value"
                                  label="Live Director Template"
                                  hide-details
                                  :disabled="true"
                        />
                        <v-select v-if="!isExternalEmbed"
                                  v-model="language"
                                  :items="availableLanguages"
                                  label="Language"
                                  item-text="label"
                                  item-value="value"
                                  hide-details
                                  disabled
                                  class="disabled-field"
                        />
                    </div>
                </v-col>
            </v-row>
            <v-row>
                <v-col v-if="!isExternalEmbed"
                       cols="12"
                       xl="6"
                       lg="6"
                       md="6"
                       sm="6"
                       class="input-col-left pt-0"
                >
                    <public-link />
                </v-col>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
    import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
    import CompanyAutocomplete from './CompanyAutocomplete';
    import { REGISTRATION_TYPE_GENERATED } from '@/services/RegistrationService';
    import ProjectTypeSelector from '@/components/controls/ProjectTypeSelector';
    import RegistrationTypeField from'./RegistrationTypeField';
    import PublicLink from'./PublicLink';

    export default {
        name: 'ProjectSettings',
        components: {
            ProjectTypeSelector,
            CompanyAutocomplete,
            RegistrationTypeField,
            PublicLink,
        },
        data() {
            return {
                availableLanguages: [
                    {'label': 'Dutch (formal)', 'value': 'nlFormal'},
                    {'label': 'Dutch (informal)', 'value': 'nlInformal'},
                    {'label': 'English', 'value': 'en'},
                    {'label': 'German', 'value': 'de'},
                ],
                liveDirectorTemplates: [
                    {'label': 'Medtalks', value: 'medtalks'},
                    {'label': 'ASML', value: 'asml'},
                ],
                hoverCopy: false,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['registrationType', 'isExternalEmbed', 'isHybridProject', 'mailingsEnabled']),
            requiredField() {
                return [(v) => !!v || 'Field is required'];
            },
            language: {
                get() {
                    return this.project.language;
                },
                set(value) {
                    this.setLanguage(value);
                    this.saveProject({
                        key: 'language',
                        value,
                    });
                },
            },
            liveDirectorTemplate: {
                get() {
                    return this.project.template;
                },
            },
            projectName: {
                get() {
                    return this.project.name;
                },
                set(value) {
                    this.setProjectName(value);
                    this.saveProject({
                        key: 'name',
                        value,
                    });
                },
            },
            expectedOnSiteViewers: {
                get() {
                    return this.project.expectedOnSiteViewers;
                },
                set(value) {
                    this.setExpectedOnSiteViewers(value);
                    this.saveProject({
                        key: 'expectedOnSiteViewers',
                        value,
                    });
                },
            },
            enableRegistrationStatistics: {
                get () {
                    return this.project.enableGeneratedRegistrationStatistics;
                },
                set (value) {
                    this.setEnableGeneratedRegistrationStatistics(value);
                    this.saveProject({
                        key: 'enableGeneratedRegistrationStatistics',
                        value
                    });
                }
            },
            showRegistrationStatisticsCheckbox() {
                return this.registrationType === REGISTRATION_TYPE_GENERATED;
            }
        },
        methods: {
            ...mapMutations('projects', [
                'setLanguage',
                'setProjectName',
                'setEnableGeneratedRegistrationStatistics',
                'setExpectedOnSiteViewers',
            ]),
            ...mapActions('projects', ['saveProject',]),
        },
    };
</script>

<style lang="scss">
.input-col-left {
    padding-right: 2vw !important;
}

.input-col-right {
    padding-left: 2vw !important;
}

.disabled-field {
    .v-input__append-inner {
        display: none !important;
    }

    .v-input__slot:before {
        display: none !important;
    }
}
</style>
