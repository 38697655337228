import BaseAggregator from '@/services/BaseAggregator';

class UserAgentBrowserAggregator extends BaseAggregator {
    getResults(data) {
        const total = data.length;
        const result = {};
        for (let item in data) {
            const deviceName = this.getBrowserName(data[item].agent?.browser?.name);
            if (!result[deviceName]) {
                result[deviceName] = 0;
            }
            result[deviceName] += 1;
        }

        return this.withPercentages(result, total);
    }

    getBrowserName(browserName) {
        return browserName ?? 'Other';
    }
}

export default UserAgentBrowserAggregator;