<template>
    <div>
        <div class="font-weight-bold mb-4 mt-2">
            Moderator
        </div>

        <div class="moderator-editor">
            <v-text-field v-model="internalValue"
                          outlined
                          dense
                          color="primary"
                          label="Moderator"
                          :rules="[rules.email]"
                          class="mr-2"
                          @keypress="edit"
            />
            <v-btn outlined
                   color="primary"
                   :disabled="!isEditing || !isValid"
                   @click="save"
            >
                Save moderator
            </v-btn>
            <v-tooltip bottom>
                <template v-if="!!value"
                          v-slot:activator="{on}"
                >
                    <v-btn
                        icon
                        color="error"
                        @click="clear"
                        v-on="on"
                    >
                        <v-icon>delete</v-icon>
                    </v-btn>
                </template>
                <span>Remove moderator</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
    import { email } from 'vee-validate/dist/rules';

    export default {
        name: 'ModeratorEditor',
        data() {
            return {
                isEditing: false,
                internalValue: this.value,
                rules: {
                    email: this.validateEmailAddress,
                },
                isValid: true,
            };
        },
        props: {
            value: {
                type: String,
                default: () => ''
            }
        },
        methods: {
            validateEmailAddress(value) {
                this.isValid = email.validate(value);
                return this.isValid || 'Please enter a valid e-mail address';
            },
            edit() {
                this.isEditing = true;
            },
            save() {
                this.isEditing = false;
                this.$emit('input', this.internalValue);
            },
            clear() {
                this.internalValue = '';
                this.save();
            },
        },
        watch: {
            internalValue(value) {
                this.isEditing = value.length > 0 && value !== this.value;
            }
        }
    };
</script>

<style scoped>
.moderator-editor {
    display: flex;
}
</style>