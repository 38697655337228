<template>
    <v-row>
        <v-col class="py-0">
            <v-text-field
                v-model="eventTitle"
                filled
                class="text-input"
                label="Event title"
                :disabled="project.isArchived"
                :rules="requiredField"
            />
        </v-col>
    </v-row>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'EventTitle',
        computed: {
            ...mapState('projects', ['project']),
            requiredField(){
                return [(v) => !!v || 'Field is required'];
            },
            eventTitle: {
                get() {
                    return this.project.eventTitle;
                },
                set(value) {
                    this.setProjectEventTitle(value);
                    this.saveProject({
                        id: this.project._id,
                        key: 'eventTitle',
                        value,
                    });
                },
            },
        },
        methods: {
            ...mapMutations('projects', ['setProjectEventTitle']),
            ...mapActions('projects', ['saveProject']),
        }
    };
</script>

<style scoped>
.text-input {
    width: 22rem;
}
</style>
