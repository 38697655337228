import {v4 as uuidv4} from 'uuid';
import FieldOption from '@/models/FieldOption';

class Field {
    constructor(data) {
        this.id = data.id || uuidv4();
        this.name = data.name || this.id;
        this.type = data.type;
        this.label = data.label;
        this.required = data.required;

        if (data.options) {
            this.options = data.options.map(o => new FieldOption(o));
        }
    }
}

export default Field;