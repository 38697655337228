<template>
    <div v-if="user">
        <a v-if="showLink"
           :href="userUrl"
           target="_blank"
        >{{ user.name }}<br> {{ user.email }}</a>
        <span v-else>
            {{ user.name }}<br> {{ user.email }}
        </span>
    </div>
    <div v-else>
        User not found
    </div>
</template>
<script>
    export default {
        name: 'ChangingUser',
        props: {
            user: {
                type: Object,
                default: null
            },
            showLink: {
                type: Boolean,
                default: false
            }
        },
        computed:{
            userUrl() {
                return this.$router.resolve({
                    name: 'User',
                    params: {
                        'user_id': this.user.id
                    }
                }).href;
            }
        }
    };
</script>

<style scoped>

</style>