<template>
    <div>
        <v-select
            v-model="registrationType"
            :items="registrationTypeOptions"
            item-text="label"
            item-value="value"
            label="Type of registration"
            :disabled="project.isArchived"
        />
        <v-text-field
            v-if="registrationType === registrationTypeGenerated"
            v-model="registrationExpectedViewers"
            min="1"
            max="10000"
            type="number"
            class="number-input"
            :rules="numberRules"
            :disabled="project.isArchived"
            label="Expected viewers (max 10.000)"
        >
            <template v-slot:append-outer>
                <v-tooltip top>
                    <template v-slot:activator="{ on }">
                        <v-btn icon
                               v-on="on"
                        >
                            <v-icon>info</v-icon>
                        </v-btn>
                    </template>

                    <span>
                        This will generate a list with unique links. Fill in the expected
                        amount of viewers.<br>
                        If you need more links after downloading the links, you should fill
                        in a higher amount of expected viewers than you did before.<br>
                        This will generate a list with all the previous generated links +
                        the new links.
                    </span>
                </v-tooltip>
                <v-btn
                    v-if="!loading"
                    text
                    color="primary"
                    title="Download List"
                    :disabled="project.isArchived"
                    @click="downloadRegistrationsList"
                >
                    <v-icon dark
                            left
                    >
                        download
                    </v-icon>
                    Download List
                </v-btn>
                <v-progress-circular v-if="loading"
                                     indeterminate
                                     color="primary"
                />
            </template>
        </v-text-field>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';
    import {
        REGISTRATION_TYPES,
        REGISTRATION_TYPE_GENERATED,
    } from '@/services/RegistrationService';
    import { $http } from '@/services/http';
    import {
        GENERATE_REGISTRATION_LINKS_URL,
        populateUrl,
    } from '@/services/ApiService';
    import { defaultMailingsEnabled } from '@/services/MailingService';

    export default {
        name: 'RegistrationTypeField',
        data() {
            return {
                loading: false,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            numberRules() {
                const pattern = /^[0-9]{1,3}([,.]{0,1}[0-9]{3})?$/;
                return [
                    (v) => {
                        if (!pattern.test(v)) {
                            return 'Not a valid integer';
                        }
                        let number = v;
                        number = number.toString().replace('.', '');
                        number = number.replace(',', '');
                        if (number > 10000) {
                            return 'Maximum value is 10000';
                        } else if (number < 1) {
                            return 'Minimum value is 1';
                        }
                        return true;
                    },
                ];
            },
            registrationType: {
                get() {
                    return this.project.registration.type;
                },
                async set(value) {
                    this.setRegistrationType(value);
                    this.setRequireRegistrationForOnDemand(false);
                    const enableMailings = defaultMailingsEnabled(value, this.project.companyId);
                    this.setEnableMailings(enableMailings);
                    this.startLoadingProject();
                    await this.saveProjectImmediate({
                        key: 'registration.type',
                        value,
                    });
                    await this.saveProjectImmediate({
                        key: 'emailSettings.enableMailings',
                        value: enableMailings,
                    });
                    await this.saveProjectImmediate({
                        key: 'requireRegistrationForOnDemand',
                        value: false,
                    });
                    await this.reloadProject();
                    this.reRender();
                },
            },
            registrationExpectedViewers: {
                get() {
                    return this.project.registration.expectedViewers;
                },
                set(value) {
                    this.setRegistrationExpectedViewers(value);
                },
            },
            registrationTypeOptions() {
                return REGISTRATION_TYPES;
            },
            registrationTypeGenerated() {
                return REGISTRATION_TYPE_GENERATED;
            },
        },
        methods: {
            ...mapMutations('projects', [
                'setRegistrationType',
                'setRegistrationExpectedViewers',
                'startLoadingProject',
                'reRender',
                'setEnableGeneratedRegistrationStatistics',
                'setRequireRegistrationForOnDemand',
                'setEnableMailings',
            ]),
            ...mapActions('projects', [
                'saveProject',
                'saveProjectImmediate',
                'reloadProject',
            ]),
            downloadRegistrationsList() {
                this.loading = true;
                let number = this.registrationExpectedViewers.toString().replace('.', '');
                number = number.replace(',', '');
                const url =
                    populateUrl(GENERATE_REGISTRATION_LINKS_URL, this.project._id) +
                    '?count=' +
                    number;
                $http
                    .get(url, {})
                    .then((response) => {
                        let fileURL = window.URL.createObjectURL(new Blob([response.data]));
                        let fileLink = document.createElement('a');
                        fileLink.href = fileURL;
                        fileLink.setAttribute('download', 'registrationIds.csv');
                        document.body.appendChild(fileLink);
                        fileLink.click();
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
        },
    };
</script>

<style lang="scss">
.input-col-left {
  padding-right: 2vw !important;
}

.number-input {
  width: 35.2rem;
}
</style>
