<template>
    <v-tabs v-model="tab"
            vertical
            show-arrows
            background-color="#f0f0f0"
    >
        <v-tab small
               class="text-capitalize text-left"
        >
            Project details
        </v-tab>
        <v-tab small
               class="text-capitalize text-left"
        >
            Event details
        </v-tab>
        <v-tab small
               class="text-capitalize text-left"
        >
            Registration page
        </v-tab>
        <v-tab small
               class="text-capitalize text-left"
        >
            Registration e-mail
        </v-tab>
        <v-tab small
               class="text-capitalize text-left"
        >
            Landing page
        </v-tab>
        <v-tab small
               class="text-capitalize text-left"
        >
            Reminder e-mails
        </v-tab>
        <v-tab small
               class="text-capitalize text-left"
        >
            Live page
        </v-tab>
        <v-tab small
               class="text-capitalize text-left"
        >
            End page
        </v-tab>
        <v-tab small
               class="text-capitalize text-left"
        >
            Post event e-mail
        </v-tab>
        <v-tab small
               class="text-capitalize text-left"
        >
            Evaluation page
        </v-tab>
        <v-tab-item class="pa-4">
            <project-details
                :value="{ client: intake.client, style: intake.style }"
            />
        </v-tab-item>
        <v-tab-item class="pa-4">
            <event-details :value="{ project: intake.project }" />
        </v-tab-item>
        <v-tab-item class="pa-4">
            <registration-page-details
                :value="{ registration: intake.registration }"
            />
        </v-tab-item>
        <v-tab-item class="pa-4">
            <registration-email-details
                :value="{ registerMail: intake.registerMail }"
            />
        </v-tab-item>
        <v-tab-item class="pa-4">
            <landing-page-details :value="{ splash: intake.splash }" />
        </v-tab-item>
        <v-tab-item class="pa-4">
            <reminder-email-details
                :value="{ reminderMail: intake.reminderMail }"
            />
        </v-tab-item>
        <v-tab-item class="pa-4">
            <live-page-details :value="{ live: intake.live }" />
        </v-tab-item>
        <v-tab-item class="pa-4">
            <end-page-details :value="{ end: intake.end }" />
        </v-tab-item>
        <v-tab-item class="pa-4">
            <post-event-mail-details :value="{ postEventMail: intake.postEventMail }" />
        </v-tab-item>
        <v-tab-item class="pa-4">
            <evaluation-page-details
                :value="{ evaluation: intake.evaluation }"
            />
        </v-tab-item>
    </v-tabs>
</template>

<script>
    import EvaluationPageDetails from '@/components/pages/Intake/Elements/EvaluationPageDetails';
    import EndPageDetails from '@/components/pages/Intake/Elements/EndPageDetails';
    import LivePageDetails from '@/components/pages/Intake/Elements/LivePageDetails';
    import ReminderEmailDetails from '@/components/pages/Intake/Elements/ReminderEmailDetails';
    import LandingPageDetails from '@/components/pages/Intake/Elements/LandingPageDetails';
    import RegistrationEmailDetails from '@/components/pages/Intake/Elements/RegistrationEmailDetails';
    import RegistrationPageDetails from '@/components/pages/Intake/Elements/RegistrationPageDetails';
    import EventDetails from '@/components/pages/Intake/Elements/EventDetails';
    import ProjectDetails from '@/components/pages/Intake/Elements/ProjectDetails';
    import PostEventMailDetails from '@/components/pages/Intake/Elements/PostEventMailDetails.vue';

    export default {
        name: 'IntakeTabs',
        components: {
            ProjectDetails,
            EventDetails,
            RegistrationPageDetails,
            RegistrationEmailDetails,
            LandingPageDetails,
            ReminderEmailDetails,
            LivePageDetails,
            EndPageDetails,
            PostEventMailDetails,
            EvaluationPageDetails,
        },
        props: {
            value: {
                type: Object,
                default: () => {},
            },
        },
        data() {
            return {
                tab: null,
                intake: JSON.parse(JSON.stringify(this.value)),
            };
        },
    };
</script>

<style scoped>
.v-tab.text-left {
    justify-content: start;
}
</style>