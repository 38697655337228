<template>
    <div>
        <v-row>
            <v-col cols="12">
                <h2 class="font-weight-regular black--text mb-2">
                    Evaluation page details
                </h2>
                <v-divider class="mb-2" />
                <h3 class="font-weight-regular black--text">
                    Fields
                </h3>
                <div v-for="(field, index) in value.evaluation.fields"
                     :key="index"
                     class="py-2"
                >
                    <v-divider class="mb-2" />
                    <div>
                        Name: <span class="black--text">{{ field.label }}</span>
                    </div>
                    <div>
                        Type: <span class="black--text">{{ field.type }}</span>
                    </div>
                    <div>
                        Required: <span class="black--text">{{ field.type ? 'Yes' : 'No' }}</span>
                    </div>
                    <div v-if="field.options"
                         class="d-flex"
                    >
                        Options: <span class="black--text">
                            <simple-options-display :options="field.options" />
                        </span>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import SimpleOptionsDisplay from '@/components/controls/SimpleOptionsDisplay';
    export default {
        name: 'EvaluationPageDetails',
        components: {SimpleOptionsDisplay},
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        evaluation: {
                            title: '',
                            bodyText: '',
                            fields: [],
                        },
                    };
                }
            }
        },
    };
</script>

<style scoped>

</style>