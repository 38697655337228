import { filterInvalidNodes } from '@/services/EditorPasteFilter';

const EDITOR_INIT_CONTENT_STYLE = `.mceNonEditable {background-color: #d2d9dc; border-radius: 10px; padding: 3px}
a {color:blue !important;}
body{font-family: *fontStyle*}
`;

export const EDITOR_DEFAULT_FONT_FAMILY = 'Arial, helvetica, sans-serif';

export const EDITOR_INIT_TOOLBAR_WITH_FONT_SELECTOR = 'fontsizeselect | fontselect | styleselect | customBoldBtn italic | \
                        bullist numlist | \
                        alignleft aligncenter alignright alignjustify | \
                        blockquote link | removeformat | defaultTexts';

export const getEditorConfig = function (setup) {
    return {
        style_formats: [
            {title: 'Paragraph', block: 'p'},
            {title: 'Heading 1', block: 'h1'},
            {title: 'Heading 2', block: 'h2'},
            {title: 'Heading 3', block: 'h3'},
            {title: 'Heading 4', block: 'h4'},
            {title: 'Heading 5', block: 'h5'},
            {title: 'Heading 6', block: 'h6'},
            {title: 'Preformatted', block: 'pre'},
        ],
        merge_styles: true,
        skin_url: '/assets/tinymce/skins/ui/oxide',
        content_css: [
            '/assets/tinymce/skins/ui/oxide/content.min.css',
            'https://fonts.googleapis.com/css?family=Oswald|Open+Sans|Roboto|Lato|Montserrat|Source+Sans+Pro|Slabo+27px|Raleway|PT+Sans|Merriweather',
            'https://d3p1ldfz9nbsuw.cloudfront.net/css2FamilyArchivoWght400500600DisplaySwap.css'
        ],
        branding: false,
        height: 400,
        menubar: false,
        paste_data_images: false,
        smart_paste: false,
        toolbar_mode: 'sliding',
        setup,
        paste_postprocess: (plugin, args) => {
            filterInvalidNodes(args.node);
        },
        plugins: [
            'autolink lists link anchor',
            'searchreplace visualblocks',
            'paste wordcount',
            'noneditable',
        ],
        content_style: '.mceNonEditable {background-color: #d2d9dc; border-radius: 10px; padding: 3px}' + ' a {color:blue !important;}'
            + 'body{font-family: Archivo, helvetica, sans-serif;}',
        font_formats: 'Archivo=Archivo,arial,helvetica,sans-serif; Open Sans=Open Sans, arial, helvetica, sans-serif; Roboto=Roboto, arial, helvetica, sans-serif; Lato=Lato, arial, helvetica, sans-serif; Montserrat=Montserrat, arial, helvetica, sans-serif; Oswald=Oswald, arial, helvetica, sans-serif; Source Sans Pro=Source Sans Pro, arial, helvetica, sans-serif; Slabo=Slabo 27px, arial, helvetica, sans-serif; Raleway=Raleway, arial, helvetica, sans-serif; PT Sans=PT Sans, arial, helvetica, sans-serif; Merriweather=Merriweather, arial, helvetica, sans-serif',
        noneditable_noneditable_class: 'mceNonEditable',
        fontsize_formats: '8pt 9pt 10pt 11pt 12pt 14pt 16pt 18pt 20pt 24pt 28pt 30pt 36pt 48pt 60pt 72pt 96pt',
        toolbar:
            'fontsizeselect | styleselect | customBoldBtn italic | \
            bullist numlist | \
            alignleft aligncenter alignright alignjustify | \
            blockquote link | removeformat | defaultTexts',

        link_list: [],
    };
};

export const getEditorInitFontStyle = function (fontStyle) {
    return EDITOR_INIT_CONTENT_STYLE.replace('*fontStyle*', fontStyle);
};

