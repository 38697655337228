<template>
    <v-progress-linear v-if="loading"
                       indeterminate
    />
    <div v-else
         class="link-tracking"
    >
        <link-tracking-export v-if="clickCounts.length" />
        <div v-if="!clickCounts.length">
            There is no data on link tracking yet.
        </div>
        <v-row>
            <v-col v-if="registrationClickCounts.length"
                   cols="12"
                   lg="6"
            >
                <link-tracking-page-list :items="registrationClickCounts">
                    <template v-slot:title>
                        Registration page
                    </template>
                </link-tracking-page-list>
            </v-col>
            <v-col v-if="waitingPageClickCounts.length"
                   cols="12"
                   lg="6"
            >
                <link-tracking-page-list :items="waitingPageClickCounts">
                    <template v-slot:title>
                        Waiting page
                    </template>
                </link-tracking-page-list>
            </v-col>
            <v-col v-if="livePageClickCounts.length"
                   cols="12"
                   lg="6"
            >
                <link-tracking-page-list :items="livePageClickCounts">
                    <template v-slot:title>
                        Live page
                    </template>
                </link-tracking-page-list>
            </v-col>
            <v-col v-if="endPageClickCounts.length"
                   cols="12"
                   lg="6"
            >
                <link-tracking-page-list :items="endPageClickCounts">
                    <template v-slot:title>
                        End page
                    </template>
                </link-tracking-page-list>
            </v-col>
            <v-col v-if="onDemandClickCounts.length"
                   cols="12"
                   lg="6"
            >
                <link-tracking-page-list :items="onDemandClickCounts">
                    <template v-slot:title>
                        On-demand page
                    </template>
                </link-tracking-page-list>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import { $http } from '@/services/http';
    import LinkTrackingPageList from '@/components/pages/Project/StatisticsTabs/LinkTracking/LinkTrackingPageList.vue';
    import LinkTrackingExport from '@/components/pages/Project/StatisticsTabs/LinkTracking/LinkTrackingExport.vue';

    export default {
        name: 'LinkTracking',
        components: {LinkTrackingExport, LinkTrackingPageList},
        props: {
            selectedEvent: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                stats: [],
                loading: true,
            };
        },
        computed: {
            ...mapGetters('projects', ['isHybridProject', 'isMultiSession']),
            clickCounts() {
                return [...this.stats.data ?? []].sort((a, b) => {
                    return a.app.localeCompare(b.app) || a.page.localeCompare(b.page) || (a.count - b.count) * -1 || a.action.localeCompare(b.action);
                }).filter(i => i.eventId === this.selectedEvent._id);
            },
            registrationClickCounts() {
                return this.clickCounts.filter(c => c.page === 'registration');
            },
            waitingPageClickCounts() {
                return this.clickCounts.filter(c => c.page === 'waiting');
            },
            livePageClickCounts() {
                return this.clickCounts.filter(c => c.page === 'live');
            },
            endPageClickCounts() {
                return this.clickCounts.filter(c => c.page === 'end');
            },
            onDemandClickCounts() {
                return this.clickCounts.filter(c => c.page === 'on-demand');
            }
        },
        methods: {
            async getData() {
                this.loading = true;
                try {
                    this.stats = await $http.get(process.env.VUE_APP_LINK_CLICKS_ENDPOINT.replace('{eventId}', this.selectedEvent._id));
                } finally {
                    this.loading = false;
                }
            },
        },
        mounted() {
            this.getData();
        }
    };
</script>

<style scoped>

</style>