export const CHART_JS_PLUGIN_GRACE_CUSTOM_SCALE = {
    id: 'customScale',
    beforeLayout: (chart, options) => {
        let max = Number.MIN_VALUE;
        let min = Number.MAX_VALUE;
        let grace = options.grace || 0;

        chart.data.datasets.forEach((dataset) => {
            max = Math.max(max, Math.max(...dataset.data));
            min = Math.min(min, Math.min(...dataset.data));
        });

        if (typeof grace === 'string' && grace.includes('%')) {
            grace = Number(grace.replace('%', '')) / 100;

            chart.options.scales.yAxes[0].ticks.suggestedMax = max + (max * grace);
            chart.options.scales.yAxes[0].ticks.suggestedMin = min - (min * grace);

        } else if (typeof grace === 'number') {
            chart.options.scales.yAxes[0].ticks.suggestedMax = max + grace;
            chart.options.scales.yAxes[0].ticks.suggestedMin = min - grace;
        }
    }
};

export const CHART_JS_OPTIONS_REGISTRATION = {
    responsive: false,
    plugins: {
        customScale: {
            grace: '5%', // Percentage of max value
            // grace: 40 // Flatout extra value to add
        }
    },
    animation: {
        duration: 750,
    },
};

export const getRegistrationChartDataTemplate = (isHybrid) => {
        const chartData = {
            labels: [],
            datasets: [
                {
                    label: 'Registrations over time',
                    pointStyle: 'circle',
                    pointBackgroundColor: 'rgba(164, 164, 164, 1)',
                    backgroundColor: 'rgba(164, 164, 164, 0.3)',
                    borderColor: 'rgba(164, 164, 164, 1)',
                    borderWidth: 2,
                    pointRadius: 5,
                    data: [],
                }
            ]
        };

        if (isHybrid) {
            chartData.datasets.push({
                label: 'Registrations as online viewer',
                pointStyle: 'circle',
                pointBackgroundColor: 'rgba(25, 118, 210, 1)',
                borderColor: 'rgba(25, 118, 210, 1)',
                backgroundColor: 'rgba(25, 118, 210, 0.3)',
                borderWidth: 2,
                pointRadius: 5,
                data: [],
            });

            chartData.datasets.push({
                label: 'Registrations as on-site viewer',
                pointStyle: 'circle',
                pointBackgroundColor: 'rgba(255, 127, 0, 1)',
                backgroundColor: 'rgba(255, 127, 0, 0.3)',
                borderColor: 'rgba(255, 127, 0, 1)',
                borderWidth: 2,
                pointRadius: 5,
                data: [],
            });
        }

        return chartData;
};

export const pieChartDatasetTemplate = (label, data) => {
        return {
            label,
            data,
            backgroundColor: [
                '#2977BE',
                '#2B4390',
                '#31B6BB',
                '#961E87',
                '#4F3480',
                '#C20C7F',
                '#EE3A23',
                '#23A6DF',
                '#40B649',
                '#F7A41D',
                '#CCCCCC',
                '#B0B0B0',
                '#AAAAAA',
                '#909090',
                '#888888',
                '#707070',
            ],
            hoverOffset: 4,
        };
};