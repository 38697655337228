<template>
    <div class="row">
        <div class="col">
            <h3 class="mb-5 mt-2 font-weight-bold">
                Activity Log
            </h3>
            <div class="activity-log">
                <div class="list-header font-weight-bold">
                    <div class="date">
                        Time
                    </div>
                    <div class="user">
                        User
                    </div>
                    <div v-if="isMultiSession"
                         class="event"
                    >
                        Event
                    </div>
                    <div class="value">
                        Value
                    </div>
                    <div class="actions">
                        Actions
                    </div>
                </div>
                <div v-for="item in log"
                     :key="`log-${getItemKey(item)}`"
                     class="log-item d-flex list-item"
                >
                    <div class="date">
                        {{ item.createdAt }}
                    </div>
                    <div class="user">
                        <changing-user :user="item.user" />
                    </div>
                    <div v-if="isMultiSession"
                         class="event"
                    >
                        <changed-event :event-id="item.eventId" />
                    </div>
                    <div class="value">
                        {{ truncate(item.newValue) }}
                    </div>
                    <div class="actions">
                        <log-details :item="item" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import { mapGetters, mapState } from 'vuex';
    import { $http } from '@/services/http';
    import { makeUrl } from '@/services/ApiService';
    import ChangedEvent from '@/components/pages/ActivityLog/ChangedEvent';
    import ChangingUser from '@/components/pages/ActivityLog/ChangingUser';
    import LogDetails from '@/components/pages/ActivityLog/LogDetails';

    export default {
        name: 'ActivityLog',
        components: {LogDetails, ChangingUser, ChangedEvent},
        data() {
            return {
                log: [],
                loading: true,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['isMultiSession']),
        },
        methods: {
            async getLog() {
                this.loading = true;
                this.log = await $http.get(
                    makeUrl('/projects/{project_id}/activity', {projectId: this.project._id})
                ).then(r => r.data);
                this.loading = false;
            },
            truncate(s) {
                let t = s.substr(0, 50);
                if (t.length < s.length) {
                    t += '...';
                }
                return t;
            },
            getItemKey() {
                return Math.random() * 10000 * Math.random();
            }
        },
        mounted() {
            this.getLog();
        }
    };
</script>

<style scoped lang="scss">
.list-header {
    position: static;
    display: flex;
    background-color: #fff;
    border-bottom: 1px solid rgba(0,0,0,0.05);
    & > div {
        padding: 0.5rem 1rem;
    }
}

.list-item {
    border-top: 1px solid rgba(0,0,0,0.05);
    &:nth-child(odd) {
        background-color: rgba(0,0,0,0.05);
        &:hover {
            background-color: rgba(0,0,0,0.1);
        }
    }
    & > div {
        padding: 0.5rem 1rem;
    }
}

.date {
    flex: 0 0 150px;
}

.user {
    flex: 0 0 250px;
}

.event {
    flex: 0 0 250px;
}

.value {
    flex: 1 1 0;
    overflow: hidden;
    text-overflow: ellipsis;
}

.actions {
    flex: 0 0 50px;
}

</style>