<template>
    <v-card class="px-4 topics-list-header"
            rounded="0"
            elevation="1"
    >
        <v-row>
            <v-col cols="12"
                   md="5"
                   lg="5"
                   class="pr-0"
            >
                <h5 class="text-left">
                    Title
                </h5>
            </v-col>
            <v-col cols="12"
                   md="4"
                   lg="4"
                   class="pr-0"
            >
                <h5 class="text-left">
                    Description
                </h5>
            </v-col>
            <v-col cols="12"
                   lg="1"
                   md="1"
                   class="pr-0"
            >
                <h5 class="left">
                    Time
                </h5>
            </v-col>
            <v-col cols="12"
                   lg="2"
                   md="2"
                   class="pr-0 pl-0"
            >
                <h5 class=" text-left">
                    Duration
                </h5>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    export default {
        name: 'SessionsListHeader',
    };
</script>

<style scoped>
.topics-list-header {
    color: #00000099;
}
</style>
