<template>
    <div class="preview-inner"
         :class="{'fullscreen': fullScreen}"
    >
        <v-progress-linear v-if="loading"
                           indeterminate
        />

        <iframe ref="iframe"
                frameborder="0"
                src="about:blank"
        />
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';
    import {
        EMAIL_PREVIEW_REGISTRATION_CONFIRMATION,
        EMAIL_PREVIEW_REMINDER,
        EMAIL_PREVIEW_THANK_YOU,
        populateUrl,
        EMAIL_PREVIEW_POST_EVENT,
        EMAIL_PREVIEW_EXTRA,
        EMAIL_PREVIEW_ON_SITE_REGISTRATION_CONFIRMATION,
        EMAIL_PREVIEW_ON_SITE_VIEWER_REMINDER,
    } from '@/services/ApiService';

    export default {
        name: 'EmailPreview',
        props: {
            template: {
                type: String,
                default: () => ''
            },
            projectId:{
                type: String,
                default: () => ''
            },
            fullScreen:{
                type: Boolean,
                default: () => false
            }
        },
        created() {
            this.loadPreview();
        },
        data() {
            return {
                loading: false
            };
        },
        computed: {
            ...mapGetters('projects', ['currentProjectId']),
            ...mapState('projects', ['project']),
            previewUrl: function (){
                let currentProjectId = this.currentProjectId ? this.currentProjectId : this.projectId;
                if(this.template === 'registration-confirmation'){
                    return populateUrl(EMAIL_PREVIEW_REGISTRATION_CONFIRMATION, currentProjectId);
                }else if(this.template === 'reminder'){
                    return populateUrl(EMAIL_PREVIEW_REMINDER, currentProjectId);
                }else if(this.template === 'thank-you'){
                    return populateUrl(EMAIL_PREVIEW_THANK_YOU, currentProjectId);
                }else if(this.template === 'post-event'){
                    return populateUrl(EMAIL_PREVIEW_POST_EVENT, currentProjectId);
                }else if(this.template === 'extra'){
                    return populateUrl(EMAIL_PREVIEW_EXTRA, currentProjectId);
                }else if(this.template === 'on-site-viewer-reminder'){
                    return populateUrl(EMAIL_PREVIEW_ON_SITE_VIEWER_REMINDER, currentProjectId);
                }else if (this.template === 'on-site-viewer-registration-confirmation'){
                    return populateUrl(EMAIL_PREVIEW_ON_SITE_REGISTRATION_CONFIRMATION, this.project._id);
                }
                return null;
            },
        },
        methods: {
            ...mapActions('projects', ['renderEmailPreview']),
            loadPreview() {
                this.loading = true;
                this.renderEmailPreview({
                    url: this.previewUrl
                }).then((response) => {
                    const iframe = this.$refs.iframe;
                    iframe.contentWindow.document.open();
                    iframe.contentWindow.document.write(response.data.html);
                    iframe.height = iframe.contentWindow.document.body.scrollHeight + 80 + 'px';
                    iframe.contentWindow.document.close();
                }).finally(() => {
                    this.loading = false;
                });
            }
        },
    };
</script>

<style scoped>
iframe {
    width: 100%;
    pointer-events: none;
}

.preview-inner {
    overflow-y: auto;
}

.fullscreen{
    height: 84vh !important;
}
</style>
