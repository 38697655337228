<template>
    <div>
        <v-autocomplete
            v-if="loaded"
            :key="autocompleteKey"
            ref="companySelector"
            v-model="companyID"
            :search-input.sync="companySearchTerm"
            :items="companies"
            item-text="name"
            item-value="_id"
            hide-details
            filled
            label="Company"
            :disabled="!canSelectCompany || project.isArchived"
        >
            <template v-if="canSelectCompany"
                      v-slot:prepend-inner
            >
                <a @click="showCreateCompanyModal">
                    <v-icon class="mr-2 add-company-icon">add</v-icon>
                </a>
            </template>
            <template v-slot:selection="data">
                <v-chip class="no-background company-selection mr-2">
                    <span v-if="canShowProductionCompany"
                          class="production-company-name"
                    >{{ getProductionCompanyName(data) }}</span> {{ data.item.name }}
                </v-chip>
            </template>
            <template v-slot:item="data">
                <v-list-item-content>
                    <v-list-item-subtitle v-if="isQuadiaLiveAdmin">
                        {{ getProductionCompanyName(data) }}
                    </v-list-item-subtitle>
                    <v-list-item-title>
                        {{ data.item.name }}
                    </v-list-item-title>
                </v-list-item-content>
            </template>
        </v-autocomplete>

        <v-dialog v-model="isConfirmationModalVisible"
                  :max-width="700"
        >
            <v-card>
                <v-card-title>
                    Overwrite project branding?
                </v-card-title>
                <v-card-text>
                    <p>
                        Do you want to overwrite the project branding with the company
                        branding?
                    </p>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn @click="closeConfirmationModal">
                        No
                    </v-btn>
                    <v-btn
                        color="primary"
                        @click="overwriteBrandingAndCloseConfirmationModal"
                    >
                        Yes
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="isCreateCompanyModalVisible"
                  persistent
                  scrollable
        >
            <create-company-modal
                v-if="isCreateCompanyModalVisible"
                @close="closeCreateCompanyModal"
                @saved-and-close="updateCompanyAndCloseCreateCompanyModal"
            />
        </v-dialog>
    </div>
</template>

<script>
    import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
    import CreateCompanyModal from './CreateCompanyModal';
    import { buildSimpleQueryString, COMPANIES_URL } from '@/services/ApiService';
    import {$http} from '@/services/http';
    import {debounce} from 'lodash';
    import { defaultMailingsEnabled } from '@/services/MailingService';

    export default {
        name: 'CompanyAutocomplete',
        components: {CreateCompanyModal},
        props: {
            justSelect: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                isConfirmationModalVisible: false,
                isCreateCompanyModalVisible: false,
                companySearchTerm: '',
                companies: [],
                loaded: false,
                autocompleteKey: 0,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapState('companies', ['company']),
            ...mapGetters('auth', [
                'isQuadiaLiveAdmin',
                'isProjectEditorAtAnyProductionCompany',
                'isCompanyAdminAtAnyProductionCompany',
                'isFreelancerOnlyForProject',
                'isFreelancerForProject',
            ]),
            ...mapGetters('projects', ['isExternalEmbed']),
            companyID: {
                get() {
                    return this.project.companyId;
                },
                async set(value) {
                    if (!value) {
                        await this.setCompanyId(this.project.companyId);
                        ++this.autocompleteKey;
                        this.$nextTick(() => {
                            this.$refs.companySelector.focus();
                            this.$refs.companySelector.activateMenu();
                        });
                        return;
                    }
                    if (value) {
                        await this.getCompany(value);
                    }
                    if(this.justSelect){
                        await this.setCompanyId(value);
                        this.$emit('companySelected', value);
                        return;
                    }

                    await this.updateMailingsEnabled(value);

                    if(this.brandingMatters) {
                        const hadCompanyIdPreviously = !!this.project.companyId;
                        if (hadCompanyIdPreviously) {
                            this.showConfirmationModal();
                        } else {
                            await this.overwriteBranding(value);
                        }
                    }

                    await this.updateCompanyID(value);
                }
            },
            brandingMatters() {
                return !this.isExternalEmbed;
            },
            canSelectCompany() {
                // adding company automatically changes the company to the newly added one
                // thus it is enough for check permissions on the current project to determine
                // whether the add button should be shown
                if (this.isFreelancerOnlyForProject(this.project)) {
                    return false;
                }

                return this.isQuadiaLiveAdmin ||
                    this.isCompanyAdminAtAnyProductionCompany ||
                    this.isProjectEditorAtAnyProductionCompany;
            },
            canShowProductionCompany() {
                return this.isQuadiaLiveAdmin ||
                    this.isFreelancerForProject(this.project);
            }
        },
        watch: {
            companySearchTerm: debounce(function (val) {
                if(!this.isFreelancerOnlyForProject(this.project)) {
                    this.getCompanies(val);
                }
            }, 400),
        },
        methods: {
            ...mapMutations('projects', ['setCompanyId', 'setProductionCompany', 'setEnableMailings']),
            ...mapActions('projects', ['saveProject', 'saveProjectImmediate', 'inheritCompanyBranding']),
            ...mapActions('companies', ['getCompany']),
            showConfirmationModal() {
                this.isConfirmationModalVisible = true;
            },
            closeConfirmationModal() {
                this.isConfirmationModalVisible = false;
            },
            async overwriteBrandingAndCloseConfirmationModal() {
                await this.overwriteBranding(this.companyID);
                this.closeConfirmationModal();
            },
            async overwriteBranding(companyID) {
                await this.inheritCompanyBranding(companyID);
            },
            async updateMailingsEnabled(companyId) {
                const enableMailings = defaultMailingsEnabled(this.project?.registration?.type, companyId);
                this.setEnableMailings(enableMailings);

                await this.saveProjectImmediate({
                    key: 'emailSettings.enableMailings',
                    value: enableMailings,
                });
            },
            showCreateCompanyModal() {
                this.isCreateCompanyModalVisible = true;
            },
            async updateCompanyID(value) {
                this.setCompanyId(value);
                await this.saveProject({
                    key: 'companyId',
                    value,
                });
            },
            closeCreateCompanyModal() {
                this.isCreateCompanyModalVisible = false;
            },
            updateCompanyAndCloseCreateCompanyModal(company) {
                // makes sure the company is in the list so we can select it
                this.companies.push(company);
                this.companyID = company._id;
                this.closeCreateCompanyModal();
            },
            async getCompanies(val) {
                const url = buildSimpleQueryString(COMPANIES_URL, {search: val || ''});
                var response = await $http.get(url);
                let companies = response.data.companies;
                if (this.company._id) {
                    companies = [this.company, ...companies];
                }
                this.companies = companies;
            },
            getProductionCompanyName(company) {
                return company.item.productionCompany;
            }
        },
        async created() {
            if (this.companyID) {
                //make sure the company is actually in the fitered companies list
                var company = await this.getCompany(this.companyID);
                this.companies.push(company);
            } else {
                await this.getCompanies();
            }
            this.loaded = true;
        },
    };
</script>

<style scoped lang="scss">

.add-company-icon {
  color: #1976d2 !important;
}

.no-background {
  background: transparent !important;
  padding: 0 !important;
  margin: 0 !important;

  &:hover {
    &:before {
      background: transparent !important;
    }
  }
}

.production-company-name {
  opacity: 0.7;

  &:after {
    content: "/";
    margin-left: 0.3rem;
    margin-right: 0.3rem;
    color: #000000;
  }
}
</style>

<style>
.company-selection + input {
  padding-top: 0.32rem !important;
  color: rgba(0, 0, 0, 0.5) !important;
}
</style>
