<template>
    <v-menu offset-y>
        <template v-slot:activator="{on}">
            <v-chip color="white"
                    class="mr-4"
                    v-on="on"
            >
                <span class="mr-4">{{ userName }}</span>
                <v-avatar color="primary">
                    <span class="white--text">{{ initials }}</span>
                </v-avatar>
            </v-chip>
        </template>
        <v-list>
            <v-list-item v-if="isQuadiaLiveAdmin"
                         @click="downloadIVSCostReport"
            >
                <v-list-item-icon>
                    <v-icon>mdi-download</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    Download IVS cost report
                </v-list-item-title>
            </v-list-item>
            <v-divider v-if="isQuadiaLiveAdmin" />
            <v-list-item @click="logout">
                <v-list-item-icon>
                    <v-icon>mdi-logout</v-icon>
                </v-list-item-icon>
                <v-list-item-title>
                    Sign out
                </v-list-item-title>
            </v-list-item>
        </v-list>
    </v-menu>
</template>

<script>

    import { $auth } from '../../../services/auth';
    import { $http, makeAccountsUrl } from '../../../services/http';
    import { mapGetters, mapState } from 'vuex';
    import { API_URL } from '@/services/ApiService';

    export default {
        name: 'UserMenu',
        computed: {
            ...mapState('auth', ['user']),
            ...mapGetters('auth', ['isQuadiaLiveAdmin']),
            userName() {
                return this.user.name;
            },
            initials() {
                return this.user.name
                    .split(' ')
                    .map(x => x[0])
                    .join('')
                    .toUpperCase();
            }
        },
        methods: {
            logout() {
                $auth.logout();

                window.location.href = makeAccountsUrl(
                    '/sso/logout'
                );
            },
            downloadIVSCostReport(e) {
                const url = API_URL + '/broadcast-cost-report';
                $http.get(url, {
                    responseType: 'blob',
                }).then(response => {
                    const link = document.createElement('a');
                    link.href = URL.createObjectURL(response.data);
                    link.download = 'streamCostReport.xlsx';
                    link.dispatchEvent(e);
                });
            }
        }
    };
</script>

<style scoped>

</style>