<template>
    <v-row>
        <v-col cols="12"
               xl="5"
               lg="5"
               md="5"
               class="pr-0 topic-title-wrapper"
        >
            <h5 class="font-weight-regular text-no-wrap topic-title">
                <slot name="title" />
            </h5>
        </v-col>
        <v-col cols="12"
               xl="4"
               lg="4"
               md="4"
               class="pr-0 topic-title-wrapper"
        >
            <h5 class="font-weight-regular text-no-wrap topic-title">
                <slot name="description" />
            </h5>
        </v-col>
        <v-col cols="12"
               xl="1"
               lg="1"
               md="1"
               class="text-left pr-0"
        >
            <h5 class="font-weight-regular">
                <slot name="startTime" />
            </h5>
        </v-col>
        <v-col cols="12"
               xl="1"
               lg="1"
               md="1"
               class="text-left pl-0 pr-0"
        >
            <h5 class="font-weight-regular text-no-wrap">
                <slot name="duration" />
            </h5>
        </v-col>
        <v-col cols="12"
               xl="1"
               lg="1"
               md="1"
               class="text-end pr-0 pl-0"
        >
            <slot name="actions" />
        </v-col>
    </v-row>
</template>
<script>
    export default {
        name: 'TopicsListItem'
    };
</script>

<style scoped lang="scss">
.topic-title-wrapper{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    .topic-title{
        display: contents;
    }
}
</style>