var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"list-wrapper"},[_c('email-list-header'),_vm._l((_vm.project.scheduledEmails),function(item){return _c('v-card',{key:("" + (_vm.project.scheduledEmails.indexOf(item))),staticClass:"px-4 email-item",attrs:{"elevation":"0","rounded":"0"}},[_c('v-row',[_c('v-col',{staticClass:"left pr-0",attrs:{"cols":"12","lg":"2","md":"2"}},[_c('h5',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(_vm.emailTypes[item.emailType])+" ")])]),_c('v-col',{staticClass:"text-left pr-0 pl-0",attrs:{"cols":"12","lg":"3","md":"3"}},[_c('h5',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(_vm.emailSendDate(item.timeDifferenceInMinutes, item.emailType))+" ")])]),_c('v-col',{staticClass:"text-left pl-0 pr-0",attrs:{"cols":"12","lg":"3","md":"3"}},[_c('h5',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(_vm.timeDifference(item.timeDifferenceInMinutes, item.emailType))+" ")])]),_c('v-col',{staticClass:"text-left pr-0",attrs:{"cols":"12","lg":"2","md":"2"}},[_c('h5',{staticClass:"font-weight-regular"},[_vm._v(" "+_vm._s(item.wasSentAt ? ("Sent to " + (item.nrOfEmailsSent) + " " + (item.nrOfEmailsSent === 1 ? 'user': 'users')) : 'Scheduled')+" ")])]),(!item.wasSentAt)?_c('v-col',{staticClass:"text-end pr-0 pl-0",attrs:{"cols":"12","lg":"2","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":true,"disabled":_vm.project.isArchived},on:{"click":function($event){return _vm.editScheduledEmail(item)}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-pencil ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":true,"disabled":_vm.project.isArchived},on:{"click":function($event){return _vm.initiateDelete(item._id)}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1):_c('v-col',{staticClass:"text-end pr-0 pl-0",attrs:{"cols":"12","lg":"2","md":"2"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""},on:{"click":function($event){return _vm.showPreview(item._id)}}},on),[_c('v-icon',{attrs:{"size":"20"}},[_vm._v(" mdi-eye ")])],1)]}}],null,true)},[_c('span',[_vm._v("View")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({attrs:{"size":"20","color":"green"}},on),[_vm._v(" mdi-check ")])]}}],null,true)},[_c('span',[_vm._v("Sent")])])],1)],1)],1)}),_c('schedule-mail-dialog',{attrs:{"show-email-modal":_vm.showEmailModal,"email":_vm.selectedEmail},on:{"cancelled":_vm.cancelled}})],2),_c('v-row',[_c('v-col',[_c('v-btn',{attrs:{"color":"primary","outlined":"","disabled":_vm.project.isArchived},on:{"click":function($event){_vm.showEmailModal = true}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" ADD A MAIL ")],1)],1)],1),_c('v-dialog',{attrs:{"persistent":"","max-width":"500"},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}},[_c('v-card',[_c('v-card-title',{staticClass:"pb-0"},[_vm._v(" Delete scheduled email "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.cancelDelete()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_c('p',{staticClass:"font-weight-regular mt-4"},[_vm._v(" Are you sure you want to delete this scheduled email? ")])]),_c('v-divider'),_c('v-card-actions',{staticClass:" px-4 "},[_c('v-spacer'),_c('v-btn',{staticClass:"px-2",attrs:{"color":"error","dark":""},on:{"click":function($event){return _vm.deleteScheduledEmail()}}},[_c('v-icon',[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.cancelDelete()}}},[_vm._v(" Cancel ")])],1)],1)],1),_c('email-preview-dialog',{attrs:{"email":_vm.showEmailPreviewId,"show":_vm.showEmailPreviewModal},on:{"cancelModal":_vm.cancelPreview}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }