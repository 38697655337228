<template>
    <div class="d-flex align-center">
        <div class="d-inline-block mr-9">
            {{ `Hide "${timezone}" label` }}
        </div>
        <div class="d-flex align-center">
            <v-switch 
                v-model="hideTimezone"
                inset
                :disabled="project.isArchived"
            />
            <v-tooltip top>
                <template v-slot:activator="{on}">
                    <v-icon class="pb-1"
                            v-on="on"
                    >
                        info
                    </v-icon>
                </template>
                <span>{{ `Hide the time "${timezone}" annotation on the waiting page, the registration page and the emails.` }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';
    import TimeService from '@/services/TimeService';
    import moment from 'moment-timezone';
    export default {
        name: 'HideTimezone',
        computed: {
            ...mapState('projects', ['project']),
            hideTimezone: {
                get() {
                    return this.project.hideTimezone;
                },
                set(value) {
                    this.setHideTimezone(value);
                    this.saveProject({
                        id: this.project._id,
                        key: 'hideTimezone',
                        value,
                    });
                },
            },
            timezone() {
                const s = moment(new Date()).tz(TimeService.defaultTimezone).format('YYYY-MM-DD HH:mm');
                return moment.tz(s, TimeService.defaultTimezone).format('z');
            }
        },
        methods: {
            ...mapMutations('projects', ['setHideTimezone']),
            ...mapActions('projects', ['saveProject']),
        }
    };
</script>
