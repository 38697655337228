<template>
    <v-row>
        <v-col class="d-flex">
            <div class="d-inline-block mr-9 mt-1">
                Enable extra mail
            </div>
            <v-switch
                v-model="extraMailEnabled"
                hide-details
                class="mt-0"
                inset
                :disabled="project.isArchived"
            />
        </v-col>
    </v-row>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'ToggleExtraMail',
        computed: {
            ...mapState('projects', ['project']),
            extraMailEnabled: {
                get() {
                    return this.project.extraMailEnabled;
                },
                set(value) {
                    this.setExtraMailEnabled(value);
                    this.saveProject({
                        key: 'extraMailEnabled',
                        value: value,
                    });
                },
            },
        },
        methods: {
            ...mapMutations('projects', [
                'setExtraMailEnabled',
            ]),
            ...mapActions('projects', ['saveProject']),
        },
    };
</script>