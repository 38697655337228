<template>
    <v-dialog v-model="value"
              persistent
              max-width="600"
              scrollable
    >
        <v-form ref="updateBreakoutFrom"
                @submit.prevent="updateBreakoutGroup"
        >
            <v-card>
                <v-card-title class="pb-0">
                    Edit breakout
                    <v-spacer />
                    <v-btn icon
                           @click="closeModal"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    <v-text-field v-model="internalBreakoutGroupName"
                                  filled
                                  class="session-input mt-4"
                                  style="max-width: none"
                                  label="Breakout group name"
                                  :rules="requiredField"
                                  hide-details="auto"
                    />
                    <v-divider class=" mt-8 mb-7" />
                    <div
                        v-for="key in Object.keys(breakouts)"
                        :key="`breakout-${key}`"
                    >
                        <v-text-field
                            v-model="breakouts[key]"
                            filled
                            class="session-input"
                            style="max-width: none"
                            label="Breakout title"
                            :rules="requiredField"
                            hide-details="auto"
                        />
                        <div class="d-flex align-center  mt-3">
                            <span class="mr-10 pt-3">Max number of registrations</span>
                            <v-text-field
                                v-model="breakoutsRegistrationsCountLimit[key]"
                                class="number-input"
                                type="number"
                                min="0"
                                max="300"
                                :rules="[v => v <= 300 || 'Max 300 registrants']"
                            />
                        </div>
                        <v-divider class=" mt-3 mb-6" />
                    </div>
                    <v-row>
                        <v-col cols="12"
                               lg="6"
                               md="6"
                               sm="6"
                               class="py-0 d-inline-flex"
                        >
                            <h3 class="font-weight-regular mr-3 pt-6">
                                Start time
                            </h3>
                            <custom-time-picker v-model="breakoutGroupStartTime"
                                                :rules="requiredField"
                                                class="session-input"
                            />
                        </v-col>
                        
                        <v-col cols="12"
                               lg="6"
                               md="6"
                               sm="6"
                               class="py-0 d-inline-flex"
                        >
                            <h3 class="font-weight-regular mr-3 pt-6">
                                Duration
                            </h3>
                            <v-text-field
                                v-model="internalDurationInMinutes"
                                type="number"
                                min="1"
                                class="session-input"
                                :rules="requiredFieldDuriation"
                            />
                            <h3 class="font-weight-regular ml-3 pt-6">
                                minutes
                            </h3>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="px-6 pb-4">
                    <v-spacer />
                    <v-btn color="primary"
                           :disabled="submitDisabled"
                           :loading="submitDisabled"
                           type="submit"
                    >
                        Update
                    </v-btn>
                    <v-btn @click="closeModal">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </v-dialog>
</template>
<script>
    import { $http } from '@/services/http';
    import { makeUrl } from '@/services/ApiService';
    import { mapState } from 'vuex';
    import CustomTimePicker from '@/components/controls/CustomTimePicker';
    import { trim } from 'lodash';


    export default {
        name: 'UpdateBreakoutGroupDialog',
        components: {CustomTimePicker},
        props: {
            value: {
                type: Boolean,
                default: () => false,
            },
            breakoutGroup: {
                type: String,
                default: () => '',
            },
            startTime: {
                type: Number,
                default: () => 0,
            },
            durationInMinutes: {
                type: Number,
                default: () => 0
            },
            breakoutGroupName: {
                type: String,
                default: () => '',
            }
        },
        data() {
            return {
                breakoutGroupStartTime: this.startTime,
                internalDurationInMinutes: this.durationInMinutes,
                internalBreakoutGroupName: this.breakoutGroupName,
                submitDisabled: false,
                breakouts: {},
                breakoutsRegistrationsCountLimit: {},
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            requiredField(){
                return [(v) => !!trim(v) || 'Field is required'];
            },
            requiredFieldDuriation(){
                return [(v) => !!v && v > 0 || 'Value must be greater than 1'];
            }
        },
        methods: {
            async updateBreakoutGroup() {
                this.submitDisabled = true;
                if (this.$refs.updateBreakoutFrom.validate()){
                    const url = makeUrl('/projects/{project_id}/events/breakouts/{breakoutGroup}', {
                        projectId: this.project._id,
                        breakoutGroup: this.breakoutGroup
                    });
                    await $http.put(url, {
                        startTime: this.breakoutGroupStartTime,
                        durationInMinutes: Math.round(this.internalDurationInMinutes),
                        breakoutGroupName: this.internalBreakoutGroupName,
                        breakouts: this.breakouts,
                        registrationsCountLimitAssocArray: this.breakoutsRegistrationsCountLimit,
                    });
                    this.$emit('saved');
                    this.closeModal();
                }
                this.submitDisabled = false;
            },
            closeModal(){
                this.$emit('input', false);
            },
        },
        created(){
            this.project.events
                .filter(e => e.breakout_group === this.breakoutGroup)
                .map(e => { 
                    this.breakouts[e._id] = e.name;
                    this.breakoutsRegistrationsCountLimit[e._id] = e.registrationsCountLimit;
                });
        }
    };
</script>

<style scoped>
.session-input{
    max-width: 10rem;
}
.number-input{
    max-width: 100px;
}
</style>
