<template>
    <draggable
        :list="blocks"
        class="block-container"
        group="main"
        v-bind="draggableOptions"
        :options="{disabled : project.isArchived}"
        @end="dragEnded"
    >
        <div
            v-for="block in blocks"
            :key="`block-${block._id}`"
            class="block-wrapper"
            :class="blockClass(block)"
        >
            <div
                :ref="`block-${block._id}`"
                class="block"
                :class="{ selected: isSelectedBlock(block) }"
                @click="selectBlock(block)"
            >
                <div
                    v-if="isSelectedBlock(block) && block.componentType === 'column'"
                    class="column-options"
                >
                    <block-creator
                        class="block-creator mr-2"
                        :block="block"
                        use-icon-button
                        :save-url="saveUrl"
                    >
                        <template v-slot:tooltip>
                            Add new block to this column
                        </template>
                    </block-creator>
                    <move-into-column-button :block="block"
                                             style="position: absolute"
                                             :save-url="saveUrl"
                    />
                </div>
                <draggable
                    v-if="block.componentType === 'column'"
                    :list="block.componentOptions.blocks"
                    class="block-column"
                    :group="block._id"
                    v-bind="draggableOptions"
                    :options="{disabled : project.isArchived}"
                    @end="dragEnded"
                >
                    <div
                        v-for="subBlock in block.componentOptions.blocks"
                        :key="`block-${subBlock._id}`"
                        class="block-wrapper"
                        :class="blockClass(subBlock)"
                    >
                        <div
                            :ref="`block-${subBlock._id}`"
                            class="block"
                            :class="{ selected: isSelectedBlock(subBlock) }"
                            @click.stop="selectBlock(subBlock)"
                        >
                            <move-out-of-column-button
                                v-if="isSelectedBlock(subBlock)"
                                :block="subBlock"
                                :save-url="saveUrl"
                            />
                        </div>
                    </div>
                </draggable>
            </div>
        </div>
    </draggable>
</template>

<script>
    import { mapMutations, mapState, mapGetters, mapActions } from 'vuex';
    import MoveIntoColumnButton from '@/components/pages/Project/EventPage/MoveIntoColumnButton';
    import MoveOutOfColumnButton from '@/components/pages/Project/EventPage/MoveOutOfColumnButton';
    import draggable from 'vuedraggable';
    import BlockCreator from '@/components/pages/Project/EventPage/BlockCreator';

    export default {
        name: 'BlockOverlay',
        components: {
            BlockCreator,
            draggable,
            MoveIntoColumnButton,
            MoveOutOfColumnButton,
        },
        computed: {
            ...mapState('dynamic-block', [
                'blocks',
                'overlayScrollKey',
                'blocksLoadedMessage',
            ]),
            ...mapGetters('dynamic-block', ['isSelectedBlock']),
            ...mapState('projects', ['project'])
        },
        props: {
            saveUrl: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                draggableOptions: {
                    ghostClass: 'ghost',
                    scrollSensitivity: 250,
                    forceFallback: true
                },
            };
        },
        methods: {
            ...mapMutations('dynamic-block', ['selectBlock']),
            ...mapActions('dynamic-block', ['saveBlocks']),
            blockClass(block) {
                return {
                    third: 'third-width',
                    'two-thirds': 'two-third-width',
                    half: 'half-width',
                    full: 'full-width',
                }[block.width];
            },
            dragEnded() {
                this.saveBlocks(this.saveUrl);
                this.$emit('dragend');
            },
            resizeBlocks() {
                this.blocksLoadedMessage.components.forEach((block) => {
                    var element = this.$refs[`block-${block.id}`][0];
                    if (element) {
                        element.style.height = `${block.offsetHeight}px`;
                    }
                });
            },
        },
        mounted() {
            this.resizeBlocks();
        },
        watch: {
            async overlayScrollKey() {
                await this.$nextTick();
                const element = this.$el.querySelector('.block.selected');
                element?.scrollIntoView(false);
            },
            blocksLoadedMessage: {
                deep: true,
                handler() {
                    this.resizeBlocks();
                },
            },
        },
    };
</script>

<style lang="scss">
.block-container,
.block-column {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  color: #2e1830;

  .third-width {
    flex: 0 1 calc(100% / 3);
    max-width: calc((100% / 3));
  }

  .two-third-width {
    flex: 0 1 calc((100% / 3) * 2);
    max-width: calc((100% / 3) * 2);
  }

  .half-width {
    flex: 0 1 50%;
    max-width: 50%;
  }

  .full-width {
    flex: 0 1 100%;
    max-width: 100%;
  }

  .block-wrapper {
    position: relative;
    transition: all .3s ease-in-out;

    &:hover{
      background-color: rgba(255,255,255,0.1);
      transition: all .3s ease-in-out;
    }

    .block {
      max-width: 100%;
      position: relative;
      .column-options {
        display: flex;
        position: absolute;
        left: 0;
        top: 0;
        height: 40px;
        z-index: 1;
      }

      &.selected {
        border: 3px dashed #bbbbbb;
        border-radius: 3px;
      }
    }
  }
}

.ghost {
  border: 3px solid #1c6ca1;
  order: initial;
}
</style>