<template>
    <div>
        <v-tooltip top>
            <template v-slot:activator="{on}">
                <v-btn icon
                       color="primary"
                       v-on="on"
                       @click="openSelector"
                >
                    <v-icon>
                        add
                    </v-icon>
                </v-btn>
            </template>
            <span>Add upcoming events</span>
        </v-tooltip>
        <confirm-modal ref="upcomingEventSelectorModal">
            <template v-slot:title>
                Select events
            </template>
            <template v-slot:content="{toggleConfirm}">
                <upcoming-events-list v-if="!loading"
                                      v-model="internalSelectedItems"
                                      :items="items"
                                      @toggleconfirm="toggleConfirm"
                >
                    <template v-slot:no-items>
                        No upcoming events found
                    </template>
                </upcoming-events-list>
                <v-progress-linear v-else
                                   indeterminate
                />
            </template>
            <template v-slot:additional-buttons>
                <v-btn color="secondary"
                       :disabled="items.length === 0"
                       @click="selectAllAndUpdate"
                >
                    Select all and update
                </v-btn>
            </template>
            <template v-slot:confirm-title>
                Update
            </template>
        </confirm-modal>
    </div>
</template>
<script>
    import UpcomingEventsList from '@/components/controls/upcoming-events/UpcomingEventsList.vue';
    import ConfirmModal from '@/components/controls/dialogs/ConfirmModal.vue';
    import { $http } from '@/services/http';
    import { makeUrl } from '@/services/ApiService';
    import { mapState } from 'vuex';

    export default {
        name: 'UpcomingEventsSelector',
        components: {ConfirmModal, UpcomingEventsList},
        data() {
            return {
                loading: false,
                items: [],
                internalSelectedItems: this.value,
            };
        },
        props: {
            value: {
                type: Array,
                default: () => [],
            },
        },
        computed: {
            ...mapState('projects', ['project'])
        },
        methods: {
            async openSelector() {
                this.loadItems();
                const result = await this.$refs.upcomingEventSelectorModal.open();
                if (result) {
                    this.$emit('input', this.internalSelectedItems);
                }
            },
            async loadItems() {
                this.loading = true;
                const response = await $http.get(
                    makeUrl('/projects/upcoming/company/' + this.project.companyId)
                );
                this.items = response.data.filter(e => e._id !== this.project.events[0]._id);
                this.loading = false;
            },
            selectAllAndUpdate() {
                this.$refs.upcomingEventSelectorModal.cancel();
                this.$emit('input', this.items);
            }
        }
    };
</script>

<style scoped>

</style>