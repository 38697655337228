<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col>
                    <h3 class="mb-5 font-weight-regular">
                        Project settings
                    </h3>
                    <company-autocomplete :just-select="true"
                                          class="text-input mb-5 e2etest-project-company"
                                          @companySelected="companySelected"
                    />
                    <v-text-field
                        v-model="projectName"
                        filled
                        class="text-input e2etest-project-name"
                        label="Internal project name"
                    />
                    <v-select v-if="!isExternalEmbed"
                              v-model="projectLanguage"
                              :items="availableLanguages"
                              label="Language"
                              item-text="label"
                              item-value="value"
                              class="text-input mb-4 e2etest-project-language"
                              hide-details
                    />
                    <project-type-selector v-model="projectType"
                                           class="text-input pt-4 e2etest-project-type"
                    />
                    <v-text-field
                        v-if="isHybridProject"
                        v-model="expectedOnSiteViewers"
                        filled
                        class="text-input mt-4"
                        label="Maximum on-site viewers"
                        type="number"
                    />
                    <custom-time-picker v-if="isHybridProject"
                                        :value="onSiteStartTime"
                                        class="text-input"
                                        label="On-site start time"
                                        @input="setOnSiteStartTime"
                    />
                    <v-select v-if="!isExternalEmbed"
                              v-model="registrationType"
                              class="text-input mt-4 e2etest-registration-type"
                              :items="registrationTypeOptions"
                              item-text="label"
                              item-value="value"
                              label="Type of registration"
                    />
                    <v-select v-if="isExternalEmbed"
                              v-model="liveDirectorTemplate"
                              class="text-input mt-4"
                              :items="liveDirectorTemplates"
                              item-text="label"
                              item-value="value"
                              label="Live Director template"
                    />
                </v-col>
            </v-row>
            <v-divider class="mb-2 mt-2" />
            <v-row>
                <v-col>
                    <h3 class="mt-5 mb-3 font-weight-regular todo-tbd">
                        Event settings
                    </h3>
                    <v-text-field v-if="!isExternalEmbed"
                                  v-model="eventTitle"
                                  filled
                                  class="text-input e2etest-event-title"
                                  label="Event title"
                                  @keyup="eventTitleChanged"
                    />
                    <v-row>
                        <v-col cols="12"
                               :lg="showFullDateTimePicker ? 3 : 4"
                        >
                            <custom-date-picker
                                v-model="startDate"
                                :top="true"
                                class="e2etest-date-picker"
                            />
                        </v-col>
                        <v-col v-if="showFullDateTimePicker"
                               cols="12"
                               lg="3"
                               class="pt-6"
                        >
                            <custom-time-picker
                                v-model="startDate"
                                class="e2etest-time-picker"
                            />
                        </v-col>
                        <v-col v-if="showFullDateTimePicker"
                               cols="12"
                               lg="2"
                        >
                            <v-text-field
                                v-model="durationInMinutes"
                                class="mt-4 e2etest-event-duration"
                                label="Duration"
                                type="number"
                                min="0"
                            />
                        </v-col>
                        <v-col v-if="showFullDateTimePicker"
                               cols="12"
                               lg="4"
                        >
                            <div class="mt-8">
                                <h3 class="font-weight-regular todo-tbd">
                                    Minutes
                                </h3>
                            </div>
                        </v-col>
                    </v-row>
                    <sessions-list-create-project v-if="isMultiSession"
                                                  :events="events"
                                                  @updateEvents="updateEvents"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-btn color="primary"
                           :disabled="!valid || projectSaving"
                           @click="createProject"
                    >
                        <v-progress-circular v-if="projectSaving"
                                             indeterminate
                                             color="white"
                                             class="mr-4"
                                             :size="25"
                                             :width="2"
                        />
                        <span class="button-content">
                            Save
                        </span>
                    </v-btn>
                </v-col>
            </v-row>
        </v-col>
        <v-divider inset
                   vertical
        />
        <v-col>
            <client-assignments v-if="canAssignClients"
                                :key="selectedCompany"
                                v-model="assignedClients"
                                :company="selectedCompany"
            />
        </v-col>
    </v-row>
</template>

<script>
    import { mapActions, mapGetters } from 'vuex';
    import CompanyAutocomplete from '@/components/pages/Project/ProjectOverviewElements/CompanyAutocomplete';
    import CustomDatePicker from '@/components/controls/CustomDatePicker';
    import CustomTimePicker from '@/components/controls/CustomTimePicker';
    import SessionsListCreateProject from '@/components/pages/Project/CreateProjectElements/SessionsListCreateProject';
    import ProjectTypeSelector from '@/components/controls/ProjectTypeSelector';
    import { REGISTRATION_TYPES } from '@/services/RegistrationService';
    import TimeService from '@/services/TimeService';
    import { toastWarning } from '@/services/responseErrors';
    import ClientAssignments from '@/components/controls/ClientAssignments.vue';
    import { $http } from '@/services/http';
    import { makeUrl } from '@/services/ApiService';

    export default {
        name: 'CreateProjectDetails',
        components: {
            ClientAssignments,
            CustomDatePicker,
            CompanyAutocomplete,
            ProjectTypeSelector,
            CustomTimePicker,
            SessionsListCreateProject,
        },
        data() {
            return {
                projectName: '',
                projectLanguage: '',
                durationInMinutes: '',
                startDate: 0,
                onSiteStartTime: 0,
                selectedCompany: null,
                projectSaving: false,
                eventTitle: '',
                availableLanguages: [
                    {'label': 'Dutch (formal)', 'value': 'nlFormal'},
                    {'label': 'Dutch (informal)', 'value': 'nlInformal'},
                    {'label': 'English', 'value': 'en'},
                ],
                projectType: 'singleSession',
                expectedOnSiteViewers: null,
                registrationType: 'registration',
                liveDirectorTemplate: null,
                liveDirectorTemplates: [
                    {'label': 'Medtalks', value: 'medtalks'},
                    {'label': 'ASML', value: 'asml'},
                ],
                events: [],
                assignedClients: [],
            };
        },
        watch: {
            startDate (value) {
                this.onSiteStartTime = TimeService.applyDateComponent(value, this.onSiteStartTime);
            },
            projectType (value) {
                if (value === 'externalEmbed') {
                    this.registrationType = 'open';
                }
            },
            selectedCompany (value, oldValue) {
                if (value !== oldValue) {
                    this.assignedClients = [];
                }
            }
        },
        computed: {
            ...mapGetters('auth', ['isQuadiaLiveAdmin', 'isCompanyAdminAtAnyProductionCompany', 'isProjectEditorAtAnyProductionCompany']),
            valid() {
                if(this.isSingleSession){
                    return this.projectName && this.durationInMinutes && this.startDate && this.selectedCompany && this.eventTitle && this.projectLanguage;
                }
                if(this.isMultiSession) {
                    return this.projectName  && this.startDate && this.selectedCompany && this.projectLanguage && this.events.length > 0 && this.eventTitle;
                }
                if(this.isExternalEmbed) {
                    return this.projectName && this.durationInMinutes && this.startDate && this.selectedCompany;
                }
                if(this.isHybridProject){
                    return this.projectName && this.durationInMinutes && this.startDate && this.selectedCompany && this.expectedOnSiteViewers > 0 && this.eventTitle && this.projectLanguage;
                }
                return false;
            },
            registrationTypeOptions() {
                return REGISTRATION_TYPES;
            },
            showFullDateTimePicker() {
                return !this.isMultiSession;
            },
            isSingleSession(){
                return this.projectType === 'singleSession';
            },
            isMultiSession() {
                return this.projectType === 'multiSession';
            },
            isExternalEmbed() {
                return this.projectType === 'externalEmbed';
            },
            isHybridProject(){
                return this.projectType === 'hybrid';
            },
            canAssignClients() {
                return !!this.selectedCompany
                    && (
                        this.isQuadiaLiveAdmin
                        || this.isCompanyAdminAtAnyProductionCompany
                        || this.isProjectEditorAtAnyProductionCompany
                    );
            }
        },
        methods: {
            ...mapActions('projects', ['storeProject']),
            eventTitleChanged(name) {
                this.$emit('eventTitleChanged', name.target.value);
            },
            companySelected(value) {
                this.selectedCompany = value;
            },
            updateEvents(events){
                this.events = events;
            },
            async applyClientPermissions(projectId) {
                if (this.assignedClients.length > 0) {
                    try {
                        await $http.put(
                            makeUrl(
                                '/clients/assign/{project_id}',
                                {projectId}
                            ),
                            {
                                client_users: this.assignedClients.map(c => c.user_id),
                            }
                        );
                    } catch (e) {
                        toastWarning('Unable to save client permissions on this project.');
                    }
                }
            },
            createProject() {
                this.projectSaving = true;
                let projectToStore = {
                    name: this.projectName,
                    language: this.projectLanguage || 'en',
                    companyId: this.selectedCompany,
                    template: this.liveDirectorTemplate,
                    eventTitle: this.eventTitle || this.projectName,
                    type: this.projectType,
                    registrationType: this.registrationType,
                    expectedOnSiteViewers: this.expectedOnSiteViewers,
                    onSiteStartTime: this.onSiteStartTime,
                };

                if(this.showFullDateTimePicker){
                    projectToStore.eventStartTime = this.startDate;
                    projectToStore.durationInMinutes = this.durationInMinutes;
                } else {
                    this.addTimeStampToEvents();
                    projectToStore.events = this.events;
                }
                this.storeProject(projectToStore)
                    .then(async (response) => {
                        if(!response.data.productionCompanyExist){
                            toastWarning('No matching ProductionCompany found in LiveDirector.  Please contact support');
                        }

                        await this.applyClientPermissions(response.data._id);

                        await this.$router.push({
                            name: 'Project',
                            params: {project_id: response.data._id},
                        });
                    })
                    .finally(() => {
                        this.projectSaving = false;
                    });
            },
            addTimeStampToEvents(){
                this.events.map((event) => {
                    event.startTime = TimeService.eventStartTimeFromProjectStartTime(this.startDate, event.startTimeFormattedTime);
                });
            },
            setOnSiteStartTime(value) {
                this.onSiteStartTime = TimeService.applyDateComponent(this.startDate, value);
            },
        }
    };
</script>

<style scoped lang="scss">
.text-input {
  width: 22rem;
}

.v-btn:not(.v-btn--outlined).primary{
     .button-content{
        color: white !important;
    }

}
.theme--light.v-btn.v-btn--disabled{
    .button-content{
        color: rgba(0, 0, 0, 0.26) !important;
    }
}
</style>
