<template>
    <v-dialog v-model="dialog"
              scrollable
    >
        <v-card :loading="loading">
            <v-card-title v-if="!loading">
                {{ intake.intake.project.webinarTitle }} details
                <v-spacer />
                <span class="font-weight-regular grey--text smaller mr-4">
                    <a :href="intakeUrl"
                       target="_blank"
                    >{{ intake._id }}</a>
                </span>
                <v-btn icon
                       @click="closeDialog"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-divider />
            <v-card-actions v-if="!loading"
                            class="py-0"
            >
                <intake-global
                    :value="{
                        ui: this.intake.ui,
                        timestamps: this.intake.timestamps,
                    }"
                    :intake-id="intake._id"
                />
            </v-card-actions>
            <v-divider />
            <v-card-text v-if="!loading"
                         class="px-0 pb-0"
            >
                <component :is="intakeType"
                           :value="intake.intake"
                />
            </v-card-text>
            <v-card-text v-else
                         class="pa-4"
            >
                Loading...
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
    import { INTAKE_URL, populateIntakeUrl, SINGLE_INTAKE_URL } from '@/services/ApiService';
    import { $http } from '@/services/http';
    import IntakeGlobal from '@/components/pages/Intake/IntakeDisplay/IntakeGlobal';
    import IntakeTabsQlive from '@/components/pages/Intake/IntakeDisplay/IntakeTabsQlive';
    import IntakeTabs from '@/components/pages/Intake/IntakeDisplay/IntakeTabs';

    export default {
        name: 'IntakeData',
        components: {
            IntakeTabs,
            IntakeTabsQlive,
            IntakeGlobal,
        },
        props: {
            value: {
                type: String,
                default: () => null,
            },
        },
        data() {
            return {
                dialog: false,
                loading: true,
                intake: null,
            };
        },
        watch: {
            dialog(value) {
                if (!value) {
                    this.$emit('close');
                }
            },
        },
        computed: {
            intakeType() {
                let component = 'intake-tabs';
                if (this.intake.type === 'quadia-live') {
                    component = `${component}-qlive`;
                }
                return component;
            },
            intakeUrl() {
                return populateIntakeUrl(INTAKE_URL, this.intake._id);
            },
        },
        methods: {
            loadIntake() {
                if (!this.value) {
                    return;
                }
                this.loading = true;
                this.dialog = true;
                $http
                    .get(populateIntakeUrl(SINGLE_INTAKE_URL, this.value))
                    .then((response) => {
                        this.intake = response.data;
                        this.loading = false;
                    });
            },
            closeDialog() {
                this.dialog = false;
            },
        },
        mounted() {
            this.loadIntake();
        },
    };
</script>

<style scoped>
.smaller {
    font-size: 0.9rem;
}
</style>