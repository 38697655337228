<template>
    <logo-picker :upload-url="uploadUrl"
                 :logo-url="logoUrl"
                 :logo="logo"
                 :disabled="project.isArchived"
                 @input="logoChanged"
    />
</template>


<script>
    import { LOGO_UPLOAD_URL } from '@/services/ApiService';
    import { mapActions, mapMutations, mapState } from 'vuex';
    import LogoPicker from '@/components/controls/LogoPicker.vue';


    export default {
        name: 'ProjectLogoPicker',
        components:{LogoPicker},
        data(){
            return{
                logoUploading: false,
                logoKey: 0,
                selectedFile: ''
            };
        },
        computed:{
            ...mapState('projects', ['project']),
            uploadUrl() {
                return LOGO_UPLOAD_URL;
            },
            logoUrl: {
                get() {
                    return this.project.style.logoUrl;
                },
                set(value) {
                    this.setLogoUrl(value);
                },
            },
            logo: {
                get() {
                    return this.project.style.logo;
                },
                set(value) {
                    this.setLogo(value);
                    this.saveProject({
                        key: 'style.logo',
                        value,
                    });
                },
            },
        },
        methods:{
            ...mapMutations('projects', [
                'setLogo',
                'setLogoUrl',
            ]),
            ...mapActions('projects', ['saveProject']),
            logoChanged(data) {
                this.logo = data.logo;
                this.logoUrl = data.logoUrl;
            }
        }
    };
</script>

<style lang="scss">

</style>