<template>
    <div>
        <v-text-field v-if="hasTabTitles"
                      v-model="tabTitle"
                      label="Tab title"
                      :disabled="project.isArchived"
        />
        <v-select v-model="width"
                  :items="widthItems"
                  label="Select width"
                  class="mt-3"
                  attach=""
                  dense
                  hide-details
                  :disabled="project.isArchived"
        />
        <div class="d-flex mt-3">
            <label class="mr-3">Background color:</label>
            <custom-color-picker v-model="backgroundColor"
                                 :disabled="project.isArchived"
            />
        </div>
        <div v-if="!hideGradient"
             class="d-flex mt-3"
        >
            <label class="mt-1 mr-4">Overlay:</label>
            <v-switch
                v-model="gradient"
                :input-value="gradient"
                inset
                class="mt-0"
                hide-details
                :disabled="project.isArchived"
            />
        </div>
        <v-divider class="mb-0 mt-2" />
        <div>
            <image-picker v-model="background"
                          :max-file-size="maxFileSize"
                          :rules="imageRules"
                          :upload-url="uploadUrl"
                          label="Background image"
            >
                <template v-slot:hint>
                    <background-hint />
                </template>
            </image-picker>
        </div>
        <div v-if="!hideTextColor"
             class="d-flex align-center"
        >
            <label class="mr-4">Text color:</label>
            <v-radio-group
                v-model="textColor"
                row
            >
                <v-radio
                    label="Light"
                    value="#fff"
                />
                <v-radio
                    label="Dark"
                    value="#000"
                />
            </v-radio-group>
        </div>
    </div>
</template>

<script>
    import CustomColorPicker from '@/components/controls/CustomColorPicker';
    import ImagePicker from '@/components/controls/ImagePicker';
    import BackgroundHint from '@/components/controls/hints/BackgroundHint';
    import { mapActions, mapState } from 'vuex';
    import { DYNAMIC_BLOCK_IMAGE_UPLOAD_URL } from '@/services/ApiService';
    import {
        DYNAMIC_BLOCK_IMAGE_MAX_FILE_SIZE,
        dynamicBlockImageValidationRules,
    } from '@/services/fileValidation';

    export default {
        name: 'GenericBlockOptions',
        components: {
            CustomColorPicker,
            ImagePicker,
            BackgroundHint
        },
        props: {
            block: {
                type: Object,
                required: true,
            },
            hideGradient: {
                type: Boolean,
                default: false,
            },
            hideTextColor: {
                type: Boolean,
                default: false
            },
            saveUrl: {
                type: String,
                required: true,
            },
            hasTabTitles: {
                type: Boolean,
                default: () => true,
            }
        },
        data() {
            return {
                widthItems: [
                    // { value: 'third', text: 'Third' },
                    // { value: 'two-thirds', text: 'Two Third' },
                    { value: 'half', text: 'Half' },
                    { value: 'full', text: 'Full width' },
                ],
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            textColor:{
                get(){
                    return this.block?.textColor || '#fff';
                },
                set(value){
                    this.block.textColor = value;
                    this.saveBlocks(this.saveUrl);
                }

            },
            background:{
                get() {
                    return {'url': this.backgroundUrl, 'key': this.backgroundPath};
                },
                set(value) {
                    this.backgroundUrl = value.url;
                    this.backgroundPath = value.key;
                },
            },
            backgroundUrl: {
                get() {
                    return this.block.backgroundImage?.url || '';
                },
                set(value) {
                    this.block.backgroundImage.url  = value;
                    this.getMeta(value);
                },
            },
            backgroundPath: {
                get() {
                    return this.block?.backgroundImage?.path || '' ;
                },
                set(value) {
                    this.block.backgroundImage.path = value;
                    this.saveBlocks(this.saveUrl);
                },
            },
            width: {
                get() {
                    return this.block.width;
                },
                set(value) {
                    this.block.width = value;
                    this.saveBlocks(this.saveUrl);
                },
            },
            backgroundColor: {
                get() {
                    return this.block.backgroundColor;
                },
                set(value) {
                    this.block.backgroundColor = value;
                    this.saveBlocks(this.saveUrl);
                },
            },
            tabTitle: {
                get(){
                    return this.block?.tabTitle || '';
                },
                set(value){
                    this.block.tabTitle = value;
                    this.saveBlocks(this.saveUrl);
                }
            },
            gradient:{
                get() {
                    return this.block.gradient;
                },
                set(value) {
                    this.block.gradient = value;
                    this.saveBlocks(this.saveUrl);
                },
            },
            imageRules(){
                return dynamicBlockImageValidationRules;
            },
            maxFileSize(){
                return DYNAMIC_BLOCK_IMAGE_MAX_FILE_SIZE;
            },
            uploadUrl(){
                return DYNAMIC_BLOCK_IMAGE_UPLOAD_URL;
            }
        },
        methods: {
            ...mapActions('dynamic-block', ['saveBlocks']),
            getMeta(url){
                const img = new Image();
                img.addEventListener('load', () => {
                    this.block.backgroundImage.width = img.width;
                    this.block.backgroundImage.height = img.height;
                    this.saveBlocks(this.saveUrl);
                });
                img.src = url;
            }
        }
    };
</script>
