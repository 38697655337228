<template>
    <ul>
        <li v-for="(item, index) in items"
            :key="index"
        >
            {{ item }}
        </li>
    </ul>
</template>

<script>
    export default {
        name: 'SimpleOptionsDisplay',
        props: {
            options: {
                type: String
            }
        },
        computed: {
            items() {
                return this.options.split('\n');
            }
        }
    };
</script>

<style scoped>

</style>