<template>
    <div class="session-type">
        <h3 class="mb-2 font-weight-bold">
            Session type
        </h3>
        <v-divider class="mb-1" />
        <v-radio-group v-model="internalValue"
                       :disabled="project.isArchived"
        >
            <v-radio label="Regular session"
                     value="regular"
            />
            <v-radio label="Interactive session (Jitsi)"
                     value="jitsi-meet"
            />
        </v-radio-group>
    </div>
</template>
<script>
    import { mapState } from 'vuex';

    export default {
        name: 'SessionTypeEditor',
        props: {
            value: {
                type: String,
                default: () => 'regular',
                required: true
            }
        },
        data() {
            return {
                internalValue: this.value,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
        },
        watch: {
            internalValue(value) {
                this.$emit('input', value);
            }
        }
    };
</script>

<style scoped>

</style>