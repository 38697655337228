<template>
    <v-row>
        <v-col>
            <h3 class="mb-2 font-weight-bold">
                On-site homescreen body text
            </h3>
            <v-divider class="mb-10" />
            <div class="px-4">
                <rich-text-editor v-model="bodyText"
                                  :disabled="project.isArchived"
                                  class="rich-text-editor"
                />
            </div>
        </v-col>
    </v-row>
</template>

<script>
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import {mapActions, mapMutations, mapState} from 'vuex';
    export default {
        name: 'OnSiteHomescreenTab',
        components: { RichTextEditor, },
        computed: {
            ...mapState('projects', ['project']),
            bodyText: {
                get() {
                    return this.project?.pwaHomescreenContent || '';
                },
                async set(value) {
                    this.setProjectPwaHomescreenContent(value);
                    await this.saveProject({
                        id: this.project._id,
                        key: 'pwaHomescreenContent',
                        value: value,
                    });
                    this.$emit('refreshPreview');
                },
            },
        },
        methods: {
            ...mapActions('projects', ['saveProject']),
            ...mapMutations('projects', ['setProjectPwaHomescreenContent']),
        },
    };
</script>
