<template>
    <v-main class="quadia-live-admin grey lighten-4">
        <router-view />
    </v-main>
</template>

<script>

    export default {
        name: 'AuthLayout'
    };
</script>

<style scoped>

</style>