<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="thankYouMailSubject"
                        filled
                        class="text-input todo-tbd"
                        label="Mail subject"
                        :disabled="!edit"
                    />
                </v-col>
            </v-row>
            <rich-text-editor v-model="thankYouMailBody"
                              :disabled="!edit"
                              :with-agenda="true"
                              :with-event-links="false"
                              :with-on-site-start-time="isHybridProject"
                              :with-location-address="isHybridProject"
                              :with-font-selector="false"
            />
            <v-row class="mt-5">
                <v-col v-if="!project.isArchived"
                       cols="12"
                       lg="4"
                       xl="3"
                >
                    <v-btn
                        v-if="!edit"
                        class="mr-4 pa-2"
                        color="primary"
                        outlined
                        @click="editEmail"
                    >
                        <v-icon class="mr-1">
                            mdi-pencil
                        </v-icon>
                        EDIT CONTENT
                    </v-btn>
                    <v-btn
                        v-else
                        class="mr-4 pa-2"
                        color="primary"
                        @click="saveAndRefresh"
                    >
                        <v-icon class="mr-1">
                            mdi-content-save
                        </v-icon>
                        SAVE CONTENT
                    </v-btn>
                </v-col>
                <v-col cols="12"
                       lg="8"
                       xl="9"
                       class="d-flex"
                >
                    <v-spacer v-if="$vuetify.breakpoint.lgAndUp && !project.emailSettings.thankYou.emailsSentAt" />
                    <send-thank-you-email v-if="!project.emailSettings.thankYou.emailsSentAt" />
                    <div v-else>
                        <p class="mb-1 mt-0">
                            The emails were sent out at: {{ mailsSentOutAt }}
                        </p>
                        <p class="my-1">
                            Number of emails sent: {{ nrOfEmailsSent }}
                        </p>
                        <p class="grey--text my-1">
                            (it's calculated in the background, please refresh)
                        </p>
                    </div>
                </v-col>
            </v-row>
        </v-col>
        <v-divider
            vertical
        />
    </v-row>
</template>

<script>
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import SendThankYouEmail from '@/components/controls/SendThankYouEmail';
    import { mapGetters, mapMutations, mapState } from 'vuex';
    import { AUTOSAVE_PROJECT_URL, populateUrl } from '@/services/ApiService';
    import TimeService from '@/services/TimeService';
    import { $http } from '@/services/http';

    export default {
        name: 'ThankYouMailing',
        components: { RichTextEditor, SendThankYouEmail },
        data() {
            return {
                edit: false,
                thankYouMailSubject: '',
                thankYouMailBody:''
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['currentProjectId', 'isHybridProject']),
            mailsSentOutAt: function (){
                if(!this.project.emailSettings.thankYou.emailsSentAt){
                    return null;
                }
                const date = TimeService.dateFromUnixTimestamp(this.project.emailSettings.thankYou.emailsSentAt);
                return `${TimeService.toDateString(date)} ${TimeService.toTimeString(date)}`;
            },
            nrOfEmailsSent: function (){
                return this.project.emailSettings.thankYou.nrOfEmailsSent;
            }
        },
        watch:{
            'project.emailSettings.thankYou.subject': {
                immediate:true,
                handler(value){
                    this.thankYouMailSubject = value;
                }
            },
            'project.emailSettings.thankYou.bodyText': {
                immediate:true,
                handler(value){
                    this.thankYouMailBody = value;
                }
            }
        },
        methods: {
            ...mapMutations('projects', ['setThankYouBody', 'setThankYouSubject']),
            editEmail(){
                this.edit = true;
            },
            async saveAndRefresh(){
                this.edit = false;
                this.setThankYouSubject(this.thankYouMailSubject);
                this.setThankYouBody(this.thankYouMailBody);
                var url = populateUrl(AUTOSAVE_PROJECT_URL, this.currentProjectId);
                await $http.put(url, {
                    'emailSettings.thankYou.subject' : this.thankYouMailSubject,
                    'emailSettings.thankYou.bodyText': this.thankYouMailBody,
                });
                this.$emit('refreshPreview');
            }
        }
    };
</script>
<style scoped>
.text-input {
  width: 22rem;
}
</style>
