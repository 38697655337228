<template>
    <div class="download-container mx-3 text-right">
        <v-btn text
               color="#666666"
               class="font-weight-regular font-16"
               :loading="exportIsLoading"
               :disabled="exportIsLoading"
               @click="downloadInsights"
        >
            Download insights
            <v-icon class="ml-2">
                mdi-file-download-outline
            </v-icon>
        </v-btn>
    </div>
</template>
<script>
    import { makeUrl } from '@/services/ApiService';
    import { mapGetters } from 'vuex';
    import { $http } from '@/services/http';

    export default {
        name: 'InsightsStatisticsExport',
        props: {
            selectedEvent: {
                type: Object,
                default: () => {}
            }
        },
        data() {
            return {
                exportIsLoading: false,
            };
        },
        computed: {
            ...mapGetters('projects', ['currentProjectId', 'isHybridProject', 'isSingleSession']),
            url() {
                if (this.isHybridProject) {
                    return makeUrl(
                        '/projects/{project_id}/stats/insights-hybrid',
                        {projectId: this.currentProjectId}
                    );
                }

                if (this.isSingleSession) {
                    return makeUrl(
                        '/projects/{project_id}/stats/insights',
                        {projectId: this.currentProjectId}
                    );
                }

                return makeUrl(
                    '/projects/{event_id}/stats/insights-per-event',
                    {eventId: this.selectedEvent._id}
                );
            },
            fileName() {
                if (this.isSingleSession) {
                    return 'insights-' + this.currentProjectId + '.xlsx';
                } else {
                    return 'insights-' + this.selectedEvent._id + '.xlsx';
                }
            }
        },
        methods: {
            async downloadInsights() {

                this.exportIsLoading = true;

                const {data} = await $http({
                    url: this.url,
                    method: 'GET',
                    responseType: 'blob'
                });

                const blobUrl = window.URL.createObjectURL(new Blob([data]));
                const hiddenElement = document.createElement('a');
                hiddenElement.download = this.fileName;
                hiddenElement.href = blobUrl;
                hiddenElement.click();
                setTimeout(() => {
                    this.exportIsLoading = false;
                }, 300);
            }
        }
    };
</script>

<style scoped>

</style>