class ThankYouEmailSettings {
    constructor(data) {
        this.subject = data.subject || '';
        this.bodyText = data.bodyText || '';
        this.emailsSentAt = data.emailsSentAt || null;
        this.nrOfEmailsSent = data.nrOfEmailsSent || 0;
    }
}

export default ThankYouEmailSettings;
