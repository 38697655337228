<template>
    <div>
        <v-row class="tabs sticky-submenu">
            <v-col
                cols="12"
                lg="9"
                md="9"
                sm="9"
                class="pt-0 pr-0 col-height"
            >
                <v-tabs v-model="tab"
                        class="mb-6 "
                >
                    <v-tab v-if="canShowOnSiteContent"
                           class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#onSiteHomescreenTab"
                    >
                        On-site Homescreen
                    </v-tab>
                    <v-tab class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#waitingTab"
                    >
                        Waiting
                    </v-tab>
                    <v-tab class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#liveTab"
                    >
                        Live
                    </v-tab>
                    <v-tab class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#endTab"
                    >
                        End
                    </v-tab>
                    <v-tab class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#onDemandTab"
                    >
                        On demand
                    </v-tab>
                </v-tabs>
            </v-col>
            <v-col cols="12"
                   lg="3"
                   md="3"
                   sm="3"
                   class="pl-0 pt-0 col-height"
            >
                <v-row justify="end"
                       class="ml-0 pl-0 "
                >
                    <live-director-button />
                </v-row>
            </v-col>
        </v-row>
        <v-row>
            <v-col
                v-if="showEventSelector"
                lg="2"
                md="2"
                sm="12"
                class="event-selector-col"
            >
                <event-selector v-model="selectedEvent" />
            </v-col>
            <v-col
                :lg="tabContentColumnSize"
                :md="tabContentColumnSize"
                sm="12"
            >
                <v-tabs-items
                    :value="tab"
                >
                    <v-tab-item v-if="canShowOnSiteContent"
                                :transition="false"
                                :reverse-transition="false"
                                class="tab-content"
                                :class="{'with-event-selector': showEventSelector}"
                                value="onSiteHomescreenTab"
                    >
                        <on-site-homescreen-tab />
                    </v-tab-item>
                    <v-tab-item :transition="false"
                                :reverse-transition="false"
                                value="waitingTab"
                    >
                        <waiting-event-tab v-if="tab === 'waitingTab'" />
                    </v-tab-item>
                    <v-tab-item :transition="false"
                                :reverse-transition="false"
                                value="liveTab"
                                class="tab-content"
                                :class="{'with-event-selector': showEventSelector}"
                    >
                        <live-event-tab v-if="tab === 'liveTab'"
                                        :key="selectedEvent._id"
                                        :event="selectedEvent"
                        />
                    </v-tab-item>
                    <v-tab-item :transition="false"
                                :reverse-transition="false"
                                class="tab-content"
                                value="endTab"
                    >
                        <end-event-tab v-if="tab === 'endTab'" />
                    </v-tab-item>
                    <v-tab-item :transition="false"
                                :reverse-transition="false"
                                class="tab-content"
                                :class="{'with-event-selector': showEventSelector}"
                                value="onDemandTab"
                    >
                        <ondemand-tab v-if="tab === 'onDemandTab'"
                                      :key="selectedEvent._id"
                                      :event="selectedEvent"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import EventSelector from './EventTabs/EventSelector';
    import WaitingEventTab from './EventTabs/WaitingEventTab';
    import LiveEventTab from './EventTabs/LiveEventTab';
    import EndEventTab from './EventTabs/EndEventTab';
    import OndemandTab from './EventTabs/OndemandTab';
    import OnSiteHomescreenTab from './EventTabs/OnSiteHomescreenTab';
    import LiveDirectorButton from '@/components/pages/Project/Elements/LiveDirectorButton';

    export default {
        name: 'ProjectEvents',
        components: {
            LiveDirectorButton,
            EventSelector,
            WaitingEventTab,
            LiveEventTab,
            EndEventTab,
            OndemandTab,
            OnSiteHomescreenTab,
        },
        data() {
            return {
                tab: 'waitingTab',
                selectedEvent: null,
            };
        },
        computed: {
            ...mapGetters('projects', [
                'currentProjectId',
                'isMultiSession',
                'isHybridProject'
            ]),
            ...mapState('projects', ['project', 'previewLoading']),
            showEventSelector() {
                // show only in live & ondemand tabs
                return this.isMultiSession && ['liveTab','onDemandTab'].indexOf(this.tab) !== -1;
            },
            tabContentColumnSize(){
                return this.showEventSelector ? 10 : 12;
            },
            page(){
                return this.obj[this.tab];
            },
            canShowOnSiteContent(){
                return this.isHybridProject;
            }
        },
        watch: {
            tab() {
                window.scrollTo({top: 0, left: 0});
            },
        },
        created() {
            this.selectedEvent = this.project.events[0];
        }
    };
</script>

<style scoped lang="scss">
.tabs {
    border-bottom: 4px solid;
    border-color: #f5f5f5;
    border-top: 20px solid white;
    background-color: white;

}
.tab-title {
    border-right: 2px solid;
    border-color: #f5f5f5;
}

.col-height{
    height: 48px !important
}

.event-selector-col{
    border-right: 4px solid;
    border-color: #f5f5f5;
    padding-top: 50px;
}

.tab-content{
    padding: 40px 12vw;
    min-height: 70vh;
    &.with-event-selector{
        padding: 40px 5vw;
    }
}
</style>
