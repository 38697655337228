var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('draggable',{staticClass:"field-dragger",attrs:{"ghost-class":"ghost","handle":".drag-handle","options":{disabled : _vm.disabled}},model:{value:(_vm.fields),callback:function ($$v) {_vm.fields=$$v},expression:"fields"}},_vm._l((_vm.fields),function(field){return _c('v-card',{key:("field-" + (field.id)),staticClass:"item-row pt-md-2 mt-md-2 mb-2",class:{
                'field-error':
                    _vm.fieldValidationResult.field &&
                    _vm.fieldValidationResult.field === field,
                'field-active': _vm.isActiveField(field),
            },attrs:{"elevation":"1"},on:{"click":function($event){return _vm.activateField(field)}}},[_c('v-card-text',{staticClass:"py-0"},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"5","sm":"6"}},[_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"flex-grow-0 drag-handle"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-4 mt-2",attrs:{"color":"grey"}},on),[_vm._v(" drag_handle ")])]}}],null,true)},[_c('span',[_vm._v("Drag to reorder")])])],1),_c('div',{staticClass:"flex-grow-1"},[_c('v-text-field',{staticClass:"mt-0",attrs:{"hide-details":"","disabled":_vm.disabled},on:{"input":_vm.validateFieldsDebounced},model:{value:(field.label),callback:function ($$v) {_vm.$set(field, "label", $$v)},expression:"field.label"}})],1)])]),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12","lg":"4","sm":"6"}},[_c('v-select',{staticClass:"mt-0",attrs:{"items":_vm.fieldTypeOptions(field.name),"item-text":"label","item-value":"value","hide-details":"","disabled":_vm.disabled || _vm.isFixedField(field) || _vm.isTypeFixed(field)},on:{"input":function($event){return _vm.validateFieldsDebounced(field)}},model:{value:(field.type),callback:function ($$v) {_vm.$set(field, "type", $$v)},expression:"field.type"}})],1),_c('v-col',{staticClass:"pt-2 pb-2 pl-3 pr-0 text-center",attrs:{"cols":"2","lg":"1","sm":"1"}},[(_vm.fieldHasOptions(field))?_c('v-btn',{attrs:{"icon":"","disabled":_vm.disabled},on:{"click":function($event){return _vm.editFieldOptions(field)}}},[(_vm.optionsOpen === field.id)?_c('v-icon',[_vm._v(" mdi-chevron-up ")]):_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1):_vm._e()],1),_c('v-col',{staticClass:"pt-3 pb-2 pr-0 mr-0 d-flex justify-center",attrs:{"cols":"10","lg":"1","sm":"1"}},[_c('v-switch',{staticClass:"mt-0 mr-0 pr-0 required",attrs:{"disabled":_vm.isFixedField(field) || _vm.disabled,"hide-details":""},model:{value:(field.required),callback:function ($$v) {_vm.$set(field, "required", $$v)},expression:"field.required"}})],1),_c('v-col',{staticClass:"pt-2 pb-2 text-end",attrs:{"cols":"2","lg":"1","sm":"1"}},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mt-0",attrs:{"icon":"","color":"error","disabled":_vm.isFixedField(field) || _vm.disabled},on:{"click":function($event){return _vm.deleteField(field)}}},on),[_c('v-icon',[_vm._v("delete")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)],1),(
                        _vm.fieldHasOptions(field) && _vm.optionsOpen === field.id
                    )?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"8","offset":"2"}},[_c('FieldOptionEditorCard',{attrs:{"field":_vm.editedField,"disabled":_vm.disabled},on:{"confirm":_vm.updateField},model:{value:(_vm.optionEditor),callback:function ($$v) {_vm.optionEditor=$$v},expression:"optionEditor"}})],1)],1):_vm._e()],1)],1)}),1),_c('v-row',[(!_vm.fieldValidationResult.valid)?_c('div',{staticClass:"mx-4"},[(_vm.fieldValidationResult.reason === _vm.fieldOptionDuplicate)?_c('div',{staticClass:"error--text"},[_vm._v(" There are fields with duplicate options ")]):_vm._e(),(_vm.fieldValidationResult.reason === _vm.fieldOptionNone)?_c('div',{staticClass:"error--text"},[_vm._v(" There are fields with no options ")]):_vm._e(),(_vm.fieldValidationResult.reason === _vm.fieldNoLabel)?_c('div',{staticClass:"error--text"},[_vm._v(" There are fields with no label ")]):_vm._e()]):_vm._e()]),_c('FieldOptionDeleteModal',{attrs:{"field":_vm.fieldToDelete},on:{"confirm":_vm.confirmDeleteField,"cancel":_vm.closeDeleteModal},model:{value:(_vm.deleteModal),callback:function ($$v) {_vm.deleteModal=$$v},expression:"deleteModal"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }