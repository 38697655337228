<template>
    <div v-if="show"
         class="d-flex"
    >
        <h3 class="font-weight-bold pt-2 mr-2 text-right">
            Public link:
        </h3>
        <h3 class="font-weight-regular mr-1 pt-2 text-right">
            <a
                id="mylink"
                class="text-decoration-none"
                target="_blank"
                :href="urlToShow"
            >
                Open in new tab
            </a>
        </h3>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-btn icon
                       class="mr-1"
                       @click="copyURL"
                       v-on="on"
                >
                    <v-icon>mdi-content-copy</v-icon>
                </v-btn>
            </template>
            <span>Copy link</span>
        </v-tooltip>
        <v-tooltip top>
            <template v-slot:activator="{ on }">
                <v-btn icon
                       v-on="on"
                >
                    <v-icon>info</v-icon>
                </v-btn>
            </template>
            <span>This is the public link you can share with your audience.</span>
        </v-tooltip>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import {
        REGISTRATION_TYPE_SPRINGER,
        REGISTRATION_TYPE_REGISTRATION,
        REGISTRATION_TYPE_OPEN,
    } from '@/services/RegistrationService';

    export default {
        name: 'PublicLink',
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['registrationType']),
            show() {
                return (
                    [
                        REGISTRATION_TYPE_OPEN,
                        REGISTRATION_TYPE_REGISTRATION,
                        REGISTRATION_TYPE_SPRINGER,
                    ].indexOf(this.registrationType) !== -1
                );
            },
            urlToShow() {
                const eventID = this.project.events[0]._id;
                switch (this.registrationType) {
                case REGISTRATION_TYPE_SPRINGER:
                    return process.env.VUE_APP_FRONTEND_SPRINGER_AUTH_URL.replace(
                        '{eventID}',
                        eventID
                    );
                case REGISTRATION_TYPE_REGISTRATION:{
                    return process.env.VUE_APP_FRONTEND_REGISTRATION_PAGE_URL.replace(
                        '{eventID}',
                        eventID
                    );
                }
                case REGISTRATION_TYPE_OPEN:
                    return process.env.VUE_APP_FRONTEND_EVENT_PAGE_URL.replace(
                        '{eventID}',
                        eventID
                    ).replace('{registrationID}', 'gm84cf8a74');
                }
                return null; // stupid way of disabling IDE warning
            },
        },
        methods: {
            copyURL() {
                let input = document.createElement('input');
                input.style.opacity = '0';
                input.style['pointer-events'] = 'none';
                document.body.appendChild(input);
                input.value = this.urlToShow;
                input.select();
                document.execCommand('copy');
            },
        },
    };
</script>