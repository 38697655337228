<template>
    <v-main class="quadia-live-admin grey lighten-4">
        <router-view />
    </v-main>
</template>

<script>


    export default {
        name: 'PreviewLayout',
        mounted(){
            document.querySelector('html').classList.add('preview');
        }
    };
</script>

<style>
html.preview{
    overflow: hidden;
}
</style>