<template>
    <div>
        <h3 class="mb-2 font-weight-bold">
            Tab order editor
        </h3>
        <v-divider class="mb-10" />
        <draggable
            v-model="tabOrderList"
            class="field-dragger tab-wrapper px-4"
            ghost-class="ghost"
            handle=".drag-handle"
            :options="{disabled : project.isArchived}"
        >
            <div v-for="tab in tabOrderList"
                 :key="tab"
                 class="align-center mb-4"
                 :class="{'d-none': !showTab(tab), 'd-flex': showTab(tab)}"
            >
                <v-card 
                    class="flex-grow-1 tab-list-item"
                >
                    <v-tooltip top>
                        <template v-slot:activator="{ on }">
                            <v-card-title class="d-flex justify-center align-center px-0 py-1 drag-handle"
                                          style="cursor: pointer"
                                          v-on="on"
                            >
                                <v-icon
                                    color="grey"
                                    class="drag-handle"
                                    style="cursor: pointer"
                                >
                                    drag_handle
                                </v-icon>
                            </v-card-title>
                        </template>
                        <span>Drag to reorder</span>
                    </v-tooltip>
                    <v-card-text class="d-flex flex-column align-center px-0 py-2 drag-handle"
                                 style="cursor: pointer"
                    >
                        <div class="d-flex align-center">
                            <v-icon class="mr-2">
                                {{ tab === 'custom' ? customTabIcon : icons[tab] }}
                            </v-icon>
                            <span class="text-capitalize">
                                {{ tab }}
                            </span>
                        </div>
                    </v-card-text>
                </v-card>
            </div>
        </draggable>
    </div>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import draggable from 'vuedraggable';
    const defaultTabOrder = ['livecontent', 'agenda', 'program', 'speakers', 'question', 'poll', 'chat', 'handout', 'quiz', 'custom'];
    export default {
        name: 'TabOrderEditor',
        props: {
            event: {
                type: Object,
                required: true
            },
        },
        components: {draggable},
        data(){
            return {
                icons:{
                    livecontent: 'mdi-information-outline',
                    agenda: 'mdi-calendar-outline',
                    program: 'mdi-calendar-outline',
                    speakers: 'mdi-account-tie-voice-outline',
                    question: 'mdi-help',
                    poll: 'mdi-chart-box-outline',
                    chat: 'mdi-message-text-outline',
                    handout: 'mdi-download-outline',
                    quiz: 'mdi-star-box-multiple-outline',
                }
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['isMultiSession']),
            tabOrderList: {
                get() {
                    if (this.event.tabOrder.length === 0) {
                        return defaultTabOrder;
                    }

                    let tabOrders = [...this.event.tabOrder];

                    for (let x = 0; x < defaultTabOrder.length; x++) {
                        if (tabOrders.indexOf(defaultTabOrder[x]) < 0) {
                            tabOrders.splice(x, 0, defaultTabOrder[x]);
                        }
                    }

                    return tabOrders;
                },
                async set(value) {
                    this.setEventTabOrder({eventID: this.event._id, value});
                    await this.saveEvent({
                        id: this.event._id,
                        key: 'tabOrder',
                        value: value,
                    });
                }
            },
            customTabIcon(){
                return this.project.customTab.icon;
            }

        },
        methods:{
            ...mapActions('projects', ['saveEvent']),
            ...mapMutations('projects', ['setEventTabOrder']),
            showTab(tab){
                if(tab === 'program' && (this.isMultiSession || !this.project.showProgramTab)){
                    return false;
                }
                if(tab === 'agenda' && !this.isMultiSession){
                    return false;
                }
                return true;
            }
        }
    };
</script>

<style scoped lang="scss">
.tab-wrapper{
    display: grid;
    gap: 10px;
    grid-auto-flow: column;
    grid-auto-columns: 1fr;
}

.d-none{
    display: none;
}
</style>