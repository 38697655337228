import { $http } from '@/services/http';
import { buildSimpleQueryString, PROJECT_EVENT_LISTING_URL } from '@/services/ApiService';

const PAGE_SIZE = 25;

function createListingData(sortingDirection, pageSize) {
    return {
        items: [],
        pagination: {
            current: 1,
            total: 1,
            pageSize: pageSize || PAGE_SIZE,
            itemCount: 0,
        },
        headers: {
            sorting: {
                targetId: 'startTime',
                direction: sortingDirection || 'asc'
            },
            filtering: {
                openPopup: '',
                filters: {},
                subFilters: {}
            },
            filtersLoading: false,
        },
        loading: false
    };
}

const projectListingStore = {
    namespaced: true,
    state() {
        return {
            listings: {
                upcoming: createListingData('asc', 1000),
                passed: createListingData('desc'),
            }
        };
    },
    getters: {
        listingHeader: (state) => (part) => state.listings[part].headers,
        pagination: (state) => (part) => state.listings[part].pagination,
        items: (state) => (part) => state.listings[part].items,
        filtersLoading: (state) => (part) => state.listings[part].headers.filtersLoading,
    },
    mutations: {
        setItems: (state, payload) => state.listings[payload.listing].items = payload.value,
        setCurrentPage: (state, payload) => state.listings[payload.listing].pagination.current = parseInt(payload.value),
        setPageTotal: (state, payload) => state.listings[payload.listing].pagination.total = parseInt(payload.value),
        setPageSize: (state, payload) => state.listings[payload.listing].pagination.pageSize = parseInt(payload.value),
        setItemCount: (state, payload) => state.listings[payload.listing].pagination.itemCount = parseInt(payload.value),
        setSortingTargetId: (state, payload) => state.listings[payload.listing].headers.sorting.targetId = payload.value,
        setSortingDirection: (state, payload) => state.listings[payload.listing].headers.sorting.direction = payload.value,
        setHeaderOpenPopup: (state, payload) => state.listings[payload.listing].headers.filtering.openPopup = payload.value,
        setHeaderFilter: (state, payload) => state.listings[payload.listing].headers.filtering.filters = { ...state.listings[payload.listing].headers.filtering.filters, [payload.value.id]: payload.value.items },
        setHeaderSubFilter: (state, payload) => state.listings[payload.listing].headers.filtering.subFilters = { ...state.listings[payload.listing].headers.filtering.subFilters, [payload.value.id]: payload.value.content },
        setFiltersLoading: (state, payload) => state.listings[payload.listing].headers.filtersLoading = payload.value,
    },
    actions: {
        getItems: ({commit}, payload) => {
            const url = buildSimpleQueryString(PROJECT_EVENT_LISTING_URL, payload.value);

            $http.get(url)
                .then(response => response.data)
                .then((data) => {
                    commit('setItems', {
                        listing: payload.listing,
                        value: data.projects
                    });
                    commit('setCurrentPage', {
                        listing: payload.listing,
                        value: data.pagination.current
                    });
                    commit('setPageTotal', {
                        listing: payload.listing,
                        value: data.pagination.total
                    });
                    commit('setPageSize', {
                        listing: payload.listing,
                        value: data.pagination?.pageSize || PAGE_SIZE
                    });
                    commit('setItemCount', {
                        listing: payload.listing,
                        value: data.pagination?.itemCount || 0
                    });
                });
        }
    }
};

export default projectListingStore;