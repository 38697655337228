<template>
    <v-tooltip
        bottom
    >
        <template v-slot:activator="{on}">
            <v-btn
                ref="button"
                icon
                small
                class="filter-button pb-5"
                :class="{'is-open': isOpen, 'is-filtered' : isFiltered}"
                @click="openPopup"
                v-on="on"
            >
                <v-icon dark
                        small
                >
                    mdi-filter-variant
                </v-icon>
            </v-btn>
        </template>
        <span>{{ tooltip }}</span>
    </v-tooltip>
</template>
<script>
    export default {
        name: 'ListFilterButton',
        props: {
            tooltip: {
                type: String,
                default: 'Filter',
            },
            isOpen: {
                type: Boolean,
                default: false,
            },
            isFiltered: {
                type: Number,
                default: 0,
            }
        },
        methods: {
            openPopup() {
                this.$emit('input');
            },
        }
    };
</script>

<style scoped>

</style>