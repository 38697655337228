<template>
    <div>
        <div class="list-wrapper mb-3">
            <topic-list-header class="topic-header" />
            <div v-for="(topic, index) in topics"
                 :key="`topic-${topic._id}`"
            >
                <v-card elevation="0"
                        rounded="0"
                        class="px-4"
                        :class="{'topic': (index+1 !== topics.length) }"
                >
                    <topic-list-item>
                        <template v-slot:title>
                            {{ topic.title }}
                        </template>
                        <template v-slot:description>
                            {{ topic.description }}
                        </template>
                        <template v-slot:startTime>
                            {{ topic.startTimeFormatted }}
                        </template>
                        <template v-slot:duration>
                            {{ topic.durationInMinutes + ' min' }}
                        </template>
                        <template v-slot:actions>
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        icon
                                        x-small
                                        v-on="on"
                                        @click="editTopic(topic)"
                                    >
                                        <v-icon size="20">
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-btn icon
                                           x-small
                                           v-on="on"
                                           @click="initiateDelete(topic)"
                                    >
                                        <v-icon size="20">
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                        </template>
                    </topic-list-item>
                </v-card>
            </div>
        </div>
        <v-btn color="primary"
               outlined
               :disabled="project.isArchived"
               @click="createTopic"
        >
            <v-icon class="mr-3">
                mdi-plus
            </v-icon>
            Topic
        </v-btn>
        <topic-dialog v-if="showTopicsModal"
                      v-model="showTopicsModal"
                      :topic="selectedTopic"
                      @addTopic="addTopic"
                      @updateTopic="updateTopic"
        />
        <delete-dialog
            v-if="showDeleteDialog"
            v-model="showDeleteDialog"
            @confirm="deleteTopic"
        >
            <template v-slot:title>
                This will delete the selected topic.
            </template>
            <template v-slot:content>
                <p class="mt-4">
                    This operation cannot be undone. Are you sure?
                </p>
            </template>
        </delete-dialog>
    </div>
</template>

<script>
    import TopicListHeader from '@/components/pages/Project/TopicList/TopicListHeader';
    import TopicListItem from '@/components/pages/Project/TopicList/TopicListItem';
    import TopicDialog from '@/components/pages/Project/TopicList/TopicDialog';
    import DeleteDialog from '@/components/controls/dialogs/DeleteDialog';
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'TopicList',
        components: {TopicListHeader, TopicListItem, TopicDialog, DeleteDialog},
        data(){
            return {
                selectedTopic: null,
                showTopicsModal: false,
                showDeleteDialog: false
            };
        },
        computed:{
            ...mapState('projects', ['project']),
            topics:{
                get(){
                    return this.project?.topics || [];
                },
                async set(value){
                    const sortedTopics = value.sort((a,b) => a.startTime - b.startTime);
                    this.setTopics(sortedTopics);
                    await this.saveProject({
                        key: 'topics',
                        value: sortedTopics,
                    });
                }
            }
        },
        methods:{
            ...mapMutations('projects', ['setTopics']),
            ...mapActions('projects', ['saveProject']),
            createTopic() {
                this.selectedTopic = null;
                this.showTopicsModal = true;
            },
            editTopic(topic){
                this.selectedTopic = topic,
                this.showTopicsModal = true;
            },
            addTopic(topic){
                this.topics = [...this.topics, topic];
            },
            updateTopic(topic){
                this.topics = this.topics.map(item => 
                    item._id === topic._id ? topic : item
                );
            },
            initiateDelete(topic){
                this.selectedTopic = topic;
                this.showDeleteDialog = true;
            },
            deleteTopic(){
                this.topics = this.topics.filter( (e) => e._id !== this.selectedTopic._id);
            }
        }

    };
</script>
<style lang="scss" scoped>
.list-wrapper{
    border: 1px solid #ccc  !important;
    border-radius: 2px!important;
}

.topic-header{
    border-bottom: 1.5px solid rgba(0,0,0,.35) !important;
}

.topic{
    border-bottom: 1.5px solid #ccc !important;
}
</style>
