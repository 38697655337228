<template>
    <v-row class="pt-4">
        <v-col
            :cols="isExternalEmbed ? 12 : 6"
            :class="{ 'border-right': !isExternalEmbed }"
        >
            <project-settings />
            <v-divider class="my-2" />
            <event-settings />
        </v-col>
        <v-col v-if="!isExternalEmbed"
               cols="6"
        >
            <style-settings />
            <v-divider class="my-2" />
            <pwa-settings v-if="isHybridProject" />
            <v-divider v-if="isHybridProject"
                       class="my-2"
            />            
        </v-col>
    </v-row>
</template>

<script>
    import EventSettings from '@/components/pages/Project/ProjectOverviewElements/EventSettings';
    import ProjectSettings from '@/components/pages/Project/ProjectOverviewElements/ProjectSettings';
    import StyleSettings from '@/components/pages/Project/ProjectOverviewElements/StyleSettings';
    import { mapGetters } from 'vuex';
    import PwaSettings from '@/components/pages/Project/ProjectOverviewElements/PwaSettings';

    export default {
        name: 'GeneralSettings',
        components: {
            PwaSettings,
            EventSettings,
            ProjectSettings,
            StyleSettings,
        },
        computed: {
            ...mapGetters('projects', ['isHybridProject', 'isExternalEmbed']),
        },
    };
</script>
<style scoped>
.border-right {
  border-right: 1px solid #ccc;
}
</style>
