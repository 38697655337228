<template>
    <v-row class="page-header-container grey lighten-4">
        <v-col cols="12"
               md="2"
               lg="2"
        >
            <h1 class="px-8 font-weight-regular page-header">
                All events
            </h1>
        </v-col>
        <v-col cols="12"
               md="2"
               lg="2"
        >
            <slot />
        </v-col>
        <create-project-button v-if="canCreateProject" />
    </v-row>
</template>
<script>
    import CreateProjectButton from '@/components/pages/Projects/CreateProjectButton';
    import { mapGetters } from 'vuex';
    export default {
        name: 'ProjectListTitle',
        components: {CreateProjectButton},
        computed: {
            ...mapGetters('auth', [
                'isQuadiaLiveAdmin',
                'isCompanyAdminAtAnyProductionCompany',
                'isProjectEditorAtAnyProductionCompany'
            ]),
            canCreateProject() {
                return this.isQuadiaLiveAdmin ||
                    this.isCompanyAdminAtAnyProductionCompany ||
                    this.isProjectEditorAtAnyProductionCompany;
            }
        }
    };
</script>

<style scoped>

</style>