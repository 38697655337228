import ProjectStatisticsService from '@/services/ProjectStatisticsService';

const StatisticsStore = {
  namespaced: true,
  state: {
    contentRelatedQuestions: 0,
    polls: [],
    filledInEvaluations: 0,
    filledInOnSiteEvaluations: 0,
    loading: true,
  },
  getters: {
    hasPolls(state, getters){
        return getters.pollsCount > 0 && !state.loading;
    },
    hasQuestions(state){
        return state.contentRelatedQuestions > 0 && !state.loading;
    },
    hasEvaluations(state){
        return state.filledInEvaluations > 0 && !state.loading;
    },
    hasOnSiteEvaluations(state){
        return state.filledInOnSiteEvaluations > 0 && !state.loading;
    },
    pollsCount(state){
        return state.polls.length;
    },
  },
  mutations: {
    setPolls(state, payload){
        state.polls = payload;
    },
    setQuestions(state, payload){
        state.contentRelatedQuestions = payload;
    },
    setEvaluations(state, payload){
        state.filledInEvaluations = payload;
    },
    setOnSiteEvaluations(state, payload){
        state.filledInOnSiteEvaluations = payload;
    },
    setLoading(state, payload){
        state.loading = payload;
    }
  },
  actions: {
    async getOptionalMetrics({commit}, payload){
        commit('setLoading', true);
        let promises = [];
        promises.push(ProjectStatisticsService.getStatisticsQuestionsCount(payload.eventId));
        promises.push(ProjectStatisticsService.getPolls(payload.eventId));
        promises.push(ProjectStatisticsService.getFilledInEvaluationCount(payload.projectId));
        const responses = await Promise.all(promises);
        commit('setQuestions', responses[0].content_related_questions ?? 0);
        commit('setPolls', responses[1] ?? 0);
        commit('setEvaluations', responses[2].onlineEvaluations ?? 0);
        commit('setOnSiteEvaluations', responses[2].onSiteEvaluations ?? 0);
        commit('setLoading', false);
    },

    async deleteRegistrant(c, payload) {
        return await ProjectStatisticsService.unregister(payload.projectId, payload.registrationId);
    },

    async updateRegistrant(c, payload) {
        return await ProjectStatisticsService.update(payload.projectId, payload.registrationId, payload.email);
    }
  }
};

export default StatisticsStore;
