<template>
    <div v-if="isExternalEmbed || isOpenRegistrationType"
         class="download-container mx-3 text-right"
    >
        <v-btn text
               color="#666666"
               class="font-weight-regular font-16"
               :loading="exportIsLoading"
               :disabled="exportIsLoading"
               @click="downloadOpenAttendance"
        >
            Download attendance report
            <v-icon class="ml-2">
                mdi-file-download-outline
            </v-icon>
        </v-btn>
    </div>
</template>
<script>
    import { makeUrl } from '@/services/ApiService';
    import { mapGetters } from 'vuex';
    import { $http } from '@/services/http';
    import { REGISTRATION_TYPE_OPEN } from '@/services/RegistrationService';

    export default {
        name: 'OpenAttendanceExport',
        data() {
            return {
                exportIsLoading: false,
            };
        },
        computed: {
            // including external embed check for backwards compatibility
            ...mapGetters('projects', ['currentProjectId', 'registrationType', 'isExternalEmbed']),
            isOpenRegistrationType()
            {
                return this.registrationType === REGISTRATION_TYPE_OPEN;
            }
        },
        methods: {
            async downloadOpenAttendance() {
                const url = makeUrl(
                    '/projects/{project_id}/stats/attendance-open',
                    {projectId: this.currentProjectId}
                );

                this.exportIsLoading = true;

                const {data} = await $http({
                    url,
                    method: 'GET',
                    responseType: 'blob'
                });

                const blobUrl = window.URL.createObjectURL(new Blob([data]));
                const hiddenElement = document.createElement('a');
                hiddenElement.download =
                    'attendance-report-' + this.currentProjectId + '.xlsx';
                hiddenElement.href = blobUrl;
                hiddenElement.click();
                setTimeout(() => {
                    this.exportIsLoading = false;
                }, 300);
            }
        }
    };
</script>

<style scoped>

</style>