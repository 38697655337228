<template>
    <v-container fluid>
        <v-row class="page-header-container grey lighten-4">
            <v-col cols="12"
                   md="2"
                   lg="2"
            >
                <h1 class="px-8 font-weight-regular page-header">
                    Companies
                </h1>
            </v-col>
            <v-col cols="12"
                   md="2"
                   lg="2"
            >
                <v-text-field v-model="searchText"
                              solo
                              flat
                              hide-details
                              dense
                              background-color="rgba(0,0,0,0.1)"
                              placeholder="Search for a company"
                              clearable
                />
            </v-col>
            <v-col col="12"
                   xl="2"
                   lg="8"
                   class="text-right pr-11"
            >
                <v-btn color="primary"
                       @click="showCreateCompanyModal"
                >
                    <v-icon class="mr-2">
                        add
                    </v-icon>
                    NEW COMPANY
                </v-btn>
                <v-dialog v-model="isCreateCompanyModalVisible"
                          persistent
                          scrollable
                >
                    <create-company-modal
                        v-if="isCreateCompanyModalVisible"
                        @close="closeCreateCompanyModal"
                        @saved-and-close="updateCompanyAndCloseCreateCompanyModal"
                    />
                </v-dialog>
            </v-col>
        </v-row>
        <div v-if="!loading">
            <company-list :companies="companies" />
            <v-pagination v-if="pagination.total > 1"
                          v-model="currentPage"
                          :length="pagination.total"
                          circle
                          total-visible="6"
                          class="mt-4"
                          color="secondary"
            />
        </div>
        <v-progress-linear v-else
                           indeterminate
        />
    </v-container>
</template>

<script>

    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import CompanyList from '@/components/pages/Companies/CompanyList';
    import {debounce} from 'lodash';
    import CreateCompanyModal from './Project/ProjectOverviewElements/CreateCompanyModal';

    export default {
        name: 'Companies',
        components: {CompanyList, CreateCompanyModal},
        data() {
            return {
                loading: true,
                inProgress: false,
                searchText: '',
                paginatorCurrentPageKey: 1,
                isConfirmationModalVisible: false,
                isCreateCompanyModalVisible: false
            };
        },
        watch: {
            searchText: debounce(function () {
                this.setCurrentPage(1);
                this.loadCompanies(true);
            }, 400),
            paginatorCurrentPageKey: debounce(function () {
                this.loadCompanies(false);
            }, 400),
            sortBy: debounce(function () {
                this.loadCompanies(false);
            }, 400),
            sortOrder: debounce(function () {
                this.loadCompanies(false);
            }, 400),
        },
        computed: {
            ...mapState('companies', ['companies', 'pagination', 'listingHeader']),
            ...mapGetters('auth', [
                'isQuadiaLiveAdmin',
                'isCompanyAdminAtAnyProductionCompany',
                'isProjectEditorAtAnyProductionCompany'
            ]),
            currentPage: {
                get() {
                    return this.pagination.current;
                },
                set(value) {
                    this.paginatorCurrentPageKey += 1;
                    this.setCurrentPage(value);
                }
            },
            sortBy() {
                return this.listingHeader.sorting.targetId;
            },
            sortOrder() {
                return this.listingHeader.sorting.direction;
            },
            canCreateCompany() {
                return this.isQuadiaLiveAdmin ||
                    this.isCompanyAdminAtAnyProductionCompany ||
                    this.isProjectEditorAtAnyProductionCompany;
            }
        },
        methods: {
            ...mapActions('companies', ['getCompanies']),
            ...mapMutations('companies', ['setCurrentPage', 'setPageTotal']),
            companyCreated() {
                this.loadCompanies(true);
            },
            async loadCompanies(reload) {
                this.loading = reload;
                await this.getCompanies(this.getParams());
                this.loading = false;
            },
            getParams() {
                const params = {};
                if (this.searchText) {
                    params['search'] = encodeURIComponent(this.searchText);
                }

                if (this.currentPage && this.currentPage > 1) {
                    params['page'] = this.currentPage;
                }

                if (this.pageSize) {
                    params['perPage'] = this.pageSize;
                }

                if (this.sortBy) {
                    params['sort'] = this.sortBy;
                }

                if (this.sortOrder) {
                    params['order'] = this.sortOrder;
                }

                return params;
            },
            closeCreateCompanyModal() {
                this.isCreateCompanyModalVisible = false;
            },
            showCreateCompanyModal() {
                this.isCreateCompanyModalVisible = true;
            },
            updateCompanyAndCloseCreateCompanyModal(company) {
                this.$router.push({name: 'Company', params: { company_id: company._id}});
                this.closeCreateCompanyModal();
            }
        },
        mounted() {
            this.setCurrentPage(1);
            this.loadCompanies(true);
        }
    };
</script>

<style scoped>

</style>
