<template>
    <v-col md="2">
        <router-link
            :to="{name: 'Project', params:{ 'project_id': projectEvent._id}}"
        >
            {{ projectEvent.name }}
        </router-link>
    </v-col>
</template>

<script>
    export default {
        name: 'ListProjectLink',
        props: {
            projectEvent: {
                type: Object,
                required: true
            }
        },
        computed: {
            companyName() {
                return this.projectEvent.company ? this.projectEvent.company.name : '';
            }
        }
    };
</script>

<style scoped>

</style>