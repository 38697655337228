import TimeService from '@/services/TimeService';

class ReminderEmailSettings {
    constructor(data) {
        this.reminder_24h = this.getBooleanValue(data.reminder_24h);
        this.reminder_8h = this.getBooleanValue(data.reminder_8h);
        this.reminder_1h = this.getBooleanValue(data.reminder_1h);
        this.reminder_15m = this.getBooleanValue(data.reminder_15m);
        this.reminder15mSentAt = data.reminder15mSentAt ? TimeService.dateFromUnixTimestamp(data.reminder15mSentAt) : null;
        this.reminder1hSentAt = data.reminder1hSentAt ? TimeService.dateFromUnixTimestamp(data.reminder1hSentAt) : null;
        this.reminder8hSentAt = data.reminder8hSentAt ? TimeService.dateFromUnixTimestamp(data.reminder8hSentAt) : null;
        this.reminder24hSentAt = data.reminder24hSentAt ? TimeService.dateFromUnixTimestamp(data.reminder24hSentAt) : null;
        this.subject = data.subject || '';
        this.bodyText = data.bodyText || '';
    }

    getBooleanValue(value, defaultValue = true) {
        return typeof value === 'boolean' ? value : defaultValue;
    }
}

export default ReminderEmailSettings;
