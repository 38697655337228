<template>
    <div>
        <h2 class="font-weight-regular black--text">
            Event details
        </h2>
        <v-row>
            <v-col cols="12">
                Event title:
                <div class="black--text my-2">
                    {{ value.project.webinarTitle }}
                </div>
            </v-col>
        </v-row>
        <v-divider class="mb-2" />
        <v-row>
            <v-col cols="12"
                   md="6"
                   lg="4"
            >
                Start date:
                <div class="black--text my-2">
                    {{ date.startDate }}
                </div>
            </v-col>
            <v-col cols="12"
                   md="6"
                   lg="4"
            >
                Start time:
                <div class="black--text my-2">
                    {{ date.startHour }}
                </div>
            </v-col>
            <v-col cols="12"
                   md="12"
                   lg="4"
            >
                Duration:
                <div class="black--text my-2">
                    {{ value.project.duration }}
                </div>
            </v-col>
        </v-row>
        <v-divider class="mb-2" />
        <v-row>
            <v-col cols="12"
                   md="6"
                   lg="4"
            >
                Language:
                <div class="black--text my-2">
                    {{ humanFriendlyLanguage }}
                </div>
            </v-col>
            <v-col cols="12"
                   md="6"
                   lg="4"
            >
                Registration required:
                <div class="black--text my-2">
                    <v-checkbox
                        readonly
                        :input-value="value.project.registrationRequired"
                        class="my-n2"
                    />
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import TimeService from '@/services/TimeService';
    export default {
        name: 'EventDetails',
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        project: {
                            webinarTitle: '',
                            startTime: null,
                            duration: null,
                            language: 'nlFormal',
                            registrationRequired: false,
                        },
                    };
                },
            },
        },
        computed: {
            date() {
                if (this.value.project.startTime) {
                    const date = TimeService.dateFromUnixTimestamp(
                        this.value.project.startTime
                    );
                    const startDate = TimeService.toDateString(date);
                    const startHour = TimeService.toTimeString24(date);
                    return { startDate, startHour };
                }
                return { startDate: '', startHour: '' };
            },
            humanFriendlyLanguage() {
                switch (this.value.project.language) {
                default:
                case 'nl': return 'Dutch';
                case 'nlFormal': return 'Dutch (formal)';
                case 'nlInformal': return 'Dutch (informal)';
                case 'en': return 'English';
                case 'de': return 'German';
                }
            }
        },
    };
</script>

<style scoped>
</style>
