import EvaluationField from '@/models/EvaluationField';

class Evaluation {
    constructor(data) {
        this._id = data._id;
        this.fields = data.fields.map(f => new EvaluationField(f));
    }
}

export default Evaluation;
