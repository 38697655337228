<template>
    <div class="d-flex justify-space-between">
        <v-radio-group v-model="buttonBorderRadius">
            <template v-slot:label>
                <strong>Button borders</strong>
            </template>
            <v-radio
                v-for="border in buttonBorders"
                :key="border.value"
                :disabled="project.isArchived"
                :label="border.label"
                :value="border.value"
            />
        </v-radio-group>
        <v-radio-group v-model="labelBorderRadius">
            <template v-slot:label>
                <strong>Label borders</strong>
            </template>
            <v-radio
                v-for="border in labelBorders"
                :key="border.value"
                :disabled="project.isArchived"
                :label="border.label"
                :value="border.value"
            />
        </v-radio-group>
        <v-radio-group v-model="cardBorderRadius">
            <template v-slot:label>
                <strong>Card borders</strong>
            </template>
            <v-radio
                v-for="border in cardBorders"
                :key="border.value"
                :disabled="project.isArchived"
                :label="border.label"
                :value="border.value"
            />
        </v-radio-group>
        <v-radio-group v-model="inputBorderRadius">
            <template v-slot:label>
                <strong>Input borders</strong>
            </template>
            <v-radio
                v-for="border in inputBorders"
                :key="border.value"
                :disabled="project.isArchived"
                :label="border.label"
                :value="border.value"
            />
        </v-radio-group>
    </div>
</template>

<script>
    import {mapActions, mapMutations, mapState} from 'vuex';
    export default {
        name: 'BorderSelector',
        data(){
            return {
                buttonBorders: [
                    {
                        label: 'non-rounded',
                        value: 0,
                    },
                    {
                        label: 'rounded',
                        value: 4,
                    },
                    {
                        label: 'extra-rounded',
                        value: 25,
                    },
                ],
                labelBorders: [
                    {
                        label: 'non-rounded',
                        value: 0,
                    },
                    {
                        label: 'rounded',
                        value: 4,
                    },
                    {
                        label: 'extra-rounded',
                        value: 20,
                    },
                ],
                cardBorders: [
                    {
                        label: 'non-rounded',
                        value: 0,
                    },
                    {
                        label: 'rounded',
                        value: 4,
                    },
                    {
                        label: 'extra-rouded',
                        value: 20,
                    },
                ],
                inputBorders: [
                    {
                        label: 'non-rounded',
                        value: 0,
                    },
                    {
                        label: 'rounded',
                        value: 4,
                    },
                    {
                        label: 'extra-rounded',
                        value: 25,
                    },
                ],
            };
        },
        computed:{
            ...mapState('projects', ['project']),
            buttonBorderRadius: {
                get(){
                    return this.project.style.buttonBorderRadius;
                },
                set(value){
                    this.setButtonBorderRadius(value);
                    this.saveProject({
                        key: 'style.buttonBorderRadius',
                        value,
                    });
                }
            },
            labelBorderRadius: {
                get(){
                    return this.project.style.labelBorderRadius;
                },
                set(value){
                    this.setLabelBorderRadius(value);
                    this.saveProject({
                        key: 'style.labelBorderRadius',
                        value,
                    });
                }
            },
            cardBorderRadius: {
                get(){
                    return this.project.style.cardBorderRadius;
                },
                set(value){
                    this.setCardBorderRadius(value);
                    this.saveProject({
                        key: 'style.cardBorderRadius',
                        value,
                    });
                }
            },
            inputBorderRadius: {
                get(){
                    return this.project.style.inputBorderRadius;
                },
                set(value){
                    this.setInputBorderRadius(value);
                    this.saveProject({
                        key: 'style.inputBorderRadius',
                        value,
                    });
                }
            }
        },
        methods:{
            ...mapActions('projects', ['saveProject']),
            ...mapMutations('projects', [
                'setButtonBorderRadius',
                'setLabelBorderRadius',
                'setCardBorderRadius',
                'setInputBorderRadius'
            ])
        }
    };
</script>
