import moment from 'moment-timezone';

const TimeService = class {
    constructor(locale) {
        this.defaultTimezone = 'Europe/Amsterdam';
        this.locale = locale || 'en-gb';
    }

    dateFromUnixTimestamp(timestamp) {
        return new Date(timestamp * 1000);
    }

    toDateString(date) {
        return date.toLocaleDateString(this.locale);
    }

    toTimeString(date) {
        return date.toLocaleTimeString(this.locale);
    }

    toTimeString24(date) {
        return date.toLocaleTimeString(this.locale, { hourCycle: 'h23', timeStyle: 'short' }).slice(0,5);
    }

    toCETTimeString24(date) {
        return date.toLocaleTimeString(this.locale, { hourCycle: 'h23', timeStyle: 'short', timeZone: 'Europe/Amsterdam' }).slice(0,5);
    }

    toFormattedISODateString(date) {
        const m = date.getMonth() + 1;
        const month = (m >= 10) ? `${m}` : `0${m}`;
        const d = date.getDate();
        const day = (d >= 10) ? `${d}` : `0${d}`;

        return `${date.getFullYear()}-${month}-${day}`;
    }

    toDMYFromISO(dateStr) {
        if (!dateStr) return null;

        const [year, month, day] = dateStr.split('-');
        return `${this.leadingZero(day)}-${this.leadingZero(month)}-${year}`;
    }

    secondsToHHMMSS(sec) {
        let hours   = Math.floor(sec / 3600);
        let minutes = Math.floor((sec - (hours * 3600)) / 60);
        let seconds = sec - (hours * 3600) - (minutes * 60);

        if (hours   < 10) {hours   = '0'+hours;}
        if (minutes < 10) {minutes = '0'+minutes;}
        if (seconds < 10) {seconds = '0'+seconds;}
        return hours+':'+minutes+':'+seconds;
    }

    leadingZero(val) {
        let result = '' + val;
        return result.length < 2 ? '0' + result : result;
    }

    fromMsToHis(time) {
        return moment.utc(time).format('HH:mm:ss');
    }

    eventStartTimeFromProjectStartTime(projectStartTime, startHour) {
        let currentStartTime = moment(projectStartTime, 'X').tz(this.defaultTimezone).format('YYYY-MM-DD');
        const hours = startHour.split(':')[0];
        const minutes = startHour.split(':')[1];
        currentStartTime = currentStartTime + ' ' + hours + ':' + minutes;
        return moment.tz(currentStartTime, this.defaultTimezone).unix();
    }

    applyDateComponent(from, to) {
        const fromTime = moment(from, 'X').tz(this.defaultTimezone);
        const fromTimeDateString = fromTime.format('YYYY-MM-DD');
        const toTime = moment(to, 'X').tz(this.defaultTimezone);
        const toTimeString = toTime.format('HH:mm');
        const result = moment.tz(`${fromTimeDateString} ${toTimeString}`, this.defaultTimezone);
        return result.unix();
    }
};

export default new TimeService();
