<template>
    <div>
        <v-row>
            <v-col md="8"
                   sm="7"
                   cols="8"
            >
                <v-text-field
                    v-model="onDemandID"
                    filled
                    class="text-input"
                    label="Ondemand ID"
                    :disabled="event.onDemandActive || project.isArchived"
                />
            </v-col>
            <v-col md="4"
                   sm="5"
                   cols="4"
            >
                <div class="mt-4 mr-3 float-left">
                    Publish
                </div>
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <div class="float-left"
                             v-on="on"
                        >
                            <v-switch
                                :key="onDemandActiveKey"
                                :input-value="event.onDemandActive"
                                :disabled="canNotActivateOndemand || !onDemandID || project.isArchived"
                                inset
                                class="pb-0 pt-0 mb-0 mt-4"
                                @click.prevent="showActivationModal"
                            />
                        </div>
                    </template>
                    <span>Toggle on demand state</span>
                </v-tooltip>
            </v-col>
        </v-row>

        <h3 class="mb-2 font-weight-bold">
            On demand page body text
        </h3>
        <v-divider class="mb-10" />
        <div class="px-4">
            <rich-text-editor
                v-model="bodyText"
                :disabled="project.isArchived"
                class="rich-text-editor"
            />
        </div>

        <v-dialog v-model="activateOnDemandModal"
                  :max-width="700"
        >
            <v-card class="on-demand-switch-dialog">
                <v-card-title>
                    <span v-if="!event.onDemandActive">
                        Are you sure you want to publish the on-demand page?
                    </span>
                    <span v-else>
                        Are you sure you want to deactivate the on-demand page?
                    </span>
                </v-card-title>
                <v-divider />
                <v-card-text class="py-4">
                    <p v-if="!event.onDemandActive">
                        This means the entire event will change to on-demand state and the live pages will be no longer available.
                    </p>
                    <p v-else>
                        This means that the on-demand page will be no longer available.
                    </p>
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn
                        color="primary"
                        @click="acceptActivationModal()"
                    >
                        <span v-if="!event.onDemandActive">
                            Publish
                        </span>
                        <span v-else>
                            Deactivate
                        </span>
                    </v-btn>
                    <v-btn @click="closeActivationModal()">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import {mapActions, mapMutations, mapState} from 'vuex';

    export default {
        name: 'OndemandTab',
        props: {
            event: {
                type: Object,
                required: true
            },
        },
        components: { RichTextEditor },
        data() {
            return {
                onDemandActiveKey: 0,
                activateOnDemandModal: false,
            };
        },
        computed: {
            ...mapState('projects', ['previewKey', 'project']),
            onDemandID: {
                get() {
                    return this.event.onDemandID;
                },
                set(value) {
                    this.setEventOnDemandID({eventID: this.event._id, value});
                    this.saveEvent({
                        id: this.event._id,
                        key: 'onDemandID',
                        value,
                    });
                },
            },
            canNotActivateOndemand() {
                if (!this.onDemandID) {
                    return false;
                }

                let time = new Date();
                return Math.round(time.getTime() / 1000) < this.event.startTime;
            },
            bodyText: {
                get() {
                    return this.event.onDemandPageContent;
                },
                async set(value) {
                    this.setEventOnDemandPageContent({eventID: this.event._id, value});
                    await this.saveEvent({
                        id: this.event._id,
                        key: 'onDemandPageContent',
                        value: value,
                    });
                    this.$emit('refreshPreview');
                },
            },
        },
        methods: {
            ...mapActions('projects', ['saveEvent']),
            ...mapMutations('projects', ['setEventOnDemandID', 'setActivateEventOnDemand', 'setEventOnDemandPageContent', 'incrementPreviewKey', 'setPreviewLoading']),
            showActivationModal() {
                this.activateOnDemandModal = true;
                return false;
            },
            closeActivationModal() {
                this.activateOnDemandModal = false;
                ++this.onDemandActiveKey;
            },
            async acceptActivationModal() {
                const value = !this.event.onDemandActive;
                this.setActivateEventOnDemand({eventID: this.event._id, value});
                await this.saveEvent({
                    id: this.event._id,
                    key: 'onDemandActive',
                    value,
                });
                this.setPreviewLoading(true);
                setTimeout(() => {
                    this.incrementPreviewKey();
                    this.setPreviewLoading(false);
                }, 7000);
                this.closeActivationModal();
            }
        },
    };
</script>

<style scoped>
.rich-text-editor{
    height: 23rem;
}

h1.smaller {
    font-size: 28px;
}
</style>

<style lang="scss">
.on-demand-switch-dialog {
    .v-card__title {
        word-break: normal !important;
    }
}
</style>
