<template>
    <generic-block-options :block="block"
                           :save-url="saveUrl"
                           :has-tab-titles="hasTabTitles"
    />
</template>

<script>
    import GenericBlockOptions from './GenericBlockOptions';

    export default {
        name: 'CountDown',
        components: {
            GenericBlockOptions,
        },
        props: {
            block: {
                type: Object,
                required: true
            },
            saveUrl: {
                type: String,
                required: true,
            },
            hasTabTitles: {
                type: Boolean,
                default: () => true,
            },
        },
    };
</script>