var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"md":"1"}},[_c('div',{staticClass:"text-right"},[(_vm.isClientAtMostForProject(_vm.projectEvent))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"black","small":"","to":{name: 'CreateStatistics', params: {'project_id': _vm.projectEvent._id}}}},on),[_c('v-icon',[_vm._v("query_stats")])],1)]}}],null,false,953865304)},[_c('span',[_vm._v("Statistics")])]):_vm._e(),(_vm.canEditProject(_vm.projectEvent))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"mr-2",attrs:{"icon":"","color":"black","small":"","to":{name: 'CreateEvent',
                             params:{ 'project_id': _vm.projectEvent._id, 'event_id': _vm.projectEvent.event._id}}}},on),[_c('v-icon',[_vm._v("edit")])],1)]}}],null,false,1811737244)},[_c('span',[_vm._v("Edit")])]):_vm._e(),(_vm.canEditProject(_vm.projectEvent))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                             var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","color":"black","small":""},on:{"click":function($event){return _vm.initArchive(_vm.projectEvent)}}},on),[_c('v-icon',[_vm._v("mdi-archive")])],1)]}}],null,false,4203177058)},[_c('span',[_vm._v("Archive")])]):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }