<template>
    <div class="evaluation-simple-cell">
        {{ value }}
    </div>
</template>

<script>
    export default {
        name: 'EvaluationSimpleCell',
        props: ['value']
    };
</script>

<style scoped>
.evaluation-simple-cell {
    min-width: 10rem;
}
</style>