<template>
    <div>
        <v-radio-group :key="selectedPreset"
                       :value="internalValue"
                       row
                       :disabled="project.isArchived"
                       @change="valueChanged"
        >
            <v-radio
                label="Basic (default)"
                value="basic"
            />
            <v-radio
                :label="layoutLabels['default']"
                value="default"
            />
        </v-radio-group>
        <confirm-modal ref="layoutConfirm">
            <template v-slot:title>
                Would you like to apply this preset ({{ layoutLabels[selectedPreset] }})?
            </template>
            <template v-slot:content>
                <p class="mt-4"
                   style="width: 650px"
                >
                    This will replace the content of the editor. Would you like to continue?
                </p>
            </template>
        </confirm-modal>
    </div>
</template>

<script>
    import ConfirmModal from '@/components/controls/dialogs/ConfirmModal';
    import { mapState } from 'vuex';
    export default {
        name: 'LayoutSelector',
        components: {ConfirmModal},
        props: {
            value: {
                type: String,
                default: 'default',
            },
            layoutLabels: {
                type: Object,
                required: true,
            }
        },
        data() {
            return {
                internalValue: this.value,
                selectedPreset: '',
            };
        },
        computed:{
            ...mapState('projects', ['project'])
        },
        methods: {
            async valueChanged(value) {
                const oldValue = this.internalValue;
                this.selectedPreset = value;
                const response = await this.$refs.layoutConfirm.open();
                if (!response) {
                    this.internalValue = oldValue;
                    this.selectedPreset = '';
                    return;
                }
                this.internalValue = this.selectedPreset;
                this.$emit('input', value);
            },
        }
    };
</script>