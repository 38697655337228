<template>
    <v-card elevation="0"
            rounded="0"
            class="px-4 breakout-group"
    >
        <v-row class="breakout-item">
            <v-col cols="8"
                   lg="8"
                   md="8"
                   class="text-left pr-0"
            >
                <h5 class="font-weight-bold">
                    {{ breakoutGroupName || 'Breakout group' }}
                </h5>
            </v-col>
            <v-col cols="4"
                   lg="4"
                   md="4"
                   class="text-end pr-0 pl-0"
            >
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-btn icon
                               x-small
                               v-on="on"
                               @click="editBreakoutGroup()"
                        >
                            <v-icon size="20">
                                mdi-pencil
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Edit breakout group (without title)</span>
                </v-tooltip>
                <v-tooltip bottom>
                    <template v-slot:activator="{on}">
                        <v-btn icon
                               :x-small="true"
                               v-on="on"
                               @click="addBreakoutToGroup()"
                        >
                            <v-icon size="20">
                                mdi-plus
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>Add new breakout to group</span>
                </v-tooltip>
            </v-col>
        </v-row>
        <draggable
            v-model="internalValue"
            class="field-dragger"
            ghost-class="ghost"
            handle=".drag-handle"
            @change="saveOrder"
        >
            <session-list-item v-for="(item) in internalValue"
                               :key="item.name"
                               class="breakout-item"
            >
                <template v-slot:type>
                    <h5 class="font-weight-regular">
                        <span class="padded session-type">
                            <event-type :event="item"
                                        drag-handle
                            />Breakout
                        </span>
                    </h5>
                </template>
                <template v-slot:startTime>
                    {{ item.startTimeFormattedTime }}
                </template>
                <template v-slot:duration>
                    {{ item.durationInMinutes + ' min' }}
                </template>
                <template v-slot:maxRegistrations>
                    {{ item.registrationsCountLimit }}
                </template>

                <template v-slot:eventName>
                    <a class="text-decoration-none"
                       target="_blank"
                       :href="getEventDashboardUrl(item._id, registrationType)"
                    >
                        {{ item.name }}
                    </a>
                </template>
                <template v-slot:actions>
                    <v-tooltip bottom>
                        <template v-slot:activator="{on}">
                            <v-btn icon
                                   :x-small="true"
                                   v-on="on"
                                   @click="initiateDelete(item)"
                            >
                                <v-icon size="20">
                                    mdi-delete
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Delete</span>
                    </v-tooltip>
                </template>
            </session-list-item>
        </draggable>
        <delete-dialog v-model="deleteBreakoutModal"
                       @confirm="deleteBreakout"
        >
            <template v-slot:title>
                This will delete the selected breakout.
            </template>
            <template v-slot:content>
                <p class="mt-4">
                    This can't be undone. Are you sure?
                </p>
            </template>
        </delete-dialog>
        <add-to-breakout-group v-if="addToBreakoutModal"
                               v-model="addToBreakoutModal"
                               @confirm="insertBreakout"
        />
        <update-breakout-group-dialog v-if="updateBreakoutGroupModal"
                                      :key="breakoutGroupName"
                                      v-model="updateBreakoutGroupModal"
                                      :breakout-group="breakoutGroup"
                                      :start-time="startTime"
                                      :breakout-group-name="breakoutGroupName"
                                      :duration-in-minutes="durationInMinutes"
                                      @saved="emitInput"
        />
    </v-card>
</template>
<script>
    import draggable from 'vuedraggable';
    import { toastError } from '@/services/responseErrors';
    import EventType from '@/components/pages/Project/Elements/EventType';
    import SessionListItem from '@/components/pages/Project/SessionList/SessionListItem';
    import DeleteDialog from '@/components/controls/dialogs/DeleteDialog';
    import AddToBreakoutGroup from '@/components/pages/Project/SessionList/AddToBreakoutGroup';
    import { makeUrl } from '@/services/ApiService';
    import { mapGetters, mapState } from 'vuex';
    import { $http } from '@/services/http';
    import { deleteEvent, getEventDashboardUrl } from '@/services/SessionService';
    import UpdateBreakoutGroupDialog from '@/components/pages/Project/Elements/UpdateBreakoutGroupDialog';

    export default {
        name: 'BreakoutGroupEditor',
        components: {
            UpdateBreakoutGroupDialog,
            AddToBreakoutGroup, DeleteDialog, SessionListItem, EventType, draggable},
        props: {
            value: {
                type: Array,
                required: true
            }
        },
        data() {
            return {
                internalValue: [...this.value],
                deleteBreakoutModal: false,
                breakoutToDelete: null,
                addToBreakoutModal: false,
                updateBreakoutGroupModal: false,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['registrationType']),
            firstBreakout() {
                return this.internalValue[0] || null;
            },
            breakoutGroupName() {
                return this.firstBreakout?.breakoutGroupName || '';
            },
            breakoutGroup() {
                return this.firstBreakout.breakout_group;
            },
            startTime() {
                return this.firstBreakout.startTime;
            },
            durationInMinutes() {
                return this.firstBreakout.durationInMinutes;
            }
        },
        methods: {
            getEventDashboardUrl,
            editBreakoutGroup() {
                this.updateBreakoutGroupModal = true;
            },
            updateInternalValue(newValue) {
                const breakouts = {};
                for (const index in newValue) {
                    const item = newValue[index];
                    breakouts[item._id] = item.name;
                }
                const firstBreakout = newValue.find(x => x !== undefined);
                const url = makeUrl('/projects/{project_id}/events/breakouts/{breakoutGroup}', {
                    projectId: this.project._id,
                    breakoutGroup: this.breakoutGroup
                });
                $http.put(url, {
                    breakoutGroupName: firstBreakout.breakoutGroupName,
                    breakouts,
                    durationInMinutes: firstBreakout.durationInMinutes,
                    startTime: firstBreakout.startTime,
                });
                this.emitInput();
            },
            addBreakoutToGroup() {
                this.addToBreakoutModal = true;
            },
            async insertBreakout(breakout) {
                const exists = this.internalValue.filter(i => i.name === breakout.name).length > 0;
                if (exists) {
                    return toastError('A breakout with this name already exists: ' + name + '.');
                }
                const item = this.internalValue.find(x => x !== undefined);
                const url = makeUrl('/projects/{project_id}/events/breakouts/{breakoutGroup}', {
                    projectId: this.project._id,
                    breakoutGroup: this.breakoutGroup,
                });

                await $http.post(url, {
                    startTime: item.startTime,
                    durationInMinutes: item.durationInMinutes,
                    name: breakout.name,
                    registrationsCountLimit: breakout.registrationsCountLimit
                });

                this.emitInput();
            },
            initiateDelete(item) {
                this.breakoutToDelete = item;
                this.deleteBreakoutModal = true;
            },
            async deleteBreakout() {
                await deleteEvent(this.project._id, this.breakoutToDelete._id);
                this.emitInput();
            },
            mapSort() {
                return this.internalValue.map((x, index) => {
                    return {
                        id: x._id,
                        order: index
                    };
                });
            },
            async saveOrder() {
                const url = makeUrl('/projects/{project_id}/events/breakouts/{breakoutGroup}/sort', {
                    projectId: this.project._id,
                    breakoutGroup: this.breakoutGroup
                });
                await $http.post(url, {
                    'sort': this.mapSort()
                });
                this.emitInput();
            },
            emitInput() {
                this.$emit('input');
            }
        }
    };
</script>

<style scoped lang="scss">
.ghost {
    border: 1px solid #2977BE;
}

.breakout-item{
    border-bottom: 1px solid rgba(0,0,0,.12);
    &:last-child {
        border-bottom: 1px solid transparent;
    }
}

.breakout-group {
    border-bottom: 1.5px solid rgba(0,0,0,.35) !important;
}

.padded {
    padding-left: 10px;
}

.session-type {
    display: flex;
}
</style>