<template>
    <div class="evaluation-date-time py-2">
        {{ value }}
    </div>
</template>

<script>
    export default {
        name: 'EvaluationDateTimeCell',
        props: ['value'],
    };
</script>

<style scoped>
    .evaluation-date-time {
        min-width: 5rem;
    }
</style>
