<template>
    <v-card elevation="2"
            class="stats-date-picker"
    >
        <v-list>
            <v-list-item-group v-model="selected"
                               :mandatory="selected > -1"
                               :color="$vuetify.theme.themes.light.primary"
            >
                <v-list-item v-for="item in list"
                             :key="item.id"
                >
                    <v-list-item-content>
                        <v-list-item-title v-text="item.text" />
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        <date-picker v-model="dates"
                     style="width: 100%;"
                     range
                     placeholder="Select date"
                     :disabled-date="disabledBeforeAndAfter"
                     @input="customDateRangeUpdated"
        />
    </v-card>
</template>

<script>
    import DatePicker from 'vue2-datepicker';
    import 'vue2-datepicker/index.css';

    export default {
        name: 'StatsDatePicker',
        components: { DatePicker },
        props: ['value', 'selection', 'minimumDate'],
        data() {
            return {
                dates: this.value,
                selected: this.selection,
                list: [
                    {
                        text: 'Today',
                        function: this.today,
                        id: 0,
                    },
                    {
                        text: 'Past week',
                        function: this.pastWeek,
                        id: 1,
                    },
                    {
                        text: 'Past Month',
                        function: this.pastMonth,
                        id: 2,
                    },
                    {
                        text: 'Past Year',
                        function: this.pastYear,
                        id: 3
                    }
                ]
            };
        },
        watch: {
            selected(value, prevValue) {
                if (value < 0) {
                    return;
                }
                if (typeof value === 'undefined') {
                    this.selected = prevValue;
                    return;
                }
                this.list[value].function();
            }
        },
        methods: {
            disabledBeforeAndAfter(date)
            {
                if (!this.minimumDate || typeof this.minimumDate.valueOf !== 'function') {
                    return false;
                }

                const lastAvailable = new Date();
                lastAvailable.setHours(23,59,59,999);

                return date.valueOf() < this.minimumDate.valueOf() || date.valueOf() > lastAvailable.valueOf();
            },
            customDateRangeUpdated(value) {
                this.selected = -1;
                this.daterangeUpdated(value);
            },
            daterangeUpdated(value) {
                this.$emit('input', value);
            },
            today() {
                const start = new Date();
                start.setHours(0,0,0,0);
                const end = new Date();
                end.setHours(23,59,59,999);
                this.dates = [start, end];
                this.daterangeUpdated(this.dates);
            },
            pastWeek() {
                const start = new Date();
                const end = new Date();
                start.setTime(start.getTime() - 7 * 24 * 3600 * 1000);
                this.dates = [start, end];
                this.daterangeUpdated(this.dates);
            },
            pastMonth() {
                const start = new Date();
                const end = new Date();
                start.setTime(start.getTime() - 31 * 24 * 3600 * 1000);
                this.dates = [start, end];
                this.daterangeUpdated(this.dates);
            },
            pastYear() {
                const start = new Date();
                const end = new Date();
                start.setTime(start.getTime() - 365 * 24 * 3600 * 1000);
                this.dates = [start, end];
                this.daterangeUpdated(this.dates);
            }
        }
    };
</script>

<style scoped>
.stats-date-picker {
    box-shadow: 0 0 3px #b3b3b3 !important;
}
</style>
