<template>
    <v-col md="1">
        {{ companyName }}
    </v-col>
</template>

<script>
    export default {
        name: 'ListCompany',
        props: {
            projectEvent: {
                type: Object,
                required: true
            }
        },
        computed: {
            companyName() {
                return this.projectEvent.company ? this.projectEvent.company.name : '';
            }
        }
    };
</script>

<style scoped>

</style>