<template>
    <v-col md="1"
           class="align-center"
    >
        {{ projectEvent.registrationsCountText }}
    </v-col>
</template>
<script>
    export default {
        name: 'ListRegistrationsCount',
        props: {
            projectEvent: {
                type: Object,
                required: true
            },
        }
    };
</script>

<style scoped>

</style>