<template>
    <favicon-picker :upload-url="uploadUrl"
                    :favicon-url="faviconUrl"
                    :favicon="favicon"
                    :disabled="project.isArchived"
                    @input="faviconChanged"
    />
</template>

<script>
    import FaviconPicker from '@/components/controls/FaviconPicker.vue';
    import { FAVICON_UPLOAD_URL } from '@/services/ApiService';
    import { mapActions, mapMutations, mapState } from 'vuex';


    export default {
        name: 'ProjectFaviconPicker',
        components: {
            FaviconPicker,
        },
        data(){
            return{
                uploading: false,
                key: 0,
                selectedFile: ''
            };
        },
        computed:{
            ...mapState('projects', ['project']),
            uploadUrl() {
                return FAVICON_UPLOAD_URL;
            },
            faviconUrl: {
                get() {
                    return this.project.style.faviconUrl;
                },
                set(value) {
                    this.setFaviconUrl(value);
                },
            },
            favicon: {
                get() {
                    return this.project.style.favicon;
                },
                set(value) {
                    this.setFavicon(value);
                    this.saveProject({
                        key: 'style.favicon',
                        value,
                    });
                },
            },
        },
        methods:{
            ...mapMutations('projects', [
                'setFavicon',
                'setFaviconUrl',
            ]),
            ...mapActions('projects', ['saveProject']),
            faviconChanged(data) {
                this.favicon = data.favicon;
                this.faviconUrl = data.faviconUrl;
            },
        }
    };
</script>

<style lang="scss">

</style>