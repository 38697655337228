<template>
    <v-card elevation="2"
            class="stats-item"
    >
        <v-card-title>
            <slot name="title" />
        </v-card-title>
        <v-divider />
        <v-card-text class="">
            <slot name="content" />
        </v-card-text>
        <v-progress-linear v-if="loading"
                           indeterminate
                           :color="$vuetify.theme.themes.light.primary"
                           style="position: absolute; bottom: 0"
        />
    </v-card>
</template>

<script>
    export default {
        name: 'StatsCard',
        props: ['loading'],
    };
</script>

<style scoped>
.stats-item {
    box-shadow: 0 0 3px #b3b3b3 !important;
  
}
.stats-item .v-card__title {
    justify-content: center !important;
    font-size: 0.9rem ;
    word-break: break-word;
    max-height: 50px;
    padding: 10px;
}

.stats-item.chart .v-card__text{
    max-height: 500px !important;
}


.stats-item .v-card__text {
    text-align: center !important;
    font-size: 1.4rem;
    line-height: 3rem;
    min-height: 100px;
    max-height: 100px;
    overflow: auto;
}

.stats-item.metric .v-card__text{
    display: grid  !important;
    align-items: center;
    padding-top: 0;
}


.stats-item.smaller .v-card__text {
    padding-top: 10px;
    font-size: 1.4rem;
}

@media (max-width: 1600px) {
    .stats-item .v-card__title {
        font-size: 0.8rem;
    }
}

@media (max-width: 1500px) {
    .stats-item .v-card__title {
        font-size: 0.68rem;
    }
    .stats-item.smaller .v-card__text {
        font-size: 1.4rem;
    }
    .stats-item .v-card__text {
      font-size: 1.4rem;
    }

}



@media (max-width: 1346px) {
   .stats-item .v-card__title {
      font-size: 0.55rem;
  }

  .stats-item .v-card__text {
      font-size: 1.5rem;
  }

  .stats-item.smaller .v-card__text {
    font-size: 1.4rem;
    }
}

@media (max-width: 1100px) {
 .stats-item .v-card__title {
    font-size: 0.55rem;
  }
  .stats-item .v-card__text {
    font-size: 1.7rem;
  }

  .stats-item.smaller .v-card__text {
    font-size: 1.3rem;
  }
}

@media (max-width: 959px) {
  .stats-item .v-card__title {
    font-size: 0.6rem;
  }

  .stats-item .v-card__text {
    font-size: 2rem;
  }

  .stats-item.smaller .v-card__text {
    font-size: 1.5rem;
  }
}

</style>
