<template>
    <div class="d-flex align-center">
        <div class="d-inline-block mr-5 pl-4">
            Require registration for on-demand
        </div>
        <v-switch v-model="requireRegistrationForOnDemand"
                  inset
        />
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'RegistrationForOnDemand',
        computed: {
            ...mapState('projects', ['project']),
            requireRegistrationForOnDemand: {
                get() {
                    return this.project.requireRegistrationForOnDemand;
                },
                set(value) {
                    this.setRequireRegistrationForOnDemand(value);
                    this.saveProject({
                        id: this.project._id,
                        key: 'requireRegistrationForOnDemand',
                        value,
                    });
                }
            }
        },
        methods: {
            ...mapActions('projects', ['saveProject']),
            ...mapMutations('projects', ['setRequireRegistrationForOnDemand']),
        }
    };
</script>

<style scoped>

</style>