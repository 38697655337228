<template>
    <stats-card class="chart">
        <template v-slot:title>
            Dropoff rate
        </template>
        <template v-slot:content>
            <div class="chart-container">
                <line-chart v-if="chartData"
                            :key="chartUpdate"
                            :chartdata="chartData"
                            :options="options"
                />
            </div>
        </template>
    </stats-card>
</template>

<script>
    import LineChart from './LineChart';
    import StatsCard from './StatsCard';
    import TimeService from '@/services/TimeService';
    import { fetchStatisticsApi } from '@/services/StatisticsService';
    import { debounce} from 'lodash';

    export default {
        name: 'DropoffRate',
        props: {
            videoId: {
                type: String,
                required: true
            },
            dateRange: {
                type: Array,
                required: true,
            }
        },
        components: {StatsCard, LineChart},
        data() {
            return {
                loading: true,
                chartData: null,
                chartUpdate: 1,
                options: {
                    responsive: true,
                    maintainAspectRatio: false,
                    legend: {
                        display: false
                    },
                    scales: {
                        xAxes: [{
                            ticks: {
                                autoSkip: true,
                                maxTicksLimit: 15,
                                display: true,
                                maxRotation: 0,
                                minRotation: 0,
                            },
                            gridLines: {
                                display: false
                            },
                            scaleLabel: {
                                display: true,
                                labelString: 'Play time'
                            }
                        }],
                        yAxes: [{
                            scaleLabel: {
                                display: true,
                                labelString: 'Views'
                            },
                            ticks: {
                                stepSize: 1
                            }
                        }]
                    },
                }
            };
        },
        methods: {
            getData() {
                fetchStatisticsApi('/api/videos/drop-off-rate-chart', this.dateRange, this.videoId).then(response => {
                    const labels = Object.keys(response.data).map(time => {
                        return TimeService.fromMsToHis(parseInt(time));
                    });
                    const viewers = Object.values(response.data);

                    this.chartData = {
                        labels: labels,
                        datasets: [{
                            label: 'Views',
                            borderColor: this.$vuetify.theme.themes.light.primary,
                            backgroundColor: 'transparent',
                            pointBackgroundColor: this.$vuetify.theme.themes.light.primary,
                            data: viewers,
                        }]
                    };
                }).finally(() => {
                    this.chartUpdate += 1;
                });
            },
            onResize() {
                const options = {...this.options};
                const width = this.$el.offsetWidth;
                let maxTicksLimit = 15;
                if (width < 750 && width >= 700) {
                    maxTicksLimit = 11;
                }
                if (width < 700 && width >= 600) {
                    maxTicksLimit = 10;
                }

                if (width < 600) {
                    maxTicksLimit = 7;
                }
                options.scales.xAxes[0].ticks.maxTicksLimit = maxTicksLimit;
                this.options = options;
                this.chartUpdate +=1;
            }
        },
        mounted() {
            window.addEventListener('resize', debounce(this.onResize) );
            this.onResize();
            this.getData();
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.onResize );
        }
    };
</script>

<style scoped>
.chart {
    height: 100%;
}
</style>
