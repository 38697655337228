<template>
    <v-row>
        <v-col class="py-0">
            <v-row>
                <v-col cols="12"
                       lg="3"
                       class="py-0"
                >
                    <custom-date-picker 
                        v-model="startDate"
                        :top="true"
                        :disabled="project.isArchived"
                    />
                </v-col>
                <v-col cols="12"
                       lg="3"
                       :class="timePickerClasses"
                >
                    <custom-time-picker
                        v-model="startDate"
                        :disabled="project.isArchived"
                    />
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="12">
                    <v-switch v-model="preventSwitchingRooms"
                              label="Prevent users from changing breakouts"
                    />
                </v-col>
            </v-row>
            <v-alert v-if="startTimeInconsistent"
                     outlined
                     color="warning"
                     dense
            >
                <v-icon color="warning">
                    warning
                </v-icon>
                <small>The project's start time doesn't match the first session's start time. Make sure they match, otherwise the registrants will have inconsistent start times displayed in the application and e-mail content.</small>
            </v-alert>

            <sessions-list />
        </v-col>
    </v-row>
</template>

<script>
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import CustomDatePicker from '@/components/controls/CustomDatePicker';
    import CustomTimePicker from '@/components/controls/CustomTimePicker';
    import SessionsList from '@/components/pages/Project/Elements/SessionsList';

    export default {
        name: 'MultiSessionEventSettings',
        components: { CustomDatePicker, CustomTimePicker, SessionsList },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['startTimeInconsistent']),
            startDate: {
                get() {
                    return this.project.startTime || 0;
                },
                async set(value) {
                    this.setProjectDateTime(value);
                    await this.saveProjectImmediate({
                        id: this.project._id,
                        key: 'startTime',
                        value: value,
                    });
                    await this.reloadProject();
                    this.reRender();
                },
            },
            preventSwitchingRooms: {
                get() {
                    return this.project.preventSwitchingRooms;
                },
                async set(value) {
                    this.setProjectSwitchingRooms(value);
                    await this.saveProjectImmediate({
                        id: this.project._id,
                        key: 'preventSwitchingRooms',
                        value: value,
                    });
                    await this.reloadProject();
                    this.reRender();
                }
            },
            timePickerClasses() {
                return {
                    'v-alert': this.startTimeInconsistent,
                    'v-alert--outlined': this.startTimeInconsistent,
                    'warning--text': this.startTimeInconsistent,
                    'v-sheet': this.startTimeInconsistent,
                    'v-sheet--outlined': this.startTimeInconsistent,
                    'theme-light': this.startTimeInconsistent,
                };
            }
        },
        methods: {
            ...mapMutations('projects', [
                'setProjectDateTime',
                'setProjectSwitchingRooms',
                'reRender',
            ]),            
            ...mapActions('projects', ['saveProjectImmediate', 'reloadProject']),
        },
    };
</script>

<style scoped>
.text-input {
    width: 22rem;
}
</style>
