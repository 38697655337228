<template>
    <div v-if="project._id"
         class="preview-container"
    >
        <v-tabs 
            v-model="tab"
            class="tabs"
        >
            <v-tab v-if="showRegistrationPages"
                   :to="{ name: 'RegistrationPreviews' }"
                   replace
                   href="#registration"
            >
                Landing pages
            </v-tab>
            <v-tab :to="{ name: 'EventPreviews' }"
                   replace
                   href="#eventPages"
            >
                Event pages
            </v-tab>
            <v-tab v-if="showMails"
                   :to="{ name: 'MailPreviews' }"
                   replace
                   href="#mails"
            >
                Mails
            </v-tab>
            <v-spacer />
            <h3 class="font-weight-regular mt-3 grey--text text--darken-2 mr-2">
                Preview mode - Use the tabs to preview your project
            </h3>
        </v-tabs>
        <v-tabs-items class="tabs-items"
                      :value="recentTab"
        >
            <v-divider />
            <v-tab-item v-if="showRegistrationPages"
                        :transition="false"
                        :reverse-transition="false"
                        value="registrations"
            >
                <preview-registration-pages />
            </v-tab-item>
            <v-tab-item :transition="false"
                        :reverse-transition="false"
                        value="eventPages"
            >
                <preview-event-pages />
            </v-tab-item>
            <v-tab-item v-if="showMails"
                        :transition="false"
                        :reverse-transition="false"
                        value="mails"
            >
                <preview-mails />
            </v-tab-item>
        </v-tabs-items>
    </div>
</template>
<script>
    import PreviewRegistrationPages from '@/components/pages/Preview/PreviewRegistrationPages';
    import PreviewEventPages from '@/components/pages/Preview/PreviewEventPages';
    import PreviewMails from '@/components/pages/Preview/PreviewMails';
    import { mapActions, mapGetters,mapState } from 'vuex';
    export default {
        name: 'Preview',
        components: {PreviewRegistrationPages, PreviewEventPages, PreviewMails},
        data(){
            return {
                tab: null,
                recentTab : 'eventPages',
            };
        },
        computed:{
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['registrationType', 'isSpringerProject']),
            showRegistrationPages(){
                return this.registrationType === 'registration';
            },
            showMails(){
                if (this.isSpringerProject && this.project.extraMailEnabled) {
                    return true;
                }
                return this.registrationType === 'registration';
            }
        },
        watch:{
            $route: {
                handler(to) {
                    if(to.path.indexOf('registration-previews') > -1) {
                        this.recentTab =  'registrations';
                    }
                    if(to.path.indexOf('event-previews') > -1) {
                        this.recentTab =  'eventPages';
                    }
                    if(to.path.indexOf('mail-previews') > -1) {
                        this.recentTab =  'mails';
                    }
                },
                immediate: true,
            },
        },
        methods:{
            ...mapActions('projects', ['getProject']),
        },
        created(){
            this.getProject(this.$route.params.project_id);
        },
        mounted(){
            if(this.$route.name === 'CreatePreview'){
                this.$router.push({name: 'EventPreviews'});
            }
        }
    };
</script>

<style scoped lang="scss">

.preview-container{
    display: flex;
    flex-direction: column;
    height: 100% !important;

    .tabs{
        flex: 0 0 auto
    }

    .tabs-items{
        flex: 1 1 0;
    }
}

</style>

<style lang="scss" >

.preview-container{
    .tabs-items{
        height: 100% !important;
        .v-window__container{
            height: 100% !important;
            .v-window-item{
                height: 100% !important;
                .preview-canvas{
                    height: 100% !important;
                    display: flex;
                    flex-direction: column;
                    .tabs{
                        flex: 0 0 auto;
                        
                    }
                    .preview-inner{
                            flex: 1 1 0;
                            height: 100% !important;
                            .iframe-row{
                                height: 100%;
                            }
                    }
                }
            }
        }
    }
}

</style>