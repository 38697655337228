<template>
    <div>
        <list-filter-selected v-if="selected.length"
                              :column-id="columnId"
                              :items="selected"
                              @input="toggleSelected"
        />
        <list-filter-quick-search v-if="items.length > listLengthLimit || subFilter"
                                  v-model="subFilter"
        />

        <div v-if="!filtersLoading">
            <list-filter-selector v-if="(subFilter && items.length > 0) || items.length <= listLengthLimit"
                                  :column-id="columnId"
                                  :selected="selected"
                                  :items="items"
                                  @input="toggleSelected"
            />
        </div>
        <v-progress-linear v-else
                           indeterminate
        />
    </div>
</template>

<script>
    import { debounce } from 'lodash';
    import { mapGetters, mapMutations, mapState } from 'vuex';
    import ListFilterSelected from '@/components/controls/list-filters/ListFilterSelected';
    import ListFilterQuickSearch from '@/components/controls/list-filters/ListFilterQuickSearch';
    import ListFilterSelector from '@/components/controls/list-filters/ListFilterSelector';

    export default {
        name: 'ListHeadFilter',
        components: {ListFilterSelector, ListFilterQuickSearch, ListFilterSelected},
        props: ['items', 'columnId'],
        data: () => ({
            listLengthLimit: 5,
        }),
        computed: {
            ...mapState('archives', ['listingHeader']),
            ...mapGetters('archives', ['filtersLoading']),
            selected: {
                get() {
                    return this.listingHeader.filtering.filters[this.columnId] || [];
                },
                set(value) {
                    this.setHeaderFilter({id: this.columnId, items: value});
                }
            },
            subFilter: {
                get() {
                    return this.listingHeader.filtering.subFilters[this.columnId] || '';
                },
                set: debounce(function (value) {
                    this.setHeaderSubFilter({id: this.columnId, content: value});
                }, 400)
            },
        },
        methods: {
            ...mapMutations('archives', ['setHeaderFilter', 'setHeaderSubFilter']),
            toggleSelected(item) {
                if (this.selected.filter(x => x.id === item.id).length > 0) {
                    this.selected = this.selected.filter(s => s.id !== item.id);
                } else {
                    this.selected = [...this.selected, item];
                }
            },
        },
        mounted() {
            this.subFilter = '';
        },
        beforeDestroy() {
            this.subFilter = '';
        }
    };
</script>
