<template>
    <v-dialog v-if="field"
              v-model="value"
              max-width="700px"
    >
        <v-card>
            <v-card-title>
                Delete field
            </v-card-title>
            <v-card-text>
                <p>Would you like to delete the following field?</p>
                <p>{{ field.name }}</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="error"
                       @click="confirmDeleteField"
                >
                    Delete
                </v-btn>
                <v-btn @click="closeDeleteModal">
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'FieldOptionDeleteModal',
        components: {},
        props: {
            value: {
                type: Boolean,
                default: false
            },
            field: {
                type: Object
            }
        },
        methods: {
            closeDeleteModal() {
                this.$emit('cancel');
            },
            confirmDeleteField() {
                this.$emit('confirm');
            },

        },
    };
</script>

<style scoped lang="scss">
</style>
