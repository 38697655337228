import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../components/pages/Home.vue';
import MainLayout from '@/components/layouts/MainLayout';
import AuthLayout from '../components/layouts/AuthLayout';
import Project from '@/components/pages/Project';
import NotFound from '@/components/layouts/NotFound';
import AuthCallback from '../components/pages/AuthCallback';
import store from '../store/index';
import Intakes from '@/components/pages/Intakes';
import Companies from '@/components/pages/Companies';
import Company from '@/components/pages/Company/CompanyOverview';
import Users from '@/components/pages/Users';
import Clients from '@/components/pages/Clients';
import Archive from '@/components/pages/Archive';
import Preview from '@/components/pages/Preview';
import PreviewLayout from '@/components/layouts/PreviewLayout';
import CreateProject from '@/components/pages/Project/CreateProject';
import { refreshWhenDeprecated } from '@/services/versioning';
import {ProjectsMeta, ArchivesMeta, IntakesMeta, UsersMeta, ClientsMeta, OverviewMeta, CompaniesMeta} from '@/router/menu-constants';
import { DEFAULT_TITLE, setTitle } from '@/services/TitleService';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: { name: 'Home' },
        component: PreviewLayout,
        children: [
        {
            path: 'projects/:project_id/:event_id/preview',
            component: Preview,
            name: 'CreatePreview',
            children: [
                {
                    path: 'registration-previews',
                    component: Preview,
                    name: 'RegistrationPreviews',
                    children:[
                        {
                            path: 'registration',
                            component: Preview,
                            name: 'RegistrationPagePreview'
                        },
                        {
                            path: 'registration-confirmation',
                            component: Preview,
                            name: 'RegistrationConfirmationPreview'
                        },
                        {
                            path: 'on-site-registration-confirmation',
                            component: Preview,
                            name: 'OnSiteRegistrationConfirmationPreview'
                        },
                    ]
                },
                {
                    path: 'event-previews',
                    component: Preview,
                    name: 'EventPreviews',
                    children:[
                        {
                            path: 'waiting',
                            component: Preview,
                            name: 'WaitingPagePreview'
                        },
                        {
                            path: 'live',
                            component: Preview,
                            name: 'LivePagePreview'
                        },
                        {
                            path: 'end',
                            component: Preview,
                            name: 'EndPagePreview'
                        },
                        {
                            path: 'on-demand',
                            component: Preview,
                            name: 'OnDemandPagePreview'
                        },
                        {
                            path: 'evaluation',
                            component: Preview,
                            name: 'EvaluationPagePreview'
                        },
                        {
                            path: 'on-site-evaluation',
                            component: Preview,
                            name: 'OnSiteEvaluationPagePreview'
                        },
                    ]
                },
                {
                    path: 'mail-previews',
                    component: Preview,
                    name: 'MailPreviews',
                    children:[
                        {
                            path: 'confirmation',
                            component: Preview,
                            name: 'ConfirmationMailPreview'
                        },
                        {
                            path: 'reminder',
                            component: Preview,
                            name: 'ReminderMailPreview'
                        },
                        {
                            path: 'thank-you',
                            component: Preview,
                            name: 'ThankYouMailPreview'
                        },
                        {
                            path: 'extra',
                            component: Preview,
                            name: 'ExtraMailPreview'
                        },
                        {
                            path: 'on-site-confirmation',
                            component: Preview,
                            name: 'OnSiteConfirmationMailPreview'
                        },
                        {
                            path: 'on-site-reminder',
                            component: Preview,
                            name: 'OnSiteReminderMailPreview'
                        },
                    ]
                },
            ]
        }]
    },
    {
        path: '/',
        redirect: { name: 'Home' },
        component: MainLayout,
        children: [
            {
                path: 'projects',
                component: Home,
                name: 'Home',
                meta: { requiresAuth: true, menu: ProjectsMeta }
            },
            {
                path: 'projects/new',
                component: CreateProject,
                name: 'CreateProject',
                meta: { requiresAuth: true, menu: ProjectsMeta }
            },
            {
                path: 'projects/:project_id/',
                component: Project,
                name: 'Project',
                meta: { requiresAuth: true, menu: OverviewMeta }
            },
            {
                path: 'projects/:project_id/overview',
                component: Project,
                name: 'CreateOverview',
                meta: { requiresAuth: true, menu: OverviewMeta }
            },

            {
                path: 'projects/:project_id/registration',
                component: Project,
                name: 'CreateRegistration',
                meta: { requiresAuth: true, menu: OverviewMeta, preventsDisabledAccess: true }
            },
            {
                path: 'projects/:project_id/mailings',
                component: Project,
                name: 'CreateMailings',
                meta: { requiresAuth: true, menu: OverviewMeta, preventsDisabledAccess: true}
            },
            {
                path: 'projects/:project_id/activity',
                component: Project,
                name: 'ActivityLog',
                meta: {requiresAuth: true, menu: OverviewMeta}
            },
            {
                path: 'projects/:project_id/:event_id/eventpage',
                component: Project,
                name: 'CreateEvent',
                meta: { requiresAuth: true, menu: OverviewMeta }
            },
            {
                path: 'projects/:project_id/evaluation-settings',
                component: Project,
                name: 'EvaluationSettings',
                meta: {requiresAuth: true, menu: OverviewMeta}
            },
            {
                path: 'projects/:project_id/statistics',
                component: Project,
                name: 'CreateStatistics',
                meta: { requiresAuth: true, menu: OverviewMeta },
                children:[
                    {
                        path: 'registrations',
                        component: Project,
                        name: 'RegistrationStatistics',
                        meta: { requiresAuth: true, menu: OverviewMeta }
                    },
                    {
                        path: 'insights',
                        component: Project,
                        name: 'InsightsStatistics',
                        meta: { requiresAuth: true, menu: OverviewMeta }
                    },
                    {
                        path: 'polls',
                        component: Project,
                        name: 'PollsStatistics',
                        meta: { requiresAuth: true, menu: OverviewMeta }
                    },
                    {
                        path: 'evaluations',
                        component: Project,
                        name: 'EvaluationStatistics',
                        meta: { requiresAuth: true, menu: OverviewMeta }
                    },
                    {
                        path: 'on-site-evaluations',
                        component: Project,
                        name: 'OnSiteEvaluationStatistics',
                        meta: { requiresAuth: true, menu: OverviewMeta }
                    },
                    {
                        path: 'ondemand',
                        component: Project,
                        name: 'OndemandStatistics',
                        meta: { requiresAuth: true, menu: OverviewMeta }
                    },
                    {
                        path: 'questions',
                        component: Project,
                        name: 'QuestionsStatistics',
                        meta: { requiresAuth: true, menu: OverviewMeta }
                    },
                    {
                        path: 'chat',
                        component: Project,
                        name: 'ChatStatistics',
                        meta: { requiresAuth: true, menu: OverviewMeta }
                    },
                    {
                        path: 'link-tracking',
                        component: Project,
                        name: 'LinkTracking',
                        meta: { requiresAuth: true, menu: OverviewMeta }
                    },
                    {
                        path: 'device-stats',
                        component: Project,
                        name: 'DeviceStats',
                        meta: { requiresAuth: true, menu: OverviewMeta }
                    },
                ]
            },

            {
                path: 'intake',
                component: Intakes,
                name: 'Intake',
                meta: { requiresAuth: true , menu: IntakesMeta}
            },
            {
                path: 'companies',
                component: Companies,
                name: 'Companies',
                meta: { requiresAuth: true, menu: CompaniesMeta }
            },
            {
                path: 'companies/:company_id/',
                component: Company,
                name: 'Company',
                meta: { requiresAuth: true, menu: CompaniesMeta }
            },
            {
                path: 'users',
                component: Users,
                name: 'Users',
                meta: { requiresAuth: true, menu: UsersMeta }
            },
            {
                path: 'users/create',
                component: Users,
                name: 'CreateUser',
                meta: { requiresAuth: true, menu: UsersMeta }
            },
            {
                path: 'users/:user_id',
                component: Users,
                name: 'User',
                meta: { requiresAuth: true, menu: UsersMeta }
            },
            {
                path: 'clients',
                component: Clients,
                name: 'Clients',
                meta: { requiresAuth: true, menu: ClientsMeta }
            },
            {
                path: 'archives',
                component: Archive,
                name: 'Archives',
                meta: { requiresAuth: true, menu: ArchivesMeta }
            }
        ]
    },
    {
        path: '/',
        component: AuthLayout,
        children: [
            { path: '/auth', name: 'AuthCallback', component: AuthCallback },
        ]
    },
    {
        path: '*',
        component: NotFound,
        name: 'NotFound'
    }
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => !!record.meta.preventsDisabledAccess) 
            && store.state.projects.project.registration.type !== 'registration'
            && store.state.projects.project.registration.type !== 'springer' ) {
            next({
            name: 'Project',
            params: {project_id: to.params.project_id}
        });
    } else {
        next();
    }
});

router.afterEach((to) => {
    if (to.meta.menu !== OverviewMeta) {
        setTitle(DEFAULT_TITLE);
    }
    refreshWhenDeprecated();
});

export default router;
