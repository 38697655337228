<template>
    <v-dialog :value="true"
              max-width="1000"
              persistent
              scrollable
    >
        <v-card>
            <v-card-title>
                <span class="mr-1">Assign freelancer project to user</span>
                <span v-if="internalUser"> {{ internalUser.email }}</span>
                <v-spacer />
                <v-btn icon
                       @click="close"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text v-if="internalUser">
                <project-selector :assigned-projects="internalUser.freelancerProjects"
                                  @assign="assign"
                                  @unassign="unAssign"
                />
            </v-card-text>
            <v-card-actions v-if="internalUser">
                <v-spacer />
                <v-btn @click="close">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import ProjectSelector from '@/components/controls/user-management/ProjectSelector';
    import User from '@/models/User';

    export default {
        name: 'AssignProjectDialog',
        components: { ProjectSelector },
        props: {
            value: {
                type: Object,
                default: () => new User()
            }
        },
        data() {
            return {
                internalUser: this.user
            };
        },
        computed: {
            ...mapState('users', ['user']),
        },
        methods: {
            ...mapActions('users', [
                'getUser',
                'getUsers',
                'unAssignUserFromFreelancerProject',
                'assignUserToFreelancerProject',
            ]),
            async assign(project) {
                await this.assignUserToFreelancerProject({
                    userId: this.user.id,
                    projectId: project.projectId,
                });
                this.user.freelancerProjects.push(project);
            },
            async unAssign(project) {
                await this.unAssignUserFromFreelancerProject({
                    userId: this.user.id,
                    projectId: project.projectId,
                });
                this.user.freelancerProjects = this.user.freelancerProjects.filter((p) => {
                    return p.projectId !== project.projectId;
                });
            },
            async close() {
                await this.getUsers();
                this.$emit('close');
            },
        },
        async mounted() {
            await(this.getUser(this.value.id));
            this.internalUser = this.user;
        }
    };
</script>
