<template>
    <div class="px-8 item-list">
        <company-list-header />
        <v-card v-for="company in companies"
                :key="`company-list-${company._id}`"
                class="mt-1 list-item"
        >
            <v-row class="px-4">
                <v-col :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'">
                    <router-link :to="{name: 'Company', params:{ 'company_id': company._id}}">
                        {{ company.name }}
                    </router-link>
                </v-col>
                <v-col v-if="isQuadiaLiveAdmin"
                       :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'"
                >
                    {{ company.productionCompany }}
                </v-col>
                <v-col :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'">
                    {{ company.createdBy ? company.createdBy.name : '' }}
                </v-col>
                <v-col :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'">
                    Last edited by name
                </v-col>
            </v-row>
        </v-card>
    </div>
</template>

<script>
    import CompanyListHeader from '@/components/pages/Companies/CompanyListHeader';
    import { mapGetters } from 'vuex';

    export default {
        name: 'CompanyList',
        components: {CompanyListHeader},
        props: {
            companies: {
                type: Array,
                default: () => []
            }
        },
        computed: {
            ...mapGetters('auth', ['isQuadiaLiveAdmin']),
        }
    };
</script>

<style scoped>

</style>
