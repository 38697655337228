<template>
    <v-main v-if="isAuthenticated"
            class="quadia-live-admin grey lighten-4"
    >
        <app-bar v-if="!isPreview" />
        <navigation-drawer v-if="!isPreview && isAuthenticated" />
        <router-view />
        <snack-bar />
        <ignore-page-views-trigger />
    </v-main>
</template>

<script>
    import AppBar from '@/components/controls/app-bar/AppBar';
    import SnackBar from '@/components/controls/SnackBar';
    import NavigationDrawer from '@/components/controls/NavigationDrawer';
    import { mapGetters } from 'vuex';
    import { $auth } from '@/services/auth';
    import IgnorePageViewsTrigger from '@/components/controls/IgnorePageViewsTrigger';

    export default {
        name: 'MainLayout',
        components: {IgnorePageViewsTrigger, AppBar, SnackBar, NavigationDrawer},
        computed:{
            ...mapGetters('auth', ['isAuthenticated']),
            isPreview(){
                return this.$route.name === 'CreatePreview';
            }
        },
        mounted() {
            $auth.checkAuth();
            if (!this.isAuthenticated) {
                $auth.login();
            }
        }
    };
</script>

<style scoped>
</style>