<template>
    <v-row>
        <v-col class="py-0"
               cols="12"
        >
            <h3 class="mt-3 mb-3 font-weight-bold">
                Event settings
            </h3>
            <event-title v-if="!isExternalEmbed" />
            <div v-if="isHybridProject">
                <location-picker class="my-3" />
                <on-site-start-time class="flex-grow-0" />
            </div>
            <multi-session-event-settings v-if="isMultiSession" />
            <project-time v-if="showProjectTime" />
            <topic-list v-if="showTopics" />
        </v-col>
    </v-row>
</template>

<script>
    import { mapGetters } from 'vuex';
    import MultiSessionEventSettings from '@/components/pages/Project/ProjectOverviewElements/MultiSessionEventSettings';
    import ProjectTime from '@/components/pages/Project/ProjectOverviewElements/ProjectTime';
    import EventTitle from '@/components/pages/Project/ProjectOverviewElements/EventTitle';
    import TopicList from '@/components/pages/Project/Elements/TopicList';
    import LocationPicker from  '@/components/pages/Project/ProjectOverviewElements/LocationPicker';
    import OnSiteStartTime from '@/components/pages/Project/ProjectOverviewElements/OnSiteStartTime';

    export default {
        name: 'EventSettings',
        components: {
            OnSiteStartTime,
            EventTitle,
            MultiSessionEventSettings,
            ProjectTime,            
            TopicList,
            LocationPicker,
        },
        computed: {
            ...mapGetters('projects', [
                'isMultiSession',
                'isSingleSession',
                'isExternalEmbed',
                'isHybridProject'
            ]),
            showProjectTime() {
                return this.isSingleSession || this.isExternalEmbed || this.isHybridProject;
            },
            showTopics() {
                return this.isSingleSession || this.isHybridProject;
            }
        }
    };
</script>
