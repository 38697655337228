<template>
    <v-dialog :value="true"
              max-width="1000"
              persistent
              scrollable
    >
        <v-card>
            <v-card-title>
                Assign project to client {{ client.email }}
                <v-spacer />
                <v-btn icon
                       @click="close"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <project-selector
                    :assigned-projects="client.clientProjects"
                    @assign="assign"
                    @unassign="unassign"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn @click="close">
                    Close
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions } from 'vuex';
    import ProjectSelector from '@/components/controls/user-management/ProjectSelector';

    export default {
        name: 'AssignProjectDialog',
        components: { ProjectSelector },
        props: ['client'],
        computed: {
            showProductionCompany() {
                // TODO: consideer permissions
                return true;
            },
        },
        methods: {
            ...mapActions('clients', [
                'getClients',
                'assignClientToProject',
                'unassignClientFromProject',
            ]),
            async assign(project) {
                await this.assignClientToProject({
                    userId: this.client.id,
                    projectId: project.projectId,
                });
                this.client.clientProjects.push(project);
            },
            async unassign(project) {
                await this.unassignClientFromProject({
                    userId: this.client.id,
                    projectId: project.projectId,
                });
                this.client.clientProjects = this.client.clientProjects.filter((p) => {
                    return p.projectId !== project.projectId;
                });
            },
            async close() {
                await this.getClients();
                this.$emit('close');
            },
        },
    };
</script>
