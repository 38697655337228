<template>
    <v-dialog
        v-model="value"
        persistent
        max-width="500"
    >
        <v-card>
            <v-card-title class="pb-0">
                <slot name="title" />
                <v-spacer />
                <v-btn icon
                       @click="closeModal"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <slot name="content" />
            </v-card-text>
            <v-divider />
            <v-card-actions class=" px-4 ">
                <v-spacer />
                <v-btn color="error"
                       @click="confirm"
                >
                    <v-icon>mdi-delete</v-icon>
                    Delete
                </v-btn>


                <v-btn color="primary"
                       @click="closeModal"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>    
    export default {
        name: 'DeleteDialog',
        props:{
            value: {
                type: Boolean,
                required: true
            },
        },
        data(){
            return {
                loading : false,
            };
        },
        methods:{
            closeModal(){
                this.$emit('input', false);
            },
            confirm(){
                this.closeModal();
                this.$emit('confirm');
            }
        }
    };
</script>