<template>
    <div>
        <v-row v-show="!isExternalEmbed"
               class="tabs sticky-submenu"
        >
            <v-col
                cols="12"
                class="pt-0 pr-0 col-height"
            >
                <v-tabs v-model="tab"
                        class="mb-6 "
                >
                    <v-tab class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#general"
                    >
                        General
                    </v-tab>
                    <v-tab v-if="!isExternalEmbed"
                           class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#security"
                    >
                        Security
                    </v-tab>
                    <v-tab class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#speakers"
                    >
                        Speakers
                    </v-tab>
                    <v-tab v-if="!isExternalEmbed"
                           class="subtitle-1 text-capitalize tab-title mb-3"
                           href="#advanced"
                    >
                        Advanced
                    </v-tab>
                    <v-tab v-if="isQuadiaLiveAdmin && !isExternalEmbed"
                           class="subtitle-1 text-capitalize mb-3"
                           href="#code"
                    >
                        Custom code
                    </v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-tabs-items
                    :value="tab"
                >
                    <v-tab-item
                        :transition="false"
                        :reverse-transition="false"
                        value="general"
                    >
                        <general-settings />
                    </v-tab-item>
                    <v-tab-item
                        :transition="false"
                        :reverse-transition="false"
                        value="security"
                        class="tab-content"
                    >
                        <security-settings />
                    </v-tab-item>
                    <v-tab-item
                        :transition="false"
                        :reverse-transition="false"
                        value="speakers"
                        class="tab-content"
                    >
                        <speakers-settings />
                    </v-tab-item>
                    <v-tab-item
                        :transition="false"
                        :reverse-transition="false"
                        value="advanced"
                        class="tab-content"
                    >
                        <advanced-settings />
                    </v-tab-item>
                    <v-tab-item
                        v-if="isQuadiaLiveAdmin"
                        :transition="false"
                        :reverse-transition="false"
                        value="code"
                        class="tab-content"
                    >
                        <custom-code />
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import GeneralSettings from '@/components/pages/Project/OverviewTabs/GeneralSettings';
    import SecuritySettings from '@/components/pages/Project/OverviewTabs/SecuritySettings';
    import SpeakersSettings from '@/components/pages/Project/OverviewTabs/SpeakersSettings';
    import AdvancedSettings from '@/components/pages/Project/OverviewTabs/AdvancedSettings';
    import CustomCode from '@/components/pages/Project/OverviewTabs/CustomCode';
    import { mapGetters } from 'vuex';

    export default {
        name: 'ProjectSettings',
        components: {
            CustomCode,
            GeneralSettings, 
            SecuritySettings,
            SpeakersSettings,
            AdvancedSettings
        },
        data(){
            return {
                tab: 'general',
            };
        },
        computed: {
            ...mapGetters('auth', ['isQuadiaLiveAdmin']),
            ...mapGetters('projects', ['isExternalEmbed']),
        }
    };
</script>

<style scoped>
.tabs {
    border-bottom: 4px solid;
    border-color: #f5f5f5;
    border-top: 20px solid white;
    background-color: white;

}
.tab-title {
    border-right: 2px solid;
    border-color: #f5f5f5;
}

.col-height{
    height: 48px !important
}

.tab-content{
    padding: 40px 12vw;
    min-height: 70vh;
}
</style>