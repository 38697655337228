<template>
    <div>
        <v-tabs v-model="tab"
                class="pt-2 tabs"
        >
            <v-tab v-show="showConfirmationMail"
                   :to="{ name: 'ConfirmationMailPreview' }"
                   class="subtitle-1 text-capitalize tab-title"
                   href="#confirmation"
            >
                Confirmation
            </v-tab>
            <v-tab v-show="showReminderMail"
                   :to="{ name: 'ReminderMailPreview' }"
                   class="subtitle-1 text-capitalize tab-title"
                   href="#reminder"
            >
                Reminder
            </v-tab>
            <v-tab v-show="showThankYouMail"
                   :to="{ name: 'ThankYouMailPreview' }"
                   class="subtitle-1 text-capitalize tab-title"
                   href="#thankYou"
            >
                Thank You
            </v-tab>
            <v-tab v-show="showExtraMail"
                   :to="{ name: 'ExtraMailPreview' }"
                   class="subtitle-1 text-capitalize tab-title"
                   href="#extra"
            >
                Extra Mail
            </v-tab>
            <v-tab v-show="showOnSiteConfirmation"
                   :to="{ name: 'OnSiteConfirmationMailPreview' }"
                   class="subtitle-1 text-capitalize tab-title"
                   href="#onSiteConfirmation"
            >
                On-site Viewer confirmation
            </v-tab>
            <v-tab v-show="isHybridProject && showOnSiteReminderMail"
                   :to="{ name: 'OnSiteReminderMailPreview' }"
                   class="subtitle-1 text-capitalize last-tab-title"
                   href="#onSiteReminder"
            >
                On-site Reminder Mail
            </v-tab>
        </v-tabs>
        <email-preview v-if="currentProjectId"
                       :key="previewKey"
                       :template="template"
                       :project-id="currentProjectId"
                       :full-screen="true"
        />
    </div>
</template>

<script>
    import EmailPreview from '@/components/controls/EmailPreview';
    import { mapGetters, mapState } from 'vuex';
    export default {
        name: 'PreviewMails',
        components: {EmailPreview},
        data(){
            return {
                tab: 'confirmation',
                recentTab: 'confirmation',
                obj: {
                    'confirmation': 'registration-confirmation',
                    'reminder': 'reminder',
                    'thankYou': 'thank-you',
                    'extra': 'extra',
                    'onSiteConfirmation': 'on-site-viewer-registration-confirmation',
                    'onSiteReminder': 'on-site-viewer-reminder',
                },
                previewKey: 0
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects',['isHybridProject', 'isSpringerProject']),
            template(){
                return this.obj[this.recentTab];
            },
            currentProjectId(){
                return this.$route.params.project_id;
            },
            showReminderMail(){
                if (this.isSpringerProject) {
                    return false;
                }
                return this.project.scheduledEmails.some(e => e.emailType === 'reminder');
            },
            showThankYouMail(){
                if (this.isSpringerProject) {
                    return false;
                }
                return this.project.scheduledEmails.some(e => e.emailType === 'thank_you');
            },
            showExtraMail: function (){
                return this.project.extraMailEnabled;
            },
            showOnSiteReminderMail(){
                if (this.isSpringerProject) {
                    return false;
                }
                return this.project.scheduledEmails.some(e => e.emailType === 'on_site_reminder');
            },
            showConfirmationMail(){
                return !this.isSpringerProject;
            },
            showOnSiteConfirmation(){
                return this.isHybridProject && !this.isSpringerProject;
            }
        },
        watch:{
            tab(){
                this.previewKey++;
            },
            $route: {
                handler(to) {
                    if(to.path.indexOf('reminder') > -1) {
                        this.recentTab =  'reminder';
                    }
                    if(to.path.indexOf('confirmation') > -1) {
                        this.recentTab =  'confirmation';
                    }
                    if(to.path.indexOf('thank-you') > -1) {
                        this.recentTab =  'thankYou';
                    }
                    if(to.path.indexOf('extra') > -1) {
                        this.recentTab =  'extra';
                    }
                    if(to.path.indexOf('on-site-confirmation') > -1) {
                        this.recentTab =  'onSiteConfirmation';
                    }
                    if(to.path.indexOf('on-site-reminder') > -1) {
                        this.recentTab =  'onSiteReminder';
                    }
                },
                immediate: true,
            },
        },
    };
</script>

<style scoped>
.tabs {
    border-bottom: 12px solid;
    border-color: #f5f5f5;
    background-color: white;
    border-top: 4px white;

}
.tab-title {
    border-right: 2px solid;
    border-color: #f5f5f5;
    margin-bottom: 10px;

}

.last-tab-title{
     margin-bottom: 10px;

}

</style>
