<template>
    <div v-if="isCrispChat">
        <h2 class="font-weight-medium mt-4 ">
            Crisp chat statistics
        </h2>

        <div v-if="canDownload"
             class="text-center"
        >
            <download-crisp-chat-stats-button :selected-event="selectedEvent" />
        </div>
    </div>
    <div v-else>
        <download-chat-stats-button v-if="chatCount > 0 && canDownload" />
        <v-row v-if="selectedEvent.chatType !== 'noChat'"
               class="content-center mb-4"
        >
            <v-col>
                <h2 class="font-weight-regular text-center pt-5 ">
                    This event had:
                    <b v-if="!loading">{{ chatCount }}</b>
                    <v-progress-circular v-else
                                         indeterminate
                                         size="14"
                                         width="2"
                    />
                    chats
                </h2>
            </v-col>
        </v-row>
        <chat-category-counts v-if="selectedEvent.chatType !== 'noChat'" />
    </div>
</template>


<script>
    import { mapGetters, mapState } from 'vuex';
    import ProjectStatisticsService from '@/services/ProjectStatisticsService';
    import ChatCategoryCounts from '@/components/pages/Project/StatisticsTabs/ChatStatistics/ChatCategoryCounts';
    import DownloadChatStatsButton
        from '@/components/pages/Project/StatisticsTabs/ChatStatistics/DownloadChatStatsButton';
    import DownloadCrispChatStatsButton
        from '@/components/pages/Project/StatisticsTabs/ChatStatistics/DownloadCrispChatStatsButton.vue';

    export default {
        name: 'ChatStatistics',
        components: {DownloadCrispChatStatsButton, DownloadChatStatsButton, ChatCategoryCounts},
        props: {
            selectedEvent: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                chatCount: null,
                loading: false
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('auth', ['canDownloadStats']),
            canDownload() {
                return this.canDownloadStats((this.project));
            },
            isCrispChat() {
                return this.selectedEvent.chatType === 'crisp'
                    && !!this.selectedEvent.crispChatEvent;
            }
        },
        methods: {
            async loadData() {
                this.loading = true;
                this.chatCount = await ProjectStatisticsService.getChatCount(this.project._id);
                this.loading = false;
            },
        },
        created() {
            if (!this.isCrispChat && this.selectedEvent.chatType !== 'noChat') {
                this.loadData();
            }
        },
    };
</script>

<style scoped lang="scss">
.clickable-text:hover{
    cursor: pointer;
}
</style>