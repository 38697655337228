<template>
    <v-col md="2">
        {{ getStartTime(projectEvent) }}
    </v-col>
</template>

<script>
    import moment from 'moment-timezone';
    import TimeService from '@/services/TimeService';

    export default {
        name: 'ListStartTime',
        props: {
            projectEvent: {
                type: Object,
                required: true
            },
        },
        methods: {
            getStartTime(projectEvent) {
                return moment(projectEvent.startTime, 'X')
                    .tz(TimeService.defaultTimezone)
                    .format('DD/MM/YYYY HH:mm');
            },
        }
    };
</script>

<style scoped>

</style>