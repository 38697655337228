<template>
    <span :class="classes">{{ eventName }}</span>
</template>

<script>
    export default {
        name: 'ProjectEventName',
        props: {
            'projectEvent': {
                type: Object,
                required: true
            }
        },
        computed: {
            eventName() {
                return this.projectEvent.eventTitle;
            },
            classes() {
                return {
                    'font-weight-bold': this.projectEvent.type === 'multiSession',
                };
            }
        },
    };
</script>

<style scoped>

</style>