import {$http} from '@/services/http';
import {
    buildSimpleQueryString,
    INTAKE_LIST_URL,
    INTAKE_TOGGLE_SECTION_URL,
    populateIntakeUrl
} from '@/services/ApiService';

const PAGE_SIZE = 10;

const intakeStore = {
    namespaced: true,
    state: {
        items: [],
        pagination: {
            current: 1,
            total: 1,
            pageSize: PAGE_SIZE,
            itemCount: 0,
        },
        listingHeader: {
            filtering: {
                openPopup: '',
                filters: {},
                subFilters: {}
            },
        }
    },
    mutations: {
        setItems: (state, payload) => state.items = payload,
        setCurrentPage: (state, payload) => state.pagination.current = parseInt(payload),
        setPageTotal: (state, payload) => state.pagination.total = parseInt(payload),
        setPageSize: (state, payload) => state.pagination.pageSize = parseInt(payload),
        setItemCount: (state, payload) => state.pagination.itemCount = parseInt(payload),
        setHeaderOpenPopup: (state, payload) => state.listingHeader.filtering.openPopup = payload,
        setHeaderFilter: (state, payload) => state.listingHeader.filtering.filters = { ...state.listingHeader.filtering.filters, [payload.id]: payload.items },
    },
    actions: {
        getItems({commit}, payload) {
            const url = buildSimpleQueryString(INTAKE_LIST_URL, payload);
            return $http.get(url).then(response => {
                commit('setItems', response.data.intakes);
                commit('setCurrentPage', response.data.pagination?.current || 1);
                commit('setPageTotal', response.data.pagination?.total || 1);
                commit('setPageSize', response.data.pagination?.pageSize || PAGE_SIZE);
                commit('setItemCount', response.data.pagination?.itemCount || 0);
            });
        },
        toggleSection: (context, payload) => {
            return $http.post(populateIntakeUrl(INTAKE_TOGGLE_SECTION_URL, payload.id), {
                section: payload.section
            });

        }
    }
};

export default intakeStore;