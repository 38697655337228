<template>
    <div>
        <generic-block-options 
            :block="block"
            :save-url="saveUrl"
            :has-tab-titles="hasTabTitles"
        />
        <label class="d-block mb-1">Content text:</label>
        <rich-text-editor
            v-model="content"
            class="rich-text-editor"
            :disabled="project.isArchived"
            :with-event-links="false"
        />
        <v-divider class="my-6" />
        <v-text-field
            v-model="onDemandId"
            label="Ondemand video Id"
            :disabled="project.isArchived"
            hide-details
        />
        <v-divider class="my-6" />
        <v-select
            v-model="alignment"
            :items="alignmentOptions"
            item-text="label"
            item-value="value"
            label="Video alignment"
            :disabled="project.isArchived"
            class="mb-6"
            hide-details
        />
    </div>
</template>

<script>
    import GenericBlockOptions from './GenericBlockOptions';
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import {mapActions, mapState} from 'vuex';
    import { debounce } from 'lodash';

    export default {
        name: 'VideoContentBlock',
        components: {
            GenericBlockOptions,
            RichTextEditor
        },
        props: {
            block: {
                type: Object,
                required: true,
            },
            saveUrl: {
                type: String,
                required: true,
            },
            hasTabTitles: {
                type: Boolean,
                default: () => true,
            },
        },
        methods: {
            ...mapActions('dynamic-block', ['saveBlocks']),
        },
        computed:{
            ...mapState('projects', ['project']),
            alignmentOptions() {
                let options = [{
                    label: 'Player top',
                    value: 'player-top'
                },                    {
                    label: 'Player bottom',
                    value: 'player-bottom'
                }
                ];
                if(this.block.width === 'half'){
                    return options;
                }
                return [...options, 
                        {
                            label: 'Player right', 
                            value: 'player-right'
                        }, 
                        {
                            label: 'Player left',
                            value: 'player-left'
                        }];
            },
            content: {
                get(){
                    return this.block.componentOptions?.content || '';
                },
                set: debounce(async function (value) {
                    this.block.componentOptions.content = value;
                    await this.saveBlocks(this.saveUrl);
                }, 400)
            },
            alignment: {
                get(){
                    return this.block.componentOptions?.alignment || '';
                },
                set(value){
                    this.block.componentOptions.alignment = value;
                    this.saveBlocks(this.saveUrl);
                }
            },
            onDemandId: {
                get(){
                    return this.block.componentOptions?.onDemandId || '';
                },
                set(value){
                    this.block.componentOptions.onDemandId = value;
                    this.saveBlocks(this.saveUrl);
                }
            },
        },
        watch:{
            'block.width':{
                handler(value){
                    if(value === 'half'){
                        this.alignment = 'player-top';
                    }
                },
                immediate: true,
                deep: true
            }
        }
    };
</script>