<template>
    <div class="px-8 item-list">
        <intake-list-header />
        <v-card
            v-for="item in items"
            :key="`project-list-${item._id}`"
            class="mt-1 list-item"
        >
            <v-row class="px-4">
                <v-col md="2">
                    <a href="#"
                       @click.prevent="openIntake(item._id)"
                    >{{
                        item._id
                    }}</a>
                </v-col>
                <v-col md="1">
                    <intake-type-display :value="item.type" />
                </v-col>
                <v-col md="2">
                    {{ item.companyName }}
                </v-col>
                <v-col md="3">
                    {{ item.webinarTitle }}
                </v-col>
                <v-col md="2">
                    <timestamp-to-date :value="item.updatedAt" />
                </v-col>
                <v-col md="2">
                    <div class="text-right">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    icon
                                    color="black"
                                    small
                                    target="_blank"
                                    @click="copyIntake(item._id)"
                                    v-on="on"
                                >
                                    <v-icon>copy_all</v-icon>
                                </v-btn>
                            </template>
                            <span>Copy</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    icon
                                    color="black"
                                    small
                                    :href="intakeUrl(item._id)"
                                    target="_blank"
                                    v-on="on"
                                >
                                    <v-icon>edit</v-icon>
                                </v-btn>
                            </template>
                            <span>Edit</span>
                        </v-tooltip>
                    </div>
                </v-col>
            </v-row>
        </v-card>
        <intake-display
            :key="selectedIntake"
            :value="selectedIntake"
            @close="clearIntake"
        />
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import IntakeListHeader from '@/components/pages/Intake/IntakeListHeader';
    import TimestampToDate from '@/components/controls/TimestampToDate';
    import IntakeDisplay from '@/components/pages/Intake/IntakeDisplay';
    import { INTAKE_URL, INTAKE_COPY_URL, populateIntakeUrl } from '@/services/ApiService';
    import { $http } from '@/services/http';
    import IntakeTypeDisplay from '@/components/controls/IntakeTypeDisplay';

    export default {
        name: 'IntakeList',
        components: {IntakeTypeDisplay, IntakeDisplay, TimestampToDate, IntakeListHeader },
        props: {
            items: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                selectedIntake: null,
            };
        },
        methods: {
            ...mapActions('intake', ['getItems']),
            openIntake(id) {
                this.selectedIntake = id;
            },
            clearIntake() {
                this.selectedIntake = null;
            },
            intakeUrl(id) {
                return populateIntakeUrl(INTAKE_URL, id);
            },
            copyIntake(id) {
                $http.post(populateIntakeUrl(INTAKE_COPY_URL, id), {}).then((response) => {
                    window.open(this.intakeUrl(response.data._id), '_blank');
                });

            }
        },
        mounted() {},
    };
</script>

<style scoped>
</style>
