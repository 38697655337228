<template>
    <stats-card class="h13 metric"
                :loading="loading"
    >
        <template v-slot:title>
            Starts
        </template>
        <template v-slot:content>
            {{ views }}
        </template>
    </stats-card>
</template>
<script>
    import { fetchStatisticsApi } from '@/services/StatisticsService';
    import StatsCard from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/StatsCard.vue';

    export default {
        name: 'OnDemandViews',
        components: {StatsCard},
        props: {
            dateRange: {
                type: Array,
                required: true
            },
            videoId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                views: 0
            };
        },
        methods: {
            getViews() {
                this.loading = true;
                fetchStatisticsApi('/api/videos/views', this.dateRange, this.videoId).then(response => {
                    const buckets = response.data.filter(item => item.key === this.videoId);
                    this.views = 0;
                    if (buckets.length > 0) {
                        this.views = buckets[0].doc_count;
                        this.$emit('ready', buckets[0].doc_count);
                    }
                }).finally(() => this.loading = false);
            },
        },
        mounted() {
            this.getViews();
        }
    };
</script>

<style scoped>

</style>