<template>
    <div class="custom-date-picker">
        <v-form ref="form">
            <v-row>
                <v-col>
                    <v-menu
                        v-model="pickerOpen"
                        :close-on-content-click="false"
                        :top="top"                        
                        transition="scale-transition"
                        offset-y
                        min-width="290px"
                        :disabled="disabled"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                :value="inputModel"
                                :rules="dateFormat"
                                prepend-icon="mdi-calendar"
                                v-bind="attrs"
                                label="Start Date"
                                :disabled="disabled"
                                v-on="on"
                                @input="inputUpdate"
                            />
                        </template>
                        <v-date-picker
                            :value="pickerModel"
                            :disabled="disabled"
                            @input="pickerUpdate"
                        />
                    </v-menu>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>
<script>
    import TimeService from '@/services/TimeService';
    import moment from 'moment-timezone';
    export default {
        name: 'CustomDatePicker',
        props: {
            value: {
                type: Number,
                required: true
            },
            top: {
                type: Boolean,
                default: () => false,
            },
            disabled: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                pickerOpen: false,
                startHour: '00:00',
                pickerModel: '',
                inputModel: null,
            };
        },
        computed:{
            dateFormat() {
                const pattern = /^\d{2}-\d{2}-\d{4}$/;
                return [
                    (v) => {
                        if (!pattern.test(v)) {
                            return 'Correct date format DD-MM-YYYY';
                        }
                        const d = new Date(v.split('-').reverse().join('-'));
                        if (isNaN(d.getTime())) {
                            return 'Invalid date DD-MM-YYYY';
                        }
                        return true;
                    },
                ];
            },
        },
        methods: {
            async pickerUpdate(value) {
                this.pickerModel = value;
                this.pickerOpen = false;
                this.inputModel = TimeService.toDMYFromISO(value);
                await this.$nextTick();
                this.emitInput(value);
            },
            inputUpdate(value) {
                this.inputModel = value;
                const [day, month, year] = value.split('-');
                if(!year){
                    return;
                }
                const isoValue = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;    
                this.pickerModel = isoValue;
                this.emitInput(isoValue);
            },
            emitInput(value) {
                if(this.$refs.form.validate()){
                    const d = moment.tz(`${value} ${this.startHour}`, TimeService.defaultTimezone).unix();
                    this.$emit('input', d);
                }
            },
        },
        watch: {
            value:{
                handler(value){   
                    if(value){          
                        const date = moment(value, 'X').tz(TimeService.defaultTimezone);
                        this.startHour = date.format('HH:mm');
                        if(date.format('YYYY') !== '1970'){
                            this.pickerModel = date.format('YYYY-MM-DD');
                            this.inputModel = date.format('DD-MM-YYYY');
                        }
                    }
                },
                immediate: true
            },
        },
    };
</script>


<style lang="scss">
.custom-date-picker {
    position: relative;
}

.v-date-picker-table--date td {
    padding: 0 !important;
}
</style>
