<template>
    <div>
        <v-row class="tabs sticky-submenu">
            <v-col
                cols="12"
                class="pt-0 pr-0 col-height"
            >
                <v-tabs v-model="tab"
                        class="mb-6"
                >
                    <v-tab
                        class="subtitle-1 text-capitalize tab-title mb-3"
                        href="#general"
                    >
                        General
                    </v-tab>
                    <v-tab class="subtitle-1 text-capitalize mb-3"
                           :class="{'tab-title': isHybridProject && onSiteEvaluationId}"
                           href="#evaluationTab"
                           :disabled="evaluationButtonOverwrite"
                    >
                        Evaluation
                    </v-tab>
                    <v-tab v-if="isHybridProject && onSiteEvaluationId"
                           class="subtitle-1 text-capitalize mb-3"
                           href="#onSiteEvaluationTab"
                           :disabled="evaluationButtonOverwrite"
                    >
                        On-site Evaluation
                    </v-tab>
                </v-tabs>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-tabs-items
                    :value="tab"
                >
                    <v-tab-item :transition="false"
                                :reverse-transition="false"
                                value="general"
                                class="tab-content"
                    >
                        <h3 class="mb-2 font-weight-bold">
                            General settings
                        </h3>
                        <v-divider class="mb-1" />
                        <evaluation-custom-buttons 
                            label="Custom Buttons"
                        />
                    </v-tab-item>
                    <v-tab-item :transition="false"
                                :reverse-transition="false"
                                value="evaluationTab"
                                class="tab-content"
                    >
                        <h3 class="mb-2 font-weight-bold">
                            Evaluation form editor
                        </h3>
                        <v-divider class="mb-1" />
                        <evaluation-form-tab v-if="tab === 'evaluationTab'"
                                             :evaluation-id="evaluationId"
                        />
                    </v-tab-item>
                    <v-tab-item v-if="isHybridProject && onSiteEvaluationId"
                                :transition="false"
                                :reverse-transition="false"
                                value="onSiteEvaluationTab"
                                class="tab-content"
                    >
                        <h3 class="mb-2 font-weight-bold">
                            On-site evaluation form editor
                        </h3>
                        <v-divider class="mb-1" />
                        <evaluation-form-tab v-if="tab === 'onSiteEvaluationTab'"
                                             :evaluation-id="onSiteEvaluationId"
                        />
                    </v-tab-item>
                </v-tabs-items>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import EvaluationFormTab  from  '@/components/pages/Project/EvaluationTabs/EvaluationFormTab';
    import EvaluationCustomButtons from '@/components/controls/EvaluationCustomButtons';
    import { mapGetters, mapState } from 'vuex';

    export default {
        name: 'ProjectEvaluations',
        components: {EvaluationFormTab, EvaluationCustomButtons},
        data(){
            return {
                tab: 'general'
            };
        },
        computed:{
            ...mapState('projects', ['project']),
            ...mapGetters('projects', [
                'onSiteEvaluationId',
                'evaluationId',
                'isHybridProject',
                'isSpringerProject',
            ]),
            evaluationButtonOverwrite() {
                return this.project.evaluationButton.overwrite;
            },
        }
    };
</script>

<style scoped>
.tabs {
    border-bottom: 4px solid;
    border-color: #f5f5f5;
    border-top: 20px solid white;
    background-color: white;

}
.tab-title {
    border-right: 2px solid;
    border-color: #f5f5f5;
}

.col-height{
    height: 48px !important
}

.tab-content{
    padding: 40px 12vw;
}
</style>