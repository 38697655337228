import { filterText } from '@/services/strUtils';

export const EVALUATION_FIELD_TYPE_EMAIL = 'email';
export const EVALUATION_FIELD_TYPE_TEXT = 'text';
export const EVALUATION_FIELD_TYPE_TEXTAREA = 'textarea';
export const EVALUATION_FIELD_TYPE_CHECKBOX = 'checkbox';
export const EVALUATION_FIELD_TYPE_RADIO = 'radio';
export const EVALUATION_FIELD_TYPE_DROPDOWN = 'dropdown';
export const EVALUATION_FIELD_TYPE_STAR_RATING = 'star-rating';
export const EVALUATION_FIELD_TYPE_SCALE = 'scale';
export const EVALUATION_FIELD_TYPE_DROPDOWN_OPEN_ANSWER = 'dropdown-open-answer';
export const EVALUATION_FIELD_TYPE_SYSTEM_DATE = 'system-date';


export const EVALUATION_FIELD_VALIDATION_OPTION_NONE = 'no-options';
export const EVALUATION_FIELD_VALIDATION_OPTION_DUPLICATE = 'duplicate-options';
export const EVALUATION_FIELD_VALIDATION_NO_QUESTION = 'no-field-question';

export const EVALUATION_FIELD_TYPES = [
    {
        label: 'Text field',
        value: EVALUATION_FIELD_TYPE_TEXT
    },
    {
        label: 'Multi-line text',
        value: EVALUATION_FIELD_TYPE_TEXTAREA
    },
    {
        label: 'Check boxes',
        value: EVALUATION_FIELD_TYPE_CHECKBOX
    },
    {
        label: 'Radio buttons',
        value: EVALUATION_FIELD_TYPE_RADIO
    },
    {
        label: 'Dropdown',
        value: EVALUATION_FIELD_TYPE_DROPDOWN
    },
    {
        label: 'Star rating (1-5)',
        value: EVALUATION_FIELD_TYPE_STAR_RATING
    },
    {
        label: 'Scale (1-10)',
        value: EVALUATION_FIELD_TYPE_SCALE
    }, 
    {
        label: 'Open answer dropdown',
        value: EVALUATION_FIELD_TYPE_DROPDOWN_OPEN_ANSWER
    }
];

export const fieldHasOptions = function (field) {
    return ([
        EVALUATION_FIELD_TYPE_RADIO,
        EVALUATION_FIELD_TYPE_CHECKBOX,
        EVALUATION_FIELD_TYPE_DROPDOWN,
        EVALUATION_FIELD_TYPE_DROPDOWN_OPEN_ANSWER
    ].indexOf(field.type) > -1);
};

export const validateOptions = function (options) {
    if (!options || options.length < 1) {
        return {
            valid: false,
            reason: EVALUATION_FIELD_VALIDATION_OPTION_NONE,
        };
    }

    let duplicateFound = false;
    options.forEach(m => {
        const ct = options.filter(e => e.text.toLowerCase() === m.text.toLowerCase()).length;
        if (ct !== 1) {
            duplicateFound = true;
        }
    });

    if (duplicateFound) {
        return {
            valid: false,
            reason: EVALUATION_FIELD_VALIDATION_OPTION_DUPLICATE
        };
    }

    return {
        valid: true,
        reason: ''
    };
};

export const getNonDuplicateText = function (options, text) {
    const filteredText = filterText(text);
    let newText = filteredText;

    let found = true;
    let i = 0;

    while (found) {
        const item = options.find(e => filterText(e.text.toLowerCase()) === newText.toLowerCase());
        if (typeof item === 'undefined') {
            break;
        }
        i++;
        if (i > 0) {
            newText = `${filteredText} (${i})`;
        }
    }

    return newText;
};

export const validateEvaluationFields = function (fields) {
    const validResult = {
        valid: true,
        reason: '',
    };

    for (let i in fields) {
        if (!fields[i].question) {
            validResult.valid = false;
            validResult.reason = EVALUATION_FIELD_VALIDATION_NO_QUESTION;
            validResult.field = fields[i];
            break;
        }

        if (fieldHasOptions(fields[i])) {
            const vr = validateOptions(fields[i].options);
            if (!vr.valid) {
                validResult.valid = false;
                validResult.reason = vr.reason;
                validResult.field = fields[i];
                break;
            }
        }
    }

    return validResult;
};

const mapFieldOptions = function (fieldOptions) {
    return fieldOptions.map(o => {
        return {
            id: o.id,
            text: filterText(o.text)
        };
    });
};

export const mapToEvaluationFields = function (fields) {
    return fields.map(f => {
        const fieldDto = {
            id: f.id,
            type: f.type,
            question: filterText(f.question),
            required: f.required,
        };

        if (fieldHasOptions(f)) {
            fieldDto.options = mapFieldOptions(f.options || []);
        }
        return fieldDto;
    });
};

