<template>
    <v-container fluid
                 class="pt-0"
    >
        <archive-banner v-if="!loading" />
        <v-row justify="space-around">
            <v-col cols="12"
                   md="12"
                   sm="12"
                   lg="12"
                   class="pt-0"
            >
                <v-card v-if="!loading"
                        elevation="0"
                        class="mx-4 content-card pt-0"
                >
                    <v-row no-gutters
                           class="sticky-tabs"
                    >
                        <v-col cols="12"
                               xl="9"
                               lg="9"
                               md="9"
                               sm="9"
                        >
                            <v-tabs
                                v-model="tab"
                                elevation="0"
                                class=" text-right nav-tabs"
                                background-color="#F5F5F5"
                                active-class="active-tab"
                                show-arrows
                            >
                                <template v-slot:default>
                                    <v-tab
                                        v-if="!isClientAtMost"
                                        :to="{ name: 'CreateOverview' }"
                                        replace
                                        class="text-capitalize"
                                    >
                                        <v-icon class="mr-1">
                                            mdi-cog
                                        </v-icon>
                                        Settings
                                    </v-tab>
                                    <v-tooltip
                                        v-if="!isExternalEmbed && !isClientAtMost"
                                        :disabled="isRegistrationAllowed"
                                        bottom
                                    >
                                        <template v-slot:activator="{on}">
                                            <div class="d-flex"
                                                 v-on="on"
                                            >
                                                <v-tab
                                                    v-if="!isExternalEmbed"
                                                    :to="{ name: 'CreateRegistration' }"
                                                    class="text-capitalize"
                                                    replace
                                                    :disabled="!isRegistrationAllowed"
                                                >
                                                    <v-icon class="mr-1">
                                                        mdi-format-list-bulleted
                                                    </v-icon>
                                                    Landing Page
                                                </v-tab>
                                            </div>
                                        </template>
                                        <span>Change the type of registration</span>
                                    </v-tooltip>
                                    <v-tooltip
                                        v-if="!isExternalEmbed && !isClientAtMost"
                                        :disabled="!mailingsEnabled"
                                        bottom
                                    >
                                        <template v-slot:activator="{on}">
                                            <div class="d-flex"
                                                 v-on="on"
                                            >
                                                <v-tab
                                                    v-if="!isExternalEmbed"
                                                    :to="{ name: 'CreateMailings' }"
                                                    replace
                                                    class="text-capitalize"
                                                    :disabled="!mailingsEnabled"
                                                >
                                                    <v-icon class="mr-1">
                                                        mdi-email-edit
                                                    </v-icon>
                                                    Mailings
                                                </v-tab>
                                            </div>
                                        </template>
                                        <span>Change the type of registration</span>
                                    </v-tooltip>
                                    <v-tab
                                        v-if="!isExternalEmbed && !isClientAtMost"
                                        :to="{name: 'CreateEvent', params:{ 'event_id': currentEvent}}"
                                        replace
                                        class="text-capitalize"
                                    >
                                        <v-icon class="mr-1">
                                            mdi-television-play
                                        </v-icon>
                                        Event Pages
                                    </v-tab>
                                    <v-tab
                                        v-if="!isExternalEmbed && !isClientAtMost"
                                        :to="{name: 'EvaluationSettings'}"
                                        replace
                                        class="text-capitalize"
                                    >
                                        <v-icon class="mr-1">
                                            mdi-clipboard-text
                                        </v-icon>
                                        Evaluations
                                    </v-tab>
                                    <v-tab
                                        :to="{ name: isRegistrationAllowed ? 'RegistrationStatistics': 'InsightsStatistics' }"
                                        replace
                                        class="text-capitalize"
                                    >
                                        <v-icon class="mr-1">
                                            mdi-chart-bar
                                        </v-icon>
                                        Statistics
                                    </v-tab>
                                    <v-tab v-if="isQuadiaLiveAdmin"
                                           :to="{name: 'ActivityLog'}"
                                           replace
                                           class="text-capitalize"
                                    >
                                        <v-icon class="mr-1">
                                            mdi-history
                                        </v-icon>
                                        Activity log
                                    </v-tab>
                                    <live-director-button v-if="isExternalEmbed" />
                                </template>
                            </v-tabs>
                        </v-col>
                        
                        <v-col v-if="!isExternalEmbed"
                               class="preview-link-wrapper"
                               cols="12"
                               xl="3"
                               lg="3"
                               md="3"
                               sm="3"
                        >
                            <v-spacer />
                            <a
                                target="_blank"
                                :href="previewLink"
                                class="font-weight-regular link text-decoration-none  px-4 pt-3"
                            >
                        
                                <v-icon class="link-icon pr-1 text-right">mdi-open-in-new</v-icon>
                                <h4 class="preview-mode font-weight-regular pt-3">
                                    Preview {{ project.eventTitle }}
                                </h4>
                            </a>
                        </v-col>
                    </v-row>
                    <v-divider />
                    <v-card-text elevation="2"
                                 class="pt-0"
                    >
                        <v-tabs-items :value="recentTab"
                                      class="pt-0"
                        >
                            <v-tab-item
                                v-if="!isClientAtMost"
                                :transition="false"
                                :reverse-transition="false"
                                value="overview"
                            >
                                <project-overview />
                            </v-tab-item>
                            <v-tab-item
                                v-if="!isClientAtMost"
                                :transition="false"
                                :reverse-transition="false"
                                value="registration"
                            >
                                <registration-page-editor />
                            </v-tab-item>
                            <v-tab-item
                                v-if="!isClientAtMost"
                                :transition="false"
                                :reverse-transition="false"
                                value="mailings"
                            >
                                <project-mailings />
                            </v-tab-item>
                            <v-tab-item
                                v-if="!isClientAtMost"
                                :transition="false"
                                :reverse-transition="false"
                                value="eventPages"
                            >
                                <project-events 
                                    :key="projectRenderKey"
                                />
                            </v-tab-item>
                            <v-tab-item
                                :transition="false"
                                :reverse-transition="false"
                                value="evaluation"
                            >
                                <project-evaluations />
                            </v-tab-item>
                            <v-tab-item
                                :transition="false"
                                :reverse-transition="false"
                                value="statistics"
                            >
                                <project-statistics :key="`stats-tab-${tab}`" />
                            </v-tab-item>
                            <v-tab-item v-if="isQuadiaLiveAdmin"
                                        value="activityLog"
                            >
                                <activity-log />
                            </v-tab-item>
                        </v-tabs-items>
                    </v-card-text>
                </v-card>
                <v-progress-linear v-else
                                   indeterminate
                                   class="mt-5"
                />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
    import ArchiveBanner from '@/components/pages/Project/ArchiveBanner';
    import RegistrationPageEditor from '@/components/pages/Project/RegistrationPageEditor';
    import ProjectOverview from '@/components/pages/Project/ProjectOverview';
    import ProjectMailings from '@/components/pages/Project/ProjectMailings.vue';
    import ProjectEvents from '@/components/pages/Project/ProjectEvents.vue';
    import ProjectEvaluations from '@/components/pages/Project/ProjectEvaluations';
    import ProjectStatistics from '@/components/pages/Project/ProjectStatistics.vue';
    import TimeService from '@/services/TimeService';
    import {REGISTRATION_TYPE_REGISTRATION} from '@/services/RegistrationService';
    import ActivityLog from '@/components/pages/Project/ActivityLog';
    import LiveDirectorButton from '@/components/pages/Project/Elements/LiveDirectorButton';

    export default {
        name: 'Project',
        components: {
            ActivityLog,
            ArchiveBanner,
            ProjectMailings,
            RegistrationPageEditor,
            ProjectOverview,
            ProjectStatistics,
            ProjectEvents,
            LiveDirectorButton,
            ProjectEvaluations,
        },

        data() {
            return {
                loading: true,
                tab: window.location.href,
                recentTab: 'overview',
            };
        },
        watch: {
            async tab(value, oldValue) {
                // don't reload the project when we're jumping
                // from projects/projectId to projects/projectId/statistics, for example
                // it is already loaded and it would only duplicate the request
                const currentUrl = window.location.href.split('/');
                const currentHostProtocol = currentUrl[0] + '//' + currentUrl[2];
                const newValWithHost = currentHostProtocol + value;
                if (newValWithHost.indexOf(oldValue) > -1) {
                    return;
                }
                await this.reloadProject();
                this.reRender();
                window.scrollTo({left: 0, top:0});
            },
            $route: {
                async handler(to) {
                    if (!this.project._id) {
                        await this.waitUntilLoaded();
                    }
                    if (to.path.indexOf('overview') > -1) {
                        this.recentTab = 'overview';
                    }
                    if (to.path.indexOf('registration') > -1) {
                        this.recentTab = 'registration';
                    }
                    if (to.path.indexOf('mailings') > -1) {
                        this.recentTab = 'mailings';
                    }
                    if (to.path.indexOf('eventpage') > -1) {
                        this.recentTab = 'eventPages';
                    }
                    if (to.path.indexOf('evaluation-settings') > -1) {
                        this.recentTab = 'evaluation';
                    }
                    if (to.path.indexOf('statistics') > -1) {
                        this.recentTab = 'statistics';
                    }
                    if (to.path.indexOf('activity') > -1) {
                        this.recentTab = 'activityLog';
                    }
                },
                immediate: true,
            },
        },
        computed: {
            ...mapState('projects', ['project', 'projectRenderKey']),
            ...mapGetters('projects', [
                'registrationType',
                'isMultiSession',
                'isExternalEmbed',
                'isSpringerProject',
                'isRegistrationTypeWithoutMailings',
                'mailingsEnabled',
            ]),
            ...mapGetters('auth', ['isClientAtMostForProject', 'isQuadiaLiveAdmin']),
            currentEvent(){
                return this.project?.events[0]?._id;
            },
            isClientAtMost(){
                return this.isClientAtMostForProject(this.project);
            },
            startDate(){
                const date = TimeService.dateFromUnixTimestamp(this.project.events[0].startTime);
                return TimeService.toDMYFromISO(TimeService.toFormattedISODateString(date));
            },
            startTime(){
                return this.project.events[0].startTimeFormattedTime.slice(0, 5);
            },
            duration(){
                return this.project.events[0].durationInMinutes;
            },
            isRegistrationAllowed(){
                return this.registrationType === REGISTRATION_TYPE_REGISTRATION;
            },
            previewLink(){
                return `${window.origin}/projects/${this.project._id}/${this.project.events[0]._id}/preview`;
            },
            disableMailingsTab(){
                if (this.isSpringerProject && this.project.extraMailEnabled) {
                    return false;
                }
                return !this.isRegistrationAllowed;
            },

        },
        methods: {
            ...mapActions('projects', ['getProject', 'reloadProject']),
            ...mapActions('productionCompanies', ['getProductionCompanies']),
            ...mapMutations('projects', ['reRender']),
            async waitUntilLoaded() {
                return new Promise((resolve) => {
                    const t = setInterval(() => {
                        if (!this.loading) {
                            clearInterval(t);
                            resolve();
                        }
                    }, 10);
                });
            },
        },
        async created() {
            var promises = [
                this.getProductionCompanies(),
                this.getProject(this.$route.params.project_id)
            ];
            await Promise.all(promises);

            if (this.isClientAtMostForProject(this.project) && this.$route.name !== 'CreateStatistics') {
                await this.$router.push({
                    name: 'CreateStatistics',
                    params: {
                        project_id: this.$route.params.project_id
                    }
                });
            }

            this.loading = false;
        },
    };
</script>

<style scoped lang="scss">
.active-tab {
    background-color: white;
}

.preview-link-wrapper{
    background-color: #f5f5f5f5;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis !important;
    display: flex;

    .link-icon{
        color: #1976d2 !important
    }

    .link{
        display: contents;
 
        .preview-mode{
            text-decoration: underline;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis !important;
        }
    }
}

.badge{
    z-index:10
}
</style>

<style lang="scss">
.nav-tabs{
    .v-slide-group__next, .v-slide-group__prev{
        display: contents;
    }
}
</style>