<template>
    <div>
        <questions-list-header />
        <div v-if="!loading">
            <v-card v-for="item in items"
                    :key="`${items.indexOf(item)}`"
                    class="mt-1"
            >
                <v-card-text class="px-0">
                    <v-row class="mx-0">
                        <v-col cols="12"
                               lg="2"
                               md="2"
                               sm="2"
                               class="vertical-center-text"
                        >
                            <span v-if="item.created_at">
                                {{ item.created_at }}
                            </span>
                        </v-col>

                        <v-col cols="12"
                               lg="5"
                               md="5"
                               sm="5"
                               class="vertical-center-text"
                        >
                            {{ item.message }}
                        </v-col>

                        <v-col v-if="canShowComponent"
                               cols="12"
                               lg="2"
                               md="2"
                               sm="2"
                               class="vertical-center-text"
                        >
                            {{ item.name }}
                        </v-col>

                        <v-col v-if="canShowComponent"
                               cols="12"
                               lg="3"
                               md="3"
                               sm="3"
                               class="vertical-center-text"
                        >
                            {{ item.email }}
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </div>
        <v-progress-linear v-else
                           indeterminate
        />
    </div>
</template>

<script>
    import {mapGetters, mapState} from 'vuex';
    import QuestionsListHeader from './QuestionsListHeader';

    export default {
        name: 'QuestionsList',
        components: {QuestionsListHeader},
        props: {
            loading: {
                type: Boolean,
                default: () => false,
            },
            items: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {};
        },
        computed: {
            ...mapGetters('auth', ['canSeeRegistrationData']),
            ...mapState('projects', ['project']),
            canShowComponent() {
                return this.canSeeRegistrationData(this.project);
            }
        },
        mounted() {},
    };
</script>

<style scoped>
.vertical-center-text{
    display: block;
    align-items: center;
    word-break: break-word;
}
</style>
