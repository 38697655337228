<template>
    <div>
        <generic-block-options :block="block"
                               :save-url="saveUrl"
                               :has-tab-titles="hasTabTitles"
        />
        <label class="d-block mb-1">Content text:</label>
        <rich-text-editor
            v-model="content"
            class="rich-text-editor"
            :disabled="project.isArchived"
            :with-event-links="false"
        />
        <v-divider class="mt-6 mb-4" />
        <div class="d-flex mb-4">
            <label class="mt-5 mr-4">CTA button:</label>
            <v-switch v-model="enableCtaButton"
                      inset
                      hide-details
            />
        </div>
        <v-text-field v-if="enableCtaButton"
                      v-model="ctaButtonText"
                      label="CTA button text"
        />
        <v-text-field v-if="enableCtaButton"
                      v-model="ctaButtonUrl"
                      label="CTA button URL"
                      :rules="urlRules"
        />
        <v-select
            v-model="ctaButtonAlignment"
            class="mb-6"
            :items="ctaButtonAlignmentOptions"
            item-text="label"
            item-value="value"
            label="Cta button alignment"
            :disabled="project.isArchived"
            hide-details=""
        />
    </div>
</template>

<script>
    import GenericBlockOptions from './GenericBlockOptions';
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import { mapActions, mapState } from 'vuex';
    import { debounce } from 'lodash';
    import {isHttpUri, isHttpsUri} from 'valid-url';

    export default {
        name: 'TextContent',
        components: {
            GenericBlockOptions,
            RichTextEditor
        },
        props: {
            block: {
                type: Object,
                required: true
            },
            saveUrl: {
                type: String,
                required: true,
            },
            hasTabTitles: {
                type: Boolean,
                default: () => true,
            },
        },
        data(){
            return {
                ctaButtonAlignmentOptions:[
                    {
                        value: 'flex-start',
                        label: 'Left'
                    },
                    {
                        value: 'center',
                        label: 'Center'
                    },                    
                    {
                        value: 'flex-end',
                        label: 'Right'
                    }                    
                ]
            };
        },
        computed:{
            ...mapState('projects', ['project']),
            urlRules(){
                return [v => (isHttpUri(v) !== undefined || isHttpsUri(v) !== undefined  || v.length === 0) || 'URL is invalid'];
            },
            content:{
                get(){
                    return this.block.componentOptions.content;
                },
                set: debounce(async function (value) {
                    this.block.componentOptions.content = value;
                    await this.saveBlocks(this.saveUrl);
                },400)
            },
            enableCtaButton:{
                get(){
                    return this.block.componentOptions?.ctaButton?.enableCtaButton || false ;
                },
                set(value){
                    this.block.componentOptions.ctaButton.enableCtaButton = value;
                    this.saveBlocks(this.saveUrl);
                }
            },
            ctaButtonText:{
                get(){
                    return this.block.componentOptions?.ctaButton?.ctaButtonText || '';
                },
                set(value){
                    this.block.componentOptions.ctaButton.ctaButtonText = value;
                    this.saveBlocks(this.saveUrl);
                }
            },
            ctaButtonUrl:{
                get(){
                    return this.block.componentOptions?.ctaButton?.ctaButtonUrl || '';
                },
                set(value){
                    if(this.isUrlValid(value)){
                        this.block.componentOptions.ctaButton.ctaButtonUrl = value;
                        this.saveBlocks(this.saveUrl);
                    }
                }
            },
            ctaButtonAlignment:{
                get(){
                    return this.block.componentOptions?.ctaButton?.alignment || 'flex-start';
                },
                set(value){
                    this.block.componentOptions.ctaButton.alignment = value;
                    this.saveBlocks(this.saveUrl);
                }
            },
        },
        methods: {
            ...mapActions('dynamic-block', ['saveBlocks']),
            isUrlValid(v){
                return (isHttpUri(v) !== undefined || isHttpsUri(v) !== undefined  || v.length === 0) || false;
            }
        }
    };
</script>