import { $http, makeAccountsUrl, getInternalUrl } from './http';
import jwtDecode from 'jwt-decode';
import { RequestRetryService } from './request-retry-service';
import { $refreshTokenService } from './refresh-token-service.js';
import crypto from 'crypto-js';
import { toastError } from '@/services/responseErrors';

import store from '../store/index';
import {
  handleBadRequest,
  handleForbidden,
  handleNotFound,
  handleServerErrors,
  handleTooManyAttempts,
  handleUnprocessable,
} from './responseErrors';

export const $auth = {
  loginInProgress: false,
  authCallback() {
    return getInternalUrl({ name: 'AuthCallback' });
  },
  login() {
    if (this.loginInProgress) {
      return;
    }
    this.loginInProgress = true;
    const state = createRandomString(40);
    const verifier = createRandomString(128);
    const challenge = base64Url(crypto.SHA256(verifier));
    window.sessionStorage.setItem('state', state);
    window.sessionStorage.setItem('verifier', verifier);
    window.sessionStorage.setItem('auth_callback_redirect_uri', window.location.href);

    window.location.href = `${makeAccountsUrl('/sso/auth')}
?client_id=${process.env.VUE_APP_ACCOUNTS_API_CLIENT_ID}
&redirect_uri=${this.authCallback()}
&response_type=code&scope=*
&state=${state}
&code_challenge=${challenge}
&code_challenge_method=S256`;
  },
  async askForToken(code) {
    const url = makeAccountsUrl('/sso/token');
    const loginResponse = await $http.post(url, {
      grant_type: 'authorization_code',
      client_id: process.env.VUE_APP_ACCOUNTS_API_CLIENT_ID,
      redirect_uri: this.authCallback(),
      code_verifier: window.sessionStorage.getItem('verifier'),
      code,
    });
    window.sessionStorage.removeItem('state');
    window.sessionStorage.removeItem('verifier');

    sessionStorage.setItem('access_token', loginResponse.data.access_token);
    sessionStorage.setItem('refresh_token', loginResponse.data.refresh_token);

    this.checkAuth();
  },
  refresh() {
    const refresh_token = sessionStorage.getItem('refresh_token');
    if (!refresh_token) {
      this.login();
      return Promise.reject();
    }

    if ($refreshTokenService.isInProgress()) {
      return $refreshTokenService.awaitRefresh();
    }

    $refreshTokenService.start();
    const url = makeAccountsUrl('/sso/token');
    return $http.post(url, {
      grant_type: 'refresh_token',
      client_id: process.env.VUE_APP_ACCOUNTS_API_CLIENT_ID,
      refresh_token,
    }).then((response) => {
      window.sessionStorage.setItem('access_token', response.data.access_token);
      window.sessionStorage.setItem('refresh_token', response.data.refresh_token);

      this.checkAuth();
    }).catch(() => {
      this.login();
    }).finally(() => {
      $refreshTokenService.finish();
    });
  },

  logout() {
    window.sessionStorage.removeItem('access_token');
    window.sessionStorage.removeItem('refresh_token');
    store.commit('auth/logout');
  },

  checkAuth() {
    var jwt = window.sessionStorage.getItem('access_token');

    if (jwt) {
      const payload = jwtDecode(jwt);
      store.commit('auth/login', payload);
    } else {
      store.commit('auth/logout');
    }
  },

  getAuthHeader() {
    return 'Bearer ' + window.sessionStorage.getItem('access_token');
  }
};

$http.interceptors.request.use((config) => {
    $auth.checkAuth();
    if (store.state.auth.authenticated) {
      config.headers.Authorization = $auth.getAuthHeader();
    }
    return config;
  },
  (error) => Promise.reject(error)
);

$http.interceptors.response.use((response) => {
  RequestRetryService.clearAttempts(response);
  return response;
}, async (error) => {
  // Do something with response error
  switch (error.response.status) {
    default:
    case 500:
      handleServerErrors();
      break;
    case 400:
      handleBadRequest(error);
      break;
    case 401:
      return handle401(error.response);
    case 403:
      handleForbidden(error);
      break;
    case 404:
      handleNotFound(error);
      break;
    case 422:
      handleUnprocessable(error);
      break;
    case 429:
      handleTooManyAttempts(error);
      break;
  }

  return Promise.reject(error);
}
);

function handle401(errorResponse) {
  if (errorResponse.config) {
    try{
      var data = JSON.parse(errorResponse.config.data);
    } catch{
      data = {};
    }
    if (data.grant_type === 'refresh_token') {
      $auth.login();
      return Promise.reject();
    }
  }

  return $auth.refresh().then(function () {
    RequestRetryService.incrementNumberOfAttempts(errorResponse);
    if (RequestRetryService.tooManyAttempts(errorResponse)) {
      toastError('You are not allowed to access this feature.');
      return Promise.reject(errorResponse);
    } else {
      return $http.request(errorResponse.config);
    }
  });
}

$auth.checkAuth();

function base64Url(string) {
  return string.toString(crypto.enc.Base64)
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

function createRandomString(num) {
  return [...Array(num)].map(() => Math.random().toString(36)[2]).join('');
}
