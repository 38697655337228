<template>
    <v-container fluid>
        <v-card v-if="!loading"
                elevation="0"
                class="mx-4"
        >
            <v-row v-if="!loading"
                   class="page-header-container grey lighten-4"
            >
                <v-col>
                    <h1 class="px-4 font-weight-regular page-header">
                        <router-link
                            class="text-decoration-none"
                            :to="{ name: 'Companies' }"
                        >
                            Companies
                        </router-link>
                        <v-icon color="grey">
                            chevron_right
                        </v-icon>
                        {{ companyName }}
                    </h1>
                </v-col>
            </v-row>
            <v-card-text elevation="2">
                <company-details v-if="!loading" />
            </v-card-text>
        </v-card>
        <v-progress-linear v-else
                           indeterminate
        />
    </v-container>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';
    import CompanyDetails from '@/components/controls/company/CompanyDetails';

    export default {
        name: 'CompanyOverview',
        components: {CompanyDetails},
        data() {
            return {
                loading: false,
            };
        },
        computed: {
            ...mapState('companies', ['company']),
            ...mapGetters('auth', [
                'isQuadiaLiveAdmin',
                'isCompanyAdminAtAnyProductionCompany',
                'isProjectEditorAtAnyProductionCompany'
            ]),
            canEditCompany() {
                return this.isQuadiaLiveAdmin ||
                    this.isCompanyAdminAtAnyProductionCompany ||
                    this.isProjectEditorAtAnyProductionCompany;
            },
            companyName() {
                return this.company.name;
            }
        },
        methods: {
            ...mapActions('companies', ['getCompany']),
            ...mapActions('productionCompanies', ['getProductionCompanies'])
        },
        async mounted() {
            if(!this.canEditCompany){
                this.$router.push({ name: 'Home' });
                return;
            }
            this.loading = true;
            var promises = [
                this.getCompany(this.$route.params.company_id),
                this.getProductionCompanies()
            ];
            await Promise.all(promises);            
            this.loading = false;
        },
    };
</script>
