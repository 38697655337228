<template>
    <v-dialog
        v-model="showModal"
        persistent
        max-width="600"
    >
        <v-card>
            <v-card-title class="pb-0">
                {{ topic ? 'Edit a topic' : 'Add a topic' }}
                <v-spacer />
                <v-btn icon
                       @click="closeModal"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <v-row no-gutters>
                    <v-col>
                        <h3 class="font-weight-regular mb-5">
                            Topic:
                        </h3>
                        <v-form ref="form"
                                @submit.prevent="topic ? updateTopic() : addTopic()"
                        >
                            <v-text-field
                                v-model="title"
                                filled
                                class="text-input"
                                label="Topic title"
                                :rules="requiredField"
                            />
                            <v-textarea
                                v-model="description"
                                filled
                                label="Topic description"
                            />
                            <v-row>
                                <v-col cols="12"
                                       lg="6"
                                       md="6"
                                       sm="6"
                                       class="py-0 d-inline-flex"
                                >
                                    <h3 class="font-weight-regular mr-3 pt-6"
                                        style="min-width: 80px"
                                    >
                                        Start time
                                    </h3>
                                    <custom-time-picker v-model="startTime"
                                                        class="topic-input"
                                                        send-time-formatted
                                                        @formattedStartTime="saveFormattedTime"
                                    />
                                </v-col>
                                <v-col cols="12"
                                       lg="6"
                                       md="6"
                                       sm="6"
                                       class="py-0 d-inline-flex"
                                >
                                    <h3 class="font-weight-regular mr-3 pt-6">
                                        Duration
                                    </h3>
                                    <v-text-field
                                        v-model="durationInMinutes"
                                        class="topic-input"
                                        type="number"
                                        min="1"
                                        :rules="requiredFieldDuriation"
                                    />
                                    <h3 class="font-weight-regular ml-3 pt-6">
                                        minutes
                                    </h3>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-col>
                </v-row>
                <p v-if="incorrectInterval"
                   class="error--text mt-5"
                >
                    There is already a topic in this interval.
                </p>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-6 pb-4">
                <v-spacer />
                <v-btn color="primary"
                       @click="topic ? updateTopic() : addTopic()"
                >
                    {{ topic ? 'Update' : 'Add' }}
                </v-btn>
                <v-btn @click="closeModal">
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions, mapState } from 'vuex';
    import { v4 as uuidv4 } from 'uuid';
    import CustomTimePicker from '@/components/controls/CustomTimePicker';

    export default {
        name:'TopicDialog',
        components: {CustomTimePicker},
        props:{
            value: {
                type: Boolean,
                default: () => false
            },
            topic: {
                type: Object,
                default: () => null
            }
        },
        data(){
            return {
                showModal: this.value,
                title: null,
                startTime: 0,
                startTimeFormatted: '',
                durationInMinutes: 1,
                description: '',
                id: uuidv4(),
                incorrectInterval: false
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            requiredField(){
                return [(v) => !!v || 'Field is required'];
            },
            requiredFieldDuriation(){
                return [(v) => !!v && v > 0 || 'Value must be greater than 1'];
            }
        },
        methods:{
            ...mapActions('projects', ['getProject']),
            updateTopic(){
                this.incorrectInterval = this.isOverlapping();
                if(this.$refs.form.validate() && !this.incorrectInterval){
                    this.$emit('updateTopic', {
                        _id: this.id,
                        title: this.title,
                        description: this.description,
                        startTime: this.startTime,
                        startTimeFormatted: this.startTimeFormatted,
                        durationInMinutes: Math.ceil(this.durationInMinutes),
                    });
                    this.closeModal();
                }
            },
            addTopic(){
                this.incorrectInterval = this.isOverlapping();
                if(this.$refs.form.validate() && !this.incorrectInterval){
                    this.$emit('addTopic', {
                        _id: this.id,
                        title: this.title,
                        description: this.description,
                        startTime: this.startTime,
                        startTimeFormatted: this.startTimeFormatted,
                        durationInMinutes: Math.ceil(this.durationInMinutes),
                    });
                    this.closeModal();        
                }   
            },
            saveFormattedTime(value){
                this.startTimeFormatted = value;
            },
            isOverlapping(){
                const endTime = this.startTime * 1000 + Math.ceil(this.durationInMinutes) * 60000;

                const found = this.project.topics.filter(e => {
                    if(e._id=== this.id){
                        return false;
                    }
                    const itemEndTime= e.startTime * 1000 + e.durationInMinutes * 60000;
                    return endTime > e.startTime*1000 && endTime <= itemEndTime || 
                        this.startTime >= e.startTime && this.startTime*1000 < itemEndTime ||
                        e.startTime >= this.startTime && e.startTime *1000 < endTime ||
                        itemEndTime > this.startTime*1000 && itemEndTime <= endTime;
                });
                return found.length > 0;
            },
            closeModal(){
                this.$emit('input', false);
            },
        },
        created(){
            if(this.topic){
                this.id = this.topic._id;
                this.title = this.topic.title;
                this.description = this.topic.description;
                this.startTime = this.topic.startTime;
                this.startTimeFormatted = this.topic.startTimeFormatted;
                this.durationInMinutes = this.topic.durationInMinutes;
            }
        }
    };
</script>

