import {v4 as uuidv4} from 'uuid';

class Speaker {
    constructor(data) {
        this.id = data.id || uuidv4();
        this.name = data.name || '';
        this.description = data.description || '';
        this.link = data.link || '';
        this.title = data.title || '';
        this.avatar = data.avatar || {
            url: '',
            key: ''
        };
    }
}

export default Speaker;