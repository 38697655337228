<template>
    <v-card class="px-4 session-list-header"
            rounded="0"
            elevation="1"
    >
        <v-row>
            <v-col cols="12"
                   md="2"
                   lg="2"
                   class="pr-0"
            >
                <h5 class="text-left">
                    Type
                </h5>
            </v-col>
            <v-col cols="12"
                   lg="2"
                   md="2"
                   class="pr-0"
            >
                <h5 class="left">
                    Time
                </h5>
            </v-col>
            <v-col cols="12"
                   lg="2"
                   md="2"
                   class="pr-0 pl-0"
            >
                <h5 class=" text-left">
                    Duration
                </h5>
            </v-col>
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-col cols="12"
                           lg="1"
                           md="1"
                           sm="1"
                           class="pl-0 pr-0"
                           v-on="on"
                    >
                        <h5 class="text-left">
                            Max.#
                        </h5>
                    </v-col>
                </template>
                <span>This shows the maximum number of participants to a break-out session.</span>
            </v-tooltip>
            <v-col cols="12"
                   lg="4"
                   md="4"
                   class="pr-0"
            >
                <h5 class="text-left">
                    Title
                </h5>
            </v-col>
            <v-col cols="12"
                   lg="1"
                   md="1"
            />
        </v-row>
    </v-card>
</template>

<script>
    export default {
        name: 'SessionsListHeader',
    };
</script>

<style scoped>
.session-list-header {
    color: #00000099;
}
</style>
