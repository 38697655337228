<template>
    <div class="mt-2">
        <h3 class="font-weight-regular">
            {{ label }}
  
            <v-btn color="secondary"
                   class="ml-4 mb-2"
                   :disabled="showAddTitle || disabled"
                   @click="addTitle"
            >
                Title
            </v-btn>
            <v-btn color="secondary"
                   class="ml-4 mb-2"
                   :disabled="showAddFirstname || disabled"
                   @click="addFirstname"
            >
                First name
            </v-btn>
            <v-btn color="secondary"
                   class="ml-4 mb-2"
                   :disabled="showAddCompany || disabled"
                   @click="addCompany"
            >
                Company
            </v-btn>
            <v-btn color="secondary"
                   class="ml-4 mb-2"
                   :disabled="showAddMobileNumber || disabled"
                   @click="addMobileNumber"
            >
                Mobile
            </v-btn>
            <v-btn color="primary"
                   class="ml-4 mb-2"
                   :disabled="disabled"
                   @click="addField"
            >
                Custom Field
            </v-btn>
        </h3>
        <v-row>
            <v-col cols="12"
                   lg="5"
                   md="6"
            >
                <h4 class="caption">
                    Field name
                </h4>
            </v-col>
            <v-col cols="12"
                   lg="5"
                   md="5"
            >
                <h4 class="caption">
                    Type
                </h4>
            </v-col>
            <v-col cols="12"
                   lg="2"
                   md="2"
            >
                <h4 class="caption">
                    Required?
                </h4>
            </v-col>
        </v-row>
        <FieldRow
            :key="`registration-fields-${updateKey}`"
            :value="fields"
            :fixed-fields="fixedFields"
            :disabled="disabled"
            @input="emitFields"
        />
    </div>
</template>

<script>
    import {
        FIELD_TYPE_TEXT,
        FIELD_TYPE_MOBILE,
        mapToRegistrationFields,
    } from '@/services/FieldService';
    import Field from '@/models/Field';
    import FieldRow from '@/components/controls/field-editor/FieldRow';
    import slug from 'slug';
    import {v4 as uuidv4} from 'uuid';
    import { mapState } from 'vuex';

    export default {
        name: 'RegistrationFieldsEditor',
        components: { FieldRow },
        computed: {
            ...mapState('projects',['project']),
            showAddCompany() {
                return !!this.fields.find((f) => f.name === 'company');
            },
            showAddFirstname() {
                return !!this.fields.find((f) => f.name === 'first_name');
            },
            showAddTitle() {
                return !!this.fields.find((f) => f.name === 'title');
            },
            showAddMobileNumber(){
                return !!this.fields.find((f) => f.name === 'mobile');
            }
        },
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            label: String,
            useIds: {
                type: Boolean,
                default: false,
            },
            fixedFields: {
                type: Array,
                default: () => [],
            },
            disabled:{
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                fields: this.value.map((field) => new Field(field)),
                updateKey: 0,
            };
        },
        methods: {
            getFieldName(name) {
                let newName = name;
                let nameCounter = 0;
                let found = true;
                while (found) {
                    const field = this.fields.find((f) => f.name === newName);
                    if (!field) {
                        return newName;
                    }

                    ++nameCounter;
                    newName = name + '_' + nameCounter;
                }
            },
            addField() {
                this.fields.push(
                    new Field({
                        id: undefined,
                        name: this.getFieldName('new_field'),
                        type: FIELD_TYPE_TEXT,
                        required: false,
                        label: 'new_label',
                    })
                );
                ++this.updateKey;
                this.emitFields(this.fields);
            },
            addTitle() {
                this.fields.unshift(
                    new Field({
                        name: 'title',
                        type: FIELD_TYPE_TEXT,
                        required: true,
                        label: 'Title',
                    })
                );
                ++this.updateKey;
                this.emitFields(this.fields);
            },
            addFirstname() {
                const index = this.fields.map((item) => { return item.name; }).indexOf('last_name');
                this.fields.splice(index, 0, new Field({
                    name: 'first_name',
                    type: FIELD_TYPE_TEXT,
                    required: true,
                    label: 'Voornaam',
                }));
                ++this.updateKey;
                this.emitFields(this.fields);
            },
            addCompany() {
                const index = this.fields.map((item) => { return item.name; }).indexOf('email');
                this.fields.splice(index + 1 , 0, new Field({
                    name: 'company',
                    type: FIELD_TYPE_TEXT,
                    required: false,
                    label: 'Bedrijf',
                }));
                ++this.updateKey;
                this.emitFields(this.fields);
            },
            addMobileNumber() {
                let label = this.project.language === 'nlInformal' || this.project.language === 'nlFormal' ? 'Mobiel' : 'Mobile';
                const index = this.fields.map((item) => { return item.name; }).indexOf('email');
                this.fields.splice(index + 1 , 0, new Field({
                    name: 'mobile',
                    type: FIELD_TYPE_MOBILE,
                    required: false,
                    label: label,
                }));
                ++this.updateKey;
                this.emitFields(this.fields);
            },
            emitFields: function (fields) {
                const reservedFields = ['last_name', 'email', 'first_name', 'company', 'title', 'mobile', 'on_site_viewer'];
                const usedNames = [];
                this.fields = fields.map((field) => {
                    let newFieldName = field.name;
                    if(reservedFields.indexOf(newFieldName) === -1) {
                        newFieldName = slug(field.label, '_');
                        if(usedNames.indexOf(newFieldName) > -1) {
                            newFieldName = newFieldName + '_' + slug(uuidv4(), '_');
                        }
                        usedNames.push(newFieldName);
                    }

                    return {
                        ...field, name: newFieldName
                    };
                });
                this.$emit('input', mapToRegistrationFields(this.fields));
            },
        },
    };
</script>
<style scoped lang="scss">
.required {
    margin-left: 12.5rem;
}

.v-btn:not(.v-btn--outlined).secondary{
    color: #fff !important
}
</style>
