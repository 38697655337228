<template>
    <div class="px-8 item-list">
        <client-list-header />
        <v-card
            v-for="client in clients"
            :key="`client-list-${client.id}`"
            class="mt-1 list-item"
        >
            <v-row class="px-4">
                <v-col md="3">
                    {{ client.email }}
                    <br>
                    {{ client.name }}
                </v-col>
                <v-col md="8"
                       class="py-0"
                >
                    <v-chip-group plass="py-0">
                        <project-chip
                            v-for="project in client.clientProjects"
                            :key="`client-project-${project.projectId}`"
                            :project="project"
                            @close="showConfirmUnassignDialog(client, project)"
                        />
                    </v-chip-group>
                </v-col>
                <v-col md="1"
                       class="d-flex flex-row justify-end"
                >
                    <v-btn v-if="canEdit"
                           icon
                           small
                           color="primary"
                           @click="showEditClientDialog(client)"
                    >
                        <v-icon small>
                            edit
                        </v-icon>
                    </v-btn>

                    <v-btn v-if="canEdit"
                           icon
                           small
                           color="black"
                           @click="showAssignDialog(client)"
                    >
                        <v-icon small>
                            assignment_ind
                        </v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card>

        <edit-client-dialog
            v-if="editClient"
            :client="editClient"
            @close="hideEditClientDialog"
        />

        <assign-project-dialog
            v-if="assignClient"
            :client="assignClient"
            @close="hideAssignDialog"
        />

        <confirm-unassign-dialog
            v-if="confirmUnassign.visible"
            :client="confirmUnassign.client"
            :project="confirmUnassign.project"
            @close="hideConfirmUnassignDialog"
        />
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import ClientListHeader from './ClientListHeader';
    import AssignProjectDialog from './AssignProjectDialog';
    import EditClientDialog from './EditClientDialog';  
    import ProjectChip from '@/components/controls/user-management/ProjectChip';
    import ConfirmUnassignDialog from './ConfirmUnassignDialog';
    import User from '@/models/User';

    export default {
        name: 'ClientList',
        components: {
            EditClientDialog,
            AssignProjectDialog,
            ConfirmUnassignDialog,
            ProjectChip,
            ClientListHeader,
        },
        data() {
            return {
                assignClient: null,
                editClient: null,
                confirmUnassign: {
                    visible: false,
                    client: null,
                    project: null,
                },
            };
        },
        computed: {
            ...mapState('clients', ['clients']),
            ...mapGetters('auth', [
                'isQuadiaLiveAdmin',
                'isProjectEditorAtAnyProductionCompany',
                'isCompanyAdminAtAnyProductionCompany'
            ]),
            canEdit() {
                return this.isQuadiaLiveAdmin ||
                    this.isCompanyAdminAtAnyProductionCompany ||
                    this.isProjectEditorAtAnyProductionCompany;
            }
        },
        methods: {
            showConfirmUnassignDialog(client, project) {
                this.confirmUnassign.visible = true;
                this.confirmUnassign.client = client;
                this.confirmUnassign.project = project;
            },
            hideConfirmUnassignDialog() {
                this.confirmUnassign.visible = false;
            },
            showEditClientDialog(client) {
                this.editClient = new User(client);
            },
            hideEditClientDialog() {
                this.editClient = null;
            },
            showAssignDialog(client) {
                this.assignClient = new User(client);
            },
            hideAssignDialog() {
                this.assignClient = null;
            },
        },
    };
</script>
