<template>
    <v-dialog
        v-model="value"
        persistent
        max-width="680"
    >
        <v-card>
            <v-card-title class="pb-0">
                <slot name="title" />
                <v-spacer />
                <v-btn icon
                       @click="cancel"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <slot name="content"
                      :toggleConfirm="toggleConfirm"
                />
            </v-card-text>
            <v-divider />
            <v-card-actions class=" px-4 ">
                <v-spacer />
                <slot name="additional-buttons" />
                <v-btn color="primary"
                       :disabled="!canSubmit"
                       @click="confirm"
                >
                    <slot name="confirm-title">
                        Yes
                    </slot>
                </v-btn>

                <v-btn color="default"
                       @click="cancel"
                >
                    <slot name="cancel-title">
                        Cancel
                    </slot>
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>
<script>
    export default {
        name: 'ConfirmModal',
        data(){
            return {
                value: false,
                loading : false,
                resolve: null,
                reject: null,
                canSubmit: true,
            };
        },
        methods:{
            async open() {
                return new Promise((resolve) => {
                    this.resolve = resolve;
                    this.value = true;
                });
            },
            closeModal(){
                this.value = false;
            },
            cancel() {
                this.closeModal();
                this.resolve(false);
            },
            confirm(){
                this.closeModal();
                this.resolve(true);
            },
            toggleConfirm(value) {
                this.canSubmit = value;
            }
        }
    };
</script>

<style scoped>

</style>