<template>
    <v-row>
        <v-col>
            <v-row class="no-top-bottom-gutters">
                <v-col lg="6"
                       sm="16"
                >
                    <v-text-field
                        v-model="mailFromName"
                        filled
                        class="text-input full-width-text"
                        label="Mail From Name"
                        :disabled="!edit"
                    />
                </v-col>
            </v-row>
            <v-row class="no-top-bottom-gutters">
                <v-col lg="12">
                    <div class="d-flex align-baseline">
                        <v-file-input
                            :key="headerImgKey"
                            :disabled="!edit"
                            label="Header image"
                            accept="image/jpg, image/jpeg, image/png, *.jpg, *.jpeg, *.png"
                            truncate-length="50"
                            show-size
                            :rules="headerImgRules"
                            @change="onFileSelect"
                        >
                            <template v-slot:append-outer>
                                <v-btn
                                    v-if="headerImgUrl"
                                    icon
                                    color="primary"
                                    title="Remove header image"
                                    :disabled="!edit"
                                    @click="removeHeaderImg"
                                >
                                    <v-icon>
                                        mdi-image-remove
                                    </v-icon>
                                </v-btn>
                            </template>
                        </v-file-input>
                        <email-header-hint />
                    </div>
                    <img v-if="!headerImgUploading"
                         :src="headerImgUrl"
                         class="header-image"
                    >
                    <v-progress-circular v-else
                                         indeterminate
                                         color="primary"
                    />
                    <image-cropper :file="selectedFile"
                                   @save="uploadHeaderImg"
                                   @cancel="headerImgKey++"
                    />
                </v-col>
            </v-row>
            <v-row class="no-top-bottom-gutters">
                <v-col lg="6"
                       sm="12"
                >
                    <v-text-field
                        v-model="salutation"
                        filled
                        class="text-input full-width-text"
                        label="Salutation"
                        :disabled="!edit"
                    />
                </v-col>
                <v-col lg="6"
                       sm="12"
                >
                    <v-select
                        v-model="nameSetup"
                        :items="nameSetupOptions"
                        item-text="label"
                        item-value="value"
                        item-disabled="disabled"
                        class="mt-0"
                        hide-details
                        filled
                        :disabled="!edit"
                    >
                        <template v-slot:item="data">
                            <div v-if="data.item.disabled">
                                {{ data.item.label }}
                                <span class="select-subtext">In order to use this option, please add the related fields (as required) on registration page settings.</span>
                            </div>
                            <div v-else>
                                {{ data.item.label }}
                            </div>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col>
                    <div class="mb-2 mt-4 font-weight-bold">
                        Footer text
                    </div>
                    <rich-text-editor v-model="footerText"
                                      :disabled="!edit"
                                      :with-event-links="false"
                                      :with-font-selector="false"
                    />
                </v-col>
            </v-row>
            <div v-if="!project.isArchived">
                <v-btn
                    v-if="!edit"
                    class="mt-5 mr-4 pa-2"
                    color="primary"
                    outlined
                    @click="editEmail"
                >
                    <v-icon class="mr-1">
                        mdi-pencil
                    </v-icon>
                    EDIT CONTENT
                </v-btn>
                <v-btn
                    v-else
                    class="mt-5 mr-4 pa-2"
                    color="primary"
                    @click="saveAndRefresh"
                >
                    <v-icon class="mr-1">
                        mdi-content-save
                    </v-icon>
                    SAVE CONTENT
                </v-btn>
            </div>
            <v-row class="mt-6">
                <v-col>
                    <email-list />
                </v-col>
            </v-row>
        </v-col>
        <v-divider vertical />
    </v-row>
</template>

<script>
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import { populateUrl, AUTOSAVE_PROJECT_URL, EMAIL_HEADER_IMAGE_UPLOAD_URL } from '@/services/ApiService';
    import { $http } from '@/services/http';
    import EmailList from '@/components/pages/Project/Elements/EmailList';
    import EmailHeaderHint from '@/components/controls/hints/EmailHeaderHint';
    import ImageCropper from '@/components/controls/ImageCropper';


    export default {
        name: 'MailingSettings',
        components: {EmailHeaderHint, RichTextEditor, EmailList, ImageCropper},
        data() {
            return {
                nameSetupOptions: [
                    {label: 'Last name', value: 'last_name', disabled: false},
                    {label: 'First name Last name', value: 'first_name-last_name', disabled: false},
                    {label: 'First name', value: 'first_name', disabled: false},
                    {label: 'Title First name Last name', value: 'title-first_name-last_name', disabled: false},
                    {label: 'Title Last name', value: 'title-last_name', disabled: false},
                ],
                edit: false,
                mailFromName: '',
                salutation: '',
                footerText: '',
                nameSetup:'',
                headerImgKey: 0,
                headerImgUploading: false,
                headerImgRules: [
                    value => !value || value.size < 100000 || 'Header image size should be less than 100 KB!',
                ],
                selectedFile: ''
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['currentProjectId']),
            headerImgUrl: {
                get() {
                    return this.project.emailSettings.headerImgUrl;
                },
                set(value) {
                    this.setHeaderImgUrl(value);
                },
            },
        },
        watch: {
            project: {
                deep: true,
                handler() {
                    this.updateAllowedNamingValues();
                },
            },
            'project.emailSettings.mailFromName': {
                immediate:true,
                handler(value){
                    this.mailFromName = value;
                }
            },
            'project.emailSettings.salutation': {
                immediate:true,
                handler(value){
                    this.salutation = value;
                }
            },
            'project.emailSettings.footerText': {
                immediate:true,
                handler(value){
                    this.footerText = value;
                }
            },
            'project.emailSettings.nameSetup': {
                immediate:true,
                handler(value){
                    this.nameSetup = value;
                }
            },
            'project.emailSettings.headerImg': {
                immediate:true,
                handler(value){
                    this.headerImg = value;
                }
            },
        },
        methods: {
            ...mapActions('projects', ['saveProject']),
            ...mapMutations('projects', [
                'setMailFromName',
                'setMailSalutation',
                'setMailFooterText',
                'setMailNameSetup',
                'setHeaderImg',
                'setHeaderImgUrl',
            ]),
            updateAllowedNamingValues() {
                if (!this.project || !this.project.registration) {
                    return;
                }

                let fields = this.project.registration.fields.reduce(function (r, e) {
                    r[e.name] = e.required;
                    return r;
                }, {});

                this.nameSetupOptions.map(item => {
                    if (item.value === 'last_name') {
                        item.disabled = !fields['last_name'];
                    } else if (item.value === 'first_name-last_name') {
                        item.disabled = !fields['last_name'] || !fields['first_name'];
                    } else if (item.value === 'first_name') {
                        item.disabled = !fields['first_name'];
                    } else if (item.value === 'title-first_name-last_name') {
                        item.disabled = !fields['title'] || !fields['last_name'] || !fields['first_name'];
                    } else if (item.value === 'title-last_name') {
                        item.disabled = !fields['title'] || !fields['last_name'];
                    }
                    return item;
                });
            },
            editEmail(){
                this.edit = true;
            },
            async saveAndRefresh(){
                this.edit = false;
                this.setMailFromName(this.mailFromName);
                this.setMailSalutation(this.salutation);
                this.setMailFooterText(this.footerText);
                this.setMailNameSetup(this.nameSetup);
                this.setHeaderImg(this.headerImg);
                var url = populateUrl(AUTOSAVE_PROJECT_URL, this.currentProjectId);
                await $http.put(url, {
                    'emailSettings.mailFromName' : this.mailFromName,
                    'emailSettings.salutation': this.salutation,
                    'emailSettings.footerText': this.footerText,
                    'emailSettings.nameSetup': this.nameSetup,
                    'emailSettings.headerImg': this.headerImg,
                });
                this.$emit('refreshPreview');

            },
            async uploadHeaderImg(file) {
                if (file === undefined || file.size > 100000) {
                    return Promise.reject();
                }
                const formData = new FormData();
                formData.append('emailHeader', file);
                this.headerImgUploading = true;
                return $http.post(EMAIL_HEADER_IMAGE_UPLOAD_URL, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                }).then((res)=>{
                    this.headerImgUrl = res.data.url;
                    this.headerImg = res.data.key;
                }).finally(()=>{
                    this.headerImgUploading = false;
                });
            },
            async removeHeaderImg(){
                this.setHeaderImg('');
                this.setHeaderImgUrl('');
                this.headerImgKey++;
            },
            onFileSelect(e){
                this.selectedFile = e;
            }
        },
        mounted() {
            this.updateAllowedNamingValues();
        }
    };
</script>
<style scoped>
.text-input {
  width: 22rem;
}
.no-top-bottom-gutters > div{
  padding-bottom: 0;
  padding-top: 0;
}
.full-width-text{
  width: 100%;
}
.select-subtext{
  display: block;
  font-size: 11px;
}
.header-image {
  max-width: 40%;
}
</style>
