<template>
    <div class="preview-inner">
        <v-row v-if="actions"
               class="my-4"
               no-gutters
        >
            <v-col>
                <h2 class="font-weight-regular my-3">
                    Preview
                </h2>
            </v-col>
            <v-spacer />
            <v-badge
                :value="hoverMobile"
                content="414x736"
                left
                offset-x="55"
                transition="slide-x-transition"
            >
                <v-hover v-model="hoverMobile">
                    <v-btn class="mx-1 pb-0"
                           large
                           icon
                           @click="mobileScreen"
                    >
                        <v-icon>mdi-cellphone</v-icon>
                    </v-btn>
                </v-hover>
            </v-badge>
            <v-badge
                :value="hoverTablet"
                content="768x1024"
                left
                offset-x="60"
                transition="slide-x-transition"
            >
                <v-hover v-model="hoverTablet">
                    <v-btn class="mx-2 pb-0"
                           large
                           icon
                           @click="tabletScreen"
                    >
                        <v-icon large>
                            mdi-tablet
                        </v-icon>
                    </v-btn>
                </v-hover>
            </v-badge>
            <v-badge
                :value="hoverDesktop"
                content="1920x1080"
                left
                offset-x="65"
                transition="slide-x-transition"
            >
                <v-hover v-model="hoverDesktop">
                    <v-btn class="mx-2"
                           large
                           icon
                           @click="desktopScreen"
                    >
                        <v-icon large>
                            mdi-monitor
                        </v-icon>
                    </v-btn>
                </v-hover>
            </v-badge>
            <v-btn
                v-if="!wide"
                class="text-capitalize mx-3 mr-4"
                @click="loadPreview"
            >
                Refresh
            </v-btn>
        </v-row>
        <v-divider v-if="actions" />
        <v-row no-gutters
               class="iframe-row"
               :class="{'iframe-row-fullscreen' : fullScreen}"
        >
            <div v-if="!fullScreen"
                 class="mt-2 wrap"
            >
                <div v-if="loading"
                     class="d-flex flex-column align-content-center align-center justify-center"
                     style="height: 300px;"
                >
                    <p>This may take a few seconds.</p>
                    <v-progress-circular
                        indeterminate
                    />
                </div>
                <iframe v-else
                        :key="key"
                        :src="frontendAppUrl"
                        :width="width"
                        :height="height"
                        class="frame pt-0"
                        :style="styleScale"
                        frameborder="1"
                />
            </div>
            <iframe
                v-else
                :key="key"
                :src="frontendAppUrl"
                :width="width"
                class="full-frame pt-0"
                :frameborder="desktop ? 0 : 1"
                :allowfullscreen="true"
            />
        </v-row>
    </div>
</template>


<script>
    export default {
        name: 'FrontendPreview',
        props: {
            page: {
                type: String,
                default: () => '',
            },
            eventID: {
                type: String,
                default: () => '',
            },
            companyID: {
                type: String,
                default: () => '',
            },
            wide: {
                type: Boolean,
                default: () => false,
            },
            actions: {
                type: Boolean,
                default: () => true,
            },
            mobile: {
                type: Boolean,
                default: () => false,
            },
            tablet: {
                type: Boolean,
                default: () => false,
            },
            desktop: {
                type: Boolean,
                default: () => false,
            },
            loading: {
                type: Boolean,
                default: () => false,
            },
            fullScreen: {
                type: Boolean,
                default: () => false
            },
            showEventSelector:{
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                key: 0,
                width: '1920px',
                height: '1080px',
                scale: 0.34,
                wideScale: 0.58,
                decrement: 0,
                maxWidth: 0,
                maxHeight: 0,
                hoverMobile: false,
                hoverTablet: false,
                hoverDesktop: false,
            };
        },
        computed: {
            frontendAppUrl() {
                let obj = {
                    registration: process.env.VUE_APP_FRONTEND_REGISTRATION_PAGE_URL.replace(
                        '{eventID}',
                        this.eventID
                    ),
                    'registration-company': process.env.VUE_APP_FRONTEND_REGISTRATION_COMPANY_URL.replace(
                        '{companyID}',
                        this.companyID
                    ),
                    'registration-confirmation': process.env.VUE_APP_FRONTEND_REGISTRATION_CONFIRMATION_PAGE_URL.replace(
                        '{eventID}',
                        this.eventID
                    ),
                    'on-site-registration-confirmation': process.env.VUE_APP_FRONTEND_REGISTRATION_CONFIRMATION_PAGE_URL.replace(
                        '{eventID}',
                        this.eventID
                    ),
                    splash:
                        process.env.VUE_APP_FRONTEND_EVENT_PAGE_URL.replace(
                            '{eventID}',
                            this.eventID
                        ) +
                        '?extraLiveDrectorConfig=' +
                        this.extraLiveDrectorConfig('pre'),
                    live:
                        process.env.VUE_APP_FRONTEND_EVENT_PAGE_URL.replace(
                            '{eventID}',
                            this.eventID
                        ) +
                        '?extraLiveDrectorConfig=' +
                        this.extraLiveDrectorConfig('on-air'),
                    end:
                        process.env.VUE_APP_FRONTEND_EVENT_PAGE_URL.replace(
                            '{eventID}',
                            this.eventID
                        ) +
                        '?extraLiveDrectorConfig=' +
                        this.extraLiveDrectorConfig('post'),
                    'on-demand': process.env.VUE_APP_FRONTEND_ONDEMAND_PAGE_URL.replace(
                        '{eventID}',
                        this.eventID
                    ),
                    evaluation: process.env.VUE_APP_FRONTEND_EVALUATION_PAGE_URL.replace(
                        '{eventID}',
                        this.eventID
                    ),
                    'on-site-evaluation': process.env.VUE_APP_FRONTEND_ON_SITE_EVALUATION_PAGE_URL.replace(
                        '{eventID}',
                        this.eventID
                    ),
                };
                let url = new URL(obj[this.page]);
                const previewModeValue = this.page === 'on-site-registration-confirmation' ? 'on-site' : '';
                url.searchParams.append('previewMode', previewModeValue);
                return decodeURIComponent(url.toString());
            },
            styleScale() {
                return { 'transform': `scale(${this.scale})` };
            },
        },
        created() {
            window.addEventListener('resize', this.setScale);
            this.maxWidth = window.innerWidth;
            this.maxHeight = window.innerHeight;
            this.getScale();
        },
        destroyed() {
            window.removeEventListener('resize', this.setScale);
        },
        methods: {
            extraLiveDrectorConfig(state) {
                return btoa(
                    JSON.stringify({
                        extraTransmitterConnectionParams: {
                            cockpit: 1,
                        },
                        fixedState: {
                            'controller-switch': {
                                state: {
                                    state,
                                },
                            },
                        },
                    })
                );
            },
            getScale(){
                let adjust = 0;
                if(this.wide){
                    adjust = 115;
                } else if (this.showEventSelector){
                    adjust = -70;
                }
                if(window.innerWidth >= 2500 ){
                    this.scale = ((this.maxWidth/(190 - adjust))*100)/this.maxWidth;
                }
                if (window.innerWidth > 2250 && window.innerWidth < 2500) {
                    this.scale = ((this.maxWidth/(200 - adjust))*100)/this.maxWidth;
                }
                if (window.innerWidth > 2000 && window.innerWidth < 2250) {
                    this.scale = ((this.maxWidth/(230 - adjust))*100)/this.maxWidth;
                }
                if (window.innerWidth > 1904 && window.innerWidth <= 2000) {
                    this.scale = ((this.maxWidth/(245 - adjust))*100)/this.maxWidth;
                }
                if (window.innerWidth >= 1800 && window.innerWidth <= 1904) {
                    this.scale = ((this.maxWidth/(260 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 1750 && window.innerWidth < 1800 ){
                    this.scale = ((this.maxWidth/(275 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 1700 && window.innerWidth < 1750 ){
                    this.scale = ((this.maxWidth/(290 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 1650 && window.innerWidth < 1700 ){
                    this.scale = ((this.maxWidth/(305 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 1600 && window.innerWidth < 1650 ){
                    this.scale = ((this.maxWidth/(320 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 1550 && window.innerWidth < 1600 ){
                    this.scale = ((this.maxWidth/(335 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 1500 && window.innerWidth < 1550 ){
                    this.scale = ((this.maxWidth/(350 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 1450 && window.innerWidth < 1500 ){
                    this.scale = ((this.maxWidth/(365 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 1300 && window.innerWidth < 1450 ){
                    this.scale = ((this.maxWidth/(380 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 1250 && window.innerWidth < 1300 ){
                    this.scale = ((this.maxWidth/(395 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 1200 && window.innerWidth < 1250 ){
                    this.scale = ((this.maxWidth/(410 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 1150 && window.innerWidth < 1200 ){
                    this.scale = ((this.maxWidth/(425 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 1100 && window.innerWidth < 1150 ){
                    this.scale = ((this.maxWidth/(440 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 1050 && window.innerWidth < 1100 ){
                    this.scale = ((this.maxWidth/(455 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 1000 && window.innerWidth < 1050 ){
                    this.scale = ((this.maxWidth/(470 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth >= 960 && window.innerWidth < 1000 ){
                    this.scale = ((this.maxWidth/(485 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth <  960 ){
                    this.scale = ((this.maxWidth/(350 - adjust))*100)/this.maxWidth;
                }
                if(window.innerWidth <  600 ){
                    this.scale = ((this.maxWidth/(500 - adjust))*100)/this.maxWidth;
                }
            },
            loadPreview() {
                this.key = this.key + 1;
            },
            mobileScreen() {
                this.width = '414px';
                this.height = '736px';
                this.key++;
            },
            tabletScreen() {
                this.width = '768px';
                this.height = '1024px';
                this.key++;

            },
            desktopScreen() {
                this.width = ' 1920px';
                this.height = '1080px';
                this.key++;

            },
            setScale() {
                this.getScale();
            },
        },
        watch: {
            mobile(newValue) {
                if (newValue) {
                    this.mobileScreen();
                }
            },
            tablet(newValue) {
                if (newValue) {
                    this.tabletScreen();
                }
            },
            desktop(newValue) {
                if (newValue) {
                    this.desktopScreen();
                }
            },
        },

    };
</script>

<style scoped>
.wrap {
    width: auto;
    padding: 0;
    overflow: hidden;
}

.frame {
    overflow: hidden;
    transform-origin: 0 0;
}

.full-frame{
    height: 100%;

}


.iframe-row {
    justify-content: center;
    align-items: center;
}

/* .iframe-row-fullscreen{
    border-bottom: 24px solid #f5f5f5;
} */

</style>
