<template>
    <v-navigation-drawer 
        permanent
        app
        width="72"
        clipped
    >
        <router-link
            class="text-decoration-none left-side-link"
            :class="{'active-menu': activeProjects}"
            :to="{ name: 'Home' }"
        >
            <div class="iconcontainer my-3 justify-center align-center">
                <v-icon class="icon">
                    mdi-view-list
                </v-icon>
                <span class="span p-0">Projects</span>
            </div>
        </router-link>
        <router-link
            class="text-decoration-none left-side-link"
            :class="{'active-menu': activeArchives}"
            :to="{ name: 'Archives' }"
        >
            <div class="iconcontainer my-3 justify-center align-center">
                <v-icon class="icon">
                    mdi-archive
                </v-icon>
                <span class="span p-0">Archives</span>
            </div>
        </router-link>
        <router-link
            v-if="canAccessIntakes"
            class="text-decoration-none left-side-link"
            :class="{'active-menu': activeIntakes}"
            :to="{ name: 'Intake' }"
        >
            <div class="iconcontainer my-3 justify-center align-center">
                <i class="material-icons icon pl-2">question_answer_outlined</i>
                <span class="span p-0">Intakes</span>
            </div>
        </router-link>

        <router-link
            v-if="canAccessCompanies"
            class="text-decoration-none left-side-link"
            :class="{'active-menu': activeCompanies}"
            :to="{ name: 'Companies' }"
        >
            <div class="iconcontainer my-3 justify-center align-center">
                <v-icon class="icon">
                    mdi-view-list
                </v-icon>
                <span class="span p-0">Companies</span>
            </div>
        </router-link>

        <router-link
            v-if="canAccessUsers"
            class="text-decoration-none left-side-link"
            :class="{'active-menu': activeUsers}"
            :to="{ name: 'Users' }"
        >
            <div class="iconcontainer my-3 justify-center align-center">
                <v-icon class="icon">
                    mdi-account-multiple
                </v-icon>
                <span class="span p-0">Users</span>
            </div>
        </router-link>

        <router-link
            v-if="canAccessClients"
            class="text-decoration-none left-side-link"
            :class="{'active-menu': activeClients}"
            :to="{ name: 'Clients' }"
        >
            <div class="iconcontainer my-3 justify-center align-center">
                <v-icon class="icon">
                    mdi-account-box-multiple
                </v-icon>
                <span class="span p-0">Clients</span>
            </div>
        </router-link>
    </v-navigation-drawer>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import {ProjectsMeta, ArchivesMeta, IntakesMeta, UsersMeta, ClientsMeta, OverviewMeta, CompaniesMeta} from '@/router/menu-constants';
    export default {
        name: 'NavigationDrawer',
        data(){
            return{
                menu: null
            };
        },
        computed: {
            ...mapGetters('auth', [
                'isQuadiaLiveAdmin',
                'isCompanyAdminAtAnyProductionCompany',
                'isProjectEditorAtAnyProductionCompany',
                'canAccessIntakes'
            ]),
            ...mapState('projects', ['project', 'loadingProject']),
            canAccessCompanies() {
                return (
                    this.isQuadiaLiveAdmin ||
                    this.isCompanyAdminAtAnyProductionCompany ||
                    this.isProjectEditorAtAnyProductionCompany
                );
            },
            canAccessUsers() {
                return (
                    this.isQuadiaLiveAdmin ||
                    this.isCompanyAdminAtAnyProductionCompany ||
                    this.isProjectEditorAtAnyProductionCompany
                );
            },
            canAccessClients() {
                return (
                    this.isQuadiaLiveAdmin ||
                    this.isCompanyAdminAtAnyProductionCompany ||
                    this.isProjectEditorAtAnyProductionCompany
                );
            },
            activeProjects(){
                if(this.menu === ProjectsMeta){
                    return true;
                }
                return this.menu === OverviewMeta && !this.project.isArchived && !this.loadingProject;
            },
            activeArchives(){
                if(this.menu === ArchivesMeta){
                    return true;
                }
                return this.menu === OverviewMeta && this.project.isArchived && !this.loadingProject;
                
            },
            activeCompanies(){
                return this.menu === CompaniesMeta;
            },
            activeClients(){
                return this.menu === ClientsMeta;
            },
            activeUsers(){
                return this.menu === UsersMeta;
            },
            activeIntakes(){
                return this.menu === IntakesMeta;
            }
        },
        watch:{
            $route:{
                handler(to){
                    this.menu = to.meta.menu;
                },
                immediate: true
            }
        }
    };
</script>

<style scoped>
.material-icons {
  width: 2rem;
}

.iconcontainer {
  margin: 0;
  width: 71px;
  height: 45px;
  /* padding-left: 23px; */
  text-align: center;
  display: grid;
}

.left-side-link {
  display: grid;
  justify-content: center;
}

.left-side-link .span {
  font-size: 72%;
  color: #454545;
}

.left-side-link .icon {
  color: #454545;
}

.left-side-link:hover .icon {
  color: #454545;
}

.left-side-link:hover .span {
  color: #454545;
}

.left-side-link:hover {
  background-color: rgba(41, 119, 190, 0.08);
}

.left-side-link.active-menu {
  background-color: rgba(41, 119, 190, 0.5);
  border-left: 3px solid #2977be;
}
.left-side-link.active-menu .icon {
  color: #2977be;
}

.left-side-link.active-menu .span {
  color: #2977be;
}

.left-side-link.active-menu:hover {
  background-color: rgba(41, 119, 190, 0.65);
}

.left-side-link.active-menu:hover .span {
  color: #2977be;
}

.left-side-link.active-menu:hover .icon {
  color: #2977be;
}



</style>
