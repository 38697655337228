<template>
    <div class="py-2">
        <div class="d-flex flex-row">
            <label class="py-2">Upcoming events</label>
            <upcoming-events-selector :key="`selector-${internalValue.length}`"
                                      :value="internalValue"
                                      @input="internalValueChange"
            />
        </div>
        <upcoming-events-list :key="`list-${internalValue.length}`"
                              :value="internalValue"
                              :items="internalValue"
                              delete-mode
                              @input="internalValueChange"
        >
            <template v-slot:no-items>
                No upcoming events selected
            </template>
        </upcoming-events-list>
    </div>
</template>
<script>
    import UpcomingEventsList from '@/components/controls/upcoming-events/UpcomingEventsList.vue';
    import UpcomingEventsSelector from '@/components/controls/upcoming-events/UpcomingEventsSelector.vue';

    export default {
        name: 'UpcomingEventsEditor',
        components: {UpcomingEventsSelector, UpcomingEventsList},
        props: {
            value: {
                type: Array,
                default: () => [],
            }
        },
        data() {
            return {
                internalValue: this.value
            };
        },
        methods: {
            internalValueChange(value) {
                this.internalValue = value;
                this.$emit('input', value);
            }
        }
    };
</script>

<style scoped>

</style>