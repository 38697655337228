<template>
    <div>
        <v-tooltip right>
            <template v-slot:activator="{ on }">
                <v-btn
                    fab
                    x-small
                    elevation="1"
                    color="white"
                    :disabled="project.isArchived"
                    v-on="on"
                    @click.stop="moveOutOfColumn({block, saveUrl})"
                >
                    <v-icon small>
                        mdi-undo-variant
                    </v-icon>
                </v-btn>
            </template>
            <span>Move out of column</span>
        </v-tooltip>
    </div>
</template>

<script>
    import { mapActions, mapState } from 'vuex';

    export default {
        name: 'MoveOutOfColumnButton',
        props: {
            block: {
                type: Object,
                required: true,
            },
            saveUrl: {
                type: String,
                required: true,
            },
        },
        computed:{
            ...mapState('projects', ['project'])
        },
        methods: {
            ...mapActions('dynamic-block', ['moveOutOfColumn']),
        },
    };
</script>

<style scoped>
      .move-out-of-column-button {
        position: absolute;
        bottom: 0;
        right: 0;
      }
</style>