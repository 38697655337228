import { render, staticRenderFns } from "./DeviceStats.vue?vue&type=template&id=3eb7ddf2&scoped=true&"
import script from "./DeviceStats.vue?vue&type=script&lang=js&"
export * from "./DeviceStats.vue?vue&type=script&lang=js&"
import style0 from "./DeviceStats.vue?vue&type=style&index=0&id=3eb7ddf2&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3eb7ddf2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VDivider,VRow})
