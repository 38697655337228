<template>
    <v-row>
        <v-col>
            <h3 class="mb-2 font-weight-bold">
                Settings
            </h3>
            <v-divider class="mb-2" />
            <div class="d-inline-flex mb-10">
                <div class="mt-1 mr-4 d-inline-block">
                    Hide evaluation button
                </div>
                <v-switch
                    v-model="evaluationButtonHide"
                    :input-value="evaluationButtonHide"
                    inset
                    class="mt-0"
                    hide-details
                    :disabled="project.isArchived"
                />
            </div>
            <div v-if="!endPageCrispChatOptionsDisabled"
                 class="d-inline-flex mb-10"
            >
                <div class="mt-1 mr-4 d-inline-block">
                    Show Crisp Chat in side bar
                </div>
                <v-switch v-model="showCrispChat"
                          :input-value="showCrispChat"
                          inset
                          class="mt-0"
                          hide-details
                />
            </div>
            <h3 class="mb-2 font-weight-bold">
                End page body text
            </h3>
            <v-divider class="mb-10" />
            <div class="px-4">
                <rich-text-editor v-model="bodyText"
                                  :disabled="project.isArchived"
                                  class="rich-text-editor"
                />
            </div>
            <h3 class="mb-2 mt-15 font-weight-bold">
                Handouts
            </h3>
            <v-divider class="mb-4" />
            <handout-editor v-model="handouts"
                            class="px-4"
            />
        </v-col>
    </v-row>
</template>

<script>
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import HandoutEditor from '../../../controls/handouts/HandoutEditor';
    export default {
        name: 'EndEventTab',
        components: {HandoutEditor, RichTextEditor },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['isMultiSession']),
            bodyText: {
                get() {
                    return this.project.endPage.content;
                },
                async set(value) {
                    this.setProjectEndPageContent(value);
                    await this.saveProject({
                        id: this.project._id,
                        key: 'endPage.content',
                        value: value,
                    });
                    this.$emit('refreshPreview');
                },
            },
            evaluationButtonHide: {
                get() {
                    return this.project.evaluationButton.hide;
                },
                set(value){
                    this.setEvaluationButtonHide(value);
                    this.saveProject({
                        key: 'evaluationButton.hide',
                        value: value,
                    });
                    this.$emit('refreshPreview');
                }
            },
            showCrispChat: {
                get() {
                    return this.project.endPage.showCrispChat;
                },
                async set(value) {
                    this.setShowCrispChat(value);
                    await this.saveProject({
                        key: 'endPage.showCrispChat',
                        value,
                    });
                    this.$emit('refreshPreview');
                }
            },
            endPageCrispChatOptionsDisabled() {
                if (this.isMultiSession) {
                    return true;
                }

                console.log('not multi');

                if (this.project.isArchived) {
                    return true;
                }

                console.log('not archive');

                return !this.project.events[0].crispChatEvent
                    || this.project.events[0].chatType !== 'crisp';
            },
            handouts: {
                get() {
                    return this.project.endPage.handouts;
                },
                async set(value) {
                    this.setProjectEndPageHandouts(value);
                    await this.saveProject({
                        id: this.project._id,
                        key: 'endPage',
                        value: {'handouts': value},
                    });
                    this.$emit('refreshPreview');
                }
            },
        },
        methods: {
            ...mapActions('projects', ['saveProject']),
            ...mapMutations('projects', [
                'setProjectEndPageContent',
                'setEvaluationButtonHide',
                'setProjectEndPageHandouts',
                'setShowCrispChat'
            ]),
        },
    };
</script>

<style scoped>
.rich-text-editor{
    height: 23rem;
}
</style>
