<template>
    <div>
        <h3 class="font-weight-regular mt-4 mb-5">
            Client permissions
        </h3>
        <v-alert color="info"
                 text
                 dense
                 type="info"
                 outlined
        >
            <p class="small">
                This section allows you to apply the same client permissions as your previous projects.<br>
                Click one to apply it to this project too. Click the selected item again to remove the assignments.<br><br>
                The following client permissions have been set on previous projects.
            </p>
        </v-alert>
        <v-list v-if="!loading"
                dense
        >
            <v-list-item-group v-model="internalValue"
                               color="primary"
            >
                <template v-for="(assignment, index) in assignments">
                    <v-list-item :key="index"
                                 :value="assignment"
                    >
                        <v-list-item-content class="d-flex flex-row assignment">
                            <div v-for="user in assignment"
                                 :key="`assigment-user-${index}-${user.user_id}}`"
                                 class="assignment-item d-flex flex-column flex-wrap flex-grow-0 flex-shrink-0 mr-2"
                            >
                                <div class="client-name">
                                    {{ user.user_name }}
                                </div>
                                <div class="client-email">
                                    {{ user.email }}
                                </div>
                            </div>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="'div-' + index" />
                </template>
            </v-list-item-group>
        </v-list>
        <v-progress-circular v-else />
    </div>
</template>
<script>
    import { makeUrl } from '@/services/ApiService';
    import { $http } from '@/services/http';

    export default {
        name: 'ClientAssignments',
        props: {
            value: {
                type: Array,
                default: () => []
            },
            company: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                assignments: [],
                loading: false,
                internalValue: this.value,
            };
        },
        watch: {
            internalValue (value) {
                if (typeof value === 'undefined') {
                    this.emitValue([]);
                    return;
                }
                this.emitValue(value);
            }
        },
        methods: {
            async getClientAssignments()
            {
                this.loading = true;
                const response = await $http.get(makeUrl('/client-companies/' + this.company + '/project-associations'));
                this.assignments = response.data;
                this.loading = false;
            },
            emitValue(value) {
                this.internalValue = value;
                this.$emit('input', value);
            },
        },
        mounted() {
            this.getClientAssignments();
        }
    };
</script>

<style scoped lang="scss">
     .assignment {
         align-items: flex-start;
         gap: 10px;
     }
    .assignment-item {
        flex: 0 0 auto;
        background-color: #f0f0f0;
        padding: 5px 20px;
        border-radius: 20px;
        margin-bottom: 0 !important;
    }

    .client-name,
    .client-email {
        flex: 0 0 100%;
    }

    .small {
        font-size: 12px;
        line-height: 14px;
        margin: 0;
    }
</style>