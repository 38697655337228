import BaseAggregator from '@/services/BaseAggregator';

class UserAgentDeviceAggregator extends BaseAggregator {
    getResults(data) {
        const total = data.length;
        const result = {};
        for (let item in data) {
            const deviceName = this.getDeviceName(data[item].agent?.device?.type);
            if (!result[deviceName]) {
                result[deviceName] = 0;
            }
            result[deviceName] += 1;
        }

        return this.withPercentages(result, total);
    }

    getDeviceName(deviceType) {
        return deviceType ?? 'desktop';
    }
}

export default UserAgentDeviceAggregator;