<template>
    <v-row>
        <v-col>
            <v-row class="mb-15">
                <v-col cols="6"
                       class="border-right pr-6"
                >
                    <session-type-editor v-model="sessionType"
                                         class="mb-8"
                    />
                    <moderator-editor v-if="sessionType === 'jitsi-meet'"
                                      :key="moderator"
                                      v-model="moderator"
                    />
                </v-col>
                <v-col cols="6"
                       class="pl-6"
                >
                    <chat-tab-settings :event="event" />
                </v-col>
            </v-row>
            <div class="d-flex flex-column mb-15 px-4">
                <div class="d-inline-flex mb-4">
                    <div class="mt-1 d-inline-block"
                         style="margin-right: 17px"
                    >
                        Show polls over video
                    </div>
                    <v-switch
                        v-model="showPollsOverVideo"
                        :input-value="showPollsOverVideo"
                        :disabled="project.isArchived"
                        inset
                        class="mt-0"
                        hide-details
                    />
                </div>
                <div v-if="showQuizToggle"
                     class="d-inline-flex mb-4"
                >
                    <div class="mt-1 d-inline-block"
                         style="margin-right: 21px"
                    >
                        Show quiz over video
                    </div>
                    <v-switch
                        v-model="showQuizOverVideo"
                        :input-value="showQuizOverVideo"
                        :disabled="project.isArchived"
                        inset
                        class="mt-0"
                        hide-details
                    />
                </div>
                <div v-if="!isMultiSession"
                     class="d-inline-flex mb-4"
                >
                    <div class="mt-1 d-inline-block"
                         style="margin-right: 38px"
                    >
                        Show program tab
                    </div>
                    <v-switch
                        v-model="showProgramTab"
                        :input-value="showProgramTab"
                        :disabled="project.isArchived"
                        inset
                        class="mt-0"
                        hide-details
                    />
                </div>
            </div>
            <h3 class="mb-2 font-weight-bold">
                Live page body text
            </h3>
            <v-divider class="mb-10" />
            <div class="px-4 mb-15">
                <rich-text-editor
                    v-model="bodyText"
                    :disabled="project.isArchived"
                    class="rich-text-editor"
                />
            </div>
            <tab-order-editor :event="event"
                              class="mb-15"
            />

            <custom-tab-settings class="my-15" />
            <handout-tab-settings :event="event" />
        </v-col>
    </v-row>
</template>

<script>
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import {mapActions, mapGetters, mapMutations, mapState} from 'vuex';
    import SessionTypeEditor from '@/components/pages/Project/Elements/SessionTypeEditor';
    import ModeratorEditor from '@/components/pages/Project/Elements/ModeratorEditor';
    import TabOrderEditor from '@/components/controls/TabOrderEditor';
    import CustomTabSettings from '@/components/pages/Project/EventTabs/CustomTabSettings';
    import HandoutTabSettings from '@/components/pages/Project/EventTabs/HandoutTabSettings';
    import ChatTabSettings from '@/components/pages/Project/EventTabs/ChatTabSettings';

    export default {
        name: 'LiveEventTab',
        props: {
            event: {
                type: Object,
                required: true
            },
        },
        components: {
            ModeratorEditor,
            SessionTypeEditor,
            RichTextEditor,
            TabOrderEditor,
            CustomTabSettings,
            HandoutTabSettings,
            ChatTabSettings
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['isSpringerProject', 'isMultiSession']),
            showPollsOverVideo: {
                get() {
                    return this.project.showPollsOverVideo;
                },
                set(value) {
                    this.setProjectShowPollsOverVideo(value);
                    this.saveProject({
                        id: this.project._id,
                        key: 'showPollsOverVideo',
                        value: value,
                    });
                },
            },
            showQuizToggle() {
                return this.isSpringerProject;
            },
            showQuizOverVideo: {
                get() {
                    return this.project.showQuizOverVideo;
                },
                set(value) {
                    this.setProjectShowQuizOverVideo(value);
                    this.saveProject({
                        id: this.project._id,
                        key: 'showQuizOverVideo',
                        value: value,
                    });
                },
            },
            showProgramTab: {
                get() {
                    return this.project.showProgramTab;
                },
                set(value) {
                    this.setShowProgramTab(value);
                    this.saveProject({
                        id: this.project._id,
                        key: 'showProgramTab',
                        value: value,
                    });
                },
            },
            bodyText: {
                get() {
                    return this.event.livePageContent;
                },
                async set(value) {
                    this.setEventLivePageContent({eventID: this.event._id, value});
                    await this.saveEvent({
                        id: this.event._id,
                        key: 'livePageContent',
                        value: value,
                    });
                },
            },
            sessionType: {
                get() {
                    return this.event.sessionType || 'regular';
                },
                async set(value) {
                    await this.setSessionType({eventID: this.event._id, value});
                    this.saveEvent({
                        id: this.event._id,
                        key: 'sessionType',
                        value
                    });
                }
            },
            moderator: {
                get() {
                    return this.event.moderator || '';
                },
                async set (value) {
                    await this.setModerator({eventID: this.event._id, value});
                    this.saveEvent({
                        id: this.event._id,
                        key: 'moderator',
                        value
                    });
                }
            },
            livePageHandoutText: {
                get() {
                    return this.event.livePageHandoutText;
                },
                async set(value) {
                    this.setEventLivePageHandoutText({eventID: this.event._id, value});
                    await this.saveEvent({
                        id: this.event._id,
                        key: 'livePageHandoutText',
                        value: value,
                    });
                },
            },
        },
        methods: {
            ...mapActions('projects', ['saveEvent', 'saveProject']),
            ...mapMutations('projects',
                            ['setEventLivePageContent',
                             'setProjectShowPollsOverVideo',
                             'setProjectShowQuizOverVideo',
                             'setSessionType',
                             'setModerator',
                             'setShowProgramTab'
                            ]),
        },
    };
</script>

<style lang="scss">

.border-right{
    border-right: 1px solid #ccc;
}

.rich-text-editor{
    height: 23rem;
}

.drag-handle {
    cursor: grab;
}
</style>
