<template>
    <v-dialog :value="true"
              scrollable
              persistent
    >
        <v-card>
            <v-progress-linear v-if="loading"
                               indeterminate
            />
            <v-card-title>
                <h1 class="px-4 font-weight-regular page-header">
                    <span v-if="!user.id">Create</span>
                    <span v-else>Edit</span> user: {{ user.email }}
                </h1>
            </v-card-title>
            <v-card-subtitle>
                <user-details v-if="!loading"
                              :key="userDetailsKey"
                              v-model="user"
                              class="mt-4"
                              @validate="userDetailsValidated"
                              @input="++freelancerProjectKey"
                />
            </v-card-subtitle>
            <v-card-text v-if="user.role === 'freelancer' || isProjectEditorAtAnyProductionCompany">
                <v-divider class="my-2" />
                <h3 class="font-weight-regular black--text">
                    Freelancer projects
                </h3>
                <project-selector :assigned-projects="user.freelancerProjects"
                                  @assign="assign"
                                  @unassign="unAssign"
                />
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn :to="{name: 'Users'}">
                    Cancel
                </v-btn>
                <v-btn color="primary"
                       :disabled="!valid"
                       @click="saveUser"
                >
                    Save
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import UserDetails from '@/components/pages/Users/UserDetails';
    import { mapActions, mapGetters, mapMutations } from 'vuex';
    import User from '@/models/User';
    import ProjectSelector from '@/components/controls/user-management/ProjectSelector';

    export default {
        name: 'CreateUser',
        components: {ProjectSelector, UserDetails},
        data() {
            return {
                loading: false,
                valid: false,
                freelancerProjectKey: 0,
                userDetailsKey: 0,
            };
        },
        computed: {
            ...mapGetters('auth', ['isQuadiaLiveAdmin', 'isProjectEditorAtAnyProductionCompany']),
            user: {
                get() {
                    return this.$store.state.users.user;
                },
                set(value) {
                    this.setUser(value);
                }
            }
        },
        methods: {
            ...mapMutations('users', ['setUser']),
            ...mapActions('users', [
                'getUsers',
                'storeUser',
                'getUser',
            ]),
            ...mapActions('productionCompanies', ['getProductionCompanies']),
            saveUser: async function () {
                await this.storeUser();                
                this.$emit('user-created');                
            },
            async loadUser() {
                this.loading = true;
                await this.getProductionCompanies();
                this.setUser(new User({
                    role: this.isProjectEditorAtAnyProductionCompany ? 'freelancer' : null
                }));
                this.loading = false;
            },
            userDetailsValidated(valid) {
                this.valid = valid;
            },
            assign(project) {
                this.user.freelancerProjects.push(project);
            },
            unAssign(project) {
                this.user.freelancerProjects = this.user.freelancerProjects
                    .filter(p => p.projectId !== project.projectId);
            }
        },
        mounted() {
            this.loadUser();
        }
    };
</script>