<template>
    <div>
        <v-container v-if="isAuthenticated"
                     fluid
        >
            <project-list-title>
                <v-text-field v-model="searchText"
                              solo
                              flat
                              hide-details
                              dense
                              background-color="rgba(0,0,0,0.1)"
                              placeholder="Search"
                              clearable
                />
            </project-list-title>
            <div v-if="!loading">
                <div class="mx-8 mb-4">
                    Upcoming
                </div>
                <project-list part="upcoming"
                              class="upcoming"
                              @reload="loadUpcomingProjectEvents"
                />

                <div class="passed-line-marker mx-8 mt-4">
                    Past events
                </div>
                <project-list part="passed"
                              @reload="loadPassedProjectEvents"
                />

                <project-list-footer>
                    <template v-slot:page-size-selector-label>
                        Rows per page
                    </template>
                    <template v-slot:page-size-selector>
                        <v-select
                            v-model="rowsPerPageSelected"
                            :items="rowsPerPageItems"
                            :label="undefined"
                            :outlined="true"
                            :dense="true"
                            :hide-details="true"
                            @input="rowsPerPageChanged"
                        >
                            <template v-slot:selection="{ item }">
                                <span class="d-flex justify-end"
                                      style="width: 100%;"
                                >
                                    {{ item }}
                                </span>
                            </template>
                        </v-select>
                    </template>
                    <template v-slot:pagination>
                        <v-pagination v-if="pagination('passed').total > 1"
                                      v-model="currentPage"
                                      :length="pagination('passed').total"
                                      circle
                                      total-visible="6"
                                      color="secondary"
                        />
                    </template>
                </project-list-footer>
            </div>
            <v-progress-linear v-else
                               indeterminate
            />
        </v-container>
    </div>
</template>

<script>

    import { mapActions, mapGetters, mapMutations } from 'vuex';
    import {debounce} from 'lodash';
    import ProjectList from '@/components/pages/Projects/ProjectList';
    import ProjectListTitle from '@/components/pages/Projects/ProjectListTitle';
    import ProjectListFooter from '@/components/pages/Projects/ProjectListFooter';

    export default {
        name: 'Home',
        components: {ProjectListFooter, ProjectListTitle, ProjectList},
        data() {
            return {
                loading: true,
                inProgress: false,
                searchText: '',
                paginatorCurrentPageKey: 1,
                rowsPerPageItems: [10, 25, 50, 100],
                rowsPerPageSelected: 25
            };
        },
        watch: {
            searchText: debounce(function () {
                this.resetCurrentPage();
                this.loadUpcomingProjectEvents(true);
                this.loadPassedProjectEvents(true);
            },400),
            paginatorCurrentPageKey: debounce(function () {
                this.loadPassedProjectEvents(true);
            }, 400),
            sortUpcomingBy: debounce(function () {
                this.loadUpcomingProjectEvents(false);
            }, 400),
            sortPassedBy: debounce(function () {
                this.loadPassedProjectEvents(false);
            }, 400),
            sortUpcomingOrder: debounce(function () {
                this.loadUpcomingProjectEvents(false);
            }, 400),
            sortPassedOrder: debounce(function () {
                this.loadPassedProjectEvents(false);
            }, 400),
            headerUpcomingFilters: debounce(function () {
                this.resetCurrentPage();
                this.loadUpcomingProjectEvents(false);
            }, 400),
            headerPassedFilters: debounce(function () {
                this.resetCurrentPage();
                this.loadPassedProjectEvents(false);
            }, 400),
        },
        computed: {
            ...mapGetters('projectLists', ['items', 'pagination', 'listingHeader']),
            ...mapGetters('auth', ['isAuthenticated']),
            currentPage: {
                get() {
                    return this.pagination('passed').current;
                },
                set(value) {
                    this.paginatorCurrentPageKey += 1;
                    this.setCurrentPage({
                        listing: 'passed',
                        value
                    });
                }
            },
            sortUpcomingBy() {
                return this.listingHeader('upcoming').sorting.targetId;
            },
            sortPassedBy() {
                return this.listingHeader('passed').sorting.targetId;
            },
            sortUpcomingOrder() {
                return this.listingHeader('upcoming').sorting.direction;
            },
            sortPassedOrder() {
                return this.listingHeader('passed').sorting.direction;
            },
            headerUpcomingFilters() {
                return this.listingHeader('upcoming').filtering.filters;
            },
            headerPassedFilters() {
                return this.listingHeader('passed').filtering.filters;
            },
        },
        methods: {
            ...mapActions('projectLists', ['getItems']),
            ...mapMutations('projectLists', ['setCurrentPage']),
            async loadProjectEvents(reload) {
                this.loading = reload;
                await this.getItems({
                    listing: 'upcoming',
                    value: this.getParams('upcoming')
                });
                await this.getItems({
                    listing: 'passed',
                    value: this.getParams('passed')
                });
                this.loading = false;
            },
            async loadUpcomingProjectEvents(reload) {
                this.loading = reload;
                await this.getItems({
                    listing: 'upcoming',
                    value: this.getParams('upcoming')
                });
                this.loading = false;
            },
            async loadPassedProjectEvents(reload) {
                this.loading = reload;
                await this.getItems({
                    listing: 'passed',
                    value: this.getParams('passed')
                });
                this.loading = false;
            },
            resetCurrentPage() {
                this.setCurrentPage({
                    listing: 'passed',
                    value: 1
                });
            },
            rowsPerPageChanged() {
                window.localStorage.setItem('projectRowsPerPage', this.rowsPerPageSelected);
                this.resetCurrentPage();
                this.loadProjectEvents(true);
            },
            getParams(part) {
                const params = {};
                if (this.searchText) {
                    params['search'] = encodeURIComponent(this.searchText);
                }

                if (part === 'upcoming') {
                    params['perPage'] = 1000;
                } else {
                    if (this.currentPage && this.currentPage > 1) {
                        params['page'] = this.currentPage;
                    }

                    params['perPage'] = this.rowsPerPageSelected;
                }

                const sortBy = this.listingHeader(part).sorting.targetId;

                if(sortBy){
                    params['sort'] = sortBy;
                }
                const sortOrder = this.listingHeader(part).sorting.direction;

                if(sortOrder){
                    params['order'] = sortOrder;
                }

                const filters = this.listingHeader(part).filtering.filters;

                if(filters){
                    for(let k in filters){
                        params['filter['+k+']'] = filters[k].map(x => x.id);
                    }
                }
                params['filter[part]'] = part;

                return params;
            },
        },
        mounted() {
            let rowsPerPage = window.localStorage.getItem('projectRowsPerPage');
            if (rowsPerPage) {
                this.rowsPerPageSelected = parseInt(rowsPerPage);
            }
            this.resetCurrentPage();
            this.loadProjectEvents(true);
        }
    };
</script>

<style scoped>
.passed-line-marker{
    border-top: 1px solid lightgray;
    margin-top: 20px;
    margin-bottom: 10px;
    padding-top: 5px;
}
</style>
