<template>
    <span>
        <v-btn :disabled="project.isArchived"
               @click="openDialog"
        >
            <slot name="buttonText">Send test e-mail</slot>
        </v-btn>
        <v-dialog v-model="dialog"
                  :max-width="700"
        >
            <form action="#"
                  method="POST"
                  @submit.prevent="send"
            >
                <v-card>
                    <v-card-title>
                        <slot name="title">Send test e-mail</slot>
                    </v-card-title>
                    <v-card-text>
                        <slot />
                        <v-text-field v-model="testEmail"
                                      type="email"
                                      required
                                      label="E-mail address:"
                        />
                        <v-text-field v-if="registrationFields['title']"
                                      v-model="testTitle"
                                      required
                                      label="Title"
                        />
                        <v-text-field v-if="registrationFields['first_name']"
                                      v-model="testFirstName"
                                      required
                                      label="First Name"
                        />
                        <v-text-field v-model="testLastName"
                                      required
                                      label="Last Name"
                        />
                    </v-card-text>
                    <v-divider />
                    <v-card-actions>
                        <v-spacer />
                        <v-btn color="primary"
                               type="submit"
                               class="mr-2"
                               :loading="sending"
                               :disabled="sending"
                        >Send</v-btn>
                        <v-btn @click="closeDialog">Cancel</v-btn>
                    </v-card-actions>
                </v-card>
            </form>
        </v-dialog>
    </span>
</template>

<script>
    import {mapActions, mapGetters, mapState} from 'vuex';
    import {toastMessage, toastSuccess} from '@/services/responseErrors';

    export default {
        name: 'SendTestEmail',
        props: {
            url: {
                type: String,
                default: ''
            }
        },
        computed:{
            ...mapGetters('auth', ['user']),
            ...mapState('projects', ['project']),
            registrationFields() {
                return this.project.registration.fields.reduce(function (r, e) {
                    r[e.name] = e.required;
                    return r;
                }, {});
            }
        },
        data() {
            return {
                dialog: false,
                sending: false,
                testEmail: '',
                testLastName: '',
                testFirstName: '',
                testTitle: '',
            };
        },
        methods: {
            ...mapActions('projects', ['sendTestEmail']),
            openDialog() {
                this.testEmail = this.user.email;
                this.testTitle = 'Mevr.';
                this.testFirstName = 'Sanne';
                this.testLastName = 'Janssen';
                this.dialog = true;
            },
            closeDialog() {
                this.dialog = false;
            },
            send() {
                toastMessage('Sending...');
                this.sending = true;
                this.sendTestEmail({
                    url: this.url,
                    settings: {
                        email: this.testEmail,
                        title: this.testTitle,
                        first_name: this.testFirstName,
                        last_name: this.testLastName,
                    }
                })
                    .then(() => {
                        toastSuccess('Test email sent.');
                        this.dialog = false;
                    }).finally(() => {
                        this.sending = false;
                    });
            }
        }
    };
</script>

<style scoped>

</style>
