<template>
    <v-row>
        <v-col>
            <editor v-if="isWaitingPageEditor"
                    dynamic-blocks-kind="waitingPage"
            >
                <template v-slot:extra-content="{refresh}">
                    <h3 class="mb-2 font-weight-bold">
                        Waiting page body text
                    </h3>
                    <v-divider class="mb-10" />
                    <rich-text-editor v-model="bodyText"
                                      :disabled="project.isArchived"
                                      class="rich-text-editor"
                                      @input="debounced(refresh)"
                    />
                </template>
            </editor>
        </v-col>
    </v-row>
</template>

<script>
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import {mapActions, mapMutations, mapState} from 'vuex';
    import Editor from '@/components/pages/Project/EventPage/Editor.vue';
    import {debounce} from 'lodash';
    export default {
        name: 'WaitingEventTab',
        components: {Editor, RichTextEditor, },
        data() {
            return {
                tab: 0
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            bodyText: {
                get() {
                    return this.project.splashPageContent;
                },
                async set(value) {
                    this.setProjectSplashPageContent(value);
                    await this.saveProject({
                        id: this.project._id,
                        key: 'splashPageContent',
                        value: value,
                    });
                    this.$emit('refreshPreview');
                },
            },
            isWaitingPageEditor() {
                return this.$route.path.includes('eventpage');
            }
        },
        methods: {
            ...mapActions('projects', ['saveProject']),
            ...mapMutations('projects', ['setProjectSplashPageContent']),
            debounced: debounce(function(callee) {
                callee();
            }, 2000),
        },
    };
</script>

<style scoped>

.rich-text-editor{
    height: 23rem;
}

</style>
