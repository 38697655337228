<template>
    <div class="dynamic-block-editor">
        <layout-selector v-model="layout"
                         :layout-labels="editorLayoutLabels"
        />
        <v-row v-show="layout === 'default'">
            <v-col
                v-show="!loading"
                cols="12"
                lg="3"
                md="3"
                class="sidebar-container"
            >
                <div class="d-flex">
                    <block-creator class="mb-5 flex-grow-1"
                                   :save-url="saveBlocksUrl"
                                   :dynamic-block-kind="dynamicBlocksKind"
                    />
                    <div class="d-flex">
                        <div v-if="canAccessTemplates"
                             class="d-flex"
                        >
                            <load-from-template :index-url="templateIndexUrl"
                                                @input="loadFromTemplate"
                            />
                            <save-as-template :index-url="templateIndexUrl"
                                              @input="onSaveTemplateSelect"
                            />
                        </div>
                        <confirm-modal ref="loadConfirm">
                            <template v-slot:title>
                                Load from template
                            </template>
                            <template v-slot:content>
                                <p class="mt-4">
                                    This will replace the dynamic blocks on the page and is not undoable.
                                </p>
                                <p>Would you like to continue?</p>
                            </template>
                        </confirm-modal>
                    </div>
                </div>
                <v-card class="sidebar">
                    <block-sidebar :key="`dynamic-blocks-sidebar${sidebarKey}`"
                                   :save-url="saveBlocksUrl"
                                   :dynamic-block-kind="dynamicBlocksKind"
                    />
                </v-card>
            </v-col>

            <v-col v-if="loading"
                   cols="12"
                   lg="3"
                   md="3"
                   class="sidebar-container"
            >
                <v-skeleton-loader class="mx-auto"
                                   type="image"
                                   min-height="600"
                />
            </v-col>

            <v-col cols="12"
                   lg="9"
                   md="9"
                   class="preview-container"
            >
                <dynamic-blocks-page-preview
                    :key="`dynamic-block-preview-${previewKey}`"
                    :dynamic-block-kind="dynamicBlockKind"
                />
                <block-overlay v-if="blocksLoadedMessage.initialSizesLoaded"
                               :key="layout"
                               class="block-overlay"
                               :save-url="saveBlocksUrl"
                               @dragend="dragEnd"
                />
            </v-col>
        </v-row>
        <v-row v-if="layout === 'basic' && !loading">
            <v-col v-if="dynamicBlocksKind !== 'registration'"
                   cols="12"
                   md="3"
            >
                <slot name="extra-content"
                      :refresh="refreshPreview"
                />
            </v-col>
            <v-col cols="12"
                   :md="dynamicBlocksKind === 'registration' ? 12: 9"
            >
                <iframe :key="`static-preview-${previewKey}`"
                        :src="editorUrl"
                        :style="defaultPreviewStyle"
                />
            </v-col>
        </v-row>
    </div>
</template>
<script>
    import LayoutSelector from '@/components/pages/Project/EventPage/LayoutSelector';
    import BlockOverlay from '@/components/pages/Project/EventPage/BlockOverlay';
    import BlockSidebar from '@/components/pages/Project/EventPage/BlockSidebar';
    import BlockCreator from '@/components/pages/Project/EventPage/BlockCreator';
    import DynamicBlocksPagePreview from '@/components/pages/Project/EventPage/DynamicBlocksPagePreview';
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import LoadFromTemplate from '@/components/pages/Project/EventPage/Templates/LoadFromTemplate';
    import SaveAsTemplate from '@/components/pages/Project/EventPage/Templates/SaveAsTemplate';
    import { makeUrl } from '@/services/ApiService';
    import { $http } from '@/services/http';
    import { toastError, toastSuccess } from '@/services/responseErrors';
    import ConfirmModal from '@/components/controls/dialogs/ConfirmModal';
    import { getDynamicBlockKind } from '@/components/pages/Project/EventPage/blocks-helper';

    export default {
        name: 'Editor',
        components: {
            ConfirmModal,
            SaveAsTemplate,
            LoadFromTemplate,
            LayoutSelector,
            BlockOverlay,
            BlockSidebar,
            BlockCreator,
            DynamicBlocksPagePreview,
        },
        data() {
            return {
                previewKey: 0,
                sidebarKey: 0,
                loading: true,
            };
        },
        props: {
            dynamicBlocksKind: {
                type: String,
                required: true,
            }
        },
        computed: {
            ...mapState('dynamic-block', ['blocks', 'blocksLoadedMessage']),
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['dynamicBlocksLayout', 'waitingPageDynamicBlocksLayout']),
            ...mapGetters('auth', ['isFreelancerForProject', 'isQuadiaLiveAdmin', 'isProjectEditorForProject', 'isProductionCompanyAdminForProject']),
            canAccessTemplates() {
                return (
                    this.isQuadiaLiveAdmin || this.isProjectEditorForProject(this.project) || this.isProductionCompanyAdminForProject(this.project)
                );
            },
            dynamicBlockKind() {
                return getDynamicBlockKind(this.dynamicBlocksKind);
            },
            templateIndexUrl() {
                return this.dynamicBlockKind.templateIndexUrl;
            },
            templateAddUrl() {
                return this.dynamicBlockKind.templateAddUrl;
            },
            templateUpdateUrl() {
                return this.dynamicBlockKind.templateUpdateUrl;
            },
            layoutKey() {
                return this.dynamicBlockKind.key;
            },
            layoutPresetUrl() {
                return this.dynamicBlockKind.presetUrl;
            },
            loadBlocksUrl() {
                const getter = this.blocksGetter;
                return this.dynamicBlockKind.loadBlocksUrl + this[getter];
            },
            saveBlocksUrl() {
                const getter = this.blocksGetter;
                return this.dynamicBlockKind.saveBlocksUrl + this[getter];
            },
            blocksGetter() {
                return this.dynamicBlockKind.layoutGetter;
            },
            blocksSetter() {
                return this.dynamicBlockKind.layoutSetterKey;
            },
            previewUrl() {
                return this.dynamicBlockKind.previewUrl;
            },
            layoutSetterKey() {
                return this.dynamicBlockKind.setterKey;
            },
            selectedLayoutUrl() {
                return this.dynamicBlockKind.selectedLayoutUrl;
            },
            editorLayoutLabels() {
                return this.dynamicBlockKind.editorLayoutLabels;
            },
            layout: {
                get(){
                    const stateKey = this.layoutKey;
                    return this.project[stateKey]?.layout || 'default';
                },
                async set(value){
                    this.loading = true;
                    await this.saveProject({
                        key: this.layoutKey + '.layout',
                        value: value,
                    });
                    const setter = this.layoutSetterKey;
                    this[setter](value);
                    if (value !== 'basic') {
                        const {data} = await $http.get(makeUrl(this.layoutPresetUrl + value));
                        if (this.dynamicBlocksKind !== 'registration') {
                            for (let x in data) {
                                if (data[x].componentType === 'header-block') {
                                    data[x].componentOptions = {
                                        ...data[x].componentOptions,
                                        content: this.project.splashPageContent
                                    };
                                    break;
                                }
                            }
                        }
                        this.setBlocks(data);
                        await this.saveBlocks(this.saveBlocksUrl);
                    }
                    await this.getSelectedLayout({
                        baseUrl: this.selectedLayoutUrl,
                        setter: this.blocksSetter
                    });
                    await setTimeout(() => {}, 1000);
                    this.loading = false;
                    if (value === 'basic') {
                        this.previewKey++;
                    }
                },
            },
            editorUrl() {
                return this.previewUrl.replace(
                    '{eventID}',
                    this.project?.events[0]?._id,
                ) + 'v=' + this.previewKey * (new Date()).getTime();
            },
            defaultPreviewStyle() {
                return {
                    width: '100%',
                    minHeight: '860px',
                };
            }
        },
        methods: {
            ...mapActions('dynamic-block', ['loadBlocks', 'saveBlocks']),
            ...mapMutations('dynamic-block', ['setBlocks', 'updateBlocksLoadedMessage']),
            ...mapActions('projects', ['getSelectedLayout', 'saveProject']),
            ...mapMutations('projects', ['setDynamicBlocksLayout', 'setWaitingPageDynamicBlocksLayout']),
            reloadPreview() {
                this.previewKey++;
            },
            dragEnd() {
                this.sidebarKey++;
            },
            refreshPreview() {
                this.previewKey++;
            },
            async onSaveTemplateSelect(result) {
                try {
                    if (result.action === 'store') {
                        await $http.post(this.templateAddUrl.replace('{company}', this.project.company._id), {
                            'blocks': this.blocks,
                            'layout': result.selectedTemplate.layout,
                        });

                        toastSuccess(`New template ${result.selectedTemplate.layout} added.`);
                    } else {
                        await $http.put(
                            this.templateUpdateUrl
                                .replace('{company}', this.project.company._id)
                                .replace('{layout}', result.selectedTemplate.layout),
                            {
                                'blocks': this.blocks
                            });
                        toastSuccess(`Template ${result.selectedTemplate.layout} updated.`);
                    }
                } catch (e) {
                    toastError(`An error occurred while saving the template ${result.selectedTemplate.layout}`);
                }
            },
            async loadFromTemplate(selectedTemplate) {
                if (!await this.$refs.loadConfirm.open()) {
                    return;
                }
                this.setBlocks(selectedTemplate.blocks);
                await this.saveBlocks(this.saveBlocksUrl);
                await this.loadBlocks(this.saveBlocksUrl);
            },
            dynamicBlocksLoadedListener (event) {
                if (event.origin !== process.env.VUE_APP_FRONTEND_ORIGIN) {
                    return;
                }
                var message = JSON.parse(event.data);
                if (message.event === 'qlive-app-blocks-loaded') {
                    this.updateBlocksLoadedMessage(message);
                }
            },
        },
        async mounted() {
            window.addEventListener('message', this.dynamicBlocksLoadedListener);
            window.addEventListener('resize', this.reloadPreview);
            await this.loadBlocks(this.loadBlocksUrl);
            this.loading = false;
        },
        beforeDestroy() {
            window.addEventListener('message', this.dynamicBlocksLoadedListener);
            window.removeEventListener('resize', this.reloadPreview);
        },
    };
</script>

<style lang="scss">
.dynamic-block-editor {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  .sidebar-container {
    position: sticky;
    top: 230px;
    max-height: 67vh;
    overflow-y: auto;
  }

  .preview-container {
    position: relative;

    .dynamic-block-iframe {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    .block-overlay {
      position: absolute;
      top: 12px;
      left: 12px;
      right: 12px;
      overflow: visible;
      padding-top: 64px;
      padding-bottom: 80px;
    }
  }
}
</style>