<template>
    <div>
        <v-row>
            <v-col cols="12"
                   md="6"
            >
                <h2 class="font-weight-regular black--text mb-2">
                    Registration page details
                </h2>
                <v-divider class="mb-2" />
                <div>
                    Page header:
                    <div class="black--text my-2">
                        {{ value.registration.title }}
                    </div>
                </div>
                <v-divider class="mb-2" />
                <div>
                    Body text:
                    <div class="black--text my-2"
                         v-html="value.registration.bodyText"
                    />
                </div>
                <v-divider class="mb-2" />
                <div>
                    Terms and conditions:
                    <div class="black--text my-2">
                        {{ value.registration.acceptTocText }}
                    </div>
                </div>
            </v-col>
            <v-col cols="12"
                   md="6"
            >
                <h3 class="font-weight-regular black--text">
                    Fields
                </h3>
                <div
                    v-for="(field, index) in value.registration.fields"
                    :key="index"
                    class="py-2"
                >
                    <v-divider class="mb-2" />
                    <div>
                        Name: <span class="black--text">{{ field.label }}</span>
                    </div>
                    <div>
                        Type: <span class="black--text">{{ field.type }}</span>
                    </div>
                    <div>
                        Required:
                        <span class="black--text">{{
                            field.type ? "Yes" : "No"
                        }}</span>
                    </div>
                    <div v-if="field.options"
                         class="d-flex"
                    >
                        Options:
                        <span class="black--text">
                            <simple-options-display :options="field.options" />
                        </span>
                    </div>
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import SimpleOptionsDisplay from '@/components/controls/SimpleOptionsDisplay';
    export default {
        name: 'RegistrationPageDetails',
        components: { SimpleOptionsDisplay },
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        registration: {
                            title: '',
                            bodyText: '',
                            fields: [],
                            acceptTocText: '',
                        },
                    };
                },
            },
        },
    };
</script>

<style scoped>
</style>