<template>
    <v-card class="px-4 font-weight-medium list-header"
            elevation="0"
    >
        <v-row>
            <v-col v-if="isClientOnly"
                   md="1"
            />
            <v-col md="2"
                   class="pt-4"
                   :class="{'col-md-3': isClientOnly}"
            >
                <ProjectListHeaderItem id="company.name"
                                       name="Company"
                                       :filter-items="companiesColumnFilters"
                                       :part="part"
                />
            </v-col>
            <v-col v-if="!isClientOnly"
                   md="2"
                   class="pt-4"
            >
                <ProjectListHeaderItem
                    id="name"
                    name="Project"
                    :part="part"
                />
            </v-col>
            <v-col md="2"
                   class="pt-4"
                   :class="{'col-md-3': isClientOnly}"
            >
                <ProjectListHeaderItem id="events.name"
                                       name="Event"
                                       :part="part"
                />
            </v-col>
            <v-col md="2"
                   class="pt-4"
                   :class="{'col-md-3': isClientOnly}"
            >
                <ProjectListHeaderItem id="startTime"
                                       name="Start Time"
                                       :part="part"
                />
            </v-col>
            <v-col v-if="!isClientOnly"
                   md="2"
                   class="pt-4"
            >
                <ProjectListHeaderItem
                    id="createdBy.name"
                    name="Created By"
                    :filter-items="createdByColumnFilters"
                    :part="part"
                />
            </v-col>
            <v-col md="1"
                   class="pt-4 pl-1"
            >
                <div class="text-right"
                     style="white-space: nowrap;"
                >
                    Registrations
                </div>
            </v-col>
            <v-col md="1"
                   class="pt-4"
            >
                <div class="text-right"
                     style="white-space: nowrap;"
                >
                    Actions
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import {$http} from '@/services/http';
    import {COMPANIES_FOR_FILTERING_URL, PROJECT_EVENT_LIST_CREATED_BY_URL} from '@/services/ApiService';
    import {mapGetters, mapMutations} from 'vuex';
    import ProjectListHeaderItem from './ProjectListHeaderItem';

    export default {
        name: 'ProjectListHeader',
        components: {ProjectListHeaderItem},
        props: {
            part: {
                type: String,
                default: () => 'upcoming',
            }
        },
        data: () => ({
            createdByColumnFilters: [],
            companiesColumnFilters: [],
        }),
        computed: {
            ...mapGetters('projectLists', ['listingHeader']),
            ...mapGetters('auth', ['isClientOnly']),
            createdBySubSearch: function () {
                return this.listingHeader(this.part).filtering.subFilters['createdBy.name'];
            },
            companiesSubSearch: function () {
                return this.listingHeader(this.part).filtering.subFilters['company.name'];
            }
        },
        watch: {
            createdBySubSearch: function (value) {
                this.getCreatedByFilterItems(value ? value : '');
            },
            companiesSubSearch: function (value) {
                this.getCompaniesFilterItems(value ? value : '');
            },
        },
        methods: {
            ...mapMutations('projectLists', ['setFiltersLoading']),
            getCreatedByFilterItems(search) {
                this.setFiltersLoading({
                    listing: this.part,
                    value: true
                });
                $http.get(PROJECT_EVENT_LIST_CREATED_BY_URL, {
                    params: {
                        'search': encodeURIComponent(search),
                        'part': this.part,
                    }
                }).then((data) => {
                    this.createdByColumnFilters = data.data;
                }).finally(() => this.setFiltersLoading({
                    listing: this.part,
                    value: false
                }));
            },
            getCompaniesFilterItems(search) {
                this.setFiltersLoading({
                    listing: this.part,
                    value: true
                });
                $http.get(COMPANIES_FOR_FILTERING_URL, {
                    params: {
                        'search': encodeURIComponent(search)
                    }
                }).then((data) => {
                    this.companiesColumnFilters = data.data;
                }).finally(() => this.setFiltersLoading({
                    listing: this.part,
                    value: false
                }));
            }
        },
        mounted() {
            this.getCreatedByFilterItems('');
            this.getCompaniesFilterItems('');
        }
    };
</script>

<style scoped>

</style>
