<template>
    <div class="pt-2">
        <div v-if="!loading && canDownload"
             class="d-flex justify-end"
        >
            <open-attendance-export v-if="!loading" />
            <insights-statistics-export v-if="!loading"
                                        :selected-event="selectedEvent"
            />
        </div>
        <div v-if="!loading"
             class="insigths-row"
             :style="cssVars"
        >
            <v-card v-if="showRegistrationPageVisits"
                    class="insights-card"
                    elevation="3"
            >
                <v-card-title class="font-weight-regular text-center d-flex justify-center card-title">
                    Registration page visits
                </v-card-title>
                <v-divider />
                <v-card-text class="d-flex align-center justify-center">
                    <h1 class="font-weight-medium text-center statistics-large-text">
                        {{ showRegistrationPageViews ? totalVisitsRegistrationPage : "N/A" }}
                    </h1>
                </v-card-text>
            </v-card>
            <v-card v-if="isRegistrationAllowed"
                    class="insights-card"
                    elevation="3"
            >
                <v-card-title class="font-weight-regular text-center d-flex justify-center card-title">
                    Total registrations
                </v-card-title>
                <v-divider />
                <v-card-text class="d-flex justify-center flex-column">
                    <h1 class="font-weight-medium text-center"
                        :class="{'mb-4':showRegistrationPageViews}"
                    >
                        {{ totalRegistrations }}
                    </h1>
                    <div v-if="showRegistrationPageViews"
                         class="d-flex justify-center"
                         :class="{'mb-4': isHybridProject}"
                    >
                        <v-avatar size="100"
                                  class="elevation-5"
                        >
                            <v-progress-circular 
                                :value="registrationsPerVisits"
                                size="100"
                                width="20"
                                color="primary"
                            >
                                <h2 class="font-weight-regular">
                                    {{ registrationsPerVisits }}%
                                </h2>
                            </v-progress-circular>
                        </v-avatar>
                    </div>
                </v-card-text>
                <v-divider v-if="isHybridProject" />
                <v-card-actions v-if="isHybridProject"
                                class="hybrid-stats"
                >
                    <div class="d-flex flex-column align-center justify-center">
                        <span>Online</span>
                        <span>{{ totalOnlineRegistrations }}</span>
                    </div>
                    <v-divider vertical />
                    <div class="d-flex flex-column align-center justify-center">
                        <span>On-site</span>
                        <span>{{ totalOnSiteRegistrations }}</span>
                    </div>
                </v-card-actions>
            </v-card>
            <v-card class="insights-card"
                    elevation="3"
            >
                <v-card-title class="font-weight-regular text-center d-flex justify-center card-title">
                    {{ isRegistrationAllowed ? 'Unique viewers' : 'Unique sessions' }}
                </v-card-title>
                <v-divider />
                <v-card-text class="d-flex justify-center flex-column">
                    <h1 class="font-weight-medium text-center"
                        :class="{'mb-4': isRegistrationAllowed, 'statistics-large-text': !isRegistrationAllowed}"
                    >
                        {{ isRegistrationAllowed ? uniqueViewers : uniqueSessions }}
                    </h1>
                    <div v-if="isRegistrationAllowed"
                         class="d-flex justify-center"
                         :class="{'mb-4': isHybridProject}"
                    >
                        <v-avatar size="100"
                                  class="elevation-5"
                        >
                            <v-progress-circular
                                :value="uniqueViewersPercentage"
                                size="100"
                                width="20"
                                color="primary"
                            >
                                <h2 class="font-weight-regular">
                                    {{ uniqueViewersPercentage }}%
                                </h2>
                            </v-progress-circular>
                        </v-avatar>
                    </div>
                </v-card-text>
                <v-divider v-if="isHybridProject" />
                <v-card-actions v-if="isHybridProject"
                                class="hybrid-stats"
                >
                    <div class="d-flex flex-column align-center justify-center">
                        <span>Online</span>
                        <span>{{ isRegistrationAllowed ? onlineViewers : onlineSessions }}</span>
                    </div>
                    <v-divider vertical />
                    <div class="d-flex flex-column align-center justify-center">
                        <span>On-site</span>
                        <span>{{ isRegistrationAllowed ? onSiteViewers : onSiteSessions }}</span>
                    </div>
                </v-card-actions>
            </v-card>
            <v-card v-if="isRegistrationAllowed"
                    class="insights-card"
                    elevation="3"
            >
                <v-card-title class="font-weight-regular text-center d-flex justify-center card-title">
                    No shows
                </v-card-title>
                <v-divider />
                <v-card-text class="d-flex justify-center flex-column">
                    <h1 class="font-weight-medium text-center"
                        :class="{'mb-4': isRegistrationAllowed, 'statistics-large-text': !isRegistrationAllowed}"
                    >
                        {{ noShows }}
                    </h1>
                    <div v-if="isRegistrationAllowed"
                         class="d-flex justify-center"
                         :class="{'mb-4': isHybridProject}"
                    >
                        <v-avatar size="100"
                                  class="elevation-5"
                        >
                            <v-progress-circular
                                :value="noShowsPercentage"
                                size="100"
                                width="20"
                                color="primary"
                            >
                                <h2 class="font-weight-regular">
                                    {{ noShowsPercentage }}%
                                </h2>
                            </v-progress-circular>
                        </v-avatar>
                    </div>
                </v-card-text>
                <v-divider v-if="isHybridProject" />
                <v-card-actions v-if="isHybridProject"
                                class="hybrid-stats"
                >
                    <div class="d-flex flex-column align-center justify-center">
                        <span>Online</span>
                        <span>{{ onlineNoShows }}</span>
                    </div>
                    <v-divider vertical />
                    <div class="d-flex flex-column align-center justify-center">
                        <span>On-site</span>
                        <span>{{ onSiteNoShows }}</span>
                    </div>
                </v-card-actions>
            </v-card>
            <v-card v-if="showEvaluations"
                    style="cursor: pointer"
                    class="insights-card"
                    elevation="3"
                    @click="toEvaluations"
            >
                <v-card-title class="font-weight-regular text-center d-flex justify-center card-title">
                    Evaluation forms
                </v-card-title>
                <v-divider />
                <v-card-text 
                    class="d-flex justify-center flex-column"
                >
                    <h1 class="font-weight-medium  text-center"
                        :class="{'mb-4': isRegistrationAllowed, 'statistics-large-text': !isRegistrationAllowed}"
                    >
                        {{ evaluationsCount }}
                    </h1>
                    <div v-if="isRegistrationAllowed"
                         class="d-flex justify-center"
                    >
                        <v-avatar size="100"
                                  class="elevation-5"
                        >
                            <v-progress-circular 
                                :value="averageEvaluationsFilled"
                                size="100"
                                width="20"
                                color="primary"
                            >
                                <h2 class="font-weight-regular">
                                    {{ averageEvaluationsFilled }}%
                                </h2>
                            </v-progress-circular>
                        </v-avatar>
                    </div>
                </v-card-text>
                <v-divider v-if="isHybridProject" />
                <v-card-actions v-if="isHybridProject"
                                class="hybrid-stats"
                >
                    <div class="d-flex flex-column align-center justify-center">
                        <span>Online</span>
                        <span>{{ filledInEvaluations }}</span>
                    </div>
                    <v-divider vertical />
                    <div class="d-flex flex-column align-center justify-center">
                        <span>On-site</span>
                        <span>{{ filledInOnSiteEvaluations }}</span>
                    </div>
                </v-card-actions>
            </v-card>
            <v-card v-if="showPolls"
                    style="cursor: pointer"
                    class="insights-card"
                    @click="toPolls()"
            >
                <v-card-title class="font-weight-regular text-center d-flex justify-center card-title">
                    Polls
                </v-card-title>
                <v-divider />
                <v-card-text class="d-flex justify-center flex-column">
                    <h1 class="font-weight-medium text-center statistics-large-text">
                        {{ pollsCount }}
                    </h1>
                </v-card-text>
            </v-card>
            <v-card v-if="showQuestions"
                    style="cursor: pointer"
                    class="insights-card"
                    @click="toQuestions()"
            >
                <v-card-title class="font-weight-regular text-center d-flex justify-center card-title">
                    Questions
                </v-card-title>
                <v-divider />
                <v-card-text class="d-flex justify-center flex-column">
                    <h1 class="font-weight-medium text-center statistics-large-text">
                        {{ contentRelatedQuestions }}
                    </h1>
                </v-card-text>
            </v-card>
            <v-card v-if="selectedEvent.onDemandActive"
                    class="insights-card"
            >
                <v-card-title class="font-weight-regular text-center d-flex justify-center card-title">
                    On demand views
                </v-card-title>
                <v-divider />
                <v-card-text class="d-flex justify-center flex-column">
                    <h1 class="font-weight-medium text-center statistics-large-text">
                        {{ onDemandViews }}
                    </h1>
                </v-card-text>
            </v-card>
            <v-card class="insights-card">
                <v-card-title class="font-weight-regular text-center d-flex justify-center card-title">
                    Average viewing time
                </v-card-title>
                <v-divider />
                <v-card-text class="d-flex justify-center flex-column">
                    <h1 class="font-weight-medium text-center statistics-average-time">
                        {{ averageViewingTime }}
                    </h1>
                </v-card-text>
            </v-card>
        </div>
        <v-row 
            v-if="!loading"
            class="content-center mt-10"
            justify="center"
        >
            <v-col cols="12"
                   lg="9"
                   md="9"
            >
                <h3 class="font-weight-mefium text-center">
                    Viewing chart
                </h3>
                <v-divider />
                <custom-chart
                    :width="1000"
                    :height="300"
                    :data="viewingChartData"
                    :options="options"
                />

                <h1 class="caption text-center">
                    Viewing time
                </h1>
            </v-col>
        </v-row>
        <v-progress-linear v-if="loading"
                           indeterminate
        />
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import CustomChart from '../Elements/CustomChart.vue';
    import ProjectStatisticsService from '@/services/ProjectStatisticsService';
    import TimeService from '@/services/TimeService';
    import {$http} from '@/services/http';
    import {REGISTRATION_TYPE_REGISTRATION, REGISTRATION_TYPE_SPRINGER} from '@/services/RegistrationService';
    import InsightsStatisticsExport
        from '@/components/pages/Project/StatisticsTabs/InsightStatistics/InsightsStatisticsExport.vue';
    import OpenAttendanceExport
        from '@/components/pages/Project/StatisticsTabs/InsightStatistics/OpenAttendanceExport.vue';

    export default {
        name: 'InsightStatistics',
        components: {InsightsStatisticsExport, OpenAttendanceExport, CustomChart },
        props:{
            selectedEvent: {
                type: Object,
                required: true
            },
        },
        data() {
            return {
                loading: true,
                currentEventId: '',
                fill: true,
                totalRegistrations: 0,
                uniqueViewers: 0,
                onSiteViewers: 0,
                onlineViewers: 0,
                onSiteSessions: 0,
                onlineSessions: 0,
                totalOnSiteRegistrations: 0,
                totalOnlineRegistrations:0,
                totalVisitsRegistrationPage: 0,
                averageViewingTime: '00:00:00',                
                viewingChartData: null,
                uniqueSessions: 0,
                onDemandViews: 0,
                options:{
                    scales: {
                        yAxes: [{
                            ticks: {
                                beginAtZero: false,
                                callback: function(val) {
                                    return Number.isInteger(val) ? val : '';
                                },
                            },
                        }]
                    }
                },
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['currentProjectId', 'registrationType', 'isExternalEmbed', 'isHybridProject']),
            ...mapState('statistics', ['filledInEvaluations', 'filledInOnSiteEvaluations', 'contentRelatedQuestions']),
            ...mapGetters('statistics', ['pollsCount', 'hasPolls', 'hasEvaluations', 'hasQuestions', 'hasOnSiteEvaluations']),
            ...mapGetters('auth', ['canDownloadStats']),
            canDownload() {
                return this.canDownloadStats(this.project);
            },
            evaluationsCount(){
                if(this.isHybridProject){
                    return this.filledInEvaluations + this.filledInOnSiteEvaluations;
                }
                return this.filledInEvaluations;
            },
            averageEvaluationsFilled() {
                let average = Math.round(this.evaluationsCount * 100 / this.uniqueViewers);
                return !isNaN(average) && isFinite(average) ? average : 0;
            },
            registrationsPerVisits(){
                const average = Math.round((this.totalRegistrations*100)/ this.totalVisitsRegistrationPage);
                return !isNaN(average) && isFinite(average) ? average : 0;
            },
            showRegistrationPageViews(){
                return this.project._id > '62626e900000000000000000';
            },
            showRegistrationPageVisits(){
                if (this.isExternalEmbed || this.registrationType === REGISTRATION_TYPE_SPRINGER ) {
                    return false;
                }
                return this.isRegistrationAllowed;
            },
            uniqueViewersPercentage(){
                const average = Math.round((this.uniqueViewers*100)/ this.totalRegistrations);
                return isNaN(average) ? 0 : average;
            },
            noShows() {
                return Math.max(0, this.totalRegistrations - this.uniqueViewers);
            },
            noShowsPercentage(){
                const average = Math.round((this.noShows*100)/ this.totalRegistrations);
                return isNaN(average) ? 0 : average;
            },
            onSiteNoShows(){
                return Math.max(0, this.totalOnSiteRegistrations - this.onSiteViewers);
            },
            onlineNoShows(){
                return Math.max(0, this.noShows - this.onSiteNoShows);
            },
            videoID() {
                return this.selectedEvent.onDemandID;
            },
            isRegistrationAllowed(){
                return [REGISTRATION_TYPE_REGISTRATION, REGISTRATION_TYPE_SPRINGER].indexOf(this.registrationType) !== -1;
            },
            showPolls(){
                return !this.isExternalEmbed && this.hasPolls;
            },
            showEvaluations(){
                return !this.isExternalEmbed && (this.hasEvaluations || this.hasOnSiteEvaluations);
            },
            showQuestions(){
                return !this.isExternalEmbed && this.hasQuestions;
            },
            cssVars(){
                let numberOfCards = this.isRegistrationAllowed ? 8 : 5;
                if(!this.showPolls){
                    numberOfCards--;
                }
                if(!this.showEvaluations){
                    numberOfCards--;
                }
                if(!this.showQuestions){
                    numberOfCards--;
                }
                if(this.selectedEvent.onDemandActive){
                    numberOfCards++;
                }
                if(!this.showRegistrationPageVisits){
                    numberOfCards--;
                }
                return {
                    '--card-flex-percentage': `${100/numberOfCards}%`,
                    '--card-text-size': numberOfCards > 6 ? '30px' : '40px',
                    '--card-text-average-time-size':  numberOfCards >= 5 ? '25px' : '32px',
                    '--card-title-size-lg' : numberOfCards > 6 ? '14px' : '18px',
                    '--card-title-size-md' : numberOfCards > 6 ? '12px' : '16px'

                };
            }
        },
        methods: {
            getTotalRegistrations(){
                return ProjectStatisticsService.getTotalRegistrationsCount(this.currentProjectId).then((count) => {
                    this.totalRegistrations = count;
                });
            },
            getRegistrationPageViews(){
                return  ProjectStatisticsService.getRegistrationPageViews(this.currentProjectId).then((count) => {
                    this.totalVisitsRegistrationPage = count;
                });
            },
            getUniqueViewersCount(){
                return ProjectStatisticsService.getUniqueViewersCount(this.currentEventId).then((count) => {
                    this.uniqueViewers = count;
                });
            },
            getUniqueSessionsCount(){
                return  ProjectStatisticsService.getUniqueSessionsCount(this.currentEventId).then((count) => {
                    this.uniqueSessions = count;
                });
            },
            getAverageViewingTime(){
                return ProjectStatisticsService.getAverageViewingTime(this.currentEventId).then((count) => {
                    this.averageViewingTime = TimeService.secondsToHHMMSS(count);
                });
            },
            getAverageViewingTimeByUID(){
                return ProjectStatisticsService.getAverageViewingTimeByUID(this.currentEventId).then((count) => {
                    this.averageViewingTime = TimeService.secondsToHHMMSS(count);
                });
            },
            getViewingChartData(){
                return ProjectStatisticsService.getViewingChartData(this.currentEventId).then((data) => {
                    if (data) {
                        const viewingChartData = {
                            labels: [],
                            datasets: [
                                {
                                    label: 'chart',
                                    pointStyle: 'circle',
                                    pointBackgroundColor: '#2977BE',
                                    pointRadius: 3,
                                    data: [],
                                },
                            ],
                        };
                        let viewersData = data['viewersChart'];
                        let maxValue = 0;
                        for (let key in viewersData) {
                            let dateTime = TimeService.toCETTimeString24(TimeService.dateFromUnixTimestamp(key));
                            viewingChartData.labels.push(dateTime);
                            viewingChartData.datasets[0].data.push(viewersData[key]);
                            if(viewersData[key] > maxValue){
                                maxValue = viewersData[key];
                            }
                        }
                        if(maxValue < 100) {
                            this.options.scales.yAxes[0].ticks.max = 100;
                            this.options.scales.yAxes[0].ticks.beginAtZero = true;
                        }
                        this.viewingChartData = viewingChartData;
                    }
                });
            },
            getOndemanViews(){
                return $http.get(process.env.VUE_APP_STATS_API_HOST + '/api/videos/views?videos[]=' +  this.videoID).then(response => {
                    const buckets = response.data.filter(item => item.key === this.videoID);
                    this.onDemandViews = 0;
                    if (buckets.length > 0) {
                        this.onDemandViews = buckets[0].doc_count;
                    }
                });
            },
            getHybridRegistrations(){
                return ProjectStatisticsService.getHybridRegistrations(this.currentProjectId).then((data) => {
                    this.totalOnSiteRegistrations = data.onSiteViewers??0;
                    this.totalOnlineRegistrations = data.onlineViewers??0;
                    this.totalRegistrations = this.totalOnSiteRegistrations + this.totalOnlineRegistrations;
                });            
            },
            getHybridUniqueViewers(){
                return ProjectStatisticsService.getHybridUniqueViewers(this.currentEventId).then((data) => {
                    this.onSiteViewers = data.onSiteViewers;
                    this.onlineViewers = data.onlineViewers;
                    this.uniqueViewers = this.onSiteViewers + this.onlineViewers;
                });
            },
            getHybridUniqueSessions(){
                return ProjectStatisticsService.getHybridUniqueSessions(this.currentEventId).then((data) => {
                    this.onSiteSessions = data.onSiteSessions;
                    this.onlineSessions = data.onlineSessions;
                    this.uniqueSessions = this.onSiteSessions + this.onlineSessions;
                });
            },
            async loadData() {
                if (!this.currentProjectId) {
                    return;
                }
                let promises = [];


                if(!this.isHybridProject){
                    if(this.isRegistrationAllowed){
                        promises.push(this.getTotalRegistrations());
                        promises.push(this.getUniqueViewersCount());
                    } else {
                        promises.push(this.getUniqueSessionsCount());
                    }
                }

                if(this.isRegistrationAllowed){
                    promises.push(this.getAverageViewingTime());
                    if(this.showRegistrationPageViews){
                        promises.push(this.getRegistrationPageViews());
                    }
                } else {
                    promises.push(this.getAverageViewingTimeByUID());
                }

                if(this.isHybridProject){
                    if(this.isRegistrationAllowed){
                        promises.push(this.getHybridRegistrations());
                        promises.push(this.getHybridUniqueViewers());

                    } else {
                        promises.push(this.getHybridUniqueSessions());
                    }
                }

                promises.push(this.getViewingChartData());
                promises.push(this.getOndemanViews());
                await Promise.all(promises);
                this.loading = false;
            },
            toPolls(){
                this.$router.replace({name: 'PollsStatistics'});
            },
            toEvaluations() {
                this.$router.replace({name: 'EvaluationStatistics'});
            },
            toQuestions(){
                this.$router.replace({name: 'QuestionsStatistics'});
            },
        },
        mounted() {
            this.currentEventId = this.selectedEvent._id;
            this.loadData();
        }
    };
</script>

<style lang="scss">
.insigths-row{
    display: flex;
    justify-content: center;
    .insights-card{
        min-width: 120px;
        margin: 10px 10px;
        flex: 0 1 var(--card-flex-percentage);

        
        .card-title{
            font-size: var(--card-title-size-lg);
            padding: 10px 5px;
            @media (max-width: 1700px) {
                font-size: var(--card-title-size-md);
            }
        }
       
       .v-card__text{
            min-height: 220px !important;
            max-height: 220px !important;
        }

        .hybrid-stats{
            display: grid;
            grid-template-columns: 48% 4% 48%;
            padding-top: 8px;
        }
    }
}

.statistics-large-text{
    font-size: var(--card-text-size);
}

.statistics-average-time{
    font-size: var(--card-text-average-time-size);
}

.font-16 {
    font-size: 16px !important;
}
</style>
