<template>
    <v-row>
        <v-col>
            <v-row>
                <v-col>
                    <v-text-field
                        v-model="extraMailSubject"
                        filled
                        class="text-input todo-tbd"
                        label="Mail subject"
                        :disabled="!edit"
                    />
                </v-col>
                <v-col>
                    <v-select
                        v-model="selectedEmailGroup"
                        :items="availableEmailGroups"
                        filled
                        label="Email group"
                        item-text="label"
                        item-value="value"
                        :disabled="project.isArchived"
                    />
                </v-col>
            </v-row>
            <rich-text-editor v-model="extraMailBody"
                              :disabled="!edit"
                              :with-agenda="true"
                              :with-event-links="false"
                              :with-on-site-start-time="isHybridProject"
                              :with-location-address="isHybridProject"
                              :with-font-selector="false"
            />
            <v-row class="mt-5">
                <v-col v-if="!project.isArchived"
                       cols="12"
                       lg="4"
                       xl="3"
                >
                    <v-btn
                        v-if="!edit"
                        class="mr-4 pa-2"
                        color="primary"
                        outlined
                        @click="editEmail"
                    >
                        <v-icon class="mr-1">
                            mdi-pencil
                        </v-icon>
                        EDIT CONTENT
                    </v-btn>
                    <v-btn
                        v-else
                        class="mr-4 pa-2"
                        color="primary"
                        @click="saveAndRefresh"
                    >
                        <v-icon class="mr-1">
                            mdi-content-save
                        </v-icon>
                        SAVE CONTENT
                    </v-btn>
                </v-col>
                <v-col cols="12"
                       lg="8"
                       xl="9"
                       class="d-flex"
                >
                    <v-spacer v-if="$vuetify.breakpoint.lgAndUp " />
                    <send-extra-email 
                        :selected-email-group="selectedEmailGroup"
                    />
                </v-col>
            </v-row>
        </v-col>
        <v-divider
            vertical
        />
    </v-row>
</template>

<script>
    import RichTextEditor from '@/components/controls/RichTextEditor';
    import SendExtraEmail from '@/components/controls/SendExtraEmail';
    import { mapGetters, mapMutations, mapState } from 'vuex';
    import { $http } from '@/services/http';
    import {
        AUTOSAVE_PROJECT_URL,
        populateUrl,
    } from '@/services/ApiService';

    export default {
        name: 'ExtraMailing',
        components: { RichTextEditor, SendExtraEmail },
        data(){
            return{
                edit: false,
                extraMailSubject:'',
                extraMailBody: '',
                selectedEmailGroup: 'all',
                availableEmailGroups: [
                    {'label': 'All registrants', 'value': 'all'},
                    {'label': 'All viewers', 'value': 'viewers'},
                    {'label': 'No shows', 'value': 'no-shows'},
                ],
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['currentProjectId', 'isHybridProject']),
        },
        watch:{
            'project.emailSettings.extraMail.subject': {
                immediate:true,
                handler(value){
                    this.extraMailSubject = value;
                }
            },
            'project.emailSettings.extraMail.bodyText': {
                immediate:true,
                handler(value){
                    this.extraMailBody = value;
                }
            }
        },
        methods: {
            ...mapMutations('projects', ['setExtraBody', 'setExtraSubject']),
            editEmail(){
                this.edit = true;
            },
            async saveAndRefresh(){
                this.edit = false;
                this.setExtraSubject(this.extraMailSubject);
                this.setExtraBody(this.extraMailBody);
                var url = populateUrl(AUTOSAVE_PROJECT_URL, this.currentProjectId);
                await $http.put(url, {
                    'emailSettings.extraMail.subject' : this.extraMailSubject,
                    'emailSettings.extraMail.bodyText': this.extraMailBody,
                });
                this.$emit('refreshPreview');
            }
        },
    };
</script>
<style scoped>
.text-input {
  width: 22rem;
}
</style>
