<template>
    <div>
        <h3 class="mb-2 font-weight-bold">
            Chat type
        </h3>
        <v-divider class="mb-1" />
        <v-radio-group
            v-model="chatType"
            class="chat-type"
            :disabled="project.isArchived"
        >
            <v-radio
                label="No chat"
                value="noChat"
            />
            <v-radio
                label="Slido event"
                value="slido"
            />
            <v-radio
                label="Crisp.chat event"
                value="crisp"
            />
        </v-radio-group>
        <v-text-field
            v-if="chatType === 'slido'"
            v-model="slidoEvent"
            filled
            class="mt-7"
            label="Slido event-code"
            :disabled="project.isArchived"
        />
        <v-text-field
            v-if="chatType === 'crisp'"
            v-model="crispChatEvent"
            filled
            class="mt-7"
            label="Crisp.chat site Id"
            :disabled="project.isArchived"
        />
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';
    export default {
        name:'ChatTabSettings',
        props: {
            event: {
                type: Object,
                required: true
            },
        },
        computed:{
            ...mapState('projects', ['project']),
            slidoEvent: {
                get() {
                    return this.event.slidoEvent;
                },
                set(value) {
                    this.setEventSlidoEvent({eventID: this.event._id, value});
                    this.saveEvent({
                        id: this.event._id,
                        key: 'slidoEvent',
                        value: value,
                    });
                },
            },
            crispChatEvent: {
                get() {
                    return this.event.crispChatEvent;
                },
                set(value) {
                    this.setEventCrispChatEvent({eventID: this.event._id, value});
                    this.saveEvent({
                        id: this.event._id,
                        key: 'crispChatEvent',
                        value: value,
                    });
                },
            },
            chatType:{
                get() {
                    return this.event.chatType ? this.event.chatType : 'slido';
                },
                async set(value) {
                    await this.setEventChatType({eventID: this.event._id, value});
                    this.saveEvent({
                        id: this.event._id,
                        key: 'chatType',
                        value: value,
                    });
                },
            },
        },
        methods: {
            ...mapActions('projects', ['saveEvent']),
            ...mapMutations('projects',
                            ['setEventSlidoEvent',
                             'setEventCrispChatEvent',
                             'setEventChatType',
                            ]),
        },
    };
</script>