<template>
    <v-row>
        <v-col class="d-flex">
            <div class="d-inline-block gradient-toggle-label mr-4 mt-1">
                Enforce color
            </div>
            <v-switch
                v-model="gradient"
                hide-details
                class="mt-0"
                inset
                :disabled="project.isArchived"
            />
            <v-tooltip top>
                <template v-slot:activator="{ on }">
                    <v-icon v-on="on">
                        info
                    </v-icon>
                </template>
                <span>This will enforce the primary color on the background <br>
                    and disable the gradient.</span>
            </v-tooltip>
        </v-col>
    </v-row>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'EnforceColor',
        computed: {
            ...mapState('projects', ['project']),
            gradient: {
                get() {
                    return !this.project.style.backgroundGradient;
                },
                set(value) {
                    this.setBackgroundGradient(!value);
                    this.saveProject({
                        key: 'style.backgroundGradient',
                        value: !value,
                    });
                },
            },
        },
        methods: {
            ...mapMutations('projects', [
                'setBackgroundGradient',
            ]),
            ...mapActions('projects', ['saveProject']),
        },
    };
</script>


<style lang="scss">
.gradient-toggle-label {
  width: 138px;
}
</style>