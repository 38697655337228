<template>
    <v-row v-if="showArchived || showPseudonymized"
           justify="space-around"
    >
        <v-col cols="12"
               md="12"
               sm="12"
               lg="11"
        >
            <v-card elevation="0"
                    class="mx-4 content-card pt-0"
            >
                <v-alert v-if="showArchived"
                         dense
                         border="left"
                         type="warning"
                >
                    This project is currently archived and it will be pseudonymised <b>{{ pseudonymizeAt }}</b> as a GDPR measure. All personal information from registrants/viewers will be irreversibly deleted.
                </v-alert>
                <v-alert v-if="showPseudonymized"
                         dense
                         border="left"
                         type="info"
                >
                    All personal information from registrants/viewers have been deleted as a GDPR measure.
                </v-alert>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    import moment from 'moment-timezone';
    import {mapState} from 'vuex';

    export default {
        name: 'ArchiveBanner',
        computed: {
            ...mapState('projects', ['project']),
            showPseudonymized() {
                return this.project.isPseudonymized;
            },
            showArchived() {
                return this.project.isArchived && !this.project.isPseudonymized;
            },
            pseudonymizeAt() {
                var fiveDaysFromArchived = moment(this.project.archivedAt).add(5, 'days');                
                return fiveDaysFromArchived.format('dddd Do MMM');             
            }
        },
    };
</script>