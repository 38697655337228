<template>
    <v-container fluid>
        <v-row class="page-header-container grey lighten-4">
            <v-col cols="12"
                   md="4"
                   lg="2"
                   xl="1"
            >
                <h1 class="px-8 mt-1 font-weight-regular page-header">
                    Intake
                </h1>
            </v-col>
            <v-col cols="8"
                   md="4"
                   lg="2"
                   xl="2"
            >
                <v-text-field
                    v-model="searchText"
                    solo
                    flat
                    hide-details
                    dense
                    background-color="rgba(0,0,0,0.1)"
                    placeholder="Search"
                    clearable
                />
            </v-col>
            <v-col cols="4"
                   md="4"
                   lg="2"
                   xl="9"
                   class="d-flex"
            >
                <v-spacer />
                <create-intake @created="intakeCreated" />
            </v-col>
        </v-row>
        <div v-if="!loading">
            <v-pagination
                v-if="pagination.total > 1"
                v-model="currentPage"
                :length="pagination.total"
                circle
                total-visible="6"
                class="mb-4"
                color="secondary"
            />
            <intake-list :items="items" />
            <v-pagination
                v-if="pagination.total > 1"
                v-model="currentPage"
                :length="pagination.total"
                circle
                total-visible="6"
                class="mt-4"
                color="secondary"
            />
        </div>
        <v-progress-linear v-else
                           indeterminate
        />
    </v-container>
</template>

<script>
    import { debounce } from 'lodash';
    import { mapActions, mapMutations, mapState } from 'vuex';
    import IntakeList from '@/components/pages/Intake/IntakeList';
    import CreateIntake from '@/components/pages/Intake/CreateIntake';

    export default {
        name: 'Intakes',
        data() {
            return {
                loading: true,
                searchText: '',
            };
        },
        watch: {
            searchText: debounce(function () {
                // prevent reloading items because we're going to reload them in the next step anyway
                this.$options.skipReload = true;
                this.setCurrentPage(1);
                this.$options.skipReload = false;
                this.loadItems(this.getParams());
            }, 400),
            currentPage: debounce(function () {
                this.getItems(this.getParams());
            }, 400),
            headerFilters: debounce(function () {
                this.$options.skipReload = true;
                this.setCurrentPage(1);
                this.$options.skipReload = false;
                this.loadItems(this.getParams());
            }, 400),
        },
        components: {CreateIntake, IntakeList },
        computed: {
            ...mapState('intake', ['pagination', 'items', 'listingHeader']),
            currentPage: {
                get() {
                    return this.pagination.current;
                },
                set(value) {
                    this.setCurrentPage(value);
                },
            },
            headerFilters(){
                return this.listingHeader.filtering.filters;
            },
        },
        methods: {
            ...mapActions('intake', ['getItems']),
            ...mapMutations('intake', ['setCurrentPage', 'setPageTotal']),
            loadItems(params) {
                // we can prevent a reload if we want to chain some state changes
                if (this.$options.skipReload) {
                    this.$options.skipReload = false;
                    return;
                }

                this.loading = true;
                this.getItems(params).then(() => {
                    this.loading = false;
                });
            },
            getParams() {
                const params = {};
                if (this.searchText) {
                    params['q'] = encodeURIComponent(this.searchText);
                }

                if (this.currentPage && this.currentPage > 1) {
                    params['p'] = this.currentPage;
                }

                if (this.pageSize) {
                    params['pagesize'] = this.pageSize;
                }

                let filters = {...this.headerFilters};
                if(filters){
                    for(let k in filters){
                        params['filter['+k+']'] = filters[k].map(x => x.id);
                    }
                }

                return params;
            },
            intakeCreated() {
                this.loadItems(this.getParams());
            }
        },
        mounted() {
            this.loadItems(this.getParams());
        },
    };
</script>

<style scoped>
</style>