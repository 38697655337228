<template>
    <div>
        <draggable
            v-model="fields"
            class="field-dragger"
            ghost-class="ghost"
            handle=".drag-handle"
            :options="{disabled : disabled}"
        >
            <v-card
                v-for="field in fields"
                :key="`field-${field.id}`"
                class="item-row pt-md-2 mt-md-2 mb-2"
                elevation="1"
                :class="{
                    'field-error':
                        fieldValidationResult.field &&
                        fieldValidationResult.field === field,
                    'field-active': isActiveField(field),
                }"
                @click="activateField(field)"
            >
                <v-card-text class="py-0">
                    <v-row>
                        <v-col cols="12"
                               lg="5"
                               sm="6"
                               class="py-0"
                        >
                            <div class="d-flex">
                                <div class="flex-grow-0 drag-handle">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <v-icon
                                                color="grey"
                                                class="mr-4 mt-2"
                                                v-on="on"
                                            >
                                                drag_handle
                                            </v-icon>
                                        </template>
                                        <span>Drag to reorder</span>
                                    </v-tooltip>
                                </div>
                                <div class="flex-grow-1">
                                    <v-text-field
                                        v-model="field.label"
                                        class="mt-0"
                                        hide-details
                                        :disabled="disabled"
                                        @input="validateFieldsDebounced"
                                    />
                                </div>
                            </div>
                        </v-col>
                        <v-col cols="12"
                               lg="4"
                               sm="6"
                               class="py-0"
                        >
                            <v-select
                                v-model="field.type"
                                :items="fieldTypeOptions(field.name)"
                                item-text="label"
                                item-value="value"
                                class="mt-0"
                                hide-details
                                :disabled="disabled || isFixedField(field) || isTypeFixed(field)"
                                @input="validateFieldsDebounced(field)"
                            />
                        </v-col>
                        <v-col
                            cols="2"
                            lg="1"
                            sm="1"
                            class="pt-2 pb-2 pl-3 pr-0 text-center"
                        >
                            <v-btn
                                v-if="fieldHasOptions(field)"
                                icon
                                :disabled="disabled"
                                @click="editFieldOptions(field)"
                            >
                                <v-icon v-if="optionsOpen === field.id">
                                    mdi-chevron-up
                                </v-icon>
                                <v-icon v-else>
                                    mdi-chevron-down
                                </v-icon>
                            </v-btn>
                        </v-col>
                        <v-col
                            cols="10"
                            lg="1"
                            sm="1"
                            class="pt-3 pb-2 pr-0 mr-0 d-flex justify-center"
                        >
                            <v-switch
                                v-model="field.required"
                                class="mt-0 mr-0 pr-0 required"
                                :disabled="isFixedField(field) || disabled"
                                hide-details
                            />
                        </v-col>
                        <v-col cols="2"
                               lg="1"
                               sm="1"
                               class="pt-2 pb-2 text-end"
                        >
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                        icon
                                        color="error"
                                        class="mt-0"
                                        :disabled="isFixedField(field) || disabled"
                                        v-on="on"
                                        @click="deleteField(field)"
                                    >
                                        <v-icon>delete</v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="
                            fieldHasOptions(field) && optionsOpen === field.id
                        "
                    >
                        <v-col cols="8"
                               offset="2"
                               class="py-0"
                        >
                            <FieldOptionEditorCard
                                v-model="optionEditor"
                                :field="editedField"
                                :disabled="disabled"
                                @confirm="updateField"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>
            </v-card>
        </draggable>
        <v-row>
            <div v-if="!fieldValidationResult.valid"
                 class="mx-4"
            >
                <div
                    v-if="fieldValidationResult.reason === fieldOptionDuplicate"
                    class="error--text"
                >
                    There are fields with duplicate options
                </div>
                <div
                    v-if="fieldValidationResult.reason === fieldOptionNone"
                    class="error--text"
                >
                    There are fields with no options
                </div>
                <div
                    v-if="fieldValidationResult.reason === fieldNoLabel"
                    class="error--text"
                >
                    There are fields with no label
                </div>
            </div>
        </v-row>

        <FieldOptionDeleteModal
            v-model="deleteModal"
            :field="fieldToDelete"
            @confirm="confirmDeleteField"
            @cancel="closeDeleteModal"
        />
    </div>
</template>

<script>
    import draggable from 'vuedraggable';
    import {
        FIELD_TYPES,
        FIELD_VALIDATION_NO_LABEL,
        FIELD_VALIDATION_OPTION_DUPLICATE,
        FIELD_VALIDATION_OPTION_NONE,
        fieldHasOptions,
        validateFields,
        getFieldsForCompanyAndTitle,
        getFieldsForFirstName, FIELD_TYPE_ON_SITE_VIEWER,
    } from '@/services/FieldService';
    import Field from '@/models/Field';
    import { debounce } from 'lodash';
    import FieldOptionEditorCard from '@/components/controls/field-editor/FieldOptionEditorCard';
    import FieldOptionDeleteModal from '@/components/controls/field-editor/FieldOptionDeleteModal';
    import { mapGetters } from 'vuex';

    export default {
        name: 'FieldRow',
        components: { FieldOptionDeleteModal, FieldOptionEditorCard, draggable },
        props: {
            value: {
                type: Array,
                default: () => [],
            },
            fixedFields: {
                type: Array,
                default: () => [],
            },
            disabled: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                fields: [...this.value],
                editedField: null,
                optionEditor: false,
                fieldToDelete: null,
                deleteModal: false,
                fieldValidationResult: {
                    valid: true,
                    reason: '',
                },
                optionsOpen: null,
                activeField: null,
            };
        },
        computed: {
            ...mapGetters('projects', ['isHybridProject']),
            fieldOptionNone() {
                return FIELD_VALIDATION_OPTION_NONE;
            },
            fieldOptionDuplicate() {
                return FIELD_VALIDATION_OPTION_DUPLICATE;
            },
            fieldNoLabel() {
                return FIELD_VALIDATION_NO_LABEL;
            },
        },
        watch: {
            fields: {
                deep: true,
                handler(value) {
                    this.emitFields(value);
                },
            }
        },
        methods: {
            fieldTypeOptions(fieldName) {
                if(fieldName === 'company' || fieldName === 'title'){
                    return getFieldsForCompanyAndTitle();
                } else if(fieldName === 'first_name'){
                    return getFieldsForFirstName();
                } 
                return FIELD_TYPES.filter(f => {
                    if (f.value === FIELD_TYPE_ON_SITE_VIEWER) {
                        return this.isHybridProject;
                    }
                  
                    return true;
                });
            },
            isFixedField(field) {
                return this.fixedFields.indexOf(field.name) > -1;
            },
            isTypeFixed(field){
                return field.name === 'mobile';
            },
            fieldHasOptions(field) {
                return fieldHasOptions(field);
            },
            emitFields: debounce(function () {
                this.$emit('input', this.fields);
            }),
            getOptionsFieldBadgeColor(field) {
                return (field.options || []).length > 0 ? 'info' : 'error';
            },
            editFieldOptions(field) {
                if (this.optionsOpen == field.id) {
                    this.optionsOpen = null;
                    this.optionEditor = false;
                } else {
                    this.optionsOpen = field.id;
                    this.editedField = new Field({ ...field });
                    this.optionEditor = true;
                }
            },
            updateField() {
                this.fields = this.fields.map((f) => {
                    if (f.id === this.editedField.id) {
                        return this.editedField;
                    }

                    return f;
                });

                this.validateFields();
                this.emitFields();
            },
            deleteField(field) {
                this.fieldToDelete = field;
                this.deleteModal = true;
            },
            closeDeleteModal() {
                this.deleteModal = false;
                this.$nextTick(() => {
                    this.fieldToDelete = null;
                });
            },
            confirmDeleteField() {
                this.fields = this.fields.filter(
                    (f) => f.id !== this.fieldToDelete.id
                );
                this.emitFields();

                this.closeDeleteModal();
                this.validateFields();
            },
            validateFields() {
                this.fieldValidationResult = validateFields(this.fields);
                this.$emit('validate', this.fieldValidationResult);
            },
            validateFieldsDebounced(field) {
                if (this.fieldHasOptions(field)){
                    this.optionsOpen = field.id;
                    this.editedField = new Field({ ...field });
                    this.optionEditor = true;
                }
                clearInterval(this.$options.validationInterval);
                this.$options.validationInterval = setTimeout(
                    this.validateFields,
                    400
                );
            },
            isActiveField(field) {
                return this.activeField === field;
            },
            activateField(field) {
                this.activeField = field;
            }
        },
        mounted() {
            this.validateFields();
        },
    };
</script>

<style scoped
       lang="scss"
>
@import "src/_scss/components/colors";

.item-row {
    border: 1px solid transparent;
    border-top: 1px solid #f2f2f2;
    border-left-width: 3px;
    position: relative;

    .drag-handle {
        cursor: grab;
    }

    &.field-active {
        border: 1px solid $primary;
        border-left-width: 3px;
    }

    &.field-error {
        border: 1px solid $error;
        border-left-width: 3px;
    }
}

.ghost {
    border: 1px solid $primary;
}
.required {
    margin-top: 0;
    padding: 0;
    font-size: 0.74rem !important;
    line-height: 1.18rem;
}

</style>
