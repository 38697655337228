<template>
    <v-col md="2">
        <span>{{ projectEvent.createdBy ? projectEvent.createdBy.name : '' }}</span>
    </v-col>
</template>
<script>
    export default {
        name: 'ListCreatedBy',
        props: {
            projectEvent: {
                type: Object,
                required: true
            },
        }
    };
</script>

<style scoped>

</style>