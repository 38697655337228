export const addContentVariables = (ed, withOnSiteStartTime, withLocationAddress, withAgenda ) => {
    const insertTitle = function () {
        ed.insertContent('<span class="mceNonEditable">{{event-name}}</span>');
    };

    const insertStartTime = function () {
        ed.insertContent('<span class="mceNonEditable">{{event-start-time}}</span>');
    };

    const insertStartDate = function () {
        ed.insertContent('<span class="mceNonEditable">{{event-start-date}}</span>');
    };

    const insertStartTimeAndDate = function () {
        ed.insertContent('<span class="mceNonEditable">{{event-start-time-and-date}}</span>');
    };

    const insertAgenda = function () {
        ed.insertContent('<span class="mceNonEditable">{{agenda}}</span>');
    };

    const insertOnSiteStartTime = function () {
        ed.insertContent('<span class="mceNonEditable">{{event-on-site-start-time}}</span>');
    };

    const insertLocationAddress = function () {
        ed.insertContent('<span class="mceNonEditable">{{location-address}}</span>');
    };

    ed.addShortcut('meta+alt+U', 'Insert title', function () {
        insertTitle();
    });

    ed.addShortcut('meta+alt+T', 'Insert start time', function () {
        insertStartTime();
    });

    ed.ui.registry.addMenuButton('defaultTexts', {
        icon: 'plus',
        fetch: (callback) => {
            const items = [
                {
                    type: 'menuitem',
                    text: 'Insert title',
                    shortcut: 'meta+alt+U',
                    onAction: function () {
                        insertTitle();
                    }
                },
                {
                    type: 'menuitem',
                    text: 'Insert start time',
                    shortcut: 'meta+alt+T',
                    onAction: function() {
                        insertStartTime();
                    }
                },
                {
                    type: 'menuitem',
                    text: 'Insert start date',
                    shortcut: 'meta+alt+D',
                    onAction: function() {
                        insertStartDate();
                    }
                },
                {
                    type: 'menuitem',
                    text: 'Insert date and time',
                    shortcut: 'meta+alt+S',
                    onAction: function() {
                        insertStartTimeAndDate();
                    }
                },
            ];
            if (withOnSiteStartTime) {
                items.push({
                    type: 'menuitem',
                    text: 'Insert on-site Start time',
                    shortcut: 'meta+alt+O',
                    onAction: insertOnSiteStartTime
                });
            }
            if (withLocationAddress) {
                items.push({
                    type: 'menuitem',
                    text: 'Insert Location Address',
                    shortcut: 'meta+alt+L',
                    onAction: insertLocationAddress
                });
            }
            if(withAgenda){
                items.push({
                    type: 'menuitem',
                    text: 'Insert agenda',
                    shortcut: 'meta+alt+A',
                    onAction: function() {
                        insertAgenda();
                    }
                });
            }
            callback(items);
        }
    });
};

export function toNonEditable(value) {
    let content = value;
    content = content.replaceAll('{{event-name}}', '<span class="mceNonEditable">{{event-name}}</span>');
    content = content.replaceAll('{{event-start-time}}', '<span class="mceNonEditable">{{event-start-time}}</span>');
    content = content.replaceAll('{{event-start-date}}', '<span class="mceNonEditable">{{event-start-date}}</span>');
    content = content.replaceAll('{{event-start-time-and-date}}', '<span class="mceNonEditable">{{event-start-time-and-date}}</span>');
    content = content.replaceAll('{{agenda}}', '<span class="mceNonEditable">{{agenda}}</span>');

    return content;
}

export function toPlaceholder(value, linkColor) {
    let result = value;
    result = result.replaceAll('<a',`<a style="color:${linkColor}"`);
    result = result.replaceAll('<span class="mceNonEditable">{{event-name}}</span>', '{{event-name}}');
    result = result.replaceAll('<span class="mceNonEditable">{{event-start-time}}</span>', '{{event-start-time}}');
    result = result.replaceAll('<span class="mceNonEditable">{{event-start-date}}</span>', '{{event-start-date}}');
    result = result.replaceAll('<span class="mceNonEditable">{{event-start-time-and-date}}</span>', '{{event-start-time-and-date}}');
    result = result.replaceAll('<span class="mceNonEditable">{{agenda}}</span>', '{{agenda}}');

    return result;
}