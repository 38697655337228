<template>
    <v-list v-if="items.length <= listLengthLimit"
            class="list-filter-selector pt-0"
            dense
            flat
    >
        <v-list-item v-if="items.length === 0">
            <v-list-item-title>No results found</v-list-item-title>
        </v-list-item>
        <v-list-item v-if="items.length > listLengthLimit">
            <v-list-item-title>Please narrow your search</v-list-item-title>
        </v-list-item>
        <v-list-item-group :key="`selected-length-${columnId}-${selected.length}`"
                           multiple
                           :value="selected"
        >
            <v-list-item v-for="item in sortedItems.slice(0,listLengthLimit)"
                         :key="`selectable-${columnId}-${item.id}`"
                         :class="`selectable-${item.id}`"
                         @click.prevent="toggleSelected(item)"
            >
                <template v-slot:default>
                    <v-list-item-action>
                        <v-checkbox :input-value="hasSelected(item)"
                                    @click.prevent=""
                        />
                    </v-list-item-action>
                    <v-list-item-content>
                        <v-list-item-subtitle v-if="item.subtitle">
                            {{ item.subtitle }}
                        </v-list-item-subtitle>
                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                </template>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>
<script>
    export default {
        name: 'ListFilterSelector',
        data() {
            return {
                listLengthLimit: this.maxListLength > 0 ? this.maxListLength : 10000,
            };
        },
        props: {
            items: {
                type: Array,
                required: true,
            },
            selected: {
                type: Array,
                required: true,
            },
            columnId: {
                type: String,
                required: true,
            },
            maxListLength: {
                type: Number,
                default: 5
            }
        },
        computed: {
            sortedItems() {
                return [...this.items].sort((a, b) => {
                    if (a.name === b.name) {
                        return 0;
                    }

                    return a.name < b.name ? -1 : 1;
                });
            }
        },
        methods: {
            toggleSelected(item) {
                this.$emit('input', item);
            },
            hasSelected(item) {
                return this.selected.filter(i => i.id === item.id).length > 0;
            }
        }
    };
</script>

<style scoped>
.v-list-item__action {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 10px !important;
}

.v-list-item__title {
    text-align: left;
}

.v-list-item__subtitle {
    text-align: left;
}
</style>