<template>
    <div class="d-flex align-center">
        <div class="d-inline-block mr-5">
            Hide calendar invite
        </div>
        <div class="d-flex align-center">
            <v-switch 
                v-model="hideCalendarInvite"
                inset
                :disabled="project.isArchived"
            />
            <v-tooltip top>
                <template v-slot:activator="{on}">
                    <v-icon class="pb-1"
                            v-on="on"
                    >
                        info
                    </v-icon>
                </template>
                <span>{{ `Hide the calendar invite on the registration-confirmation page and in the emails.` }}</span>
            </v-tooltip>
        </div>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';
    export default {
        name: 'HideCalendarInvite',
        computed: {
            ...mapState('projects', ['project']),
            hideCalendarInvite: {
                get() {
                    return this.project.hideCalendarInvite;
                },
                set(value) {
                    this.setHideCalendarInvite(value);
                    this.saveProject({
                        id: this.project._id,
                        key: 'hideCalendarInvite',
                        value,
                    });
                },
            },
        },
        methods: {
            ...mapMutations('projects', ['setHideCalendarInvite']),
            ...mapActions('projects', ['saveProject']),
        }
    };
</script>
