<template>
    <div>
        <v-row>
            <v-col cols="12"
                   md="6"
            >
                <h3 class="mb-2 font-weight-bold">
                    Mail settings
                </h3>
                <v-divider class="mb-1" />
                <toggle-enable-mailings class="pl-4 mt-4"
                                        :class="{'mb-5': !mailingsEnabled}"
                />
                <toggle-extra-mail v-if="mailingsEnabled"
                                   class="mb-5 pl-4"
                />
                <h3 v-if="canHaveRegistrationForOnDemand"
                    class="mb-2 font-weight-bold"
                >
                    On-demand settings
                </h3>
                <v-divider v-if="canHaveRegistrationForOnDemand"
                           class="mb-1"
                />
                <registration-for-on-demand v-if="canHaveRegistrationForOnDemand"
                                            class="mb-5"
                />
            </v-col>
            <v-col cols="12"
                   md="6"
            >
                <h3 v-if="!isDutchProject"
                    class="mb-2 font-weight-bold"
                >
                    Time settings
                </h3>
                <v-divider v-if="!isDutchProject"
                           class="mb-1"
                />
                <hide-timezone v-if="!isDutchProject"
                               class="mb-5 pl-4"
                />
                <h3 class="mb-2 font-weight-bold">
                    Calendar settings
                </h3>
                <v-divider class="mb-1" />
                <calendar-title />
                <hide-calendar-invite class="mb-5 pl-4" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <h3>Custom Call To Action</h3>
                <v-divider class="mb-1" />
                <custom-cta-button v-if="!isSpringerProject" />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <privacy-settings class="mb-5" />
                <h3 class="mb-2 font-weight-bold">
                    Branding settings
                </h3>
                <v-divider class="mb-10" />
                <font-selector class="mb-10 pl-4" />
                <border-selector class="mb-10 px-4" />
                <logo-width-height-registration class="mb-5 pl-4" />
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import ToggleExtraMail from '@/components/pages/Project/ProjectOverviewElements/ToggleExtraMail';
    import HideTimezone from '@/components/pages/Project/ProjectOverviewElements/HideTimezone';
    import HideCalendarInvite from '@/components/pages/Project/ProjectOverviewElements/HideCalendarInvite';
    import LogoWidthHeightRegistration from '@/components/pages/Project/ProjectOverviewElements/LogoWidthHeightRegistration';
    import PrivacySettings from '@/components/pages/Project/ProjectOverviewElements/PrivacySettings';
    import CalendarTitle from '@/components/pages/Project/ProjectOverviewElements/CalendarTitle';
    import BorderSelector from '@/components/pages/Project/ProjectOverviewElements/BorderSelector';
    import FontSelector from '@/components/pages/Project/ProjectOverviewElements/FontSelector';
    import { mapGetters, mapState } from 'vuex';
    import RegistrationForOnDemand from '@/components/pages/Project/ProjectOverviewElements/RegistrationForOnDemand';
    import CustomCtaButton from '@/components/controls/CustomCtaButton.vue';
    import ToggleEnableMailings from '@/components/pages/Project/ProjectOverviewElements/ToggleEnableMailings.vue';

    export default {
        name: 'Advanced',
        components: {
            ToggleEnableMailings,
            CustomCtaButton,
            RegistrationForOnDemand,
            HideTimezone,
            HideCalendarInvite,
            LogoWidthHeightRegistration,
            PrivacySettings,
            ToggleExtraMail,
            CalendarTitle,
            BorderSelector,
            FontSelector,
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['isDutchProject', 'isSpringerProject', 'mailingsEnabled']),
            canHaveRegistrationForOnDemand() {
                return this.project.registration.type === 'registration' && !this.project.registration.showLinkedIn;
            },
        }
    };
</script>
