<template>
    <div>
        <v-row>
            <v-col cols="12"
                   class="input-col-right py-0 pl-3"
            >
                <image-picker v-model="backgroundImage"
                              :max-file-size="maxFileSize"
                              :rules="backgroundRules"
                              :upload-url="uploadUrl"
                              form-data-key="background"
                              label="Background image"
                >
                    <template v-slot:hint>
                        <background-hint />
                    </template>
                </image-picker>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="align-center py-0">
                <div class="d-flex mb-2">
                    <label class="mr-10">
                        Primary color
                    </label>
                    <custom-color-picker
                        :key="primaryColor"
                        v-model="primaryColor"
                        :disabled="project.isArchived"
                        class="mr-8"
                    />
                    <v-tooltip top>
                        <template v-slot:activator="{on}">
                            <v-icon v-on="on">
                                info
                            </v-icon>
                        </template>
                        <span>This will be applied to the main background, to the active tabs and to the cards.</span>
                    </v-tooltip>
                </div>
                <div class="d-flex mb-1">
                    <label class="mr-5">
                        Secondary color
                    </label>
                    <custom-color-picker
                        v-model="secondaryColor"
                        :disabled="project.isArchived"
                        class="mr-8"
                    />
                    <v-tooltip top>
                        <template v-slot:activator="{on}">
                            <v-icon v-on="on">
                                info
                            </v-icon>
                        </template>
                        <span>This will be applied to the Header and the Menu.</span>
                    </v-tooltip>
                </div>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex py-0">
                <div class="d-inline-block gradient-toggle-label mr-4 mt-1">
                    Enforce color
                </div>
                <v-switch v-model="gradient"
                          hide-details
                          class="mt-0"
                          inset
                          :disabled="project.isArchived"
                />
                <v-tooltip top>
                    <template v-slot:activator="{on}">
                        <v-icon v-on="on">
                            info
                        </v-icon>
                    </template>
                    <span>This will enforce the primary color on the background <br> and disable the gradient.</span>
                </v-tooltip>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapActions,mapMutations, mapState } from 'vuex';
    import CustomColorPicker from '@/components/controls/CustomColorPicker';
    import ImagePicker from '@/components/controls/ImagePicker';
    import BackgroundHint from '@/components/controls/hints/BackgroundHint';
    import {BACKGROUND_UPLOAD_URL } from '@/services/ApiService';
    import {
        BACKGROUND_MAX_FILE_SIZE,
        backgroundValidationRules,
    } from '@/services/fileValidation';

    export default {
        name: 'PwaStyleSettings',
        components: {ImagePicker, CustomColorPicker, BackgroundHint },
        computed: {
            ...mapState('projects', ['project']),
            primaryColor: {
                get() {
                    return this.project.pwaStyle.primaryColor;
                },
                set(value) {
                    this.setPwaPrimaryColor(value);
                    this.saveProject({
                        key: 'pwaStyle.primaryColor',
                        value,
                    });
                },
            },
            secondaryColor:{
                get() {
                    return this.project.pwaStyle.secondaryColor;
                },
                set(value) {
                    this.setPwaSecondaryColor(value);
                    this.saveProject({
                        key: 'pwaStyle.secondaryColor',
                        value,
                    });
                },
            },
            gradient:{
                get() {
                    return !this.project.pwaStyle.backgroundGradient;
                },
                set(value) {
                    this.setPwaBackgroundGradient(!value);
                    this.saveProject({
                        key: 'pwaStyle.backgroundGradient',
                        value: !value,
                    });
                },
            },
            backgroundImage:{
                get() {
                    return {'url': this.backgroundUrl, 'key': this.background};
                },
                set(value) {
                    this.backgroundUrl = value.url;
                    this.background = value.key;
                },
            },
            backgroundUrl: {
                get() {
                    return this.project.pwaStyle?.backgroundUrl;
                },
                set(value) {
                    this.setPwaBackgroundUrl(value);
                },
            },
            background: {
                get() {
                    return this.project.pwaStyle.background;
                },
                set(value) {
                    this.setPwaBackground(value);
                    this.saveProject({
                        key: 'pwaStyle.background',
                        value,
                    });
                },
            },
            backgroundRules(){
                return backgroundValidationRules;
            },
            maxFileSize(){
                return BACKGROUND_MAX_FILE_SIZE;
            },
            uploadUrl(){
                return BACKGROUND_UPLOAD_URL;
            }
        },
        methods: {
            ...mapActions('projects', ['saveProject', 'saveProjectImmediate']),
            ...mapMutations('projects', [
                'setPwaPrimaryColor',
                'setPwaSecondaryColor',
                'setPwaBackgroundGradient',
                'setPwaBackground',
                'setPwaBackgroundUrl',
            ]),
            ...mapActions('projects', ['saveProject']),
        },
    };
</script>

<style scoped>
.logo {
    max-width: 40%;
}
</style>

<style lang="scss">
.file-input{
    .v-icon.v-icon.v-icon--link{
        padding-top: 10px;
        color: #2977BE
    }
}

.gradient-toggle-label {
    width: 138px;
}

</style>

