<template>
    <v-card elevation="3"
            class="mb-6 mx-10"
    >
        <v-row class="ml-3">
            <v-col cols="7">
                <h2 class="font-weight-regular">
                    {{ poll.question }}
                </h2>
            </v-col>
            <v-col>
                <h2 class="font-weight-regular">
                    {{ totalAnswers }} answers
                </h2>
            </v-col>
        </v-row>
        <div v-for="(option, index) in poll.options"
             :key="index"
        >
            <v-row class="line-container ml-3 pl-0">
                <v-col cols="8">
                    <h3 class="font-weight-regular">
                        {{ option.text }}
                    </h3>
                    <v-row class="ml-1">
                        <div
                            :style="lineLength(option)"
                            :class="chooseClass(option.votesCount)"
                        />
                        <h3 class="ml-2 mt-1 font-weight-regular">
                            {{ percentage(option) + "%" }} ({{ option.votesCount }})
                        </h3>
                    </v-row>
                </v-col>
            </v-row>
        </div>
    </v-card>
</template>


<script>
    export default {
        name: 'PollsDisplay',
        props: ['poll'],
        computed: {
            totalAnswers() {
                let count = 0;
                for (const option in this.poll.options) {
                    count += this.poll.options[option].votesCount;
                }
                return count;
            },
            maximumVotes() {
                let arr = [];
                for (const option in this.poll.options) {
                    arr.push(this.poll.options[option].votesCount);
                }
                return Math.max(...arr);
            },
        },
        methods: {
            percentage(option) {
                return this.totalAnswers === 0
                    ? 0
                    : Math.round((option.votesCount * 100) / this.totalAnswers);
            },
            lineLength(option) {
                const percent = this.percentage(option);
                if (percent === 0) {
                    return 'width: 1%';
                } else if (percent > 90) {
                    return 'width: 90%';
                }
                return `width: ${percent}%`;
            },
            chooseClass(votesCount) {
                return votesCount === this.maximumVotes ? 'line' : 'line-grey';
            },
        },
    };
</script>

<style scoped>
.line {
  height: 12px;
  margin-bottom: 7px;
  margin-top: 9px;
  border-width: 4px;
  border-radius: 10px;
  background-color: #2196f3 !important;
  border-color: #2196f3 !important;
}

.line-grey {
  height: 10px;
  margin-bottom: 7px;
  margin-top: 9px;
  border-width: 4px;
  border-radius: 10px;
  background-color: #e91e63 !important;
  border-color: #e91e63 !important;
}

.line-containre {
  display: flex;
  align-items: flex-start;
}

h2 {
  color: rgba(17, 17, 17, 0.698);
}

h3 {
  color: rgba(0, 0, 0, 0.6);
}
</style>