<template>
    <stats-card class="h13 metric"
                :loading="loading"
    >
        <template v-slot:title>
            Viewed Ratio
        </template>
        <template v-slot:content>
            {{ viewedRatio }} %
        </template>
    </stats-card>
</template>
<script>
    import { fetchStatisticsApi } from '@/services/StatisticsService';
    import StatsCard from '@/components/pages/Project/StatisticsTabs/OndemandStatistics/StatsCard.vue';

    export default {
        name: 'OnDemandViewRatio',
        components: {StatsCard},
        props: {
            dateRange: {
                type: Array,
                required: true
            },
            videoId: {
                type: String,
                required: true
            }
        },
        data() {
            return {
                loading: false,
                viewedRatio: 0,
            };
        },
        methods: {
            getViewedRatio() {
                this.loading = true;
                fetchStatisticsApi('/api/videos/view-ratio', this.dateRange, this.videoId).then(response => {
                    this.viewedRatio = response.data;
                }).finally(() => this.loading = false);
            },
        },
        mounted() {
            this.getViewedRatio();
        }
    };
</script>

<style scoped>

</style>