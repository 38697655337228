<template>
    <div class="header-item">
        <h4 class="header-item-title">
            {{ name }}
        </h4>
        <div class="header-filters">
            <intake-list-head-filter-popup v-if="filterItems"
                                           :is-filtered="isFilteredBy"
                                           :name="name"
                                           class="ml-1"
            >
                <intake-list-head-filter
                    :items="filterItems"
                    :column-id="id"
                />
            </intake-list-head-filter-popup>
        </div>
    </div>
</template>

<script>
    import {mapState} from 'vuex';
    import IntakeListHeadFilterPopup from '@/components/pages/Intake/IntakeListHeadFilterPopup';
    import IntakeListHeadFilter from '@/components/pages/Intake/IntakeListHeadFilter';

    export default {
        name: 'IntakeListHeaderItem',
        components: {IntakeListHeadFilter, IntakeListHeadFilterPopup},
        props: ['id', 'name', 'filterItems'],
        computed: {
            ...mapState('intake', ['listingHeader']),
            isFilteredBy(){
                return this.listingHeader.filtering.filters && this.listingHeader.filtering.filters[this.id] && this.listingHeader.filtering.filters[this.id].length;
            },
            isStartTime(){
                return this.name === 'Start Time';
            },
        },
    };
</script>

<style lang="scss" scoped>

button.v-icon {
  font-size: 18px !important;
}
</style>
