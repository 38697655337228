import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import 'vue-datetime/dist/vue-datetime.css';
import vuetify from './plugins/vuetify';
import './_scss/main.scss';
import { fetchNewVersion } from './services/versioning';
import * as VueGoogleMaps from 'vue2-google-maps';


setInterval(fetchNewVersion, 120000);

Vue.config.productionTip = true;

Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
    libraries: 'places'
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');