<template>
    <v-dialog :value="value"
              max-width="700"
    >
        <v-card>
            <v-card-title>
                Would you like to delete the selected user?
                <v-spacer />
                <v-btn icon
                       small
                       @click="closeDeleteModal"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <p>User <b>{{ user.email }}</b>,  <b>{{ user.role }}</b>, will lose access to this product.</p>
                <p>Do you wish to continue?</p>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn color="error"
                       @click="confirmDeleteUser"
                >
                    Yes, delete
                </v-btn>
                <v-btn @click="closeDeleteModal">
                    No
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import User from '@/models/User';

    export default {
        name: 'DeleteUserDialog',
        props: {
            value: {
                type: Boolean,
                default: () => false
            },
            user: {
                type: Object,
                default: () => new User([])
            }
        },
        methods: {
            closeDeleteModal() {
                this.$emit('input', false);
            },
            confirmDeleteUser() {
                this.$emit('confirm');
                this.closeDeleteModal();
            }
        }
    };
</script>

<style scoped>

</style>