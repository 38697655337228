<template>
    <v-row>
        <v-col>
            <h3 class="mb-2 font-weight-bold todo-tbd">
                Privacy settings
            </h3>
            <v-divider class="mb-3" />
            <v-text-field
                v-model="privacyPolicyUrl"
                class="text-input pl-4"
                prepend-icon="link"
                label="Privacy Policy"
                :disabled="project.isArchived"
            />
            <span v-if="!isValid"
                  class="error--text"
            >It looks like you’ve filled in an invalid link. Please check if the link starts with http:// or https://</span>
        </v-col>
    </v-row>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'PrivacySettings',
        data() {
            return {
                isValid: true,
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            privacyPolicyUrl: {
                get() {
                    return this.project.privacyPolicyUrl;
                },
                set(value) {
                    this.validateUrl(value);
                    if(this.isValid || !value){
                        this.setProjectPrivacyPolicyUrl(value);
                        this.saveProject({
                            key: 'privacyPolicyUrl',
                            value,
                        });
                    }
                },
            },
        },
        methods: {
            ...mapMutations('projects', [
                'setProjectPrivacyPolicyUrl',
            ]),
            ...mapActions('projects', ['saveProject']),
            validateUrl(value) {
                let validUrlPattern = new RegExp('^(https?:\\/\\/)'+ // protocol
                    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
                    '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
                    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
                    '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
                    '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
                this.isValid = !value ? true : !!validUrlPattern.test(value);
            }
        },
    };
</script>

<style scoped>
</style>
