<template>
    <v-row class="mx-0">
        <v-col cols="12"
               md="6"
               lg="3"
               class="pt-4"
        >
            Last update: <span class="black--text">
                <timestamp-to-date :value="intake.timestamps.updatedAt" />
            </span>
        </v-col>
        <v-col cols="12"
               md="6"
               lg="3"
               class="pt-4"
        >
            Last updated by:
            <span class="black--text">N/A</span>
        </v-col>
        <v-col cols="12"
               md="6"
               lg="3"
        >
            <v-tooltip bottom>
                <template v-slot:activator="{on}">
                    <div class="d-flex">
                        <v-switch class="mt-n1 mb-n8"
                                  :input-value="preparationSection"
                                  label="Lock Preparation Phase"
                                  hide-details
                                  v-on="on"
                                  @change="togglePreparationSection"
                        />
                        <v-icon class="ml-2 mt-1"
                                small
                                v-on="on"
                        >
                            help
                        </v-icon>
                    </div>
                </template>
                <span>
                    This will disable the preparation phase steps on the intake <br>form for this order. (Up to and including the landing page step.)
                </span>
            </v-tooltip>
        </v-col>
        <v-col cols="12"
               md="6"
               lg="3"
        >
            <v-tooltip bottom>
                <template v-slot:activator="{on}">
                    <div class="d-flex">
                        <v-switch class="mt-n1 mb-n8"
                                  :input-value="liveSection"
                                  label="Lock Live Phase"
                                  hide-details
                                  v-on="on"
                                  @change="toggleLiveSection"
                        />
                        <v-icon class="ml-2 mt-1"
                                small
                                v-on="on"
                        >
                            help
                        </v-icon>
                    </div>
                </template>
                <span>
                    This will disable the live phase of the intake form for this order (from the reminder mail settings to the Evaluation page step).
                </span>
            </v-tooltip>
        </v-col>
    </v-row>
</template>

<script>
    import TimestampToDate from '@/components/controls/TimestampToDate';
    import {
        INTAKE_LIVE_SECTION,
        INTAKE_PREPARATION_SECTION,
    } from '@/services/ApiService';
    import {mapActions} from 'vuex';
    export default {
        name: 'IntakeGlobal',
        components: {TimestampToDate},
        props: {
            value: {
                type: Object,
                default: () => {
                    return {
                        ui: {
                            step: 1,
                            disabledSections: []
                        },
                        timestamps: {
                            createdAt: null,
                            updatedAt: null
                        }
                    };
                },
            },
            intakeId: String
        },
        data() {
            return {
                intake: JSON.parse(JSON.stringify(this.value)),
            };
        },
        computed: {
            preparationSection() {
                return this.intake.ui.disabledSections.indexOf(INTAKE_PREPARATION_SECTION) > -1;
            },
            liveSection() {
                return this.intake.ui.disabledSections.indexOf(INTAKE_LIVE_SECTION) > -1;
            }
        },
        methods: {
            ...mapActions('intake', ['toggleSection']),
            togglePreparationSection() {
                this.toggleSection({
                    id: this.intakeId,
                    section: INTAKE_PREPARATION_SECTION
                }).then(() => this.updateSections(INTAKE_PREPARATION_SECTION));
            },
            toggleLiveSection() {
                this.toggleSection({
                    id: this.intakeId,
                    section: INTAKE_LIVE_SECTION
                }).then(() => this.updateSections(INTAKE_LIVE_SECTION));
            },
            updateSections(section) {
                if (this.intake.ui.disabledSections.indexOf(section) > -1) {
                    this.intake.ui.disabledSections = this.intake.ui.disabledSections.filter(s => s !== section);
                } else {
                    this.intake.ui.disabledSections.push(section);
                }
            }
        }
    };
</script>

<style scoped>

</style>