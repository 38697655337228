<template>
    <div class="filter-popup-container">
        <list-filter-button :is-open="isOpen"
                            :is-filtered="isFiltered"
                            :tooltip="tooltipContentFilter"
                            @input="openPopup"
        />
        <list-filter-card v-if="isOpen"
                          @close="closeMenu"
        >
            <slot />
        </list-filter-card>
    </div>
</template>

<script>
    import {v4 as uuidv4} from 'uuid';
    import { mapMutations, mapGetters } from 'vuex';
    import ListFilterButton from '@/components/controls/list-filters/popup/ListFilterButton';
    import ListFilterCard from '@/components/controls/list-filters/popup/ListFilterCard';

    export default {
        name: 'ProjectListHeadFilterPopup',
        components: {ListFilterCard, ListFilterButton},
        props: ['isFiltered', 'name', 'part'],
        computed: {
            ...mapGetters('projectLists', ['listingHeader']),
            buttonId() {
                return `button-filter-${uuidv4()}`;
            },
            isOpen() {
                return this.listingHeader(this.part).filtering.openPopup === this.buttonId;
            },
            isCompany(){
                return this.name === 'Company';
            },
            tooltipContentFilter(){
                return this.isCompany ? 'Filter by company' : 'Filter by name';
            },
        },
        methods: {
            ...mapMutations('projectLists', ['setHeaderOpenPopup']),
            closeMenu() {
                this.setHeaderOpenPopup({
                    listing: this.part,
                    value: ''
                });
            },
            openPopup() {
                const value = this.isOpen ? '' : this.buttonId;
                this.setHeaderOpenPopup({
                    listing: this.part,
                    value
                });
            }
        }
    };
</script>

