<template>
    <div v-if="!!project"
         :key="key"
    >
        <div class="list-wrapper">
            <sessions-list-header class="session" />
            <div v-for="(item, index) in internalEvents"
                 :key="getInternalKey(item)"
                 class="newlist"
                 :class="itemClass(index)"
            >
                <v-card v-if="isEvent(item)"
                        elevation="0"
                        rounded="0"
                        class="px-4 session"
                >
                    <session-list-item>
                        <template v-slot:type>
                            <h5 class="font-weight-bold">
                                <span class="session-type">
                                    <event-type :event="item" />Session
                                </span>
                            </h5>
                        </template>
                        <template v-slot:startTime>
                            {{ item.startTimeFormattedTime }}
                        </template>
                        <template v-slot:duration>
                            {{ item.durationInMinutes + ' min' }}
                        </template>
                        <template v-slot:eventName>
                            <a class="text-decoration-none"
                               target="_blank"
                               :href="getEventDashboardUrl(item._id, registrationType)"
                            >
                                {{ item.name }}
                            </a>
                        </template>
                        <template v-slot:actions>
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-btn
                                        icon
                                        :x-small="true"
                                        v-on="on"
                                        @click="editSession(item)"
                                    >
                                        <v-icon size="20">
                                            mdi-pencil
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Edit</span>
                            </v-tooltip>
                            <v-tooltip bottom>
                                <template v-slot:activator="{on}">
                                    <v-btn icon
                                           :x-small="true"
                                           v-on="on"
                                           @click="initiateDelete(item)"
                                    >
                                        <v-icon size="20">
                                            mdi-delete
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span>Delete</span>
                            </v-tooltip>
                        </template>
                    </session-list-item>
                </v-card>
                <breakout-group-editor v-else
                                       :value="item"
                                       @input="updateList"
                />
            </div>
        </div>
        <v-row>
            <v-col>
                <v-btn color="primary"
                       outlined
                       :disabled="project.isArchived"
                       class="mr-3"
                       @click="createSession"
                >
                    <v-icon>mdi-plus</v-icon>
                    Session
                </v-btn>
                <v-btn color="primary"
                       outlined
                       :disabled="project.isArchived"
                       @click="createBreakout"
                >
                    <v-icon>mdi-plus</v-icon>
                    Breakout
                </v-btn>
            </v-col>
        </v-row>
        <delete-dialog v-if="showDeleteDialog"
                       v-model="showDeleteDialog"
                       @confirm="deleteSession"
        >
            <template v-slot:title>
                This will delete the selected session.
            </template>
            <template v-slot:content>
                <p class="mt-4">
                    This operation cannot be undone. Are you sure?
                </p>
            </template>
        </delete-dialog>
        <session-dialog v-if="showSessionModal"
                        v-model="showSessionModal"
                        :session="selectedSession"
                        @updateList="updateList"
        />
        <create-breakout-group v-if="showBreakoutModal"
                               v-model="showBreakoutModal"
                               @confirm="saveBreakout"
        />
    </div>
</template>

<script>
    import SessionsListHeader from '@/components/pages/Project/SessionList/SessionsListHeader';
    import SessionDialog from '@/components/pages/Project/Elements/SessionDialog';
    import { mapActions, mapGetters, mapState } from 'vuex';
    import EventType from '@/components/pages/Project/Elements/EventType';
    import { buildSessionList, deleteEvent, getEventDashboardUrl } from '@/services/SessionService';
    import SessionListItem from '@/components/pages/Project/SessionList/SessionListItem';
    import BreakoutGroupEditor from '@/components/pages/Project/Elements/BreakoutGroupEditor';
    import DeleteDialog from '@/components/controls/dialogs/DeleteDialog';
    import CreateBreakoutGroup from '@/components/pages/Project/SessionList/CreateBreakoutGroup';
    import { BREAKOUT_SESSION_EVENT_URL, populateUrl } from '@/services/ApiService';
    import { $http } from '@/services/http';

    export default {
        name: 'SessionsList',
        props:{},
        components:{
            CreateBreakoutGroup,
            DeleteDialog,
            BreakoutGroupEditor,
            SessionListItem,
            EventType,
            SessionsListHeader,
            SessionDialog,
        },
        data(){
            return {
                loading : false,
                showDeleteDialog: false,
                showBreakoutModal: false,
                showSessionModal: false,
                eventToDelete: null,
                selectedSession: null,
                key:0,
            };
        },
        computed:{
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['registrationType', 'startTimeInconsistent']),
            internalEvents() {
                return buildSessionList(this.project.events);
            }
        },
        methods:{
            ...mapActions('projects', ['getProject']),
            getEventDashboardUrl,
            getInternalKey(item) {
                const keyVariant = (Array.isArray(item) ? item.find(x => x !== undefined)?._id || Math.random() : item._id) + '-group';
                return `create-event-list-${keyVariant}`;
            },
            isEvent(item) {
                return !Array.isArray(item) && typeof item === 'object';
            },
            initiateDelete(event){
                this.eventToDelete = event;
                this.showDeleteDialog = true;
            },
            editSession(session){
                this.selectedSession = session;
                this.showSessionModal = true;
            },
            createSession() {
                this.selectedSession = null;
                this.showSessionModal = true;
            },
            createBreakout() {
                this.showBreakoutModal = true;
            },
            async saveBreakout(breakout) {
                const url = populateUrl(BREAKOUT_SESSION_EVENT_URL, this.project._id);
                const firstBreakout = breakout.find(x => x !== undefined);
                let names = {};
                breakout.map((b) => {
                    names[b.name] = b.registrationsCountLimit;
                });
                await $http.post(url, {
                    startTime: firstBreakout.startTime,
                    durationInMinutes: firstBreakout.durationInMinutes,
                    names: names,
                    breakoutGroupName: firstBreakout.breakoutGroupName,
                });
                this.updateList();
            },
            async deleteSession() {
                await deleteEvent(this.project._id, this.eventToDelete._id);
                await this.updateList();
            },
            async updateList(){
                await this.getProject(this.project._id);
                ++this.key;
            },
            itemClass(index) {
                return {
                    'warning': index === 0 && this.startTimeInconsistent
                };
            }
        },
    };
</script>

<style lang="scss" scoped>
h5{
    display: inline-block;
    vertical-align: sub;
}

.list-wrapper{
    border: 1px solid #ccc  !important;
    border-radius: 2px!important;
}

.session {
    border-bottom: 1.5px solid rgba(0,0,0,.35) !important;
}

.newlist.warning {
  border-width: 1px;
  border-style: solid;
}
</style>
