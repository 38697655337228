<template>
    <v-dialog max-width="700"
              persistent
              :value="true"
              @click:outside="cancel"
    >
        <v-card>
            <v-card-title>
                Delete speaker
            </v-card-title>
            <v-card-text>
                <p>Would you like to delete the following speaker?</p>
                <p>{{ speaker.name }}</p>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn color="error"
                       @click="confirm"
                >
                    Delete
                </v-btn>
                <v-btn @click="cancel">
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    export default {
        name: 'SpeakerDeleteModal',
        props: {
            speaker: {
                type: Object,
                required: true
            }
        },
        methods: {
            confirm() {
                this.$emit('input', this.speaker);
            },
            cancel() {
                this.$emit('input', null);
            }
        }
    };
</script>

<style scoped>

</style>