<template>
    <div class="avatar-editor">
        <div v-if="!value.url"
             class="d-flex"
        >
            <v-file-input
                :key="avatarKey"
                ref="avatar-uploader"
                :rules="avatarRules"
                hide-input
                accept="image/jpg, image/jpeg, image/png, *.jpeg, *.jpg, *.png"
                prepend-icon="mdi-camera"
                label="Avatar"
                class="ma-0 pa-0 mr-5 speaker-avatar-input justify-space-around"
                :disabled="disabled"
                @change="onFileSelect"
            />
            <speaker-avatar-hint />
        </div>
        <image-cropper :file="selectedFile"
                       @save="uploadAvatar"
                       @cancel="avatarKey++"
        />
        <div v-if="value.url"
             class="d-flex"
        >
            <v-avatar v-if="!avatarUploading"
                      size="110"
            >
                <img :src="value.url"
                     :alt="value.url"
                >
            </v-avatar>
            <v-progress-circular v-else
                                 indeterminate
            />
            <v-btn
                icon
                color="primary"
                :disabled="disabled"
                class="mt-8"
                @click="removeImage()"
            >
                <v-icon>
                    mdi-image-remove
                </v-icon>
            </v-btn>
        </div>
    </div>
</template>
<script>
    import {
        SPEAKER_AVATAR_MAX_FILE_SIZE,
        speakerAvatarValidationRules
    } from '@/services/fileValidation';
    import SpeakerAvatarHint from '@/components/controls/hints/SpeakerAvatarHint';
    import { toastError } from '@/services/responseErrors';
    import { $http } from '@/services/http';
    import { SPEAKER_AVATAR_UPLOAD_URL } from '@/services/ApiService';
    import ImageCropper from '@/components/controls/ImageCropper';

    export default {
        name: 'AvatarEditor',
        components: {SpeakerAvatarHint, ImageCropper},
        props: {
            value: {
                type: Object,
                required: true
            },
            disabled: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                avatarKey:0,
                avatarUploading: false,
                selectedFile:''
            };
        },
        computed: {
            avatarRules(){
                return speakerAvatarValidationRules;
            }
        },
        methods: {
            removeImage() {
                this.$emit('input', {
                    'url': '',
                    'key': ''
                });
            },
            async uploadAvatar(file) {
                if (file === undefined || file.size > SPEAKER_AVATAR_MAX_FILE_SIZE) {
                    if (file.size > SPEAKER_AVATAR_MAX_FILE_SIZE) {
                        toastError('File is too large, please make sure it\'s smaller than ' + (SPEAKER_AVATAR_MAX_FILE_SIZE / 1000) + 'KB.' );
                    }
                    return Promise.reject('Invalid file.');
                }
                const formData = new FormData();
                formData.append('avatar', file);
                this.avatarUploading = true;
                const {data} = await $http.post(SPEAKER_AVATAR_UPLOAD_URL, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                });
                this.$emit('input', data);
                this.avatarUploading = false;
            },
            onFileSelect(e){
                this.selectedFile = e;
            }
        },

    };
</script>

<style lang="scss">
.speaker-avatar-input {
    .v-icon{
        position: absolute !important;
        font-size: 6rem !important;
    }
}
</style>