const DynamicBlocks = class {
    constructor (data) {
        this.layout = data?.layout || 'default';
        this.layouts = data?.layouts || [];
    }
};

export default DynamicBlocks;



