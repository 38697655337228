export const defaultMailingsEnabled = (registrationType, companyId) => {
    switch (registrationType) {
        default:
        case 'registration':
            return true;
        case 'generated':
        case 'open':
            return false;
        case 'springer':
            return companyId === process.env.VUE_APP_SPRINGER_INTERNATIONAL_SMEDCOM_COMPANY_ID;
    }
};