<template>
    <div>
        <v-btn :disabled="project.isArchived"
               @click="openDialog"
        >
            <slot name="buttonText">
                Send now
            </slot>
        </v-btn>
        <v-dialog v-model="dialog"
                  :max-width="500"
        >
            <v-card>
                <v-card-title>
                    <slot name="title">
                        Send "Extra" email
                    </slot>
                </v-card-title>
                <v-card-text class="d-flex align-center py-5 subtitle-1">
                    Are you sure you want to send the “Extra” email?
                </v-card-text>
                <v-divider />
                <v-card-actions>
                    <v-spacer />
                    <v-btn color="primary"
                           type="submit"
                           class="mr-2"
                           :loading="sending"
                           :disabled="sending"
                           @click="send"
                    >
                        Send
                    </v-btn>
                    <v-btn @click="closeDialog">
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
    import { $http } from '@/services/http';
    import {EMAIL_SEND_EXTRA, populateUrl} from '@/services/ApiService';
    import {mapState} from 'vuex';
    import {toastMessage, toastSuccess} from '@/services/responseErrors';

    export default {
        name: 'SendExtraEmail',
        props:{
            selectedEmailGroup:{
                type: String,
                required: true
            }
        },
        data() {
            return {
                dialog: false,
                sending: false,
            };
        },
        computed:{
            ...mapState('projects', ['project']),
        },

        methods: {
            openDialog() {
                this.dialog = true;
            },
            closeDialog() {
                this.dialog = false;
            },
            send(){
                this.sending = true;
                toastMessage('Sending...');
                const url = populateUrl(EMAIL_SEND_EXTRA, this.project._id, this.project.events[0]._id);
                return $http.post(url, {
                    group: this.selectedEmailGroup,
                }).then(()=>{
                    toastSuccess('Extra emails sent.');
                    this.closeDialog();
                }).finally(() => {
                    this.sending = false;
                });
            },
        }
    };
</script>

<style scoped>

</style>
