<template>
    <div class="preview-canvas">
        <v-row no-gutters
               class="tabs"
        >
            <v-col cols="12"
                   lg="7"
                   md="7"
                   sm="7"
                   class="pa-0 ma-0"
            >
                <v-tabs
                    v-model="tab"
                    class="pt-2"
                >
                    <v-tab :to="{ name: 'WaitingPagePreview' }"
                           class="subtitle-1 text-capitalize tab-title"
                           href="#waiting"
                    >
                        Waiting
                    </v-tab>
                    <v-tab :to="{ name: 'LivePagePreview' }"
                           class="subtitle-1 text-capitalize tab-title"
                           href="#live"
                    >
                        Live
                    </v-tab>
                    <v-tab :to="{ name: 'EndPagePreview' }"
                           class="subtitle-1 text-capitalize tab-title"
                           href="#end"
                    >
                        End
                    </v-tab>
                    <v-tab v-show="showOndemandPage"
                           :to="{ name: 'OnDemandPagePreview' }"
                           class="subtitle-1 text-capitalize tab-title"
                           href="#onDemand"
                    >
                        On demand
                    </v-tab>
                    <v-tab v-show="showEvaluationPage"
                           :to="{ name: 'EvaluationPagePreview' }"
                           class="subtitle-1 text-capitalize last-tab-title"
                           :class="{'tab-title': showOnSiteEvaluationPage}"
                           href="#evaluation"
                    >
                        Evaluation
                    </v-tab>
                    <v-tab v-show="showOnSiteEvaluationPage"
                           :to="{ name: 'OnSiteEvaluationPagePreview' }"
                           class="subtitle-1 text-capitalize last-tab-title"
                           href="#onSiteEvaluation"
                    >
                        On-site Evaluation
                    </v-tab>
                </v-tabs>
            </v-col>
            <v-col cols="12"
                   lg="3"
                   md="3"
                   sm="3"
                   class="pr-10"
            >
                <v-select v-if="showEventSelector"
                          v-model="selectedEvent"
                          :items="project.events"
                          :return-object="true"
                          item-text="name"
                />
            </v-col>
            <v-col cols="12"
                   lg="2"
                   md="2"
                   sm="2"
            >
                <v-row 
                    justify="end"
                    align="center"
                >
                    <v-col cols="12"
                           lg="4"
                           md="4"
                           sm="4"
                           class="pt-2"
                    >
                        <v-badge
                            :value="hoverMobile"
                            content="414x736"
                            left
                            offset-x="55"
                            transition="slide-x-transition"
                        >
                            <v-hover v-model="hoverMobile">
                                <v-btn 
                                    large
                                    icon
                                
                                    @click="mobileScreen"
                                >
                                    <v-icon>mdi-cellphone</v-icon>
                                </v-btn>
                            </v-hover>
                        </v-badge>
                    </v-col>
                   
                    <v-col cols="12"
                           lg="4"
                           md="4"
                           sm="4"
                           class="pt-2"
                    >
                        <v-badge
                            :value="hoverTablet"
                            content="768x1024"
                            left
                            offset-x="60"
                            transition="slide-x-transition"
                        >
                            <v-hover v-model="hoverTablet">
                                <v-btn
                                    large
                                    icon
                                 
                                    @click="tabletScreen"
                                >
                                    <v-icon large>
                                        mdi-tablet
                                    </v-icon>
                                </v-btn>
                            </v-hover>
                        </v-badge>
                    </v-col>
                    
                    <v-col cols="12"
                           lg="4"
                           md="4"
                           sm="4"
                           class="pt-2"
                    >
                        <v-badge
                            :value="hoverDesktop"
                            content="1920x1080"
                            left
                            offset-x="65"
                            transition="slide-x-transition"
                        >
                            <v-hover v-model="hoverDesktop">
                                <v-btn
                                    large
                                    icon
                               
                                    @click="desktopScreen"
                                >
                                    <v-icon large>
                                        mdi-monitor
                                    </v-icon>
                                </v-btn>
                            </v-hover>
                        </v-badge>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <frontend-preview 
            :full-screen="true"
            :actions="false"
            :page="page"
            :event-i-d="currentEventId"
            :mobile="mobile"
            :tablet="tablet"
            :desktop="desktop"
        />
    </div>
</template>

<script>
    import FrontendPreview from '@/components/pages/Project/Elements/FrontendPreview';
    import { mapGetters, mapState } from 'vuex';
    export default {
        name: 'PreviewEventPages',
        components: {FrontendPreview},
        data(){
            return {
                tab: 'waiting',
                recentTab: 'waiting',
                hoverMobile: false,
                hoverTablet: false,
                hoverDesktop: false,
                mobile: false,
                tablet: false,
                desktop: true,
                selectedEvent: null,
                pages: {
                    'waiting': 'splash',
                    'live': 'live',
                    'end': 'end',
                    'onDemand': 'on-demand',
                    'evaluation': 'evaluation',
                    'onSiteEvaluation': 'on-site-evaluation'
                }
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            ...mapGetters('projects', ['isMultiSession', 'isHybridProject']),
            showEventSelector(){
                return this.isMultiSession && 
                    (this.page === 'live'|| this.page === 'on-demand');
            },
            page(){
                return this.pages[this.recentTab];
            },
            currentEventId(){
                return this.selectedEvent._id;
            },
            showOndemandPage(){
                return this.project.events.find(e => e._id === this.currentEventId).onDemandID;
            },
            showEvaluationPage(){
                return !this.evaluationButtonOverwrite;
            },
            showOnSiteEvaluationPage(){
                return this.isHybridProject && !this.evaluationButtonOverwrite;
            },
            evaluationButtonOverwrite() {
                return this.project.evaluationButton.overwrite;
            },
        },
        methods:{
            mobileScreen(){
                this.desktop = false;
                this.tablet = false;
                this.mobile = true;
            },
            tabletScreen(){
                this.desktop = false;
                this.mobile = false;
                this.tablet = true;
            },
            desktopScreen(){
                this.mobile = false;
                this.tablet = false;
                this.desktop = true;
            },
            navigateToLiveTabIfNoOndemandAvailable() {
                if(this.page === 'on-demand' && !this.showOndemandPage) {
                    this.tab = 'live';
                }
            }
        },
        watch:{
            selectedEvent() {
                this.navigateToLiveTabIfNoOndemandAvailable();
            },
            $route: {
                handler(to) {
                    if(to.path.indexOf('waiting') > -1) {
                        this.recentTab =  'waiting';
                    }
                    if(to.path.indexOf('live') > -1) {
                        this.recentTab =  'live';
                    }
                    if(to.path.indexOf('end') > -1) {
                        this.recentTab =  'end';
                    }
                    if(to.path.indexOf('evaluation') > -1) {
                        this.recentTab =  'evaluation';
                    }
                    if(to.path.indexOf('on-demand') > -1) {
                        this.recentTab =  'onDemand';
                    }
                    if(to.path.indexOf('on-site-evaluation') > -1) {
                        this.recentTab =  'onSiteEvaluation';
                    }
                },
                immediate: true,
            },
        },
        async created(){
            this.selectedEvent = this.project.events[0];
        }
    };
</script>

<style scoped>
.tabs {
    border-bottom: 12px solid;
    border-color: #f5f5f5;
    background-color: white;
    border-top: 4px white;
    height: 68px !important;

}
.tab-title {
    border-right: 2px solid;
    border-color: #f5f5f5;
    margin-bottom: 10px;
}

.last-tab-title{
     margin-bottom: 10px;

}
</style>