<template>
    <div>
        <h3 class="font-weight-bold mt-n4 pt-3">
            Branding settings
        </h3>
        <v-row>
            <v-col cols="12"
                   xl="6"
                   lg="6"
                   md="6"
                   sm="6"
                   class="input-col-left"
            >
                <project-logo-picker />
            </v-col>
            <v-col cols="12"
                   xl="6"
                   lg="6"
                   md="6"
                   sm="6"
                   class="input-col-right d-flex"
            >
                <v-divider vertical
                           class="mr-2"
                />
                <project-favicon-picker />
            </v-col>
            <v-col cols="12">
                <v-divider class="mb-2" />
                <background-picker />
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <primary-color-picker />
            </v-col>
        </v-row>
        <enforce-color />
    </div>
</template>

<script>
    import ProjectLogoPicker from '@/components/controls/ProjectLogoPicker.vue';
    import EnforceColor from '@/components/pages/Project/ProjectOverviewElements/EnforceColor';
    import ProjectFaviconPicker from '@/components/controls/ProjectFaviconPicker.vue';
    import BackgroundPicker from '@/components/controls/BackgroundPicker.vue';
    import PrimaryColorPicker from '@/components/pages/Project/ProjectOverviewElements/PrimaryColorPicker.vue';

    export default {
        name: 'StyleSettings',
        components: {
            PrimaryColorPicker,
            BackgroundPicker,
            ProjectFaviconPicker,
            ProjectLogoPicker,
            EnforceColor,
        },
    };
</script>
