import {
    COMPANY_TEMPLATE_ADD_URL,
    COMPANY_TEMPLATE_INDEX_URL,
    COMPANY_TEMPLATE_UPDATE_URL, GET_SELECTED_LAYOUT,
    WAITING_PAGE_COMPANY_TEMPLATE_ADD_URL,
    WAITING_PAGE_COMPANY_TEMPLATE_INDEX_URL,
    WAITING_PAGE_COMPANY_TEMPLATE_UPDATE_URL, WAITING_PAGE_GET_SELECTED_LAYOUT
} from '@/services/ApiService';

export const componentTypes = [
    {
        text: 'Header block',
        type: 'header-block',
        defaultComponentOptions: {},
    },
    {
        text: 'Column',
        type: 'column',
        defaultComponentOptions: {
            blocks: []
        },
    },
    {
        text: 'Spacer',
        type: 'spacer',
        defaultComponentOptions: {},
    },
    {
        text: 'CountDown',
        type: 'count-down-block',
        defaultComponentOptions: {},
    },
    {
        text: 'Image',
        type: 'image-content-block',
        defaultComponentOptions: {
            alignment: 'image-right',
            content: 'Text near image',
            image: {
                url: null,
                path: null,
                height: 0,
                width: 0,//unused yet
            },
            ctaButton:{
                enableCtaButton: false,
                ctaButtonText: '',
                ctaButtonUrl: '',
                alignment: 'flex-start'
            }
        },
    },
    {
        text: 'Slideshow',
        type: 'slideshow',
        defaultComponentOptions: {
            images: [],
        },
    },
    {
        text: 'Speakers',
        type: 'speakers',
        defaultComponentOptions: {},
    },
    {
        text: 'ProgramElement',
        type: 'program-element',
        defaultComponentOptions: {},
    },  
    {
        text: 'Text',
        type: 'text-content-block',
        defaultComponentOptions: {
            content: 'Specify content...',
            ctaButton:{
                enableCtaButton: false,
                ctaButtonText: '',
                ctaButtonUrl: '',
                alignment: 'flex-start'
            }
        },
    },
    {
        text: 'Video',
        type: 'video-content-block',
        defaultComponentOptions: {
            onDemandId: '',
            content: 'Text near video',
            alignment: 'player-bottom',
        },
    },
    {
        text: 'Upcoming Events',
        type: 'upcoming-events',
        defaultComponentOptions: {
            content: 'Upcoming events',
            upcomingEvents: [],
        }
    }
];

export const extraLiveDirectorConfig = (state) => {
    return btoa(
        JSON.stringify({
            extraTransmitterConnectionParams: {
                cockpit: 1,
            },
            fixedState: {
                'controller-switch': {
                    state: {
                        state,
                    },
                },
            },
        })
    );
};

const dynamicBlockKinds = {
    'registration': {
        'key': 'dynamicBlocks',
        'setterKey': 'setDynamicBlocksLayout',
        'presetUrl': '/dynamic-blocks/presets/',
        'previewUrl': process.env.VUE_APP_FRONTEND_REGISTRATION_PAGE_URL
            + '?previewMode=default-registration&',
        'dynamicPreviewUrl': process.env.VUE_APP_FRONTEND_DYNAMIC_BLOCKS_PAGE_URL
            + '?previewMode=',
        'templateAddUrl': COMPANY_TEMPLATE_ADD_URL,
        'templateIndexUrl': COMPANY_TEMPLATE_INDEX_URL,
        'templateUpdateUrl': COMPANY_TEMPLATE_UPDATE_URL,
        'layoutGetter': 'dynamicBlocksLayout',
        'layoutSetterKey': 'setDynamicBlocks',
        'saveBlocksUrl': '/projects/{project_id}/layouts/',
        'loadBlocksUrl': '/projects/{project_id}/layouts/',
        'selectedLayoutUrl': GET_SELECTED_LAYOUT,
        'editorLayoutLabels': {
            'default': 'Dynamic layout',
            'basic': 'Basic layout (default)',
        },
    },
    'waitingPage': {
        'key': 'waitingPageDynamicBlocks',
        'setterKey': 'setWaitingPageDynamicBlocksLayout',
        'presetUrl': '/dynamic-blocks/waiting-page-presets/',
        'previewUrl': process.env.VUE_APP_FRONTEND_EVENT_PAGE_URL
            + '?extraLiveDrectorConfig='
            + extraLiveDirectorConfig('pre')
            + '&previewMode=default-waiting-page&',
        'dynamicPreviewUrl': process.env.VUE_APP_FRONTEND_EVENT_PAGE_URL
            + '?extraLiveDrectorConfig='
            + extraLiveDirectorConfig('pre')
            + '&previewMode=dynamic-waiting-page&',
        'templateAddUrl': WAITING_PAGE_COMPANY_TEMPLATE_ADD_URL,
        'templateIndexUrl': WAITING_PAGE_COMPANY_TEMPLATE_INDEX_URL,
        'templateUpdateUrl': WAITING_PAGE_COMPANY_TEMPLATE_UPDATE_URL,
        'layoutGetter': 'waitingPageDynamicBlocksLayout',
        'layoutSetterKey': 'setWaitingPageDynamicBlocks',
        'saveBlocksUrl': '/projects/{project_id}/waiting-page-layouts/',
        'loadBlocksUrl': '/projects/{project_id}/waiting-page-layouts/',
        'selectedLayoutUrl': WAITING_PAGE_GET_SELECTED_LAYOUT,
        'editorLayoutLabels': {
            'default': 'Dynamic layout',
            'basic': 'Basic layout',
        },
    },
};

export const getDynamicBlockKind = (dynamicBlockKind) => {
    return dynamicBlockKinds[dynamicBlockKind];
};

export const componentTypeNames = (function () {
    var map = {};
    for (var i in componentTypes) {
        map[componentTypes[i].type] = componentTypes[i].text;
    }
    return map;
})();


