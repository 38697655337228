<template>
    <div>
        <generic-block-options :block="block"
                               :hide-gradient="true"
                               :hide-text-color="true"
                               :save-url="saveUrl"
                               :has-tab-titles="hasTabTitles"
        />
        <label>Edit slideshow images:</label>
        <slideshow-image-picker v-model="slideshow" />
    </div>
</template>

<script>
    import GenericBlockOptions from './GenericBlockOptions';
    import SlideshowImagePicker from '@/components/controls/SlideshowImagePicker';
    import { mapActions } from 'vuex';
    export default {
        name: 'SlideShow',
        components: {
            GenericBlockOptions,
            SlideshowImagePicker
        },
        props: {
            block: {
                type: Object,
                required: true
            },
            saveUrl: {
                type: String,
                required: true,
            },
            hasTabTitles: {
                type: Boolean,
                default: () => true,
            },
        },
        computed:{
            slideshow:{
                get(){
                    return this.block.componentOptions.images;
                },
                set(value){
                    this.block.componentOptions.images = value;
                    this.saveBlocks(this.saveUrl);
                }
            }
        },
        methods: {
            ...mapActions('dynamic-block', ['saveBlocks'])
        }
    };
</script>
