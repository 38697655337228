var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"px-8"},[_c('registration-list-header'),(!_vm.loading)?_c('div',_vm._l((_vm.items),function(item){return _c('v-card',{key:("" + (_vm.items.indexOf(item))),staticClass:"mt-1"},[_c('v-card-text',{staticClass:"px-0"},[_c('v-row',{staticClass:"mx-0"},[(_vm.canShowComponent)?_c('v-col',{staticClass:"vertical-center-text",attrs:{"cols":"12","lg":"1","md":"1","sm":"1"}},[_vm._v(" "+_vm._s(item.name)+" ")]):_vm._e(),(_vm.canShowComponent)?_c('v-col',{staticClass:"vertical-center-text",attrs:{"cols":"12","lg":"2","md":"2","sm":"2"}},[_vm._v(" "+_vm._s(item.email)+" ")]):_vm._e(),_c('v-col',{staticClass:"vertical-center-text",staticStyle:{"display":"block"},attrs:{"cols":"12","lg":"2","md":"2","sm":"2"}},[(item.registrationTimestamp)?_c('span',[_vm._v(" "+_vm._s(item.registrationTimestamp.split(" ")[0])+" "),_c('br'),_vm._v(" "+_vm._s(((item.registrationTimestamp.split(" ")[1]) + " " + (item.registrationTimestamp.split(" ")[2]))))]):_vm._e(),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(item.onSiteViewer)?_c('v-icon',_vm._g({staticClass:"ml-2 on-site-marker"},on),[_vm._v(" mdi-map-marker-check ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("On-site registration")])])],1),_c('v-col',{staticClass:"vertical-center-text",attrs:{"cols":"12","lg":"2","md":"2","sm":"2"}},[_vm._v(" "+_vm._s(item.eventName)+" ")]),_c('v-col',{staticClass:"vertical-center-text",staticStyle:{"justify-content":"center"},attrs:{"cols":"12","lg":_vm.canShowComponent ? '1':'2',"md":_vm.canShowComponent ? '1':'2',"sm":_vm.canShowComponent ? '1':'2'}},[(item.attended)?_c('v-icon',{attrs:{"color":"green"}},[_vm._v(" mdi-check ")]):_c('v-icon',{attrs:{"color":"red"}},[_vm._v(" mdi-close ")])],1),_c('v-col',{staticClass:"vertical-center-text",staticStyle:{"display":"block"},attrs:{"cols":"12","lg":_vm.canShowComponent ? '1':'2',"md":_vm.canShowComponent ? '1':'2',"sm":_vm.canShowComponent ? '1':'2'}},[(item.joinedTime)?_c('span',[_vm._v(" "+_vm._s(item.joinedTime.split(" ")[0])+" "),_c('br'),_vm._v(" "+_vm._s(((item.joinedTime.split(" ")[1]) + " " + (item.joinedTime.split(" ")[2]))))]):_vm._e()]),_c('v-col',{staticClass:"vertical-center-text",staticStyle:{"display":"block"},attrs:{"cols":"12","lg":_vm.canShowComponent ? '1':'2',"md":_vm.canShowComponent ? '1':'2',"sm":_vm.canShowComponent ? '1':'2'}},[(item.exitTime)?_c('span',[_vm._v(" "+_vm._s(item.exitTime.split(" ")[0])+" "),_c('br'),_vm._v(" "+_vm._s(((item.exitTime.split(" ")[1]) + " " + (item.exitTime.split(" ")[2]))))]):_vm._e()]),_c('v-col',{staticClass:"vertical-center-text",staticStyle:{"justify-content":"center"},attrs:{"cols":"12","lg":"2","md":"2","sm":"2"}},[_vm._v(" "+_vm._s(item.attendanceInPercent + "%")+" "),(_vm.isQuadiaLiveAdmin)?_c('div',{staticClass:"action-container"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","small":"","disabled":_vm.saving || _vm.deleting || _vm.started,"loading":_vm.isSaving(item)},on:{"click":function($event){return _vm.editRegistrant(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit e-mail address")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"icon":"","small":"","color":"error","disabled":_vm.saving || _vm.deleting || _vm.started,"loading":_vm.isDeleting(item)},on:{"click":function($event){return _vm.deleteRegistrantQuery(item)}}},on),[_c('v-icon',{attrs:{"small":""}},[_vm._v(" delete ")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete registrant")])])],1):_vm._e()])],1)],1)],1)}),1):_c('v-progress-linear',{attrs:{"indeterminate":""}}),_c('confirm-modal',{ref:"deleteDialog",attrs:{"value":"false"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" This will remove the selected registration ")]},proxy:true},{key:"content",fn:function(){return [_c('p',[_vm._v("The following registrant will be deleted: ")]),_c('p',[_c('strong',[_vm._v(_vm._s(_vm.registrantEmail))])]),_c('p',[_vm._v("This action cannot be undone. The registrant will not be able to view the event.")]),_c('p',[_vm._v("Are you sure?")])]},proxy:true}])}),_c('confirm-modal',{ref:"editModal",attrs:{"value":"false"},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" Edit registrant ")]},proxy:true},{key:"content",fn:function(ref){
var toggleConfirm = ref.toggleConfirm;
return [_c('v-text-field',{ref:"emailEditor",attrs:{"type":"email","rules":_vm.editedEmailRules},on:{"input":function () { return _vm.validate(toggleConfirm); }},model:{value:(_vm.editedEmail),callback:function ($$v) {_vm.editedEmail=$$v},expression:"editedEmail"}})]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }