<template>
    <div v-click-outside="cancel"
         class="absolute-menu-parent"
         :class="{'absolute-menu-parent-small': useIconButton}"
    >
        <div>
            <v-tooltip :bottom="!useIconButton"
                       :right="useIconButton"
            >
                <template v-slot:activator="{on}">
                    <v-btn ref="button"
                           :block="!useIconButton"
                           :text="!useIconButton"
                           :fab="useIconButton"
                           :x-small="useIconButton"
                           :disabled="project.isArchived"
                           v-on="on"
                           @click.stop="toggleOpen"
                    >
                        <v-icon>
                            mdi-plus
                        </v-icon>
                        <span v-if="!useIconButton">Add new block</span>
                    </v-btn>
                </template>
                <span><slot name="tooltip">Add new block to the top level</slot></span>
            </v-tooltip>
        </div>
        <v-divider v-if="!useIconButton"
                   class="my-0"
        />
        <v-card class="absolute-menu"
                :class="{'open': open}"
                :style="style"
                elevation="10"
        >
            <v-list dense
                    class="pt-0"
            >
                <v-subheader class="slim-subheader px-2">
                    Select component
                </v-subheader>
                <v-list-item-group color="primary">
                    <v-list-item
                        v-for="(item, i) in componentTypes"
                        :key="`component-type-${i}`"
                        class="slim"
                        @click="add(item)"
                    >
                        <v-list-item-content class="matte">
                            <v-list-item-title v-text="item.text" />
                        </v-list-item-content>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-card>
    </div>
</template>

<script>
    import { v4 as uuidv4 } from 'uuid';
    import { mapActions, mapMutations, mapState } from 'vuex';
    import { componentTypes } from '@/components/pages/Project/EventPage/blocks-helper.js';

    export default {
        name: 'BlockCreator',
        props: {
            block: {
                type: Object,
                default: () => null
            },
            useIconButton: {
                type: Boolean,
                default: false
            },
            saveUrl:{
                type: String,
                required: true,
            },
            dynamicBlockKind: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                open: false,
                componentTypes: componentTypes,
                style: {}
            };
        },
        computed:{
            ...mapState('projects', ['project'])
        },
        methods: {
            ...mapMutations('dynamic-block', ['addNewBlock', 'addBlockToColumn']),
            ...mapActions('dynamic-block', ['saveBlocks']),
            cancel() {
                this.open = false;
                this.componentType = null;
            },
            toggleOpen() {
                this.reposition();
                this.open = !this.open;
            },
            reposition() {
                const el = this.$refs.button.$el;
                const rect = el.getBoundingClientRect();
                const viewportHeight = window.innerHeight;
                const diff = viewportHeight - rect.bottom;
                if (diff < 250) {
                    this.style = {
                        top: 'initial',
                        bottom: `${250-diff}px`,
                    };
                }
            },
            add(selectedType) {
                const block = {
                    _id: uuidv4(),
                    width: 'full',
                    backgroundImage: {
                        url:'',
                        path: '',
                    },
                    backgroundColor: 'rgba(255, 255, 255, 1);',
                    componentType: selectedType.type,
                    componentOptions: this.deepClone(selectedType.defaultComponentOptions),
                };
                if (this.dynamicBlockKind === 'waitingPage' && block.componentType === 'header-block') {
                    block.componentOptions = {...block.componentOptions, content: this.project.splashPageContent};
                }
                if (this.block) {
                    this.addBlockToColumn({
                        columnBlock: this.block,
                        block
                    });
                } else {
                    this.addNewBlock(block);
                }
                this.cancel();
                this.saveBlocks(this.saveUrl);
            },
            deepClone(obj) {
                return JSON.parse(JSON.stringify(obj));
            },
        },
    };
</script>

<style lang="scss">
    .slim {
        min-height: 0 !important;
    }

    .matte {
        color: #3e3e3e;
    }

    .slim-subheader {
        height: auto !important;
        padding: 8px 0 !important;
        border-top: 1px solid #e3e3e3;
    }

    .absolute-menu-parent-small {
        width: 33px;
    }

    .absolute-menu-parent {
        position: relative;
        max-height: 35px;
    }

    .absolute-menu {
        top: 0;
        overflow-y: auto;
        position: absolute;
        z-index: 8;
        display: none !important;
        min-width: 200px;
        max-height: 230px;
        &.open {
            display: block !important;
        }
    }
</style>