<template>
    <div>
        <h3 class="mb-2 font-weight-bold">
            Security settings
        </h3>
        <v-divider class="mb-4" />
        <v-row v-if="registrationType !== 'open'"
               no-gutters
               class="pl-4 mb-4"
        >
            <v-col class="pb-0">
                <div class="d-inline-flex">
                    <div class="d-inline-block mr-4 mt-1 security-toggle-label">
                        Single session
                    </div>
                    <div v-if="!loadingProject"
                         class="d-flex align-center"
                    >
                        <v-switch 
                            v-model="singleSession"
                            hide-details
                            class="mt-0"
                            inset
                            :disabled="project.isArchived"
                        />
                        <v-tooltip top>
                            <template v-slot:activator="{on}">
                                <v-icon v-on="on">
                                    info
                                </v-icon>
                            </template>
                            <span>This will limit the same viewing link to be opened<br> only in one browser window at a time.</span>
                        </v-tooltip>
                    </div>
                    <v-progress-circular v-else
                                         indeterminate
                    />
                </div>
            </v-col>
        </v-row>
        <v-row 
            class="pl-4"
            no-gutters
        > 
            <v-col md="5"
                   sm="12"
                   class="pr-0 pb-0 mt-n3 d-inline-flex"
                   style="min-height: 86px"
            >
                <div class="mt-1 mr-4 d-flex align-center security-toggle-label">
                    Password live page
                </div>
                <div class="d-flex align-center">
                    <v-switch
                        :key="projectPasswordActiveKey"
                        v-model="projectPasswordActive"
                        :input-value="projectPasswordActive"
                        :disabled="project.isArchived"
                        inset
                        class="mt-0"
                        hide-details
                    />                  
                    <v-tooltip top>
                        <template v-slot:activator="{on}">
                            <v-icon v-on="on">
                                info
                            </v-icon>
                        </template>
                        <span>Turning on this option will protect the live page with a password.<br>It’s advisable to turn off the registrations.</span>
                    </v-tooltip>
                </div>
            </v-col>
            <v-col md="5"
                   sm="12"
                   class="pl-0 pr-5 pb-0 d-flex align-center mt-n3" 
            >
                <v-text-field
                    v-show="projectPasswordActive"
                    v-model="passwordLivePage"
                    filled
                    class="text-input password-input"
                    :rules="emptyPassword"
                    label="Password"
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    :disabled="!editPassword"
                    @click:append="showPassword = !showPassword"
                    @keydown="checkCapsLock"
                >
                    <template v-if="hasCapsLock && !hasMsCapsLockWarning"
                              v-slot:append-outer
                    >
                        <small class="error--text">CAPS LOCK<br> ON</small>
                    </template>
                </v-text-field>
            </v-col>
            <v-col md="2"
                   sm="12"
                   class="pl-0 pr-0 pb-0 mt-n3"
            >
                <v-btn
                    v-show="projectPasswordActive"
                    v-if="!editPassword"
                    color="primary"
                    outlined
                    class="mt-2 pl-3 pr-3"
                    :disabled="project.isArchived"
                    @click="editPassword = true"
                >
                    <v-icon class="mr-1">
                        mdi-pencil
                    </v-icon>
                    EDIT
                </v-btn>
                <v-btn
                    v-else
                    v-show="projectPasswordActive"
                    color="primary"
                    class="mt-2 pl-3 pr-3"
                    @click="savePassword"
                >
                    <v-icon class="mr-1">
                        mdi-content-save
                    </v-icon>
                    SAVE
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapActions,mapMutations, mapState } from 'vuex';

    export default {
        name: 'SecuritySettings',
        components: { },
        data() {
            return {
                passwordLivePage: '',
                projectPasswordActiveKey: 0,
                activateProjectPasswordLivePage: false,
                showPassword: false,
                editPassword: false,
                emptyPassword: [ v => !!v || 'Password required if protection is activated.'],
                hasCapsLock: false,
            };
        },
        computed: {
            ...mapState('projects', ['project', 'loadingProject']),
            projectPasswordActive: {
                get() {
                    return this.project.security.passwordLivePageActive;
                },
                set(value) {
                    if(value === true && !this.passwordLivePage) {
                        this.generateSecurePassword();
                    }
                    this.setProjectPasswordLivePageActive(value);
                    this.saveProjectImmediate({
                        key: 'security.passwordLivePageActive',
                        value,
                    });
                },
            },
            hasMsCapsLockWarning() {
                // IE 10 and 11 caps lock warning proprietary support check
                // Caps lock detection in IE is fishy, but since it has a caps lock warning anyway,
                // we degrade to it
                return typeof document.msCapsLockWarningOff !== 'undefined';
            },
            registrationType() {
                return this.project.registration.type;
            },
            singleSession: {
                get() {
                    return this.project.registration.singleSession;
                },
                set(value) {
                    this.setSingleSession(value);
                    this.saveProject({
                        key: 'registration.singleSession',
                        value
                    });
                }
            },
        },
        watch:{
            'project.security.passwordLivePage': {
                immediate:true,
                handler(value){
                    this.passwordLivePage = value;
                }
            }
        },
        methods: {
            ...mapMutations('projects', [
                'setProjectPasswordLivePage',
                'setProjectPasswordLivePageActive',
                'setSingleSession'
            ]),
            ...mapActions('projects', ['saveProject', 'saveProjectImmediate']),
            generateSecurePassword() {
                this.passwordLivePage = [...Array(9)].map(() => Math.random().toString(36)[2]).join('');
                this.showPassword = true;
                this.setProjectPasswordLivePage(this.passwordLivePage);
                this.saveProject({
                    key: 'security.passwordLivePage',
                    value: this.passwordLivePage,
                });
            },
            savePassword(){
                if( this.passwordLivePage) {
                    this.showPassword = false;
                    this.editPassword = false;
                    this.setProjectPasswordLivePage(this.passwordLivePage);
                    this.saveProjectImmediate({
                        key: 'security.passwordLivePage',
                        value: this.passwordLivePage,
                    });
                }
            },
            checkCapsLock(event) {
                if (typeof event.getModifierState === 'function') {
                    const capsLockState = event.getModifierState('CapsLock');
                    this.hasCapsLock = event.keyCode === 20 ? !capsLockState : capsLockState;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
.password-input {
  ::v-deep .v-input__append-outer {
    margin-top: 10px !important;
  }
}

.security-toggle-label {
    width: 140px;
}
</style>
