<template>
    <v-dialog :value="true"
              :max-width="700"
    >
        <v-card>
            <v-card-title>
                Remove project assignment
                <v-spacer />
                <v-btn icon
                       small
                       @click="cancel"
                >
                    <v-icon>close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text>
                <p>User <b>{{ client.email }}</b> will lose access to <b>{{ project.projectName }}</b>.</p>
                <p>
                    Do you wish to continue?
                </p>
            </v-card-text>
            <v-divider />
            <v-card-actions>
                <v-spacer />
                <v-btn color="primary"
                       :disabled="saving"
                       @click="unassign"
                >
                    Yes, remove assignment
                </v-btn>
                <v-btn :disabled="saving"
                       @click="cancel"
                >
                    No
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapActions } from 'vuex';

    export default {
        name: 'ConfirmUnassignDialog',
        props: ['client', 'project'],
        data() {
            return {
                saving: false,
            };
        },
        methods: {
            ...mapActions('clients', ['unassignClientFromProject', 'getClients']),
            cancel() {
                this.$emit('close');
            },
            async unassign() {
                this.saving = true;
                await this.unassignClientFromProject({
                    userId: this.client.id,
                    projectId: this.project.projectId,
                });
                await this.getClients();
                this.$emit('close');
            },
        },
    };
</script>
