<template>
    <v-card class="px-4 font-weight-medium list-header"
            elevation="0"
    >
        <v-row>
            <v-col :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'"
                   class="pt-4"
            >
                <user-list-header-item id="email"
                                       name="User"
                />
            </v-col>
            <v-col v-if="isQuadiaLiveAdmin"
                   :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'"
                   class="pt-4"
            >
                <user-list-header-item id="production_company"
                                       name="Production company"
                />
            </v-col>
            <v-col :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'"
                   class="pt-4"
            >
                <user-list-header-item id="role"
                                       name="Role"
                />
            </v-col>
            <v-col :class="isQuadiaLiveAdmin ? 'md-3' : 'md-4'">
                <div class="header-item text-right">
                    <div class="py-1">
                        Actions
                    </div>
                </div>
            </v-col>
        </v-row>
    </v-card>
</template>

<script>
    import UserListHeaderItem from '@/components/pages/Users/UserListHeaderItem';
    import { mapGetters } from 'vuex';

    export default {
        name: 'UserListHeader',
        components: {UserListHeaderItem},
        computed: {
            ...mapGetters('auth', ['isQuadiaLiveAdmin']),
        }
    };
</script>
