<template>
    <v-dialog
        v-model="value"
        persistent
        max-width="600"
    >
        <v-card>
            <v-card-title class="pb-0">
                Add breakout to group
                <v-spacer />
                <v-btn icon
                       @click="closeModal"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <br>
            <v-card-text>
                <v-form ref="form"
                        @submit.prevent="save"
                >
                    <v-text-field
                        v-model="name"
                        filled
                        class="text-input"
                        label="Breakout title"
                        :rules="requiredField"
                        hide-details="auto"
                    />
                    <div class="d-flex align-center  mt-3">
                        <span class="mr-10 pt-3">Max number of registrations</span>
                        <v-text-field
                            v-model="registrationsCountLimit"
                            class="number-input"
                            type="number"
                            min="0"
                            max="300"
                            :rules="[v => v <= 300 || 'Max 300 registrants']"
                        />
                    </div>
                </v-form>
            </v-card-text>
            <v-divider />
            <v-card-actions class="px-6 pb-4">
                <v-spacer />
                <v-btn color="primary"
                       :disabled="submitDisabled"
                       :loading="submitDisabled"
                       @click="addBreakoutToGroupCreateProject"
                >
                    Add
                </v-btn>
                <v-btn
                    @click="closeModal"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapState } from 'vuex';    
    import {trim} from 'lodash';


    export default {
        name:'AddToBreakoutGroup',
        props:{
            value: {
                type: Boolean,
                required: true
            },
        },
        data(){
            return {
                name: '',
                submitDisabled: false,
                registrationsCountLimit: 0
            };
        },
        computed: {
            ...mapState('projects', ['project']),
            requiredField(){
                return [(v) => !!trim(v) || 'Field is required'];
            },
        },
        methods:{
            addBreakoutToGroupCreateProject(){
                if(this.$refs.form.validate()){
                    const breakout = {
                        name: this.name,
                        registrationsCountLimit: this.registrationsCountLimit
                    };
                    this.$emit('confirm', breakout);
                    this.closeModal();              
                }
            },
            closeModal(){
                this.$emit('input', false);
            },
        },     
    };
</script>
<style scoped>
.number-input{
    max-width: 100px;
}
</style>