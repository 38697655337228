<template>
    <div class="px-8 item-list">
        <archive-project-list-header />
        <template v-for="(projectEvent) in projectEvents.list">
            <v-card :key="`project-list-${projectEvent.event._id}`"
                    class="mt-1 list-item"
            >
                <v-row class="px-4">
                    <v-col md="1"
                           class="logo-container"
                    >
                        <img v-if="projectEvent.company"
                             class="logo"
                             :src="projectEvent.company.logoUrl"
                             :alt="projectEvent.company.name"
                        >
                    </v-col>
                    <v-col md="1"
                           :class="{'col-md-3': isClientOnly}"
                    >
                        {{ projectEvent.company ? projectEvent.company.name : '' }}
                    </v-col>
                    <list-project-link v-if="!isClientOnly"
                                       :project-event="projectEvent"
                    />
                    <list-event-link :project-event="projectEvent"
                                     :is-client-only="isClientOnly"
                    />
                    <v-col md="2"
                           :class="{'col-md-3': isClientOnly}"
                    >
                        {{ getStartTime(projectEvent) }}
                    </v-col>
                    <v-col
                        v-if="!isClientOnly"
                        md="2"
                    >
                        <span>{{ projectEvent.createdBy ? projectEvent.createdBy.name : '' }}</span>
                    </v-col>
                    <v-col md="1"
                           class="align-center"
                    >
                        {{ projectEvent.registrationsCountText }}
                    </v-col>
                    <v-col md="1">
                        <div class="text-right">
                            <v-tooltip v-if="isClientAtMostForProject(projectEvent)"
                                       bottom
                            >
                                <template v-slot:activator="{on}">
                                    <v-btn icon
                                           color="black"
                                           small
                                           :to="{name: 'CreateStatistics', params: {'project_id': projectEvent._id}}"
                                           v-on="on"
                                    >
                                        <v-icon>query_stats</v-icon>
                                    </v-btn>
                                </template>
                                <span>Statistics</span>
                            </v-tooltip>
                            <v-tooltip v-if="canEditProject(projectEvent)"
                                       bottom
                            >
                                <template v-slot:activator="{on}">
                                    <v-btn icon
                                           color="black"
                                           small
                                           :to="{name: 'CreateEvent',
                                                 params:{ 'project_id': projectEvent._id, 'event_id': projectEvent.event._id}}"
                                           class="mr-2"
                                           v-on="on"
                                    >
                                        <v-icon>mdi-eye</v-icon>
                                    </v-btn>
                                </template>
                                <span>View</span>
                            </v-tooltip>
                        </div>
                    </v-col>
                </v-row>
            </v-card>
        </template>
    </div>
</template>

<script>
    import ArchiveProjectListHeader from '@/components/pages/Archives/ArchiveProjectListHeader';
    import moment from 'moment-timezone';
    import { mapGetters } from 'vuex';
    import TimeService from '@/services/TimeService';
    import ListProjectLink from '@/components/pages/Projects/Columns/ListProjectLink';
    import ListEventLink from '@/components/pages/Projects/Columns/ListEventLink';

    export default {
        name: 'ArchiveProjectList',
        components: {
            ListEventLink,
            ListProjectLink, 
            ArchiveProjectListHeader,
        },
        props: {
            projectEvents: {
                type: Object,
                default: () => {
                    return {
                        'list': [],
                        'firstPassedId': null,
                        'firstUpcomingId': null
                    };
                }
            }
        },
        data(){
            return{
                projectToArchive: {},
                archiveModal:false,
            };
        },
        computed: {
            ...mapGetters('auth', ['isClientAtMostForProject', 'canEditProject', 'isClientOnly'])
        },
        methods: {
            getStartTime(projectEvent) {
                return moment(projectEvent.startTime, 'X')
                    .tz(TimeService.defaultTimezone)
                    .format('DD/MM/YYYY HH:mm');
            },
            initArchive(project){
                this.projectToArchive = project;
                this.archiveModal = true;
            },
            cancelArchive(){
                this.projectToArchive = {};
                this.archiveModal = false;
            },
            archive(){
                //TODO archive project
            }
        }
    };
</script>

<style scoped>
.logo-container{
  padding-bottom: 0;
  padding-top: 0;
}
.logo{
  max-height: 50px;
  width: 100%;
  object-fit: scale-down;
}
.passed-line-marker{
  border-top: 1px solid lightgray;
  margin-top: 20px;
  margin-bottom: 10px;
  padding-top: 5px;
}
.passed-line-marker.no-divider-line{
  border-top: 0;
  margin-top: 0;
}
</style>
