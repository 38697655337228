<template>
    <v-app-bar app
               color="white"
               light
               clipped-left
    >
        <router-link
            class="text-decoration-none "
            :to="{ name: 'Home' }"
        >
            <v-toolbar-title style="display: flex"
                             class="ml-0 pl-lg-10 mr-8"
            >
                <v-img src="/images/logo-quadia-horizontal-w145.png"
                       class="lighten-4"
                       max-width="145"
                       max-height="100"
                />
                <div class="ml-2 title-text primary--text">
                    Live
                </div>
            </v-toolbar-title>
        </router-link>
        <v-spacer />
        <v-sheet class="d-flex">
            <user-menu />
        </v-sheet>
    </v-app-bar>
</template>

<script>
    import UserMenu from '@/components/controls/app-bar/UserMenu';

    export default {
        name: 'AppBar',
        components: {UserMenu}
    };
</script>

<style scoped>
.title-text {
    font-size: 1.2em;
}

</style>