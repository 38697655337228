<template>
    <div class="custom-cta-button">
        <v-switch v-model="enabled"
                  :input-value="enabled"
                  inset
                  label="Show Custom Call to Action button"
                  :disabled="project.isArchived"
                  :messages="enabled ? ['This button shows up on the end and on-demand pages. If you want to show this button on the live page too, turn on the custom-cta switch controller in Live Director']: []"
        />
        <div v-if="enabled">
            <v-text-field v-model="title"
                          label="Custom CTA title"
                          filled
                          :disabled="project.isArchived"
                          class="text-input mt-8"
            />
            <v-text-field v-model="href"
                          label="Custom CTA url"
                          filled
                          :disabled="project.isArchived"
                          class="text-input mt-4"
            />
        </div>
    </div>
</template>
<script>
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'CustomCtaButton',
        computed: {
            ...mapState('projects', ['project']),
            enabled: {
                get() {
                    return this.project.customCtaButton.enabled;
                },
                set(value) {
                    this.setProjectCustomCtaEnabled(value);
                    this.saveProjectImmediate({
                        key: 'customCtaButton.enabled',
                        value
                    });
                }
            },
            title: {
                get() {
                    return this.project.customCtaButton.title;
                },
                set(value) {
                    this.setProjectCustomCtaTitle(value);
                    this.saveProject({
                        key: 'customCtaButton.title',
                        value
                    });
                }
            },
            href: {
                get() {
                    return this.project.customCtaButton.href;
                },
                set(value) {
                    this.setProjectCustomCtaHref(value);
                    this.saveProject({
                        key: 'customCtaButton.href',
                        value
                    });
                }
            },
        },
        methods: {
            ...mapActions('projects', ['saveProject', 'saveProjectImmediate']),
            ...mapMutations('projects', ['setProjectCustomCtaEnabled', 'setProjectCustomCtaTitle', 'setProjectCustomCtaHref']),
        }
    };
</script>

<style scoped>

</style>