<template>
    <v-select
        v-model="internalValue"
        :items="availableProjectTypes"
        label="Project Type"
        item-text="label"
        item-value="value"
        hide-details
        :disabled="disabled"
    />
</template>

<script>
    import { mapGetters } from 'vuex';
    export default {
        name: 'ProjectTypeSelector',
        props: {
            value: {
                type: String,
                default: '',
            },
            disabled: {
                type: Boolean,
                default: () => false
            }
        },
        data() {
            return {
                projectTypes: [
                    {'label': 'Single event', 'value': 'singleSession'},
                    {'label': 'Multi session', 'value': 'multiSession'},
                    {'label': 'Hybrid event', 'value': 'hybrid'},
                ],
                externalEmbed: [{'label': 'External embed', 'value': 'externalEmbed'}],
                internalValue: this.value,
            };
        },
        computed:{
            ...mapGetters('auth', [
                'canCreateOnlyExternalEmbedProject',
            ]),
            availableProjectTypes(){
                return this.canCreateOnlyExternalEmbedProject ? 
                    this.externalEmbed : [...this.projectTypes, ...this.externalEmbed];
            }
        },
        watch: {
            internalValue(value) {
                this.$emit('input', value);
            }
        },
        mounted(){
            if(!this.value){
                this.internalValue = this.canCreateOnlyExternalEmbedProject ? 'externalEmbed' : 'singleSession';
            }
        }
    };
</script>
