<template>
    <v-list flat>
        <v-list-item-group color="primary"
                           :value="value._id"
        >
            <v-list-item v-for="(event, i) in project.events"
                         :key="i"
                         :value="event._id"
                         @click="select(event)"
            >
                <v-list-item-content>
                    <v-list-item-title class="list-title"
                                       v-text="event.name"
                    />
                </v-list-item-content>
            </v-list-item>
        </v-list-item-group>
    </v-list>
</template>

<script>
    import { mapState } from 'vuex';
    export default {
        name: 'EventSelector',
        props: {
            value: {
                type: Object,
                required: true
            },
        },
        methods: {
            select(event) {
                this.$emit('input', event);
            },
        },
        computed: {
            ...mapState('projects', ['project']),
        },
    };
</script>

<style scoped>
.list-title{
    white-space: normal !important;
}
</style>