<template>
    <v-dialog
        v-model="show"
        max-width="800"
        style="overflow:hidden"
    >
        <v-card>
            <v-card-title>
                <v-spacer />
                <v-btn icon
                       @click="closeModal"
                >
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-card-title>
            <v-card-text :style="{'overflow':'auto', 'max-height': '75vh'}">
                <v-progress-linear v-if="loading"
                                   indeterminate
                />
                <div v-else
                     class="iframe-wrapper"
                     :style="{height: height + 'px'}"
                >
                    <iframe :key="html"
                            :srcdoc="html"
                            frameborder="0"
                            @load="iframeLoaded"
                    />
                </div>
            </v-card-text>
            <v-card-actions class="mt-4">
                <v-spacer />
                <v-btn color="primary"
                       @click="closeModal()"
                >
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import { mapState } from 'vuex';
    import { $http } from '@/services/http';
    import {EMAIL_GET_SCHEDULED_EMAIL_PREVIEW,
            populateScheduledEmailsUrl
    } from '@/services/ApiService';

    export default {
        name:'ScheduleMailDialog',
        props:{
            show: {
                type: Boolean,
                default: () => false
            },
            email: {
                type: String,
                default: () => ''
            } 
        },
        data(){
            return {
                html:'',
                height: 0,
                loading :false
            };
        },
        computed: {
            ...mapState('projects', ['project']),
        },
        methods:{
            closeModal(){
                this.$emit('cancelModal');
            },
            iframeLoaded(e){
                this.height = e.target.contentWindow.document.documentElement.scrollHeight;
            },
        },
        watch:{
            email(value){
                if(value && this.show){
                    this.loading = true;
                    $http.get(populateScheduledEmailsUrl(
                        EMAIL_GET_SCHEDULED_EMAIL_PREVIEW,
                        this.$route.params.project_id, 
                        value))
                        .then((res)=>{
                            this.html = res.data.html;
                            this.loading = false;
                        });
                    
                }
            }
        }
    };
</script>
<style scoped>
iframe{
  margin-top: 1rem;
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 100%;
  border: none;
}
.iframe-wrapper{
  position: relative;
}
</style>
