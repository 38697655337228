import Field from '@/models/Field';

const Registration = class {
    constructor (data) {
        this.fields = data.fields.map(f => new Field(f));
        this.type = data.type || 'open';
        this.expectedViewers = data.expectedViewers || 0;
        this.introductionText = data.introductionText || '';
        this.confirmationText = data.confirmationText || '';
        this.singleSession = data.singleSession || false;
        this.onSiteConfirmationText = data.onSiteConfirmationText || '';
        this.showLinkedin = data.showLinkedin || false;
        this.attachRegistrationDataToAskAQuestion = data.attachRegistrationDataToAskAQuestion ?? false;
    }
};

export default Registration;
