<template>
    <div>
        <label style="font-size: 16px"><strong>Custom logo size</strong></label>
        <v-row no-gutters>
            <v-col cols="12"
                   lg="6"
                   class="d-flex align-center justify-center"
            >
                <div class="d-inline-block mr-4">
                    Custom logo height
                </div>
                <div class="d-flex align-center">
                    <v-text-field
                        v-model="customHeight"
                        type="number"
                        style="max-width: 100px"
                        min="0"
                        :disabled="project.isArchived"
                    />
                </div>
            </v-col>
            <v-col cols="12"
                   lg="6"
                   class="d-flex align-center justify-center"
            >
                <div class="d-inline-block mr-4">
                    Custom logo width
                </div>
                <div class="d-flex align-center">
                    <v-text-field
                        v-model="customWidth"
                        style="max-width: 100px"
                        type="number"
                        min="0"
                        :disabled="project.isArchived"
                    />
                </div>
            </v-col>
        </v-row>
    </div>
</template>

<script>
    import { mapActions, mapMutations, mapState } from 'vuex';

    export default {
        name: 'LogoWidthHeightRegistration',
        computed: {
            ...mapState('projects', ['project']),
            customHeight: {
                get() {
                    return this.project.style.customLogoHeightRegistrationPage;
                },
                set(value) {
                    this.setCustomLogoHeightRegistrationPage(value);
                    this.saveProject({
                        id: this.project._id,
                        key: 'style.customLogoHeightRegistrationPage',
                        value,
                    });
                },
            },
            customWidth: {
                get() {
                    return this.project.style.customLogoWidthInPercentRegistrationPage;
                },
                set(value) {
                    this.setCustomLogoWidthInPercentRegistrationPage(value);
                    this.saveProject({
                        id: this.project._id,
                        key: 'style.customLogoWidthInPercentRegistrationPage',
                        value,
                    });
                },
            },
        },
        methods: {
            ...mapMutations('projects', ['setCustomLogoHeightRegistrationPage', 'setCustomLogoWidthInPercentRegistrationPage']),
            ...mapActions('projects', ['saveProject']),
        }
    };
</script>
