<template>
    <v-container fluid>
        <v-row class="page-header-container grey lighten-4">
            <v-col cols="12"
                   md="2"
                   lg="2"
            >
                <h1 class="px-8 font-weight-regular page-header">
                    Users
                </h1>
            </v-col>
            <v-col cols="12"
                   md="2"
                   lg="2"
            >
                <v-text-field v-model="searchText"
                              solo
                              flat
                              hide-details
                              dense
                              background-color="rgba(0,0,0,0.1)"
                              placeholder="Search users"
                              clearable
                />
            </v-col>
            <v-col cols="12"
                   md="8"
                   lg="8"
                   class="d-flex"
            >
                <v-spacer />
                <v-btn color="primary"
                       class="mr-8"
                       @click="createUser"
                >
                    Add User
                </v-btn>
            </v-col>
        </v-row>
        <div v-if="!loading">
            <user-list :key="userEditorState"
                       :users="users"
            />
            <v-container fluid>
                <v-row align="center"
                       align-content="center"
                       justify="center"
                >
                    <v-col class="d-flex"
                           cols="1"
                           sm="1"
                    >
                        <small class="d-flex justify-right"
                               style="width: 100%; white-space: nowrap"
                        >
                            Rows per page
                        </small>
                    </v-col>
                    <v-col class="d-flex"
                           cols="1 sm=1"
                    >
                        <v-select v-model="rowsPerPageSelected"
                                  :items="rowsPerPageItems"
                                  :label="undefined"
                                  outlined
                                  dense
                                  hide-details
                        >
                            <template v-slot:selection="{item}">
                                <span class="d-flex justify-end"
                                      style="width: 100%"
                                >{{ item }}</span>
                            </template>
                        </v-select>
                    </v-col>
                    <v-col class="d-flex"
                           cols="6"
                           sm="3"
                    >
                        <v-pagination v-if="pagination.total > 1"
                                      v-model="currentPage"
                                      :length="pagination.total"
                                      circle
                                      total-visible="6"
                                      color="secondary"
                        />
                    </v-col>
                </v-row>
            </v-container>
        </div>
        <v-progress-linear v-else
                           indeterminate
        />
        <create-user v-if="isUserCreated"
                     :key="userEditorState"
                     @user-created="userSaved"
        />
        <edit-user v-if="isUserEdited"
                   :key="userEditorState"
                   @user-edited="userSaved"
        />
    </v-container>
</template>

<script>
    import { debounce } from 'lodash';
    import UserList from '@/components/pages/Users/UserList';
    import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
    import CreateUser from '@/components/pages/Users/CreateUser';
    import EditUser from '@/components/pages/Users/EditUser';

    export default {
        name: 'Users',
        components: {EditUser, CreateUser, UserList},
        data() {
            return {
                loading: true,
                inProgress: false,
                rowsPerPageItems: [10, 25, 50],
                userModal: false,
            };
        },
        computed: {
            ...mapState('users', ['users', 'pagination', 'listingHeader', 'userEditorState']),
            ...mapGetters('auth', [
                'isQuadiaLiveAdmin',
                'isCompanyAdminAtAnyProductionCompany',
                'isProjectEditorAtAnyProductionCompany'
            ]),
            searchText: {
                get() {
                    return this.pagination.searchText;
                },
                set: debounce(function(value) {
                    this.setSearchText(value);
                    this.setCurrentPage(1);
                    this.loadUsers(true);
                }, 400)
            },
            rowsPerPageSelected: {
                get() {
                    return this.pagination.pageSize;
                },
                set(value) {
                    this.setCurrentPage(1);
                    this.setPageSize(value);
                    this.loadUsers(true);
                },
            },
            currentPage: {
                get() {
                    return this.pagination.current;
                },
                set(value) {
                    this.setCurrentPage(value);
                    this.loadUsers(true);
                },
            },
            isUserCreated() {
                return this.$route.name === 'CreateUser';
            },
            isUserEdited() {
                return this.$route.name === 'User';
            }
        },
        methods: {
            ...mapActions('users', ['getUsers']),
            ...mapMutations('users', ['setCurrentPage', 'setPageSize', 'setSearchText', 'updateEditorState']),
            createUser()
            {
                this.updateEditorState();
                this.$router.push({name: 'CreateUser'});
            },
            async userSaved() {
                await this.loadUsers(true);
                this.$router.push({name: 'Users'});
            },
            async loadUsers(reload) {
                this.loading = reload;
                await this.getUsers();
                this.loading = false;
            },
        },
        mounted() {
            this.setCurrentPage(1);
            this.loadUsers(true);
        }
    };
</script>